import React, { Component } from "react";
import { Link } from "react-router-dom";
import GroupListItem from "./GroupListItem";
import { graphql } from "react-apollo";
import { RN_GROUP_lIST_PAGER } from "./../../GraphQL/RNqueries";
import { Mutation } from "react-apollo";
import { RN_GROUP_CREATE } from "../../GraphQL/RNmutations";
const queryString = require("query-string");

class GroupList extends Component {
  state = {
    page: 1,
    count: 10,
    offset: 0
  };

  update(pageNumber) {
    this.props.update((pageNumber - 1) * this.state.count, this.state.count);
  }

  static getDerivedStateFromProps(props, oldState) {
    let updateForState = {};
    if (oldState.updated == null || props.pageInfo) {
      updateForState = {
        count:
          props.pageInfo.count === oldState.count
            ? +props.pageInfo.count
            : +oldState.count,
        total: +props.pageInfo.total,
        offset: (oldState.page - 1) * oldState.count,
        page: oldState.offset / oldState.count + 1,
        pages: Math.ceil(props.pageInfo.total / oldState.count),
        updated: true
      };
      updateForState.page =
        +queryString.parse(document.location.hash).page || 1;
      updateForState.count =
        +queryString.parse(document.location.hash).count || 10;
      updateForState.pages = Math.ceil(
        props.pageInfo.total / updateForState.count
      );
      updateForState.offset = (updateForState.page - 1) * updateForState.count;
      props.update(updateForState.offset, updateForState.count);
    }

    return updateForState;
  }

  renderButtons(count) {
    let array = [];
    let numbers = Math.floor(count / 2);

    let start = this.state.page - numbers;
    let end = this.state.page + numbers;

    for (let i = start - 1; i <= end + 1; i++) {
      if (i <= start - 1 && i > 1) {
        array.push("...");
      } else if (i === end + 1 && i < this.state.pages) {
        array.push("...");
      } else if (i >= this.state.pages) {
        continue;
      } else if (i <= 1) {
        continue;
      } else {
        array.push(i);
      }
    }

    let array_to_show = [1, ...array, +this.state.pages];

    return (
      <>
        {this.state.pages > 1 &&
          array_to_show.map((item, i) => {
            return (
              <React.Fragment key={`${item}${i}`}>
                {item !== "..." ? (
                  <li
                    className={
                      this.state.page === item
                        ? "page-item active"
                        : "page-item"
                    }
                  >
                    <Link
                      className="page-link"
                      onClick={() => {
                        this.update(item);
                        this.setState({
                          page: item
                        });
                      }}
                      to={`/#page=${item}&count=${this.state.count}`}
                    >
                      {item}
                    </Link>
                  </li>
                ) : (
                  <li className="page-item disabled">
                    <span className="page-link">...</span>
                  </li>
                )}
              </React.Fragment>
            );
          })}
      </>
    );
  }

  render() {
    console.log(this.props);

    const startString = (this.state.page - 1) * this.state.count + 1;
    const endString =
      (this.state.page - 1) * this.state.count + this.state.count;

    return (
      <>
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
          <div className="collapse navbar-collapse">
            <span className="navbar-brand mr-5">
              Список групп пользователей
            </span>
          </div>
        </nav>
        <section className="section-top">
          <div className="container-fluid">
            <div className="mb-1">
              <div className="float-left">
                <div className="mr-5 text-dark">
                  <h2>Список</h2>
                  <label>Показывать строк: </label>
                  <select
                    value={this.state.count}
                    onChange={e => {
                      const value = e.target.value;
                      this.setState({
                        count: value,
                        pages: Math.ceil(this.state.total / value),
                        page: Math.ceil(this.state.offset / value) + 1
                      });

                      const params = queryString.parse(document.location.hash);
                      params.page = Math.ceil((this.state.offset + 1) / value);
                      params.count = value;
                      document.location.hash = queryString.stringify(params);

                      this.props.update(
                        Math.ceil(this.state.offset / value) * value,
                        value
                      );
                    }}
                  >
                    <option value={10}>10</option>
                    <option value={5}>5</option>
                    <option value={20}>20</option>
                  </select>
                </div>
              </div>
              <div className="float-left mt-2">
                <Mutation mutation={RN_GROUP_CREATE}>
                  {(createGroup, { data }) => (
                    <button
                      className="btn btn-sm btn-success mr-1"
                      onClick={() => {
                        createGroup().then(res => {
                          this.props.history.push(
                            `/group/edit/${res.data.RnGroupCreate.id}`
                          );
                        });
                      }}
                    >
                      Добавить группу
                    </button>
                  )}
                </Mutation>
                <Link className="btn btn-sm btn-secondary" to="/group/access">
                  Доступы
                </Link>
              </div>
              <div className="float-right mt-2 text-right right-nav-text">
                <a>
                  {startString} -{" "}
                  {endString < this.state.total ? endString : this.state.total}{" "}
                  из {this.state.total}
                </a>
                <span className="nav-controls">
                  {this.state.page > 1 ? (
                    <Link
                      className="prev-page"
                      onClick={() => {
                        this.update(this.state.page - 1);
                        this.setState(state => ({
                          page: state.page - 1
                        }));
                      }}
                      to={`/#page=${this.state.page - 1}&count=${
                        this.state.count
                      }`}
                    />
                  ) : (
                    <a className="prev-page disabled"></a>
                  )}
                  {this.state.page < this.state.pages &&
                  this.state.count < this.state.total ? (
                    <Link
                      className="next-page"
                      onClick={() => {
                        this.update(this.state.page + 1);
                        this.setState(state => ({
                          page: state.page + 1
                        }));
                      }}
                      to={`/#page=${this.state.page + 1}&count=${
                        this.state.count
                      }`}
                    />
                  ) : (
                    <a className="next-page disabled"></a>
                  )}
                </span>
              </div>
              <div className="clearfix"></div>
            </div>
          </div>
        </section>
        <section className="content">
          <div className="container-fluid mediaplans-list-block">
            <div className="table-container">
              <table className="table table-borderless table-striped table-hover mpListTable">
                <thead className="thead-light">
                  <tr>
                    <th className="font-weight-bold">Наименование группы</th>
                    <th className="font-weight-bold">Зона видимости</th>
                    <th className="font-weight-bold" colSpan={5}></th>
                  </tr>
                </thead>
                <tbody>
                  {this.props.list.map(item => (
                    <React.Fragment key={item.id}>
                      {<GroupListItem {...item} />}
                    </React.Fragment>
                  ))}
                </tbody>
              </table>
              <nav
                className="text-center mt-5 mb-5"
                aria-label="Page navigation example"
              >
                <ul className="pagination justify-content-center">
                  {this.state.total > this.state.count
                    ? this.renderButtons(3)
                    : null}
                </ul>
              </nav>
            </div>
          </div>
        </section>
        <div className="small-screen">
          <div className="container-fluid mt-5">
            <h1>
              На данном устройстве работа не возможна. Используйте устройство с
              более широким экраном.
            </h1>
          </div>
        </div>
      </>
    );
  }
}

export const GroupListContainer = graphql(RN_GROUP_lIST_PAGER, {
  options: props => ({
    variables: {
      params: {
        offset: 0,
        count: 10
      }
    }
  }),
  props: ({ data }) => {
    return {
      loading: data.loading,
      error: data.error,
      list: data.GroupListPager ? data.GroupListPager.data : null,
      pageInfo: data.GroupListPager ? data.GroupListPager.pageInfo : null,
      update: (offset, count) => {
        data.fetchMore({
          variables: {
            params: {
              count: count,
              offset: offset
            }
          },
          updateQuery: (prev, { fetchMoreResult }) => {
            return fetchMoreResult;
          }
        });
      }
    };
  }
})(props => {
  if (props.loading) return null;
  if (props.error) return null;
  if (props.list == null) {
    console.log("loading bad result", props);
    return null;
  }

  return <GroupList {...props} />;
});

export default GroupListContainer;
