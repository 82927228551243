import React, { useState, useEffect } from "react";
import NumberFormat from "react-number-format";
import Select from "react-select";
import AsyncSelect from "react-select/lib/Async";
import { ApolloConsumer } from "react-apollo";

import { selectQuery, selectReferenceGeo } from "../../Briefing/gql/query";
import { COMON_USER_AVAILABLE } from "../../../GraphQL/RNqueries";

import icon from "./ravno.png";

const moment = require("moment/min/moment-with-locales.js");

/* global $*/
function Briefing(props) {
  const [state, setState] = useState({
    data: props.data && props.data.Briefing ? props.data.Briefing : {},
  });

  //создание ссылки ручное
  const dateRangeInput = React.createRef();
  const tenderDeadlineInput = React.createRef();
  const deadlineInput = React.createRef();

  useEffect(() => {
    function jQueryCreate() {
      $("#daterangselectBriefing")
        .daterangepicker({
          startDate: state.data.dateStart
            ? moment(state.data.dateStart).format("DD.MM.YYYY")
            : moment(),
          endDate: state.data.dateEnd
            ? moment(state.data.dateEnd).format("DD.MM.YYYY")
            : moment(),
          opens: "right",
          locale: {
            applyLabel: "Применить",
            cancelLabel: "Отменить",
          },
        })
        .on("apply.daterangepicker", function (e, picker) {
          setState({
            data: {
              ...state.data,
              dateStart: moment(picker.startDate).format("YYYY-MM-DD"),
              dateEnd: moment(picker.endDate).format("YYYY-MM-DD"),
            },
          });

          props.updateField({
            variables: {
              id: state.data.id,
              field: "dateStart",
              value: moment(picker.startDate).format(),
            },
          });

          props
            .updateField({
              variables: {
                id: state.data.id,
                field: "dateEnd",
                value: moment(picker.endDate).format(),
              },
            })
            .then(() => {
              props.history.go(0);
            });
        });

      $("#daterangselectBriefing").ready((e) => {
        let date = dateRangeInput.current.value.split(" - ");
        setState({
          data: {
            ...state.data,
            dateStart: moment(date[0], "DD.MM.YYYY").format("YYYY-MM-DD"),
            dateEnd: moment(date[1], "DD.MM.YYYY").format("YYYY-MM-DD"),
          },
        });

        props.updateField({
          variables: {
            id: state.data.id,
            field: "dateStart",
            value: moment(date[0], "DD.MM.YYYY").format("YYYY-MM-DD"),
          },
        });

        props.updateField({
          variables: {
            id: state.data.id,
            field: "dateEnd",
            value: moment(date[1], "DD.MM.YYYY").format("YYYY-MM-DD"),
          },
        });
      });

      $("input[name=deadline]")
        .daterangepicker({
          startDate: state.data.deadline
            ? moment(state.data.deadline).format("DD.MM.YYYY HH:mm")
            : false,
          timePicker: true,
          timePicker24Hour: true,
          singleDatePicker: true,
          showDropdowns: true,
          locale: {
            format: "DD.MM.YYYY HH:mm ",
            applyLabel: "Применить",
            cancelLabel: "Отменить",
          },
        })
        .on("apply.daterangepicker", function (ev, picker) {
          setState({
            data: {
              ...state.data,
              deadline: moment(picker.startDate).format(),
            },
          });

          props.updateField({
            variables: {
              id: state.data.id,
              field: "deadline",
              value: moment(picker.startDate).format(),
            },
          });
        });

      $("input[name=tender_deadline]")
        .daterangepicker({
          startDate: state.data.tender_deadline
            ? moment(state.data.tender_deadline).format("DD.MM.YYYY HH:mm")
            : moment(),
          timePicker: true,
          timePicker24Hour: true,
          singleDatePicker: true,
          showDropdowns: true,
          locale: {
            format: "DD.MM.YYYY HH:mm ",
            applyLabel: "Применить",
            cancelLabel: "Отменить",
          },
        })
        .on("apply.daterangepicker", function (ev, picker) {
          setState({
            data: {
              ...state.data,
              tender_deadline: moment(picker.startDate).format(),
            },
          });

          props.updateField({
            variables: {
              id: state.data.id,
              field: "tender_deadline",
              value: moment(picker.startDate).format(),
            },
          });
        });
    }

    jQueryCreate();
    // let date = dateRangeInput.current.value;
    // date = date.split(" - ");

    // props.updateField({
    //     variables:
    //         {
    //             id: state.data.id,
    //             field: 'dateEnd',
    //             value: moment(date[0]).format()
    //
    //         }
    // });
    //
    // props.updateField({
    //     variables:
    //         {
    //             id: state.data.id,
    //             field: 'dateStart',
    //             value: moment(date[1]).format()
    //
    //         }
    // });

    // setState({
    //     data: {
    //         ...state.data,
    //         entryDate: moment().format(),
    //         //deadline: moment(deadlineInput.current.value, 'dd.mm.yyyy').format('YYYY-MM-DD'),
    //         //tender_deadline: moment(tenderDeadlineInput.current.value, 'dd.mm.yyyy').format('YYYY-MM-DD'),
    //         //dateStart: moment(date[0], 'dd.mm.yyyy').format('YYYY-MM-DD'),
    //         //dateEnd: moment(date[1], 'dd.mm.yyyy').format('YYYY-MM-DD')
    //     }
    // });
  }, [state.data.entryDate]);

  let renderLegalEntityVat = false;
  let renderLegalEntityNoVat = false;

  if (state.data.contract_type === 1 && state.data.vat === 1) {
    renderLegalEntityVat = true;
  }

  if (state.data.contract_type === 1 && state.data.no_vat === 1) {
    renderLegalEntityNoVat = true;
  }

  if (
    state.data.contract_type === 2 &&
    state.data.no_vat === 1 &&
    state.data.vat === 1 &&
    state.data.agent_commission_position
  ) {
    renderLegalEntityVat = true;
  }

  if (
    state.data.contract_type === 3 &&
    state.data.no_vat &&
    state.data.vat &&
    state.data.agent_commission_position
  ) {
    renderLegalEntityVat = true;
    renderLegalEntityNoVat = false;
  }

  if (
    state.data.contract_type === 3 &&
    state.data.no_vat &&
    !state.data.vat &&
    state.data.agent_commission_position
  ) {
    renderLegalEntityVat = false;
    renderLegalEntityNoVat = true;
  }

  let payment_delay = [];

  if (state.data.payment_delay === 1)
    payment_delay = [{ label: "С Даты Финиш" }];
  if (state.data.payment_delay === 2)
    payment_delay = [{ label: "С начала месяца Даты Финиш" }];
  if (state.data.payment_delay === 3)
    payment_delay = [{ label: "С конца месяца Даты Финиш" }];
  if (state.data.payment_delay === 4)
    payment_delay = [{ label: "С Даты Старт" }];
  if (state.data.payment_delay === 5)
    payment_delay = [{ label: "С начала месяца Даты Старт" }];
  if (state.data.payment_delay === 6)
    payment_delay = [{ label: "С конца месяца Даты Старт" }];

  let cities = [];

  if (state.data.Cities) {
    cities = state.data.Cities.map((item) => {
      return {
        value: item.AOGUID,
        label: item.FORMALNAME,
      };
    });
  }

  return (
    <>
      <div className="col-7">
        <div className="row mb-2">
          <div className="col-6">
            <h5>Брифинг в медиаплане</h5>
          </div>
          <div className="col-6 text-right">
            <button
              className="btn btn-danger"
              onClick={() => {
                props.deleteBriefing();
              }}
            >
              Удалить
            </button>
          </div>
        </div>
        <div className="row form-group mt-1">
          <label className="col-3">Название</label>
          <div className="col-9">
            <input
              type="text"
              value={state.data.name ? state.data.name : ""}
              className="form-control"
              onChange={(e) => {
                const value = e.target.value;
                setState({
                  data: {
                    ...state.data,
                    name: value,
                  },
                });

                props.updateField({
                  variables: {
                    id: state.data.id,
                    field: "name",
                    value: value,
                  },
                });
              }}
            />
          </div>
        </div>
        <div className="row form-group mt-1">
          <label className="col-3">
            Клиент<span className="text-danger">*</span>
          </label>
          <div className="col-9">
            <ApolloConsumer>
              {(client) => (
                <AsyncSelect
                  name="partner_id"
                  defaultValue={
                    state.data.Partner
                      ? {
                          value: state.data.Partner.id,
                          label: state.data.Partner.name,
                        }
                      : {}
                  }
                  loadOptions={(inputValue) =>
                    selectQuery(inputValue, "MLPartnerList", client)
                  }
                  onChange={(partner_id) => {
                    const value = partner_id.value;
                    setState({
                      data: {
                        ...state.data,
                        partner_id: value,
                      },
                    });

                    props.updateField({
                      variables: {
                        id: state.data.id,
                        field: "partner_id",
                        value: value,
                      },
                    });
                  }}
                />
              )}
            </ApolloConsumer>
          </div>
        </div>
        <div className="row form-group mt-1">
          <label className="col-3">
            Бренд<span className="text-danger">*</span>
          </label>
          <div className="col-9">
            <ApolloConsumer>
              {(client) => (
                <AsyncSelect
                  name="brand_id"
                  defaultValue={
                    state.data.Brand
                      ? {
                          value: state.data.Brand.id,
                          label: state.data.Brand.name,
                        }
                      : {}
                  }
                  loadOptions={(inputValue) =>
                    selectQuery(inputValue, "MLBrandList", client)
                  }
                  onChange={(brand_id) => {
                    const value = brand_id.value;
                    setState({
                      data: {
                        ...state.data,
                        brand_id: value,
                      },
                    });

                    props.updateField({
                      variables: {
                        id: state.data.id,
                        field: "brand_id",
                        value: value,
                      },
                    });
                  }}
                />
              )}
            </ApolloConsumer>
          </div>
        </div>
        <div className="row form-group mt-1">
          <label className="col-3">
            Налогооблажение<span className="text-danger">*</span>
          </label>
          <div className="col-9">
            <label>
              <input
                name="vat"
                type="checkbox"
                onChange={(e) => {
                  const value = e.target.checked ? 1 : 0;
                  setState({
                    data: {
                      ...state.data,
                      vat: value,
                      legal_entity_vat: null,
                      legal_entity_novat: null,
                    },
                  });

                  props.updateField({
                    variables: {
                      id: state.data.id,
                      field: "vat",
                      value: value,
                    },
                  });
                }}
                checked={state.data.vat === 1}
              />{" "}
              с НДС
            </label>
            &nbsp;&nbsp;
            <label>
              <input
                name="no_vat"
                type="checkbox"
                onChange={(e) => {
                  const value = e.target.checked ? 1 : 0;
                  setState({
                    data: {
                      ...state.data,
                      no_vat: value,
                      legal_entity_vat: null,
                      legal_entity_novat: null,
                    },
                  });

                  props.updateField({
                    variables: {
                      id: state.data.id,
                      field: "no_vat",
                      value: value,
                    },
                  });
                }}
                checked={state.data.no_vat === 1}
              />{" "}
              без НДС
            </label>
          </div>
        </div>
        <div className="row form-group mt-1">
          <label className="col-3">
            Тип договора<span className="text-danger">*</span>
          </label>
          <div className="col-9">
            <select
              name="contract_type"
              className="form-control"
              value={state.data.contract_type ? state.data.contract_type : ""}
              disabled={!state.data.no_vat && !state.data.vat}
              onChange={(e) => {
                const value = +e.target.value;
                setState({
                  data: {
                    ...state.data,
                    contract_type: value,
                    legal_entity_vat: null,
                    legal_entity_novat: null,
                    agent_commission_value_type: 1,
                  },
                });

                props.updateField({
                  variables: {
                    id: state.data.id,
                    field: "contract_type",
                    value: value,
                  },
                });
              }}
            >
              <option value={0} hidden>
                &nbsp;
              </option>
              <option value={1}>Услуговый</option>
              <option
                value={2}
                disabled={state.data.no_vat !== 1 || state.data.vat !== 1}
              >
                Агентский
              </option>
              <option value={3} disabled={state.data.no_vat !== 1}>
                Комплексный
              </option>
            </select>
          </div>
        </div>
        {state.data.contract_type === 2 || state.data.contract_type === 3 ? (
          <div className={state.data.vat || state.data.no_vat ? "" : "d-none"}>
            <div className="form-group row">
              <label className="col-3 col-form-label">
                Агентское вознаграждение
              </label>
              <div className="col-9">
                <select
                  name="agent_commission_position"
                  value={
                    state.data.agent_commission_position
                      ? state.data.agent_commission_position
                      : ""
                  }
                  className="form-control"
                  onChange={(e) => {
                    const value = +e.target.value;
                    setState({
                      data: {
                        ...state.data,
                        agent_commission_position: value,
                      },
                    });

                    props.updateField({
                      variables: {
                        id: state.data.id,
                        field: "agent_commission_position",
                        value: value,
                      },
                    });
                  }}
                >
                  <option value="0" hidden></option>
                  <option value="1">Сверху</option>
                  <option value="2" disabled={state.data.contract_type !== 3}>
                    Внутри
                  </option>
                </select>
              </div>
            </div>
            <div className="form-group row">
              <label className="col-3 col-form-label">Сумма АВ</label>
              <div className="col-4">
                <input
                  name="agent_commission_value"
                  className="form-control"
                  type="text"
                  value={
                    state.data.agent_commission_value
                      ? state.data.agent_commission_value
                      : ""
                  }
                  onChange={(e) => {
                    const value = e.target.value;
                    setState({
                      data: {
                        ...state.data,
                        agent_commission_value: value,
                      },
                    });

                    props.updateField({
                      variables: {
                        id: state.data.id,
                        field: "agent_commission_value",
                        value: value,
                      },
                    });
                  }}
                />
              </div>
              <div className="col-3">
                {state.data.agent_commission_value_type === 0 ? "руб" : "%"}
                &nbsp;
                <img
                  id="agent_commission_value_type"
                  src={icon}
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    let value = 1;
                    if (state.data.agent_commission_value_type === 1) {
                      value = 0;
                    }
                    setState({
                      data: {
                        ...state.data,
                        agent_commission_value_type: value,
                      },
                    });

                    props.updateField({
                      variables: {
                        id: state.data.id,
                        field: "agent_commission_value_type",
                        value: value,
                      },
                    });
                  }}
                />
                &nbsp;
                {state.data.agent_commission_value_type === 0 ? "%" : "руб"}
              </div>
            </div>
          </div>
        ) : null}
        {renderLegalEntityVat ? (
          <div className="form-group row">
            <label className="col-3 col-form-label">
              Юр. лицо Дельта Плана с НДС
            </label>
            <div className="col-9">
              <ApolloConsumer>
                {(client) => (
                  <AsyncSelect
                    name="legal_entity_vat"
                    defaultValue={
                      state.UrFace_nds
                        ? {
                            value: state.data.UrFace_nds.id,
                            label: state.data.UrFace_nds.name,
                          }
                        : {}
                    }
                    defaultOptions
                    loadOptions={(inputValue) =>
                      selectQuery(
                        inputValue,
                        "MLDpUrFacesList",
                        client,
                        "vat",
                        1
                      )
                    }
                    onChange={(legal_entity_vat) => {
                      const value = legal_entity_vat.value;

                      setState({
                        data: {
                          ...state.data,
                          legal_entity_vat: value,
                        },
                      });

                      props.updateField({
                        variables: {
                          id: state.data.id,
                          field: "legal_entity_vat",
                          value: value,
                        },
                      });
                    }}
                  />
                )}
              </ApolloConsumer>
            </div>
          </div>
        ) : null}
        {renderLegalEntityNoVat ? (
          <div className="form-group row">
            <label className="col-3 col-form-label">
              Юр. лицо Дельта Плана
            </label>
            <div className="col-9">
              <ApolloConsumer>
                {(client) => (
                  <AsyncSelect
                    name="legal_entity_novat"
                    defaultValue={
                      state.UrFace_nonds
                        ? {
                            value: state.data.UrFace_nonds.id,
                            label: state.data.UrFace_nonds.name,
                          }
                        : {}
                    }
                    defaultOptions
                    loadOptions={(inputValue) =>
                      selectQuery(
                        inputValue,
                        "MLDpUrFacesList",
                        client,
                        "vat",
                        0
                      )
                    }
                    onChange={(legal_entity_novat) => {
                      const value = legal_entity_novat.value;
                      setState({
                        data: {
                          ...state.data,
                          legal_entity_novat: value,
                        },
                      });

                      props.updateField({
                        variables: {
                          id: state.data.id,
                          field: "legal_entity_novat",
                          value: value,
                        },
                      });
                    }}
                  />
                )}
              </ApolloConsumer>
            </div>
          </div>
        ) : null}
        <div className="form-group row">
          <label className="col-3 col-form-label">Адрес сайта</label>
          <div className="col-9">
            <input
              name="website"
              type="text"
              value={state.data.website ? state.data.website : ""}
              className="form-control"
              onChange={(e) => {
                const value = e.target.value;
                setState({
                  data: {
                    ...state.data,
                    website: value,
                  },
                });

                props.updateField({
                  variables: {
                    id: state.data.id,
                    field: "website",
                    value: value,
                  },
                });
              }}
            />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-3 col-form-label">Тендер</label>
          <div className="col-4">
            <label>
              <input
                name="tender_yes"
                type="checkbox"
                onChange={(e) => {
                  const value = e.target.checked && 1;
                  setState({
                    data: {
                      ...state.data,
                      tender: value,
                    },
                  });

                  props.updateField({
                    variables: {
                      id: state.data.id,
                      field: "tender",
                      value: value,
                    },
                  });
                }}
                checked={state.data.tender === 1}
              />{" "}
              да
            </label>
            &nbsp;&nbsp;
            <label>
              <input
                name="tender_no"
                type="checkbox"
                onChange={(e) => {
                  const value = e.target.checked && 0;
                  setState({
                    data: {
                      ...state.data,
                      tender: value,
                    },
                  });

                  props.updateField({
                    variables: {
                      id: state.data.id,
                      field: "tender",
                      value: value,
                    },
                  });
                }}
                checked={state.data.tender === 0}
              />{" "}
              нет
            </label>
          </div>
          <div
            className="col-lg-3"
            style={{
              display: state.data.tender === 1 ? "inline-block" : "none",
            }}
          >
            <input
              name="tender_deadline"
              id="tender_deadline"
              type="text"
              className="form-control calendar-icon"
              placeholder="Период"
              title="form-value"
              ref={tenderDeadlineInput}
            />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-3 col-form-label">
            Рекламируемый товар / услуга<span className="text-danger">*</span>
          </label>
          <div className="col-9">
            <input
              name="products"
              value={state.data.products ? state.data.products : ""}
              type="text"
              className="form-control"
              onChange={(e) => {
                const value = e.target.value;
                setState({
                  data: {
                    ...state.data,
                    products: value,
                  },
                });

                props.updateField({
                  variables: {
                    id: state.data.id,
                    field: "products",
                    value: value,
                  },
                });
              }}
            />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-3 col-form-label">
            Целевая аудитория РК - город, возраст, пол, уровень дохода, род
            занятий<span className="text-danger">*</span>
          </label>
          <div className="col-9">
            <textarea
              rows="4"
              name="target_audience"
              className="form-control"
              value={
                state.data.target_audience ? state.data.target_audience : ""
              }
              onChange={(e) => {
                const value = e.target.value;
                setState({
                  data: {
                    ...state.data,
                    target_audience: value,
                  },
                });

                props.updateField({
                  variables: {
                    id: state.data.id,
                    field: "target_audience",
                    value: value,
                  },
                });
              }}
            ></textarea>
            <p
              style={{
                fontSize: "0.8rem",
              }}
            >
              (если несколько указать важность каждой)
            </p>
          </div>
        </div>
        <div className="form-group row">
          <label className="col-3 col-form-label">Период</label>
          <div className="col-9">
            <input
              id="daterangselectBriefing"
              type="text"
              className="form-control calendar-icon"
              ref={dateRangeInput}
            />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-3 col-form-label">География РК</label>
          <div className="col-9">
            <ApolloConsumer>
              {(client) => (
                <AsyncSelect
                  isMulti
                  name="cities"
                  defaultValue={cities}
                  loadOptions={(inputValue) =>
                    selectReferenceGeo(inputValue, "MlCityList", client)
                  }
                  onChange={(cities) => {
                    const value = cities.map((item) => item.value).join(",");
                    setState({
                      data: {
                        ...state.data,
                        cities: value,
                      },
                    });

                    props.updateField({
                      variables: {
                        id: state.data.id,
                        field: "cities",
                        value: value,
                      },
                    });
                  }}
                />
              )}
            </ApolloConsumer>
          </div>
        </div>
        {/*<div className="form-group row">*/}
        {/*  <label className="col-3 col-form-label">Вся РФ</label>*/}
        {/*  <div className="col-9">*/}
        {/*    <input*/}
        {/*      name="federal"*/}
        {/*      type="checkbox"*/}
        {/*      checked={state.data.federal === 1}*/}
        {/*      onChange={(e) => {*/}
        {/*        const value = e.target.checked ? 1 : 0;*/}

        {/*        setState({*/}
        {/*          data: {*/}
        {/*            ...state.data,*/}
        {/*            federal: value,*/}
        {/*          },*/}
        {/*        });*/}

        {/*        props.updateField({*/}
        {/*          variables: {*/}
        {/*            id: state.data.id,*/}
        {/*            field: "federal",*/}
        {/*            value: value,*/}
        {/*          },*/}
        {/*        });*/}
        {/*      }}*/}
        {/*    />*/}
        {/*  </div>*/}
        {/*</div>*/}
        <div className="row form-group mt-1">
          <label className="col-3">
            Куратор проекта<span className="text-danger">*</span>
          </label>
          <div className="col-9">
            <ApolloConsumer>
              {(client) => (
                <AsyncSelect
                  name="manager_id"
                  defaultValue={
                    state.data.Manager
                      ? {
                          value: state.data.Manager.id,
                          label: state.data.Manager.nameFull,
                        }
                      : {}
                  }
                  loadOptions={(inputValue) =>
                    selectQuery(
                      inputValue,
                      "RefEmployeeList",
                      client,
                      "manager"
                    )
                  }
                  onChange={(brand_id) => {
                    const value = brand_id.value;
                    setState({
                      data: {
                        ...state.data,
                        manager_id: value,
                      },
                    });

                    props.updateField({
                      variables: {
                        id: state.data.id,
                        field: "manager_id",
                        value: value,
                      },
                    });
                  }}
                />
              )}
            </ApolloConsumer>
          </div>
        </div>
        <div className="row form-group mt-1">
          <label className="col-3">
            Ответственный<span className="text-danger">*</span>
          </label>
          <div className="col-9">
            <ApolloConsumer>
              {(client) => (
                <AsyncSelect
                  name="responsible_id"
                  defaultValue={
                    state.Responsible
                      ? {
                          value: state.Responsible.id,
                          label: state.Responsible.nameFull,
                        }
                      : {}
                  }
                  loadOptions={(inputValue) =>
                    selectQuery(
                      inputValue,
                      "RefEmployeeList",
                      client,
                      "manager"
                    )
                  }
                  onChange={(responsible) => {
                    const value = responsible.value;

                    client
                      .query({
                        query: COMON_USER_AVAILABLE,
                        variables: {
                          id: value,
                        },
                      })
                      .then((result) => {
                        if (result.data.ComonUserAvailable.id !== value) {
                          alert(
                            "Исполнитель в отпуске, заменен на заместителя"
                          );
                        }
                        setState({
                          data: {
                            ...state.data,
                            responsible_id: value,
                          },
                          Responsible: { ...result.data.ComonUserAvailable },
                        });

                        props.updateField({
                          variables: {
                            id: state.data.id,
                            field: "responsible_id",
                            value: value,
                          },
                        });
                      });
                  }}
                />
              )}
            </ApolloConsumer>
          </div>
        </div>
        <div className="form-group row">
          <label className="col-3 col-form-label">Бюджет РК</label>
          <div className="col-4 form-group row">
            <label className="col-3 col-form-label">От</label>
            <NumberFormat
              name="totalBudgetFrom"
              className="form-control col-9"
              thousandSeparator={" "}
              decimalSeparator={","}
              decimalScale={2}
              suffix={" ₽"}
              value={
                state.data.totalBudgetFrom ? state.data.totalBudgetFrom : null
              }
              onValueChange={(values) => {
                const value = values.value;
                setState({
                  data: {
                    ...state.data,
                    totalBudgetFrom: value,
                  },
                });

                props.updateField({
                  variables: {
                    id: state.data.id,
                    field: "totalBudgetFrom",
                    value: value,
                  },
                });
              }}
            />
          </div>
          <div className="col-4 form-group row">
            <label className="col-3 col-form-label">До</label>
            <NumberFormat
              name="totalBudgetTo"
              className="col-9 form-control"
              thousandSeparator={" "}
              decimalSeparator={","}
              decimalScale={2}
              suffix={" ₽"}
              value={state.data.totalBudgetTo ? state.data.totalBudgetTo : null}
              onValueChange={(values) => {
                const value = values.value;
                setState({
                  data: {
                    ...state.data,
                    totalBudgetTo: value,
                  },
                });

                props.updateField({
                  variables: {
                    id: state.data.id,
                    field: "totalBudgetTo",
                    value: value,
                  },
                });
              }}
            />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-3 col-form-label">
            Отсрочка платежа<span className="text-danger">*</span>
          </label>
          <div className="col-6">
            <Select
              name="payment_delay"
              options={[
                {
                  label: "С Даты Финиш",
                  value: 1,
                },
                {
                  label: "С начала месяца Даты Финиш",
                  value: 2,
                },
                {
                  label: "С конца месяца Даты Финиш",
                  value: 3,
                },
                {
                  label: "С Даты Старт",
                  value: 4,
                },
                {
                  label: "С начала месяца Даты Старт",
                  value: 5,
                },
                {
                  label: "С конца месяца Даты Старт",
                  value: 6,
                },
              ]}
              defaultValue={payment_delay}
              onChange={(payment_delay) => {
                const value = payment_delay.value;
                setState({
                  data: {
                    ...state.data,
                    payment_delay: value,
                  },
                });

                props.updateField({
                  variables: {
                    id: state.data.id,
                    field: "payment_delay",
                    value: value,
                  },
                });
              }}
            />
          </div>
          <div className="col-3">
            <input
              name="payment_delay_value"
              type="number"
              step="1"
              className="form-control"
              value={
                state.data.payment_delay_value
                  ? state.data.payment_delay_value
                  : ""
              }
              onChange={(e) => {
                const value = +e.target.value;
                setState({
                  data: {
                    ...state.data,
                    payment_delay_value: value,
                  },
                });

                props.updateField({
                  variables: {
                    id: state.data.id,
                    field: "payment_delay_value",
                    value: value,
                  },
                });
              }}
            />
          </div>
        </div>
        <div className="row form-group mt-1">
          <label className="col-3">deadline, дата и время</label>
          <div className="col-5">
            <input
              name="deadline"
              type="text"
              className="form-control calendar-icon"
              placeholder="Период"
              title="form-value"
              ref={deadlineInput}
            />
          </div>
          <div className="col-4 date-info">
            Не менее трех рабочих дней на подготовку медиаплана
          </div>
        </div>
      </div>
    </>
  );
}

export default Briefing;
