import React, { Component } from "react";
import TargetingList from "./TargetingList/TargetingList";
import { Mutation } from "react-apollo";
import gql from "graphql-tag";
import { Query } from "react-apollo";
import Creatable from "react-select/lib/Creatable";

import NumberFormat from "react-number-format";
import { ImageCard } from "../../../../../../components/inventory/imageCard";

class InventoryBanner extends Component {
  state = {
    image: [],
    loaded: false,
    pages: [],
    targeting: [],
    file: null,
  };

  static getDerivedStateFromProps(props, oldState) {
    let updateForState = {};
    if (!oldState.loaded && props.parentProps.inventory) {
      updateForState = {
        files: [...props.parentProps.inventory.files],
        image: props.parentState.image,
        loaded: true,
        pages: props.parentProps.inventory.Pages.map((item) => ({
          label: item.name,
          value: item.id,
        })),
        targeting: props.parentProps.inventory.Targeting.map((item) => ({
          label: item.name,
          value: item.id,
        })),
      };
    }

    return updateForState;
  }

  render() {
    return (
      <>
        <div className="col-12">
          <div className="row">
            <div className="col-6 mb-3">
              <div className="row mb-3">
                <div className="col-4 pt-1">
                  Название<span className="text-danger">*</span>
                </div>
                <div className="col-8">
                  <input
                    type="text"
                    name="name"
                    className="form-control"
                    value={
                      this.props.parentState.data.name
                        ? this.props.parentState.data.name
                        : ""
                    }
                    onChange={(e) => {
                      const value = e.target.value;
                      this.props.setParentState({ name: value });
                    }}
                  />
                </div>
                <div className="col-12 mt-1">
                  <p
                    style={{
                      fontSize: "0.8rem",
                    }}
                  >
                    Введите название, расположение, ротацию, пример:{" "}
                    <i>Баннер-растяжка на главной, 33% ротации</i>
                  </p>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-4 pt-2">Разделы сайта</div>
                <div className="col-8">
                  <Query
                    variables={{
                      params: {
                        offset: 0,
                        count: 999,
                      },
                    }}
                    query={gql`
                      query RnInventoryPagesList($params: ParamsInput) {
                        RnInventoryPagesList(params: $params) {
                          id
                          name
                        }
                      }
                    `}
                  >
                    {({ loading, error, data }) => {
                      if (loading) return "Loading...";
                      if (error) return `Error! ${error.message}`;

                      const RnInventoryPagesList = data.RnInventoryPagesList.map(
                        (item) => ({
                          label: item.name,
                          value: item.id,
                        })
                      );

                      return (
                        <Mutation
                          mutation={gql`
                            mutation RnInventoryPageCreate(
                              $page: RnInventoryPagesInput
                            ) {
                              RnInventoryPageCreate(page: $page) {
                                id
                                name
                              }
                            }
                          `}
                        >
                          {(newItem, { data }) => (
                            <Creatable
                              name="pages"
                              isClearable
                              isMulti
                              value={this.state.pages}
                              options={RnInventoryPagesList}
                              onChange={(pages) => {
                                this.setState({
                                  pages,
                                });

                                const value = pages
                                  .map((item) => item.value)
                                  .join(",");

                                this.props.setParentState({
                                  pages: value.length ? value : null,
                                });
                              }}
                              onCreateOption={(new_page) => {
                                newItem({
                                  variables: {
                                    page: {
                                      name: new_page,
                                    },
                                  },
                                  refetchQueries: [
                                    "RnInventory",
                                    "RnInventoryPagesList",
                                  ],
                                }).then((res) => {
                                  let pages = this.state.pages;
                                  pages.push({
                                    label: res.data.RnInventoryPageCreate.name,
                                    value: res.data.RnInventoryPageCreate.id,
                                  });
                                  this.setState({
                                    pages,
                                  });

                                  const value = this.state.pages
                                    .map((item) => item.value)
                                    .join(",");

                                  this.props.setParentState({
                                    pages: value.length ? value : null,
                                  });
                                });
                              }}
                            />
                          )}
                        </Mutation>
                      );
                    }}
                  </Query>
                </div>
              </div>
              <div className="row mb-4 mt-2">
                <div className="col-4 pt-1">Отображение</div>
                <div className="col-8">
                  <label className="mr-3">
                    <input
                      type="checkbox"
                      name="display_desktop"
                      checked={
                        this.props.parentState.data.display_desktop === 1
                      }
                      onChange={(e) => {
                        const value = e.target.checked ? 1 : 0;
                        this.props.setParentState({
                          display_desktop: value,
                        });
                      }}
                    />{" "}
                    Десктоп
                  </label>
                  <label>
                    <input
                      type="checkbox"
                      name="display_mobile"
                      checked={this.props.parentState.data.display_mobile === 1}
                      value="1"
                      onChange={(e) => {
                        const value = e.target.checked ? 1 : 0;
                        this.props.setParentState({
                          display_mobile: value,
                        });
                      }}
                    />{" "}
                    Мобайл
                  </label>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-4">Размер / формат</div>
                <div className="col-8">
                  <input
                    type="text"
                    name="format"
                    className="form-control"
                    placeholder="Размер / формат"
                    value={
                      this.props.parentState.data.format
                        ? this.props.parentState.data.format
                        : ""
                    }
                    onChange={(e) => {
                      this.props.setParentState({
                        format: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>

              <div className="row mb-3">
                <div className="col-4">Фото</div>
                <div className="col-8">
                  <ImageCard
                    imageUrl={
                      this.state?.files?.length && this.state?.files[0]?.id
                        ? process.env.REACT_APP_BASE_API_URL +
                          "/endpoints/files.php?id=" +
                          this.state?.files[0]?.id
                        : null
                    }
                    onDelete={() => {
                      if (window.confirm("Вы точно хотите удалить файл?")) {
                        this.props.setParentState({
                          fileToDelete: this.state?.files[0]
                            ? [this.state?.files[0].id]
                            : [],
                        });

                        this.setState({
                          ...this.state,
                          files: [],
                        });
                      }
                    }}
                    onChange={(file) => {
                      this.props.setParentState({
                        fileToUpload: [file],
                      });
                    }}
                  />
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-4">Единица закупа</div>
                <div className="col-8">
                  <select
                    name="priceType"
                    className="form-control"
                    value={
                      this.props.parentState.data.priceType
                        ? this.props.parentState.data.priceType
                        : ""
                    }
                    onChange={(e) => {
                      this.props.setParentState({
                        priceType: +e.target.value,
                        price_value: null,
                        price_value_week: null,
                        price_value_month: null,
                      });
                    }}
                  >
                    <option>Выбрать</option>
                    <option value={5}>День</option>
                    <option value={1}>Неделя / Месяц</option>
                    <option value={6}>2 недели</option>
                    <option value={2}>За 1000 показов / CPM</option>

                    <option value={3}>За клик / CPC</option>
                    <option value={7}>Карточка</option>
                    <option value={8}>Звонок</option>
                    <option value={9}>Выход</option>
                    <option value={10}>Выезд</option>
                    <option value={11}>Материал</option>
                    <option value={12}>Период</option>
                  </select>
                </div>
              </div>
              {this.props.parentState.data.priceType &&
              this.props.parentState.data.priceType !== 1 ? (
                <div className="row mb-3">
                  <div className="col-4">Стоимость</div>
                  <div className="col-8">
                    <NumberFormat
                      name="price_value"
                      className="form-control"
                      thousandSeparator={" "}
                      decimalSeparator={","}
                      decimalScale={2}
                      suffix={!this.props.isForeign && " ₽"}
                      value={
                        this.props.parentState.data.price_value !== null
                          ? this.props.parentState.data.price_value
                          : ""
                      }
                      onValueChange={(price) => {
                        const value =
                          price.value === "" ? null : price.floatValue;
                        this.props.setParentState({
                          price_value: value,
                        });
                      }}
                    />
                  </div>
                </div>
              ) : null}
              {this.props.parentState.data.priceType === 1 ? (
                <div className="row mb-3">
                  <div className="col-4">Стоимость за неделю / месяц</div>
                  <div className="col-8">
                    <div
                      className="form-control"
                      style={{
                        padding: "0",
                      }}
                    >
                      <NumberFormat
                        style={{
                          border: "none",
                          height: "100%",
                          width: "50%",
                          padding: ".375rem .75rem",
                        }}
                        name="price_value_week"
                        thousandSeparator={" "}
                        decimalSeparator={","}
                        decimalScale={2}
                        suffix={!this.props.isForeign && " ₽"}
                        value={
                          this.props.parentState.data.price_value_week !== null
                            ? this.props.parentState.data.price_value_week
                            : ""
                        }
                        onValueChange={(price) => {
                          const value =
                            price.value === "" ? null : price.floatValue;
                          this.props.setParentState({
                            price_value_week: value,
                          });
                        }}
                      />
                      /
                      <NumberFormat
                        style={{
                          border: "none",
                          height: "100%",
                          width: "calc(50% - 7px)",
                          padding: ".375rem .75rem",
                        }}
                        name="price_value_month"
                        thousandSeparator={" "}
                        decimalSeparator={","}
                        decimalScale={2}
                        suffix={!this.props.isForeign && " ₽"}
                        value={
                          this.props.parentState.data.price_value_month !== null
                            ? this.props.parentState.data.price_value_month
                            : ""
                        }
                        onValueChange={(price) => {
                          const value =
                            price.value === "" ? null : price.floatValue;
                          this.props.setParentState({
                            price_value_month: value,
                          });
                        }}
                      />
                    </div>
                  </div>
                </div>
              ) : null}
              <div className="row mb-3">
                <div className="col-4">Период размещения</div>
                <div className="col-8">
                  <select
                    name="placement_value"
                    className="form-control"
                    value={
                      this.props.parentState.data.placement_value
                        ? this.props.parentState.data.placement_value
                        : ""
                    }
                    onChange={(e) => {
                      this.props.setParentState({
                        placement_value: +e.target.value,
                      });
                    }}
                  >
                    <option>Выбрать</option>
                    <option value={1}>Неделя</option>
                    <option value={2}>Месяц</option>
                    <option value={3}>5 дней</option>
                    <option value={4}>10 дней</option>
                    <option value={5}>15 дней</option>
                    <option value={6}>Сутки</option>
                    <option value={7}>21 день</option>
                  </select>
                </div>
              </div>
              <TargetingList {...this.props} />
              <div className="row mb-3">
                <div className="col-4">Показы</div>
                <div className="col-8">
                  <input
                    type="number"
                    step="1"
                    name="showing"
                    className="form-control"
                    value={
                      this.props.parentState.data.showing
                        ? this.props.parentState.data.showing
                        : ""
                    }
                    onChange={(e) => {
                      const value = +e.target.value;
                      this.props.setParentState({
                        showing: value,
                        clicks: (this.props.parentState.data.ctr * value) / 100,
                      });
                    }}
                  />
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-4">Минимальное количество показов</div>
                <div className="col-8">
                  <input
                    type="number"
                    step="1"
                    name="min_impressions"
                    className="form-control"
                    value={
                      this.props.parentState.data.min_impressions
                        ? this.props.parentState.data.min_impressions
                        : ""
                    }
                    onChange={(e) => {
                      const value = +e.target.value;
                      this.props.setParentState({
                        min_impressions: value,
                      });
                    }}
                  />
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-4">Клики</div>
                <div className="col-8">
                  <input
                    type="number"
                    name="clicks"
                    className="form-control"
                    value={
                      this.props.parentState.data.clicks
                        ? this.props.parentState.data.clicks
                        : ""
                    }
                    onChange={(e) => {
                      this.props.setParentState({
                        clicks: e.target.value,
                      });
                    }}
                    disabled={true}
                  />
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-4">CTR</div>
                <div className="col-8">
                  <input
                    type="number"
                    name="clicks"
                    className="form-control"
                    value={
                      this.props.parentState.data.ctr !== null
                        ? this.props.parentState.data.ctr
                        : ""
                    }
                    onChange={(e) => {
                      const value = +e.target.value;
                      this.props.setParentState({
                        ctr: value,
                        clicks:
                          (this.props.parentState.data.showing * value) / 100,
                      });
                    }}
                  />
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-4">Налогообложение</div>
                <div className="col-8">
                  <select
                    className="form-control"
                    value={
                      this.props.parentState.data.vat
                        ? this.props.parentState.data.vat
                        : ""
                    }
                    onChange={(e) => {
                      const value = +e.target.value;
                      this.props.setParentState({
                        vat: value,
                      });
                    }}
                  >
                    <option value="0" hidden>
                      Выбрать
                    </option>
                    <option value="1">с НДС</option>
                    <option value="2">без НДС</option>
                  </select>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-4">Комментарий</div>
                <div className="col-8">
                  <textarea
                    name="comment"
                    rows="4"
                    className="form-control"
                    value={
                      this.props.parentState.data.comment
                        ? this.props.parentState.data.comment
                        : ""
                    }
                    onChange={(e) => {
                      this.props.setParentState({
                        comment: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default InventoryBanner;
