import React from "react";
import { Mutation } from "react-apollo";
import gql from "graphql-tag";
import TemplateEditor from "./TemplateEditor";

const moment = require("moment/min/moment-with-locales.js");

function TemplateEditorContainer(props) {
  let sender = props.parentProps.parentProps.me
    ? props.parentProps.parentProps.me.email
    : "rn@deltaclick.ru";
  let partnerName =
    props.parentState.Briefing && props.parentState.Briefing.Partner
      ? props.parentState.Briefing.Partner.name
      : null;
  let platformName = props.MediaPlanItem.Platform
    ? props.MediaPlanItem.Platform.name
    : null;

  let startDate =
    props.parentState.Briefing && props.parentState.Briefing.dateStart
      ? moment(props.parentState.Briefing.dateStart)
      : moment();
  let endDate =
    props.parentState.Briefing && props.parentState.Briefing.dateEnd
      ? moment(props.parentState.Briefing.dateEnd)
      : moment();

  let date;

  if (endDate.month() !== startDate.month()) {
    date = startDate.format("DD.M.Y") + " - " + endDate.format("DD.M.Y");
  } else {
    date = startDate.format("DD.M.Y");
  }

  let subject = partnerName + " / " + date + " / " + platformName;

  let inventoryName = props.MediaPlanItem.InventoryItem
    ? props.MediaPlanItem.InventoryItem.name
    : "";
  let format = props.MediaPlanItem.InventoryItem
    ? props.MediaPlanItem.InventoryItem.format
    : "";
  let count = props.MediaPlanItem.purchase_count
    ? props.MediaPlanItem.purchase_count
    : "";
  let price_cost = props.MediaPlanItem.Info
    ? props.MediaPlanItem.Info.unit_price_cost
    : "";

  let mpStartDate = props.MediaPlanItem.startDate
    ? moment(props.MediaPlanItem.startDate).format("DD.M.Y")
    : startDate.format("DD.M.Y");
  let mpEndDate = props.MediaPlanItem.endDate
    ? moment(props.MediaPlanItem.startDate).format("DD.M.Y")
    : endDate.format("DD.M.Y");

  let recipient;

  if (
    props.MediaPlanItem.Platform &&
    props.MediaPlanItem.Platform.ContactsItems
  ) {
    const list = props.MediaPlanItem.Platform.ContactsItems;
    for (let i of list) {
      if (i.responsible === 1) recipient = i;
    }
  }

  let name = recipient ? recipient.name : "";

  let template =
    `<p>Добрый день, ${name}!</p>` +
    `<p>Прошу подтвердить расчет на размещение:</p>` +
    `<p>${inventoryName}, ${format} в период ${mpStartDate} - ${mpEndDate} в количестве ${count} шт. по цене прайса ${price_cost} руб.</p>` +
    "<p>Прошу дать прогнозную статистику по размещению на указанный период: количество прочтений, охват (для статьи/новости)/показы, клики, CTR, охват (для баннера). Так же, прошу приложить технические требования к позиции.</p>" +
    "<p>Спасибо!</p>" +
    "<p>Просьба не менять тему письма, а отвечать на это письмо, так как письма у нас распределяются по веткам в почтовом клиенте</p>";

  const [state, setState] = React.useState({
    subject,
    message: template,
    recipient: recipient ? recipient.email : false,
    copyRecipients: props.MediaPlanItem.Platform.ContactsItems.map(
      contact => contact.email
    ),
    sender
  });

  return (
    <div className="row">
      <div className="col">
        <div className="mb-1">
          <strong>Тема</strong>
          <br />
          <input
            type="text"
            className="border px-3"
            style={{
              width: "100%"
            }}
            value={state.subject}
            onChange={e => {
              setState({
                ...state,
                subject: e.target.value
              });
            }}
          />
        </div>
        <strong>Сообщение для отправки</strong>
        <br />
        <div className="border p-3">
          <TemplateEditor
            setParentState={newState => {
              setState({
                ...state,
                ...newState
              });
            }}
            template={template}
          />
        </div>
        <div className="btn-group mt-3">
          <Mutation
            mutation={gql`
              mutation(
                $recipient: String
                $message: String
                $subject: String
                $sender: String
                $copyRecipients: [String]
              ) {
                RnMpSendEmail(
                  recipient: $recipient
                  message: $message
                  subject: $subject
                  sender: $sender
                  copyRecipients: $copyRecipients
                )
              }
            `}
          >
            {(send, { data }) => (
              <button
                className="btn btn-success"
                onClick={() => {
                  if (!recipient) {
                    alert("Нет отвественного за пайсы или не указан его email");
                  } else {
                    send({
                      variables: {
                        recipient: state.recipient,
                        subject: state.subject,
                        message: state.message,
                        sender: state.sender,
                        copyRecipients: state.copyRecipients
                      }
                    }).then(res => {
                      alert(res.data.RnMpSendEmail);

                      props.updateFieldText({
                        variables: {
                          id: props.mpItem.id,
                          field: "price_query_date",
                          value: moment().format()
                        },
                        refetchQueries: ["RnMp", "RnMpItem"]
                      });
                      props.close();
                    });
                  }
                }}
              >
                Отправить запрос
              </button>
            )}
          </Mutation>
        </div>
      </div>
    </div>
  );
}
export default TemplateEditorContainer;
