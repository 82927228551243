import React from "react";
import Th from "./../../../../../components/styled/th";

export default function Error(props: any) {
  console.log(props.src_data.RnPlatform);

  return (
    <div className={"tab-content mb-3"}>
      <div className="row mt-3">
        <div className="col">
          <table>
            <thead>
              <tr>
                <Th>Раздел</Th>
                <Th>Статус</Th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Название</td>
                <td>
                  {props.data.name ? (
                    <span className="text-success">Все в порядке</span>
                  ) : (
                    <span className="text-danger">Ошибка</span>
                  )}
                </td>
              </tr>
              <tr>
                <td>Гео</td>
                <td>
                  {props.src_data.RnPlatform.CityList ||
                  props.src_data.RnPlatform.RegionList ? (
                    <span className="text-success">Все в порядке</span>
                  ) : (
                    <span className="text-danger">Ошибка</span>
                  )}
                </td>
              </tr>
              <tr>
                <td>Контакты</td>
                <td>
                  {props.src_data.RnPlatform.ContactsItems &&
                  props.src_data.RnPlatform.ContactsItems.length ? (
                    <span className="text-success">Все в порядке</span>
                  ) : (
                    <span className="text-danger">Ошибка</span>
                  )}
                </td>
              </tr>
              <tr>
                <td>Файл прайса</td>
                <td>
                  {props.src_data.RnPlatform.priceFile &&
                  props.src_data.RnPlatform.priceFile.length ? (
                    <span className="text-success">Все в порядке</span>
                  ) : (
                    <span className="text-danger">Ошибка</span>
                  )}
                </td>
              </tr>
              <tr>
                <td>Налогообложение</td>
                <td>
                  {props.src_data.RnPlatform.Price &&
                  props.src_data.RnPlatform.Price.vat !== null ? (
                    <span className="text-success">Все в порядке</span>
                  ) : (
                    <span className="text-danger">Ошибка</span>
                  )}
                </td>
              </tr>
              <tr>
                <td>Инвентарь</td>
                <td>
                  {props.src_data.RnPlatform.InventoryItems &&
                  props.src_data.RnPlatform.InventoryItems.length ? (
                    <span className="text-success">Все в порядке</span>
                  ) : (
                    <span className="text-danger">Ошибка</span>
                  )}
                </td>
              </tr>
              <tr>
                <td>Скидка ДП</td>
                <td>
                  {props.src_data.RnPlatform.DiscountItems &&
                  props.src_data.RnPlatform.DiscountItems.filter(
                    (d: any) => d.subject === 1
                  ).length ? (
                    <span className="text-success">Все в порядке</span>
                  ) : (
                    <span className="text-danger">Ошибка</span>
                  )}
                </td>
              </tr>
              <tr>
                <td>Сезонные коэффициенты</td>
                <td>
                  {props.src_data.RnPlatform.CoefficientItems &&
                  props.src_data.RnPlatform.CoefficientItems.length ? (
                    <span className="text-success">Все в порядке</span>
                  ) : (
                    <span className="text-danger">Ошибка</span>
                  )}
                </td>
              </tr>
              <tr>
                <td>Сроки оплаты</td>
                <td>
                  {props.src_data.RnPlatform.payment_delay &&
                  props.src_data.RnPlatform.payment_delay_value ? (
                    <span className="text-success">Все в порядке</span>
                  ) : (
                    <span className="text-danger">Ошибка</span>
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
