import React, { Component } from "react";
import AsyncSelect from "react-select/lib/Async";
import NumberFormat from "react-number-format";
import { ApolloConsumer } from "react-apollo";
import { graphql } from "react-apollo";
import Select from "react-select";
import CoefficientModal from "./../MediaPlan/MediaPlanItem/coefficients.modal.tsx";
import GreyTd from "../../../components/styled/greyTd";
import {
  InventoryField,
  GeoField,
  PartnerField,
  PurchaseUnitField,
  DiscountField,
  RequirementsField,
  VatField,
} from "./fields";

import {
  COPY_MP_ITEM,
  RN_MP_ITEM_UPDATE_FIELD,
  RN_MP_ITEM_UPDATE_FIELD_TEXT,
  RN_MP_ITEM_UPDATE_TARGETING_LIST,
} from "../../GraphQL/RNmutations";

import { Mutation } from "react-apollo";
import gql from "graphql-tag";

import InventoryListModal from "./../../../components/inventoryListModal";
import InventoryFastCreation from "./InventoryFastCreation";
import FormatModal from "./InventoryFormat/Modal";
import TemplateEditor from "./InventoryApplyModal/Modal";
import icon from "./chat.svg";

import ReportModal from "./MediaPlanItem/report.modal";
import coefficientDetector from "../../../functions/coefficientDetector";
import { Link } from "react-router-dom";
import { RN_MP_ITEM } from "../../GraphQL/RNqueries";
import getRating from "../../../functions/getRating";
import { ItemLogListDocument } from "../../../graphql/types";
import { Content } from "../../../components/ItemLogList/content";
import TextField from "@material-ui/core/TextField";
import { Box } from "@material-ui/core";
import { Requirements } from "../../../components/inventory/requirements";
import Alert from "@material-ui/lab/Alert";

const moment = require("moment/min/moment-with-locales.js");

class MediaPlanItemsListItem extends Component {
  /* global $*/

  state = {
    inventoryListModalIsOpen: false,
    reportModal: false,
    inventory_format_modal: false,
    coefficientModal: false,
    historyShow: false,
  };

  static getDerivedStateFromProps(props) {
    let updateForState = {};

    updateForState.MediaPlanItem = props.mpItem;
    updateForState.page_access =
      props.parentProps.parentProps.match.params.type;
    updateForState.is_change_comment = props.mpItem.is_change_comment;
    updateForState.inOrder = props.mpItem.inOrder;

    return updateForState;
  }

  componentDidMount() {
    let _this = this;
    $(`#daterangeselect-${_this.props.indexKey}`)
      .daterangepicker({
        startDate: _this.props.mpItem.startDate
          ? moment(_this.props.mpItem.startDate).format("DD.MM.YYYY")
          : _this.props.parentState.Briefing
          ? moment(_this.props.parentState.Briefing.dateStart).format(
              "DD.MM.YYYY"
            )
          : moment(),
        endDate: _this.props.mpItem.endDate
          ? moment(_this.props.mpItem.endDate).format("DD.MM.YYYY")
          : _this.props.parentState.Briefing
          ? moment(_this.props.parentState.Briefing.dateEnd).format(
              "DD.MM.YYYY"
            )
          : moment(),
        // minDate: _this.props.mpItem.startDate
        //   ? moment(_this.props.mpItem.startDate).format("DD.MM.YYYY")
        //   : _this.props.parentState.Briefing
        //   ? moment(_this.props.parentState.Briefing.dateStart).format(
        //       "DD.MM.YYYY"
        //     )
        //   : moment(),
        opens: "right",
        locale: {
          applyLabel: "Применить",
          cancelLabel: "Отменить",
        },
      })
      .on("apply.daterangepicker", function (e, picker) {
        _this.props
          .updateFieldText({
            variables: {
              id: _this.props.mpItem.id,
              field: "startDate",
              value: moment(picker.startDate, "DD-MM-YYYY").format(
                "YYYY-MM-DD"
              ),
            },
            refetchQueries: this.refetchQueries,
          })
          .then(() => {
            _this.props.updateFieldText({
              variables: {
                id: _this.props.mpItem.id,
                field: "endDate",
                value: moment(picker.endDate, "DD-MM-YYYY").format(
                  "YYYY-MM-DD"
                ),
              },
              refetchQueries: this.refetchQueries,
            });
          });
      });
  }

  refetchQueries = [
    "RnMp",
    "RnMpItemList",
    "rnMp",
    {
      query: RN_MP_ITEM,
      variables: { id: this.props.mpItem.id },
    },
  ];

  coefficientRender(coefficient) {
    return (
      <div
        style={{
          position: "relative",
        }}
        onMouseEnter={() => {
          this.setState({
            ...this.state,
            coefficientModal: true,
          });
        }}
        onMouseLeave={() => {
          this.setState({
            ...this.state,
            coefficientModal: false,
          });
        }}
      >
        <div
          style={{
            background: "#f5ec3c",
            color: "#333333",
            padding: "1px 5px 1px 5px",
          }}
        >
          Сезонник {coefficient}
          <label>
            <input
              type="checkbox"
              checked={this.state.MediaPlanItem.coefficient_off}
              onChange={(e) => {
                const value = e.target.checked ? 1 : 0;

                this.props.updateField({
                  variables: {
                    id: this.props.mpItem.id,
                    field: "coefficient_off",
                    value: value,
                  },
                  refetchQueries: this.refetchQueries,
                });
              }}
            />{" "}
            Отключить
          </label>
        </div>

        <a href="#" onClick={(e) => e.preventDefault()}>
          Сезонные коэффициенты
        </a>

        {this.state.MediaPlanItem.Platform &&
        this.state.MediaPlanItem.Platform.CoefficientItems &&
        this.state.coefficientModal ? (
          <CoefficientModal
            list={[...this.state.MediaPlanItem.Platform.CoefficientItems]}
          />
        ) : null}
      </div>
    );
  }

  handlePurchaseCount(e) {
    let value = +e.target.value;
    this.setState({
      ...this.state,
      MediaPlanItem: {
        ...this.state.MediaPlanItem,
      },
      purchase_count: value,
    });

    setTimeout(() => {
      if (
        this.state.MediaPlanItem &&
        this.state.MediaPlanItem.InventoryItem &&
        this.state.MediaPlanItem.InventoryItem.priceType === 2 &&
        this.state.MediaPlanItem.InventoryItem.min_impressions
      ) {
        if (
          this.state.purchase_count <
          this.state.MediaPlanItem.InventoryItem.min_impressions / 1000
        ) {
          this.setState({
            ...this.state,
            MediaPlanItem: {
              ...this.state.MediaPlanItem,
            },
            purchase_count:
              this.state.MediaPlanItem.InventoryItem.min_impressions / 1000,
          });

          this.props.updateField({
            variables: {
              id: this.props.mpItem.id,
              field: "purchase_count",
              value:
                this.state.MediaPlanItem.InventoryItem.min_impressions / 1000,
            },
            refetchQueries: this.refetchQueries,
          });
        } else {
          this.props.updateField({
            variables: {
              id: this.props.mpItem.id,
              field: "purchase_count",
              value: this.state.purchase_count,
            },
            refetchQueries: this.refetchQueries,
          });
        }
      } else {
        this.props.updateField({
          variables: {
            id: this.props.mpItem.id,
            field: "purchase_count",
            value: value,
          },
          refetchQueries: this.refetchQueries,
        });
      }
    }, 1000);
  }

  render() {
    const inventoryReqShow = this.props.parentProps.parentState
      .inventoryReqShow;

    let requestDisabled = true;
    const orderExport = this.props.parentProps.parentState.orderExport;

    if (
      this.state.MediaPlanItem.Platform &&
      this.state.MediaPlanItem.Platform.ContactsItems &&
      this.state.MediaPlanItem.Platform.ContactsItems.length &&
      this.state.MediaPlanItem.Platform.ContactsItems.some((item) => item.email)
    ) {
      requestDisabled = false;
    }

    let targetingOptions = [];

    if (
      this.state.MediaPlanItem.InventoryItem &&
      this.state.MediaPlanItem.InventoryItem.Targeting
    ) {
      targetingOptions = [
        ...this.state.MediaPlanItem.InventoryItem.Targeting.map((item) => ({
          value: item.id,
          label: `${item.name} x ${item.coefficient}`,
        })),
      ];
    }

    if (
      this.state.MediaPlanItem.InventoryItem &&
      this.state.MediaPlanItem.InventoryItem.GlobalTargeting
    ) {
      targetingOptions = [
        ...targetingOptions,
        ...this.state.MediaPlanItem.InventoryItem.GlobalTargeting.map(
          (item) => ({
            value: item.id,
            label: `${item.name} x ${item.coefficient}`,
          })
        ),
      ];
    }

    let coefficientRender = false;
    let coefficient = null;

    if (
      this.state.MediaPlanItem.Platform &&
      this.state.MediaPlanItem.Platform.CoefficientItems.length
    ) {
      if (this.state.MediaPlanItem.endDate) {
        coefficient = coefficientDetector(
          this.state.MediaPlanItem.endDate,
          this.state.MediaPlanItem.Platform.CoefficientItems
        );
      } else if (
        this.props.parentState.Briefing &&
        this.props.parentState.Briefing.dateEnd
      ) {
        coefficient = coefficientDetector(
          this.props.parentState.Briefing.dateEnd,
          this.state.MediaPlanItem.Platform.CoefficientItems
        );
      }
    }

    if (coefficient) {
      coefficientRender = true;
    }

    if (
      this.state.MediaPlanItem.InventoryItem &&
      this.state.MediaPlanItem.InventoryItem.coefficient_off === 1
    ) {
      coefficientRender = false;
    }

    if (this.state.MediaPlanItem.coefficient_off === 1) {
      //coefficientRender = false;
    }

    //стили для селекта площадок
    const selectStyle = {
      menuList: () => ({
        minWidth: 450,
        background: "#fff",
      }),
      menu: () => ({
        boxShadow: "none",
        position: "absolute",
        border: "1px solid #dadada",
        zIndex: "2",
      }),
    };

    let client_vat = null;
    if (
      this.props.parentState.Briefing &&
      this.props.parentState.Briefing.vat === 1 &&
      this.props.parentState.Briefing.no_vat === 1
    ) {
      client_vat = "с НДС / без НДС";
    } else {
      if (
        this.props.parentState.Briefing &&
        this.props.parentState.Briefing.vat === 1
      ) {
        client_vat = "c НДС";
      } else if (
        this.props.parentState.Briefing &&
        this.props.parentState.Briefing.no_vat === 1
      ) {
        client_vat = "без НДС";
      }
    }

    if (
      this.state.MediaPlanItem.InventoryItem &&
      this.state.MediaPlanItem.InventoryItem.vat &&
      this.state.MediaPlanItem.InventoryItem.vat === 1 &&
      this.props.parentState.Briefing &&
      this.props.parentState.Briefing.vat === 1 &&
      this.props.parentState.Briefing.no_vat === 1
    ) {
      client_vat = "c НДС";
    }
    if (
      this.state.MediaPlanItem.InventoryItem &&
      this.state.MediaPlanItem.InventoryItem.vat &&
      this.state.MediaPlanItem.InventoryItem.vat === 2 &&
      this.props.parentState.Briefing &&
      this.props.parentState.Briefing.vat === 1 &&
      this.props.parentState.Briefing.no_vat === 1
    ) {
      client_vat = "без НДС";
    }

    if (
      this.state.MediaPlanItem.vat === 1 &&
      this.props.parentState.Briefing &&
      this.props.parentState.Briefing.no_vat === 1 &&
      this.props.parentState.Briefing.vat === 1
    ) {
      client_vat = "c НДС";
    }
    if (
      this.state.MediaPlanItem.vat === 2 &&
      this.props.parentState.Briefing &&
      this.props.parentState.Briefing.no_vat === 1 &&
      this.props.parentState.Briefing.vat === 1
    ) {
      client_vat = "без НДС";
    }

    let format = null;
    if (this.state.MediaPlanItem.format) {
      format = this.state.MediaPlanItem.format;
    } else if (
      this.state.MediaPlanItem.InventoryItem &&
      this.state.MediaPlanItem.InventoryItem.format
    ) {
      format = this.state.MediaPlanItem.InventoryItem.format;
    } else if (
      this.state.MediaPlanItem.InventoryItem &&
      this.state.MediaPlanItem.InventoryItem.type === "special"
    ) {
      format = this.state.MediaPlanItem.InventoryItem.comment;
    } else if (
      this.state.MediaPlanItem.InventoryItem &&
      this.state.MediaPlanItem.InventoryItem.publication_format &&
      this.state.MediaPlanItem.InventoryItem.publication_format === 1
    ) {
      format = "Новость";
    } else if (
      this.state.MediaPlanItem.InventoryItem &&
      this.state.MediaPlanItem.InventoryItem.publication_format &&
      this.state.MediaPlanItem.InventoryItem.publication_format === 2
    ) {
      format = "Статья";
    } else if (
      this.state.MediaPlanItem.InventoryItem &&
      this.state.MediaPlanItem.InventoryItem.publication_format &&
      this.state.MediaPlanItem.InventoryItem.publication_format === 3
    ) {
      format = "Репортаж";
    }

    let values_for_geo_filter = [];

    if (
      this.props.parentState.Briefing &&
      this.props.parentState.Briefing.FederalDistricts
    ) {
      this.props.parentState.Briefing.FederalDistricts.forEach((item) => {
        values_for_geo_filter = [...values_for_geo_filter, item.Code];

        if (item.FederalSubjects) {
          item.FederalSubjects.forEach((item) => {
            values_for_geo_filter = [...values_for_geo_filter, item.AOGUID];

            if (item.Localities) {
              item.Localities.forEach((item) => {
                values_for_geo_filter = [...values_for_geo_filter, item.AOGUID];
              });
            }
          });
        }
      });
    }

    let impressions = this.state.MediaPlanItem.showing
      ? this.state.MediaPlanItem.showing
      : this.state.MediaPlanItem.InventoryItem
      ? this.state.MediaPlanItem.InventoryItem.showing
      : null;

    let disabled = this.props.disabled;

    const isForeign =
      this.state.MediaPlanItem.Platform &&
      this.state.MediaPlanItem.Platform.isForeign
        ? true
        : false;

    return (
      <>
        <tr>
          {orderExport ? (
            <>
              <td>
                <input
                  type="checkbox"
                  checked={this.state.MediaPlanItem.inOrder ? true : false}
                  onChange={(e) => {
                    this.props
                      .updateField({
                        variables: {
                          id: this.props.mpItem.id,
                          field: "inOrder",
                          value: e.target.checked ? 1 : 0,
                        },
                        refetchQueries: this.refetchQueries,
                      })
                      .then(() =>
                        this.setState({
                          ...this.state,
                        })
                      );
                  }}
                />
              </td>
              <td>{this.state.MediaPlanItem.orderID}</td>
              <td>
                {this.state.MediaPlanItem.orderDate
                  ? new Date(
                      this.state.MediaPlanItem.orderDate
                    ).toLocaleDateString()
                  : null}
              </td>
            </>
          ) : null}
          <td className="close-icon small-width" data-name="delete">
            {disabled ? null : (
              <Mutation
                mutation={gql`
                  mutation deleteItem($id: Int!) {
                    Rn {
                      deleteItemSoft(itemID: $id)
                    }
                  }
                `}
              >
                {(removeMpItem) => (
                  <i
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={(e) => {
                      e.preventDefault();
                      removeMpItem({
                        variables: {
                          id: this.props.mpItem.id,
                        },
                        refetchQueries: this.refetchQueries,
                      });
                    }}
                    className="text-danger fas fa-times fa-2x"
                  />
                )}
              </Mutation>
            )}

            <Mutation mutation={COPY_MP_ITEM}>
              {(copy) => (
                <i
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    copy({
                      variables: {
                        itemID: this.props.mpItem.id,
                      },
                      refetchQueries: this.refetchQueries,
                    });
                  }}
                  className="fas fa-copy fa-2x text-warning ml-2"
                />
              )}
            </Mutation>
          </td>
          <td data-name="geo" className="lighblue-light-col">
            <GeoField
              geo={this.state.MediaPlanItem?.geo}
              isForeign={this.state.MediaPlanItem.Platform?.isForeign}
              isFederal={this.state.MediaPlanItem.Platform?.federal}
              cityList={this.state.MediaPlanItem.Platform?.CityList}
              citySelected={this.state.MediaPlanItem.city}
              disabled={disabled}
              onChange={(geo, fieldName) => {
                this.props.updateFieldText({
                  variables: {
                    id: this.props.mpItem.id,
                    field: fieldName,
                    value: geo,
                  },
                  refetchQueries: this.refetchQueries,
                });

                if (fieldName === "city") {
                  this.props.updateFieldText({
                    variables: {
                      id: this.props.mpItem.id,
                      field: "geo",
                      value: null,
                    },
                    refetchQueries: this.refetchQueries,
                  });
                } else {
                  this.props.updateFieldText({
                    variables: {
                      id: this.props.mpItem.id,
                      field: "city",
                      value: null,
                    },
                    refetchQueries: this.refetchQueries,
                  });
                }
              }}
            />
          </td>
          <td
            className="lighblue-light-col"
            data-name="platform_id"
            style={{
              textAlign: "left",
            }}
          >
            {disabled ? (
              <>
                {this.props.mpItem.Platform && this.props.mpItem.Platform.name}
              </>
            ) : (
              <>
                {this.props.parentState.Briefing ? (
                  <ApolloConsumer>
                    {(client) => (
                      <AsyncSelect
                        styles={selectStyle}
                        //defaultOptions ={ this.props.parentState.Briefing && this.props.parentState.Briefing.cities ? true : false }
                        defaultOptions={true}
                        loadOptions={(inputValue) => {
                          let items = [];

                          let filter = {
                            filter: [
                              {
                                values: [inputValue],
                                operator: "LIKE",
                                field: "name",
                              },
                              {
                                values: ["0"],
                                operator: "EQ",
                                field: "hidden",
                              },
                            ],
                          };

                          if (this.props.parentState.Briefing) {
                            filter = {
                              filter: [
                                {
                                  values: [inputValue],
                                  operator: "LIKE",
                                  field: "name",
                                },
                                {
                                  values: values_for_geo_filter,
                                  operator: "IN",
                                  field: "geo",
                                },
                                {
                                  values: ["0"],
                                  operator: "EQ",
                                  field: "hidden",
                                },
                              ],
                            };
                          }

                          return new Promise((resolve) => {
                            client
                              .query({
                                query: gql`
                                  query RnPlatformList($params: ParamsInput) {
                                    RnPlatformList(params: $params) {
                                      data {
                                        id
                                        name
                                        adequate
                                        adequate_comment
                                        payment_delay
                                        direct_client_rule
                                        CityList {
                                          AOGUID
                                          FORMALNAME
                                        }
                                      }
                                    }
                                  }
                                `,
                                variables: {
                                  params: {
                                    offset: 0,
                                    count: 150,
                                    ...filter,
                                  },
                                },
                                fetchPolicy: "no-cache",
                              })
                              .then((result) => {
                                items = [];
                                for (let i of result.data["RnPlatformList"]
                                  .data) {
                                  let obj = {};
                                  obj.value = i.id;
                                  obj.label = i.name;

                                  let direct_client_rule = i.direct_client_rule;
                                  let adequate = "";

                                  if (i.adequate === 3)
                                    adequate = (
                                      <div>
                                        <span
                                          style={{
                                            background: "green",
                                            width: "15px",
                                            height: "15px",
                                            display: "inline-block",
                                          }}
                                        ></span>
                                        &nbsp;
                                        <strong>{i.adequate_comment}</strong>
                                      </div>
                                    );

                                  if (i.adequate === 2)
                                    adequate = (
                                      <div>
                                        <span
                                          style={{
                                            background: "yellow",
                                            width: "15px",
                                            height: "15px",
                                            display: "inline-block",
                                          }}
                                        ></span>
                                        &nbsp;
                                        <strong>{i.adequate_comment}</strong>
                                      </div>
                                    );

                                  if (i.adequate === 1)
                                    adequate = (
                                      <div>
                                        <span
                                          style={{
                                            background: "red",
                                            width: "15px",
                                            height: "15px",
                                            display: "inline-block",
                                          }}
                                        ></span>
                                        &nbsp;
                                        <strong>{i.adequate_comment}</strong>
                                      </div>
                                    );

                                  obj.label = (
                                    <div className="d-flex justify-content-between text-left">
                                      <div>
                                        {i.name}&nbsp; &nbsp;
                                        {i.CityList &&
                                          i.CityList.map(
                                            (city) => city.FORMALNAME
                                          ).join(", ")}
                                      </div>
                                      {adequate}
                                      {direct_client_rule}
                                    </div>
                                  );

                                  items.push(obj);
                                }

                                resolve();
                              });
                          }).then(() => {
                            if (!items.length) {
                              return [
                                {
                                  label: (
                                    <>
                                      <strong className="text-center">
                                        площадка не найдена, добавить
                                      </strong>
                                    </>
                                  ),
                                  value: "open.window",
                                },
                              ];
                            }

                            return items;
                          });
                        }}
                        value={
                          this.props.mpItem.Platform
                            ? {
                                value: this.props.mpItem.Platform.id,
                                label: this.props.mpItem.Platform.name,
                              }
                            : {}
                        }
                        onChange={(platform_id) => {
                          const value = platform_id.value;

                          if (value !== "open.window") {
                            this.props.updateField({
                              variables: {
                                id: this.props.mpItem.id,
                                field: "platform_id",
                                value: value,
                              },
                              refetchQueries: this.refetchQueries,
                            });
                          } else {
                            this.props.setParentState({
                              modal: true,
                            });
                          }
                        }}
                      />
                    )}
                  </ApolloConsumer>
                ) : (
                  "не выбран или не заполнен бриф"
                )}
              </>
            )}
            {this.props.mpItem.Platform && (
              <Link to={"/platform/update/" + this.props.mpItem.Platform.id}>
                Редактировать
              </Link>
            )}
          </td>

          <td data-name="inventory-name" className="lighblue-light-col">
            <InventoryField
              inventoryName={this.state.MediaPlanItem.inventoryName}
              onChange={(value) => {
                this.props.updateFieldText({
                  variables: {
                    id: this.props.mpItem.id,
                    field: "inventoryName",
                    value,
                  },
                  refetchQueries: this.refetchQueries,
                });
              }}
              onDelete={() => {
                this.props.updateField({
                  variables: {
                    id: this.props.mpItem.id,
                    field: "inventory_id",
                    value: null,
                  },
                  refetchQueries: this.refetchQueries,
                });
              }}
              onShowModal={() => {
                this.setState({
                  inventoryListModalIsOpen: true,
                });
              }}
              onFastCreate={() => {
                this.setState({
                  fast_creation: true,
                });
              }}
              disabled={!this.state.MediaPlanItem.Platform || disabled}
            />

            {this.props.parentProps.parentState.historyShow && (
              <ApolloConsumer>
                {(client) => (
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      client
                        .query({
                          query: ItemLogListDocument,
                          variables: {
                            platformID: this.state.MediaPlanItem?.Platform
                              ? [this.state.MediaPlanItem?.Platform.id]
                              : null,
                            inventoryID: this.state.MediaPlanItem?.InventoryItem
                              ? [this.state.MediaPlanItem?.InventoryItem.id]
                              : null,
                          },
                        })
                        .then((res) => {
                          this.setState({
                            ...this.state,
                            itemLogList: res?.data?.RN?.itemLogList,
                            historyShow: !this.state.historyShow,
                          });
                        });
                    }}
                  >
                    История закупа
                  </a>
                )}
              </ApolloConsumer>
            )}
          </td>
          {this.props.scoringShow ? (
            <td className="lighblue-light-col t">
              {this.state.MediaPlanItem?.InventoryItem?.rating &&
                getRating(this.state.MediaPlanItem?.InventoryItem?.rating)}
            </td>
          ) : null}
          <td
            data-name="inventory-format"
            className="lighblue-light-col"
            style={
              format && format.length > 130
                ? {
                    minWidth: "212px",
                  }
                : null
            }
          >
            {format}

            {disabled ? null : (
              <>
                {this.state.MediaPlanItem.InventoryItem ? (
                  <>
                    <br />
                    <button
                      disabled={disabled}
                      className="btn btn-sm btn-warning"
                      onClick={() => {
                        this.setState({
                          ...this.state,
                          inventory_format_modal: !this.state
                            .inventory_format_modal,
                        });
                      }}
                    >
                      Свой формат
                    </button>
                  </>
                ) : null}
              </>
            )}
          </td>
          <td data-name="targeting" className="lighblue-light-col">
            {disabled ? (
              <>
                {this.state.MediaPlanItem.targetingList &&
                  this.state.MediaPlanItem.targetingList
                    .map((item) => item.name)
                    .join(", ")}
              </>
            ) : (
              <Select
                isMulti
                isClearable
                placeholder={"Выбрать"}
                defaultValue={
                  this.state.MediaPlanItem.targetingList
                    ? this.state.MediaPlanItem.targetingList.map((item) => ({
                        label: `${item.name} ${item.coefficient}`,
                        value: item.id,
                      }))
                    : null
                }
                options={targetingOptions}
                onChange={(targeting) => {
                  const value = targeting.map((item) => item.value);
                  this.props.updateTargeting({
                    variables: {
                      id: this.props.mpItem.id,
                      field: "targeting",
                      value,
                    },
                    refetchQueries: this.refetchQueries,
                  });
                }}
              />
            )}
          </td>
          <td data-name="date" className="lighblue-light-col">
            <input
              id={`daterangeselect-${this.props.indexKey}`}
              className="form-control"
              disabled={disabled}
            />
          </td>
          <td
            data-name="purchaseUnit"
            className="bordered"
            style={{
              minWidth: "50px",
            }}
          >
            <PurchaseUnitField
              inventoryItem={this.state.MediaPlanItem.InventoryItem}
              onChange={(value) => {
                this.props.updateField({
                  variables: {
                    id: this.props.mpItem.id,
                    field: "price_type",
                    value,
                  },
                  refetchQueries: this.refetchQueries,
                });
              }}
              price_type={this.state.MediaPlanItem?.price_type}
              disabled={disabled}
            />
          </td>
          <td
            data-name="purchase_count"
            className="bordered"
            style={{
              minWidth: "50px",
            }}
          >
            <Box my={1}>
              <TextField
                label={false}
                type="number"
                size="small"
                variant="outlined"
                inputProps={{
                  style: { padding: "5px", fontSize: "12px" },
                }}
                disabled={disabled}
                onBlur={(e) => {
                  const value = e.target.value;

                  this.props.updateField({
                    variables: {
                      id: this.props.mpItem.id,
                      field: "purchase_count",
                      value,
                    },
                    refetchQueries: this.refetchQueries,
                  });
                }}
                defaultValue={this.state.MediaPlanItem.purchase_count || 1}
              />
            </Box>

            {this.state.MediaPlanItem.InventoryItem &&
              this.state.MediaPlanItem.InventoryItem.priceType === 2 &&
              this.state.MediaPlanItem.InventoryItem.min_impressions && (
                <>
                  мин. закуп{" "}
                  {this.state.MediaPlanItem.InventoryItem.min_impressions}
                </>
              )}
          </td>
          <td data-name="unit_cost_nov_vat">
            <TextField
              type="number"
              size="small"
              variant="outlined"
              disabled={disabled}
              inputProps={{
                style: { padding: "5px", fontSize: "12px" },
              }}
              onChange={({ target: { value } }) => {
                this.props.updateField({
                  variables: {
                    id: this.props.mpItem.id,
                    field: "unit_cost",
                    value: +value,
                  },
                  refetchQueries: this.refetchQueries,
                });
              }}
              value={this.state.MediaPlanItem?.unitCostNoVat || ""}
            />

            {coefficientRender && this.coefficientRender(coefficient)}
          </td>
          <td data-name="discount" className="lighblue-light-col small-width">
            <DiscountField
              disabled={disabled}
              value={this.state.MediaPlanItem?.discount}
              onChange={(value) => {
                this.props.updateField({
                  variables: {
                    id: this.props.mpItem.id,
                    field: "discount",
                    value,
                  },
                  refetchQueries: this.refetchQueries,
                });
              }}
            />

            {this.state.MediaPlanItem.discountComment ? (
              <p>{this.state.MediaPlanItem.discountComment}</p>
            ) : null}
          </td>
          <td
            className="bordered"
            style={{
              position: "relative",
              minWidth: "50px",
            }}
          >
            {this.state.MediaPlanItem.Platform &&
            this.state.MediaPlanItem.Platform.Price &&
            this.state.MediaPlanItem.Platform.Price.comment_2 ? (
              <img
                className="img_hover"
                src={icon}
                style={{ height: "40px" }}
                alt=""
                title=""
                onMouseEnter={() => {
                  this.setState({
                    commentRender: true,
                  });
                }}
                onMouseLeave={() => {
                  this.setState({
                    commentRender: false,
                  });
                }}
              />
            ) : null}

            {this.state.commentRender && (
              <p
                style={{
                  position: "absolute",
                  width: "300%",
                  right: "0",
                  zIndex: 9999,
                  background: "#ffffff",
                  boxShadow: "0 24px 60px 0 rgba(0, 0, 0, 0.12)",
                  borderRadius: "5px",
                  padding: "10px",
                }}
              >
                {this.state.MediaPlanItem.Platform &&
                  this.state.MediaPlanItem.Platform.Price &&
                  this.state.MediaPlanItem.Platform.Price.comment_2}
              </p>
            )}
          </td>

          <td data-name="price&discount_novat" className="lighblue-light-col">
            {this.state.MediaPlanItem.Platform &&
            this.state.MediaPlanItem.totalCostNoVatDiscount ? (
              <NumberFormat
                displayType="text"
                thousandSeparator={" "}
                decimalSeparator={","}
                decimalScale={2}
                suffix={!isForeign && " ₽"}
                value={this.state.MediaPlanItem.totalCostNoVatDiscount}
              />
            ) : (
              ""
            )}
          </td>
          <td data-name="vat" className="lighblue-light-col small-width">
            <VatField
              vat={
                this.state.MediaPlanItem?.vat ||
                this.state.MediaPlanItem?.InventoryItem?.vat
              }
              isMulti={+this.state.MediaPlanItem?.Platform?.Price?.vat === 4}
              onChange={(vat) => {
                this.props.updateField({
                  variables: {
                    id: this.props.mpItem.id,
                    field: "vat",
                    value: vat,
                  },
                  refetchQueries: this.refetchQueries,
                });
              }}
            />
          </td>
          <td data-name="client_vat" className="lighblue-light-col small-width">
            {client_vat}
          </td>
          <td data-name="totalCost" className="lighblue-light-col">
            {this.state.MediaPlanItem.totalCostVatDiscount ? (
              <NumberFormat
                displayType="text"
                thousandSeparator={" "}
                decimalSeparator={","}
                decimalScale={2}
                suffix={!isForeign && " ₽"}
                value={this.state.MediaPlanItem.totalCostVatDiscount}
              />
            ) : null}
          </td>
          <td
            data-name="ctr"
            style={{
              position: "relative",
            }}
            className="lighblue-light-col small-width"
          >
            <input
              name="ctr"
              type="text"
              className="form-control"
              disabled={disabled}
              value={
                this.state.MediaPlanItem &&
                impressions &&
                this.state.MediaPlanItem.clicks
                  ? (this.state.MediaPlanItem.clicks / impressions) * 100
                  : ""
              }
              onChange={(e) => {
                const value = +e.target.value;
                this.props.updateField({
                  variables: {
                    id: this.props.mpItem.id,
                    field: "ctr",
                    value: value,
                  },
                  refetchQueries: this.refetchQueries,
                });
              }}
            />

            <a
              href="#"
              id={this.state.MediaPlanItem.id}
              onClick={(e) => {
                e.preventDefault();
                this.setState({
                  ...this.state,
                  reportModal: !this.state.reportModal,
                });
              }}
            >
              История отчетов
            </a>
            {this.state.reportModal ? (
              <ReportModal
                inventoryID={this.state.MediaPlanItem.inventory_id}
                setClose={() => {
                  this.setState({
                    ...this.state,
                    reportModal: false,
                  });
                }}
              />
            ) : null}
          </td>
          <td data-name="clicks" className="lighblue-light-col small-width">
            <input
              type="text"
              name="clicks"
              disabled={disabled}
              className="form-control"
              defaultValue={
                this.state.MediaPlanItem.clicks !== null
                  ? this.state.MediaPlanItem.clicks
                  : this.state.MediaPlanItem.InventoryItem &&
                    this.state.MediaPlanItem.InventoryItem.clicks !== null
                  ? this.state.MediaPlanItem.InventoryItem.clicks
                  : ""
              }
              onChange={(e) => {
                const value = +e.target.value;
                this.props.updateField({
                  variables: {
                    id: this.props.mpItem.id,
                    field: "clicks",
                    value: value,
                  },
                  refetchQueries: this.refetchQueries,
                });
              }}
            />
          </td>
          <td data-name="showing" className="lighblue-light-col small-width">
            <input
              name="impressions"
              type="text"
              className="form-control"
              disabled={disabled}
              defaultValue={
                this.state.MediaPlanItem.showing !== null
                  ? this.state.MediaPlanItem.showing
                  : this.state.MediaPlanItem.InventoryItem &&
                    this.state.MediaPlanItem.InventoryItem.showing !== null
                  ? this.state.MediaPlanItem.InventoryItem.showing
                  : ""
              }
              onChange={(e) => {
                const value = +e.target.value;
                this.props.updateField({
                  variables: {
                    id: this.props.mpItem.id,
                    field: "showing",
                    value,
                  },
                  refetchQueries: this.refetchQueries,
                });
              }}
            />
          </td>
          {this.props.publication_in_table && (
            <td data-name="view" className="lighblue-light-col small-width">
              {this.state.MediaPlanItem.InventoryItem &&
                this.state.MediaPlanItem.InventoryItem.type ===
                  "publication" && (
                  <input
                    type="number"
                    className="form-control"
                    disabled={disabled}
                    defaultValue={
                      this.state.MediaPlanItem.view &&
                      this.state.MediaPlanItem.view
                    }
                    onChange={(e) => {
                      const value = +e.target.value;
                      this.props.updateField({
                        variables: {
                          id: this.props.mpItem.id,
                          field: "view",
                          value: value,
                        },
                        refetchQueries: this.refetchQueries,
                      });
                    }}
                  />
                )}
            </td>
          )}
          <td data-name="cpm" className="lighblue-dark-col small-width">
            {this.state.MediaPlanItem.showing &&
            this.state.MediaPlanItem.totalCostNoVatDiscount
              ? (
                  (this.state.MediaPlanItem.totalCostNoVatDiscount /
                    this.state.MediaPlanItem.showing) *
                  1000
                )?.toFixed(2)
              : ""}
          </td>
          <td data-name="cpc" className="lighblue-dark-col small-width">
            {this.state.MediaPlanItem.cpc
              ? this.state.MediaPlanItem.cpc?.toFixed(2)
              : ""}
          </td>
          {this.props.publication_in_table && (
            <td data-name="view" className="lighblue-dark-col small-width">
              {this.state.MediaPlanItem.viewCost && (
                <NumberFormat
                  displayType="text"
                  thousandSeparator={" "}
                  decimalSeparator={","}
                  decimalScale={2}
                  suffix={!isForeign && " ₽"}
                  value={this.state.MediaPlanItem.viewCost}
                />
              )}
            </td>
          )}
          <td
            className="lightred-col small-width"
            data-name="discount_purchase"
          >
            <DiscountField
              disabled={disabled}
              value={this.state.MediaPlanItem?.discount_purchase}
              onChange={(value) => {
                this.props.updateField({
                  variables: {
                    id: this.props.mpItem.id,
                    field: "discount_purchase",
                    value: value,
                  },
                  refetchQueries: this.refetchQueries,
                });
              }}
            />
          </td>
          <td
            data-name="purchase_cost_novat"
            className="lightred-col small-width"
          >
            {this.state.MediaPlanItem.totalCostPurchaseDiscount !== null ? (
              <NumberFormat
                displayType="text"
                thousandSeparator={" "}
                decimalSeparator={","}
                decimalScale={2}
                suffix={!isForeign && " ₽"}
                value={this.state.MediaPlanItem.totalCostPurchaseDiscount}
              />
            ) : (
              "0.00"
            )}
          </td>
          <td data-name="profitability" className="lightred-col small-width">
            {this.state.MediaPlanItem.profitability !== null ? (
              <>{this.state.MediaPlanItem.profitability?.toFixed(2)} %</>
            ) : (
              ""
            )}
          </td>
          <td data-name="margin" className="lightred-col">
            {this.state.MediaPlanItem.margin ? (
              <NumberFormat
                displayType="text"
                thousandSeparator={" "}
                decimalSeparator={","}
                decimalScale={2}
                suffix={!isForeign && " ₽"}
                value={this.state.MediaPlanItem.margin}
              />
            ) : "0" + isForeign ? (
              ""
            ) : (
              " ₽"
            )}
          </td>
          <td data-name="platform-partner" className="lightred-col">
            <PartnerField
              platform={this.props.mpItem.Platform}
              mPlanID={this.state.MediaPlanItem.id}
            />
          </td>
          <td
            className={
              this.state.MediaPlanItem.status === 1
                ? "lightgreen-col"
                : "bg-danger"
            }
          >
            <select
              style={{
                height: "25px",
              }}
              className="form-control"
              value={
                this.state.MediaPlanItem.status
                  ? this.state.MediaPlanItem.status
                  : ""
              }
              disabled={disabled}
              onChange={(e) => {
                const value = +e.target.value;
                setTimeout(() => {
                  this.props.updateField({
                    variables: {
                      id: this.props.mpItem.id,
                      field: "status",
                      value: value,
                    },
                    refetchQueries: this.refetchQueries,
                  });
                }, 1000);
              }}
            >
              <option>Выбрать статус</option>
              <option value={1}>Подтверждено</option>
              <option value={2}>Не подтверждено</option>
            </select>
          </td>
          <td>
            <button
              className="btn btn-warning"
              disabled={disabled || requestDisabled}
              onClick={() => {
                if (!this.props.parentState.Briefing) {
                  alert("Недостаточно данных для отправки сообщения!");
                } else {
                  this.setState({
                    ...this.state,
                    inventory_apply_modal: true,
                  });
                }
              }}
            >
              Отправить запрос
            </button>
            {this.state.MediaPlanItem.price_query_date ? (
              <p>
                Запрос отправлен:{" "}
                {moment(this.state.MediaPlanItem.price_query_date).format(
                  "DD.M.Y HH:mm"
                )}
              </p>
            ) : null}
          </td>
          <td>
            {this.state.MediaPlanItem.Platform &&
              this.state.MediaPlanItem.Platform.payment_delay === 1 &&
              "С Даты Финиш"}
            {this.state.MediaPlanItem.Platform &&
              this.state.MediaPlanItem.Platform.payment_delay === 2 &&
              "С начала месяца Даты Финиш"}
            {this.state.MediaPlanItem.Platform &&
              this.state.MediaPlanItem.Platform.payment_delay === 3 &&
              "С конца месяца Даты Финиш"}
            {this.state.MediaPlanItem.Platform &&
              this.state.MediaPlanItem.Platform.payment_delay === 4 &&
              "С Даты Старт"}
            {this.state.MediaPlanItem.Platform &&
              this.state.MediaPlanItem.Platform.payment_delay === 5 &&
              "С начала месяца Даты Старт"}
            {this.state.MediaPlanItem.Platform &&
              this.state.MediaPlanItem.Platform.payment_delay === 6 &&
              "С конца месяца Даты Старт"}
            &nbsp;
            {this.state.MediaPlanItem.Platform &&
              this.state.MediaPlanItem.Platform.payment_delay_value}
          </td>
          <td>
            <div className="row">
              <div className="col-9">
                <select
                  style={{
                    height: "25px",
                  }}
                  data-name="payment_delay"
                  className="form-control"
                  disabled={disabled}
                  defaultValue={
                    this.state.MediaPlanItem.payment_delay
                      ? this.state.MediaPlanItem.payment_delay
                      : this.state.MediaPlanItem.Platform &&
                        this.state.MediaPlanItem.Platform.payment_delay
                      ? this.state.MediaPlanItem.Platform.payment_delay
                      : ""
                  }
                  onChange={(e) => {
                    const value = +e.target.value;
                    this.props.updateField({
                      variables: {
                        id: this.props.mpItem.id,
                        field: "payment_delay",
                        value: value,
                      },
                      refetchQueries: this.refetchQueries,
                    });
                  }}
                >
                  <option value="0" hidden></option>
                  <option value="1">С Даты Финиш</option>
                  <option value="2">С начала месяца Даты Финиш</option>
                  <option value="3">С конца месяца Даты Финиш</option>
                  <option value="4">С Даты Старт</option>
                  <option value="5">С начала месяца Даты Старт</option>
                  <option value="6">С конца месяца Даты Старт</option>
                </select>
              </div>
              <div
                className="col-3"
                style={{
                  width: "43px",
                  padding: 0,
                }}
              >
                <input
                  type="text"
                  className="form-control"
                  style={{
                    width: "43px",
                  }}
                  disabled={disabled}
                  defaultValue={
                    this.state.MediaPlanItem.payment_delay_value
                      ? this.state.MediaPlanItem.payment_delay_value
                      : this.state.MediaPlanItem.Platform &&
                        this.state.MediaPlanItem.Platform.payment_delay_value
                      ? this.state.MediaPlanItem.Platform.payment_delay_value
                      : ""
                  }
                  onChange={(e) => {
                    const value = e.target.value;
                    this.props.updateField({
                      variables: {
                        id: this.props.mpItem.id,
                        field: "payment_delay_value",
                        value: value,
                      },
                      refetchQueries: this.refetchQueries,
                    });
                  }}
                />
              </div>
            </div>
          </td>
          <td>
            {this.props.parentState.Briefing &&
              this.props.parentState.Briefing.payment_delay === 1 &&
              "С Даты Финиш"}
            {this.props.parentState.Briefing &&
              this.props.parentState.Briefing.payment_delay === 2 &&
              "С начала месяца Даты Финиш"}
            {this.props.parentState.Briefing &&
              this.props.parentState.Briefing.payment_delay === 3 &&
              "С конца месяца Даты Финиш"}
            {this.props.parentState.Briefing &&
              this.props.parentState.Briefing.payment_delay === 4 &&
              "С Даты Старт"}
            {this.props.parentState.Briefing &&
              this.props.parentState.Briefing.payment_delay === 5 &&
              "С начала месяца Даты Старт"}
            {this.props.parentState.Briefing &&
              this.props.parentState.Briefing.payment_delay === 6 &&
              "С конца месяца Даты Старт"}
            &nbsp;
            {this.props.parentState.Briefing &&
              this.props.parentState.Briefing.payment_delay_value}
          </td>
          <td>
            <RequirementsField
              platformID={this.state.MediaPlanItem?.InventoryItem?.platformId}
              inventoryID={this.state.MediaPlanItem?.InventoryItem?.id}
              comment={this.state.MediaPlanItem?.Platform?.Price.comment}
              technical_requirements_publication={
                this.state.MediaPlanItem?.Platform?.Price
                  ?.technical_requirements_publication
              }
              addFile={
                !this.state.MediaPlanItem?.InventoryItem?.files?.length &&
                this.state.MediaPlanItem?.InventoryItem?.type === "banner"
              }
            />
          </td>
          {inventoryReqShow ? (
            <td>
              {this.state.MediaPlanItem.InventoryItem?.requirements ||
              this.state.MediaPlanItem.InventoryItem?.requirementsFiles
                .length ? (
                <Alert severity="success">Загружено</Alert>
              ) : (
                <ApolloConsumer>
                  {(client) => (
                    <Requirements
                      client={client}
                      inventoryID={this.state.MediaPlanItem.inventory_id}
                    />
                  )}
                </ApolloConsumer>
              )}
            </td>
          ) : null}

          {this.props.parentProps.parentState.show ? (
            <>
              <td>
                {this.props.parentState.Briefing &&
                  this.props.parentState.Briefing.agent_commission_position ===
                    1 &&
                  "Сверху"}
                {this.props.parentState.Briefing &&
                  this.props.parentState.Briefing.agent_commission_position ===
                    2 &&
                  "Внутри"}
                {this.props.parentState.Briefing &&
                  !this.props.parentState.Briefing.agent_commission_position &&
                  "-"}
              </td>
              <td>
                {this.props.parentState?.Briefing?.agent_commission_value ? (
                  <>
                    {this.props.parentState?.Briefing?.agent_commission_value} %
                  </>
                ) : (
                  "-"
                )}
              </td>
              <td>
                {this.state.MediaPlanItem.ac ? (
                  <NumberFormat
                    displayType="text"
                    thousandSeparator={" "}
                    decimalSeparator={","}
                    decimalScale={2}
                    suffix={!isForeign && " ₽"}
                    value={this.state.MediaPlanItem.ac}
                  />
                ) : (
                  "-"
                )}
              </td>
              <td>
                {this.state.MediaPlanItem.sellNet ? (
                  <NumberFormat
                    displayType="text"
                    thousandSeparator={" "}
                    decimalSeparator={","}
                    decimalScale={2}
                    suffix={!isForeign && " ₽"}
                    value={this.state.MediaPlanItem.sellNet}
                  />
                ) : (
                  "-"
                )}
              </td>
              <td>{this.state.MediaPlanItem.acVatValue ? "есть" : "-"}</td>
              <td>
                {this.state.MediaPlanItem.acVatValue ? (
                  <NumberFormat
                    displayType="text"
                    thousandSeparator={" "}
                    decimalSeparator={","}
                    decimalScale={2}
                    suffix={!isForeign && " ₽"}
                    value={this.state.MediaPlanItem.acVatValue}
                  />
                ) : (
                  "-"
                )}
              </td>
              <td>
                {this.state.MediaPlanItem.sellGross ? (
                  <NumberFormat
                    displayType="text"
                    thousandSeparator={" "}
                    decimalSeparator={","}
                    decimalScale={2}
                    suffix={!isForeign && " ₽"}
                    value={this.state.MediaPlanItem.sellGross}
                  />
                ) : (
                  "-"
                )}
              </td>
            </>
          ) : null}

          {this.state.page_access === "manager" && (
            <td
              style={{
                minWidth: "350px",
              }}
            >
              <div className="row">
                <div className="col-8">
                  {disabled ? null : (
                    <Mutation
                      mutation={gql`
                        mutation RnMpItemUpdateFieldText(
                          $id: Int
                          $field: String
                          $value: String
                        ) {
                          RnMpItemUpdateFieldText(
                            id: $id
                            field: $field
                            value: $value
                          ) {
                            id
                            is_change_comment
                          }
                        }
                      `}
                    >
                      {(updateFieldString, { data }) => (
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Причина замены"
                          defaultValue={
                            this.state.MediaPlanItem.is_change_comment &&
                            this.state.MediaPlanItem.is_change_comment
                          }
                          onChange={(e) => {
                            const value = e.target.value;
                            if (value.length > 4) {
                              updateFieldString({
                                variables: {
                                  id: this.props.mpItem.id,
                                  field: "is_change_comment",
                                  value: value,
                                },
                                refetchQueries: this.refetchQueries,
                              });
                            }
                          }}
                        />
                      )}
                    </Mutation>
                  )}
                </div>
                <div className="col-4">
                  <button
                    data-name="is_change"
                    className="btn btn-danger btn-block"
                    disabled={disabled}
                    onClick={() => {
                      this.props.updateField({
                        variables: {
                          id: this.props.mpItem.id,
                          field: "is_change",
                          value: 1,
                        },
                        refetchQueries: this.refetchQueries,
                      });
                    }}
                  >
                    Заменить
                  </button>
                </div>
              </div>
            </td>
          )}
          {this.state.page_access !== "manager" &&
          this.state.is_change_comment ? (
            <td className="red-col">
              <strong>Замена: </strong>
              {this.state.is_change_comment}
            </td>
          ) : (
            <td></td>
          )}

          {this.props.parentProps.parentProps.mp.status === 7 ? (
            <>
              <GreyTd>
                <NumberFormat
                  disabled={disabled}
                  className="form-control"
                  thousandSeparator={" "}
                  decimalSeparator={","}
                  decimalScale={2}
                  suffix={" %"}
                  value={this.state.MediaPlanItem.rCTR}
                  onValueChange={(values) => {
                    const value = values.value;
                    if (value) {
                      this.props.updateField({
                        variables: {
                          id: this.props.mpItem.id,
                          field: "rCtr",
                          value: value,
                        },
                        refetchQueries: this.refetchQueries,
                      });
                    }
                  }}
                />
              </GreyTd>
              <GreyTd>
                <NumberFormat
                  disabled={disabled}
                  className="form-control"
                  thousandSeparator={" "}
                  value={this.state.MediaPlanItem.rClicks}
                  onValueChange={(values) => {
                    const value = values.value;
                    if (value) {
                      this.props.updateField({
                        variables: {
                          id: this.props.mpItem.id,
                          field: "rClicks",
                          value: value,
                        },
                        refetchQueries: this.refetchQueries,
                      });
                    }
                  }}
                />
              </GreyTd>
              <GreyTd>
                <NumberFormat
                  disabled={disabled}
                  className="form-control"
                  thousandSeparator={" "}
                  value={this.state.MediaPlanItem.rImpressions}
                  onValueChange={(values) => {
                    const value = values.value;
                    if (value) {
                      this.props.updateField({
                        variables: {
                          id: this.props.mpItem.id,
                          field: "rImpressions",
                          value: value,
                        },
                        refetchQueries: this.refetchQueries,
                      });
                    }
                  }}
                />
              </GreyTd>
            </>
          ) : null}
        </tr>

        {this.state.historyShow && this.state.itemLogList && (
          <>
            <tr>
              <td colSpan={5} className="bg-warning">
                <h3>История закупа</h3>
              </td>
            </tr>

            <Content itemLogList={this.state.itemLogList} />
          </>
        )}

        {this.state.inventoryListModalIsOpen && (
          <InventoryListModal
            platformID={
              this.state.MediaPlanItem.Platform &&
              this.state.MediaPlanItem.Platform.id
            }
            platform={this.state.MediaPlanItem.Platform}
            me={this.props.parentProps.parentProps.me}
            mPlanItem={this.state.MediaPlanItem}
            setClose={() => {
              this.setState((state) => ({
                ...state.data,
                inventoryListModalIsOpen: false,
              }));
            }}
            addInventory={this.props.updateField}
          />
        )}

        <div style={{ position: "relative" }}>
          {this.state.fast_creation && (
            <InventoryFastCreation
              {...this.state}
              mp_item_id={this.state.MediaPlanItem.id}
              platformId={
                this.state.MediaPlanItem.Platform &&
                this.state.MediaPlanItem.Platform.id
              }
              {...this.props}
              setParentState={(newState) => {
                this.setState((state) => ({
                  ...state.data,
                  ...newState,
                }));
              }}
            />
          )}
          {this.state.inventory_format_modal && (
            <FormatModal
              mp_item_id={this.state.MediaPlanItem.id}
              {...this.state}
              {...this.props}
              format={format}
              setParentState={(newState) => {
                this.setState((state) => ({
                  ...state.data,
                  ...newState,
                }));
              }}
            />
          )}
          {this.state.inventory_apply_modal && (
            <TemplateEditor
              mp_item_id={this.state.MediaPlanItem.id}
              {...this.state}
              {...this.props}
              format={format}
              setParentState={(newState) => {
                this.setState((state) => ({
                  ...state.data,
                  ...newState,
                }));
              }}
            />
          )}
        </div>
      </>
    );
  }
}

const MediaPlanItemsListItemContainer = graphql(RN_MP_ITEM_UPDATE_FIELD, {
  props: ({ mutate }) => {
    return {
      updateField: mutate,
    };
  },
  options: () => ({}),
})(MediaPlanItemsListItem);

const MediaPlanItemsListItemAddText = graphql(RN_MP_ITEM_UPDATE_FIELD_TEXT, {
  props: ({ mutate }) => {
    return {
      updateFieldText: mutate,
    };
  },
  options: () => ({}),
})(MediaPlanItemsListItemContainer);

const MediaPlanItemsListItemAddTargetingList = graphql(
  RN_MP_ITEM_UPDATE_TARGETING_LIST,
  {
    props: ({ mutate }) => {
      return {
        updateTargeting: mutate,
      };
    },
    options: () => ({}),
  }
)(MediaPlanItemsListItemAddText);

export default MediaPlanItemsListItemAddTargetingList;
