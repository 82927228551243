import React, { Component } from "react";
export const TabContext = React.createContext();

//поведение таб-вкладки
export const TabBarItem = (OriginalComponent) => {
  class NewComponent extends Component {
    render() {
      return (
        <TabContext.Consumer>
          {(data) => <OriginalComponent value={data} {...this.props} />}
        </TabContext.Consumer>
      );
    }
  }
  return NewComponent;
};
