import React from "react";
import { Link } from "react-router-dom";
import gql from "graphql-tag";
import { ApolloConsumer } from "react-apollo";
import NumberFormat from "react-number-format";
import { Mutation } from "react-apollo";

import Modal from "./MpCreateModal/Modal";
import { getMpName } from "../../../functions/getMpName";

const download = require("downloadjs");
const moment = require("moment/min/moment-with-locales.js");

const RN_MP_REMOVE = gql`
  mutation($id: Int) {
    RnMpRemove(id: $id) {
      id
    }
  }
`;

class ListItem extends React.Component {
  state = {
    modal: false,
  };

  render() {
    const brand =
      this.props.data.Briefing && this.props.data.Briefing.Brand
        ? this.props.data.Briefing.Brand.name
        : "Не выбран бриф";
    const partner =
      this.props.data.Briefing &&
      this.props.data.Briefing.Partner &&
      `${this.props.data.Briefing.Partner.name},`;

    let total = 0;

    if (this.props.data.MpItems.length) {
      let mp_items_totals = this.props.data.MpItems.map(
        (item) => +item.Info.total_cost_vat_discount
      );

      mp_items_totals = mp_items_totals.reduce((sum, item) => sum + item, 0);

      total = mp_items_totals;
    }

    let margin =
      this.props.data.MpItems.length > 0 &&
      this.props.data.MpItems.map((item) => item.Info.margin).reduce(
        (sum, item) => {
          if (typeof item === "number") return sum + item;
          return null;
        }
      );

    let manager =
      this.props.data.Briefing && this.props.data.Briefing.Manager
        ? this.props.data.Briefing.Manager.nameFull
        : null;
    let responsible =
      this.props.data.Briefing && this.props.data.Briefing.Responsible
        ? this.props.data.Briefing.Responsible.nameFull
        : null;

    let vat = this.props.data.Briefing && this.props.data.Briefing.vat;
    let no_vat = this.props.data.Briefing && this.props.data.Briefing.no_vat;

    let contract_type =
      this.props.data.Briefing && this.props.data.Briefing.contract_type;

    let render_delete = false;
    if (+this.props.src_data.me.id === 1) {
      render_delete = true;
    }

    if (
      this.props.data.Briefing &&
      this.props.data.Briefing.Responsible &&
      this.props.src_data.me.comon_id ===
        this.props.data.Briefing.Responsible.id
    ) {
      render_delete = true;
    }

    return (
      <>
        {this.state.modal ? (
          <Modal
            {...this.props}
            onClose={() => {
              this.setState({
                ...this.state,
                modal: false,
              });
            }}
          />
        ) : null}

        <div className="border py-3 mb-4">
          <div className="row px-3">
            <div className="col-8">
              <div className="plans-title mb-3">
                <strong>
                  <span className="text-dark">
                    Медиаплан | {partner && <>{partner} | </>} {brand} | РК:{" "}
                    {this.props.data.Briefing &&
                      this.props.data.Briefing.products}{" "}
                    {this.props.data.Briefing &&
                      moment(this.props.data.Briefing.dateStart).format(
                        "DD.MM.YYYY"
                      )}
                    {"-"}
                    {this.props.data.Briefing &&
                      moment(this.props.data.Briefing.dateEnd).format(
                        "DD.MM.YYYY"
                      )}
                  </span>{" "}
                  {this.props.data.is_copy && (
                    <button className="btn btn-warning btn-sm">Копия</button>
                  )}{" "}
                  {this.props.data.tender && (
                    <button className="btn btn-danger btn-sm">Тендерный</button>
                  )}
                </strong>
              </div>
              <div className="row">
                <div className="col-3 pr-0">
                  Номер сделки из АСС:&nbsp;
                  <strong>
                    {this.props.data.order_id ? this.props.data.order_id : null}
                    &nbsp;
                    {this.props.data.order_id_2 ? (
                      <>и {this.props.data.order_id_2}</>
                    ) : null}
                  </strong>
                </div>
                <div className="col-5 pl-0">
                  Налогообложение клиента:{" "}
                  <strong>
                    {vat ? "в т.ч. НДС" : null} {no_vat ? "без НДС" : null}
                  </strong>
                </div>
                <div className="col-4">
                  Тип договора:{" "}
                  <strong>
                    {contract_type === 1 && "Услуговый"}
                    {contract_type === 2 && "Агентский"}
                    {contract_type === 3 && "Комплексный"}
                    {!contract_type && "Не выбран бриф"}
                  </strong>
                </div>
              </div>
              <div className="row mt-4">
                <div className="col-5 row">
                  <label className="form-inline col-3" htmlFor="status">
                    Статус
                  </label>
                  <select
                    className="form-control form-inline col-9"
                    id="status"
                    disabled={true}
                    value={
                      this.props.data.status !== null
                        ? this.props.data.status
                        : undefined
                    }
                  >
                    <option value=""></option>
                    <option value={0}>В работе</option>
                    <option value={1}>Принят байером</option>
                    <option value={2}>Возвращен с замечаниями менеджеру</option>
                    <option value={3}>Корректируется байером</option>
                    <option value={4}>
                      Отправлен на согласование менеджеру
                    </option>
                    <option value={5}>Не согласован с клиентом</option>
                    <option value={6}>Согласован и отправлен в запуск</option>
                  </select>
                </div>
                <div className="col-7">
                  {manager && (
                    <>
                      Куратор: {manager} <br />
                    </>
                  )}
                  {responsible && <>Исполнитель: {responsible}</>}
                </div>
              </div>
            </div>

            <div className="col-4 text-right">
              {" "}
              Дата создания:{" "}
              {this.props.data.entryDate &&
                moment(this.props.data.entryDate).format("DD.MM.Y")}
              <br />
              <Link
                className="btn btn-primary ml-3 form-inline remove-item"
                to={`/mediaplan/update/${this.props.data.id}`}
              >
                {!this.props.data.disabled ? "Редактирование" : "Просмотр"}
              </Link>
              {/*{this.props.data.status > 4 ? (*/}
              <ApolloConsumer>
                {(client) => (
                  <button
                    className="btn btn-success ml-2"
                    onClick={async (e) => {
                      e.preventDefault();
                      const { data } = await client.query({
                        query: gql`
                          query RnMpExcelExport($mpId: Int!) {
                            RnMpExcelExport(mpId: $mpId)
                          }
                        `,
                        variables: { mpId: this.props.data.id },
                      });

                      const name = getMpName(this.props.data.Briefing);

                      const file = window.atob(data.RnMpExcelExport);
                      download(
                        file,
                        `${name}.xlsx`,
                        "application/vnd.ms-excel"
                      );
                    }}
                  >
                    XLS
                  </button>
                )}
              </ApolloConsumer>
              {/*) : null}*/}
              {!this.props.data.disabled && (
                <button
                  className="btn btn-secondary form-inline remove-item ml-2"
                  onClick={() => {
                    this.setState({
                      ...this.state,
                      modal: true,
                    });
                  }}
                >
                  Копировать
                </button>
              )}
              {render_delete ? (
                <Mutation mutation={RN_MP_REMOVE}>
                  {(remove, { data }) => (
                    <button
                      className="btn btn-danger form-inline remove-item ml-2"
                      onClick={() => {
                        let flag = window.confirm(
                          "Вы точно хотите удалить медиаплан?"
                        );
                        if (flag) {
                          remove({
                            variables: {
                              id: this.props.data.id,
                            },
                            refetchQueries: ["RnMpListPager"],
                          });
                        }
                      }}
                    >
                      Удалить
                    </button>
                  )}
                </Mutation>
              ) : null}
              {total ? (
                <h4 className="mt-3">
                  Сумма:&nbsp;
                  <NumberFormat
                    displayType="text"
                    thousandSeparator={" "}
                    decimalSeparator={","}
                    decimalScale={2}
                    suffix={" ₽"}
                    value={total}
                  />
                </h4>
              ) : null}
              {margin ? (
                <h4 className="mt-3">
                  Маржа:&nbsp;
                  <NumberFormat
                    displayType="text"
                    thousandSeparator={" "}
                    decimalSeparator={","}
                    decimalScale={2}
                    suffix={" ₽"}
                    value={margin}
                  />
                </h4>
              ) : null}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ListItem;
