import React from "react";
import CityList from "./cityList";
import { GET_FEDERAL_SUBJECT } from "./geoQueries";
import { Query } from "react-apollo";

const Region = ({
  item,
  geo_data,
  handleChangeGeoData,
  handleChangeGeoDataCity,
  handleChangeGeoDataRegion,
  src_district,
  src_subject,
  district_code,
  ulStyle,
  handleChange,
  data_geo_tree,
  tree,
  openHandler,
  district,
  handleChangeCity,
  indexKeyRegion,
  indexKeyDistrict
}) => {
  function isOpened(tree, item, district_code) {
    return (
      tree
        .find(i => i.Code === district_code)
        .regions.find(i => i.AOGUID === item.AOGUID) &&
      tree
        .find(i => i.Code === district_code)
        .regions.find(i => i.AOGUID === item.AOGUID).isOpened
    );
  }

  const memoizedChecked = React.useMemo(
    () => isOpened(tree, item, district_code),
    [tree, item, district_code]
  );

  const isChecked = () => {
    if (data_geo_tree.some(i => i === item.AOGUID)) {
      return true;
    }

    return false;
  };

  return (
    <Query
      variables={{
        id: item.AOGUID
      }}
      query={GET_FEDERAL_SUBJECT}
    >
      {({ loading, error, data }) => {
        if (loading) return null;
        if (error) return `Error! ${error.message}`;

        return (
          <li>
            <span
              className="mr-1"
              style={{
                fontSize: "1rem"
              }}
              onClick={() => {
                let local_tree = tree.filter(i => i.Code !== district_code);
                let element = tree.find(i => i.Code === district_code);
                let region = element.regions.find(
                  i => i.AOGUID === item.AOGUID
                );
                let regions = element.regions.filter(
                  i => i.AOGUID !== item.AOGUID
                );

                if (region) {
                  local_tree = [
                    ...local_tree,
                    {
                      ...element,
                      regions: [
                        ...regions,
                        {
                          AOGUID: item.AOGUID,
                          isOpened: !region.isOpened
                        }
                      ]
                    }
                  ];
                } else {
                  local_tree = [
                    ...local_tree,
                    {
                      ...element,
                      regions: [
                        ...regions,
                        {
                          AOGUID: item.AOGUID,
                          isOpened: true
                        }
                      ]
                    }
                  ];
                }

                openHandler(local_tree);
              }}
            >
              {memoizedChecked ? "-" : "+"}
            </span>

            <label>
              <input
                type="checkbox"
                checked={isChecked()}
                onChange={e => {
                  const VALUE = e.target.checked;
                  let inner_data = data_geo_tree.filter(i => i !== item.AOGUID);
                  let src_localities = src_subject.Localities;

                  if (VALUE) {
                    inner_data = [
                      ...inner_data,
                      item.AOGUID,
                      ...data.Geo.FederalSubject.Localities.map(i => i.AOGUID)
                    ];
                  } else {
                    for (let i of src_localities) {
                      inner_data = inner_data.filter(j => j !== i.AOGUID);
                    }

                    inner_data = [...inner_data];
                  }

                  let federalDistricts = geo_data.FederalDistricts.filter(
                    i => i.Code !== district_code
                  );

                  let district = geo_data.FederalDistricts.find(
                    i => i.Code === district_code
                  );

                  let federalSubjects = district
                    ? district.FederalSubjects.filter(
                        i => i.AOGUID !== item.AOGUID
                      )
                    : [];

                  if (VALUE) {
                    federalDistricts = [
                      ...federalDistricts,
                      {
                        Code: district_code,
                        isFull: false,
                        FederalSubjects: [
                          ...federalSubjects,
                          {
                            ...item,
                            isFull: true,
                            Localities: [...data.Geo.FederalSubject.Localities]
                          }
                        ]
                      }
                    ];
                  } else {
                    federalDistricts = [
                      ...federalDistricts,
                      {
                        Code: district_code,
                        FederalSubjects: [...federalSubjects]
                      }
                    ];
                  }

                  handleChangeGeoData(inner_data, federalDistricts);
                }}
              />{" "}
              {data.Geo.FederalSubject.FORMALNAME}{" "}
              {data.Geo.FederalSubject.SHORTNAME}
            </label>

            {memoizedChecked ? (
              <CityList
                parentGuid={data.Geo.FederalSubject.AOGUID}
                district_code={district_code}
                item={item}
                ulStyle={ulStyle}
                handleChange={handleChange}
                data_geo_tree={data_geo_tree}
                district={district}
                subject={data.Geo.FederalSubject}
                tree={tree}
                handleChangeCity={handleChangeCity}
                indexKeyRegion={indexKeyRegion}
                indexKeyDistrict={indexKeyDistrict}
                src_district={src_district}
                src_subject={data.Geo.FederalSubject}
                geo_data={geo_data}
                handleChangeGeoData={handleChangeGeoData}
                handleChangeGeoDataCity={handleChangeGeoDataCity}
                handleChangeGeoDataRegion={handleChangeGeoDataRegion}
              />
            ) : null}
          </li>
        );
      }}
    </Query>
  );
};

export default Region;
