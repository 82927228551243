import React from "react";
import { useMlThematicsListQuery } from "../../../graphql/types";
import Select from "react-select";
import { ApolloClient } from "@apollo/client";

interface IThematicsProps {
  client: ApolloClient<any>;
  isMulti?: boolean;
  value: any;
  onChange: (value: any) => void;
  isClearable?: boolean;
}

export function Thematics({
  client,
  isMulti,
  value,
  onChange,
  isClearable = false,
}: IThematicsProps) {
  const { data } = useMlThematicsListQuery({
    client,
  });

  return (
    <Select
      isClearable={isClearable}
      placeholder={"Выбрать"}
      value={value}
      isMulti={isMulti}
      // @ts-ignore
      options={data?.MlThematicsList?.map((t) => ({
        value: t?.id,
        label: t?.name,
      }))}
      onChange={(values) => {
        console.log(values);
        onChange(
          //@ts-ignore
          values?.map((val: any) => ({
            id: val.value,

            name: val.label,
          }))
        );
      }}
    />
  );
}
