import moment from "moment";

/**
 * функция создания имени файла мп
 * @param brief
 */
export function getMpName(brief: any): string {
  if (brief) {
    let name = "";
    if (brief.Partner) {
      name = brief.Partner.name;
    }

    if (brief.Brand) {
      name = name + " " + brief.Brand.name;
    }

    if (brief.products) {
      name = name + " " + brief.products;
    }

    if (brief.dateStart) {
      let dateStartMonthName = moment(brief.dateStart).format("MMMM");
      let dateFinishMonthName = moment(brief.dateEnd).format("MMMM");

      dateFinishMonthName =
        dateFinishMonthName.charAt(0).toUpperCase() +
        dateFinishMonthName.slice(1);
      dateStartMonthName =
        dateStartMonthName.charAt(0).toUpperCase() +
        dateStartMonthName.slice(1);

      if (dateFinishMonthName == dateStartMonthName) {
        name = name + " " + dateStartMonthName;
      } else {
        name = name + " " + dateStartMonthName + "-" + dateFinishMonthName;
      }
    }

    return name;
  }

  return "Медиаплан";
}
