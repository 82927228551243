import * as React from "react";

interface IFederalDistrict {
  readonly Code: string | number;
  readonly FederalDistrict: string;
  FederalSubjects?: any[];
}

type geoObject = {
  name?: string;
  children?: any;
};

export default function geoView(fd: IFederalDistrict[]): any {
  const geo: any[] = [];

  fd.forEach(item => {
    let obj: geoObject = {
      name: item.FederalDistrict + " ФО",
      children: []
    };

    if (item.FederalSubjects) {
      item.FederalSubjects.forEach(fs => {
        if (fs.Localities && fs.Localities.length) {
          fs.Localities.forEach((l: any) => {
            if (l.AOGUID) {
              obj = {
                ...obj,
                children: [
                  ...obj.children,
                  {
                    name:
                      l.SHORTNAME +
                      " " +
                      l.FORMALNAME +
                      " (" +
                      fs.FORMALNAME +
                      " " +
                      fs.SHORTNAME +
                      ")"
                  }
                ]
              };
            } else {
              obj = {
                ...obj,
                children: [
                  ...obj.children,
                  {
                    name: fs.FORMALNAME + " " + fs.SHORTNAME
                  }
                ]
              };
            }
          });
        } else {
          obj = {
            ...obj,
            children: [
              ...obj.children,
              {
                name: fs.FORMALNAME + " " + fs.SHORTNAME
              }
            ]
          };
        }
      });
    }

    geo.push(obj);
  });

  return (
    <ul style={{ listStyle: "none" }}>
      {geo.map((item, index) => (
        <li key={`city#${index}`}>
          {item.children.length ? (
            <>
              {item.name + ":"}
              <ul
                style={{
                  listStyle: "none"
                }}
              >
                {item.children.map((child: geoObject, cindex: number) => (
                  <li key={index + "#" + cindex}>{child.name}</li>
                ))}
              </ul>
            </>
          ) : (
            item.name
          )}
        </li>
      ))}
    </ul>
  );
}
