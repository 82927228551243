import React from "react";
import { ApolloClient } from "@apollo/client";
import { selectQuery } from "../../../RN/Components/Briefing/gql/query";
//@ts-ignore
import AsyncSelect from "react-select/lib/Async";

interface Props {
  client: ApolloClient<any>;
  isMulti?: boolean;
  value: any;
  onChange: (value: any) => void;
  isClearable?: boolean;
}

export function Employee({
  client,
  isMulti,
  value,
  onChange,
  isClearable = false,
}: Props) {
  return (
    <AsyncSelect
      isMulti={isMulti}
      isClearable={isClearable}
      name="responsible_id"
      value={value}
      loadOptions={(inputValue: string) =>
        selectQuery(inputValue, "RefEmployeeList", client, "manager")
      }
      onChange={(res: any) => {
        onChange(
          res.map((i: any) => ({
            id: i.value,
            name: i.label,
          }))
        );
      }}
    />
  );
}
