import gql from "graphql-tag";
import React from "react";

let itemsGeo = [];
export const selectReferenceGeo = (inputValue, queryName, client) => {
  let variables = {
    params: {
      offset: 0,
      count: 1000,
      filter: [
        {
          values: [inputValue],
          operator: "LIKE",
          field: "name",
        },
      ],
    },
  };

  return new Promise((resolve, reject) => {
    client
      .query({
        query: gql`
                query geo($params: ParamsInput) {
                    ${queryName}(params: $params) {
                        AOGUID
                        FORMALNAME
                        SHORTNAME
                        Parent {
                            AOGUID
                            FORMALNAME
                            SHORTNAME
                            Parent {
                                AOGUID
                                FORMALNAME
                                SHORTNAME    
                            }
                        }
                        District {
                            Code
                            FederalDistrict
                        }
                        Region {
                            AOGUID
                            FORMALNAME
                            SHORTNAME
                            District {
                                Code
                            }
                        }
                    }
                }
            `,
        variables,
      })
      .then((result) => {
        itemsGeo = [];
        for (let i of result.data[queryName]) {
          let obj = {};
          obj.src_data = i;
          obj.value = i.AOGUID;
          obj.label = (
            <>
              {i.FORMALNAME} {queryName === "MlRegionList" ? i.SHORTNAME : null}
              <br />
              <span style={{ color: "#B0B0B0" }}>
                {i.Parent && i.Parent.Parent ? (
                  <>
                    {i.Parent.Parent.FORMALNAME} {i.Parent.Parent.SHORTNAME},
                  </>
                ) : null}{" "}
                {i.Parent ? (
                  <>
                    {i.Parent.FORMALNAME} {i.Parent.SHORTNAME}
                  </>
                ) : null}{" "}
              </span>
            </>
          );
          itemsGeo.push(obj);
          resolve();
        }
      });
  }).then(() => itemsGeo);
};

export const RN_BRIEFING_lIST_PAGER = gql`
  query RnBriefingListPager($params: ParamsInput) {
    me {
      id
      comon_id
      email
    }
    RnBriefingListPager(params: $params) {
      pageInfo
      data {
        id
        Users {
          id
        }
        name
        entryDate
        products
        manager_id
        dateStart
        dateEnd
        Partner {
          id
          name
          nameFull
        }
        Brand {
          id
          name
        }
        Responsible {
          id
          nameFull
        }
        Manager {
          id
          nameFull
        }
        MP {
          id
        }
      }
    }
  }
`;

export const ME = gql`
  query {
    me {
      id
      comon_id
      email
      login
    }
    TopBuyer {
      id
      login
      comon_id
      email
    }
  }
`;

export const GET_TOP_BUYER = gql`
  query {
    getTopBuyer {
      id
      login
      comon_id
      email
    }
  }
`;

export const RN_BRIEFING = gql`
  query RnBriefing($id: Int!) {
    me {
      id
      comon_id
      login
      email
      top
    }
    RnBriefing(id: $id) {
      id
      status
      name
      manager_id
      partner_id
      brand_id
      taskId
      task_id
      vat
      no_vat
      tender
      tender_deadline
      target_audience
      banner
      news
      special
      cards
      recommends
      experience_comment
      legal_entity_vat
      legal_entity_vat
      contract_type
      agent_commission_position
      agent_commission_value
      agent_commission_value_type
      federal
      responsible_id
      comon_group_id
      Users {
        id
        name
        nameFull
      }
      FederalDistricts {
        Code
        FederalDistrict
        FederalSubjects {
          AOGUID
          FORMALNAME
          SHORTNAME
          Localities {
            AOGUID
            FORMALNAME
            SHORTNAME
          }
        }
      }
      ComonGroup {
        id
        name
      }
      Cities {
        AOGUID
        FORMALNAME
      }
      Responsible {
        id
        nameFull
      }
      Manager {
        id
        nameFull
      }
      Partner {
        id
        name
        nameFull
      }
      Brand {
        id
        name
      }
      UrFace_nds {
        id
        name
      }
      UrFace_nonds {
        id
        name
      }
      products
      advantages
      website
      competitors
      sex
      ageRanges
      audienceComment
      dateStart
      dateEnd
      advTask
      advTaskOther
      kpiType
      kpiComment
      keyMessage
      totalBudget
      totalBudgetFrom
      totalBudgetTo
      totalBudgetAcIn
      entryDate
      deadline
      dateEnd
      clicks
      kpiComment
      keyMessage
      websiteChangeable
      promotionalMaterial
      totalBudget
      totalBudgetAcIn
      agentCommissionPosition
      agentCommission
      payment_delay
      payment_delay_value
      file {
        id
        name
        path
      }
    }
  }
`;

//выгрузка данных через select
let items = [];
export const selectQuery = (
  inputValue,
  queryName,
  client,
  type = "general",
  vat = false
) => {
  let nameFull = "";

  if (type === "manager") nameFull = ":nameFull";

  let variables = {
    params: {
      offset: 0,
      count: 1000,
      filter: [
        {
          values: [inputValue],
          operator: "LIKE",
          field: "name",
        },
      ],
    },
  };

  if (vat === 0 || vat === 1) {
    variables = {
      params: {
        offset: 0,
        count: 1000,
        filter: [
          {
            values: [inputValue],
            operator: "LIKE",
            field: "name",
          },
          {
            values: [vat],
            operator: "EQ",
            field: "vat",
          },
        ],
      },
    };
  }

  return new Promise((resolve, reject) => {
    client
      .query({
        query: gql`          
          query($params: ParamsInput) {
              ${queryName}(params: $params) {
                  id
                  name 
                  ${nameFull}
              }
          }
      `,
        variables,
      })
      .then((result) => {
        items = [];
        for (let i of result.data[queryName]) {
          let obj = {};
          obj.value = i.id;
          obj.label = i.name;
          items.push(obj);
          resolve();
        }
      });
  }).then(() => items);
};
