import React from 'react';

import { LOG_LIST } from './loggerQueries';
import { useQuery } from '@apollo/react-hooks';
import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';

const translate = {
    'updateDate': 'дата обновления',
    'update_date': 'дата обновления',
    'name': 'наименование',
    'id': 'id единицы',
    'inventory_id': 'id инвентаря',
    'coefficient': 'коэффициент',
    'author_id': 'id автора',
    'type': 'тип',
    'platform_id': 'id площадки',
    'platformId': 'id площадки',
    'inventory_type': 'тип инвентаря',
    'authorId': 'id автора',
    'accrualBasis': 'база начисления',
    'discountAmount': 'скидка'

};

require('moment/locale/ru');

const Logger = props => {

    const [state,setState] = React.useState({
        page: 1,
        count: 10,
        offset: 0
    });


    const renderButtons = (count,data) => {

        let pages = Math.ceil(data.LogList.pageInfo.total / 10);

        let array = [];
        let numbers = Math.floor(count/2);

        let start = state.page - numbers;
        let end = state.page + numbers;

        for(let i = start-1; i <= end+1; i++) {
            if(i <= start-1 && i > 1) { array.push('...') }
            else if(i === end+1 && i < pages) { array.push('...') }
            else if(i >= pages) { continue; }
            else if(i <= 1) { continue; }
            else { array.push(i); }
        }

        let array_to_show = [1, ...array, pages];

        return (
            <>
            { pages > 1 &&
            array_to_show.map((item, i) => {
                return (
                    <React.Fragment key={`${item}${i}`}>
                        {item !== '...' ?
                            <li className={ state.page === item ? "page-item active" : "page-item" }>
                                <a
                                    className="page-link"
                                    onClick={ e => {
                                        e.preventDefault();
                                        fetchMore({
                                            variables: {
                                                params: {
                                                    count: 10,
                                                    offset: 10 * (item - 1),
                                                    filter: props.filter ? [...props.filter] : null
                                                }
                                            },
                                            updateQuery: (prev, { fetchMoreResult }) => {
                                                if (!fetchMoreResult) return prev;

                                                return {
                                                    ...fetchMoreResult
                                                }
                                            }
                                        });
                                        setState({
                                            ...state,
                                            page: item,
                                            count: 10,
                                            offset: 10 * (item - 1)
                                        })
                                    }}
                                    href="#"
                                >{item}
                                </a>
                            </li> : <li className="page-item disabled"><span className="page-link">...</span></li>}
                    </React.Fragment>)})}
            </>)
    };

    const { loading, error, data, fetchMore } = useQuery(
        LOG_LIST,
        {
            client: props.client,
            variables: {
                params: {
                    count: 10,
                    offset: 0
                },
                type: props.match.params.type
            }
        },
    );

    let list = data;

    if (loading) return 'Loading...';
    if (error) return `Error! ${error.message}`;
    if (list) return (
        <>
            <section className="content">
                <div className="container-fluid mediaplans-list-block mt-2">

                    <div className="table-container">
                        <div
                            className="container-fluid"
                            style={{
                                maxWidth: '1400px'
                            }}
                        >
                            <h2>Логи&nbsp;
                                { props.match.params.type === 'inventory' && 'инвентаря' }
                                { props.match.params.type === 'discount' && 'скидок' }
                                { props.match.params.type === 'coefficient' && 'коэффициентов' }

                            </h2>
                            <div className="row mt-1 mb-3">
                                <div className="col-2">
                                    Фильтровать
                                </div>
                                <div className="col-4 row">
                                    <div className="col-4">
                                        Дата старт
                                    </div>
                                    <div className="col-7">
                                        <Datetime
                                            id={'deadline_ap'}
                                            value={ state.start_date ? new Date(state.start_date) : null }
                                            dateFormat={'dddd D/M/Y'}
                                            closeOnSelect
                                            locale={'ru'}
                                            onChange={ date => {
                                                setState({
                                                    ...state,
                                                    start_date: date.toISOString()
                                                });
                                            }}
                                        />
                                    </div>

                                </div>
                                <div className="col-4 row">
                                    <div className="col-4">
                                        Дата финиш
                                    </div>
                                    <div className="col-7">
                                        <Datetime
                                            id={'deadline_ap'}
                                            value={ state.end_date ? new Date(state.end_date) : null }
                                            dateFormat={'dddd D/M/Y'}
                                            closeOnSelect
                                            locale={'ru'}
                                            onChange={ date => {
                                                setState({
                                                    ...state,
                                                    end_date: date.toISOString()
                                                });
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="col-2">
                                    <button
                                        disabled={ !state.start_date || !state.end_date }
                                        className="btn btn-sm btn-success"
                                        onClick={()=> {
                                            fetchMore({
                                                variables: {
                                                    params: {
                                                        count: state.count,
                                                        offset: state.offset,
                                                        filter: [
                                                            {
                                                                operator: 'date_range',
                                                                field: 'date',
                                                                values: [
                                                                    state.start_date,
                                                                    state.end_date
                                                                ]
                                                            }
                                                        ]
                                                    }
                                                },
                                                updateQuery: (prev, { fetchMoreResult }) => {
                                                    if (!fetchMoreResult) return prev;

                                                    return {
                                                        ...fetchMoreResult
                                                    }
                                                }
                                            });
                                        }}
                                    >Фильтровать</button>
                                    <button
                                        className="btn btn-sm btn-secondary ml-1"
                                        onClick={()=> {
                                            fetchMore({
                                                variables: {
                                                    params: {
                                                        count: state.count,
                                                        offset: state.offset
                                                    }
                                                },
                                                updateQuery: (prev, { fetchMoreResult }) => {
                                                    if (!fetchMoreResult) return prev;

                                                    return {
                                                        ...fetchMoreResult
                                                    }
                                                }
                                            });
                                        }}
                                    >Сбросить</button>
                                </div>
                            </div>

                            <table className="table-bordered w-100">
                                <thead>
                                    <tr>
                                        <th>ID</th>
                                        <th>Предыдущее значение</th>
                                        <th>Имя поля</th>
                                        <th>Текущее значение</th>
                                        <th>Пользователь</th>
                                        <th>Действие</th>
                                        <th>Дата</th>
                                        <th>Площадка</th>
                                    </tr>
                                </thead>
                                <tbody>

                                { list.LogList.data.map((item,index) => {


                                    let marker = Date.now();

                                    return(
                                        <tr
                                            key={ item.id + '-' + item.src_id + '-' + index + '-' + marker }
                                            className=""
                                        >
                                            <td className="">{item.src_id}</td>
                                            <td className="">
                                                { item.prev_value }
                                            </td>
                                            <td className="">
                                               <br/>{ translate[item.field_name] }
                                            </td>
                                            <td className="">
                                                { item.next_value}
                                            </td>
                                            <td className="">
                                                { item.User &&
                                                <>
                                                { item.User.login }<br/>
                                                { item.User.email }

                                                </>}
                                            </td>
                                            <td className="">
                                                { item.action_type === 1 && 'Создано'}
                                                { item.action_type === 2 && 'Изменено'}
                                                { item.action_type === 3 && 'Удалено'}
                                                { item.action_type === 4 && 'Клонировано'}
                                            </td>
                                            <td className="">
                                                { new Date(item.entry_date).toLocaleDateString() }
                                            </td>
                                            <td>
                                                { item.platform_id ?
                                                    <a target='_blank' href={`/platform/update/${item.platform_id}`}>Площадка</a> : null
                                                }
                                            </td>
                                        </tr>
                                    )})}
                                </tbody>
                            </table>



                            <nav aria-label="navigation" className="mt-3">
                                <ul className="pagination">
                                    { renderButtons(3,data) }
                                </ul>
                            </nav>
                        </div>
                        {/*<button*/}
                            {/*onClick={()=>{*/}
                                {/*fetchMore({*/}
                                    {/*variables: {*/}
                                        {/*params: {*/}
                                            {/*offset: 1,*/}
                                            {/*count: 10*/}
                                        {/*}*/}
                                    {/*},*/}
                                    {/*updateQuery: (prev, { fetchMoreResult }) => {*/}
                                        {/*if (!fetchMoreResult) return prev;*/}

                                        {/*return {*/}
                                            {/*...fetchMoreResult*/}
                                        {/*}*/}
                                    {/*}*/}
                                {/*})*/}
                            {/*}}*/}
                        {/*>+1</button>*/}

                    </div>
                </div>
            </section>
        </>
    );

};

export default Logger;