import * as React from "react";
import PopUp from "../../../../components/modal/modal";

const months: string[] = [
  "Январь",
  "Февраль",
  "Март",
  "Апрель",
  "Май",
  "Июнь",
  "Июль",
  "Август",
  "Сентябрь",
  "Октябрь",
  "Ноябрь",
  "Декабрь",
  "Год"
];

export default function Modal({ list }: any) {
  if (!list) {
    return null;
  }

  return (
    <PopUp>
      <table className="table w-100">
        <thead>
          <tr>
            <th>Месяц</th>
            <th>Коэффициент</th>
          </tr>
        </thead>
        <tbody>
          {list.map((coefficient: any) => (
            <tr key={coefficient.id}>
              <td>{months[coefficient.month]}</td>
              <td>{coefficient.coefficient}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </PopUp>
  );
}
