import React from "react";
import { useQuery } from "@apollo/react-hooks";
import { ApolloConsumer } from "react-apollo";
import { TabBarItem } from "../Platform/TabContext";
import { MP_ITEM_REPORT_LIST_PAGER } from "../../GraphQL/RNqueries";
import DatePicker from "react-date-picker";

const Report = (props) => {
  const [state, setState] = React.useState({
    start_date: new Date(),
    end_date: new Date(),
  });

  const { loading, error, data, fetchMore } = useQuery(
    MP_ITEM_REPORT_LIST_PAGER,
    {
      client: props.client,
      variables: {
        params: {
          count: 999,
          offset: 0,
          filter: props.filter,
        },
      },
    }
  );

  if (loading) return "Загрузка...";
  if (error) return `Error! ${error.message}`;

  return (
    <>
      <div className="mb-3">
        <label htmlFor="">
          От
          <DatePicker
            onChange={(date) => {
              setState({
                ...state,
                start_date: date,
              });
            }}
            value={state.start_date}
            format={"dd.MM.y"}
            calendarIcon={null}
          />
        </label>
        &nbsp;
        <label htmlFor="">
          До
          <DatePicker
            onChange={(date) => {
              setState({
                ...state,
                end_date: date,
              });
            }}
            value={state.end_date}
            format={"dd.MM.y"}
            calendarIcon={null}
          />
        </label>
        &nbsp;
        <button
          className="btn btn-success btn-sm"
          disabled={!state.start_date || !state.end_date}
          onClick={() => {
            fetchMore({
              variables: {
                params: {
                  count: 99,
                  offset: 0,
                  filter: [
                    ...props.filter,
                    {
                      field: "date",
                      operator: "PERIOD",
                      values: [
                        state.start_date.toISOString(),
                        state.end_date.toISOString(),
                      ],
                    },
                  ],
                },
              },
              updateQuery: (prev, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prev;

                return {
                  ...fetchMoreResult,
                };
              },
            });
          }}
        >
          Применить
        </button>
      </div>
      <div
        style={{
          overflowX: "auto",
          minHeight: "400px",
        }}
      >
        <table
          className="table table-sm table-bordered"
          style={{
            fontSize: "14px",
          }}
        >
          <thead className="thead">
            <tr>
              <th>Дата создания медиаплана</th>
              <th>Байер</th>
              <th>Название медиаплана</th>
              <th>Название инвентаря</th>
              <th>Период</th>
              <th>Стоимость за 1-цу по прайс-листу без НДС</th>
              <th>Ед закупа</th>
              <th>Кол-во</th>
              <th>Скидка клиента</th>
              <th>Стоимость за период после скидки без НДС</th>
              <th>НДС площадки</th>
              <th>НДС клиента</th>
              <th>CTR</th>
              <th>Клики</th>
              <th>Показы</th>
              <th>CPM прогноз до НДС</th>
              <th>CPC прогноз до НДС</th>
              <th>Скидка ЗАКУП,%</th>
              <th>Стоимость за период ЗАКУП без НДС</th>
              <th>Рентабельность</th>
              <th>Маржа без НДС</th>
              <th>Отсрочка базовая</th>
              <th>Отсрочка факт</th>
            </tr>
          </thead>
          <tbody>
            {data.RN.MpItemReportListPager.data.map((item) => (
              <tr key={item.id}>
                <td>{item.entryDate}</td>
                <td>{item.Buyer && item.Buyer.nameFull}</td>
                <td>
                  {item.mp_id && (
                    <a
                      href={`http://rn.lab.delta-plan.ru/mediaplan/update/${item.mp_id}`}
                    >
                      Медиаплан {item.mp_id}
                    </a>
                  )}{" "}
                </td>
                <td>{item.Inventory && item.Inventory.name}</td>
                <td>
                  {item.startDate && item.startDate} -{" "}
                  {item.endDate && item.endDate}
                </td>
                <td>{item.unit_cost_no_vat && item.unit_cost_no_vat}</td>
                <td>
                  {item.Inventory &&
                    item.Inventory.type === "publication" &&
                    "шт. 1 выход"}
                  {item.Inventory &&
                    item.Inventory.priceType === 2 &&
                    "За 1000 показов"}
                  {item.Inventory &&
                    item.Inventory.priceType === 5 &&
                    "За день"}
                  {item.price_type === 1 && "За неделю"}
                  {item.price_type === 3 && "За 2 недели"}
                  {item.price_type === 4 && "За период"}
                  {item.price_type === 2 && "За месяц"}
                </td>
                <td>{item.purchase_count && item.purchase_count}</td>
                <td>{item.discount && item.discount}</td>
                <td>
                  {item.total_cost_vat_discount && item.total_cost_vat_discount}
                </td>
                <td>
                  {item.platform_vat === 1 ? "с НДС" : null}
                  {item.platform_vat === 2 ? "без НДС" : null}
                  {item.platform_vat === 3 ? "неизвестно" : null}
                </td>
                <td>
                  {item.vat && item.no_vat && "с НДС / без НДС"}
                  {item.vat && !item.no_vat && "с НДС"}
                  {!item.vat && item.no_vat && "без НДС"}
                </td>
                <td>{item.ctr && item.ctr}</td>
                <td>{item.clicks && item.clicks}</td>
                <td>{item.clicks && item.clicks}</td>
                <td>{item.cpm && item.cpm}</td>
                <td>{item.cpc && item.cpc}</td>
                <td>{item.discount_purchase && item.discount_purchase}</td>
                <td>
                  {item.total_cost_purchase_discount &&
                    item.total_cost_purchase_discount}
                </td>
                <td>{item.profitability && item.profitability}</td>
                <td>{item.margin && item.margin}</td>
                <td>
                  {item.Platform &&
                    item.Platform.payment_delay_value &&
                    item.Platform.payment_delay_value}{" "}
                  &nbsp;
                  {item.Platform &&
                    item.Platform.payment_delay === 1 &&
                    "С Даты Финиш"}
                  {item.Platform &&
                    item.Platform.payment_delay === 2 &&
                    "С начала месяца Даты Финиш"}
                  {item.Platform &&
                    item.Platform.payment_delay === 3 &&
                    "С конца месяца Даты Финиш"}
                  {item.Platform &&
                    item.Platform.payment_delay === 4 &&
                    "С Даты Старт"}
                  {item.Platform &&
                    item.Platform.payment_delay === 5 &&
                    "С начала месяца Даты Старт"}
                  {item.Platform &&
                    item.Platform.payment_delay === 6 &&
                    "С конца месяца Даты Старт"}
                </td>
                <td>
                  {item.payment_delay_value && item.payment_delay_value} &nbsp;
                  {item.payment_delay === 1 && "С Даты Финиш"}
                  {item.payment_delay === 2 && "С начала месяца Даты Финиш"}
                  {item.payment_delay === 3 && "С конца месяца Даты Финиш"}
                  {item.payment_delay === 4 && "С Даты Старт"}
                  {item.payment_delay === 5 && "С начала месяца Даты Старт"}
                  {item.payment_delay === 6 && "С конца месяца Даты Старт"}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

const ReportContainer = (props) => {
  return (
    <ApolloConsumer>
      {(client) => {
        return <Report client={client} {...props} />;
      }}
    </ApolloConsumer>
  );
};

export default TabBarItem(ReportContainer);
