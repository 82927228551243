import React, { Component } from "react";
import { Link } from "react-router-dom";
import { SelectThematics } from "../../../components/select";

import Header from "./../../../template/Header";

//импорт табов
import { TabContext } from "./TabContext";
import Info from "./Tabs/Info/Info";
import Inventory from "./Tabs/Inventory/Inventory";
import Discount from "./Tabs/Discount/Discount";
// import MarkUp from "./Tabs/MarkUp/MarkUp";
import Contacts from "./Tabs/Contacts/Contacts";
import Audience from "./Tabs/Audience/Audience";
import Supercom from "./Tabs/Supercom/Supercom";
// import Documents from "./Tabs/Documents/Documents";
import Price from "./Tabs/Price/Price";
import Coefficient from "./Tabs/Coefficient/Coefficient";
import Report from "./../Reports/HistoryReport";
import Targeting from "./Tabs/targeting/targeting";
import Error from "./Tabs/error/error";

//запросы к api
import { RN_PLATFORM_CREATE } from "./../../GraphQL/RNmutations";
import { RN_PLATFORM_UPDATE } from "./../../GraphQL/RNmutations";
import { RN_UPLOAD_FILE } from "./../../GraphQL/RNmutations";
import { RN_PLATFORM } from "./../../GraphQL/RNqueries";

import { ApolloConsumer, graphql } from "react-apollo";
import { Mutation } from "react-apollo";

//доступы
import verify, { content_managers, buyers } from "./access/access";

//moment
const moment = require("moment/min/moment-with-locales.js");

//парсинг запроса
const queryString = require("query-string");

class Platform extends Component {
  state = {
    data: {},
    logo: [],
    files: [],
    priceFile: [],
    technical_requirements_publication_file: [],
    technical_requirements_banner_file: [],
    buyer_note_file: [],
    success: false,
    platform: false,
  };

  static getDerivedStateFromProps(props, oldState) {
    //получение данных от сервера
    let updateForState = {};

    if (!oldState.platform) {
      updateForState = {
        data: {
          ...props.platform,
          authorId: props.user.id,
        },
        platform: true,
        logo: props.platform.logo,
        files: props.platform.files,
        priceFile: props.platform.priceFile,
        technical_requirements_publication_file:
          props.platform.technical_requirements_publication_file,
        technical_requirements_banner_file:
          props.platform.technical_requirements_banner_file,
        buyer_note_file: props.platform.buyer_note_file,
      };

      if (props.platform.DiscountItems.length) {
        for (let i of updateForState.data.DiscountItems) {
          delete i["__typename"];
        }
      }

      if (props.platform.MarkUpItems.length) {
        for (let i of props.platform.MarkUpItems) {
          delete i["__typename"];
        }
      }

      if (props.platform.SupercomItems.length) {
        for (let i of props.platform.SupercomItems) {
          delete i["__typename"];
        }
      }

      if (props.platform.CoefficientItems.length) {
        for (let i of props.platform.CoefficientItems) {
          delete i["__typename"];
        }
      }

      if (props.platform.Price) {
        delete props.platform.Price["__typename"];
      }

      delete updateForState.data.updateDate;
      updateForState.data.logo = updateForState.data.logo.map(
        (item) => item.id
      );
      updateForState.data.files = updateForState.data.files.map(
        (item) => item.id
      );
      updateForState.data.priceFile = updateForState.data.priceFile.map(
        (item) => item.id
      );
      updateForState.data.technical_requirements_banner_file = updateForState.data.technical_requirements_banner_file.map(
        (item) => item.id
      );
      updateForState.data.technical_requirements_publication_file = updateForState.data.technical_requirements_publication_file.map(
        (item) => item.id
      );
      updateForState.data.buyer_note_file = updateForState.data.buyer_note_file.map(
        (item) => item.id
      );

      //удаление полей, которые помешают при обновлении
      delete updateForState.data.InventoryItems;
      delete updateForState.data.Thematics;
      delete updateForState.data.Coverage;
      delete updateForState.data.NetworkPartner;
      delete updateForState.data.Owner;
      delete updateForState.data.Seller;
      delete updateForState.data.City;
      delete updateForState.data.Region;
      delete updateForState.data.MediaType;
      delete updateForState.data.MediaGroup;
      delete updateForState.data.User;
      delete updateForState.data.ContactsItems;
      delete updateForState.data.CheckUser;

      if (updateForState.data.CityList && updateForState.data.CityList.length) {
        updateForState.data.city = updateForState.data.CityList.map(
          (item) => item.AOGUID
        );
      }
      delete updateForState.data.CityList;

      if (
        updateForState.data.RegionList &&
        updateForState.data.RegionList.length
      ) {
        updateForState.data.region = updateForState.data.RegionList.map(
          (item) => item.AOGUID
        );
      }
      delete updateForState.data.RegionList;

      updateForState.priceAuthor = updateForState.data.Price
        ? updateForState.data.Price.User
        : null;
      updateForState.data.Price && delete updateForState.data.Price.User;

      updateForState._discountItems = [];
      updateForState._supercomItems = [];

      for (let i of updateForState.data.DiscountItems) {
        updateForState._discountItems.push({
          author: i.User,
          inventory: i.Inventory
            ? {
                value: i.Inventory.id,
                label: i.Inventory.name,
              }
            : false,
        });

        delete i.User;
        delete i.Inventory;
      }

      for (let i of updateForState.data.SupercomItems) {
        updateForState._supercomItems.push(i.User);
        delete i.User;
      }

      delete updateForState.data["__typename"];
    }

    return updateForState;
  }

  /*Файл для загрузки*/
  renderFileInput() {
    return (
      <div className="row">
        <Mutation mutation={RN_UPLOAD_FILE}>
          {(mutate) => (
            <input
              type="file"
              className="form-control"
              required
              multiple
              onChange={({ target: { validity, files } }) => {
                //validity.valid &&
                //сделал две ветки на отображение и загрузку
                for (let i = 0; i < files.length; i++) {
                  mutate({ variables: { file: files[i] } }).then((res) => {
                    if (this.state.data.logo) {
                      this.setState((state) => ({
                        data: {
                          ...state.data,
                          logo: [res.data.RnUploadFile.id],
                        },
                        logo: [res.data.RnUploadFile],
                      }));
                    } else {
                      this.setState((state) => ({
                        data: {
                          ...state.data,
                          logo: [res.data.RnUploadFile.id],
                        },
                        logo: [res.data.RnUploadFile],
                      }));
                    }
                  });
                }
              }}
            />
          )}
        </Mutation>
      </div>
    );
  }

  //отправка данных
  dataSend() {
    let discountItems = this.state.data.DiscountItems;
    let supercomItems = this.state.data.SupercomItems;

    for (let i of discountItems) {
      if (i.User) delete i.User;
    }

    for (let i of supercomItems) {
      if (i.User) delete i.User;
    }

    this.setState((state) => ({
      ...state,
      data: {
        ...state.data,
        SupercomItems: supercomItems,
        DiscountItems: discountItems,
      },
      formErrors: [],
    }));

    this.props
      .createPlatform({
        variables: {
          platform: this.state.data,
        },
        refetchQueries: [`RnPlatform`],
      })
      .then(({ data }) => {
        this.setState({
          ...this.state,
          data: {
            ...this.state.data,
          },
          refresher: true,
          success: true,
          disabled: false,
        });

        //таймер отключения сообщения

        setTimeout(() => {
          this.setState({
            ...this.state,
            data: {
              ...this.state.data,
            },
            refresher: true,
            success: false,
            disabled: false,
          });
        }, 3000);
      })
      .catch((res) => {
        if (res.graphQLErrors[0].extensions.category === "FormDataError") {
          const formErrors = res.graphQLErrors[0].message.split("\n");
          this.setState({
            formErrors,
          });
        } else {
          console.warn(res);
        }
      });
  }

  componentDidMount() {
    this.setState((state) => ({
      data: {
        ...state.data,
        updateDate: moment().format(),
      },
    }));
  }

  render() {
    let save_disabled = !verify(content_managers, this.props.src_data.me.email);
    save_disabled = false;

    let path = "";

    if (this.state.logo.length) {
      path = this.state.logo[this.state.logo.length - 1].path;
      // path = path.replace('/rn/','http://rn.lab.delta-plan.ru/')
    }

    return (
      <>
        {this.state.success && (
          <div
            style={{
              position: "fixed",
              width: "50%",
              top: "20px",
              zIndex: "40",
              right: "0px",
            }}
          >
            <div
              className="alert alert-success alert-dismissible alert-dismissible fade show"
              role="alert"
            >
              <strong>Карточка площадки обновлена!</strong>
              <button
                type="button"
                className="close"
                data-dismiss="alert"
                aria-label="Close"
                onClick={() => {
                  this.setState({ success: false });
                }}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
          </div>
        )}
        <Header {...this.props} {...this.state} />
        <section className="content">
          <div className="container-fluid mediaplans-list-block mt-2">
            <div className="table-container">
              <div className="row">
                <div className="col-1">
                  {this.state.logo.length > 0 ? (
                    <img
                      data-toggle="modal"
                      data-target="#logo"
                      alt="Логотип"
                      style={{
                        width: "145px",
                        height: "auto",
                      }}
                      src={path}
                    />
                  ) : (
                    <button
                      type="button"
                      className="btn btn-primary mt-3"
                      data-toggle="modal"
                      data-target="#logo"
                    >
                      Загрузить Лого
                    </button>
                  )}
                </div>
                <div className="col-2">
                  <label>Название площадки</label>
                  <input
                    name="name"
                    className="form-control"
                    type="text"
                    value={this.state.data.name ? this.state.data.name : ""}
                    onChange={(e) => {
                      const value = e.target.value;
                      this.setState((state) => ({
                        data: {
                          ...state.data,
                          draft: 0,
                          name: value,
                        },
                      }));
                    }}
                  />
                  <label className="mt-1">
                    <input
                      type="checkbox"
                      checked={this.state.data.hidden === 1}
                      onChange={(e) => {
                        const value = e.target.checked ? 1 : 0;
                        this.setState((state) => ({
                          data: {
                            ...state.data,
                            hidden: value,
                          },
                        }));
                      }}
                    />{" "}
                    Удалить
                  </label>
                </div>
                <div className="col-2">
                  <label>Адекватность</label>
                  <select
                    name="adequate"
                    className="form-control"
                    value={
                      this.state.data.adequate ? this.state.data.adequate : ""
                    }
                    onChange={(e) => {
                      const value = +e.target.value;
                      this.setState({
                        ...this.state,
                        data: {
                          ...this.state.data,
                          adequate: value,
                        },
                      });
                    }}
                  >
                    <option hidden>Выбрать</option>
                    <option value="1">Красный</option>
                    <option value="2">Желтый</option>
                    <option value="3">Зеленый</option>
                  </select>
                  <label htmlFor="">
                    <input
                      type="checkbox"
                      checked={this.state.data.isSubBuyer}
                      onChange={(e) => {
                        const value = e.target.checked;
                        this.setState((state) => ({
                          data: {
                            ...state.data,
                            isSubBuyer: value,
                            Price: {
                              ...state.data.Price,
                              comment_2:
                                state?.data?.Price?.comment_2 +
                                " Обращаться в ЦК",
                            },
                          },
                        }));
                      }}
                    />{" "}
                    Площадка суббайеров
                  </label>
                </div>
                <div className="col-2">
                  <label>Адекватность: комментарий</label>
                  <input
                    name="adequate_comment"
                    type="text"
                    className="form-control"
                    value={
                      this.state.data.adequate_comment
                        ? this.state.data.adequate_comment
                        : ""
                    }
                    onChange={(e) => {
                      const value = e.target.value;
                      this.setState({
                        ...this.state,
                        data: {
                          ...this.state.data,
                          [e.target.name]: value,
                        },
                      });
                    }}
                  />
                </div>
                <div className="col-2">
                  <label>Комментарий по контенту</label>
                  <input
                    name="content_comment"
                    type="text"
                    className="form-control"
                    value={
                      this.state.data.content_comment
                        ? this.state.data.content_comment
                        : ""
                    }
                    onChange={(e) => {
                      const value = e.target.value;
                      this.setState({
                        ...this.state,
                        data: {
                          ...this.state.data,
                          [e.target.name]: value,
                        },
                      });
                    }}
                  />
                  {/*<input*/}
                  {/*    type="text"*/}
                  {/*    name="updateDate"*/}
                  {/*    className="form-control"*/}
                  {/*    placeholder="Дата обновления"*/}
                  {/*    value={ this.props.platform ? moment(this.props.platform.updateDate).format('DD.MM.YYYY HH:mm') : "" }*/}
                  {/*    disabled={ true }*/}
                  {/*/>*/}
                </div>
                <div className="col-3">
                  <label>Тематика</label>
                  <ApolloConsumer>
                    {(client) => (
                      <SelectThematics
                        client={client}
                        isMulti={true}
                        value={this.state.data?.thematics?.map((t) => ({
                          value: t.id,
                          label: t.name,
                        }))}
                        onChange={(values) => {
                          this.setState({
                            ...this.state,
                            data: {
                              ...this.state.data,
                              thematics: values,
                            },
                          });
                        }}
                      />
                    )}
                  </ApolloConsumer>
                </div>
              </div>
              <TabBarContainer
                parentState={this.state}
                parentProps={this.props}
                setParentState={(newState) => {
                  this.setState((state) => ({
                    data: {
                      ...state.data,
                      draft: 0,
                      ...newState,
                    },
                  }));
                }}
              >
                <Info
                  label={"info"}
                  rusLabel={"Информация"}
                  parentState={this.state}
                  parentProps={this.props}
                  setParentState={(newState) => {
                    this.setState((state) => ({
                      data: {
                        ...state.data,
                        draft: 0,
                        ...newState,
                      },
                    }));
                  }}
                />
                <Inventory
                  label={"inventory"}
                  rusLabel={"Инвентарь"}
                  parentState={this.state}
                  parentProps={this.props}
                />
                <Discount
                  label={"discount"}
                  rusLabel={"Скидки"}
                  parentState={this.state}
                  parentProps={this.props}
                  GetDiscountItems={(DiscountItems) => {
                    this.setState({
                      data: {
                        ...this.state.data,
                        DiscountItems: DiscountItems,
                      },
                    });
                  }}
                  setParentState={(newState) => {
                    this.setState((state) => ({
                      data: {
                        ...state.data,
                        draft: 0,
                        ...newState,
                      },
                    }));
                  }}
                />
                {/*<MarkUp*/}
                {/*label={'markup'}*/}
                {/*rusLabel={'Наценки'}*/}
                {/*parentState = {this.state}*/}
                {/*parentProps = {this.props}*/}
                {/*GetMarkUpItems = {(MarkUpItems)=>{*/}
                {/*this.setState({*/}
                {/*data: {*/}
                {/*...this.state.data,*/}
                {/*MarkUpItems: MarkUpItems*/}
                {/*}*/}
                {/*});*/}
                {/*}}*/}
                {/*setParentState = {*/}
                {/*newState => {*/}
                {/*this.setState(state => ({*/}
                {/*data: {*/}
                {/*...state.data,*/}
                {/*draft: 0,*/}
                {/*...newState*/}
                {/*}*/}
                {/*}));*/}
                {/*}*/}
                {/*}*/}
                {/*/>*/}
                <Contacts
                  label={"contacts"}
                  rusLabel={"Контакты"}
                  parentState={this.state}
                  parentProps={this.props}
                  GetContactsItems={(ContactsItems) => {
                    this.setState({
                      data: {
                        ...this.state.data,
                        draft: 0,
                        ContactsItems: ContactsItems,
                      },
                    });
                  }}
                  setParentState={(newState) => {
                    this.setState((state) => ({
                      data: {
                        ...state.data,
                        draft: 0,
                        ...newState,
                      },
                    }));
                  }}
                />
                <Audience
                  label={"audience"}
                  rusLabel={"Целевая аудитория"}
                  parentState={this.state}
                  parentProps={this.props}
                  setParentState={(newState) => {
                    this.setState((state) => ({
                      data: {
                        ...state.data,
                        draft: 0,
                        ...newState,
                      },
                    }));
                  }}
                />
                <Supercom
                  label={"supercom"}
                  rusLabel={"Суперком"}
                  parentState={this.state}
                  parentProps={this.props}
                  GetSupercomItems={(SupercomItems) => {
                    this.setState({
                      data: {
                        ...this.state.data,
                        SupercomItems: SupercomItems,
                      },
                    });
                  }}
                  setParentState={(newState) => {
                    this.setState((state) => ({
                      data: {
                        ...state.data,
                        draft: 0,
                        ...newState,
                      },
                    }));
                  }}
                />
                <Price
                  label={"price"}
                  rusLabel={"Прайс"}
                  parentState={this.state}
                  parentProps={this.props}
                  setParentStateDir={(newState) => {
                    this.setState((state) => ({
                      data: {
                        ...state.data,
                        draft: 0,
                        ...newState,
                      },
                    }));
                  }}
                  setParentState={(newState) => {
                    this.setState((state) => ({
                      data: {
                        ...state.data,
                        draft: 0,
                        Price: {
                          ...state.data.Price,
                          ...newState,
                        },
                      },
                    }));
                  }}
                />
                <Report
                  label={"report"}
                  rusLabel={"Отчет"}
                  {...this.state}
                  {...this.props}
                  filter={[
                    {
                      field: "platform_id",
                      operator: "EQ",
                      values: [this.state.data.id],
                    },
                  ]}
                />
                <Coefficient
                  label={"coefficient"}
                  rusLabel={"Сезонные коэффициенты"}
                  parentState={this.state}
                  parentProps={this.props}
                  GetCoefficientItems={(CoefficientItems) => {
                    this.setState({
                      data: {
                        ...this.state.data,
                        CoefficientItems,
                      },
                    });
                  }}
                  setParentState={(newState) => {
                    this.setState((state) => ({
                      data: {
                        ...state.data,
                        draft: 0,
                        ...newState,
                      },
                    }));
                  }}
                />
                <Targeting
                  {...this.state}
                  {...this.props}
                  label={"targeting"}
                  rusLabel={"Глобальные таргетинги"}
                  setParentState={(newState) => {
                    this.setState((state) => ({
                      data: {
                        ...state.data,
                        draft: 0,
                        ...newState,
                      },
                    }));
                  }}
                />
                <Error
                  {...this.state}
                  {...this.props}
                  label={"error"}
                  rusLabel={"Ошибки"}
                  setParentState={(newState) => {
                    this.setState((state) => ({
                      data: {
                        ...state.data,
                        draft: 0,
                        ...newState,
                      },
                    }));
                  }}
                />

                {/*<Documents*/}
                {/*label={'documents'}*/}
                {/*rusLabel={'Документы'}*/}
                {/*parentState = {this.state}*/}
                {/*parentProps = {this.props}*/}
                {/*setParentState = {*/}
                {/*newState => {*/}
                {/*this.setState(state => ({*/}
                {/*data: {*/}
                {/*...state.data,*/}
                {/*draft: 0,*/}
                {/*...newState*/}
                {/*}*/}
                {/*}));*/}
                {/*}*/}
                {/*}*/}
                {/*/>*/}
              </TabBarContainer>
              <hr />
              <div className="text-right mt-2 mr-1">
                <Link className="btn btn-primary float-left" to="/">
                  Вернуться к списку
                </Link>

                {verify(buyers, this.props.user.email) ? (
                  <button
                    onClick={() => {
                      this.setState(
                        (state) => ({
                          data: {
                            ...state.data,
                            checked: 1,
                            check_user: this.props.user && this.props.user.id,
                            check_date: moment().format(),
                          },
                        }),
                        this.dataSend.bind(this)
                      );
                    }}
                    className="btn mr-1"
                    style={{
                      backgroundColor: "#7d35dc",
                      color: "#ffffff",
                    }}
                  >
                    Проверено байером
                  </button>
                ) : null}

                <button
                  onClick={() => {
                    this.setState(
                      (state) => ({
                        data: {
                          ...state.data,
                        },
                        disabled: true,
                      }),
                      this.dataSend.bind(this)
                    );
                  }}
                  disabled={this.state.disabled || save_disabled}
                  className="btn btn-success"
                >
                  Сохранить
                </button>
              </div>
              <div className="text-right mt-1 mr-1">
                {this.state.data.checked ? (
                  <>
                    Проверено байером:{" "}
                    {this.props.platform.CheckUser &&
                      this.props.platform.CheckUser.login}
                    <br />
                    Дата проверки{" "}
                    {moment(this.state.data.check_date).format("D-MM-Y")}
                  </>
                ) : null}
              </div>
            </div>
          </div>
        </section>
        <footer className="bg-light"></footer>
        <div
          className="modal fade"
          id="logo"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Загрузка логотипа
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">{this.renderFileInput()}</div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Закрыть
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

class TabBarContainer extends Component {
  state = {
    activeTab: 0,
  };

  static getDerivedStateFromProps(props, state) {
    const TAB = queryString.parse(document.location.hash).tab;

    let NewState = {};
    if (!state.updated) NewState.activeTab = 0;

    let arrayOfChildren = [];
    props.children.map((item, mapIndex) => {
      let arrayObject = {};
      arrayObject.name = item.props.label;
      arrayObject.index = mapIndex;
      arrayOfChildren.push(arrayObject);
      return null;
    });

    if (!state.updated) NewState.tabList = arrayOfChildren;
    if (!state.updated && TAB)
      NewState.activeTab = NewState.tabList.filter(
        (item) => item.name === TAB.split("#token")[0]
      )[0].index;

    NewState.updated = true;
    return NewState;
  }

  onChangeHandle(label, index) {
    this.setState({
      activeTab: index,
    });
  }

  render() {
    return (
      <>
        <div className="header-controls mb-3 pt-3">
          <ul
            className="nav nav-pills nav-justified nav-tabs"
            id="myTab"
            role="tablist"
          >
            {this.props.children.map((item, index) => (
              <li key={`tabBarNav#${index}`} className="nav-item">
                <a
                  href="#"
                  className={
                    index === this.state.activeTab
                      ? "nav-link active show"
                      : "nav-link"
                  }
                  onClick={(e) => {
                    e.preventDefault();
                    this.onChangeHandle(item.props.label, index);
                    document.location.hash = `tab=${item.props.label}`;
                  }}
                >
                  {item.props.rusLabel}
                </a>
              </li>
            ))}
          </ul>
        </div>
        <div>
          {this.props.children.map((item, index) => {
            return (
              <TabContext.Provider
                key={`tab-content-${index}`}
                value={this.props.parentState}
              >
                {this.state.activeTab === index && item}
              </TabContext.Provider>
            );
          })}
        </div>
      </>
    );
  }
}

/*добавление*/
const PlatformWithSave = graphql(RN_PLATFORM_CREATE, {
  props: ({ mutate }) => {
    return {
      createPlatform: mutate,
    };
  },
  options: (props) => ({
    refetchQueries: ["RnPlatform"],
  }),
})(Platform);

export default PlatformWithSave;

/*изменение*/
export const PlatformEdit = graphql(RN_PLATFORM, {
  props: ({ data }) => {
    return {
      loading: data.loading,
      error: data.error,
      platform: data.RnPlatform ? data.RnPlatform : null,
      user: data.me ? data.me : null,
      src_data: data,
    };
  },
  options: (props) => ({
    variables: {
      id: props.match.params.id,
    },
  }),
})((props) => {
  if (props.loading) return null;
  if (props.error) return null;
  if (props.platform == null) {
    console.log("loading bad result ", props);
    return null;
  }

  return <Platform {...props} />;
});

export const PlatformEditWithSave = graphql(RN_PLATFORM_UPDATE, {
  props: ({ mutate }) => {
    return {
      createPlatform: mutate,
    };
  },
  options: (props) => ({}),
})(PlatformEdit);
