import React from "react";
import DropDown from "../../../../../components/styled/dropdown";

//типы инвентаря
import InventoryItem from "./InventoryItem";

import { Link } from "react-router-dom";

export default class Inventory extends React.Component {
  render() {
    return (
      <>
        <div className="float-right mt-2 mr-4">
          <div className="float-right mb-2">
            <DropDown
              right={0}
              linkClass={"dropdown-toggle"}
              label={"Добавить инвентарь"}
            >
              <Link
                className="dropdown-item"
                to={`/platform/inventory/add/banner/${this.props.parentState.data.id}`}
              >
                Баннер
              </Link>
              <Link
                className="dropdown-item"
                to={`/platform/inventory/add/card/${this.props.parentState.data.id}`}
              >
                Карточка
              </Link>
              <Link
                className="dropdown-item"
                to={`/platform/inventory/add/publication/${this.props.parentState.data.id}`}
              >
                Размещение публикаций и пресс-релизов
              </Link>
              <Link
                className="dropdown-item"
                to={`/platform/inventory/add/special/${this.props.parentState.data.id}`}
              >
                Эксклюзив / спец.проекты
              </Link>
              <Link
                className="dropdown-item"
                to={`/platform/inventory/add/additional/${this.props.parentState.data.id}`}
              >
                Доп. услуги
              </Link>
            </DropDown>
          </div>
        </div>
        <div className="tab-content" id="myTabContent">
          <div
            className="tab-pane fade show active"
            id="contact"
            role="tabpanel"
            aria-labelledby="contact-tab"
          >
            <table className="mt-3 table table-borderless table-striped table-hover new-table-name">
              <thead className="thead-light">
                <tr>
                  <th
                    className="font-weight-bold align-middle"
                    style={{
                      width: "50px",
                      minWidth: "50px"
                    }}
                  >
                    ID
                  </th>
                  <th className="font-weight-bold align-middle">
                    Сезонник применяется
                  </th>
                  <th className="font-weight-bold align-middle">
                    Применять таргетинг
                  </th>
                  <th className="font-weight-bold align-middle">Название</th>
                  <th className="font-weight-bold align-middle">Превью</th>
                  <th className="font-weight-bold align-middle">
                    Вид, формат размещения
                  </th>
                  <th className="font-weight-bold align-middle">
                    Тип инвентаря
                  </th>
                  <th className="font-weight-bold align-middle">Таргетинги</th>
                  <th className="font-weight-bold align-middle">Рейтинг</th>
                  <th className="font-weight-bold align-middle">Цены</th>
                  <th className="font-weight-bold align-middle">
                    Минимальное количество показов
                  </th>
                  <th className="font-weight-bold align-middle">
                    Отредактировано
                  </th>
                  <th className="font-weight-bold align-middle">
                    Налогообложение
                  </th>
                  <th
                    style={{
                      minWidth: "130px"
                    }}
                  ></th>
                </tr>
              </thead>
              <tbody>
                {this.props.parentProps.platform &&
                  this.props.parentProps.platform.InventoryItems.map(
                    (item, index) => (
                      <InventoryItem
                        {...this.props.parentProps.platform}
                        key={`inventoryItem#${index}`}
                        indexKey={index}
                        inventoryItem={item}
                        platform={{ ...this.props.parentProps.platform }}
                        me={
                          this.props.parentProps.src_data &&
                          this.props.parentProps.src_data.me
                        }
                      />
                    )
                  )}
              </tbody>
            </table>
          </div>
        </div>
      </>
    );
  }
}
