import React from "react";
import { ApolloConsumer } from "react-apollo";
import DatePicker from "react-date-picker";
//@ts-ignore
import AsyncSelect from "react-select/lib/Async";
import { selectQuery } from "../../RN/Components/Briefing/gql/query";
import HistoryReport from "../../RN/Components/Reports/MpItemHistoryReport";
import { useMpReportXlsLazyQuery } from "../../graphql/types";
const download = require("downloadjs");
interface IMpHistory {
  client: any;
}

function MpHistory({ client }: IMpHistory) {
  const [state, setState] = React.useState<any>({
    count: 10,
    offset: 0,
    start_date: new Date(),
    end_date: new Date(),
    filter: [],
    render_filter: null,
  });

  const [getReport] = useMpReportXlsLazyQuery({
    client,
    variables: {
      dateStart: state.start_date,
      dateFinish: state.end_date,
      brandID: state.filter.filter((f: any) => f.field === "brand_id")
        ?.values[0],
      partnerID: state.filter.filter((f: any) => f.field === "partner_id")
        ?.values[0],
      buyerID: state.filter.filter((f: any) => f.field === "responsible_id")
        ?.values[0],
    },
    onCompleted: (res) => {
      if (res?.RN?.mpReportXls) {
        const fileName = `${
          state.start_date
            ? new Date(state.start_date).toLocaleDateString()
            : ""
        } ${
          state.end_date
            ? "-" + new Date(state.end_date).toLocaleDateString()
            : ""
        }.xlsx`;

        const file = window.atob(res?.RN?.mpReportXls);
        download(file, fileName, "application/vnd.ms-excel");
      }
    },
  });

  return (
    <>
      <div className="filters row my-3">
        <div className="col-6 row">
          <label className="col-6 col-form-label">
            Дата создания медиаплана
          </label>
          <div className="col-6">
            <label htmlFor="">
              {" "}
              от
              <DatePicker
                onChange={(date) => {
                  setState({
                    ...state,
                    start_date: date,
                  });
                }}
                value={state.start_date}
                format={"dd.MM.y"}
                calendarIcon={null}
              />
            </label>
            &nbsp;
            <label htmlFor="">
              {" "}
              до
              <DatePicker
                onChange={(date) => {
                  setState({
                    ...state,
                    end_date: date,
                  });
                }}
                value={state.end_date}
                format={"dd.MM.y"}
                calendarIcon={null}
              />
            </label>
          </div>
        </div>
        <div className="col-6 row">
          <label className="col-4 col-form-label">Байер</label>
          <div className="col-8">
            <ApolloConsumer>
              {(client) => (
                <AsyncSelect
                  name="manager_id"
                  value={state.valueResponsible ? state.valueResponsible : null}
                  placeholder={"Выбрать"}
                  loadOptions={(inputValue: any) =>
                    selectQuery(
                      inputValue,
                      "RefEmployeeList",
                      client,
                      "manager"
                    )
                  }
                  onChange={(responsible: any) => {
                    const value = responsible.value;
                    setState({
                      ...state,
                      valueResponsible: responsible,
                      filter: [
                        ...state.filter,
                        {
                          field: "responsible_id",
                          operator: "EQ",
                          values: [value],
                        },
                      ],
                    });
                  }}
                />
              )}
            </ApolloConsumer>
          </div>
        </div>
      </div>
      <div className="filters row my-3">
        <div className="col-6 row">
          <label className="col-4 col-form-label">Бренд</label>
          <div className="col-8">
            <ApolloConsumer>
              {(client) => (
                <AsyncSelect
                  name="brand_id"
                  value={state.valueBrand ? state.valueBrand : null}
                  placeholder={"Выбрать"}
                  loadOptions={(inputValue: any) =>
                    selectQuery(inputValue, "MLBrandList", client)
                  }
                  onChange={(brand: any) => {
                    const value = brand.value;
                    setState({
                      ...state,
                      valueBrand: brand,
                      filter: [
                        ...state.filter,
                        {
                          field: "brand_id",
                          operator: "EQ",
                          values: [value],
                        },
                      ],
                    });
                  }}
                />
              )}
            </ApolloConsumer>
          </div>
        </div>
        <div className="col-6 row">
          <label className="col-4 col-form-label">Клиент</label>
          <div className="col-8">
            <AsyncSelect
              name="partner_id"
              value={state.valuePartner ? state.valuePartner : null}
              placeholder={"Выбрать"}
              loadOptions={(inputValue: any) =>
                selectQuery(inputValue, "MLPartnerList", client)
              }
              onChange={(partner: any) => {
                const value = partner.value;
                setState({
                  ...state,
                  valuePartner: partner,
                  filter: [
                    ...state.filter,
                    {
                      field: "partner_id",
                      operator: "EQ",
                      values: [value],
                    },
                  ],
                });
              }}
            />
          </div>
        </div>
      </div>
      <div className="btn-group mt-3">
        <button
          className="btn btn-primary btn-sm"
          onClick={() => {
            setState({
              ...state,
              render_filter: [
                ...state.filter,
                {
                  field: "date",
                  operator: "PERIOD",
                  values: [
                    state.start_date.toISOString(),
                    state.end_date.toISOString(),
                  ],
                },
              ],
            });
          }}
        >
          Применить фильтры
        </button>
        <button
          className="btn btn-secondary ml-1 btn-sm"
          onClick={() => {
            setState({
              ...state,
              start_date: new Date(),
              end_date: new Date(),
              render_filter: null,
              valueResponsible: null,
              valuePartner: null,
              valueBrand: null,
            });
          }}
        >
          Сбросить
        </button>

        <button
          className="btn btn-sm ml-1 btn-success"
          onClick={() => {
            getReport();
          }}
        >
          Выгрузить в эксель
        </button>
      </div>
      <HistoryReport
        filter={state.render_filter}
        count={state.count}
        offset={state.offset}
      />
    </>
  );
}
const MpItemHistoryReportContainer = (props: any) => {
  return (
    <ApolloConsumer>
      {(client) => {
        return <MpHistory client={client} {...props} />;
      }}
    </ApolloConsumer>
  );
};

export default MpItemHistoryReportContainer;
