import React from "react";
import Header from "../template/Header";
import { ApolloConsumer } from "react-apollo";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { ADD_SETTING, GET_CONTENT_MANAGER_EMAIL } from "./settings/gql";

function Settings(props: any) {
  const { data } = useQuery(GET_CONTENT_MANAGER_EMAIL, {
    client: props.client,
  });

  const [email, setEmail] = React.useState<string>("");

  const [updateSetting] = useMutation(ADD_SETTING, {
    client: props.client,
  });

  return (
    <>
      <Header {...props} />
      <div className="content">
        <div className="row col-12 mt-3">
          <div className="col-6">
            <table className="table table-bordered">
              <tbody>
                <tr>
                  <td>e-mail контент-менеджера</td>
                  <td>
                    <input
                      defaultValue={data && data.RN.getContentManagerEmail}
                      type="text"
                      className="form-control"
                      onChange={(e) => {
                        const VALUE = e.target.value;
                        setEmail(VALUE);
                      }}
                    />
                  </td>
                  <td className="text-center">
                    <button
                      className="btn btn-success"
                      onClick={() => {
                        updateSetting({
                          variables: {
                            field: "email",
                            value: email,
                          },
                        });
                      }}
                    >
                      Сохранить
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}

const SettingsContainer = (props: any) => {
  return (
    <ApolloConsumer>
      {(client) => <Settings {...props} client={client} />}
    </ApolloConsumer>
  );
};

export default SettingsContainer;
