import React from "react";
import City from "./city.js";
import { GET_LOCALITY_LIST } from "./geoQueries";
import { Query } from "react-apollo";

const CityList = ({
  parentGuid,
  geo_data,
  handleChangeGeoData,
  handleChangeGeoDataCity,
  handleChangeGeoDataRegion,
  src_subject,
  src_district,
  ulStyle,
  handleChange,
  data_geo_tree,
  tree,
  district_code,
  district,
  subject,
  handleChangeCity,
  indexKeyDistrict,
  indexKeyRegion,
  parentIsChecked
}) => {
  return (
    <Query
      variables={{
        params: {
          count: 50,
          offset: 0,
          filter: [
            {
              field: "PARENTGUID",
              operator: "EQ",
              values: [parentGuid]
            }
          ]
        }
      }}
      query={GET_LOCALITY_LIST}
    >
      {({ loading, error, data }) => {
        if (loading) return null;
        if (error) return `Error! ${error.message}`;

        return (
          <ul style={ulStyle}>
            {data.Geo.LocalityList.map((i, index) => (
              <City
                key={i.AOGUID}
                item={i}
                parentGuid={parentGuid}
                district_code={district_code}
                handleChange={handleChange}
                data_geo_tree={data_geo_tree}
                tree={tree}
                district={district}
                subject={subject}
                handleChangeCity={handleChangeCity}
                indexKeyDistrict={indexKeyDistrict}
                indexKeyRegion={indexKeyRegion}
                indexKeyCity={index}
                parentIsChecked={parentIsChecked}
                src_district={src_district}
                src_subject={src_subject}
                geo_data={geo_data}
                handleChangeGeoData={handleChangeGeoData}
                handleChangeGeoDataCity={handleChangeGeoDataCity}
                handleChangeGeoDataRegion={handleChangeGeoDataRegion}
              />
            ))}
          </ul>
        );
      }}
    </Query>
  );
};

export default CityList;
