import gql from "graphql-tag";

export const GET_INVENTORY_PAGE_LIST = gql`
  query($params: ParamsInput) {
    RN {
      RnInventoryPageList(params: $params) {
        id
        name
      }
    }
  }
`;
