import gql from "graphql-tag";

export const RN_MP_UPDATE_FIELD = gql`
  mutation RnMpUpdateField($id: Int, $field: String, $value: Float) {
    RnMpUpdateField(id: $id, field: $field, value: $value) {
      id
    }
  }
`;

export const RN_MP_UPDATE_FIELD_STRING = gql`
  mutation RnMpUpdateFieldString($id: Int, $field: String, $value: String) {
    RnMpUpdateFieldString(id: $id, field: $field, value: $value) {
      id
    }
  }
`;

export const RN_MP_ITEM_UPDATE_FIELD = gql`
  mutation RnMpItemUpdateField($id: Int, $field: String, $value: Float) {
    RnMpItemUpdateField(id: $id, field: $field, value: $value) {
      id
      margin
      unitCostNoVat
      totalCostNoVat
      totalCostNoVatDiscount
      totalCostVatDiscount
      minPurchaseCount
      totalCostPurchaseDiscount
      profitability
      totalCostPurchaseDiscountVat
      purchaseNet
      purchaseGross
      sellNet
      sellGross
      ac
      acVatValue
      cpc
      viewCost
      discount_purchase
      inOrder
      inventory_id
      inventoryName
      geo
      price_type
      purchase_count
      discount
      clicks
      showing
      status
      startDate
      endDate
      is_change
      is_change_comment
      payment_delay_value
      payment_delay
      view
      ctr
      coefficient_off
      Info {
        id
        unit_price_cost
        price_type
        purchase_gross
        sell_gross
        purchase_net
        sell_net
        margin
        profitability
        total_cost_no_vat
        total_cost_vat_discount
        total_cost_no_vat_discount
        total_cost_purchase_discount
        total_cost_purchase_discount_vat
        unit_cost_no_vat
        agent_commission
        ac_vat_rate_piece
        discount
        view_cost
        cpc
        discount_purchase
      }
      Platform {
        id
        name
        payment_delay
        payment_delay_value
        CoefficientItems {
          id
          month
          coefficient
        }
        ContactsItems {
          id
          fullName
          platformId
          phone
          email
          position
          responsible
          processType
        }
        City {
          AOGUID
          FORMALNAME
        }
        DiscountItems {
          id
          discountTo
          discountFrom
          accrualBasis
          discountAmount
        }
        Price {
          id
          vat
          priceVat
          comment
          comment_2
        }
      }
      InventoryItem {
        id
        name
        id
        pid
        name
        platformId
        format
        type
        startDate
        endDate
        priceCondition
        value
        currency
        vatRate
        vat
        description
        articleTitle
        articleBody
        position
        display_desktop
        display_mobile
        placement_value
        comment
        statistics
        priceType
        price_value
        priceFrom
        priceTo
        price_value_week
        price_value_month
        showing
        clicks
        view
        placement_type
        showing_percent
        specifications
        publication_format
        coefficient_off
        Targeting {
          id
          name
        }
        Pages {
          id
          name
        }
      }
    }
  }
`;

export const RN_INVENTORY_UPDATE_FIELD = gql`
  mutation RnInventoryUpdateField(
    $id: Int
    $field: String
    $value: Int
    $author_id: Int
  ) {
    RnInventoryUpdateField(
      id: $id
      field: $field
      value: $value
      author_id: $author_id
    ) {
      id
    }
  }
`;

export const RN_MP_ITEM_UPDATE_TARGETING_LIST = gql`
  mutation($id: Int, $value: [Int], $field: String) {
    RnMpItemUpdateTargetingList(id: $id, value: $value, field: $field) {
      id
      inventory_id
      price_type
      purchase_count
      discount
      clicks
      showing
      status
      startDate
      endDate
      is_change
      is_change_comment
      payment_delay_value
      payment_delay
      view
      ctr
      coefficient_off
      Info {
        id
        unit_price_cost
        unit_cost_no_vat
        price_type
        purchase_gross
        sell_gross
        purchase_net
        sell_net
        margin
        profitability
        total_cost_no_vat
        total_cost_vat_discount
        total_cost_no_vat_discount
        total_cost_purchase_discount
        total_cost_purchase_discount_vat
        agent_commission
        ac_vat_rate_piece
        discount
        view_cost
        cpc
        discount_purchase
      }
      Platform {
        id
        name
        payment_delay
        payment_delay_value
        CoefficientItems {
          id
          month
          coefficient
        }
        ContactsItems {
          id
          fullName
          platformId
          phone
          email
          position
          responsible
          processType
        }
        City {
          AOGUID
          FORMALNAME
        }
        DiscountItems {
          id
          discountTo
          discountFrom
          accrualBasis
          discountAmount
        }
        Price {
          id
          vat
          priceVat
          comment
          comment_2
        }
      }
      InventoryItem {
        id
        name
        id
        pid
        name
        platformId
        format
        type
        startDate
        endDate
        priceCondition
        value
        currency
        vatRate
        vat
        description
        articleTitle
        articleBody
        position
        display_desktop
        display_mobile
        placement_value
        comment
        statistics
        priceType
        price_value
        priceFrom
        priceTo
        price_value_week
        price_value_month
        showing
        clicks
        view
        placement_type
        showing_percent
        specifications
        publication_format
        coefficient_off
        Targeting {
          id
          name
        }
        Pages {
          id
          name
        }
      }
    }
  }
`;

export const RN_MP_ITEM_UPDATE_FIELD_TEXT = gql`
  mutation RnMpItemUpdateFieldText($id: Int, $field: String, $value: String) {
    RnMpItemUpdateFieldText(id: $id, field: $field, value: $value) {
      id
      inventoryName
      inventory_id
      price_type
      purchase_count
      discount
      clicks
      showing
      status
      startDate
      endDate
      is_change
      is_change_comment
      payment_delay_value
      payment_delay
      view
      ctr
      coefficient_off
      Info {
        id
        unit_price_cost
        price_type
        purchase_gross
        sell_gross
        purchase_net
        sell_net
        margin
        profitability
        total_cost_no_vat
        total_cost_vat_discount
        total_cost_no_vat_discount
        total_cost_purchase_discount
        total_cost_purchase_discount_vat
        unit_cost_no_vat
        agent_commission
        ac_vat_rate_piece
        discount
        view_cost
        cpc
        discount_purchase
      }
      Platform {
        id
        name
        payment_delay
        payment_delay_value
        CoefficientItems {
          id
          month
          coefficient
        }
        ContactsItems {
          id
          fullName
          platformId
          phone
          email
          position
          responsible
          processType
        }
        City {
          AOGUID
          FORMALNAME
        }
        DiscountItems {
          id
          discountTo
          discountFrom
          accrualBasis
          discountAmount
        }
        Price {
          id
          vat
          priceVat
          comment
          comment_2
        }
      }
      InventoryItem {
        id
        name
        id
        pid
        name
        platformId
        format
        type
        startDate
        endDate
        priceCondition
        value
        currency
        vatRate
        vat
        description
        articleTitle
        articleBody
        position
        display_desktop
        display_mobile
        placement_value
        comment
        statistics
        priceType
        price_value
        priceFrom
        priceTo
        price_value_week
        price_value_month
        showing
        clicks
        view
        placement_type
        showing_percent
        specifications
        publication_format
        coefficient_off
        Targeting {
          id
          name
        }
        Pages {
          id
          name
        }
      }
    }
  }
`;

export const RN_GROUP_UPDATE_ACCESS = gql`
  mutation RnGroupUpdateAccess($id: Int, $access: String, $value: Int) {
    RnGroupUpdateAccess(id: $id, access: $access, value: $value) {
      id
    }
  }
`;

export const RN_GROUP_UPDATE = gql`
  mutation RnGroupUpdate(
    $id: Int
    $field: String
    $value: String
    $status: Int
  ) {
    RnGroupUpdate(id: $id, field: $field, value: $value, status: $status) {
      id
    }
  }
`;

export const RN_INVENTORY_REMOVE = gql`
  mutation($id: Int, $author_id: Int) {
    RnInventoryRemove(id: $id, author_id: $author_id) {
      id
    }
  }
`;

export const RN_PLATFORM_CREATE = gql`
  mutation($platform: RnPlatformInput) {
    RnPlatformCreate(platform: $platform) {
      id
    }
  }
`;

export const RN_PLATFORM_CONTACT_CREATE = gql`
  mutation($contact: RnContactsInput) {
    RnPlatformContactCreate(contact: $contact) {
      id
    }
  }
`;

export const RN_PLATFORM_CONTACT_UPDATE = gql`
  mutation($contact: RnContactsInput) {
    RnPlatformContactUpdate(contact: $contact) {
      id
      partner_id
      legal_entity_id
      Comments
    }
  }
`;

export const RN_INVENTORY_CREATE = gql`
  mutation($inventory: RnInventoryItemInput) {
    RnInventoryCreate(inventory: $inventory) {
      id
    }
  }
`;

export const RN_GROUP_CREATE = gql`
  mutation {
    RnGroupCreate {
      id
    }
  }
`;

export const RN_INVENTORY_CLONE = gql`
  mutation($id: Int, $author_id: Int) {
    RnInventoryClone(id: $id, author_id: $author_id) {
      id
    }
  }
`;

export const RN_PLATFORM_UPDATE = gql`
  mutation RnPlatformUpdate($platform: RnPlatformInput) {
    RnPlatformUpdate(platform: $platform) {
      id
      authorId
      name
      draft
      updateDate
      url
      www
      statisticsUrl
      description
      totalAudience
      language
      certificate
      adequate
      adequate_comment
      payment_delay
      federal
      payment_delay_value
      direct_client_rule
      hidden
      checked
      check_user
      check_date
      seller
      content_comment
      targeting {
        id
        name
        coefficient
      }
      Price {
        User {
          id
          login
        }
        id
        platformId
        startDate
        endDate
        updateDate
        platformId
        vat
        vatRate
        measure
        priceVat
        comment
        authorId
        calculatingMethod
        price_url
        comment_2
        technical_requirements_publication
        buyer_note
      }
      priceFile {
        id
        name
      }
      MediaType {
        id
        name
      }
      MediaGroup {
        id
        MediaGroup
      }
      City {
        AOGUID
        FORMALNAME
      }
      Region {
        FIAS_ID
        FORMALNAME
        SHORTNAME
      }
      NetworkPartner {
        id
        name
      }
      Owner {
        id
        name
      }
      Seller {
        id
        name
      }
      Thematics {
        id
        name
      }
      Coverage {
        id
        name
      }
      logo {
        id
        name
        path
      }
      files {
        id
        name
        path
      }
      SupercomItems {
        User {
          id
          login
        }
        id
        platformId
        accrualBasis
        turnoverTo
        turnoverFrom
        authorId
        supercomAmount
        startDate
        updateDate
      }
      MarkUpItems {
        id
        platformId
        markupType
        startDate
        endDate
        updateDate
        apply
        markup
      }
      DiscountItems {
        id
        User {
          id
          login
        }

        name
        subject
        startDate
        inventory_type
        startDate
        endDate
        subject
        accrualBasis
        discountTo
        discountFrom
        discountAmount
        updateDate
        authorId
      }
      ContactsItems {
        id
        fullName
        platformId
        phone
        email
        position
        responsible
        processType
      }
      InventoryItems {
        id
        authorId
        User {
          id
          login
        }
        name
        format
        type
        startDate
        endDate
        platformId
        image {
          id
          path
          name
        }
      }
    }
  }
`;

export const RN_INVENTORY_UPDATE = gql`
  mutation($inventory: RnInventoryItemInput) {
    RnInventoryUpdate(inventory: $inventory) {
      id
      name
      type
      showing
      clicks
      publication_format
      view
      Pages {
        id
        name
      }
      Targeting {
        id
        name
      }
    }
  }
`;

export const RN_CONTACT_REMOVE = gql`
  mutation RnPlatformContactRemove($id: Int) {
    RnPlatformContactRemove(id: $id) {
      id
    }
  }
`;

export const RN_GROUP_REMOVE = gql`
  mutation($id: Int) {
    RnGroupRemove(id: $id) {
      id
    }
  }
`;

//загрузка файлов
export const RN_UPLOAD_FILE = gql`
  mutation($file: Upload) {
    RnUploadFile(file: $file, type: RN_FILES) {
      id
      name
      path
    }
  }
`;

export const RN_BRIEFING_CREATE_FAST = gql`
  mutation($mp_id: Int) {
    RnBriefingCreateFast(mp_id: $mp_id)
  }
`;

export const RN_PLATFORM_UPDATE_FIELD = gql`
  mutation RnPlatformUpdateField($id: Int, $field: String, $value: Float) {
    RnPlatformUpdateField(id: $id, field: $field, value: $value) {
      id
      name
      url
      draft
      checked
      check_date
      adequate
      adequate_comment
      price_is_needed
      Price {
        id
        vat
        priceVat
        comment
      }
      ContactsItems {
        id
      }
      InventoryItems {
        id
      }
      City {
        AOGUID
        FORMALNAME
      }
    }
  }
`;

export const COPY_MP_ITEM = gql`
  mutation CopyMpItem($itemID: Int!) {
    Rn {
      copyItem(itemID: $itemID) {
        id
        margin
        unitCostNoVat
        totalCostNoVat
        totalCostNoVatDiscount
        totalCostVatDiscount
        minPurchaseCount
        totalCostPurchaseDiscount
        profitability
        totalCostPurchaseDiscountVat
        purchaseNet
        purchaseGross
        sellNet
        sellGross
        ac
        acVatValue
        cpc
        viewCost
        discount_purchase
        inOrder
        inventory_id
        inventoryName
        geo
        price_type
        purchase_count
        discount
        clicks
        showing
        status
        startDate
        endDate
        is_change
        is_change_comment
        payment_delay_value
        payment_delay
        view
        ctr
        coefficient_off
        Info {
          id
          unit_price_cost
          price_type
          purchase_gross
          sell_gross
          purchase_net
          sell_net
          margin
          profitability
          total_cost_no_vat
          total_cost_vat_discount
          total_cost_no_vat_discount
          total_cost_purchase_discount
          total_cost_purchase_discount_vat
          unit_cost_no_vat
          agent_commission
          ac_vat_rate_piece
          discount
          view_cost
          cpc
          discount_purchase
        }
        Platform {
          id
          name
          payment_delay
          payment_delay_value
          CoefficientItems {
            id
            month
            coefficient
          }
          ContactsItems {
            id
            fullName
            platformId
            phone
            email
            position
            responsible
            processType
          }
          City {
            AOGUID
            FORMALNAME
          }
          DiscountItems {
            id
            discountTo
            discountFrom
            accrualBasis
            discountAmount
          }
          Price {
            id
            vat
            priceVat
            comment
            comment_2
          }
        }
        InventoryItem {
          id
          name
          id
          pid
          name
          platformId
          format
          type
          startDate
          endDate
          priceCondition
          value
          currency
          vatRate
          vat
          description
          articleTitle
          articleBody
          position
          display_desktop
          display_mobile
          placement_value
          comment
          statistics
          priceType
          price_value
          priceFrom
          priceTo
          price_value_week
          price_value_month
          showing
          clicks
          view
          placement_type
          showing_percent
          specifications
          publication_format
          coefficient_off
          Targeting {
            id
            name
          }
          Pages {
            id
            name
          }
        }
      }
    }
  }
`;
