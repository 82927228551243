import gql from "graphql-tag";

export const GET_PROVIDER_PAYLOAD_LIST = gql`
  query {
    RN {
      providerPayloadItemList {
        platformName
        email
        date
        cityName
        status
      }
    }
  }
`;
