import gql from "graphql-tag";

export const GET_REPORT_LIST = gql`
  query($params: ParamsInput) {
    RN {
      mPlanItemReportList(params: $params) {
        id
        mp_id
        begDate
        endDate
        platformName
        inventoryName
        rCPV
        rCTR
        rClicks
        rConversion
        rCount
        rImpressions
        rLeadImpressions
        rPlan
        rRejects
        rSpend
        rTime
        rTotalCost
        rViewDepth
        rViews
        rVisits
        responsible
        reportDate
        inventoryType
      }
    }
  }
`;
