import React from "react";

interface IVatProps {
  isMulti?: boolean;
  vat?: number;
  onChange: (vat: number) => void;
}

export function Vat({ isMulti, vat, onChange }: IVatProps) {
  if (isMulti) {
    return (
      <select
        defaultValue={vat}
        onChange={(e) => {
          const value = +e.target.value;
          onChange(value);
        }}
      >
        <option value="">Выбрать</option>
        <option value="1">с НДС</option>
        <option value="2">без НДС</option>
      </select>
    );
  } else {
    return (
      <>
        {vat === 1 && "с НДС"}
        {vat === 2 && "без НДС"}
        {!vat && (
          <span className="text-danger">
            Налогообложение инвентаря не выставлено!
          </span>
        )}
      </>
    );
  }
}
