import React, { Component } from "react";
import { graphql } from "react-apollo";
import { RN_GROUP_lIST_PAGER } from "./../../GraphQL/RNqueries";
import { RN_GROUP_UPDATE_ACCESS } from "../../GraphQL/RNmutations";

//todo здесь доступы
const access_areas = [
  {
    label: "Просмотр брифингов",
    value: "brif_view"
  },
  {
    label: "Редактирование брифингов",
    value: "brif_edit"
  },
  {
    label: "Просмотр медиапланов",
    value: "mp_view"
  },
  {
    label: "Редактирование медиапланов",
    value: "mp_edit"
  },
  {
    label: "Просмотр площадок",
    value: "platform_view"
  },
  {
    label: "Редактирование площадок",
    value: "platform_edit"
  }
];

class GroupAccess extends Component {
  render() {
    console.log(this.props);
    return (
      <>
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
          <div className="collapse navbar-collapse">
            <span className="navbar-brand col-2">
              Контроль доступов групп пользователей
            </span>
          </div>
        </nav>
        <section className="content">
          <div className="container-fluid mediaplans-list-block">
            <div className="table-container">
              <div
                className="card"
                style={{
                  height: "calc(100% - 10px)"
                }}
              >
                <div
                  className="card-body mt-0"
                  style={{
                    overflowX: "hidden"
                  }}
                >
                  <div className="row">
                    <table
                      cellSpacing="0"
                      cellPadding="0"
                      className="custom-table"
                    >
                      <thead>
                        <tr>
                          <td
                            className="lighblue-col"
                            style={{
                              background: "#1D85D0"
                            }}
                          >
                            Область видимости
                          </td>
                          {this.props.groupList.map(item => (
                            <td
                              className="lighblue-col"
                              key={`group_id_${item.id}`}
                            >
                              {item.name}
                            </td>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {access_areas.map((accessItem, index) => (
                          <tr key={`access_row_#${index}`}>
                            <td
                              style={{
                                height: "3rem"
                              }}
                              className="close-icon"
                            >
                              {accessItem.label}
                            </td>
                            {this.props.groupList.map(groupItem => {
                              console.log(groupItem);
                              const checked = groupItem.access
                                .split(", ")
                                .some(i => accessItem.value === i);

                              return (
                                <td
                                  className="lighblue-light-col"
                                  key={`access_group_id_${groupItem.id}`}
                                >
                                  <input
                                    type="checkbox"
                                    defaultChecked={checked}
                                    onChange={e => {
                                      const value = e.target.checked ? 1 : 0;
                                      const access = accessItem.value;

                                      this.props.updateAccess({
                                        variables: {
                                          id: groupItem.id,
                                          access: access,
                                          value: value
                                        },
                                        refetchQueries: ["RnGroupListPager"]
                                      });
                                    }}
                                  />
                                </td>
                              );
                            })}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="small-screen">
          <div className="container-fluid mt-5">
            <h1>
              На данном устройстве работа не возможна. Используйте устройство с
              более широким экраном.
            </h1>
          </div>
        </div>
      </>
    );
  }
}

export const GroupAccessContainerGroupsList = graphql(RN_GROUP_lIST_PAGER, {
  options: props => ({
    variables: {
      params: {
        offset: 0,
        count: 10
      }
    }
  }),
  props: ({ data }) => {
    return {
      loading: data.loading,
      error: data.error,
      groupList:
        data.RN && data.RN.GroupListPager ? data.RN.GroupListPager.data : null
    };
  }
})(props => {
  if (props.loading) return null;
  if (props.error) return null;
  if (props.groupList == null) {
    console.log("loading bad result", props);
    return null;
  }

  return <GroupAccess {...props} />;
});

export const GroupUpdateAccess = graphql(RN_GROUP_UPDATE_ACCESS, {
  props: ({ mutate }) => {
    return {
      updateAccess: mutate
    };
  },
  options: props => ({})
})(GroupAccessContainerGroupsList);

export default GroupUpdateAccess;
