export function getPaymentDelayName(
  paymentDelayID?: number | string | null
): string {
  switch (paymentDelayID) {
    case 1:
      return "С Даты Финиш";

    case 2:
      return "С начала месяца Даты Финиш";

    case 3:
      return "С конца месяца Даты Финиш";

    case 4:
      return "С Даты Старт";

    case 5:
      return "С начала месяца Даты Старт";

    case 6:
      return "С конца месяца Даты Старт";

    default:
      return "";
  }
}
