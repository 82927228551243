import React, { Component } from "react";
import MediaPlanItemsListItem from "./MediaPlanItemsListContainer";
import GreyTd from "../../../components/styled/greyTd";
import { Totals } from "../../../components/mediaPlan/media-plan-totals";
import { ApolloConsumer } from "react-apollo";

class MediaPlanItemsList extends Component {
  state = {
    items: [],
  };

  static getDerivedStateFromProps(props, oldState) {
    let updateForState = {
      ...oldState,
    };

    updateForState.mpId = props.parentProps.mp.id;
    updateForState.MediaPlanItems = props.parentProps.mp.MpItems;
    updateForState.Briefing = props.parentProps.mp.Briefing || null;
    updateForState.page_access = props.parentProps.match.params.type;

    return updateForState;
  }

  render() {
    let publication_in_table = false;

    for (let i of this.state.MediaPlanItems) {
      if (i.InventoryItem && i.InventoryItem.type === "publication") {
        publication_in_table = true;
      }
    }

    const scoringShow = this.props.parentState.scoringShow;
    const orderExport = this.props.parentState.orderExport;

    return (
      <>
        <table
          cellSpacing="0"
          cellPadding="0"
          className="custom-table"
          style={{
            fontFamily: "arial",
          }}
        >
          <thead id="thead">
            <tr>
              {orderExport && (
                <>
                  <td
                    className="close-icon"
                    style={{
                      zIndex: 99,
                    }}
                  >
                    Отправить в ACC
                  </td>
                  <td
                    className="close-icon"
                    style={{
                      zIndex: 99,
                    }}
                  >
                    Номер заказа
                  </td>
                  <td
                    className="close-icon"
                    style={{
                      zIndex: 99,
                    }}
                  >
                    Дата создания заказа
                  </td>
                </>
              )}

              <td
                className="lighblue-col"
                style={{
                  zIndex: 99,
                }}
              >
                &nbsp;
              </td>
              <td
                className="lighblue-col small-width"
                style={{
                  zIndex: 99,
                }}
              >
                Гео
              </td>
              <td
                data-name="platform"
                className="lighblue-col"
                style={{
                  minWidth: "190px",
                  zIndex: 99,
                }}
              >
                Площадка
              </td>
              <td
                className="lighblue-col desc-block"
                style={{
                  minWidth: "240px",
                  zIndex: 99,
                }}
              >
                Вид рекламы
              </td>

              {scoringShow ? (
                <td
                  className="lighblue-col"
                  style={{
                    zIndex: 99,
                  }}
                >
                  Скоринг
                </td>
              ) : null}

              <td
                className="lighblue-col"
                style={{
                  zIndex: 99,
                }}
              >
                Формат
              </td>
              <td
                data-name="targeting"
                className="lighblue-col"
                style={{
                  minWidth: "200px",
                  zIndex: 99,
                }}
              >
                Таргетинги
              </td>
              <td
                className="lighblue-col"
                style={{ minWidth: "150px", zIndex: 99 }}
              >
                Месяц/ Период
              </td>
              <td
                className="lighblue-col"
                style={{
                  zIndex: 99,
                  minWidth: "100px",
                }}
              >
                Единицы закупа
              </td>
              <td
                className="lighblue-col bordered"
                style={{
                  zIndex: "99",
                  minWidth: "50px",
                }}
              >
                Кол-во
              </td>
              <td
                className="lighblue-col"
                style={{
                  zIndex: "99",
                }}
              >
                Стоимость за 1-цу по прайс-листу без НДС
              </td>
              <td
                style={{
                  zIndex: "99",
                }}
                className="lighblue-col small-width"
              >
                Скидка
              </td>
              <td
                className="lighblue-col bordered"
                style={{
                  zIndex: "99",
                  minWidth: "50px",
                }}
              >
                Важное
              </td>
              <td
                style={{
                  zIndex: "1",
                }}
                className="lighblue-col"
              >
                Стоимость за период после скидки без НДС
              </td>
              <td className="lighblue-col small-width">
                Налогообложение площадки
              </td>
              <td className="lighblue-col small-width">
                Налогообложение клиента
              </td>
              <td className="lighblue-col">Итого клиент, включая все налоги</td>
              <td
                className="blue-col small-width"
                style={{
                  zIndex: "99",
                }}
              >
                CTR, %
              </td>
              <td className="blue-col small-width">Клики</td>
              <td className="blue-col">Показы</td>
              {publication_in_table ? (
                <td className="blue-col small-width">Просмотры</td>
              ) : null}
              <td className="blue-col">CPM прогноз до НДС</td>
              <td className="blue-col">CPC прогноз до НДС</td>
              {publication_in_table ? (
                <td className="blue-col small-width">
                  Стоимость 1 прочтения / прогноз до НДС
                </td>
              ) : null}
              <td className="red-col small-width">Скидка ЗАКУП,%</td>
              <td className="red-col">Стоимость за период ЗАКУП без НДС</td>
              {/*<td className="red-col">Стоимость за период ЗАКУП с НДС</td>*/}
              <td className="red-col small-width">Рентабельность</td>
              <td className="red-col">Маржа без НДС</td>
              <td className="red-col">Поставщик</td>
              <td className="green-col">Подтверждение брони</td>
              <td className="green-col">Запрос</td>
              <td className="lighblue-col">Отсрочка поставщика базовая</td>
              <td
                className="lighblue-col"
                style={{
                  minWidth: "200px",
                  zIndex: "2",
                }}
              >
                Отсрочка поставщика факт
              </td>
              <td className="lighblue-col">Отсрочка клиент</td>
              <td
                style={{
                  zIndex: 1,
                }}
              >
                Тех требования и фото
              </td>

              {this.props.parentState.inventoryReqShow ? (
                <td>Уникальное ТТ</td>
              ) : null}
              {this.props.parentState.show ? (
                <>
                  {/*<td>Стоимость за период ЗАКУП без НДС</td>*/}
                  {/*<td>Стоимость за период ЗАКУП с НДС</td>*/}
                  {/*<td>Стоимость за период после скидки без НДС (байер)</td>*/}
                  {/*<td>Итоговая стоимость с уч. НДС (байер)</td>*/}
                  <td>Тип АВ</td>
                  <td>Значение АВ</td>
                  <td>Значение АВ в рублях</td>
                  <td>Продажа NET с АВ (КС)</td>
                  <td>НДС у АВ</td>
                  <td>АВ НДС</td>
                  <td className="green-col">
                    Итого клиент: Продажа Gross с АВ (КС)
                  </td>
                </>
              ) : null}

              {this.state.page_access === "manager" ? (
                <td />
              ) : (
                <td>Замечания</td>
              )}

              {this.props.parentState.data.status === 7 ? (
                <>
                  {" "}
                  <GreyTd>CTR</GreyTd>
                  <GreyTd>Клики</GreyTd>
                  <GreyTd>Показы</GreyTd>
                  {/*<GreyTd>Итоговая стоимость</GreyTd>*/}
                  {/*<GreyTd>% расходования бюджета</GreyTd>*/}
                  {/*<GreyTd>Итого количество единиц за период</GreyTd>*/}
                  {/*<GreyTd>% выполнения плана</GreyTd>*/}
                  {/*<GreyTd>Количество показов анонса</GreyTd>*/}
                  {/*<GreyTd>Количество просмотров/ прочтений</GreyTd>*/}
                  {/*<GreyTd>CPV</GreyTd>*/}
                  {/*<GreyTd>Визиты</GreyTd>*/}
                  {/*<GreyTd>Время на сайте, мин:сек</GreyTd>*/}
                  {/*<GreyTd>Глубина просмотра, стр</GreyTd>*/}
                  {/*<GreyTd>Показатель отказов</GreyTd>*/}
                  {/*<GreyTd>Конверсия по любой цели, %</GreyTd>*/}
                </>
              ) : null}
            </tr>
          </thead>
          <tbody>
            {this.state.MediaPlanItems.map((item, indexKey) => (
              <MediaPlanItemsListItem
                scoringShow={scoringShow}
                disabled={this.props.disabled}
                key={item.id}
                id={item.id}
                indexKey={indexKey}
                parentState={this.state}
                parentProps={this.props}
                setParentState={(newState) => {
                  if (newState.modal) {
                    this.props.setParentState(newState);
                  }

                  this.setState((state) => ({
                    ...state.data,
                    ...newState,
                  }));
                }}
                publication_in_table={publication_in_table}
              />
            ))}

            <ApolloConsumer>
              {(client) => (
                <Totals
                  scoringShow={scoringShow}
                  orderExport={orderExport}
                  client={client}
                  mpID={this.state.mpId}
                />
              )}
            </ApolloConsumer>
          </tbody>
        </table>
      </>
    );
  }
}

export default MediaPlanItemsList;
