import gql from "graphql-tag";

export const GET_FEDERAL_DISTRICT_LIST = gql`
  query {
    Geo {
      FederalDistrictList(params: { count: 25, offset: 0 }) {
        Code
        FederalDistrict
        FederalSubjects {
          AOGUID
          FORMALNAME
          SHORTNAME
          District {
            Code
            FederalDistrict
          }
          Localities {
            AOGUID
            FORMALNAME
            SHORTNAME
            Parent {
              AOGUID
              FORMALNAME
              SHORTNAME
            }
          }
        }
      }
    }
  }
`;

export const GET_FEDERAL_DISTRICT = gql`
  query($id: Int!) {
    Geo {
      FederalDistrict(id: $id) {
        Code
        FederalDistrict
        FederalSubjects {
          AOGUID
          FORMALNAME
          SHORTNAME
          Localities {
            AOGUID
            FORMALNAME
            SHORTNAME
          }
        }
      }
    }
  }
`;

export const GET_FEDERAL_SUBJECT = gql`
  query($id: String!) {
    Geo {
      FederalSubject(id: $id) {
        AOGUID
        FORMALNAME
        SHORTNAME
        Localities {
          AOGUID
          FORMALNAME
          SHORTNAME
        }
      }
    }
  }
`;

export const GET_LOCALITY_LIST = gql`
  query($params: ParamsInput) {
    Geo {
      LocalityList(params: $params) {
        AOGUID
        FORMALNAME
        SHORTNAME
      }
    }
  }
`;
