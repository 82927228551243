import React from "react";

interface PurchaseUnitProps {
  readonly inventoryItem?: {
    priceType: number;
    type: string;
    price_value_week?: number;
    price_value_month?: number;
  };
  readonly price_type?: number;
  readonly disabled?: boolean;
  onChange: (value: any) => void;
}

export function PurchaseUnit({
  inventoryItem,
  disabled,
  onChange,
  price_type,
}: PurchaseUnitProps) {
  switch (inventoryItem?.type) {
    case "publication":
      return "шт. 1 выход";
    case "special":
      return "шт.";

    default:
      break;
  }

  switch (inventoryItem?.priceType) {
    case 1:
      return (
        <select
          style={{
            height: "25px",
          }}
          name="price_type"
          className=""
          value={price_type || 0}
          disabled={disabled}
          onChange={(e) => {
            onChange(+e.target.value);
          }}
        >
          <option value="0">Выбрать</option>
          {inventoryItem?.price_value_month &&
          inventoryItem?.price_value_week ? (
            <>
              {" "}
              <option value="1">За неделю</option>
              <option value="13">За месяц</option>
            </>
          ) : inventoryItem?.price_value_month ? (
            <option value="13">За месяц</option>
          ) : (
            <option value="1">За неделю</option>
          )}

          <option value="12">Период</option>
        </select>
      );

    case 2:
      return "За 1000 показов";
    case 3:
      return "Клик";
    case 4:
      return (
        <select
          style={{
            height: "25px",
          }}
          name="price_type"
          className=""
          value={price_type || 0}
          disabled={disabled}
          onChange={(e) => {
            onChange(+e.target.value);
          }}
        >
          <option value="0">Выбрать</option>
          <option value="13">За месяц</option>
          <option value="12">Период</option>
        </select>
      );
    case 5:
      return "За день";
    case 6:
      return "За 2 недели";
  }

  return null;
}
