import styled from "styled-components";

export default styled.a`
  position: absolute;
  right: 0.5rem;
  width: 32px;
  height: 32px;
  opacity: 0.3;
  cursor: pointer;
  transition: 0.4s;

  &:hover {
    opacity: 1;
  }

  &:before,
  &:after {
    position: absolute;
    left: 15px;
    content: " ";
    height: 22px;
    width: 3px;
    background-color: #333;
  }
  &:before {
    transform: rotate(45deg);
  }
  &:after {
    transform: rotate(-45deg);
  }
`;
