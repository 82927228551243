import React from "react";
import styled from "styled-components";

const DropDownList = styled.div`
  position: absolute;
  background: #fff;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  z-index: 9999;
  ${(p: any) => (p.right !== null ? "right: " + p.right + "px;" : "")}
`;

const DropDown = (props: any) => {
  const [dropdown, setDropDown] = React.useState<boolean>(false);

  function globalClick() {
    setDropDown(false);
    document.removeEventListener("click", globalClick);
  }

  return (
    <>
      <a
        className={props.linkClass || "nav-link dropdown-toggle"}
        href="#"
        style={{
          position: "relative",
        }}
        onClick={(e) => {
          e.preventDefault();
          if (dropdown) {
            setDropDown(false);
          } else {
            setDropDown(true);
            document.addEventListener("click", globalClick);
          }
        }}
      >
        {props.label}
      </a>

      {dropdown ? (
        <DropDownList
          //@ts-ignore
          right={props.right !== null ? props.right : null}
          onClick={() => {
            setDropDown(false);
          }}
        >
          {props.children}
        </DropDownList>
      ) : null}
    </>
  );
};

export default DropDown;
