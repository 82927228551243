import React from "react";

interface IPaginationProps {
  readonly countOfButtons: number;
  readonly page: number;
  readonly pages: number;
  readonly count: number;
  readonly total: number;
  setPage: (page: number) => void;
}

//todo подумать над параметрами

export default function Pagination({
  countOfButtons,
  page,
  pages,
  count,
  total,
  setPage
}: IPaginationProps) {
  let array = [];
  let numbers: number = Math.floor(countOfButtons / 2);

  let start = page - numbers;
  let end = page + numbers;

  for (let i = start - 1; i <= end + 1; i++) {
    if (i <= start - 1 && i > 1) {
      array.push("...");
    } else if (i === end + 1 && i < pages) {
      array.push("...");
    } else if (i >= pages) {
      continue;
    } else if (i <= 1) {
      continue;
    } else {
      array.push(i);
    }
  }

  let buttonArray = [1, ...array, +pages];

  return (
    <nav className="mt-3 col-12">
      <p className="py-4 my-0">
        <b>В списке: {total ? total : "Нет ни одной карточки"}</b>
      </p>
      <ul className="pagination">
        {pages > 1 &&
          buttonArray.map((item, i) => {
            return (
              <React.Fragment key={`${item}${i}`}>
                {item !== "..." ? (
                  <li
                    className={page === item ? "page-item active" : "page-item"}
                  >
                    <a
                      href={`#page=${item}&count=${count}`}
                      className="page-link"
                      onClick={() => {
                        setPage(+item);
                      }}
                    >
                      {item}
                    </a>
                  </li>
                ) : (
                  <li className="page-item disabled">
                    <span className="page-link">...</span>
                  </li>
                )}
              </React.Fragment>
            );
          })}
      </ul>
    </nav>
  );
}
