import React, { Component } from "react";
import NumberFormat from "react-number-format";
import TargetingList from "./TargetingList/TargetingList";

class InventorySpecialProject extends Component {
  render() {
    return (
      <div className="col-12">
        <div className="row">
          <div className="col-6 mb-3">
            <div className="row mb-3">
              <div className="col-4">
                Название<span className="text-danger">*</span>
              </div>
              <div className="col-8">
                <input
                  type="text"
                  name="name"
                  className="form-control"
                  placeholder="Название"
                  value={
                    this.props.parentState.data.name
                      ? this.props.parentState.data.name
                      : ""
                  }
                  onChange={e => {
                    this.props.setParentState({
                      name: e.target.value
                    });
                  }}
                />
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-4">Размер / формат</div>
              <div className="col-8">
                <input
                  type="text"
                  name="format"
                  className="form-control"
                  placeholder="Размер / формат"
                  value={
                    this.props.parentState.data.format
                      ? this.props.parentState.data.format
                      : ""
                  }
                  onChange={e => {
                    this.props.setParentState({
                      format: e.target.value
                    });
                  }}
                />
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-4">Единица закупа</div>
              <div className="col-8">
                <select
                  name="priceType"
                  className="form-control"
                  value={
                    this.props.parentState.data.priceType
                      ? this.props.parentState.data.priceType
                      : ""
                  }
                  onChange={e => {
                    this.props.setParentState({
                      priceType: +e.target.value,
                      price_value: null,
                      price_value_week: null,
                      price_value_month: null
                    });
                  }}
                >
                  <option>Выбрать</option>
                  <option value={1}>Неделя / Месяц</option>
                  <option value={2}>За 1000 показов / CPM</option>
                  <option value={3}>За клик / CPC</option>
                  <option value={4}>Фиксированная</option>
                  <option value={7}>Карточка</option>
                  <option value={8}>Звонок</option>
                  <option value={9}>Выход</option>
                  <option value={10}>Выезд</option>
                  <option value={11}>Материал</option>
                  <option value={12}>Период</option>
                </select>
              </div>
            </div>
            {this.props.parentState.data.priceType &&
            this.props.parentState.data.priceType !== 1 ? (
              <div className="row mb-3">
                <div className="col-4">Стоимость</div>
                <div className="col-8">
                  <NumberFormat
                    name="price_value"
                    className="form-control"
                    thousandSeparator={" "}
                    decimalSeparator={","}
                    decimalScale={2}
                    suffix={!this.props.isForeign && " ₽"}
                    value={
                      this.props.parentState.data.price_value !== null
                        ? this.props.parentState.data.price_value
                        : ""
                    }
                    onValueChange={price => {
                      const value =
                        price.value === "" ? null : price.floatValue;
                      this.props.setParentState({
                        price_value: value
                      });
                    }}
                  />
                </div>
              </div>
            ) : null}
            {this.props.parentState.data.priceType === 1 ? (
              <div className="row mb-3">
                <div className="col-4">Стоимость за неделю / месяц</div>
                <div className="col-8">
                  <div className="form-control" style={{ padding: "0" }}>
                    <NumberFormat
                      style={{
                        border: "none",
                        height: "100%",
                        width: "50%",
                        padding: ".375rem .75rem"
                      }}
                      name="price_value_week"
                      thousandSeparator={" "}
                      decimalSeparator={","}
                      decimalScale={2}
                      suffix={!this.props.isForeign && " ₽"}
                      value={
                        this.props.parentState.data.price_value_week !== null
                          ? this.props.parentState.data.price_value_week
                          : ""
                      }
                      onValueChange={price => {
                        const value =
                          price.value === "" ? null : price.floatValue;
                        this.props.setParentState({
                          price_value_week: value
                        });
                      }}
                    />
                    /
                    <NumberFormat
                      style={{
                        border: "none",
                        height: "100%",
                        width: "calc(50% - 7px)",
                        padding: ".375rem .75rem"
                      }}
                      name="price_value_month"
                      thousandSeparator={" "}
                      decimalSeparator={","}
                      decimalScale={2}
                      suffix={!this.props.isForeign && " ₽"}
                      value={
                        this.props.parentState.data.price_value_month !== null
                          ? this.props.parentState.data.price_value_month
                          : ""
                      }
                      onValueChange={price => {
                        const value =
                          price.value === "" ? null : price.floatValue;
                        this.props.setParentState({
                          price_value_month: value
                        });
                      }}
                    />
                  </div>
                </div>
              </div>
            ) : null}
            <div className="row mb-3">
              <div className="col-4">Налогообложение</div>
              <div className="col-8">
                <select
                  className="form-control"
                  value={
                    this.props.parentState.data.vat
                      ? this.props.parentState.data.vat
                      : ""
                  }
                  onChange={e => {
                    const value = +e.target.value;
                    this.props.setParentState({
                      vat: value
                    });
                  }}
                >
                  <option value="0" hidden>
                    Выбрать
                  </option>
                  <option value="1">с НДС</option>
                  <option value="2">без НДС</option>
                </select>
              </div>
            </div>
            <TargetingList {...this.props} />
            <div className="row mb-3">
              <div className="col-4">Комментарий</div>
              <div className="col-8">
                <textarea
                  name="comment"
                  rows="4"
                  className="form-control"
                  value={
                    this.props.parentState.data.comment
                      ? this.props.parentState.data.comment
                      : ""
                  }
                  onChange={e => {
                    this.props.setParentState({
                      comment: e.target.value
                    });
                  }}
                ></textarea>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default InventorySpecialProject;
