import React from "react";
import { File } from "../../../graphql/types";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardMedia,
} from "@material-ui/core";
import { ApolloConsumer } from "react-apollo";
import { FileUpload } from "../../fileUpload/fileUpload";

interface IImageCard {
  onChange?: (file: { file: File }) => void;
  imageUrl?: string;
  onDelete?: () => void;
}

// блок для просмотра изображения
export function ImageCard({ imageUrl, onChange, onDelete }: IImageCard) {
  const classes = makeStyles({
    img: {
      minHeight: 200,
      width: "100%",
    },
  })();

  const [previewFile, setPreviewFile] = React.useState(null);

  const preview = previewFile ? URL.createObjectURL(previewFile) : null;

  if (!previewFile && !imageUrl)
    return (
      <ApolloConsumer>
        {(client) => (
          <FileUpload
            client={client}
            isInventory={true}
            onChange={(fileInfo) => {
              setPreviewFile(fileInfo.file);
              if (onChange) onChange(fileInfo);
            }}
          />
        )}
      </ApolloConsumer>
    );

  return (
    <Card>
      <CardActionArea>
        <CardMedia
          className={classes.img}
          title="Изображение"
          image={preview || imageUrl}
        />
      </CardActionArea>
      <CardActions>
        <Button
          size="small"
          color="secondary"
          onClick={() => {
            setPreviewFile(null);
            onDelete && onDelete();
          }}
        >
          Удалить
        </Button>
      </CardActions>
    </Card>
  );
}
