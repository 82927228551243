import React from "react";

//типы инветаря
import InventoryBanner from "./InventoryTypes/InventoryBanner";
import InventoryPublication from "./InventoryTypes/InventoryPublication";
import InventorySpecialProject from "./InventoryTypes/InventorySpecialProject";
import InventoryAdditional from "./InventoryTypes/InventoryAdditional";
import InventoryCard from "./InventoryTypes/InventoryCard";

import { ApolloConsumer, graphql } from "react-apollo";

import { RN_INVENTORY_CREATE } from "../../../../GraphQL/RNmutations";
import { RN_INVENTORY_UPDATE } from "./../../../../GraphQL/RNmutations";
import { RN_PLATFORM } from "./../../../../GraphQL/RNqueries";
import { RN_INVENTORY } from "../../../../GraphQL/RNqueries";

import { Link } from "react-router-dom";

import Header from "./../../../../../template/Header";
import vatInPlatform from "./inventory.functions";
import {
  DELETE_INVENTORY_FILE,
  UPLOAD_INVENTORY_FILE,
} from "../../../../../graphql/mutations";

const moment = require("moment/min/moment-with-locales.js");

class InventoryItemAdd extends React.Component {
  /* global $*/
  state = {
    data: {
      image: [],
      screenshot: [],
      video: [],
      video_short: [],
      type: "banner",
      showing: null,
    },
    image: [],
    screenshot: [],
    video: [],
    video_short: [],
    fileToDelete: [],
    fileToUpload: [],
  };

  static getDerivedStateFromProps(props, oldState) {
    let updateForState = {};

    if (oldState.loaded == null && props.inventory) {
      updateForState = {
        data: {
          ...props.inventory,
          author_id: props.user.id,
        },
        image: props.inventory.image,
        screenshot: props.inventory.screenshot,
        loaded: true,
      };

      updateForState.data.type = props.match.params.type;

      updateForState.data.image = updateForState.data.image.map(
        (item) => item.id
      );
      updateForState.data.screenshot = updateForState.data.screenshot.map(
        (item) => item.id
      );
      delete updateForState.data["__typename"];

      updateForState.data.pages = updateForState.data["Pages"].map(
        (item) => item.id
      );
      delete updateForState.data["Pages"];

      updateForState.data.targetingList = updateForState.data["Targeting"].map(
        (item) => ({
          id: item.id,
          name: item.name,
          coefficient: item.coefficient,
        })
      );
      delete updateForState.data["Targeting"];
    } else {
      updateForState = { ...oldState };
    }

    if (!updateForState.data.vat) {
      updateForState.data.vat = vatInPlatform(props.platform);
    }

    return updateForState;
  }

  createOrUpdate(data, cb, client) {
    const fileToUpload = data.fileToUpload ? [...data.fileToUpload] : [];
    const fileToDelete = data.fileToDelete ? [...data.fileToDelete] : [];

    delete data.fileToUpload;
    delete data.fileToDelete;
    delete data.files;

    cb({
      variables: {
        inventory: {
          ...data,
        },
      },
    })
      .then(({ data }) => {
        const inventoryID = data.RnInventoryUpdate
          ? data.RnInventoryUpdate.id
          : data.RnInventoryCreate.id;

        // файлы для загрузки
        for (let file of fileToUpload) {
          client.mutate({
            mutation: UPLOAD_INVENTORY_FILE,
            variables: {
              file: file.file,
              inventoryID,
              srcFileName: file?.srcFileName,
              comment: file?.comment,
            },
          });
        }

        // файлы для удаления
        for (let fileID of fileToDelete) {
          client.mutate({
            mutation: DELETE_INVENTORY_FILE,
            variables: {
              inventoryID,
              fileID,
            },
          });
        }

        this.setState({
          refresher: true,
          success: true,
        });
        this.props.history.push(
          `/platform/update/${this.state.data.platformId}#tab=inventory`
        );
      })
      .catch((res) => {
        if (
          res.graphQLErrors?.length &&
          res.graphQLErrors[0].extensions.category === "FormDataError"
        ) {
          const formErrors = res.graphQLErrors[0].message.split("\n");
          this.setState({ formErrors });
        } else {
          console.warn(res);
        }
      });
  }

  dataSend(client) {
    if (this.state.data.id) {
      return this.createOrUpdate(
        this.state.data,
        this.props.updateInventory,
        client
      );
    }
    return this.createOrUpdate(
      this.state.data,
      this.props.createInventory,
      client
    );
  }

  //ссылка
  //создание ссылки ручное
  //впоследствии нужна в jquery
  textInput = React.createRef();

  //создание объектов jquery
  jQueryCreate() {
    const _this = this;
    $("input#placementDate")
      .daterangepicker({
        startDate: moment(this.state.data.startDate).format("DD.MM.YYYY"),
        endDate: moment(this.state.data.endDate).format("DD.MM.YYYY"),
        opens: "right",
        locale: {
          applyLabel: "Применить",
          cancelLabel: "Отменить",
        },
      })
      .ready(() => {
        let date = this.textInput.current.value;
        date = date.split(" - ");
        this.setState((state) => ({
          data: {
            ...state.data,
            startDate: moment(date[0], "DD-MM-YYYY").format(),
            endDate: moment(date[1], "DD-MM-YYYY").format(),
          },
        }));
      })
      .on("apply.daterangepicker", function (e, picker) {
        _this.setState((state) => ({
          data: {
            ...state.data,
            startDate: moment(picker.startDate).format("YYYY-MM-DD"),
            endDate: moment(picker.endDate).format("YYYY-MM-DD"),
          },
        }));
      });
  }

  componentDidMount() {
    //дата заполнения
    if (!this.props.inventory) {
      this.setState((state) => ({
        data: {
          ...state.data,
          entryDate: moment().format(),
        },
      }));
    }

    this.setState((state) => ({
      data: {
        ...state.data,
        type: this.props.match.params.type,
        platformId: this.props.match.params.platformId,
        author_id: this.props.user.id,
      },
    }));

    //дата обновления
    this.setState((state) => ({
      data: {
        ...state.data,
        updateDate: moment().format(),
      },
    }));
  }

  renderFormErrors() {
    if (!this.state.formErrors) return null;
    let Errors = this.state.formErrors;
    const setState = this.setState.bind(this);

    return (
      <div
        style={{
          position: "fixed",
          width: "50%",
          top: "20px",
          zIndex: "1052",
          right: "0px",
        }}
      >
        {this.state.formErrors.map(function (text, i) {
          return (
            <div
              key={i}
              className="alert alert-danger alert-dismissible fade show"
              role="alert"
            >
              {text}
              <button
                onClick={(e) => {
                  let formErrors = Errors;
                  delete formErrors[i];
                  setState({ formErrors });
                }}
                type="button"
                className="close"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
          );
        })}
      </div>
    );
  }

  render() {
    let path = "";

    if (this.props.platform.logo.length) {
      path = this.props.platform.logo[this.props.platform.logo.length - 1].path;
    }

    return (
      <>
        <Header {...this.props} {...this.state} />
        <section className="content">
          {/*форма для отображения ошибок*/}
          {this.renderFormErrors()}
          <div className="container-fluid mediaplans-list-block">
            <div className="table-container">
              <div
                className="site-logo col-12 row mb-3 mt-3"
                style={{ maxWidth: "1400px", margin: "0 auto" }}
              >
                <div className="col-2 mt-2">
                  {this.props.platform.logo.length ? (
                    <img
                      style={{
                        width: "145px",
                        height: "auto",
                      }}
                      src={path}
                      alt=""
                      title=""
                      className="img-fluid"
                    />
                  ) : (
                    <p>Логотип не загружен</p>
                  )}
                </div>
                <div className="title col-3 align-self-center">
                  <h1>
                    {this.props.platform
                      ? this.props.platform.name
                      : "Не указано"}
                  </h1>
                </div>
              </div>
              <div
                className="form-group pt-2 px-2"
                style={{ maxWidth: "1400px", margin: "0 auto" }}
              >
                <div className="row add-banner">
                  <div className="col-6 mt-3">
                    <div className="row mb-1">
                      <div className="title col-4"></div>
                      <div className="col-8">
                        <div className="col-8">
                          <label>
                            <input
                              type="checkbox"
                              checked={this.state.data.coefficient_off === 1}
                              onChange={(e) => {
                                const value = e.target.checked ? 1 : 0;
                                this.setState((state) => ({
                                  data: {
                                    ...state.data,
                                    coefficient_off: value,
                                  },
                                }));
                              }}
                            />{" "}
                            Отключить коэффициент
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="row mb-1">
                      <div className="title col-4">
                        <strong>Добавить инвентарь</strong>
                      </div>
                      <div className="col-8">
                        <select
                          name="inventoryType"
                          className="form-control"
                          value={this.state.data.type}
                          onChange={(e) => {
                            const value = e.target.value;
                            this.setState((state) => ({
                              data: {
                                ...state.data,
                                type: value,
                                priceType: null,
                              },
                            }));
                          }}
                        >
                          <option value="banner">Баннер</option>
                          <option value="card">Карточка</option>
                          <option value="publication">
                            Размещение публикаций и пресс-релизов
                          </option>
                          <option value="special">
                            Эксклюзив / спец.проекты
                          </option>
                          <option value="additional">Доп. услуги</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  {this.state.data.type === "banner" && (
                    <InventoryBanner
                      isForeign={this.props.platform.isForeign}
                      setParentState={(newState) => {
                        this.setState((state) => ({
                          data: {
                            ...state.data,
                            ...newState,
                          },
                        }));
                      }}
                      setParentRootState={(newState) => {
                        this.setState((state) => ({
                          ...state,
                          ...newState,
                        }));
                      }}
                      parentProps={this.props}
                      parentState={this.state}
                    />
                  )}
                  {this.state.data.type === "publication" && (
                    <InventoryPublication
                      isForeign={this.props.platform.isForeign}
                      setParentState={(newState) => {
                        this.setState((state) => ({
                          data: {
                            ...state.data,
                            ...newState,
                          },
                        }));
                      }}
                      parentProps={this.props}
                      parentState={this.state}
                    />
                  )}
                  {this.state.data.type === "special" && (
                    <InventorySpecialProject
                      isForeign={this.props.platform.isForeign}
                      setParentState={(newState) => {
                        this.setState((state) => ({
                          data: {
                            ...state.data,
                            ...newState,
                          },
                        }));
                      }}
                      parentProps={this.props}
                      parentState={this.state}
                    />
                  )}
                  {this.state.data.type === "additional" && (
                    <InventoryAdditional
                      isForeign={this.props.platform.isForeign}
                      setParentState={(newState) => {
                        this.setState((state) => ({
                          data: {
                            ...state.data,
                            ...newState,
                          },
                        }));
                      }}
                      parentProps={this.props}
                      parentState={this.state}
                    />
                  )}
                  {this.state.data.type === "card" && (
                    <InventoryCard
                      isForeign={this.props.platform.isForeign}
                      setParentState={(newState) => {
                        this.setState((state) => ({
                          data: {
                            ...state.data,
                            ...newState,
                          },
                        }));
                      }}
                      parentProps={this.props}
                      parentState={this.state}
                    />
                  )}
                  <div className="col-6">
                    <div className="row mt-4 mb-4">
                      <div className="col-6">
                        <ApolloConsumer>
                          {(client) => (
                            <input
                              type="button"
                              className="form-control btn btn-secondary"
                              value="Сохранить"
                              onClick={() => {
                                this.dataSend(client);
                              }}
                            />
                          )}
                        </ApolloConsumer>
                      </div>
                      <div className="col-6">
                        <Link
                          className="form-control btn btn-light"
                          to={`/platform/update/${this.state.data.platformId}#tab=inventory`}
                        >
                          Отмена
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

/*получаем данные*/
const GetPlatform = graphql(RN_PLATFORM, {
  props: ({ data }) => {
    return {
      loading: data.loading,
      error: data.error,
      platform: data.RnPlatform ? data.RnPlatform : null,
      user: data.me ? data.me : null,
      src_data: data ? data : null,
    };
  },
  options: (props) => ({
    variables: {
      id: props.match.params.platformId,
    },
  }),
})((props) => {
  if (props.loading) return null;
  if (props.error) return null;
  if (props.platform == null) {
    console.log("loading bad result ", props);
    return null;
  }

  return <InventoryItemAdd {...props} />;
});

/*добавление*/
const FormWithSave = graphql(RN_INVENTORY_CREATE, {
  props: ({ mutate }) => {
    return {
      createInventory: mutate,
    };
  },
  options: (props) => ({
    refetchQueries: ["RnInventory", "RnPlatform"],
  }),
})(GetPlatform);

const InventoryItemADD = graphql(RN_INVENTORY, {
  props: ({ data }) => {
    return {
      loading: data.loading,
      error: data.error,
      inventory: data.RnInventory ? data.RnInventory : null,
    };
  },
  options: (props) => ({
    variables: {
      id: props.match.params.inventoryId,
    },
  }),
})((props) => {
  //if(props.loading)return null;
  if (props.error) return null;
  // if(props.inventory == null) {
  //     console.log("loading bad result ", props );
  //     return null;
  // }

  return <FormWithSave {...props} />;
});

export default FormWithSave;

export const InventoryEdit = graphql(RN_INVENTORY_UPDATE, {
  props: ({ mutate }) => {
    return {
      updateInventory: mutate,
    };
  },
  options: (props) => ({
    refetchQueries: ["RnInventory", "RnPlatform"],
  }),
})(InventoryItemADD);
