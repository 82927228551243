import React from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { CircularProgress } from "@material-ui/core";
import { useInventoryListLazyQuery } from "../../../graphql/types";

interface IInventoryProps {
  client: any;
  isMulti?: boolean;
  onChange: (value: any[] | any) => void;
  label: string;
}

export function Inventory({
  client,
  onChange,
  label,
  isMulti = false,
}: IInventoryProps) {
  const [getList, { loading }] = useInventoryListLazyQuery({
    client,

    onCompleted: (data) => {
      setOptions(data?.RnInventoryList?.data || []);
    },
  });

  const [options, setOptions] = React.useState<any[]>([]);
  const [value, setValue] = React.useState<any>([]);
  const [input, setInput] = React.useState<string>("");

  return (
    <Autocomplete
      disableCloseOnSelect
      multiple
      size="small"
      value={value ?? []}
      loading={loading}
      onOpen={() => {
        getList({
          variables: {
            params: {
              count: 10,
              offset: 0,
              filter: [
                {
                  field: "name",
                  operator: "LIKE",
                  values: [input],
                },
              ],
            },
          },
        });
      }}
      onChange={(e, value) => {
        setValue(value);
        onChange(value);
      }}
      inputValue={input}
      onInputChange={(e, name) => {
        setInput(name);
        getList({
          variables: {
            params: {
              count: 10,
              offset: 0,
              filter: [
                {
                  field: "name",
                  operator: "LIKE",
                  values: [name],
                },
              ],
            },
          },
        });
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
      getOptionSelected={(option, value) => {
        return option.id === value.id;
      }}
      getOptionLabel={(option) => option?.name}
      filterSelectedOptions
      options={options || []}
    />
  );
}
