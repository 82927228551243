import React, { Component } from "react";
import TemplateEditorContainer from "./../../TemplateEditor/TemplateEditorContainer";

class Modal extends Component {
  state = {};

  render() {
    return (
      <>
        <div
          className="popup-wrapper p-3"
          style={{
            top: "-80px",
            left: "2750px",
            boxShadow: "0px 0px 6px 0px rgba(50, 50, 50, 0.3)",
            border: "none",
            zIndex: 999,
            minWidth: "450px",
            minHeight: "50px",
          }}
        >
          <div className="text-right mb-2">
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                this.props.setParentState({
                  inventory_apply_modal: !this.props.inventory_apply_modal,
                });
              }}
            >
              Закрыть
            </a>
          </div>
          <TemplateEditorContainer
            {...this.props}
            close={() => {
              this.props.setParentState({
                inventory_apply_modal: !this.props.inventory_apply_modal,
              });
            }}
          />
        </div>
      </>
    );
  }
}

export default Modal;
