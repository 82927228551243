import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /**    Список цифр через запятую. */
  ListOfInt: any;
  /**
   * Input (String) one of:
   * 
   * 
   * YYYY-MM-DD
   * 
   * 
   * DD-MM-YYYY
   * 
   * 
   * DD.MM.YYYY
   * 
   * 
   * --------------------------------------
   * 
   * 
   * Output : YYYY-MM-DD
   */
  Date: any;
  /**
   * Input (String) one of:
   * 
   * \t\tISO8601:2020-12-22T13:08:23+05:00
   * 
   * \t\tunix:1608624503
   * 
   * \t\tsource:2020-12-22 13:08:23
   * 
   * 
   * --------------------------------------
   * ISO8601: 2020-12-22T13:08:23+05:00
   */
  DateTime: any;
  /** -- */
  pageInfo: any;
  /**    Список строк через запятую. */
  ListOfString: any;
  /** Загружаемый файл */
  Upload: any;
};

/** Объект OOН наружней рекламы который в корзине */
export type BasketConstruction = {
  __typename?: 'BasketConstruction';
  /** Объект OOН наружней рекламы который в корзине */
  BasketConstructionPeriod?: Maybe<Array<Maybe<BasketConstructionPeriod>>>;
  /** Материалы  конструкции корзины */
  Materials?: Maybe<Array<Maybe<BasketConstructionMaterial>>>;
  /** Юридическая информация о конструкции поставщик , ндс */
  UrInfo?: Maybe<Array<Maybe<BasketConstructionUrInfo>>>;
  /** - */
  ap_id?: Maybe<Scalars['Int']>;
  /** - */
  binding_info?: Maybe<Scalars['String']>;
  /** - */
  deleted?: Maybe<Scalars['Boolean']>;
  /** - */
  fias_aoguid?: Maybe<Scalars['String']>;
  /** Статистика доходности строки АП */
  fullPriceStat?: Maybe<BasketObjectFullPriceStat>;
  /** id - уникальный идентификатор записи */
  id?: Maybe<Scalars['String']>;
  /** - */
  object_id?: Maybe<Scalars['String']>;
  /** Общая маржа аредны объектов закупа */
  profit?: Maybe<Scalars['Float']>;
  /** - */
  reserved?: Maybe<Scalars['Date']>;
  /** - */
  status?: Maybe<Scalars['Int']>;
};

/** Объект OOН наружней рекламы который в корзине */
export type BasketConstructionInput = {
  /** - */
  ap_id?: Maybe<Scalars['Int']>;
  /** - */
  binding_info?: Maybe<Scalars['String']>;
  /** - */
  deleted?: Maybe<Scalars['Boolean']>;
  /** - */
  fias_aoguid?: Maybe<Scalars['String']>;
  /** - */
  object_id?: Maybe<Scalars['String']>;
  /** Общая маржа аредны объектов закупа */
  profit?: Maybe<Scalars['Float']>;
  /** - */
  reserved?: Maybe<Scalars['Date']>;
  /** - */
  status?: Maybe<Scalars['Int']>;
};

/** Материалы  конструкции корзины */
export type BasketConstructionMaterial = {
  __typename?: 'BasketConstructionMaterial';
  /** - */
  ap_id?: Maybe<Scalars['Int']>;
  /** id - уникальный идентификатор записи */
  id?: Maybe<Scalars['String']>;
  /** - */
  material_id?: Maybe<Scalars['Int']>;
  /** - */
  object_id?: Maybe<Scalars['String']>;
};

/** Объект OOН наружней рекламы который в корзине */
export type BasketConstructionPeriod = {
  __typename?: 'BasketConstructionPeriod';
  /** agent_commission */
  agent_commission?: Maybe<Scalars['Float']>;
  /** agent_commission_net */
  agent_commission_net?: Maybe<Scalars['Float']>;
  /** - */
  ap_id?: Maybe<Scalars['Int']>;
  /** - */
  availability?: Maybe<Scalars['Int']>;
  /** - */
  deleted?: Maybe<Scalars['Boolean']>;
  /** discount */
  discount?: Maybe<Scalars['Float']>;
  /** id - уникальный идентификатор записи */
  id?: Maybe<Scalars['String']>;
  /** - */
  object_id?: Maybe<Scalars['String']>;
  /** - */
  periodEnd?: Maybe<Scalars['DateTime']>;
  /** - */
  periodStart?: Maybe<Scalars['DateTime']>;
  /** price */
  price?: Maybe<Scalars['Float']>;
  /** priceGrossBuy */
  priceGrossBuy?: Maybe<Scalars['Float']>;
  /** priceGrossSale */
  priceGrossSale?: Maybe<Scalars['Float']>;
  /** priceNetBuy */
  priceNetBuy?: Maybe<Scalars['Float']>;
  /** priceNetSale */
  priceNetSale?: Maybe<Scalars['Float']>;
  /** discount */
  profit?: Maybe<Scalars['Float']>;
  /** discount */
  profitability?: Maybe<Scalars['Float']>;
  /** - */
  worktype_id?: Maybe<Scalars['Int']>;
};

/** Объект OOН наружней рекламы который в корзине */
export type BasketConstructionPeriodInput = {
  /** agent_commission */
  agent_commission?: Maybe<Scalars['Float']>;
  /** agent_commission_net */
  agent_commission_net?: Maybe<Scalars['Float']>;
  /** - */
  ap_id?: Maybe<Scalars['Int']>;
  /** - */
  availability?: Maybe<Scalars['Int']>;
  /** - */
  deleted?: Maybe<Scalars['Boolean']>;
  /** discount */
  discount?: Maybe<Scalars['Float']>;
  /** id - уникальный идентификатор записи */
  id?: Maybe<Scalars['String']>;
  /** - */
  object_id?: Maybe<Scalars['String']>;
  /** - */
  periodEnd?: Maybe<Scalars['DateTime']>;
  /** - */
  periodStart?: Maybe<Scalars['DateTime']>;
  /** price */
  price?: Maybe<Scalars['Float']>;
  /** priceGrossBuy */
  priceGrossBuy?: Maybe<Scalars['Float']>;
  /** priceGrossSale */
  priceGrossSale?: Maybe<Scalars['Float']>;
  /** priceNetBuy */
  priceNetBuy?: Maybe<Scalars['Float']>;
  /** priceNetSale */
  priceNetSale?: Maybe<Scalars['Float']>;
  /** discount */
  profit?: Maybe<Scalars['Float']>;
  /** discount */
  profitability?: Maybe<Scalars['Float']>;
  /** - */
  worktype_id?: Maybe<Scalars['Int']>;
};

/** Юридическая информация о конструкции поставщик , ндс */
export type BasketConstructionUrInfo = {
  __typename?: 'BasketConstructionUrInfo';
  /** - */
  BasketConstruction?: Maybe<BasketConstruction>;
  /** - */
  UrFace?: Maybe<MlUrFaceItem>;
  /** - */
  ap_id?: Maybe<Scalars['Int']>;
  /** id - уникальный идентификатор записи */
  id?: Maybe<Scalars['String']>;
  /** - */
  object_id?: Maybe<Scalars['String']>;
  /** - */
  urface_id?: Maybe<Scalars['Int']>;
  /** - */
  vat?: Maybe<Scalars['Boolean']>;
  /** - */
  worktype_id?: Maybe<Scalars['Int']>;
};

/** Юридическая информация о конструкции поставщик , ндс */
export type BasketConstructionUrInfoInput = {
  /** - */
  ap_id?: Maybe<Scalars['Int']>;
  /** id - уникальный идентификатор записи */
  id?: Maybe<Scalars['String']>;
  /** - */
  object_id?: Maybe<Scalars['String']>;
  /** - */
  urface_id?: Maybe<Scalars['Int']>;
  /** - */
  vat?: Maybe<Scalars['Boolean']>;
  /** - */
  worktype_id?: Maybe<Scalars['Int']>;
};

/** Медиаметрия корзины АП */
export type BasketMediametry = {
  __typename?: 'BasketMediametry';
  /** - */
  ap_id?: Maybe<Scalars['Int']>;
  /** Количество билбордов */
  bcount?: Maybe<Scalars['Int']>;
  /** Охват 1+, человек */
  coverage1?: Maybe<Scalars['Float']>;
  /** Охват 1+ % */
  coverage1proc?: Maybe<Scalars['Float']>;
  /** Охват 5+, человек */
  coverage5?: Maybe<Scalars['Float']>;
  /** Охват 5+ % */
  coverage5proc?: Maybe<Scalars['Float']>;
  /** - */
  fias_aoguid?: Maybe<Scalars['String']>;
  /** Frequency (средняя частота контакта) */
  frequency?: Maybe<Scalars['Float']>;
  /** Суммарный GRP за период */
  grpPeriodSum?: Maybe<Scalars['Float']>;
  /** Сумма всех GRP / Количество GRP */
  grpX?: Maybe<Scalars['Float']>;
  /** Средний дневной GRP одной конструкции */
  grpY?: Maybe<Scalars['Float']>;
  /** - */
  id?: Maybe<Scalars['String']>;
  /** Количество конструкций малого формата (<18 м²)  */
  less18?: Maybe<Scalars['Int']>;
  /** Количество конструкций крупного формата  (>=18  м²)  */
  more18?: Maybe<Scalars['Int']>;
  /** - */
  name?: Maybe<Scalars['String']>;
  /** OTS 18+ (суммарное кол-во контактов) */
  ots?: Maybe<Scalars['Float']>;
  /** Период кампании, дней */
  periodDays?: Maybe<Scalars['Int']>;
  /** Население */
  population?: Maybe<Scalars['Int']>;
};

export type BasketObjectFullPriceStat = {
  __typename?: 'basketObjectFullPriceStat';
  AB?: Maybe<Scalars['Float']>;
  VAT?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  profit?: Maybe<Scalars['Float']>;
  profitability?: Maybe<Scalars['Float']>;
  totalGROSS?: Maybe<Scalars['Float']>;
  totalNET?: Maybe<Scalars['Float']>;
};

/** Бюджет (контракт) VIMB */
export type Budget = {
  __typename?: 'Budget';
  /** id - уникальный HASH идентификатор записи */
  ID?: Maybe<Scalars['ID']>;
  /** ID рекламодателя ?? */
  adtID?: Maybe<Scalars['Int']>;
  /** название рекламодателя ?? */
  adtName?: Maybe<Scalars['String']>;
  /** id - уникальный идентификатор записи */
  agrID?: Maybe<Scalars['Int']>;
  /** наименование договора */
  agrName?: Maybe<Scalars['String']>;
  /** Бюджет */
  budget?: Maybe<Scalars['Float']>;
  /** Город вещания */
  city?: Maybe<VimbCity>;
  /** ?? */
  cmpName?: Maybe<Scalars['String']>;
  /** Месяц */
  cnlID?: Maybe<Scalars['Int']>;
  /** ?? */
  cnlName?: Maybe<Scalars['String']>;
  /** ?? */
  coordCost?: Maybe<Scalars['Float']>;
  /** ?? */
  cost?: Maybe<Scalars['Float']>;
  /** ?? */
  dealChannelStatus?: Maybe<Scalars['Int']>;
  /** Флаг удаления */
  deleted?: Maybe<Scalars['Int']>;
  /** ?? */
  fixPercent?: Maybe<Scalars['Int']>;
  /** ?? */
  fixPercentPrime?: Maybe<Scalars['Int']>;
  /** ?? */
  floatPercent?: Maybe<Scalars['Int']>;
  /** ?? */
  floatPercentPrime?: Maybe<Scalars['Int']>;
  /** GRP */
  grp?: Maybe<Scalars['Float']>;
  /** ?? */
  grpFix?: Maybe<Scalars['Float']>;
  /** ?? */
  grpWithoutKF?: Maybe<Scalars['Float']>;
  /** Список рекламных блоков программы */
  intervals?: Maybe<Array<Maybe<Targeting>>>;
  /** ?? */
  inventoryUnitDuration?: Maybe<Scalars['Int']>;
  /** Флаг для оптимизации */
  isMax?: Maybe<Scalars['Boolean']>;
  /** Плановый ли это контракт */
  isPlan?: Maybe<Scalars['Boolean']>;
  /** ?? */
  mngName?: Maybe<Scalars['String']>;
  /** Месяц */
  month?: Maybe<Scalars['String']>;
  /** Медиаплан */
  mplans?: Maybe<Array<Maybe<MPlan>>>;
  /** тип для оптимизации */
  opTypeID?: Maybe<Scalars['Int']>;
  /** значение для оптимизации */
  opValue?: Maybe<Scalars['Float']>;
  /** целевое TRP */
  planTrp?: Maybe<Scalars['Float']>;
  /** Ценнобразование (хроно, grp) */
  priceType?: Maybe<Scalars['Int']>;
  /** Доля прайма */
  primeRate?: Maybe<Scalars['Float']>;
  /** использование прайма VIMB */
  primeTypeId?: Maybe<Scalars['Int']>;
  /** Список рекламных блоков программы */
  programBreaks?: Maybe<Array<Maybe<ProgramBreak>>>;
  /** Направление продаж (база данны) */
  sellingDirection?: Maybe<Scalars['Int']>;
  /** ?? */
  tp?: Maybe<Scalars['String']>;
};

/** Телеканал */
export type Channel = {
  __typename?: 'Channel';
  /** Территория вещания: ID */
  bcpCentralID?: Maybe<Scalars['Int']>;
  /** Территория вещания: наименование */
  bcpName?: Maybe<Scalars['Int']>;
  /** ?? */
  cnlCentralID?: Maybe<Scalars['String']>;
  /** id - уникальный идентификатор записи */
  id: Scalars['String'];
  /** Заблокирован, но для чего?? */
  isDisabled?: Maybe<Scalars['String']>;
  /** название ?? */
  mainChannel?: Maybe<Scalars['String']>;
  /** наименование канала */
  shortName?: Maybe<Scalars['String']>;
};

/** Тип конструкции */
export type CityAreaType = {
  __typename?: 'CityAreaType';
  /** id  района */
  CityArea_ID?: Maybe<Scalars['Int']>;
  /** Имя района */
  CityArea_Name?: Maybe<Scalars['String']>;
  /** маркер жилого района */
  IsResidentArea?: Maybe<Scalars['Float']>;
  /** id названия конструкции */
  id?: Maybe<Scalars['String']>;
  /** Наменование конструкции */
  name?: Maybe<Scalars['String']>;
};

/** фильтры АП для города */
export type CityFilter = {
  __typename?: 'CityFilter';
  /** - */
  constrTypes?: Maybe<Array<Maybe<Scalars['Int']>>>;
  /** - */
  expositions?: Maybe<Array<Maybe<Scalars['Int']>>>;
  /** - */
  materials?: Maybe<Array<Maybe<Scalars['Int']>>>;
};

/** Группа комона */
export type ComonGroupItem = {
  __typename?: 'ComonGroupItem';
  /** id группы */
  id?: Maybe<Scalars['ID']>;
  /** название группы */
  name?: Maybe<Scalars['String']>;
};



/** Бриф контекстной рекламы */
export type DigitalBriefingContext = {
  __typename?: 'DigitalBriefingContext';
  /** Бренд / Торговая марка */
  Brand?: Maybe<MlBrand>;
  /** Сотрудник компании */
  Manager?: Maybe<RefEmployee>;
  /** партнёры */
  Partner?: Maybe<MlPartner>;
  /** Первое действие пользователей на сайте */
  actionFirst?: Maybe<Scalars['String']>;
  /** Второе действие пользователей на сайте */
  actionSecond?: Maybe<Scalars['String']>;
  /** Первое действие пользователей на сайте */
  actionThird?: Maybe<Scalars['String']>;
  /** Цель рекламной кампании */
  advTask?: Maybe<Scalars['Int']>;
  /** Другая цель рекламной кампании */
  advTaskOther?: Maybe<Scalars['String']>;
  /** Уникальное торговое предожение клиента */
  advantages?: Maybe<Scalars['String']>;
  /**
   * Id - идентификатор возрастной группы целевой аудитории
   * @deprecated данное поле не действительно
   */
  ageRang_id?: Maybe<Scalars['Int']>;
  /** массив Id - идентификаторов возрастной группы целевой аудитории */
  ageRanges?: Maybe<Scalars['ListOfInt']>;
  /** Агентское вознаграждение */
  agentCommission?: Maybe<Scalars['Int']>;
  /** АВ выносим отдельно или включаем в стоимость клика? (in/over) */
  agentCommissionPosition?: Maybe<Scalars['String']>;
  /** Наличие системы сквозной аналитики (0/1) */
  analyticSystem?: Maybe<Scalars['Boolean']>;
  /** Логин для системы сквозной аналитики */
  analyticSystemLogin?: Maybe<Scalars['String']>;
  /** Наименование системы сквозной аналитики */
  analyticSystemName?: Maybe<Scalars['String']>;
  /** Пароль для системы сквозной аналитики */
  analyticSystemPassword?: Maybe<Scalars['String']>;
  /** Комментарий по целевой аудитории */
  audienceComment?: Maybe<Scalars['String']>;
  /** id - уникальный идентификатор бренда */
  brand_id?: Maybe<Scalars['Int']>;
  /** Наличие системы call-tracking» (0/1) */
  callTracking?: Maybe<Scalars['Boolean']>;
  /** @TODO Добавить описание */
  callTrackingFile?: Maybe<Array<Maybe<File>>>;
  /** Наименование системы колтрекинга */
  callTrackingName?: Maybe<Scalars['String']>;
  /** Стоимость клика */
  clickCost?: Maybe<Scalars['Int']>;
  /** Условие для стоимости кликов (<=, min) */
  clickCostCondition?: Maybe<Scalars['String']>;
  /** Количество кликов */
  clicks?: Maybe<Scalars['Int']>;
  /** Условие для количества кликов (>=, max) */
  clicksCondition?: Maybe<Scalars['String']>;
  /** Конкуренты клиента */
  competitors?: Maybe<Scalars['String']>;
  /** Есть CRM? */
  crm?: Maybe<Scalars['Boolean']>;
  /** База crm от 1000 контактов из CRM */
  crmCustomers?: Maybe<Scalars['Boolean']>;
  /** Файл-отчеты из CRM-системы */
  crmCustomersFile?: Maybe<Array<Maybe<File>>>;
  /** Форма обращения «звонок» (0/1) */
  ctCall?: Maybe<Scalars['Boolean']>;
  /** Форма обращения «чат» (0/1) */
  ctChat?: Maybe<Scalars['Boolean']>;
  /** Форма обращения «e-mail» (0/1) */
  ctEmail?: Maybe<Scalars['Boolean']>;
  /** Форма обращения «форма обратной связи» (0/1) */
  ctForm?: Maybe<Scalars['Boolean']>;
  /** Форма обращения «ловец лидов (лид-форма)» (0/1) */
  ctLead?: Maybe<Scalars['Boolean']>;
  /** Дата конца рекламной кампании */
  dateEnd?: Maybe<Scalars['Date']>;
  /** Дата начала рекламной кампании */
  dateStart?: Maybe<Scalars['Date']>;
  /** Deadline для отдела контекста */
  deadline?: Maybe<Scalars['DateTime']>;
  /** Проект клиента e-commerce или нет (0/1) */
  ecommerce?: Maybe<Scalars['Boolean']>;
  /** Электронная коммерция настроена? */
  ecommerceAccess?: Maybe<Scalars['Int']>;
  /** @TODO Добавить описание */
  ecommerceAccessFile?: Maybe<Array<Maybe<File>>>;
  /**  e-commerce в Google Analytics (0/1) */
  ecommerceInGA?: Maybe<Scalars['Boolean']>;
  /**   e-commerce в Yandex Metrika (0/1) */
  ecommerceInYM?: Maybe<Scalars['Boolean']>;
  /** Дата заполнения брифа */
  entryDate?: Maybe<Scalars['DateTime']>;
  /** Доступ к сервису Google Analytics */
  googleAnalytics?: Maybe<Scalars['Boolean']>;
  /** id - уникальный идентификатор записи */
  id?: Maybe<Scalars['Int']>;
  /** Ключевое сообщение */
  keyMessage?: Maybe<Scalars['String']>;
  /** Комментарий по KPI */
  kpiComment?: Maybe<Scalars['String']>;
  /** Тип KPI для рекламной кампании (traffic, action, sales) */
  kpiType?: Maybe<Scalars['String']>;
  /** Дата создания текущей версии */
  lastUpdate?: Maybe<Scalars['DateTime']>;
  /** Условие для количества лидов (>=, max) */
  leadCondition?: Maybe<Scalars['String']>;
  /** Стоимость лида */
  leadCost?: Maybe<Scalars['Float']>;
  /** Условие для количества лидов (<=, min) */
  leadCostCondition?: Maybe<Scalars['String']>;
  /** Количество лидов */
  leads?: Maybe<Scalars['Int']>;
  /** id - уникальный идентификатор менеджера */
  manager_id?: Maybe<Scalars['Int']>;
  /** название брифа */
  name?: Maybe<Scalars['String']>;
  /** id - уникальный идентификатор партнёра */
  partner_id?: Maybe<Scalars['Int']>;
  /** Продвигаемый продукт или услуги */
  products?: Maybe<Scalars['String']>;
  /** Необходимость разработки рекламных материалов (0/1) */
  promotionalMaterial?: Maybe<Scalars['Boolean']>;
  /** Причина отказа */
  refusal?: Maybe<Scalars['Int']>;
  /** Описание причины отказа */
  refusalDescription?: Maybe<Scalars['String']>;
  /** Пол целевой аудитории (f,m,fm) */
  sex?: Maybe<Scalars['String']>;
  /** Идентификатор записи */
  status?: Maybe<Scalars['Int']>;
  /** id задачи в комоне */
  taskId?: Maybe<Scalars['Int']>;
  /** Бюджет на контекстную рекламу в т.ч. НДС на весь период */
  totalBudget?: Maybe<Scalars['Int']>;
  /** Включено ли агентское вознаграждение в бюджет (0/1) */
  totalBudgetAcIn?: Maybe<Scalars['Boolean']>;
  /** Версия, необходима для хранения изменённых копий. */
  version?: Maybe<Scalars['Boolean']>;
  /** URL сайта клиента */
  website?: Maybe<Scalars['String']>;
  /** Возможность внесения изменений на сайте клиента (0/1) */
  websiteChangeable?: Maybe<Scalars['Boolean']>;
  /** Доступ к сервису Яндекс Метрика */
  yandexMetrika?: Maybe<Scalars['Boolean']>;
};

/** Бриф контекстной рекламы */
export type DigitalBriefingContextInput = {
  /** Первое действие пользователей на сайте */
  actionFirst?: Maybe<Scalars['String']>;
  /** Второе действие пользователей на сайте */
  actionSecond?: Maybe<Scalars['String']>;
  /** Первое действие пользователей на сайте */
  actionThird?: Maybe<Scalars['String']>;
  /** Цель рекламной кампании */
  advTask?: Maybe<Scalars['Int']>;
  /** Другая цель рекламной кампании */
  advTaskOther?: Maybe<Scalars['String']>;
  /** Уникальное торговое предожение клиента */
  advantages?: Maybe<Scalars['String']>;
  /** Id - идентификатор возрастной группы целевой аудитории */
  ageRang_id?: Maybe<Scalars['Int']>;
  /** массив Id - идентификаторов возрастной группы целевой аудитории */
  ageRanges?: Maybe<Scalars['ListOfInt']>;
  /** Агентское вознаграждение */
  agentCommission?: Maybe<Scalars['Int']>;
  /** АВ выносим отдельно или включаем в стоимость клика? (in/over) */
  agentCommissionPosition?: Maybe<Scalars['String']>;
  /** Наличие системы сквозной аналитики (0/1) */
  analyticSystem?: Maybe<Scalars['Boolean']>;
  /** Логин для системы сквозной аналитики */
  analyticSystemLogin?: Maybe<Scalars['String']>;
  /** Наименование системы сквозной аналитики */
  analyticSystemName?: Maybe<Scalars['String']>;
  /** Пароль для системы сквозной аналитики */
  analyticSystemPassword?: Maybe<Scalars['String']>;
  /** Комментарий по целевой аудитории */
  audienceComment?: Maybe<Scalars['String']>;
  /** id - уникальный идентификатор бренда */
  brand_id?: Maybe<Scalars['Int']>;
  /** Наличие системы call-tracking» (0/1) */
  callTracking?: Maybe<Scalars['Boolean']>;
  /** ---- */
  callTrackingFile?: Maybe<Array<Maybe<Scalars['Int']>>>;
  /** Наименование системы колтрекинга */
  callTrackingName?: Maybe<Scalars['String']>;
  /** Стоимость клика */
  clickCost?: Maybe<Scalars['Int']>;
  /** Условие для стоимости кликов (<=, min) */
  clickCostCondition?: Maybe<Scalars['String']>;
  /** Количество кликов */
  clicks?: Maybe<Scalars['Int']>;
  /** Условие для количества кликов (>=, max) */
  clicksCondition?: Maybe<Scalars['String']>;
  /** Конкуренты клиента */
  competitors?: Maybe<Scalars['String']>;
  /** Есть CRM? */
  crm?: Maybe<Scalars['Boolean']>;
  /** База crm от 1000 контактов из CRM */
  crmCustomers?: Maybe<Scalars['Boolean']>;
  /** id Файл-отчеты из CRM-системы */
  crmCustomersFile?: Maybe<Array<Maybe<Scalars['Int']>>>;
  /** Форма обращения «звонок» (0/1) */
  ctCall?: Maybe<Scalars['Boolean']>;
  /** Форма обращения «чат» (0/1) */
  ctChat?: Maybe<Scalars['Boolean']>;
  /** Форма обращения «e-mail» (0/1) */
  ctEmail?: Maybe<Scalars['Boolean']>;
  /** Форма обращения «форма обратной связи» (0/1) */
  ctForm?: Maybe<Scalars['Boolean']>;
  /** Форма обращения «ловец лидов (лид-форма)» (0/1) */
  ctLead?: Maybe<Scalars['Boolean']>;
  /** Дата конца рекламной кампании */
  dateEnd?: Maybe<Scalars['Date']>;
  /** Дата начала рекламной кампании */
  dateStart?: Maybe<Scalars['Date']>;
  /** Deadline для отдела контекста */
  deadline?: Maybe<Scalars['DateTime']>;
  /** Проект клиента e-commerce или нет (0/1) */
  ecommerce?: Maybe<Scalars['Boolean']>;
  /** Электронная коммерция настроена? */
  ecommerceAccess?: Maybe<Scalars['Int']>;
  /** ---- */
  ecommerceAccessFile?: Maybe<Array<Maybe<Scalars['Int']>>>;
  /**  e-commerce в Google Analytics (0/1) */
  ecommerceInGA?: Maybe<Scalars['Boolean']>;
  /**   e-commerce в Yandex Metrika (0/1) */
  ecommerceInYM?: Maybe<Scalars['Boolean']>;
  /** Дата заполнения брифа */
  entryDate?: Maybe<Scalars['DateTime']>;
  /** Доступ к сервису Google Analytics */
  googleAnalytics?: Maybe<Scalars['Boolean']>;
  /** id - уникальный идентификатор записи */
  id?: Maybe<Scalars['Int']>;
  /** Ключевое сообщение */
  keyMessage?: Maybe<Scalars['String']>;
  /** Комментарий по KPI */
  kpiComment?: Maybe<Scalars['String']>;
  /** Тип KPI для рекламной кампании (traffic, action, sales) */
  kpiType?: Maybe<Scalars['String']>;
  /** Дата создания текущей версии */
  lastUpdate?: Maybe<Scalars['DateTime']>;
  /** Условие для количества лидов (>=, max) */
  leadCondition?: Maybe<Scalars['String']>;
  /** Стоимость лида */
  leadCost?: Maybe<Scalars['Float']>;
  /** Условие для количества лидов (<=, min) */
  leadCostCondition?: Maybe<Scalars['String']>;
  /** Количество лидов */
  leads?: Maybe<Scalars['Int']>;
  /** id - уникальный идентификатор менеджера */
  manager_id?: Maybe<Scalars['Int']>;
  /** название брифа */
  name?: Maybe<Scalars['String']>;
  /** id - уникальный идентификатор партнёра */
  partner_id?: Maybe<Scalars['Int']>;
  /** Продвигаемый продукт или услуги */
  products?: Maybe<Scalars['String']>;
  /** Необходимость разработки рекламных материалов (0/1) */
  promotionalMaterial?: Maybe<Scalars['Boolean']>;
  /** Причина отказа */
  refusal?: Maybe<Scalars['Int']>;
  /** Описание причины отказа */
  refusalDescription?: Maybe<Scalars['String']>;
  /** Пол целевой аудитории (f,m,fm) */
  sex?: Maybe<Scalars['String']>;
  /** Идентификатор записи */
  status?: Maybe<Scalars['Int']>;
  /** id задачи в комоне */
  taskId?: Maybe<Scalars['Int']>;
  /** Бюджет на контекстную рекламу в т.ч. НДС на весь период */
  totalBudget?: Maybe<Scalars['Int']>;
  /** Включено ли агентское вознаграждение в бюджет (0/1) */
  totalBudgetAcIn?: Maybe<Scalars['Boolean']>;
  /** Версия, необходима для хранения изменённых копий. */
  version?: Maybe<Scalars['Boolean']>;
  /** URL сайта клиента */
  website?: Maybe<Scalars['String']>;
  /** Возможность внесения изменений на сайте клиента (0/1) */
  websiteChangeable?: Maybe<Scalars['Boolean']>;
  /** Доступ к сервису Яндекс Метрика */
  yandexMetrika?: Maybe<Scalars['Boolean']>;
};

/** Загрузка данных */
export type DtDocumentInput = {
  /** Дата договора */
  DocDate?: Maybe<Scalars['DateTime']>;
  /** Номер договора */
  DocNumber?: Maybe<Scalars['String']>;
  /** Заголовок договора */
  DocSubject?: Maybe<Scalars['String']>;
  /** id договора */
  Document_ID?: Maybe<Scalars['ID']>;
  /** Дни отсрочки */
  delay?: Maybe<Scalars['Int']>;
  /** id договора */
  id?: Maybe<Scalars['ID']>;
};

/** Загрузка данных по соисполнителям */
export type EmployeeInput = {
  /** id */
  id?: Maybe<Scalars['String']>;
  /** Имя */
  name?: Maybe<Scalars['String']>;
  /** Имя Фамилия */
  nameFull?: Maybe<Scalars['String']>;
};

/** Стандартный объект */
export type FederalDistrict = {
  __typename?: 'FederalDistrict';
  /** Название населенного пункта */
  Code?: Maybe<Scalars['String']>;
  /** Название населенного пункта */
  FederalDistrict?: Maybe<Scalars['String']>;
  /** Регион РФ */
  FederalSubjects?: Maybe<Array<Maybe<MlRegion>>>;
  /** Название населенного пункта */
  id?: Maybe<Scalars['Int']>;
  /** Название населенного пункта */
  name?: Maybe<Scalars['String']>;
};

/** ввод */
export type FederalDistrictInput = {
  /** Название населенного пункта */
  Code?: Maybe<Scalars['String']>;
  District?: Maybe<FederalDistrictInput>;
  /** Название населенного пункта */
  FederalDistrict?: Maybe<Scalars['String']>;
  FederalSubjects?: Maybe<Array<Maybe<MlRegionInput>>>;
  /** Название населенного пункта */
  id?: Maybe<Scalars['Int']>;
  isFull?: Maybe<Scalars['Boolean']>;
  /** Название населенного пункта */
  name?: Maybe<Scalars['String']>;
};

/** -- */
export type File = {
  __typename?: 'File';
  /** Комментарий к файлу */
  comment?: Maybe<Scalars['String']>;
  /** Хеш имени файла */
  fileName?: Maybe<Scalars['String']>;
  /** Тип файла */
  fileType?: Maybe<Scalars['Int']>;
  /** id файла */
  id?: Maybe<Scalars['Int']>;
  /** имя файла */
  name?: Maybe<Scalars['String']>;
  /** путь до файла */
  path?: Maybe<Scalars['String']>;
  /** Исходное имя файла */
  srcFileName?: Maybe<Scalars['String']>;
};

/** Ролик для размещения */
export type Film = {
  __typename?: 'Film';
  /** id - уникальный идентификатор записи ролика */
  commInMplID: Scalars['ID'];
  /** название ролика */
  filmName?: Maybe<Scalars['String']>;
  /** месяц */
  mplMonth?: Maybe<Scalars['Int']>;
};

export type FiltersInput = {
  /** Имя поля */
  field?: Maybe<Scalars['String']>;
  /** Условие сравнения */
  operator?: Maybe<Scalars['String']>;
  /** Единичное значение */
  value?: Maybe<Scalars['String']>;
  /** Список значений для сравнения */
  values?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** корневой объект ГЕО-модуля */
export type Geo = {
  __typename?: 'GEO';
  /** Стандартный объект */
  FederalDistrict?: Maybe<FederalDistrict>;
  /** Стандартный объект */
  FederalDistrictList?: Maybe<Array<Maybe<FederalDistrict>>>;
  /** Регион РФ */
  FederalSubject?: Maybe<MlRegion>;
  /** Регион РФ */
  FederalSubjectList?: Maybe<Array<Maybe<MlRegion>>>;
  /** MlFias */
  FiasEntity?: Maybe<MlFias>;
  /** MlFias */
  FiasEntityList?: Maybe<Array<Maybe<MlFias>>>;
  /** Город РФ */
  Locality?: Maybe<MlCity>;
  /** Город РФ */
  LocalityList?: Maybe<Array<Maybe<MlCity>>>;
};


/** корневой объект ГЕО-модуля */
export type GeoFederalDistrictArgs = {
  id: Scalars['Int'];
};


/** корневой объект ГЕО-модуля */
export type GeoFederalDistrictListArgs = {
  params?: Maybe<ParamsInput>;
};


/** корневой объект ГЕО-модуля */
export type GeoFederalSubjectArgs = {
  id: Scalars['String'];
};


/** корневой объект ГЕО-модуля */
export type GeoFederalSubjectListArgs = {
  params?: Maybe<ParamsInput>;
};


/** корневой объект ГЕО-модуля */
export type GeoFiasEntityArgs = {
  id?: Maybe<Scalars['String']>;
};


/** корневой объект ГЕО-модуля */
export type GeoFiasEntityListArgs = {
  params?: Maybe<ParamsInput>;
};


/** корневой объект ГЕО-модуля */
export type GeoLocalityArgs = {
  id?: Maybe<Scalars['String']>;
};


/** корневой объект ГЕО-модуля */
export type GeoLocalityListArgs = {
  params?: Maybe<ParamsInput>;
};

/** Доступ */
export type GroupAccessItem = {
  __typename?: 'GroupAccessItem';
  /** id доступа */
  id?: Maybe<Scalars['Int']>;
  /** техническое название */
  sys_name?: Maybe<Scalars['String']>;
  /** Человекопонятное название */
  title?: Maybe<Scalars['String']>;
};

/** Группа */
export type GroupItem = {
  __typename?: 'GroupItem';
  /** Список доступных директорий */
  access?: Maybe<Scalars['String']>;
  /** id - уникальный идентификатор записи */
  id?: Maybe<Scalars['Int']>;
  /** Имя группы */
  name?: Maybe<Scalars['String']>;
  /** Информация о пользователе */
  users?: Maybe<Array<Maybe<UserProfile>>>;
};

export type InputMediaNetworkAndPerson = {
  ap_id?: Maybe<Scalars['Int']>;
  cities?: Maybe<Array<Maybe<Scalars['String']>>>;
  medianetwork_id?: Maybe<Scalars['Int']>;
  person_id?: Maybe<Scalars['Int']>;
};

export type InputMediaNetworkAndPersonSendPriceRequestEmails = {
  ap_id?: Maybe<Scalars['Int']>;
  cities?: Maybe<Array<Maybe<Scalars['String']>>>;
  medianetwork_id?: Maybe<Scalars['Int']>;
  person_id?: Maybe<Scalars['Int']>;
};

/** Загрузка данных элемента контактов */
export type IntervalInput = {
  /** link to contract entity */
  agrID?: Maybe<Scalars['Int']>;
  /** время конца */
  endTime?: Maybe<Scalars['String']>;
  /** id - уникальный идентификатор записи */
  id?: Maybe<Scalars['ID']>;
  /** время начала */
  startTime?: Maybe<Scalars['String']>;
  /** Название */
  status?: Maybe<Scalars['Int']>;
  /** parameter for entity updating */
  tempID?: Maybe<Scalars['String']>;
};

/** Элемент лога */
export type ItemLog = {
  __typename?: 'ItemLog';
  /** Информация о пользователе */
  User?: Maybe<UserProfile>;
  /** Дата */
  action_type?: Maybe<Scalars['Int']>;
  /** автор */
  author_id?: Maybe<Scalars['Int']>;
  /** Дата */
  entry_date?: Maybe<Scalars['DateTime']>;
  /** Имя поля */
  field_name?: Maybe<Scalars['String']>;
  /** ID */
  id?: Maybe<Scalars['ID']>;
  /** текущее значение */
  next_value?: Maybe<Scalars['String']>;
  /** площадка */
  platform_id?: Maybe<Scalars['Int']>;
  /** предыдущее значение */
  prev_value?: Maybe<Scalars['String']>;
  /** ID изначальный */
  src_id?: Maybe<Scalars['Int']>;
};



/** -- */
export type ListWithPager_Budget = {
  __typename?: 'listWithPager_Budget';
  /** Массив Budget */
  data?: Maybe<Array<Maybe<Budget>>>;
  /** Информация о странице */
  pageInfo?: Maybe<Scalars['pageInfo']>;
};

/** -- */
export type ListWithPager_DigitalBriefingContext = {
  __typename?: 'listWithPager_DigitalBriefingContext';
  /** Массив DigitalBriefingContext */
  data?: Maybe<Array<Maybe<DigitalBriefingContext>>>;
  /** Информация о странице */
  pageInfo?: Maybe<Scalars['pageInfo']>;
};

/** -- */
export type ListWithPager_GroupItem = {
  __typename?: 'listWithPager_GroupItem';
  /** Массив GroupItem */
  data?: Maybe<Array<Maybe<GroupItem>>>;
  /** Информация о странице */
  pageInfo?: Maybe<Scalars['pageInfo']>;
};

/** -- */
export type ListWithPager_ItemLog = {
  __typename?: 'listWithPager_ItemLog';
  /** Массив ItemLog */
  data?: Maybe<Array<Maybe<ItemLog>>>;
  /** Информация о странице */
  pageInfo?: Maybe<Scalars['pageInfo']>;
};

/** -- */
export type ListWithPager_MlMediaNetwork = {
  __typename?: 'listWithPager_MlMediaNetwork';
  /** Массив MlMediaNetwork */
  data?: Maybe<Array<Maybe<MlMediaNetwork>>>;
  /** Информация о странице */
  pageInfo?: Maybe<Scalars['pageInfo']>;
};

/** -- */
export type ListWithPager_MPlan = {
  __typename?: 'listWithPager_MPlan';
  /** Массив MPlan */
  data?: Maybe<Array<Maybe<MPlan>>>;
  /** Информация о странице */
  pageInfo?: Maybe<Scalars['pageInfo']>;
};

/** -- */
export type ListWithPager_OohAp = {
  __typename?: 'listWithPager_OohAP';
  /** Массив OohAP */
  data?: Maybe<Array<Maybe<OohAp>>>;
  /** Информация о странице */
  pageInfo?: Maybe<Scalars['pageInfo']>;
};

/** -- */
export type ListWithPager_OohBriefing = {
  __typename?: 'listWithPager_OohBriefing';
  /** Массив OohBriefing */
  data?: Maybe<Array<Maybe<OohBriefing>>>;
  /** Информация о странице */
  pageInfo?: Maybe<Scalars['pageInfo']>;
};

/** -- */
export type ListWithPager_RnBriefing = {
  __typename?: 'listWithPager_RnBriefing';
  /** Массив RnBriefing */
  data?: Maybe<Array<Maybe<RnBriefing>>>;
  /** Информация о странице */
  pageInfo?: Maybe<Scalars['pageInfo']>;
};

/** -- */
export type ListWithPager_RnInventoryItem = {
  __typename?: 'listWithPager_rnInventoryItem';
  /** Массив rnInventoryItem */
  data?: Maybe<Array<Maybe<RnInventoryItem>>>;
  /** Информация о странице */
  pageInfo?: Maybe<Scalars['pageInfo']>;
};

/** -- */
export type ListWithPager_RnMp = {
  __typename?: 'listWithPager_RnMp';
  /** Массив RnMp */
  data?: Maybe<Array<Maybe<RnMp>>>;
  /** Информация о странице */
  pageInfo?: Maybe<Scalars['pageInfo']>;
};

/** -- */
export type ListWithPager_RnMpItemReport = {
  __typename?: 'listWithPager_RnMpItemReport';
  /** Массив RnMpItemReport */
  data?: Maybe<Array<Maybe<RnMpItemReport>>>;
  /** Информация о странице */
  pageInfo?: Maybe<Scalars['pageInfo']>;
};

/** -- */
export type ListWithPager_RnPlatform = {
  __typename?: 'listWithPager_rnPlatform';
  /** Массив rnPlatform */
  data?: Maybe<Array<Maybe<RnPlatform>>>;
  /** Информация о странице */
  pageInfo?: Maybe<Scalars['pageInfo']>;
};

/** -- */
export type ListWithPager_VimbContract = {
  __typename?: 'listWithPager_VimbContract';
  /** Массив VimbContract */
  data?: Maybe<Array<Maybe<VimbContract>>>;
  /** Информация о странице */
  pageInfo?: Maybe<Scalars['pageInfo']>;
};

/** -- */
export type ListWithPager_VimbPrimeItem = {
  __typename?: 'listWithPager_VimbPrimeItem';
  /** Массив VimbPrimeItem */
  data?: Maybe<Array<Maybe<VimbPrimeItem>>>;
  /** Информация о странице */
  pageInfo?: Maybe<Scalars['pageInfo']>;
};

/** -- */
export type ListWithPager_VimbTargetAudience = {
  __typename?: 'listWithPager_VimbTargetAudience';
  /** Массив VimbTargetAudience */
  data?: Maybe<Array<Maybe<VimbTargetAudience>>>;
  /** Информация о странице */
  pageInfo?: Maybe<Scalars['pageInfo']>;
};

/** -- */
export type ListWithPager_VimbTargeting = {
  __typename?: 'listWithPager_VimbTargeting';
  /** Массив VimbTargeting */
  data?: Maybe<Array<Maybe<VimbTargeting>>>;
  /** Информация о странице */
  pageInfo?: Maybe<Scalars['pageInfo']>;
};

/** Стандартный объект */
export type MailLogOohQueries = {
  __typename?: 'MailLogOOHQueries';
  /** Список людей которые отправяли письма прайсов */
  Authors?: Maybe<Array<Maybe<RefEmployee>>>;
  /** Список людей которые отправяли письма прайсов */
  Cities?: Maybe<Array<Maybe<MlCity>>>;
  /** Список людей которые отправяли письма прайсов */
  MediaNetworks?: Maybe<Array<Maybe<MlMediaNetwork>>>;
  /** Список людей которые отправяли письма прайсов */
  getList?: Maybe<Array<Maybe<OohapEmailLogItem>>>;
};


/** Стандартный объект */
export type MailLogOohQueriesGetListArgs = {
  params?: Maybe<ParamsInput>;
};

/** Стандартный объект */
export type MediaNetworksMutation = {
  __typename?: 'MediaNetworksMutation';
  /** Добавление персоны */
  ConstructionUpdate?: Maybe<OohConstruction>;
  /** Отправка писем , запрос прайса. */
  SendApPriceEmails?: Maybe<Scalars['Boolean']>;
  /** Загрука файлов */
  UploadTmpFile?: Maybe<Scalars['String']>;
};


/** Стандартный объект */
export type MediaNetworksMutationConstructionUpdateArgs = {
  construction?: Maybe<MnConstructionInput>;
};


/** Стандартный объект */
export type MediaNetworksMutationSendApPriceEmailsArgs = {
  selectedEmails?: Maybe<Array<Maybe<InputMediaNetworkAndPerson>>>;
};


/** Стандартный объект */
export type MediaNetworksMutationUploadTmpFileArgs = {
  file?: Maybe<Scalars['Upload']>;
};

/** Стандартный объект */
export type MediaNetworksOohQueries = {
  __typename?: 'MediaNetworksOOHQueries';
  /** ближайшая Цена на аренду объекта  */
  GetObjectPrice?: Maybe<MlConstructionsPriceInfo>;
  /** поставщики OOH */
  MediaNetworkListWithPager?: Maybe<ListWithPager_MlMediaNetwork>;
  /** Список людей которые отправяли письма прайсов */
  getPriceMailAuthors?: Maybe<Array<Maybe<RefEmployee>>>;
  /** Список людей которые отправяли письма прайсов */
  getPriceMailList?: Maybe<Array<Maybe<OohapPriceEmailListItem>>>;
  /** шаблоны для письма */
  priceEmailTemplate?: Maybe<OohapPriceEmail>;
  /** шаблоны для письма */
  reservEmailTemplate?: Maybe<OohapReservEmailInfo>;
};


/** Стандартный объект */
export type MediaNetworksOohQueriesGetObjectPriceArgs = {
  object_id?: Maybe<Scalars['String']>;
  workTypeId?: Maybe<Scalars['Int']>;
};


/** Стандартный объект */
export type MediaNetworksOohQueriesMediaNetworkListWithPagerArgs = {
  params?: Maybe<ParamsInput>;
};


/** Стандартный объект */
export type MediaNetworksOohQueriesGetPriceMailListArgs = {
  params?: Maybe<ParamsInput>;
};


/** Стандартный объект */
export type MediaNetworksOohQueriesPriceEmailTemplateArgs = {
  ap_id?: Maybe<Scalars['Int']>;
  cities?: Maybe<Array<Maybe<Scalars['String']>>>;
  medianetwork_id?: Maybe<Scalars['Int']>;
  person_id?: Maybe<Scalars['Int']>;
};


/** Стандартный объект */
export type MediaNetworksOohQueriesReservEmailTemplateArgs = {
  ap_id?: Maybe<Scalars['Int']>;
  fias_aoguid?: Maybe<Scalars['String']>;
  medianetwork_id?: Maybe<Scalars['Int']>;
  person_id?: Maybe<Scalars['Int']>;
};

/** Юридическая информация о   поставщике , ндс */
export type MediaNetworkUrInfoInput = {
  /** - */
  ap_id?: Maybe<Scalars['Int']>;
  /** - */
  fias_aoguid?: Maybe<Scalars['String']>;
  /** - */
  medianetwork_id?: Maybe<Scalars['Int']>;
  /** - */
  urface_id?: Maybe<Scalars['Int']>;
  /** - */
  vat?: Maybe<Scalars['Boolean']>;
  /** - */
  worktype_id?: Maybe<Scalars['Int']>;
};

/** Бренд / Торговая марка */
export type MlBrand = {
  __typename?: 'MLBrand';
  /** id бренда */
  id?: Maybe<Scalars['String']>;
  /** Название бренда */
  name?: Maybe<Scalars['String']>;
};

/** Город РФ */
export type MlCity = {
  __typename?: 'MlCity';
  /** AOGUID */
  AOGUID?: Maybe<Scalars['String']>;
  /** Родительский регион */
  District?: Maybe<FederalDistrict>;
  /** id целевой аудитории */
  FIAS_ID?: Maybe<Scalars['String']>;
  /** Наменование региона */
  FORMALNAME?: Maybe<Scalars['String']>;
  /** Полный адрес населённого пункта */
  FULL_NAME?: Maybe<Scalars['String']>;
  /** ОКАТО */
  OKATO?: Maybe<Scalars['Int']>;
  /** Родительский субъект */
  Parent?: Maybe<MlCity>;
  /** Родительский регион */
  Region?: Maybe<MlRegion>;
  /** короткое имя */
  SHORTNAME?: Maybe<Scalars['String']>;
  /** id региона */
  id?: Maybe<Scalars['String']>;
};

/** Загрузка данных */
export type MlCityInput = {
  /** AOGUID */
  AOGUID?: Maybe<Scalars['String']>;
  /** Input */
  District?: Maybe<FederalDistrictInput>;
  /** id целевой аудитории */
  FIAS_ID?: Maybe<Scalars['String']>;
  /** Наменование региона */
  FORMALNAME?: Maybe<Scalars['String']>;
  /** Полный адрес населённого пункта */
  FULL_NAME?: Maybe<Scalars['String']>;
  /** ОКАТО */
  OKATO?: Maybe<Scalars['Int']>;
  /** Input for Регион РФ */
  Parent?: Maybe<MlRegionInput>;
  /** Input */
  Region?: Maybe<MlRegionInput>;
  /** короткое имя */
  SHORTNAME?: Maybe<Scalars['String']>;
  /** id региона */
  id?: Maybe<Scalars['String']>;
};

/** Доступность конструции */
export type MlConstructionsAvailabilityInfo = {
  __typename?: 'MLConstructionsAvailabilityInfo';
  /** id объекта .  */
  Object_UID?: Maybe<Scalars['String']>;
  /** Начало периода */
  Period?: Maybe<Scalars['Date']>;
  /** Статус */
  availability?: Maybe<Scalars['Int']>;
  /** id */
  id?: Maybe<Scalars['String']>;
};

/** Грязные данные */
export type MlConstructionsDirtyData = {
  __typename?: 'MLConstructionsDirtyData';
  /** GRP */
  GRP?: Maybe<Scalars['String']>;
  /** 	OTS */
  OTS?: Maybe<Scalars['String']>;
  /** Obj_Hash_Own  */
  Obj_Hash_Own?: Maybe<Scalars['String']>;
  /** administrative_district */
  administrative_district?: Maybe<Scalars['String']>;
  /** agent_name  */
  agent_name?: Maybe<Scalars['String']>;
  /** angle */
  angle?: Maybe<Scalars['String']>;
  /** city */
  city?: Maybe<Scalars['String']>;
  /** 	construction_type_ru */
  construction_type_ru?: Maybe<Scalars['String']>;
  /** country */
  country?: Maybe<Scalars['String']>;
  /** 	description */
  description?: Maybe<Scalars['String']>;
  /** literal_code */
  exposition?: Maybe<Scalars['String']>;
  /** file_name */
  file_name?: Maybe<Scalars['String']>;
  /** installation_type */
  installation_type?: Maybe<Scalars['String']>;
  /** 	lat */
  lat?: Maybe<Scalars['String']>;
  /** 		literal_code */
  literal_code?: Maybe<Scalars['String']>;
  /** living_district */
  living_district?: Maybe<Scalars['String']>;
  /** lng */
  lng?: Maybe<Scalars['String']>;
  /** location_type */
  location_type?: Maybe<Scalars['String']>;
  /** material_type */
  material_type?: Maybe<Scalars['String']>;
  /** media_network */
  media_network?: Maybe<Scalars['String']>;
  /** 	owner_address  */
  owner_address?: Maybe<Scalars['String']>;
  /** owner_construction_type  */
  owner_construction_type?: Maybe<Scalars['String']>;
  /** owner_gid  */
  owner_gid?: Maybe<Scalars['String']>;
  /** owner_light */
  owner_light?: Maybe<Scalars['String']>;
  /** owner_side  */
  owner_side?: Maybe<Scalars['String']>;
  /** 	owner_size  */
  owner_size?: Maybe<Scalars['String']>;
  /** region */
  region?: Maybe<Scalars['String']>;
  /** id   */
  rgh_OOH_Place_ID?: Maybe<Scalars['Int']>;
  /** sheet_name  */
  sheet_name?: Maybe<Scalars['String']>;
  /** 	url_photo */
  url_photo?: Maybe<Scalars['String']>;
  /** 	url_schem */
  url_schem?: Maybe<Scalars['String']>;
};

/** Цены */
export type MlConstructionsPriceInfo = {
  __typename?: 'MLConstructionsPriceInfo';
  /** Применять НДС */
  AppliesVAT?: Maybe<Scalars['Boolean']>;
  /** Тип работ  */
  CurrencyID?: Maybe<Scalars['ID']>;
  /** Тип работ  */
  OKEI?: Maybe<Scalars['ID']>;
  /** id объекта которому пренадлежит цена.  */
  Object_UID?: Maybe<Scalars['String']>;
  Period?: Maybe<Scalars['DateTime']>;
  /** Цена */
  Price?: Maybe<Scalars['Float']>;
  /** Включает НДС */
  PriceIncludesVAT?: Maybe<Scalars['Boolean']>;
  /** Тип цены  */
  PriceTypeId?: Maybe<Scalars['Int']>;
  /** Тип работ  */
  Price_ID?: Maybe<Scalars['Int']>;
  /** Тип работ  */
  WorkTypeId?: Maybe<Scalars['ID']>;
  /** id */
  id?: Maybe<Scalars['String']>;
};

/** Цены */
export type MlConstructionsWorkInfo = {
  __typename?: 'MLConstructionsWorkInfo';
  /** id объекта которому пренадлежит цена.  */
  Object_UID?: Maybe<Scalars['String']>;
  /** Начало периода */
  Period?: Maybe<Scalars['Date']>;
  /** Начало периода */
  PeriodEnd?: Maybe<Scalars['Date']>;
  /** Начало периода */
  PeriodStart?: Maybe<Scalars['Date']>;
  /** Прайсовая цена */
  Price?: Maybe<Scalars['Float']>;
  /** Горящая Прайсовая цена */
  PriceHot?: Maybe<Scalars['Float']>;
  /** Цена со скидкой (закупочная) */
  RealPrice?: Maybe<Scalars['Float']>;
  /** Продажная цена */
  SellPrice?: Maybe<Scalars['Float']>;
  /** Цена со скидкой (закупочная) */
  WithDiscount?: Maybe<Scalars['Float']>;
  /** Тип работ  */
  WorkType_ID?: Maybe<Scalars['String']>;
  /** Статус */
  availability?: Maybe<Scalars['Int']>;
  /** id */
  id?: Maybe<Scalars['String']>;
};

/** Тип конструкции */
export type MlConstructionType = {
  __typename?: 'MlConstructionType';
  /** Наменование конструкции */
  ConstrTypeShort?: Maybe<Scalars['String']>;
  /** id названия конструкции */
  id?: Maybe<Scalars['String']>;
  /** Наменование конструкции */
  name?: Maybe<Scalars['String']>;
};

/** Загрузка данных */
export type MlConstructionTypeInput = {
  /** Наменование конструкции */
  ConstrTypeShort?: Maybe<Scalars['String']>;
  /** id названия конструкции */
  id?: Maybe<Scalars['Int']>;
  /** Наменование конструкции */
  name?: Maybe<Scalars['String']>;
};

/** Загрузка данных элемента контактов */
export type MlContactInput = {
  /** Контакт */
  Contact?: Maybe<Scalars['String']>;
  /** id контакта */
  Contact_ID?: Maybe<Scalars['ID']>;
  /** Тип контакта */
  FK_v_ref_ContactType_ID?: Maybe<Scalars['Int']>;
};

/** Контакт для передачи в МЛ */
export type MlContactItem = {
  __typename?: 'MlContactItem';
  /** Контакт */
  Contact?: Maybe<Scalars['String']>;
  /** id контакта */
  Contact_ID?: Maybe<Scalars['ID']>;
  /** Тип контакта */
  FK_v_ref_ContactType_ID?: Maybe<Scalars['Int']>;
};

/** Список зон покрытия */
export type MlCoverage = {
  __typename?: 'MlCoverage';
  /** id покрытия */
  id?: Maybe<Scalars['Int']>;
  /** Наменование покрытия */
  name?: Maybe<Scalars['String']>;
};

/** Договор */
export type MlDocumentItem = {
  __typename?: 'MlDocumentItem';
  /** Дата договора */
  DocDate?: Maybe<Scalars['DateTime']>;
  /** Номер договора */
  DocNumber?: Maybe<Scalars['String']>;
  /** Заголовок договора */
  DocSubject?: Maybe<Scalars['String']>;
  /** id договора */
  Document_ID?: Maybe<Scalars['ID']>;
  /** Дни отсрочки */
  delay?: Maybe<Scalars['Int']>;
  /** id договора */
  id?: Maybe<Scalars['ID']>;
};

/** Юридические лица Дельтаплана */
export type MlDpUrFaces = {
  __typename?: 'MLDpUrFaces';
  /** id */
  id?: Maybe<Scalars['String']>;
  /** Название */
  name?: Maybe<Scalars['String']>;
};

/** Загрузка данных */
export type MlExpositionTypeInput = {
  /** id названия конструкции */
  id?: Maybe<Scalars['Int']>;
  /** Наменование конструкции */
  name?: Maybe<Scalars['String']>;
};

/** Сущность справочника ФИАС */
export type MlFias = {
  __typename?: 'MlFias';
  /** AOGUID */
  AOGUID?: Maybe<Scalars['String']>;
  /** Родительский регион */
  District?: Maybe<FederalDistrict>;
  /** id целевой аудитории */
  FIAS_ID?: Maybe<Scalars['String']>;
  /** Наменование региона */
  FORMALNAME?: Maybe<Scalars['String']>;
  /** Полный адрес населённого пункта */
  FULL_NAME?: Maybe<Scalars['String']>;
  /** ОКАТО */
  OKATO?: Maybe<Scalars['Int']>;
  /** Родительский субъект */
  Parent?: Maybe<MlCity>;
  /** Родительский регион */
  Region?: Maybe<MlRegion>;
  /** короткое имя */
  SHORTNAME?: Maybe<Scalars['String']>;
  /** id региона */
  id?: Maybe<Scalars['String']>;
};

/** Загрузка данных материала */
export type MlMaterialInput = {
  /** Название */
  Material?: Maybe<Scalars['String']>;
  /** address */
  Material_ID?: Maybe<Scalars['Int']>;
  /** id */
  id?: Maybe<Scalars['Int']>;
};

/** Справочник материалов */
export type MlMaterials = {
  __typename?: 'MLMaterials';
  /** Название */
  Material?: Maybe<Scalars['String']>;
  /** address */
  Material_ID?: Maybe<Scalars['Int']>;
  /** id */
  id?: Maybe<Scalars['Int']>;
};

/** Список медиагрупп */
export type MlMediaGroups = {
  __typename?: 'MlMediaGroups';
  /** Наменование медиагруппы */
  MediaGroup?: Maybe<Scalars['String']>;
  /** id строки */
  id?: Maybe<Scalars['Int']>;
};

/** поставщики OOH */
export type MlMediaNetwork = {
  __typename?: 'MlMediaNetwork';
  /** Количество конструций */
  ConstructionsCount?: Maybe<Scalars['Int']>;
  /** Медиа тип. */
  FK_ref_MediaType_ID?: Maybe<Scalars['Int']>;
  /** Наменование конструкции */
  MediaNetworkName?: Maybe<Scalars['String']>;
  /** id названия конструкции */
  MediaNetwork_ID?: Maybe<Scalars['Int']>;
  /** Список партнёров */
  Partner?: Maybe<MlPartner>;
  /** id названия конструкции */
  Partner_ID?: Maybe<Scalars['Int']>;
  /** Список персон */
  Persons?: Maybe<Array<Maybe<MlPersonItem>>>;
  /** список названий городов */
  fias_aoguid_names?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** список id городов */
  fias_aoguids?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** id названия конструкции */
  id?: Maybe<Scalars['Int']>;
};

/** Список медиагрупп */
export type MlMediaType = {
  __typename?: 'MlMediaType';
  /** id строки */
  id?: Maybe<Scalars['Int']>;
  /** Наменование медиатипов */
  name?: Maybe<Scalars['String']>;
};

/** Стандартный объект */
export type MlMutation = {
  __typename?: 'MlMutation';
  /** Добавление персоны */
  PersonCreate?: Maybe<MlPersonItem>;
  /** Удаление */
  PersonRemove?: Maybe<MlPersonItem>;
  /** Добавление персоны */
  PersonUpdate?: Maybe<MlPersonItem>;
};


/** Стандартный объект */
export type MlMutationPersonCreateArgs = {
  person?: Maybe<MlPersonItemInput>;
  system_id?: Maybe<Scalars['Int']>;
};


/** Стандартный объект */
export type MlMutationPersonRemoveArgs = {
  partner_id?: Maybe<Scalars['Int']>;
  person_id?: Maybe<Scalars['Int']>;
};


/** Стандартный объект */
export type MlMutationPersonUpdateArgs = {
  id?: Maybe<Scalars['Int']>;
  person?: Maybe<MlPersonItemInput>;
  system_id?: Maybe<Scalars['Int']>;
};

/** ОКЕИ */
export type MlOkei = {
  __typename?: 'MlOKEI';
  /** id */
  id?: Maybe<Scalars['String']>;
  /** Полное название на русском */
  name?: Maybe<Scalars['String']>;
};

/** Справочник ОКСМ */
export type MlOksm = {
  __typename?: 'MlOKSM';
  /** id */
  id?: Maybe<Scalars['Int']>;
  /** shortname */
  name?: Maybe<Scalars['String']>;
};

/** Справочник ОКВ */
export type MlOkv = {
  __typename?: 'MlOKV';
  /** ID валюты */
  id?: Maybe<Scalars['Int']>;
  /** Наименование валюты */
  name?: Maybe<Scalars['String']>;
};

/** партнёры */
export type MlPartner = {
  __typename?: 'MLPartner';
  /** Полное наименование партнёра */
  PartnerName?: Maybe<Scalars['String']>;
  /** Полное наименование партнёра */
  PartnerNameFull?: Maybe<Scalars['String']>;
  /** Полное наименование партнёра */
  PartnerPersons?: Maybe<Array<Maybe<MlPersonItem>>>;
  /** id партнёра */
  id?: Maybe<Scalars['String']>;
  /** Имя партнёра */
  name?: Maybe<Scalars['String']>;
  /** Полное имя  партнёра */
  nameFull?: Maybe<Scalars['String']>;
};

/** МЛ-Персона */
export type MlPersonItem = {
  __typename?: 'MlPersonItem';
  /** Город РФ */
  City?: Maybe<Array<Maybe<MlCity>>>;
  /** сумма ав */
  FirstName?: Maybe<Scalars['String']>;
  /** позиция агентского вознаграждения */
  MiddleName?: Maybe<Scalars['String']>;
  /** партнёры */
  Partner?: Maybe<MlPartner>;
  /** Полное наименование партнёра */
  PersonContacts?: Maybe<Array<Maybe<MlContactItem>>>;
  /** id - уникальный идентификатор */
  Person_ID?: Maybe<Scalars['Int']>;
  /** Должность */
  Post?: Maybe<Scalars['String']>;
  /** Полное наименование должности */
  PostName?: Maybe<Scalars['String']>;
  /** Роли персоны */
  Role?: Maybe<Array<Maybe<Scalars['Int']>>>;
  /** id - уникальный идентификатор */
  SurName?: Maybe<Scalars['String']>;
  /** Город персоны */
  city?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** ID партнера, к которому привязана персона */
  partner_id?: Maybe<Scalars['Int']>;
  /** Должность персоны */
  position?: Maybe<Scalars['String']>;
  /** Должность персоны */
  role?: Maybe<Array<Maybe<Scalars['Int']>>>;
};

/** Загрузка данных элемента контактов */
export type MlPersonItemInput = {
  /** сумма ав */
  FirstName?: Maybe<Scalars['String']>;
  /** позиция агентского вознаграждения */
  MiddleName?: Maybe<Scalars['String']>;
  /** Загрузка списка элементов контактов */
  PersonContacts?: Maybe<Array<Maybe<MlContactInput>>>;
  /** id - уникальный идентификатор */
  Person_ID?: Maybe<Scalars['Int']>;
  /** Должность */
  Post?: Maybe<Scalars['String']>;
  /** id - уникальный идентификатор */
  SurName?: Maybe<Scalars['String']>;
  /** Город персоны */
  city?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** ID партнера, к которому привязана персона */
  partner_id?: Maybe<Scalars['Int']>;
  /** Должность персоны */
  position?: Maybe<Scalars['String']>;
  /** Хеш города */
  ref_Fias_AOGUID?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Должность персоны */
  role?: Maybe<Array<Maybe<Scalars['Int']>>>;
};

/** Объект привязки для адресной программы */
export type MlPlacementObject = {
  __typename?: 'MlPlacementObject';
  /** id объекта */
  id?: Maybe<Scalars['String']>;
  /** Наменование объекта */
  name?: Maybe<Scalars['String']>;
};

/** Тип экспонирования */
export type MlQExpositionType = {
  __typename?: 'MlQExpositionType';
  /** id названия конструкции */
  id?: Maybe<Scalars['Int']>;
  /** Наменование конструкции */
  name?: Maybe<Scalars['String']>;
};

/** Стандартный объект */
export type MlQuery = {
  __typename?: 'MLQuery';
  /** Тип конструкции */
  AdmAreaList?: Maybe<Array<Maybe<CityAreaType>>>;
  /** Тип конструкции */
  CityArea?: Maybe<CityAreaType>;
  /** Тип конструкции */
  CityAreaList?: Maybe<Array<Maybe<CityAreaType>>>;
  /** Объект OOН наружней рекламы */
  Construction?: Maybe<OohConstruction>;
  /** Грязные данные */
  ConstructionDirty?: Maybe<MlConstructionsDirtyData>;
  /** Список конструкций */
  ConstructionList?: Maybe<Array<Maybe<OohConstruction>>>;
  /** Список конструкций */
  ConstructionListTotalCount?: Maybe<Scalars['Int']>;
  /** Цены */
  ConstructionPeriodInfo?: Maybe<Array<Maybe<MlConstructionsWorkInfo>>>;
  /** Цены */
  ConstructionPrices?: Maybe<Array<Maybe<MlConstructionsWorkInfo>>>;
  /** Тип конструкции */
  ConstructionType?: Maybe<QueriesMlConstructionType>;
  /** Тип конструкции */
  ConstructionTypeList?: Maybe<Array<Maybe<QueriesMlConstructionType>>>;
  /** Договор */
  DtDocument?: Maybe<MlDocumentItem>;
  /** Договор */
  DtDocumentList?: Maybe<Array<Maybe<MlDocumentItem>>>;
  /** Тип экспонирования */
  ExpositionType?: Maybe<MlQExpositionType>;
  /** Тип экспонирования */
  ExpositionTypeList?: Maybe<Array<Maybe<MlQExpositionType>>>;
  /** Справочник материалов */
  Material?: Maybe<MlMaterials>;
  /** Справочник материалов */
  MaterialList?: Maybe<Array<Maybe<MlMaterials>>>;
  /** поставщики OOH */
  MediaNetwork?: Maybe<MlMediaNetwork>;
  /** поставщики OOH */
  MediaNetworkList?: Maybe<Array<Maybe<MlMediaNetwork>>>;
  /** поставщики OOH */
  MediaNetworkListWithPager?: Maybe<ListWithPager_MlMediaNetwork>;
  /** МЛ-Персона */
  Person?: Maybe<MlPersonItem>;
  /** МЛ-Персона */
  PersonList?: Maybe<Array<Maybe<MlPersonItem>>>;
  /** Тип конструкции */
  ResidentAreaList?: Maybe<Array<Maybe<CityAreaType>>>;
  /** МЛ-Юридическое лицо */
  UrFace?: Maybe<MlUrFaceItem>;
  /** МЛ-Юридическое лицо */
  UrFaceList?: Maybe<Array<Maybe<MlUrFaceItem>>>;
};


/** Стандартный объект */
export type MlQueryAdmAreaListArgs = {
  params?: Maybe<ParamsInput>;
};


/** Стандартный объект */
export type MlQueryCityAreaArgs = {
  id: Scalars['Int'];
};


/** Стандартный объект */
export type MlQueryCityAreaListArgs = {
  params?: Maybe<ParamsInput>;
};


/** Стандартный объект */
export type MlQueryConstructionArgs = {
  id: Scalars['String'];
};


/** Стандартный объект */
export type MlQueryConstructionDirtyArgs = {
  id: Scalars['Int'];
};


/** Стандартный объект */
export type MlQueryConstructionListArgs = {
  params?: Maybe<ParamsInput>;
};


/** Стандартный объект */
export type MlQueryConstructionListTotalCountArgs = {
  params?: Maybe<ParamsInput>;
};


/** Стандартный объект */
export type MlQueryConstructionPeriodInfoArgs = {
  Object_UID: Scalars['String'];
  PeriodEnd: Scalars['Date'];
  PeriodStart: Scalars['Date'];
};


/** Стандартный объект */
export type MlQueryConstructionPricesArgs = {
  Object_UID: Scalars['String'];
  PeriodEnd: Scalars['Date'];
  PeriodStart: Scalars['Date'];
};


/** Стандартный объект */
export type MlQueryConstructionTypeArgs = {
  id: Scalars['Int'];
};


/** Стандартный объект */
export type MlQueryConstructionTypeListArgs = {
  params?: Maybe<ParamsInput>;
};


/** Стандартный объект */
export type MlQueryDtDocumentArgs = {
  id: Scalars['Int'];
};


/** Стандартный объект */
export type MlQueryDtDocumentListArgs = {
  params?: Maybe<ParamsInput>;
};


/** Стандартный объект */
export type MlQueryExpositionTypeArgs = {
  id: Scalars['Int'];
};


/** Стандартный объект */
export type MlQueryExpositionTypeListArgs = {
  params?: Maybe<ParamsInput>;
};


/** Стандартный объект */
export type MlQueryMaterialArgs = {
  id: Scalars['Int'];
};


/** Стандартный объект */
export type MlQueryMaterialListArgs = {
  params?: Maybe<ParamsInput>;
};


/** Стандартный объект */
export type MlQueryMediaNetworkArgs = {
  id: Scalars['Int'];
};


/** Стандартный объект */
export type MlQueryMediaNetworkListArgs = {
  params?: Maybe<ParamsInput>;
};


/** Стандартный объект */
export type MlQueryMediaNetworkListWithPagerArgs = {
  params?: Maybe<ParamsInput>;
};


/** Стандартный объект */
export type MlQueryPersonArgs = {
  id: Scalars['Int'];
};


/** Стандартный объект */
export type MlQueryPersonListArgs = {
  params?: Maybe<ParamsInput>;
};


/** Стандартный объект */
export type MlQueryResidentAreaListArgs = {
  params?: Maybe<ParamsInput>;
};


/** Стандартный объект */
export type MlQueryUrFaceArgs = {
  id: Scalars['Int'];
};


/** Стандартный объект */
export type MlQueryUrFaceListArgs = {
  params?: Maybe<ParamsInput>;
};

/** Регион РФ */
export type MlRegion = {
  __typename?: 'MlRegion';
  /** aoguid */
  AOGUID?: Maybe<Scalars['String']>;
  /** Родительский субъект */
  District?: Maybe<FederalDistrict>;
  /** id целевой аудитории */
  FIAS_ID?: Maybe<Scalars['String']>;
  /** Наменование региона */
  FORMALNAME?: Maybe<Scalars['String']>;
  /** Список вложенных городов */
  Localities?: Maybe<Array<Maybe<MlCity>>>;
  /** OKATO */
  OKATO?: Maybe<Scalars['Int']>;
  /** Родительский субъект */
  Parent?: Maybe<MlRegion>;
  /** короткое имя */
  SHORTNAME?: Maybe<Scalars['String']>;
};

/** Загрузка данных */
export type MlRegionInput = {
  /** aoguid */
  AOGUID?: Maybe<Scalars['String']>;
  /** Загрузка данных */
  District?: Maybe<FederalDistrictInput>;
  /** id целевой аудитории */
  FIAS_ID?: Maybe<Scalars['String']>;
  /** Наменование региона */
  FORMALNAME?: Maybe<Scalars['String']>;
  /** Загрузка */
  Localities?: Maybe<Array<Maybe<MlCityInput>>>;
  /** OKATO */
  OKATO?: Maybe<Scalars['Int']>;
  /** Загрузка данных */
  Parent?: Maybe<MlRegionInput>;
  /** Загрузка данных */
  Region?: Maybe<MlRegionInput>;
  /** короткое имя */
  SHORTNAME?: Maybe<Scalars['String']>;
  isFull?: Maybe<Scalars['Boolean']>;
};

/** Загрузка данных */
export type MlTacticsInput = {
  /** id названия конструкции */
  id?: Maybe<Scalars['String']>;
  /** Наменование конструкции */
  name?: Maybe<Scalars['String']>;
};

/** Список типов целевой аудитории */
export type MlTargetAudience = {
  __typename?: 'MlTargetAudience';
  /** id целевой аудитории */
  id?: Maybe<Scalars['String']>;
  /** Наменование целевой аудтории */
  name?: Maybe<Scalars['String']>;
};

/** Тематика */
export type MlThematics = {
  __typename?: 'MlThematics';
  /** id тематики */
  id?: Maybe<Scalars['Int']>;
  /** тематика */
  name?: Maybe<Scalars['String']>;
};

/** Загрузка данных */
export type MlThematicsInput = {
  /** id тематики */
  id?: Maybe<Scalars['Int']>;
  /** тематика */
  name?: Maybe<Scalars['String']>;
};

/** МЛ-Юридическое лицо */
export type MlUrFaceItem = {
  __typename?: 'MlUrFaceItem';
  /** id партнера */
  FK_ref_Partner_ID?: Maybe<Scalars['Int']>;
  /** Операционное юр лицо */
  IsOpex?: Maybe<Scalars['Int']>;
  /** Частное лицо или нет? */
  IsPrivatePerson?: Maybe<Scalars['Int']>;
  /** Плательщик НДС? */
  PaysVAT?: Maybe<Scalars['Int']>;
  /** Полное наименование */
  UF_FullName?: Maybe<Scalars['String']>;
  /** наименование */
  UF_Name?: Maybe<Scalars['String']>;
  /** id юрлица */
  UrFace_ID?: Maybe<Scalars['Int']>;
  /** id юрлица */
  id?: Maybe<Scalars['Int']>;
};

/** Объект OOН наружней рекламы   */
export type MnConstructionInput = {
  /** - */
  FIAS_AOGUID?: Maybe<Scalars['String']>;
  /** - */
  GID?: Maybe<Scalars['String']>;
  /** - */
  MountingPrice?: Maybe<Scalars['Float']>;
  /** - */
  MountingPriceAppliesVAT?: Maybe<Scalars['Int']>;
  /** - */
  MountingPriceIncludesVAT?: Maybe<Scalars['Int']>;
  /** - */
  Object_Geo_UID?: Maybe<Scalars['String']>;
  /** - */
  Object_UID?: Maybe<Scalars['String']>;
  /** - */
  RentPrice?: Maybe<Scalars['Float']>;
  /** - */
  RentPriceAppliesVAT?: Maybe<Scalars['Int']>;
  /** - */
  RentPriceIncludesVAT?: Maybe<Scalars['Int']>;
  /** - */
  address?: Maybe<Scalars['String']>;
  /** - */
  cityName?: Maybe<Scalars['String']>;
  /** - */
  constructionTypeId?: Maybe<Scalars['Int']>;
  /** - */
  expositionId?: Maybe<Scalars['Int']>;
  /** - */
  latitude?: Maybe<Scalars['Float']>;
  /** - */
  light?: Maybe<Scalars['Int']>;
  /** - */
  longitude?: Maybe<Scalars['Float']>;
  /** - */
  materials?: Maybe<Array<Maybe<Scalars['Int']>>>;
  /** - */
  mediaNetworkId?: Maybe<Scalars['Int']>;
  /** - */
  sideTypeId?: Maybe<Scalars['Int']>;
  /** - */
  url_photo?: Maybe<Scalars['String']>;
};

/** Медиаплан */
export type MPlan = {
  __typename?: 'MPlan';
  /** ID ?? */
  adtID?: Maybe<Scalars['Int']>;
  /** ?? */
  adtName?: Maybe<Scalars['String']>;
  /** ?? */
  advID?: Maybe<Scalars['Int']>;
  /** ?? */
  agrName?: Maybe<Scalars['Int']>;
  /** ?? */
  allocationType?: Maybe<Scalars['Int']>;
  /** ?? */
  amountFact?: Maybe<Scalars['Int']>;
  /** ?? */
  amountPlan?: Maybe<Scalars['Int']>;
  /** ID договора ?? */
  argID?: Maybe<Scalars['Int']>;
  /** ?? */
  brandID?: Maybe<Scalars['Int']>;
  /** ?? */
  brandName?: Maybe<Scalars['String']>;
  /** ?? */
  commInMplID?: Maybe<Scalars['Int']>;
  /** ?? */
  contractBeg?: Maybe<Scalars['String']>;
  /** ?? */
  contractEnd?: Maybe<Scalars['String']>;
  /** ?? */
  cpPoffprime?: Maybe<Scalars['Int']>;
  /** ?? */
  cpPprime?: Maybe<Scalars['Int']>;
  /** ?? */
  dateFrom?: Maybe<Scalars['String']>;
  /** ?? */
  dateTo?: Maybe<Scalars['String']>;
  /** ?? */
  dealChannelStatus?: Maybe<Scalars['Int']>;
  /** Флаг удаления */
  deleted?: Maybe<Scalars['Int']>;
  /** Discounts of MP */
  discounts?: Maybe<Array<Maybe<VimbDiscount>>>;
  /** ?? */
  doubleAdvertiser?: Maybe<Scalars['String']>;
  /** ?? */
  dtpID?: Maybe<Scalars['Int']>;
  /** ?? */
  dublSpot?: Maybe<Scalars['Int']>;
  /** ?? */
  fbrName?: Maybe<Scalars['String']>;
  /** ?? */
  ffoaAllocated?: Maybe<Scalars['Int']>;
  /** ?? */
  ffoaLawAcc?: Maybe<Scalars['Int']>;
  /** ?? */
  filmDur?: Maybe<Scalars['Int']>;
  /** ?? */
  filmDurKoef?: Maybe<Scalars['Int']>;
  /** ?? */
  filmID?: Maybe<Scalars['Int']>;
  /** ?? */
  filmName?: Maybe<Scalars['String']>;
  /** ?? */
  filmVersion?: Maybe<Scalars['String']>;
  /** ?? */
  fixPriceAsFloat?: Maybe<Scalars['Int']>;
  /** ?? */
  fixPriority?: Maybe<Scalars['Int']>;
  /** ?? */
  grp?: Maybe<Scalars['Int']>;
  /** ?? */
  grpFact?: Maybe<Scalars['Int']>;
  /** ?? */
  grpPlan?: Maybe<Scalars['Int']>;
  /** ?? */
  grpShift?: Maybe<Scalars['Int']>;
  /** ?? */
  grpTotal?: Maybe<Scalars['Int']>;
  /** ?? */
  grpTotalPrime?: Maybe<Scalars['Int']>;
  /** ?? */
  hasReserve?: Maybe<Scalars['Int']>;
  /** Standard duration of film in seconds */
  inventoryUnitDuration?: Maybe<Scalars['Int']>;
  /** ?? */
  mplCbrID?: Maybe<Scalars['Int']>;
  /** ?? */
  mplCbrName?: Maybe<Scalars['String']>;
  /** ?? */
  mplCnlID?: Maybe<Scalars['Int']>;
  /** id - уникальный идентификатор записи */
  mplID?: Maybe<Scalars['Int']>;
  /** ?? */
  mplMonth?: Maybe<Scalars['String']>;
  /** ?? */
  mplName?: Maybe<Scalars['String']>;
  /** ?? */
  mplState?: Maybe<Scalars['String']>;
  /** ?? */
  multiple?: Maybe<Scalars['Int']>;
  /** ?? */
  obdPos?: Maybe<Scalars['Int']>;
  /** ?? */
  ordBegDate?: Maybe<Scalars['String']>;
  /** ?? */
  ordEndDate?: Maybe<Scalars['String']>;
  /** ?? */
  ordFrID?: Maybe<Scalars['Int']>;
  /** ?? */
  ordID?: Maybe<Scalars['Int']>;
  /** ?? */
  ordIsTriggered?: Maybe<Scalars['Int']>;
  /** ?? */
  ordManager?: Maybe<Scalars['String']>;
  /** ?? */
  ordName?: Maybe<Scalars['String']>;
  /** ?? */
  pbaCond?: Maybe<Scalars['String']>;
  /** ?? */
  pbaObjID?: Maybe<Scalars['Int']>;
  /** ?? */
  prodClassID?: Maybe<Scalars['Int']>;
  /** Список рекламных блоков программы */
  programBreaks?: Maybe<Array<Maybe<ProgramBreak>>>;
  /** ?? */
  splitMessageGroupID?: Maybe<Scalars['Int']>;
  /** Список слотов */
  spots?: Maybe<Array<Maybe<Spot>>>;
  /** ?? */
  sumShift?: Maybe<Scalars['Int']>;
  /** ?? */
  tgrID?: Maybe<Scalars['Int']>;
  /** ?? */
  tgrName?: Maybe<Scalars['String']>;
  /** ?? */
  tpName?: Maybe<Scalars['String']>;
};

/** Элемент отчета МП */
export type MPlanItemReport = {
  __typename?: 'MPlanItemReport';
  begDate?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  inventoryName?: Maybe<Scalars['String']>;
  inventoryType?: Maybe<Scalars['String']>;
  mp_id?: Maybe<Scalars['Int']>;
  platformName?: Maybe<Scalars['String']>;
  rCPV?: Maybe<Scalars['Float']>;
  rCTR?: Maybe<Scalars['Float']>;
  rClicks?: Maybe<Scalars['Int']>;
  rConversion?: Maybe<Scalars['Float']>;
  rCount?: Maybe<Scalars['Int']>;
  rImpressions?: Maybe<Scalars['Int']>;
  rLeadImpressions?: Maybe<Scalars['Int']>;
  rPlan?: Maybe<Scalars['Float']>;
  rRejects?: Maybe<Scalars['Float']>;
  rSpend?: Maybe<Scalars['Float']>;
  rTime?: Maybe<Scalars['String']>;
  rTotalCost?: Maybe<Scalars['Float']>;
  rViewDepth?: Maybe<Scalars['Float']>;
  rViews?: Maybe<Scalars['Int']>;
  rVisits?: Maybe<Scalars['Int']>;
  reportDate?: Maybe<Scalars['String']>;
  responsible?: Maybe<Scalars['String']>;
};

/** Стандартный объект */
export type Mutation = {
  __typename?: 'Mutation';
  /** Добавление брифа, в качестве аргумента - данные с полями, возврат - id добавленного брифа */
  DigitalBriefingContextCreate?: Maybe<DigitalBriefingContext>;
  /**
   * Удаление брифа, аргумент - id удаляемого элемента, возврат - id удаленного элемента
   * @deprecated В будущем мы не должны удалять брифы
   */
  DigitalBriefingContextRemove?: Maybe<DigitalBriefingContext>;
  /** Обновление данных брифа, в качестве аргумента - данные с полями, возврат - id обновленного брифа */
  DigitalBriefingContextUpdate?: Maybe<DigitalBriefingContext>;
  /** Добавление группы, возвращаем id */
  GroupCreate?: Maybe<GroupItem>;
  /**
   * Удаление группы, аргумент - id медиаплана, возврат - id удаленного медиаплана
   * @deprecated В принципе, такого функционала не должно быть вообще
   */
  GroupRemove?: Maybe<GroupItem>;
  /** Обновление данных группы, аргументы: id строчки, имя поля и значение, возврат - id обновленной группы */
  GroupUpdate?: Maybe<GroupItem>;
  /** Обновление данных группы, аргументы: id строчки, имя доступа и значение, возврат - id обновленной группы */
  GroupUpdateAccess?: Maybe<GroupItem>;
  /** Стандартный объект */
  ML?: Maybe<MlMutation>;
  /** Стандартный объект */
  MediaNetworks?: Maybe<MediaNetworksMutation>;
  /** Стандартный объект */
  OOH?: Maybe<OohMutation>;
  /** Загрука файлов */
  OohUploadFile?: Maybe<File>;
  /** Стандартный объект */
  Rn?: Maybe<RnMutation>;
  /** копирование брифа */
  RnBriefingClone?: Maybe<RnBriefing>;
  /** Создание брифа, аргумент - поля с данными, возврат - id добавленного брифа */
  RnBriefingCreate?: Maybe<RnBriefing>;
  /** Создание брифа, аргумент - поля с данными, возврат - id добавленного брифа */
  RnBriefingCreateFast?: Maybe<Scalars['Int']>;
  /**
   * Удаление брифа, аргумент - id удаляемого брифа, возврат - id удаленного брифа
   * @deprecated В будущем мы не должны удалять брифы
   */
  RnBriefingRemove?: Maybe<RnBriefing>;
  /** Обновление брифа, аргумент - поля с данными, возврат - id обновленного брифа */
  RnBriefingUpdate?: Maybe<RnBriefing>;
  /** Обновление брифа, аргумент - поля с данными, возврат - id обновленного брифа */
  RnBriefingUpdateField?: Maybe<RnBriefing>;
  /** Клонирование элемента, аргумент - id клонирумого элемента, возврат - id клонированного элемента */
  RnInventoryClone?: Maybe<RnInventoryItem>;
  /** Добавление инветаря, аргументы - поля с данными, возврат - id добавленного элемента */
  RnInventoryCreate?: Maybe<RnInventoryItem>;
  /** Добавление страницы сайта инветаря, в качестве аргумента имя странички, возврат - id названия страницы */
  RnInventoryPageCreate?: Maybe<RnInventoryPage>;
  /** Удаление элемента инвентаря, аргумент - id удаляемого элемента инвентаря, возврат - id удаленного инвентаря */
  RnInventoryRemove?: Maybe<RnInventoryItem>;
  /** Добавление таргетинга внутри инветаря, в качестве аргумента имя таргетинга, возврат - id названия таргетинга */
  RnInventoryTargetingCreate?: Maybe<RnInventoryTargeting>;
  /** Обновление данных брифа, аргументы - поля с данными, возврат - id обновленного элемента */
  RnInventoryUpdate?: Maybe<RnInventoryItem>;
  /** Обновление данных брифа, аргументы - поля с данными, возврат - id обновленного элемента */
  RnInventoryUpdateField?: Maybe<RnInventoryItem>;
  /** Выгрузка данных в Аксесс, аргумент - id медиаплана, возврат - id заказа */
  RnMpAccessExport?: Maybe<Scalars['Int']>;
  /** Копирование медиаплана */
  RnMpCopy?: Maybe<RnMp>;
  /** Добавление МП (создается сразу со одной строчкой), аргумент - данные пользователя, возврат - id плана */
  RnMpCreate?: Maybe<RnMp>;
  /** Добавление строчки медиаплана, аргумент - id медиаплана, возврат - id добавленной строчки */
  RnMpItemCreate?: Maybe<RnMpItem>;
  /** Удаление строчки медиаплана, аргумент - id строчки, возврат - id удаленной строчки */
  RnMpItemRemove?: Maybe<RnMpItem>;
  /** Обновление данных строчки медиаплана, аргументы: id строчки, имя поля и значение, возврат - id обновленной строки */
  RnMpItemUpdateField?: Maybe<RnMpItem>;
  /**
   * Обновление данных строчки медиаплана (текстовое поле), аргументы: id строчки,
   * имя поля и значение, возврат - id обновленного строчки плана
   */
  RnMpItemUpdateFieldText?: Maybe<RnMpItem>;
  /** список id таргетингов */
  RnMpItemUpdateTargetingList?: Maybe<RnMpItem>;
  /**
   * Удаление медиаплана, аргумент - id медиаплана, возврат - id удаленного медиаплана
   * @deprecated В принципе, такого функционала не должно быть вообще
   */
  RnMpRemove?: Maybe<RnMp>;
  /** Отправка письма, аргумент - email получателя, ответ - ответ библиотеки */
  RnMpSendEmail?: Maybe<Scalars['String']>;
  /** Обновление данных самого МЕДИАПЛАНА, аргументы: id строчки, имя поля и значение, возврат - id обновленного плана */
  RnMpUpdateField?: Maybe<RnMp>;
  /** Обновление данных самого МЕДИАПЛАНА, аргументы: id строчки, имя поля и значение, возврат - id обновленного плана */
  RnMpUpdateFieldString?: Maybe<RnMp>;
  /** Добавление площадки, аргументы - данные полей, возврат - id добавленной площадки */
  RnPlatformContactCreate?: Maybe<RnContacts>;
  /**
   * Удаление площадки, аргумент - id площадки, возврат - id удаленной площадки
   * @deprecated В будущем мы не должны удалять брифы
   */
  RnPlatformContactRemove?: Maybe<RnContacts>;
  /** Обновление данных брифа, аргументы - данные по полям (всем), возврат - id обновленной площадки */
  RnPlatformContactUpdate?: Maybe<RnContacts>;
  /** Добавление площадки, аргументы - данные полей, возврат - id добавленной площадки */
  RnPlatformCreate?: Maybe<RnPlatform>;
  /**
   * Удаление площадки, аргумент - id площадки, возврат - id удаленной площадки
   * @deprecated В будущем мы не должны удалять брифы
   */
  RnPlatformRemove?: Maybe<RnPlatform>;
  /** Обновление данных брифа, аргументы - данные по полям (всем), возврат - id обновленной площадки */
  RnPlatformUpdate?: Maybe<RnPlatform>;
  /** Обновление данных самого МЕДИАПЛАНА, аргументы: id строчки, имя поля и значение, возврат - id обновленного плана */
  RnPlatformUpdateField?: Maybe<RnPlatform>;
  /** Обновление данных самого МЕДИАПЛАНА, аргументы: id строчки, имя поля и значение, возврат - id обновленного плана */
  RnPlatformUpdateFieldString?: Maybe<RnPlatform>;
  /**
   * Добавление элемента прайса, аргумент - данные с полями, возврат - id добавленного элемента
   * @deprecated Прайс редактируется в составе платформы
   */
  RnPriceCreate?: Maybe<RnPrice>;
  /**
   * Удаление прайса, аргумент - id удаляемого элемента, возврат - id удаленного элемента
   * @deprecated Прайс редактируется в составе платформы
   */
  RnPriceRemove?: Maybe<RnPrice>;
  /**
   * Обновление данных элемента прайса, аргументы - поля с данными, возврат - id обновленного элемента
   * @deprecated Прайс редактируется в составе платформы
   */
  RnPriceUpdate?: Maybe<RnPrice>;
  /** Загрука файлов */
  RnUploadFile?: Maybe<File>;
  /** проверка загрузки файлов */
  TestUpload?: Maybe<Scalars['String']>;
  TopBuyerUpdateEmail?: Maybe<UserProfile>;
  /** Загрука файлов */
  UploadFile?: Maybe<File>;
  /** Стандартный объект */
  Vimb?: Maybe<VimbMutation>;
  hello?: Maybe<Scalars['String']>;
};


/** Стандартный объект */
export type MutationDigitalBriefingContextCreateArgs = {
  briefing?: Maybe<DigitalBriefingContextInput>;
};


/** Стандартный объект */
export type MutationDigitalBriefingContextRemoveArgs = {
  id?: Maybe<Scalars['Int']>;
};


/** Стандартный объект */
export type MutationDigitalBriefingContextUpdateArgs = {
  briefing?: Maybe<DigitalBriefingContextInput>;
};


/** Стандартный объект */
export type MutationGroupRemoveArgs = {
  id?: Maybe<Scalars['Int']>;
};


/** Стандартный объект */
export type MutationGroupUpdateArgs = {
  field?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Int']>;
  value?: Maybe<Scalars['String']>;
};


/** Стандартный объект */
export type MutationGroupUpdateAccessArgs = {
  access?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  value?: Maybe<Scalars['Int']>;
};


/** Стандартный объект */
export type MutationOohUploadFileArgs = {
  file?: Maybe<Scalars['Upload']>;
  id?: Maybe<Scalars['Int']>;
  type: UploadFilePath;
};


/** Стандартный объект */
export type MutationRnBriefingCloneArgs = {
  id?: Maybe<Scalars['Int']>;
};


/** Стандартный объект */
export type MutationRnBriefingCreateArgs = {
  briefing?: Maybe<RnBriefingInput>;
};


/** Стандартный объект */
export type MutationRnBriefingCreateFastArgs = {
  mp_id?: Maybe<Scalars['Int']>;
};


/** Стандартный объект */
export type MutationRnBriefingRemoveArgs = {
  id?: Maybe<Scalars['Int']>;
};


/** Стандартный объект */
export type MutationRnBriefingUpdateArgs = {
  briefing?: Maybe<RnBriefingInput>;
};


/** Стандартный объект */
export type MutationRnBriefingUpdateFieldArgs = {
  field?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  value?: Maybe<Scalars['String']>;
};


/** Стандартный объект */
export type MutationRnInventoryCloneArgs = {
  author_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};


/** Стандартный объект */
export type MutationRnInventoryCreateArgs = {
  inventory?: Maybe<RnInventoryItemInput>;
};


/** Стандартный объект */
export type MutationRnInventoryPageCreateArgs = {
  page?: Maybe<RnInventoryPagesInput>;
};


/** Стандартный объект */
export type MutationRnInventoryRemoveArgs = {
  author_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};


/** Стандартный объект */
export type MutationRnInventoryTargetingCreateArgs = {
  targeting?: Maybe<RnInventoryTargetingInput>;
};


/** Стандартный объект */
export type MutationRnInventoryUpdateArgs = {
  inventory?: Maybe<RnInventoryItemInput>;
};


/** Стандартный объект */
export type MutationRnInventoryUpdateFieldArgs = {
  author_id?: Maybe<Scalars['Int']>;
  field?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  value?: Maybe<Scalars['Int']>;
};


/** Стандартный объект */
export type MutationRnMpAccessExportArgs = {
  id?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['Int']>;
};


/** Стандартный объект */
export type MutationRnMpCopyArgs = {
  briefing_id?: Maybe<Scalars['Int']>;
  date?: Maybe<Scalars['String']>;
  mp_id?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['Int']>;
};


/** Стандартный объект */
export type MutationRnMpCreateArgs = {
  briefing_id?: Maybe<Scalars['Int']>;
  date?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['Int']>;
};


/** Стандартный объект */
export type MutationRnMpItemCreateArgs = {
  mpId?: Maybe<Scalars['Int']>;
};


/** Стандартный объект */
export type MutationRnMpItemRemoveArgs = {
  id?: Maybe<Scalars['Int']>;
};


/** Стандартный объект */
export type MutationRnMpItemUpdateFieldArgs = {
  field?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  value?: Maybe<Scalars['Float']>;
};


/** Стандартный объект */
export type MutationRnMpItemUpdateFieldTextArgs = {
  field?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  value?: Maybe<Scalars['String']>;
};


/** Стандартный объект */
export type MutationRnMpItemUpdateTargetingListArgs = {
  field?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  value?: Maybe<Array<Maybe<Scalars['Int']>>>;
};


/** Стандартный объект */
export type MutationRnMpRemoveArgs = {
  id?: Maybe<Scalars['Int']>;
};


/** Стандартный объект */
export type MutationRnMpSendEmailArgs = {
  copyRecipients?: Maybe<Array<Maybe<Scalars['String']>>>;
  message?: Maybe<Scalars['String']>;
  recipient?: Maybe<Scalars['String']>;
  sender?: Maybe<Scalars['String']>;
  subject?: Maybe<Scalars['String']>;
};


/** Стандартный объект */
export type MutationRnMpUpdateFieldArgs = {
  field?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  value?: Maybe<Scalars['Float']>;
};


/** Стандартный объект */
export type MutationRnMpUpdateFieldStringArgs = {
  field?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  value?: Maybe<Scalars['String']>;
};


/** Стандартный объект */
export type MutationRnPlatformContactCreateArgs = {
  contact?: Maybe<RnContactsInput>;
};


/** Стандартный объект */
export type MutationRnPlatformContactRemoveArgs = {
  id?: Maybe<Scalars['Int']>;
};


/** Стандартный объект */
export type MutationRnPlatformContactUpdateArgs = {
  contact?: Maybe<RnContactsInput>;
};


/** Стандартный объект */
export type MutationRnPlatformCreateArgs = {
  platform?: Maybe<RnPlatformInput>;
};


/** Стандартный объект */
export type MutationRnPlatformRemoveArgs = {
  author_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};


/** Стандартный объект */
export type MutationRnPlatformUpdateArgs = {
  platform?: Maybe<RnPlatformInput>;
};


/** Стандартный объект */
export type MutationRnPlatformUpdateFieldArgs = {
  field?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  value?: Maybe<Scalars['Float']>;
};


/** Стандартный объект */
export type MutationRnPlatformUpdateFieldStringArgs = {
  field?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  value?: Maybe<Scalars['String']>;
};


/** Стандартный объект */
export type MutationRnPriceCreateArgs = {
  price?: Maybe<RnPriceInput>;
};


/** Стандартный объект */
export type MutationRnPriceRemoveArgs = {
  id?: Maybe<Scalars['Int']>;
};


/** Стандартный объект */
export type MutationRnPriceUpdateArgs = {
  price?: Maybe<RnPriceInput>;
};


/** Стандартный объект */
export type MutationRnUploadFileArgs = {
  file?: Maybe<Scalars['Upload']>;
  id?: Maybe<Scalars['Int']>;
  type: UploadFilePath;
};


/** Стандартный объект */
export type MutationTestUploadArgs = {
  file?: Maybe<Scalars['Upload']>;
  info?: Maybe<Array<Maybe<Scalars['String']>>>;
};


/** Стандартный объект */
export type MutationTopBuyerUpdateEmailArgs = {
  email?: Maybe<Scalars['String']>;
};


/** Стандартный объект */
export type MutationUploadFileArgs = {
  file?: Maybe<Scalars['Upload']>;
  id?: Maybe<Scalars['Int']>;
  type: UploadFilePath;
};

/** АП OOH */
export type OohAp = {
  __typename?: 'OohAP';
  /** Бриф */
  Briefing?: Maybe<OohBriefing>;
  /** Город РФ */
  Cities?: Maybe<Array<Maybe<OohapCity>>>;
  /** Город РФ */
  ClientLinkHash?: Maybe<Scalars['String']>;
  /** список номеров заказов */
  Orders?: Maybe<Array<Maybe<Scalars['Int']>>>;
  /** Периоды */
  Periods?: Maybe<Array<Maybe<OohPeriod>>>;
  /** АВ внутри которое снаружи */
  agent_commission_force_in?: Maybe<Scalars['Int']>;
  /** Id брифа */
  briefing_id?: Maybe<Scalars['Int']>;
  /** Дата создания */
  entryDate?: Maybe<Scalars['DateTime']>;
  /** id - уникальный идентификатор записи */
  id?: Maybe<Scalars['Int']>;
  /** Дата последнего изменения */
  lastUpdate?: Maybe<Scalars['DateTime']>;
  /** НАзвание адресной программы */
  name?: Maybe<Scalars['String']>;
  /** Статус */
  status?: Maybe<Scalars['Int']>;
  /** Комментарий к статусу */
  status_comment?: Maybe<Scalars['String']>;
};

/** Город Из АП */
export type OohapCity = {
  __typename?: 'OOHAPCity';
  /** - */
  AOGUID?: Maybe<Scalars['String']>;
  /** - */
  DefaultFilters?: Maybe<CityFilter>;
  /** - */
  FIAS_ID?: Maybe<Scalars['String']>;
  /** - */
  FORMALNAME?: Maybe<Scalars['String']>;
  /** - */
  ap_id?: Maybe<Scalars['Int']>;
  /** - */
  fias_aoguid?: Maybe<Scalars['String']>;
  /** - */
  id?: Maybe<Scalars['String']>;
  /** - */
  status?: Maybe<Scalars['Int']>;
};

/** Город Из АП */
export type OohapCityInfo = {
  __typename?: 'OOHAPCityInfo';
  /** - */
  AVG?: Maybe<Scalars['Float']>;
  /** - */
  GrossBuy?: Maybe<Scalars['Float']>;
  /** - */
  GrossSale?: Maybe<Scalars['Float']>;
  /** - */
  count?: Maybe<Scalars['Int']>;
  /** - */
  id?: Maybe<Scalars['String']>;
  /** - */
  margin?: Maybe<Scalars['Float']>;
  /** - */
  profitability?: Maybe<Scalars['Float']>;
};

/** CityInput */
export type OohapCityInput = {
  /** - */
  AOGUID?: Maybe<Scalars['String']>;
  /** - */
  FIAS_ID?: Maybe<Scalars['String']>;
  /** - */
  ap_id?: Maybe<Scalars['Int']>;
  /** - */
  fias_aoguid?: Maybe<Scalars['String']>;
  /** - */
  id?: Maybe<Scalars['String']>;
  /** - */
  status?: Maybe<Scalars['Int']>;
};

/** Объекты клиента */
export type OohapClientObject = {
  __typename?: 'OOHAPClientObject';
  /** - */
  FK_ref_FIAS_AOGUID?: Maybe<Scalars['String']>;
  /** - */
  address?: Maybe<Scalars['String']>;
  /** - */
  ap_id?: Maybe<Scalars['Int']>;
  /** - */
  cityName?: Maybe<Scalars['String']>;
  /** - */
  color?: Maybe<Scalars['String']>;
  /** - */
  id?: Maybe<Scalars['Int']>;
  /** - */
  latitude?: Maybe<Scalars['Float']>;
  /** - */
  longitude?: Maybe<Scalars['Float']>;
  /** - */
  title?: Maybe<Scalars['String']>;
};

/** Объекты клиента */
export type OohapClientObjectInput = {
  /** - */
  FK_ref_FIAS_AOGUID?: Maybe<Scalars['String']>;
  /** - */
  address?: Maybe<Scalars['String']>;
  /** - */
  ap_id?: Maybe<Scalars['Int']>;
  cityName?: Maybe<Scalars['String']>;
  /** - */
  color?: Maybe<Scalars['String']>;
  /** - */
  id?: Maybe<Scalars['Int']>;
  /** - */
  latitude?: Maybe<Scalars['Float']>;
  /** - */
  longitude?: Maybe<Scalars['Float']>;
  /** - */
  title?: Maybe<Scalars['String']>;
};

/** История закупок конструции */
export type OohapConstructionHistory = {
  __typename?: 'OOHAPConstructionHistory';
  /** Размеры конструкции */
  brand?: Maybe<Scalars['String']>;
  /** Название типа конструкции */
  client?: Maybe<Scalars['String']>;
  /** Наличие освещения  */
  date?: Maybe<Scalars['String']>;
  /** ID */
  id?: Maybe<Scalars['String']>;
  /** ID */
  object_id?: Maybe<Scalars['String']>;
  /** Наличие освещения  */
  order_id?: Maybe<Scalars['Int']>;
  /** ID типа конструкции */
  priceGrossBuy?: Maybe<Scalars['Float']>;
  /** Название типа конструкции */
  priceGrossSale?: Maybe<Scalars['Float']>;
};

/** Ценообразование digital */
export type OohapDigitalPricingBlock = {
  __typename?: 'OOHAPDigitalPricingBlock';
  /** - */
  ap_id?: Maybe<Scalars['Int']>;
  /** - */
  block_time?: Maybe<Scalars['String']>;
  /** - */
  coefficient_length?: Maybe<Scalars['Float']>;
  /** - */
  coefficient_season?: Maybe<Scalars['Float']>;
  /** - */
  days?: Maybe<Array<Maybe<OohapDigitalPricingBlockDay>>>;
  /** - */
  discount_ra?: Maybe<Scalars['Float']>;
  /** - */
  id?: Maybe<Scalars['String']>;
  /** - */
  number?: Maybe<Scalars['Int']>;
  /** - */
  object_id?: Maybe<Scalars['String']>;
  /** - */
  output_count?: Maybe<Scalars['String']>;
  /** - */
  period?: Maybe<Scalars['String']>;
  /** - */
  placement_type?: Maybe<Scalars['String']>;
  /** - */
  price?: Maybe<Scalars['Float']>;
  /** - */
  price_type?: Maybe<Scalars['Int']>;
  /** - */
  work_time?: Maybe<Scalars['String']>;
  /** - */
  work_time_min?: Maybe<Scalars['Float']>;
};

/** Ценообразование digital */
export type OohapDigitalPricingBlockDay = {
  __typename?: 'OOHAPDigitalPricingBlockDay';
  /** - */
  ap_id?: Maybe<Scalars['Int']>;
  /** - */
  date?: Maybe<Scalars['Date']>;
  /** - */
  id?: Maybe<Scalars['String']>;
  /** - */
  number?: Maybe<Scalars['Int']>;
  /** - */
  object_id?: Maybe<Scalars['String']>;
  /** - */
  value?: Maybe<Scalars['String']>;
};

/** Ценообразование digital */
export type OohapDigitalPricingBlockDayInput = {
  /** - */
  ap_id?: Maybe<Scalars['Int']>;
  /** - */
  date?: Maybe<Scalars['Date']>;
  /** - */
  number?: Maybe<Scalars['Int']>;
  /** - */
  object_id?: Maybe<Scalars['String']>;
  /** - */
  value?: Maybe<Scalars['String']>;
};

/** Ценообразование digital */
export type OohapDigitalPricingBlockInput = {
  /** - */
  ap_id?: Maybe<Scalars['Int']>;
  /** - */
  block_time?: Maybe<Scalars['String']>;
  /** - */
  coefficient_length?: Maybe<Scalars['Float']>;
  /** - */
  coefficient_season?: Maybe<Scalars['Float']>;
  /** - */
  discount_ra?: Maybe<Scalars['Float']>;
  /** - */
  number?: Maybe<Scalars['Int']>;
  /** - */
  object_id?: Maybe<Scalars['String']>;
  /** - */
  output_count?: Maybe<Scalars['String']>;
  /** - */
  period?: Maybe<Scalars['String']>;
  /** - */
  placement_type?: Maybe<Scalars['String']>;
  /** - */
  price?: Maybe<Scalars['Float']>;
  /** - */
  price_type?: Maybe<Scalars['Int']>;
  /** - */
  work_time?: Maybe<Scalars['String']>;
  /** - */
  work_time_min?: Maybe<Scalars['Float']>;
};

/** Лог элемент письма поставщику */
export type OohapEmailLogItem = {
  __typename?: 'OOHAPEmailLogItem';
  /** - */
  Author?: Maybe<RefEmployee>;
  /** - */
  Fias?: Maybe<MlCity>;
  /** - */
  Person?: Maybe<MlPersonItem>;
  /** - */
  ap_id?: Maybe<Scalars['Int']>;
  /** - */
  author_id?: Maybe<Scalars['Int']>;
  /** - */
  dateSended?: Maybe<Scalars['DateTime']>;
  /** - */
  email?: Maybe<Scalars['String']>;
  /** - */
  fias_aoguid?: Maybe<Scalars['ListOfString']>;
  /** - */
  id?: Maybe<Scalars['Int']>;
  /** - */
  mediaNetwork?: Maybe<MlMediaNetwork>;
  /** - */
  medianetwork_id?: Maybe<Scalars['Int']>;
  /** - */
  person_id?: Maybe<Scalars['Int']>;
  /** - */
  subject?: Maybe<Scalars['String']>;
};

/** Объект для массового преобразования цен */
export type OohapMassPriceInput = {
  ap_id?: Maybe<Scalars['Int']>;
  constrTypeId?: Maybe<Scalars['Int']>;
  discount?: Maybe<Scalars['Float']>;
  fias_aoguid?: Maybe<Scalars['String']>;
  mediaNetworkId?: Maybe<Scalars['Int']>;
  priceNetBuy?: Maybe<Scalars['Int']>;
  priceNetSale?: Maybe<Scalars['Float']>;
  profitability?: Maybe<Scalars['Float']>;
};

/** поставщики OOH */
export type OohapMediaNetwork = {
  __typename?: 'OOHAPMediaNetwork';
  /** Количество конструций */
  ConstructionsCount?: Maybe<Scalars['Int']>;
  /** Медиа тип. */
  FK_ref_MediaType_ID?: Maybe<Scalars['Int']>;
  /** Наменование конструкции */
  MediaNetworkName?: Maybe<Scalars['String']>;
  /** id названия конструкции */
  MediaNetwork_ID?: Maybe<Scalars['Int']>;
  /** Список партнёров */
  Partner?: Maybe<MlPartner>;
  /** id названия конструкции */
  Partner_ID?: Maybe<Scalars['Int']>;
  /** Список персон */
  Persons?: Maybe<Array<Maybe<MlPersonItem>>>;
  /** состояние НДС */
  PricingFormationInfo?: Maybe<Array<Maybe<OohapMediaNetworkPricingFormationInfo>>>;
  /** d */
  ap_id?: Maybe<Scalars['Int']>;
  /** d */
  fias_aoguid?: Maybe<Scalars['String']>;
  /** список названий городов */
  fias_aoguid_names?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** список id городов */
  fias_aoguids?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** id названия конструкции */
  id?: Maybe<Scalars['Int']>;
  /** состояние НДС */
  vat188?: Maybe<Scalars['Boolean']>;
  /** состояние НДС */
  vat189?: Maybe<Scalars['Boolean']>;
  /** состояние НДС */
  vat190?: Maybe<Scalars['Boolean']>;
};

/** иформация о формировании цены для медиа сети */
export type OohapMediaNetworkPricingFormationInfo = {
  __typename?: 'OOHAPMediaNetworkPricingFormationInfo';
  /** Тип конструкции */
  ConstrType?: Maybe<MlConstructionType>;
  /** средний чек */
  avgPrice?: Maybe<Scalars['Float']>;
  /** средний чек закуп */
  avgRealPrice?: Maybe<Scalars['Float']>;
  /** тип конструции */
  constrTypeId?: Maybe<Scalars['Int']>;
  /** Город */
  fias_aoguid?: Maybe<Scalars['String']>;
  /** состояние НДС */
  id?: Maybe<Scalars['String']>;
  /** тип конструции */
  mediaNetworkId?: Maybe<Scalars['Int']>;
};

/** Много много функций адрески */
export type OohApMutation = {
  __typename?: 'OohApMutation';
  /** АВ внутри которе снаружи флаг */
  APABForceIN?: Maybe<OohAp>;
  /** Добавление объекта в корзину */
  AddObject?: Maybe<BasketConstruction>;
  /** Удалить объект клиента */
  ApCityClientObjectRemove?: Maybe<Scalars['Boolean']>;
  /** Удалить объект клиента */
  ApCityClientObjectUpdate?: Maybe<OohapClientObject>;
  /** Изменение юр данных у всех объектов поставщика */
  ChangeMediaNetworkUrInfo?: Maybe<Array<Maybe<BasketConstructionUrInfo>>>;
  /** Меняет юр лицо и ндс по списку возвращает обновлённые данные. */
  ChangeObjectUrInfo?: Maybe<Array<Maybe<BasketConstructionUrInfo>>>;
  /** АП OOH */
  DiscardAP?: Maybe<OohAp>;
  /** Добавление объекта в корзину */
  ForwardRentField?: Maybe<Array<Maybe<BasketConstructionPeriod>>>;
  /** Поставить отметку об ошибке в информации об объекте */
  MarkObjectInfoError?: Maybe<OohConstruction>;
  /** Поставить отметку об исправлении ошибки  */
  MarkObjectInfoErrorFixed?: Maybe<Scalars['Boolean']>;
  /** Добавление объекта в корзину */
  MassPriceInput?: Maybe<Array<Maybe<BasketConstructionPeriod>>>;
  /** Отправляет письма поставщикам по полученному массиву */
  SendPriceRequestEmails?: Maybe<Scalars['Boolean']>;
  /** Отправляет письма на резерв медиасетям  */
  SendReservEmails?: Maybe<Array<Maybe<OohapReservEmailInfo>>>;
  /** Объект OOН наружней рекламы который в корзине */
  SetAllObjectsApprove?: Maybe<Array<Maybe<BasketConstruction>>>;
  /** Город Из АП */
  UpdateAPCity?: Maybe<OohapCity>;
  /** Информация по договрам и юр лицам. */
  UpdateAPUrInfo?: Maybe<OohapUrInfo>;
  /** Добавление объекта в корзину */
  UpdateObjectItem?: Maybe<BasketConstructionPeriod>;
  /** Добавление объекта в корзину */
  UpdateObjectItemAllPrices?: Maybe<BasketConstructionPeriod>;
  /** Сохраняет шаблон письма для запроса прайса */
  UpdatePriceEmail?: Maybe<Scalars['Boolean']>;
  /** Меняет юр лицо и ндс по списку возвращает обновлённые данные. */
  UpdateReservEmail?: Maybe<Array<Maybe<OohapReservEmailInfo>>>;
  /** Создание АП из брифа */
  createApFromBriefing?: Maybe<OohAp>;
  /** Boolean */
  digitalInfoClearPeriod?: Maybe<Scalars['Boolean']>;
  /** Объект OOН наружней рекламы который в корзине */
  digitalInfoDayUpdate?: Maybe<OohapDigitalPricingBlockDay>;
  /** Ценообразование digital */
  digitalInfoUpdate?: Maybe<OohapDigitalPricingBlock>;
  /** Отправка в АСС */
  exportACCFias?: Maybe<Array<Maybe<OohapUrInfo>>>;
  /** Объект OOН наружней рекламы который в корзине */
  importConstructionFromFile?: Maybe<Scalars['Boolean']>;
  /** Список изменнённых объектов в корзине */
  setCityReserved?: Maybe<Array<Maybe<BasketConstruction>>>;
  /** Boolean */
  specialPriceToNetBuy?: Maybe<Array<Maybe<BasketConstructionPeriod>>>;
  /** Пользовательские настройки АП */
  updateAPUserConfigValue?: Maybe<UserConfig>;
  /** Обновляет данные обекта по сути сейчас это только статус. */
  updateObject?: Maybe<BasketConstruction>;
  /** Изменение материалов Объекта корзины */
  updateObjectMaterialList?: Maybe<Array<Maybe<BasketConstructionMaterial>>>;
};


/** Много много функций адрески */
export type OohApMutationApabForceInArgs = {
  ap_id?: Maybe<Scalars['Int']>;
  value?: Maybe<Scalars['Boolean']>;
};


/** Много много функций адрески */
export type OohApMutationAddObjectArgs = {
  Object_UID: Scalars['String'];
  ap_id: Scalars['Int'];
};


/** Много много функций адрески */
export type OohApMutationApCityClientObjectRemoveArgs = {
  id?: Maybe<Scalars['Int']>;
};


/** Много много функций адрески */
export type OohApMutationApCityClientObjectUpdateArgs = {
  data?: Maybe<OohapClientObjectInput>;
};


/** Много много функций адрески */
export type OohApMutationChangeMediaNetworkUrInfoArgs = {
  data?: Maybe<Array<Maybe<MediaNetworkUrInfoInput>>>;
};


/** Много много функций адрески */
export type OohApMutationChangeObjectUrInfoArgs = {
  data?: Maybe<Array<Maybe<BasketConstructionUrInfoInput>>>;
};


/** Много много функций адрески */
export type OohApMutationDiscardApArgs = {
  ap_id?: Maybe<Scalars['Int']>;
  text?: Maybe<Scalars['String']>;
};


/** Много много функций адрески */
export type OohApMutationForwardRentFieldArgs = {
  ap_id: Scalars['Int'];
  field: Scalars['String'];
  object_id: Scalars['String'];
  period: Scalars['DateTime'];
};


/** Много много функций адрески */
export type OohApMutationMarkObjectInfoErrorArgs = {
  ap_id?: Maybe<Scalars['Int']>;
  errorType?: Maybe<Scalars['Int']>;
  object_id?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
};


/** Много много функций адрески */
export type OohApMutationMarkObjectInfoErrorFixedArgs = {
  error_id?: Maybe<Scalars['Int']>;
};


/** Много много функций адрески */
export type OohApMutationMassPriceInputArgs = {
  data?: Maybe<Array<Maybe<OohapMassPriceInput>>>;
};


/** Много много функций адрески */
export type OohApMutationSendPriceRequestEmailsArgs = {
  selectedEmails?: Maybe<Array<Maybe<InputMediaNetworkAndPersonSendPriceRequestEmails>>>;
};


/** Много много функций адрески */
export type OohApMutationSendReservEmailsArgs = {
  ap_id?: Maybe<Scalars['Int']>;
  fias_aoguid?: Maybe<Scalars['String']>;
  medianetworks?: Maybe<Array<Maybe<Scalars['Int']>>>;
};


/** Много много функций адрески */
export type OohApMutationSetAllObjectsApproveArgs = {
  ap_id?: Maybe<Scalars['Int']>;
  fias_aoguid?: Maybe<Scalars['String']>;
};


/** Много много функций адрески */
export type OohApMutationUpdateApCityArgs = {
  data?: Maybe<OohapCityInput>;
};


/** Много много функций адрески */
export type OohApMutationUpdateApUrInfoArgs = {
  data?: Maybe<OohapUrInfoInput>;
};


/** Много много функций адрески */
export type OohApMutationUpdateObjectItemArgs = {
  data: BasketConstructionPeriodInput;
};


/** Много много функций адрески */
export type OohApMutationUpdateObjectItemAllPricesArgs = {
  data: BasketConstructionPeriodInput;
};


/** Много много функций адрески */
export type OohApMutationUpdatePriceEmailArgs = {
  data?: Maybe<Array<Maybe<OohapPriceEmailInput>>>;
};


/** Много много функций адрески */
export type OohApMutationUpdateReservEmailArgs = {
  allInAP?: Maybe<Scalars['Boolean']>;
  data: OohapReservEmailInfoInput;
};


/** Много много функций адрески */
export type OohApMutationCreateApFromBriefingArgs = {
  briefing_id: Scalars['Int'];
};


/** Много много функций адрески */
export type OohApMutationDigitalInfoClearPeriodArgs = {
  ap_id?: Maybe<Scalars['Int']>;
  end?: Maybe<Scalars['Date']>;
  object_id?: Maybe<Scalars['String']>;
  start?: Maybe<Scalars['Date']>;
};


/** Много много функций адрески */
export type OohApMutationDigitalInfoDayUpdateArgs = {
  dayInfo?: Maybe<OohapDigitalPricingBlockDayInput>;
};


/** Много много функций адрески */
export type OohApMutationDigitalInfoUpdateArgs = {
  digitalInfo?: Maybe<OohapDigitalPricingBlockInput>;
};


/** Много много функций адрески */
export type OohApMutationExportAccFiasArgs = {
  ap_id: Scalars['Int'];
  fias_aoguid?: Maybe<Scalars['String']>;
};


/** Много много функций адрески */
export type OohApMutationImportConstructionFromFileArgs = {
  ap_id?: Maybe<Scalars['Int']>;
  file?: Maybe<Scalars['Upload']>;
};


/** Много много функций адрески */
export type OohApMutationSetCityReservedArgs = {
  ap_id: Scalars['Int'];
  date: Scalars['Date'];
  fias_aoguid: Scalars['String'];
};


/** Много много функций адрески */
export type OohApMutationSpecialPriceToNetBuyArgs = {
  ap_id?: Maybe<Scalars['Int']>;
  fias_aoguid?: Maybe<Scalars['String']>;
};


/** Много много функций адрески */
export type OohApMutationUpdateApUserConfigValueArgs = {
  ap_id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};


/** Много много функций адрески */
export type OohApMutationUpdateObjectArgs = {
  data?: Maybe<BasketConstructionInput>;
};


/** Много много функций адрески */
export type OohApMutationUpdateObjectMaterialListArgs = {
  ap_id?: Maybe<Scalars['Int']>;
  material_ids?: Maybe<Array<Maybe<Scalars['Int']>>>;
  object_id?: Maybe<Scalars['String']>;
};

/** Список  писем на запрос прайсов */
export type OohapPriceEmail = {
  __typename?: 'OOHAPPriceEmail';
  /** - */
  Author?: Maybe<RefEmployee>;
  /** - */
  Person?: Maybe<MlPersonItem>;
  /** - */
  ap_id?: Maybe<Scalars['Int']>;
  /** - */
  author_id?: Maybe<Scalars['Int']>;
  /** - */
  brand?: Maybe<Scalars['Boolean']>;
  /** - */
  email?: Maybe<Scalars['String']>;
  /** - */
  id?: Maybe<Scalars['String']>;
  /** - */
  lastSend?: Maybe<Scalars['DateTime']>;
  /** - */
  lastUpdate?: Maybe<Scalars['DateTime']>;
  /** - */
  mediaNetworkId?: Maybe<Scalars['Int']>;
  /** - */
  mediaNetworkName?: Maybe<Scalars['String']>;
  /** - */
  name?: Maybe<Scalars['String']>;
  /** - */
  person_id?: Maybe<Scalars['Int']>;
  /** - */
  subject?: Maybe<Scalars['String']>;
  /** - */
  template1?: Maybe<Scalars['String']>;
  /** - */
  template2?: Maybe<Scalars['String']>;
  /** - */
  template_static?: Maybe<Scalars['String']>;
};

/** Список  писем на запрос прайсов */
export type OohapPriceEmailInput = {
  /** - */
  ap_id?: Maybe<Scalars['Int']>;
  /** - */
  brand?: Maybe<Scalars['String']>;
  /** - */
  mediaNetworkId?: Maybe<Scalars['Int']>;
  /** - */
  person_id?: Maybe<Scalars['Int']>;
  /** - */
  subject?: Maybe<Scalars['String']>;
  /** - */
  template1?: Maybe<Scalars['String']>;
  /** - */
  template2?: Maybe<Scalars['String']>;
};

/** Список  писем на запрос прайсов */
export type OohapPriceEmailListItem = {
  __typename?: 'OOHAPPriceEmailListItem';
  /** - */
  Author?: Maybe<RefEmployee>;
  /** - */
  Person?: Maybe<MlPersonItem>;
  /** - */
  ap_id?: Maybe<Scalars['Int']>;
  /** - */
  author_id?: Maybe<Scalars['Int']>;
  /** - */
  cities?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** - */
  email?: Maybe<Scalars['String']>;
  /** - */
  fias_aoguids?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** - */
  id?: Maybe<Scalars['String']>;
  /** - */
  lastSend?: Maybe<Scalars['DateTime']>;
  /** - */
  lastUpdate?: Maybe<Scalars['DateTime']>;
  /** - */
  mediaNetworkId?: Maybe<Scalars['Int']>;
  /** - */
  mediaNetworkName?: Maybe<Scalars['String']>;
  /** - */
  medianetwork_id?: Maybe<Scalars['Int']>;
  /** - */
  name?: Maybe<Scalars['String']>;
  /** - */
  person_id?: Maybe<Scalars['Int']>;
};

/** Шаблон и информация о письме на резерв */
export type OohapReservEmailInfo = {
  __typename?: 'OOHAPReservEmailInfo';
  /** - */
  ap_id?: Maybe<Scalars['Int']>;
  /** - */
  brand?: Maybe<Scalars['Int']>;
  /** - */
  dateSend?: Maybe<Scalars['DateTime']>;
  /** - */
  fias_aoguid?: Maybe<Scalars['String']>;
  /** - */
  id?: Maybe<Scalars['String']>;
  /** - */
  medianetwork_id?: Maybe<Scalars['Int']>;
  /** - */
  person_id?: Maybe<Scalars['Int']>;
  /** - */
  table?: Maybe<Scalars['String']>;
  /** - */
  template?: Maybe<Scalars['String']>;
};

/** Шаблон и информация о письме на резерв */
export type OohapReservEmailInfoInput = {
  /** - */
  ap_id?: Maybe<Scalars['Int']>;
  /** - */
  brand?: Maybe<Scalars['Int']>;
  /** - */
  dateSend?: Maybe<Scalars['DateTime']>;
  /** - */
  fias_aoguid?: Maybe<Scalars['String']>;
  /** - */
  id?: Maybe<Scalars['String']>;
  /** - */
  medianetwork_id?: Maybe<Scalars['Int']>;
  /** - */
  person_id?: Maybe<Scalars['Int']>;
  /** - */
  table?: Maybe<Scalars['String']>;
  /** - */
  template?: Maybe<Scalars['String']>;
};

/** Информация по договрам и юр лицам. */
export type OohapUrInfo = {
  __typename?: 'OOHAPUrInfo';
  /** - */
  AllowExportToACC?: Maybe<Scalars['Boolean']>;
  /** - */
  Document?: Maybe<MlDocumentItem>;
  /** - */
  DpUrFace?: Maybe<MlUrFaceItem>;
  /** - */
  PartnerUrFace?: Maybe<MlUrFaceItem>;
  /** - */
  ap_id?: Maybe<Scalars['Int']>;
  /** - */
  document_id?: Maybe<Scalars['Int']>;
  /** - */
  dpUrFace_id?: Maybe<Scalars['Int']>;
  /** - */
  fias_aoguid?: Maybe<Scalars['String']>;
  /** - */
  id?: Maybe<Scalars['String']>;
  /** - */
  outer_id?: Maybe<Scalars['Int']>;
  /** - */
  outer_order_id?: Maybe<Scalars['Int']>;
  /** - */
  partnerUrFace_id?: Maybe<Scalars['Int']>;
  /** - */
  vat?: Maybe<Scalars['Boolean']>;
};

/** UrInfoInput */
export type OohapUrInfoInput = {
  /** - */
  ap_id?: Maybe<Scalars['Int']>;
  /** - */
  document_id?: Maybe<Scalars['Int']>;
  /** - */
  dpUrFace_id?: Maybe<Scalars['Int']>;
  /** - */
  fias_aoguid?: Maybe<Scalars['String']>;
  /** - */
  outer_id?: Maybe<Scalars['Int']>;
  /** - */
  outer_order_id?: Maybe<Scalars['Int']>;
  /** - */
  partnerUrFace_id?: Maybe<Scalars['Int']>;
  /** - */
  vat?: Maybe<Scalars['Boolean']>;
};

/** Брифинг OOH */
export type OohBriefing = {
  __typename?: 'OohBriefing';
  /** АП OOH */
  AP?: Maybe<OohAp>;
  /** Бренд / Торговая марка */
  Brand?: Maybe<MlBrand>;
  /** Группа комона */
  ComonGroup?: Maybe<ComonGroupItem>;
  /** Тип конструкции */
  ConstructionTypes?: Maybe<Array<Maybe<MlConstructionType>>>;
  /** MlDocumentItem */
  Contract?: Maybe<MlDocumentItem>;
  /** Тактики подбора */
  ExperienceTactics?: Maybe<Array<Maybe<OohTactics>>>;
  /** Тип экспонирования */
  ExpositionTypes?: Maybe<Array<Maybe<MlQExpositionType>>>;
  /** Сотрудник компании */
  Manager?: Maybe<RefEmployee>;
  /** Справочник материалов */
  Materials?: Maybe<Array<Maybe<MlMaterials>>>;
  /** партнёры */
  Partner?: Maybe<MlPartner>;
  /** партнёры */
  PartnerInnerList?: Maybe<Array<Maybe<MlPartner>>>;
  /** Периоды */
  Periods?: Maybe<Array<Maybe<OohPeriod>>>;
  /** Город РФ */
  PlacementCities?: Maybe<Array<Maybe<MlCity>>>;
  /** Тактики подбора */
  PlacementTactics?: Maybe<Array<Maybe<OohTactics>>>;
  /** Сотрудник компании */
  Responsible?: Maybe<RefEmployee>;
  /** Список типов целевой аудитории */
  TargetAudience?: Maybe<Array<Maybe<MlTargetAudience>>>;
  /** Юридические лица Дельтаплана */
  UrFace_nds?: Maybe<MlDpUrFaces>;
  /** Юридические лица Дельтаплана */
  UrFace_nonds?: Maybe<MlDpUrFaces>;
  /** ав внутри */
  ac_in?: Maybe<Scalars['Int']>;
  /** массив Id - идентификаторов возрастной группы целевой аудитории */
  ageRanges?: Maybe<Scalars['String']>;
  /** id типа АВ */
  agent_commission_position?: Maybe<Scalars['Int']>;
  /** сумма АВ */
  agent_commission_value?: Maybe<Scalars['Float']>;
  /** рубли или проценты в АВ */
  agent_commission_value_type?: Maybe<Scalars['Int']>;
  /** _____ap_place */
  ap_place?: Maybe<Scalars['Int']>;
  /** Дата утверджения */
  approval_date?: Maybe<Scalars['DateTime']>;
  /** id - уникальный идентификатор бренда */
  brand_id?: Maybe<Scalars['Int']>;
  /** Тип отказа */
  cancel?: Maybe<Scalars['Int']>;
  /** Дата отмены */
  cancellation_date?: Maybe<Scalars['DateTime']>;
  /** элемент ап внутри брифинга */
  cities: Array<Maybe<OohBriefingCity>>;
  /** клонировать ап */
  clone_ap?: Maybe<Scalars['Int']>;
  /** клонировать брифинг */
  clone_briefing?: Maybe<Scalars['Int']>;
  /** id группы Comon */
  comon_group_id?: Maybe<Scalars['Int']>;
  /** Список id конкурентов */
  competitors?: Maybe<Scalars['ListOfInt']>;
  /** Список конкурентов, которые не занесены в базу */
  competitors_comment?: Maybe<Scalars['String']>;
  /** Число конструкций */
  construction_count?: Maybe<Scalars['Int']>;
  /** договор */
  contract?: Maybe<Scalars['Int']>;
  /** новый договор */
  contract_new?: Maybe<Scalars['Int']>;
  /** id типа договора */
  contract_type?: Maybe<Scalars['Int']>;
  /** Количество */
  count?: Maybe<Scalars['String']>;
  /** Дата deadline */
  deadline?: Maybe<Scalars['DateTime']>;
  /** Дата deadline */
  deadline_ap?: Maybe<Scalars['DateTime']>;
  /** Дата deadline */
  deadline_briefing?: Maybe<Scalars['DateTime']>;
  /** Дата конца рк */
  end_date?: Maybe<Scalars['DateTime']>;
  /** Дата заполнения */
  entry_date?: Maybe<Scalars['DateTime']>;
  /** Через кого размещались */
  experience_provider?: Maybe<Scalars['String']>;
  /** нужна ли пояснительная записка (1/0) */
  explanatory_note?: Maybe<Scalars['Int']>;
  /** Список файлов */
  files?: Maybe<Array<Maybe<File>>>;
  /** Флайтовые элементы */
  flight_items?: Maybe<Scalars['String']>;
  /** id - уникальный идентификатор записи */
  id?: Maybe<Scalars['Int']>;
  /** id - уникальный идентификатор */
  legal_entity_novat?: Maybe<Scalars['Int']>;
  /** id - уникальный идентификатор */
  legal_entity_vat?: Maybe<Scalars['Int']>;
  /** Свет (1/0) */
  light?: Maybe<Scalars['Int']>;
  /** Менеджер id */
  manager_id?: Maybe<Scalars['Int']>;
  /** Наименование оффера */
  name?: Maybe<Scalars['String']>;
  /** Без НДС */
  no_vat?: Maybe<Scalars['Int']>;
  /** id - уникальный идентификатор партнёра */
  partner_id?: Maybe<Scalars['Int']>;
  /** дней предоплаты */
  payment_from_beginning?: Maybe<Scalars['Int']>;
  /** объекты привязки */
  placement_objects?: Maybe<Scalars['String']>;
  /** Тип рамещения */
  placement_type?: Maybe<Scalars['Int']>;
  /** дни постоплаты */
  post_payment_days?: Maybe<Scalars['Int']>;
  /** постоплата */
  post_payment_value?: Maybe<Scalars['Float']>;
  /** дни предоплаты */
  prepayment_days?: Maybe<Scalars['Int']>;
  /** предоплата */
  prepayment_value?: Maybe<Scalars['String']>;
  /** тип предоплаты */
  prepayment_value_type?: Maybe<Scalars['Int']>;
  /** общие комментарии по брифу */
  recommends?: Maybe<Scalars['String']>;
  /** Тип отказа */
  refusal?: Maybe<Scalars['Int']>;
  /** Описание причины отказа */
  refusal_description?: Maybe<Scalars['String']>;
  /** Ответственный id */
  responsible_id?: Maybe<Scalars['Int']>;
  /** Ограничения */
  restriction?: Maybe<Scalars['String']>;
  /** Допустимость второго резерва (1/0) */
  second_reserve?: Maybe<Scalars['Int']>;
  /** Пол целевой аудитории (f,m,fm) */
  sex?: Maybe<Scalars['String']>;
  /** исходный брифинг */
  src_briefing?: Maybe<Scalars['Int']>;
  /** Дата начала рк */
  start_date?: Maybe<Scalars['DateTime']>;
  /** статус */
  status?: Maybe<Scalars['Int']>;
  /** Целевая группа */
  target_audience?: Maybe<Scalars['String']>;
  /** Доход целевой аудитории */
  target_audience_income?: Maybe<Scalars['String']>;
  /** Задача в комоне */
  taskId?: Maybe<Scalars['Int']>;
  /** бюджет */
  total_budget?: Maybe<Scalars['String']>;
  /** ссылка на сайт */
  url?: Maybe<Scalars['String']>;
  /** С НДС */
  vat?: Maybe<Scalars['Int']>;
};

/** элемент ап внутри брифинга */
export type OohBriefingCity = {
  __typename?: 'OohBriefingCity';
  /** Город РФ */
  City?: Maybe<MlCity>;
  /** хэш города */
  city?: Maybe<Scalars['String']>;
  /** id - уникальный идентификатор записи */
  id?: Maybe<Scalars['Int']>;
  /** период внутри элемента брифинга */
  periods?: Maybe<Array<Maybe<OohBriefingCityPeriod>>>;
};

/** элемент ап внутри брифинга */
export type OohBriefingCityInput = {
  /** Загрузка данных */
  City?: Maybe<MlCityInput>;
  budget?: Maybe<Scalars['Float']>;
  /** хэш города */
  city?: Maybe<Scalars['String']>;
  /** id - уникальный идентификатор записи */
  id?: Maybe<Scalars['Int']>;
  /** элемент ап внутри брифинга */
  periods?: Maybe<Array<Maybe<OohBriefingCityPeriodInput>>>;
};

/** период внутри элемента брифинга */
export type OohBriefingCityPeriod = {
  __typename?: 'OohBriefingCityPeriod';
  /** бюджет */
  budget?: Maybe<Scalars['Float']>;
  /** элемент конструкции внутри периода */
  constructions?: Maybe<Array<Maybe<OohBriefingCityPeriodConstruction>>>;
  /** количество */
  count?: Maybe<Scalars['String']>;
  /** дата конца периода */
  end_date?: Maybe<Scalars['DateTime']>;
  /** id - уникальный идентификатор записи */
  id?: Maybe<Scalars['ID']>;
  /** дата начала периода */
  start_date?: Maybe<Scalars['DateTime']>;
};

/** элемент конструкции внутри периода */
export type OohBriefingCityPeriodConstruction = {
  __typename?: 'OohBriefingCityPeriodConstruction';
  /** Тип экспонирования */
  ExpositionTypes?: Maybe<Array<Maybe<MlQExpositionType>>>;
  /** Справочник материалов */
  Materials?: Maybe<Array<Maybe<MlMaterials>>>;
  /** Тип конструкции */
  Type?: Maybe<QueriesMlConstructionType>;
  /** тип конструкции */
  constructions?: Maybe<Array<Maybe<Scalars['Int']>>>;
  /** количество */
  count?: Maybe<Scalars['String']>;
  /** id - уникальный идентификатор записи */
  id?: Maybe<Scalars['ID']>;
  /** тип конструкции */
  type?: Maybe<Scalars['Int']>;
};

/** элемент конструкции внутри периода */
export type OohBriefingCityPeriodConstructionInput = {
  /** виды экспонирования */
  ExpositionTypes?: Maybe<Array<Maybe<MlExpositionTypeInput>>>;
  /** материалы */
  Materials?: Maybe<Array<Maybe<MlMaterialInput>>>;
  /** конструкция */
  Type?: Maybe<MlConstructionTypeInput>;
  /** тип конструкции */
  constructions?: Maybe<Array<Maybe<Scalars['Int']>>>;
  /** количество */
  count?: Maybe<Scalars['String']>;
  /** виды экспонирования */
  exposition_types?: Maybe<Array<Maybe<Scalars['Int']>>>;
  /** id - уникальный идентификатор записи */
  id?: Maybe<Scalars['ID']>;
  /** материалы */
  materials?: Maybe<Array<Maybe<Scalars['Int']>>>;
  /** тип конструкции */
  type?: Maybe<Scalars['Int']>;
};

/** элемент ап внутри брифинга */
export type OohBriefingCityPeriodInput = {
  /** бюджет */
  budget?: Maybe<Scalars['Float']>;
  /** элемент конструкции внутри периода */
  constructions?: Maybe<Array<Maybe<OohBriefingCityPeriodConstructionInput>>>;
  /** количество */
  count?: Maybe<Scalars['String']>;
  /** дата конца периода */
  end_date?: Maybe<Scalars['DateTime']>;
  /** id - уникальный идентификатор записи */
  id?: Maybe<Scalars['ID']>;
  /** дата начала периода */
  start_date?: Maybe<Scalars['DateTime']>;
};

/** Брифинг для наружной рекламы */
export type OohBriefingInput = {
  /** Типы конструкций */
  ConstructionTypes?: Maybe<Array<Maybe<MlConstructionTypeInput>>>;
  /** договор */
  Contract?: Maybe<DtDocumentInput>;
  /** Использованные тактики */
  ExperienceTactics?: Maybe<Array<Maybe<MlTacticsInput>>>;
  /** Типы экпонирования */
  ExpositionTypes?: Maybe<Array<Maybe<MlExpositionTypeInput>>>;
  /** Типы материалов */
  Materials?: Maybe<Array<Maybe<MlMaterialInput>>>;
  /** Загрузка периода */
  Periods?: Maybe<Array<Maybe<OohPeriodInput>>>;
  /** Города размещения */
  PlacementCities?: Maybe<Array<Maybe<MlCityInput>>>;
  /** Тактики */
  PlacementTactics?: Maybe<Array<Maybe<MlTacticsInput>>>;
  /** ав внутри */
  ac_in?: Maybe<Scalars['Int']>;
  /** массив Id - идентификаторов возрастной группы целевой аудитории */
  ageRanges?: Maybe<Scalars['String']>;
  /** id типа АВ */
  agent_commission_position?: Maybe<Scalars['Int']>;
  /** сумма АВ */
  agent_commission_value?: Maybe<Scalars['Float']>;
  /** рубли или проценты в АВ */
  agent_commission_value_type?: Maybe<Scalars['Int']>;
  /** _____ap_place */
  ap_place?: Maybe<Scalars['Int']>;
  /** Дата утверджения */
  approval_date?: Maybe<Scalars['DateTime']>;
  /** id - уникальный идентификатор бренда */
  brand_id?: Maybe<Scalars['Int']>;
  /** Тип отказа */
  cancel?: Maybe<Scalars['Int']>;
  /** Дата отмены */
  cancellation_date?: Maybe<Scalars['DateTime']>;
  /** Загрузка списка ап */
  cities?: Maybe<Array<Maybe<OohBriefingCityInput>>>;
  /** клонировать ап */
  clone_ap?: Maybe<Scalars['Int']>;
  /** клонировать брифинг */
  clone_briefing?: Maybe<Scalars['Int']>;
  /** id группы Comon */
  comon_group_id?: Maybe<Scalars['Int']>;
  /** Список id конкурентов */
  competitors?: Maybe<Scalars['ListOfInt']>;
  /** Список конкурентов, которые не занесены в базу */
  competitors_comment?: Maybe<Scalars['String']>;
  /** Число конструкций */
  construction_count?: Maybe<Scalars['Int']>;
  /** Типы конструкций */
  construction_types?: Maybe<Array<Maybe<Scalars['Int']>>>;
  /** договор */
  contract?: Maybe<Scalars['Int']>;
  /** новый договор */
  contract_new?: Maybe<Scalars['Int']>;
  /** id типа договора */
  contract_type?: Maybe<Scalars['Int']>;
  /** Количество */
  count?: Maybe<Scalars['String']>;
  /** Дата deadline */
  deadline?: Maybe<Scalars['DateTime']>;
  /** Дата deadline */
  deadline_ap?: Maybe<Scalars['DateTime']>;
  /** Дата deadline */
  deadline_briefing?: Maybe<Scalars['DateTime']>;
  /** Дата конца рк */
  end_date?: Maybe<Scalars['DateTime']>;
  /** Дата заполнения */
  entry_date?: Maybe<Scalars['DateTime']>;
  /** Через кого размещались */
  experience_provider?: Maybe<Scalars['String']>;
  /** Использованные тактики */
  experience_tactics?: Maybe<Array<Maybe<Scalars['Int']>>>;
  /** нужна ли пояснительная записка (1/0) */
  explanatory_note?: Maybe<Scalars['Int']>;
  /** Типы экпонирования */
  exposition_types?: Maybe<Array<Maybe<Scalars['Int']>>>;
  /** массив id файлов для оффера */
  files?: Maybe<Array<Maybe<Scalars['Int']>>>;
  /** Флайтовые элементы */
  flight_items?: Maybe<Scalars['String']>;
  /** id - уникальный идентификатор записи */
  id?: Maybe<Scalars['Int']>;
  /** id - уникальный идентификатор */
  legal_entity_novat?: Maybe<Scalars['Int']>;
  /** id - уникальный идентификатор */
  legal_entity_vat?: Maybe<Scalars['Int']>;
  /** Свет (1/0) */
  light?: Maybe<Scalars['Int']>;
  /** Менеджер id */
  manager_id?: Maybe<Scalars['Int']>;
  /** Типы материалов */
  materials?: Maybe<Array<Maybe<Scalars['Int']>>>;
  /** Наименование оффера */
  name?: Maybe<Scalars['String']>;
  /** Без НДС */
  no_vat?: Maybe<Scalars['Int']>;
  /** id - уникальный идентификатор партнёра */
  partner_id?: Maybe<Scalars['Int']>;
  /** дней предоплаты */
  payment_from_beginning?: Maybe<Scalars['Int']>;
  /** Города размещения */
  placement_cities?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** объекты привязки */
  placement_objects?: Maybe<Scalars['String']>;
  /** Тактики */
  placement_tactics?: Maybe<Array<Maybe<Scalars['Int']>>>;
  /** Тип рамещения */
  placement_type?: Maybe<Scalars['Int']>;
  /** дни постоплаты */
  post_payment_days?: Maybe<Scalars['Int']>;
  /** постоплата */
  post_payment_value?: Maybe<Scalars['Float']>;
  /** дни предоплаты */
  prepayment_days?: Maybe<Scalars['Int']>;
  /** предоплата */
  prepayment_value?: Maybe<Scalars['String']>;
  /** тип предоплаты */
  prepayment_value_type?: Maybe<Scalars['Int']>;
  /** общие комментарии по брифу */
  recommends?: Maybe<Scalars['String']>;
  /** Тип отказа */
  refusal?: Maybe<Scalars['Int']>;
  /** Описание причины отказа */
  refusal_description?: Maybe<Scalars['String']>;
  /** Ответственный id */
  responsible_id?: Maybe<Scalars['Int']>;
  /** Ограничения */
  restriction?: Maybe<Scalars['String']>;
  /** Допустимость второго резерва (1/0) */
  second_reserve?: Maybe<Scalars['Int']>;
  /** Пол целевой аудитории (f,m,fm) */
  sex?: Maybe<Scalars['String']>;
  /** исходный брифинг */
  src_briefing?: Maybe<Scalars['Int']>;
  /** Дата начала рк */
  start_date?: Maybe<Scalars['DateTime']>;
  /** статус */
  status?: Maybe<Scalars['Int']>;
  /** Целевая группа */
  target_audience?: Maybe<Scalars['String']>;
  /** Доход целевой аудитории */
  target_audience_income?: Maybe<Scalars['String']>;
  /** Задача в комоне */
  taskId?: Maybe<Scalars['Int']>;
  /** бюджет */
  total_budget?: Maybe<Scalars['String']>;
  /** ссылка на сайт */
  url?: Maybe<Scalars['String']>;
  /** С НДС */
  vat?: Maybe<Scalars['Int']>;
};

/** Объект OOН наружней рекламы */
export type OohConstruction = {
  __typename?: 'OOHConstruction';
  /** address */
  FIAS_AOGUID?: Maybe<Scalars['String']>;
  /** address */
  GID?: Maybe<Scalars['String']>;
  /** GRP */
  GRP?: Maybe<Scalars['Float']>;
  /** LastUpdate */
  LastUpdate?: Maybe<Scalars['DateTime']>;
  /** mediaNetworkName */
  MediaNetworkColor?: Maybe<Scalars['String']>;
  /** address */
  Object_Geo_UID?: Maybe<Scalars['String']>;
  /** ссылка на фотографию */
  URL_Photo?: Maybe<Scalars['String']>;
  /** address */
  address?: Maybe<Scalars['String']>;
  /** admAreas */
  admAreas?: Maybe<Scalars['String']>;
  /** address */
  cityName?: Maybe<Scalars['String']>;
  /** constructionTypeId */
  constructionTypeId?: Maybe<Scalars['Int']>;
  /** constructionTypeName */
  constructionTypeName?: Maybe<Scalars['String']>;
  /** constructionTypeShort */
  constructionTypeShort?: Maybe<Scalars['String']>;
  /** estGRP */
  estGRP?: Maybe<Scalars['Float']>;
  /** exposition */
  exposition?: Maybe<Scalars['String']>;
  /** exposition */
  expositionId?: Maybe<Scalars['Int']>;
  /** id */
  id?: Maybe<Scalars['String']>;
  /** installationType */
  installationType?: Maybe<Scalars['String']>;
  /** latitude */
  latitude?: Maybe<Scalars['Float']>;
  /** light */
  light?: Maybe<Scalars['Boolean']>;
  /** longitude */
  longitude?: Maybe<Scalars['Float']>;
  /** Справочник материалов */
  materials?: Maybe<Array<Maybe<MlMaterials>>>;
  /** mediaNetworkId */
  mediaNetworkId?: Maybe<Scalars['Int']>;
  /** mediaNetworkName */
  mediaNetworkName?: Maybe<Scalars['String']>;
  /** медиа тип */
  mediaTypeId?: Maybe<Scalars['Int']>;
  /** PlacementType */
  placementType?: Maybe<Scalars['String']>;
  /** requirements */
  requirements?: Maybe<Scalars['String']>;
  /** residentAreas */
  residentAreas?: Maybe<Scalars['String']>;
  /** Scoring */
  scoring?: Maybe<Scalars['Float']>;
  /** side */
  side?: Maybe<Scalars['String']>;
  /** side */
  sideId?: Maybe<Scalars['Int']>;
  /** sideType */
  sideType?: Maybe<Scalars['String']>;
  /** sideType */
  sideTypeId?: Maybe<Scalars['Int']>;
  /** size */
  size?: Maybe<Scalars['String']>;
  /** size */
  sizeId?: Maybe<Scalars['Int']>;
};

/** Стандартный объект */
export type OohMutation = {
  __typename?: 'OohMutation';
  /** Мутации Адресной программы */
  AP?: Maybe<OohApMutation>;
  /** Добавление Оффера */
  BriefingCreate?: Maybe<OohBriefing>;
  /** Удаление */
  BriefingRemove?: Maybe<OohBriefing>;
  /** Обновление данных брифа */
  BriefingUpdate?: Maybe<OohBriefing>;
  /** Мутации Медиа сетей */
  MediaNetworks?: Maybe<MediaNetworksMutation>;
  /** Добавление задачи */
  createBriefingTask?: Maybe<Scalars['Int']>;
};


/** Стандартный объект */
export type OohMutationBriefingCreateArgs = {
  briefing?: Maybe<OohBriefingInput>;
};


/** Стандартный объект */
export type OohMutationBriefingRemoveArgs = {
  id?: Maybe<Scalars['Int']>;
};


/** Стандартный объект */
export type OohMutationBriefingUpdateArgs = {
  briefing?: Maybe<OohBriefingInput>;
};


/** Стандартный объект */
export type OohMutationCreateBriefingTaskArgs = {
  briefing?: Maybe<OohBriefingInput>;
};

/** Элемент периода */
export type OohPeriod = {
  __typename?: 'OohPeriod';
  /** ID брифинга, к которому привязан элемент скидки */
  briefing_id?: Maybe<Scalars['Int']>;
  /** Дата начала */
  end_date?: Maybe<Scalars['DateTime']>;
  /** Дата обновления */
  entry_date?: Maybe<Scalars['DateTime']>;
  /** id - уникальный идентификатор записи */
  id?: Maybe<Scalars['String']>;
  /** Дата начала */
  start_date?: Maybe<Scalars['DateTime']>;
};

/** Загрузка данных по периодам */
export type OohPeriodInput = {
  /** ID брифинга, к которому привязан элемент скидки */
  briefing_id?: Maybe<Scalars['Int']>;
  /** Дата начала */
  end_date?: Maybe<Scalars['DateTime']>;
  /** Дата обновления */
  entry_date?: Maybe<Scalars['DateTime']>;
  /** id - уникальный идентификатор записи */
  id?: Maybe<Scalars['String']>;
  /** Дата начала */
  start_date?: Maybe<Scalars['DateTime']>;
};

/** Стандартный объект */
export type OohQuery = {
  __typename?: 'OohQuery';
  /** АП OOH */
  AP?: Maybe<OohAp>;
  /** BasketMediametry */
  APBasketMediametry?: Maybe<BasketMediametry>;
  /** Объекты клиента */
  APCityClientObjectSearch?: Maybe<Array<Maybe<OohapClientObject>>>;
  /** Объекты клиента */
  APCityClientObjects?: Maybe<Array<Maybe<OohapClientObject>>>;
  /** Город Из АП */
  APGetCityInfo?: Maybe<OohapCityInfo>;
  /** Пользовательские настройки АП */
  APGetCityMediaNetworkEmailTableTemplate?: Maybe<Scalars['String']>;
  /** Пользовательские настройки АП */
  APGetCityMediaNetworkEmailTextTemplate?: Maybe<Scalars['String']>;
  /** Пользовательские настройки АП */
  APGetCityMediaNetworkReservEmail?: Maybe<Scalars['String']>;
  /** Пользовательские настройки АП */
  APGetCityXls?: Maybe<Scalars['String']>;
  /** Информация по договрам и юр лицам. */
  APGetUrInfo?: Maybe<Array<Maybe<OohapUrInfo>>>;
  /** Информация по договрам и юр лицам. */
  APGetUrInfoFias?: Maybe<Array<Maybe<OohapUrInfo>>>;
  APGetYandexConstructorFile?: Maybe<Scalars['String']>;
  /** Список офферов с информацией по общему количеству в базе */
  APList?: Maybe<ListWithPager_OohAp>;
  /** Список конструкций готовых для создания сделки */
  APObjectsReadyForOrder?: Maybe<Array<Maybe<OohConstruction>>>;
  /** Пользовательские настройки АП */
  APUserConfigList?: Maybe<Array<Maybe<UserConfig>>>;
  /** Пользовательские настройки АП */
  APUserConfigValue?: Maybe<UserConfig>;
  /**   конструкция в корзине */
  ApBasketConstruction?: Maybe<BasketConstruction>;
  /** Список конструкций в корзине */
  ApBasketConstructionList?: Maybe<Array<Maybe<BasketConstruction>>>;
  /** Список конструкций в корзине */
  ApBasketConstructionPeriod?: Maybe<Array<Maybe<BasketConstructionPeriod>>>;
  /** Список конструкций в корзине */
  ApBasketMassPrices?: Maybe<Array<Maybe<OohapMediaNetwork>>>;
  /** Список конструкций в корзине */
  ApBasketMediaNetworkList?: Maybe<Array<Maybe<OohapMediaNetwork>>>;
  /** Доступность конструции */
  ConstructionAvailability?: Maybe<Array<Maybe<MlConstructionsAvailabilityInfo>>>;
  /** Цены */
  ConstructionPeriodInfo?: Maybe<Array<Maybe<MlConstructionsWorkInfo>>>;
  /** Цены */
  ConstructionWorkInfo?: Maybe<Array<Maybe<MlConstructionsWorkInfo>>>;
  /** АП OOH */
  MailLog?: Maybe<MailLogOohQueries>;
  /** Стандартный объект */
  MediaNetworks?: Maybe<MediaNetworksOohQueries>;
  /** Брифинг OOH */
  OohBriefing?: Maybe<OohBriefing>;
  /** Список офферов с информацией по общему количеству в базе */
  OohBriefingList?: Maybe<ListWithPager_OohBriefing>;
  /** Статистика по заполенности наружки */
  Statistic?: Maybe<OohStatistic>;
  /** Истрия закупа конструкции */
  getConstructionHistory?: Maybe<Array<Maybe<OohapConstructionHistory>>>;
  /** Истрия закупа конструкции */
  getFullStatHistoryMediaNetwork?: Maybe<Array<Maybe<OohStatBuyMediaNetworkMonthHistory>>>;
  /** Истрия закупа конструкции */
  getSaleOutMediaNetworkStat?: Maybe<Array<Maybe<OohSaleOutStat>>>;
  /** Истрия закупа конструкции */
  getStatBuyCity?: Maybe<Array<Maybe<OohStatBuyCity>>>;
  /** Истрия закупа конструкции */
  getStatBuyConstruction?: Maybe<Array<Maybe<OohStatBuyConstruction>>>;
  /** Истрия закупа конструкции */
  getStatBuyMediaNetwork?: Maybe<Array<Maybe<OohStatBuyMediaNetwork>>>;
  /** Истрия закупа конструкции */
  getStatDiscountCity?: Maybe<Array<Maybe<OohStatDiscountCity>>>;
  /** Истрия закупа конструкции */
  getStatDiscountMediaNetwork?: Maybe<Array<Maybe<OohStatDiscountMediaNetwork>>>;
};


/** Стандартный объект */
export type OohQueryApArgs = {
  id?: Maybe<Scalars['Int']>;
};


/** Стандартный объект */
export type OohQueryApBasketMediametryArgs = {
  ap_id?: Maybe<Scalars['Int']>;
  fias_aoguid?: Maybe<Scalars['String']>;
};


/** Стандартный объект */
export type OohQueryApCityClientObjectSearchArgs = {
  fias_aoguid?: Maybe<Scalars['String']>;
  string?: Maybe<Scalars['String']>;
};


/** Стандартный объект */
export type OohQueryApCityClientObjectsArgs = {
  params?: Maybe<ParamsInput>;
};


/** Стандартный объект */
export type OohQueryApGetCityInfoArgs = {
  ap_id: Scalars['Int'];
  fias_aoguid: Scalars['String'];
};


/** Стандартный объект */
export type OohQueryApGetCityMediaNetworkEmailTableTemplateArgs = {
  ap_id: Scalars['Int'];
  fias_aoguid: Scalars['String'];
  medianetwork_id: Scalars['Int'];
};


/** Стандартный объект */
export type OohQueryApGetCityMediaNetworkEmailTextTemplateArgs = {
  ap_id: Scalars['Int'];
  fias_aoguid: Scalars['String'];
  medianetwork_id: Scalars['Int'];
  person_id?: Maybe<Scalars['Int']>;
};


/** Стандартный объект */
export type OohQueryApGetCityMediaNetworkReservEmailArgs = {
  ap_id: Scalars['Int'];
  fias_aoguid: Scalars['String'];
  medianetwork_id: Scalars['Int'];
  person_id?: Maybe<Scalars['Int']>;
};


/** Стандартный объект */
export type OohQueryApGetCityXlsArgs = {
  ap_id: Scalars['Int'];
  fias_aoguid: Scalars['String'];
};


/** Стандартный объект */
export type OohQueryApGetUrInfoArgs = {
  ap_id: Scalars['Int'];
};


/** Стандартный объект */
export type OohQueryApGetUrInfoFiasArgs = {
  ap_id: Scalars['Int'];
  fias_aoguid: Scalars['String'];
};


/** Стандартный объект */
export type OohQueryApGetYandexConstructorFileArgs = {
  ap_id: Scalars['Int'];
  fias_aoguid?: Maybe<Scalars['String']>;
};


/** Стандартный объект */
export type OohQueryApListArgs = {
  params?: Maybe<ParamsInput>;
};


/** Стандартный объект */
export type OohQueryApObjectsReadyForOrderArgs = {
  ap_id?: Maybe<Scalars['Int']>;
  fias_aoguid?: Maybe<Scalars['String']>;
};


/** Стандартный объект */
export type OohQueryApUserConfigListArgs = {
  ap_id?: Maybe<Scalars['Int']>;
};


/** Стандартный объект */
export type OohQueryApUserConfigValueArgs = {
  ap_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['Int']>;
};


/** Стандартный объект */
export type OohQueryApBasketConstructionArgs = {
  ap_id?: Maybe<Scalars['Int']>;
  object_id?: Maybe<Scalars['String']>;
};


/** Стандартный объект */
export type OohQueryApBasketConstructionListArgs = {
  params?: Maybe<ParamsInput>;
};


/** Стандартный объект */
export type OohQueryApBasketConstructionPeriodArgs = {
  ap_id?: Maybe<Scalars['Int']>;
  object_id?: Maybe<Scalars['String']>;
};


/** Стандартный объект */
export type OohQueryApBasketMassPricesArgs = {
  ap_id?: Maybe<Scalars['Int']>;
  fias_aoguid?: Maybe<Scalars['String']>;
};


/** Стандартный объект */
export type OohQueryApBasketMediaNetworkListArgs = {
  ap_id?: Maybe<Scalars['Int']>;
  fias_aoguid?: Maybe<Scalars['String']>;
};


/** Стандартный объект */
export type OohQueryConstructionAvailabilityArgs = {
  Object_UID: Scalars['String'];
  PeriodEnd: Scalars['Date'];
  PeriodStart: Scalars['Date'];
};


/** Стандартный объект */
export type OohQueryConstructionPeriodInfoArgs = {
  Object_UID: Scalars['String'];
  PeriodEnd: Scalars['Date'];
  PeriodStart: Scalars['Date'];
  ap_id: Scalars['Int'];
};


/** Стандартный объект */
export type OohQueryConstructionWorkInfoArgs = {
  Object_UID: Scalars['String'];
  PeriodEnd: Scalars['Date'];
  PeriodStart: Scalars['Date'];
  ap_id: Scalars['Int'];
  worktype?: Maybe<Scalars['Int']>;
};


/** Стандартный объект */
export type OohQueryMailLogArgs = {
  id?: Maybe<Scalars['Int']>;
};


/** Стандартный объект */
export type OohQueryMediaNetworksArgs = {
  id?: Maybe<Scalars['Int']>;
};


/** Стандартный объект */
export type OohQueryOohBriefingArgs = {
  id?: Maybe<Scalars['Int']>;
};


/** Стандартный объект */
export type OohQueryOohBriefingListArgs = {
  params?: Maybe<ParamsInput>;
};


/** Стандартный объект */
export type OohQueryStatisticArgs = {
  id: Scalars['String'];
};


/** Стандартный объект */
export type OohQueryGetConstructionHistoryArgs = {
  Object_UID?: Maybe<Scalars['String']>;
};


/** Стандартный объект */
export type OohQueryGetFullStatHistoryMediaNetworkArgs = {
  constructionTypeId?: Maybe<Scalars['Int']>;
  fiasAoguid?: Maybe<Scalars['String']>;
  mediaNetworkId?: Maybe<Scalars['Int']>;
};


/** Стандартный объект */
export type OohQueryGetSaleOutMediaNetworkStatArgs = {
  fiasAoguid?: Maybe<Scalars['String']>;
  mediaNetworkId?: Maybe<Scalars['Int']>;
  periods?: Maybe<Array<Maybe<Scalars['Date']>>>;
};


/** Стандартный объект */
export type OohQueryGetStatBuyCityArgs = {
  constructionTypeId?: Maybe<Scalars['Int']>;
  fiasAoguid?: Maybe<Scalars['String']>;
};


/** Стандартный объект */
export type OohQueryGetStatBuyConstructionArgs = {
  Object_UID?: Maybe<Scalars['String']>;
};


/** Стандартный объект */
export type OohQueryGetStatBuyMediaNetworkArgs = {
  constructionTypeId?: Maybe<Scalars['Int']>;
  fiasAoguid?: Maybe<Scalars['String']>;
  mediaNetworkId?: Maybe<Scalars['Int']>;
};


/** Стандартный объект */
export type OohQueryGetStatDiscountCityArgs = {
  constructionTypeId?: Maybe<Scalars['Int']>;
  fiasAoguid?: Maybe<Scalars['String']>;
  periods?: Maybe<Array<Maybe<Scalars['Date']>>>;
};


/** Стандартный объект */
export type OohQueryGetStatDiscountMediaNetworkArgs = {
  constructionTypeId?: Maybe<Scalars['Int']>;
  fiasAoguid?: Maybe<Scalars['String']>;
  mediaNetworkId?: Maybe<Scalars['Int']>;
  periods?: Maybe<Array<Maybe<Scalars['Date']>>>;
};

/** История закупа медиасети  SaleOutStat */
export type OohSaleOutStat = {
  __typename?: 'OohSaleOutStat';
  /** id объекта */
  AOGUID?: Maybe<Scalars['String']>;
  /** id объекта */
  ConstrType_ID?: Maybe<Scalars['Int']>;
  /** id объекта */
  MediaNetworkName?: Maybe<Scalars['String']>;
  /** id объекта */
  MediaNetwork_ID?: Maybe<Scalars['Int']>;
  /** id объекта */
  OFFNAME?: Maybe<Scalars['String']>;
  /** id объекта */
  Period?: Maybe<Scalars['Date']>;
  /** id объекта */
  SaleOut?: Maybe<Scalars['Float']>;
  /** id объекта */
  Sold?: Maybe<Scalars['Int']>;
  /** id объекта */
  Total?: Maybe<Scalars['Int']>;
};

/** История закупа по городу  */
export type OohStatBuyCity = {
  __typename?: 'OohStatBuyCity';
  /** id объекта */
  ConstrType_ID?: Maybe<Scalars['Int']>;
  /** id объекта */
  aoguid?: Maybe<Scalars['String']>;
  /** id объекта */
  cityName?: Maybe<Scalars['String']>;
  /** id объекта */
  constructionTypeName?: Maybe<Scalars['String']>;
  /** id объекта */
  priceAvg?: Maybe<Scalars['String']>;
  /** id объекта */
  priceMax?: Maybe<Scalars['String']>;
  /** id объекта */
  priceMin?: Maybe<Scalars['String']>;
  /** id объекта */
  summ_fact_client_avg?: Maybe<Scalars['String']>;
  /** id объекта */
  summ_fact_client_max?: Maybe<Scalars['String']>;
  /** id объекта */
  summ_fact_client_min?: Maybe<Scalars['String']>;
  /** id объекта */
  summ_fact_oper_avg?: Maybe<Scalars['String']>;
  /** id объекта */
  summ_fact_oper_max?: Maybe<Scalars['String']>;
  /** id объекта */
  summ_fact_oper_min?: Maybe<Scalars['String']>;
};

/** История закупа поверхности  */
export type OohStatBuyConstruction = {
  __typename?: 'OohStatBuyConstruction';
  /** id объекта */
  brand?: Maybe<Scalars['String']>;
  /** id объекта */
  buyer?: Maybe<Scalars['String']>;
  /** id объекта */
  client?: Maybe<Scalars['String']>;
  /** id объекта */
  date_end?: Maybe<Scalars['DateTime']>;
  /** id объекта */
  date_start?: Maybe<Scalars['DateTime']>;
  /** id объекта */
  object_id?: Maybe<Scalars['String']>;
  /** id объекта */
  price?: Maybe<Scalars['Float']>;
  /** id объекта */
  summ_fact_client?: Maybe<Scalars['Float']>;
  /** id объекта */
  summ_fact_oper?: Maybe<Scalars['Float']>;
};

/** История закупа медиасети  */
export type OohStatBuyMediaNetwork = {
  __typename?: 'OohStatBuyMediaNetwork';
  /** id объекта */
  cityName?: Maybe<Scalars['String']>;
  /** id объекта */
  constructionTypeName?: Maybe<Scalars['String']>;
  /** id объекта */
  mediaNetworkName?: Maybe<Scalars['String']>;
  /** id объекта */
  mediaNetwork_ID?: Maybe<Scalars['Int']>;
  /** id объекта */
  summ_fact_client_avg?: Maybe<Scalars['String']>;
  /** id объекта */
  summ_fact_client_max?: Maybe<Scalars['String']>;
  /** id объекта */
  summ_fact_client_min?: Maybe<Scalars['String']>;
  /** id объекта */
  summ_fact_oper_avg?: Maybe<Scalars['String']>;
  /** id объекта */
  summ_fact_oper_max?: Maybe<Scalars['String']>;
  /** id объекта */
  summ_fact_oper_min?: Maybe<Scalars['String']>;
};

/** История закупа медиасети по месяцам  */
export type OohStatBuyMediaNetworkMonthHistory = {
  __typename?: 'OohStatBuyMediaNetworkMonthHistory';
  /** id объекта */
  cityName?: Maybe<Scalars['String']>;
  /** id объекта */
  constructionTypeName?: Maybe<Scalars['String']>;
  /** id объекта */
  mediaNetworkName?: Maybe<Scalars['String']>;
  /** id объекта */
  mediaNetwork_ID?: Maybe<Scalars['Int']>;
  /** id объекта */
  summ_fact_oper_april_avg?: Maybe<Scalars['String']>;
  /** id объекта */
  summ_fact_oper_april_max?: Maybe<Scalars['String']>;
  /** id объекта */
  summ_fact_oper_april_min?: Maybe<Scalars['String']>;
  /** id объекта */
  summ_fact_oper_august_avg?: Maybe<Scalars['String']>;
  /** id объекта */
  summ_fact_oper_august_max?: Maybe<Scalars['String']>;
  /** id объекта */
  summ_fact_oper_august_min?: Maybe<Scalars['String']>;
  /** id объекта */
  summ_fact_oper_avg?: Maybe<Scalars['String']>;
  /** id объекта */
  summ_fact_oper_december_avg?: Maybe<Scalars['String']>;
  /** id объекта */
  summ_fact_oper_december_max?: Maybe<Scalars['String']>;
  /** id объекта */
  summ_fact_oper_december_min?: Maybe<Scalars['String']>;
  /** id объекта */
  summ_fact_oper_february_avg?: Maybe<Scalars['String']>;
  /** id объекта */
  summ_fact_oper_february_max?: Maybe<Scalars['String']>;
  /** id объекта */
  summ_fact_oper_february_min?: Maybe<Scalars['String']>;
  /** id объекта */
  summ_fact_oper_january_avg?: Maybe<Scalars['String']>;
  /** id объекта */
  summ_fact_oper_january_max?: Maybe<Scalars['String']>;
  /** id объекта */
  summ_fact_oper_january_min?: Maybe<Scalars['String']>;
  /** id объекта */
  summ_fact_oper_jule_avg?: Maybe<Scalars['String']>;
  /** id объекта */
  summ_fact_oper_jule_max?: Maybe<Scalars['String']>;
  /** id объекта */
  summ_fact_oper_jule_min?: Maybe<Scalars['String']>;
  /** id объекта */
  summ_fact_oper_june_avg?: Maybe<Scalars['String']>;
  /** id объекта */
  summ_fact_oper_june_max?: Maybe<Scalars['String']>;
  /** id объекта */
  summ_fact_oper_june_min?: Maybe<Scalars['String']>;
  /** id объекта */
  summ_fact_oper_march_avg?: Maybe<Scalars['String']>;
  /** id объекта */
  summ_fact_oper_march_max?: Maybe<Scalars['String']>;
  /** id объекта */
  summ_fact_oper_march_min?: Maybe<Scalars['String']>;
  /** id объекта */
  summ_fact_oper_max?: Maybe<Scalars['String']>;
  /** id объекта */
  summ_fact_oper_may_avg?: Maybe<Scalars['String']>;
  /** id объекта */
  summ_fact_oper_may_max?: Maybe<Scalars['String']>;
  /** id объекта */
  summ_fact_oper_may_min?: Maybe<Scalars['String']>;
  /** id объекта */
  summ_fact_oper_min?: Maybe<Scalars['String']>;
  /** id объекта */
  summ_fact_oper_november_avg?: Maybe<Scalars['String']>;
  /** id объекта */
  summ_fact_oper_november_max?: Maybe<Scalars['String']>;
  /** id объекта */
  summ_fact_oper_november_min?: Maybe<Scalars['String']>;
  /** id объекта */
  summ_fact_oper_october_avg?: Maybe<Scalars['String']>;
  /** id объекта */
  summ_fact_oper_october_max?: Maybe<Scalars['String']>;
  /** id объекта */
  summ_fact_oper_october_min?: Maybe<Scalars['String']>;
  /** id объекта */
  summ_fact_oper_september_avg?: Maybe<Scalars['String']>;
  /** id объекта */
  summ_fact_oper_september_max?: Maybe<Scalars['String']>;
  /** id объекта */
  summ_fact_oper_september_min?: Maybe<Scalars['String']>;
  /** id объекта */
  year?: Maybe<Scalars['Int']>;
};

/** История закупа по городу  */
export type OohStatDiscountCity = {
  __typename?: 'OohStatDiscountCity';
  /** id объекта */
  ConstrType?: Maybe<Scalars['String']>;
  /** id объекта */
  ConstrType_ID?: Maybe<Scalars['Int']>;
  /** id объекта */
  aoguid?: Maybe<Scalars['String']>;
  /** id объекта */
  cityName?: Maybe<Scalars['String']>;
  /** id объекта */
  dateEnd?: Maybe<Scalars['DateTime']>;
  /** id объекта */
  dateStart?: Maybe<Scalars['DateTime']>;
  /** id объекта */
  discountAvg?: Maybe<Scalars['Float']>;
  /** id объекта */
  discountMax?: Maybe<Scalars['Float']>;
  /** id объекта */
  discountMin?: Maybe<Scalars['Float']>;
  /** id объекта */
  monthName?: Maybe<Scalars['String']>;
  /** id объекта */
  sideType?: Maybe<Scalars['String']>;
  /** id объекта */
  sideTypeId?: Maybe<Scalars['Int']>;
  /** id объекта */
  year?: Maybe<Scalars['String']>;
};

/** История закупа медиасети  */
export type OohStatDiscountMediaNetwork = {
  __typename?: 'OohStatDiscountMediaNetwork';
  /** id объекта */
  City_Name?: Maybe<Scalars['String']>;
  /** id объекта */
  ConstrType?: Maybe<Scalars['String']>;
  /** id объекта */
  ConstrType_ID?: Maybe<Scalars['Int']>;
  /** id объекта */
  aoguid?: Maybe<Scalars['String']>;
  /** id объекта */
  dateEnd?: Maybe<Scalars['DateTime']>;
  /** id объекта */
  dateStart?: Maybe<Scalars['DateTime']>;
  /** id объекта */
  discountAvg?: Maybe<Scalars['Float']>;
  /** id объекта */
  discountMax?: Maybe<Scalars['Float']>;
  /** id объекта */
  discountMin?: Maybe<Scalars['Float']>;
  /** id объекта */
  mediaNetworkName?: Maybe<Scalars['String']>;
  /** id объекта */
  mediaNetwork_ID?: Maybe<Scalars['Int']>;
  /** id объекта */
  monthName?: Maybe<Scalars['String']>;
  /** id объекта */
  sideType?: Maybe<Scalars['String']>;
  /** id объекта */
  sideTypeId?: Maybe<Scalars['Int']>;
  /** id объекта */
  year?: Maybe<Scalars['String']>;
};

/** Статистика по заполенности наружки */
export type OohStatistic = {
  __typename?: 'OohStatistic';
  ConstrTypeDetail?: Maybe<Array<Maybe<OohStatisticDetailConstrType>>>;
  ConstrTypeTotal?: Maybe<Array<Maybe<OohStatisticTotalConstrType>>>;
  MediaNetworkDetail?: Maybe<Array<Maybe<OohStatisticDetailMediaNetwork>>>;
  coords?: Maybe<Scalars['Int']>;
  /** id - уникальный идентификатор записи */
  fias_aoguid?: Maybe<Scalars['String']>;
  /** id - уникальный идентификатор записи */
  id?: Maybe<Scalars['String']>;
  medianetworks_count?: Maybe<Scalars['Int']>;
  photos?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
};

/** АП OOH */
export type OohStatisticDetailConstrType = {
  __typename?: 'OohStatisticDetailConstrType';
  /** id - уникальный идентификатор записи */
  constrType?: Maybe<Scalars['Int']>;
  constrTypeName?: Maybe<Scalars['String']>;
  count?: Maybe<Scalars['Int']>;
  lastDataUpdate?: Maybe<Scalars['DateTime']>;
  lastDataUpdateCount?: Maybe<Scalars['Int']>;
  mediaNetwork?: Maybe<Scalars['Int']>;
  mediaNetworkName?: Maybe<Scalars['String']>;
  proc?: Maybe<Scalars['Float']>;
};

/** АП OOH */
export type OohStatisticDetailMediaNetwork = {
  __typename?: 'OohStatisticDetailMediaNetwork';
  fias_aoguid?: Maybe<Scalars['String']>;
  lastDataUpdate?: Maybe<Scalars['DateTime']>;
  mediaNetwork?: Maybe<Scalars['Int']>;
  procInCity?: Maybe<Scalars['Float']>;
};

/** АП OOH */
export type OohStatisticTotalConstrType = {
  __typename?: 'OohStatisticTotalConstrType';
  avgPrice?: Maybe<Scalars['Float']>;
  /** id - уникальный идентификатор записи */
  constrType?: Maybe<Scalars['Int']>;
  fias_aoguid?: Maybe<Scalars['String']>;
};

/** Тактики подбора */
export type OohTactics = {
  __typename?: 'OohTactics';
  /** id названия конструкции */
  id?: Maybe<Scalars['String']>;
  /** Наменование конструкции */
  name?: Maybe<Scalars['String']>;
};

/** Загрузка данных */
export type OptimizedBlockInput = {
  /** TRP */
  TRP?: Maybe<Scalars['Float']>;
  /** Дата блока */
  blockDate?: Maybe<Scalars['Int']>;
  /** ID Блока */
  blockID?: Maybe<Scalars['Int']>;
  /** ID роликов */
  films?: Maybe<Array<Maybe<Scalars['Int']>>>;
};


/** Входные параметры для получения списка объектов. */
export type ParamsInput = {
  /** Сколько записей нужно получить */
  count?: Maybe<Scalars['Int']>;
  filter?: Maybe<Array<Maybe<FiltersInput>>>;
  /** Количество записей, которое нужно отступить */
  offset?: Maybe<Scalars['Int']>;
};

/** Элемент таргетинга */
export type PlatformTargeting = {
  __typename?: 'PlatformTargeting';
  /** коэффициент */
  coefficient?: Maybe<Scalars['Float']>;
  /** ID объекта */
  id?: Maybe<Scalars['ID']>;
  /** Применяемость к инвентарю */
  inventoryType?: Maybe<Scalars['String']>;
  /** имя таргетинга */
  name?: Maybe<Scalars['String']>;
  /** ID платформы */
  platform_id?: Maybe<Scalars['Int']>;
};

/** Загрузка данных элемента таргетинга */
export type PlatformTargetingInput = {
  /** коэффициент */
  coefficient?: Maybe<Scalars['Float']>;
  /** ID объекта */
  id?: Maybe<Scalars['ID']>;
  /** Применяемость к инвентарю */
  inventoryType?: Maybe<Scalars['String']>;
  /** имя таргетинга */
  name?: Maybe<Scalars['String']>;
  /** ID платформы */
  platform_id?: Maybe<Scalars['Int']>;
  temp_id?: Maybe<Scalars['String']>;
};

/** условия ценовые */
export type PriceCondition = {
  __typename?: 'PriceCondition';
  /** id ценового условия */
  id?: Maybe<Scalars['Int']>;
  /** условие ценовое */
  priceCondition?: Maybe<Scalars['String']>;
};

/** тип цены */
export type PriceType = {
  __typename?: 'PriceType';
  /** id типа цены */
  id?: Maybe<Scalars['Int']>;
  /** тип цены */
  priceType?: Maybe<Scalars['String']>;
};

/** Элемент прайма справочника */
export type Prime = {
  __typename?: 'Prime';
  /** праздники */
  holidays?: Maybe<Scalars['Boolean']>;
  /** Время от */
  id?: Maybe<Scalars['String']>;
  /** Время от */
  timeFrom?: Maybe<Scalars['String']>;
  /** Время до */
  timeTo?: Maybe<Scalars['String']>;
  /** будни */
  weekdays?: Maybe<Scalars['Boolean']>;
  /** выходные */
  weekend?: Maybe<Scalars['Boolean']>;
};

/** Рекламный блок */
export type ProgramBreak = {
  __typename?: 'ProgramBreak';
  /** ?? */
  allocatedFloatingVolume?: Maybe<Scalars['Int']>;
  /** ?? */
  aucRate?: Maybe<Scalars['Int']>;
  /** ?? */
  auctionState?: Maybe<Scalars['Int']>;
  /** ?? */
  auctionStepPrice?: Maybe<Scalars['Int']>;
  /** ?? */
  availableAuctionVolume?: Maybe<Scalars['Int']>;
  /** ?? */
  blkAdvertTypePTR?: Maybe<Scalars['Int']>;
  /**
   * Признак, аукциона. 0 = аукциона нет, блок не аукционный, 1 = аукцион
   * планируется, 2 = аукцион действует, 3 = аукцион закончился (byte, not nillable)
   */
  blkAuc?: Maybe<Scalars['Int']>;
  /** ?? */
  blkOpen?: Maybe<Scalars['Int']>;
  /** ?? */
  blkOpenReserv?: Maybe<Scalars['Int']>;
  /** Дата блока */
  blockDate?: Maybe<Scalars['String']>;
  /** ?? */
  blockDistr?: Maybe<Scalars['Int']>;
  /** ?? */
  blockDur?: Maybe<Scalars['Int']>;
  /** ID ?? */
  blockID?: Maybe<Scalars['Int']>;
  /** ?? */
  blockNumber?: Maybe<Scalars['Int']>;
  /** ?? */
  blockTime?: Maybe<Scalars['Int']>;
  /** ?? */
  blockType?: Maybe<Scalars['Int']>;
  /** ?? */
  booked?: Maybe<Scalars['Int']>;
  /** ID ?? */
  cnlID?: Maybe<Scalars['Int']>;
  /** ?? */
  cnlName?: Maybe<Scalars['String']>;
  /** Deadline date */
  dlDate?: Maybe<Scalars['String']>;
  /** ?? */
  dlTrDate?: Maybe<Scalars['String']>;
  /** ?? */
  factRateBase?: Maybe<Scalars['Int']>;
  /** Медиаплан */
  film?: Maybe<MPlan>;
  /** GRP блока */
  forecastRateBase?: Maybe<Scalars['Float']>;
  /** Баинговый GRP */
  grpBa?: Maybe<Scalars['Float']>;
  /** ?? */
  isLocal?: Maybe<Scalars['Int']>;
  /** Прайм-блок */
  isPrime?: Maybe<Scalars['Int']>;
  /** ?? */
  issDuration?: Maybe<Scalars['Int']>;
  /** ID ?? */
  issID?: Maybe<Scalars['Int']>;
  /** ?? */
  issTime?: Maybe<Scalars['Int']>;
  /** ?? */
  noRating?: Maybe<Scalars['Int']>;
  /** ?? */
  prgBegMonthL?: Maybe<Scalars['Int']>;
  /** ?? */
  prgBegTimeL?: Maybe<Scalars['Int']>;
  /** ?? */
  prgEndMonthL?: Maybe<Scalars['Int']>;
  /** ?? */
  prgName?: Maybe<Scalars['String']>;
  /** ?? */
  prgNameShort?: Maybe<Scalars['String']>;
  /** ?? */
  pro2?: Maybe<Scalars['Int']>;
  /** ?? */
  proID?: Maybe<Scalars['Int']>;
  /** ?? */
  proOriginalPTR?: Maybe<Scalars['Int']>;
  /** ID ?? */
  progID?: Maybe<Scalars['Int']>;
  /** ?? */
  rateAll?: Maybe<Scalars['Int']>;
  /** ID ?? */
  rcid?: Maybe<Scalars['Int']>;
  /** ?? */
  reserveVol?: Maybe<Scalars['Int']>;
  /** ?? */
  rootRCID?: Maybe<Scalars['Int']>;
  /** ?? */
  rootRPID?: Maybe<Scalars['Int']>;
  /** ID ?? */
  rpid?: Maybe<Scalars['Int']>;
  /** ?? */
  simpleFixVolume?: Maybe<Scalars['Int']>;
  /** ?? */
  spotBan?: Maybe<Scalars['Int']>;
  /** List of Спот размещения */
  spots?: Maybe<Array<Maybe<Spot>>>;
  /** ?? */
  spotsVolume?: Maybe<Scalars['Int']>;
  /** ?? */
  sptOptions?: Maybe<Scalars['Int']>;
  /** ?? */
  tgrID?: Maybe<Scalars['Int']>;
  /** ?? */
  tgrName?: Maybe<Scalars['String']>;
  /** ?? */
  tnsBlockFactDur?: Maybe<Scalars['Int']>;
  /** ?? */
  tnsBlockFactID?: Maybe<Scalars['Int']>;
  /** ?? */
  tnsBlockFactTime?: Maybe<Scalars['Int']>;
  /** TRP */
  trp?: Maybe<Scalars['Float']>;
  /** ?? */
  weekDay?: Maybe<Scalars['Int']>;
};

/** Оптимизированные блоки */
export type ProgramBreakOptimized = {
  __typename?: 'ProgramBreakOptimized';
  /** TRP */
  TRP?: Maybe<Scalars['Float']>;
  /** Дата блока */
  blockDate?: Maybe<Scalars['Int']>;
  /** ID Блока */
  blockID?: Maybe<Scalars['Int']>;
  /** ID роликов */
  films?: Maybe<Array<Maybe<Scalars['Int']>>>;
};

/** АП */
export type PubAp = {
  __typename?: 'PubAP';
  /** Карт */
  APObjects?: Maybe<Array<Maybe<OohConstruction>>>;
  /** Список конструкций в корзине */
  ApBasketConstructionInfo?: Maybe<Array<Maybe<BasketConstructionPeriod>>>;
  /** АП OOH */
  ApInfo?: Maybe<OohAp>;
  /** Карт */
  ClientObjects?: Maybe<Array<Maybe<OohapClientObject>>>;
};


/** АП */
export type PubApApObjectsArgs = {
  hash?: Maybe<Scalars['String']>;
};


/** АП */
export type PubApApBasketConstructionInfoArgs = {
  hash?: Maybe<Scalars['String']>;
  object_id?: Maybe<Scalars['String']>;
};


/** АП */
export type PubApApInfoArgs = {
  hash?: Maybe<Scalars['String']>;
};


/** АП */
export type PubApClientObjectsArgs = {
  hash?: Maybe<Scalars['String']>;
};

/** Стандартный объект */
export type PubQuery = {
  __typename?: 'PubQuery';
  /** АП */
  AP?: Maybe<PubAp>;
};

export type PythonResponse = ProgramBreakOptimized | VimbPythonMessage;

/** Тип конструкции */
export type QueriesMlConstructionType = {
  __typename?: 'QueriesMlConstructionType';
  /** Наменование конструкции */
  ConstrTypeShort?: Maybe<Scalars['String']>;
  /** id названия конструкции */
  id?: Maybe<Scalars['Int']>;
  /** Наменование конструкции */
  name?: Maybe<Scalars['String']>;
};

/** Стандартный объект */
export type Query = {
  __typename?: 'Query';
  /** Группа комона */
  ComonGroup?: Maybe<ComonGroupItem>;
  /** Массив пользователей */
  ComonGroupList?: Maybe<Array<Maybe<ComonGroupItem>>>;
  /** Сотрудник компании */
  ComonUserAvailable?: Maybe<RefEmployee>;
  /** Бриф контекстной рекламы */
  DigitalBriefingContext?: Maybe<DigitalBriefingContext>;
  /** Массив DigitalBriefingContextType */
  DigitalBriefingContextList?: Maybe<Array<Maybe<DigitalBriefingContext>>>;
  /** Массив DigitalBriefingContextType */
  DigitalBriefingContextListPager?: Maybe<ListWithPager_DigitalBriefingContext>;
  /** корневой объект ГЕО-модуля */
  Geo?: Maybe<Geo>;
  /** Группа */
  Group?: Maybe<GroupItem>;
  /** Доступ */
  GroupAccess?: Maybe<GroupAccessItem>;
  /** Доступ */
  GroupAccessList?: Maybe<Array<Maybe<GroupAccessItem>>>;
  /** Массив RnGroup */
  GroupList?: Maybe<Array<Maybe<GroupItem>>>;
  /** Массив RnMpType */
  GroupListPager?: Maybe<ListWithPager_GroupItem>;
  /** Массив RnMpItem */
  LogList?: Maybe<ListWithPager_ItemLog>;
  /** Стандартный объект */
  ML?: Maybe<MlQuery>;
  /** Бренд / Торговая марка */
  MLBrand?: Maybe<MlBrand>;
  /** Бренд / Торговая марка */
  MLBrandList?: Maybe<Array<Maybe<MlBrand>>>;
  /** Юридические лица Дельтаплана */
  MLDpUrFaces?: Maybe<MlDpUrFaces>;
  /** Юридические лица Дельтаплана */
  MLDpUrFacesList?: Maybe<Array<Maybe<MlDpUrFaces>>>;
  /** партнёры */
  MLPartner?: Maybe<MlPartner>;
  /** партнёры */
  MLPartnerList?: Maybe<Array<Maybe<MlPartner>>>;
  /** Список типов целевой аудитории */
  MLTargetAudience?: Maybe<MlTargetAudience>;
  /** Список типов целевой аудитории */
  MLTargetAudienceList?: Maybe<Array<Maybe<MlTargetAudience>>>;
  /** Город РФ */
  MlCity?: Maybe<MlCity>;
  /** Город РФ */
  MlCityList?: Maybe<Array<Maybe<MlCity>>>;
  /** Тип конструкции */
  MlConstructionType?: Maybe<MlConstructionType>;
  /** Тип конструкции */
  MlConstructionTypeList?: Maybe<Array<Maybe<MlConstructionType>>>;
  /** Список зон покрытия */
  MlCoverage?: Maybe<MlCoverage>;
  /** Список зон покрытия */
  MlCoverageList?: Maybe<Array<Maybe<MlCoverage>>>;
  /** Список медиагрупп */
  MlMediaGroup?: Maybe<MlMediaGroups>;
  /** Список медиагрупп */
  MlMediaGroupList?: Maybe<Array<Maybe<MlMediaGroups>>>;
  /** Список медиагрупп */
  MlMediaType?: Maybe<MlMediaType>;
  /** Список медиагрупп */
  MlMediaTypeList?: Maybe<Array<Maybe<MlMediaType>>>;
  /** ОКЕИ */
  MlOKEI?: Maybe<MlOkei>;
  /** ОКЕИ */
  MlOKEIList?: Maybe<Array<Maybe<MlOkei>>>;
  /** Справочник ОКСМ */
  MlOKSM?: Maybe<MlOksm>;
  /** Справочник ОКСМ */
  MlOKSMList?: Maybe<Array<Maybe<MlOksm>>>;
  /** Справочник ОКВ */
  MlOKV?: Maybe<MlOkv>;
  /** Справочник ОКВ */
  MlOKVList?: Maybe<Array<Maybe<MlOkv>>>;
  /** Объект привязки для адресной программы */
  MlPlacementObject?: Maybe<MlPlacementObject>;
  /** Объект привязки для адресной программы */
  MlPlacementObjectList?: Maybe<Array<Maybe<MlPlacementObject>>>;
  /** Регион РФ */
  MlRegion?: Maybe<MlRegion>;
  /** Регион РФ */
  MlRegionList?: Maybe<Array<Maybe<MlRegion>>>;
  /** Тематика */
  MlThematics?: Maybe<MlThematics>;
  /** Тематика */
  MlThematicsList?: Maybe<Array<Maybe<MlThematics>>>;
  /** Стандартный объект */
  OOH?: Maybe<OohQuery>;
  /** Тактики подбора */
  OohTactics?: Maybe<OohTactics>;
  /** Тактики подбора */
  OohTacticsList?: Maybe<Array<Maybe<OohTactics>>>;
  /** условия ценовые */
  PriceCondition?: Maybe<PriceCondition>;
  /** условия ценовые */
  PriceConditionList?: Maybe<Array<Maybe<PriceCondition>>>;
  /** тип цены */
  PriceType?: Maybe<PriceType>;
  /** тип цены */
  PriceTypeList?: Maybe<Array<Maybe<PriceType>>>;
  /** Стандартный объект */
  Pub?: Maybe<PubQuery>;
  /** Стандартный объект */
  RN?: Maybe<RnQuery>;
  /** Сотрудник компании */
  RefEmployee?: Maybe<RefEmployee>;
  /** Сотрудник компании */
  RefEmployeeList?: Maybe<Array<Maybe<RefEmployee>>>;
  /** Бриф региональных площадок */
  RnBriefing?: Maybe<RnBriefing>;
  /** Массив RnBriefingType */
  RnBriefingList?: Maybe<Array<Maybe<RnBriefing>>>;
  /** Массив RnBriefingType */
  RnBriefingListPager?: Maybe<ListWithPager_RnBriefing>;
  /** Элемент инвентаря со всеми параметрами */
  RnInventory?: Maybe<RnInventoryItem>;
  /** Список инвентаря с информацией по общему количеству в базе */
  RnInventoryList?: Maybe<ListWithPager_RnInventoryItem>;
  /** Страница сайта инвентаря */
  RnInventoryPages?: Maybe<RnInventoryPage>;
  /** Страница сайта инвентаря */
  RnInventoryPagesList?: Maybe<Array<Maybe<RnInventoryPage>>>;
  /** Вид таргетинга */
  RnInventoryTargeting?: Maybe<RnInventoryTargeting>;
  /** Вид таргетинга */
  RnInventoryTargetingList?: Maybe<Array<Maybe<RnInventoryTargeting>>>;
  /** Медиаплан */
  RnMp?: Maybe<RnMp>;
  /** Выгрузка данных в эксель, аргумент - id медиаплана, возврат - файл эксель в виде строки base64 */
  RnMpExcelExport?: Maybe<Scalars['String']>;
  /** Элемент медиаплана/ строчка */
  RnMpItem?: Maybe<RnMpItem>;
  /** Рассчетные данные строки медиаплана */
  RnMpItemInfo?: Maybe<RnMpItemInfo>;
  /** Массив RnMpItem */
  RnMpItemList?: Maybe<Array<Maybe<RnMpItem>>>;
  /** Массив RnMpType */
  RnMpList?: Maybe<Array<Maybe<RnMp>>>;
  /** Массив RnMpType */
  RnMpListPager?: Maybe<ListWithPager_RnMp>;
  /** Информация о площадке */
  RnPlatform?: Maybe<RnPlatform>;
  /** Информация о площадке */
  RnPlatformItem?: Maybe<RnPlatform>;
  /** Список офферов с информацией по общему количеству в базе, информация отдельно, данные отдельно */
  RnPlatformList?: Maybe<ListWithPager_RnPlatform>;
  /** прайс */
  RnPrice?: Maybe<RnPrice>;
  /** Методы для тестирования типов */
  Tests?: Maybe<Tests>;
  /** Топ байер */
  TopBuyer?: Maybe<UserProfile>;
  /** Информация о пользователе */
  User?: Maybe<UserProfile>;
  /** Массив пользователей */
  UserList?: Maybe<Array<Maybe<UserProfile>>>;
  /** С НДС или без */
  Vat?: Maybe<Vat>;
  /** С НДС или без */
  VatList?: Maybe<Array<Maybe<Vat>>>;
  /** Уровень НДС */
  VatRate?: Maybe<VatRate>;
  /** Уровень НДС */
  VatRateList?: Maybe<Array<Maybe<VatRate>>>;
  /** Стандартный объект */
  Vimb?: Maybe<VimbQuery>;
  /** Return allowed endpoints */
  accessEndpoints?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Return token access */
  auth?: Maybe<Scalars['String']>;
  /** Очистка сесии */
  logout?: Maybe<Scalars['Boolean']>;
  /** Информация о пользователе */
  me?: Maybe<UserProfile>;
  /** Юзер */
  systemUsers?: Maybe<RnUser>;
  /** Юзер */
  systemUsersList?: Maybe<Array<Maybe<RnUser>>>;
};


/** Стандартный объект */
export type QueryComonGroupArgs = {
  id: Scalars['Int'];
};


/** Стандартный объект */
export type QueryComonGroupListArgs = {
  params?: Maybe<ParamsInput>;
};


/** Стандартный объект */
export type QueryComonUserAvailableArgs = {
  id: Scalars['Int'];
};


/** Стандартный объект */
export type QueryDigitalBriefingContextArgs = {
  id: Scalars['Int'];
};


/** Стандартный объект */
export type QueryDigitalBriefingContextListArgs = {
  params?: Maybe<ParamsInput>;
};


/** Стандартный объект */
export type QueryDigitalBriefingContextListPagerArgs = {
  params?: Maybe<ParamsInput>;
};


/** Стандартный объект */
export type QueryGroupArgs = {
  id: Scalars['Int'];
};


/** Стандартный объект */
export type QueryGroupAccessArgs = {
  id: Scalars['Int'];
};


/** Стандартный объект */
export type QueryGroupAccessListArgs = {
  params?: Maybe<ParamsInput>;
};


/** Стандартный объект */
export type QueryGroupListArgs = {
  params?: Maybe<ParamsInput>;
};


/** Стандартный объект */
export type QueryGroupListPagerArgs = {
  params?: Maybe<ParamsInput>;
};


/** Стандартный объект */
export type QueryLogListArgs = {
  params?: Maybe<ParamsInput>;
  type?: Maybe<Scalars['String']>;
};


/** Стандартный объект */
export type QueryMlBrandArgs = {
  id: Scalars['Int'];
};


/** Стандартный объект */
export type QueryMlBrandListArgs = {
  params?: Maybe<ParamsInput>;
};


/** Стандартный объект */
export type QueryMlDpUrFacesArgs = {
  id: Scalars['Int'];
};


/** Стандартный объект */
export type QueryMlDpUrFacesListArgs = {
  params?: Maybe<ParamsInput>;
};


/** Стандартный объект */
export type QueryMlPartnerArgs = {
  id: Scalars['Int'];
};


/** Стандартный объект */
export type QueryMlPartnerListArgs = {
  params?: Maybe<ParamsInput>;
};


/** Стандартный объект */
export type QueryMlTargetAudienceArgs = {
  id: Scalars['Int'];
};


/** Стандартный объект */
export type QueryMlTargetAudienceListArgs = {
  params?: Maybe<ParamsInput>;
};


/** Стандартный объект */
export type QueryMlCityArgs = {
  id: Scalars['Int'];
};


/** Стандартный объект */
export type QueryMlCityListArgs = {
  params?: Maybe<ParamsInput>;
};


/** Стандартный объект */
export type QueryMlConstructionTypeArgs = {
  id: Scalars['Int'];
};


/** Стандартный объект */
export type QueryMlConstructionTypeListArgs = {
  params?: Maybe<ParamsInput>;
};


/** Стандартный объект */
export type QueryMlCoverageArgs = {
  id: Scalars['Int'];
};


/** Стандартный объект */
export type QueryMlCoverageListArgs = {
  params?: Maybe<ParamsInput>;
};


/** Стандартный объект */
export type QueryMlMediaGroupArgs = {
  id: Scalars['Int'];
};


/** Стандартный объект */
export type QueryMlMediaGroupListArgs = {
  params?: Maybe<ParamsInput>;
};


/** Стандартный объект */
export type QueryMlMediaTypeArgs = {
  id: Scalars['Int'];
};


/** Стандартный объект */
export type QueryMlMediaTypeListArgs = {
  params?: Maybe<ParamsInput>;
};


/** Стандартный объект */
export type QueryMlOkeiArgs = {
  id: Scalars['Int'];
};


/** Стандартный объект */
export type QueryMlOkeiListArgs = {
  params?: Maybe<ParamsInput>;
};


/** Стандартный объект */
export type QueryMlOksmArgs = {
  id: Scalars['Int'];
};


/** Стандартный объект */
export type QueryMlOksmListArgs = {
  params?: Maybe<ParamsInput>;
};


/** Стандартный объект */
export type QueryMlOkvArgs = {
  id: Scalars['Int'];
};


/** Стандартный объект */
export type QueryMlOkvListArgs = {
  params?: Maybe<ParamsInput>;
};


/** Стандартный объект */
export type QueryMlPlacementObjectArgs = {
  id: Scalars['Int'];
};


/** Стандартный объект */
export type QueryMlPlacementObjectListArgs = {
  params?: Maybe<ParamsInput>;
};


/** Стандартный объект */
export type QueryMlRegionArgs = {
  id: Scalars['Int'];
};


/** Стандартный объект */
export type QueryMlRegionListArgs = {
  params?: Maybe<ParamsInput>;
};


/** Стандартный объект */
export type QueryMlThematicsArgs = {
  id: Scalars['Int'];
};


/** Стандартный объект */
export type QueryMlThematicsListArgs = {
  params?: Maybe<ParamsInput>;
};


/** Стандартный объект */
export type QueryOohTacticsArgs = {
  id: Scalars['Int'];
};


/** Стандартный объект */
export type QueryOohTacticsListArgs = {
  params?: Maybe<ParamsInput>;
};


/** Стандартный объект */
export type QueryPriceConditionArgs = {
  id: Scalars['Int'];
};


/** Стандартный объект */
export type QueryPriceConditionListArgs = {
  params?: Maybe<ParamsInput>;
};


/** Стандартный объект */
export type QueryPriceTypeArgs = {
  id: Scalars['Int'];
};


/** Стандартный объект */
export type QueryPriceTypeListArgs = {
  params?: Maybe<ParamsInput>;
};


/** Стандартный объект */
export type QueryRefEmployeeArgs = {
  id: Scalars['Int'];
};


/** Стандартный объект */
export type QueryRefEmployeeListArgs = {
  params?: Maybe<ParamsInput>;
};


/** Стандартный объект */
export type QueryRnBriefingArgs = {
  id: Scalars['Int'];
};


/** Стандартный объект */
export type QueryRnBriefingListArgs = {
  params?: Maybe<ParamsInput>;
};


/** Стандартный объект */
export type QueryRnBriefingListPagerArgs = {
  params?: Maybe<ParamsInput>;
};


/** Стандартный объект */
export type QueryRnInventoryArgs = {
  id?: Maybe<Scalars['Int']>;
};


/** Стандартный объект */
export type QueryRnInventoryListArgs = {
  params?: Maybe<ParamsInput>;
};


/** Стандартный объект */
export type QueryRnInventoryPagesArgs = {
  id: Scalars['Int'];
};


/** Стандартный объект */
export type QueryRnInventoryPagesListArgs = {
  params?: Maybe<ParamsInput>;
};


/** Стандартный объект */
export type QueryRnInventoryTargetingArgs = {
  id: Scalars['Int'];
};


/** Стандартный объект */
export type QueryRnInventoryTargetingListArgs = {
  params?: Maybe<ParamsInput>;
};


/** Стандартный объект */
export type QueryRnMpArgs = {
  id: Scalars['Int'];
};


/** Стандартный объект */
export type QueryRnMpExcelExportArgs = {
  mpId?: Maybe<Scalars['Int']>;
};


/** Стандартный объект */
export type QueryRnMpItemArgs = {
  id: Scalars['Int'];
};


/** Стандартный объект */
export type QueryRnMpItemInfoArgs = {
  id: Scalars['Int'];
};


/** Стандартный объект */
export type QueryRnMpItemListArgs = {
  params?: Maybe<ParamsInput>;
};


/** Стандартный объект */
export type QueryRnMpListArgs = {
  params?: Maybe<ParamsInput>;
};


/** Стандартный объект */
export type QueryRnMpListPagerArgs = {
  params?: Maybe<ParamsInput>;
};


/** Стандартный объект */
export type QueryRnPlatformArgs = {
  id?: Maybe<Scalars['Int']>;
};


/** Стандартный объект */
export type QueryRnPlatformItemArgs = {
  id?: Maybe<Scalars['Int']>;
};


/** Стандартный объект */
export type QueryRnPlatformListArgs = {
  params?: Maybe<ParamsInput>;
};


/** Стандартный объект */
export type QueryRnPriceArgs = {
  id?: Maybe<Scalars['Int']>;
};


/** Стандартный объект */
export type QueryUserArgs = {
  id: Scalars['Int'];
};


/** Стандартный объект */
export type QueryUserListArgs = {
  params?: Maybe<ParamsInput>;
};


/** Стандартный объект */
export type QueryVatArgs = {
  id: Scalars['Int'];
};


/** Стандартный объект */
export type QueryVatListArgs = {
  params?: Maybe<ParamsInput>;
};


/** Стандартный объект */
export type QueryVatRateArgs = {
  id: Scalars['Int'];
};


/** Стандартный объект */
export type QueryVatRateListArgs = {
  params?: Maybe<ParamsInput>;
};


/** Стандартный объект */
export type QueryAuthArgs = {
  login: Scalars['String'];
  password: Scalars['String'];
};


/** Стандартный объект */
export type QuerySystemUsersArgs = {
  id: Scalars['Int'];
};


/** Стандартный объект */
export type QuerySystemUsersListArgs = {
  params?: Maybe<ParamsInput>;
};

/** Сотрудник компании */
export type RefEmployee = {
  __typename?: 'RefEmployee';
  /** id */
  id?: Maybe<Scalars['String']>;
  /** Имя */
  name?: Maybe<Scalars['String']>;
  /** Имя Фамилия */
  nameFull?: Maybe<Scalars['String']>;
};

/** страница инвентаря */
export type RefRnInventoryPage = {
  __typename?: 'RefRnInventoryPage';
  /** ID */
  id?: Maybe<Scalars['ID']>;
  /** name */
  name?: Maybe<Scalars['String']>;
};

/** Бриф региональных площадок */
export type RnBriefing = {
  __typename?: 'RnBriefing';
  /** Бренд / Торговая марка */
  Brand?: Maybe<MlBrand>;
  /** Город РФ */
  Cities?: Maybe<Array<Maybe<MlCity>>>;
  /** Группа комона */
  ComonGroup?: Maybe<ComonGroupItem>;
  /** Географические данные площадки */
  FederalDistricts?: Maybe<Array<Maybe<RnBriefingGeoDistrict>>>;
  /** Медиаплан */
  MP?: Maybe<RnMp>;
  /** Сотрудник компании */
  Manager?: Maybe<RefEmployee>;
  /** партнёры */
  Partner?: Maybe<MlPartner>;
  /** Сотрудник компании */
  Responsible?: Maybe<RefEmployee>;
  /** Юридические лица Дельтаплана */
  UrFace_nds?: Maybe<MlDpUrFaces>;
  /** Юридические лица Дельтаплана */
  UrFace_nonds?: Maybe<MlDpUrFaces>;
  /** Список соисполнителей */
  Users?: Maybe<Array<Maybe<RefEmployee>>>;
  /** Цель рекламной кампании */
  advTask?: Maybe<Scalars['Int']>;
  /** Другая цель рекламной кампании */
  advTaskOther?: Maybe<Scalars['String']>;
  /** Уникальное торговое предожение клиента */
  advantages?: Maybe<Scalars['String']>;
  /**
   * Id - идентификатор возрастной группы целевой аудитории
   * @deprecated данное поле не действительно
   */
  ageRang_id?: Maybe<Scalars['Int']>;
  /** массив Id - идентификаторов возрастной группы целевой аудитории */
  ageRanges?: Maybe<Scalars['ListOfInt']>;
  /** Агентское вознаграждение */
  agentCommission?: Maybe<Scalars['Int']>;
  /** АВ выносим отдельно или включаем в стоимость клика? (in/over) */
  agentCommissionPosition?: Maybe<Scalars['String']>;
  /** позиция агентского вознаграждения */
  agent_commission_position?: Maybe<Scalars['Int']>;
  /** сумма ав */
  agent_commission_value?: Maybe<Scalars['Float']>;
  /** тип значения ав */
  agent_commission_value_type?: Maybe<Scalars['Int']>;
  /** Комментарий по целевой аудитории */
  audienceComment?: Maybe<Scalars['String']>;
  /** Баннер */
  banner?: Maybe<Scalars['Int']>;
  /** id - уникальный идентификатор бренда */
  brand_id?: Maybe<Scalars['Int']>;
  /** размещение карточек */
  cards?: Maybe<Scalars['Int']>;
  /** Города РК */
  cities?: Maybe<Scalars['String']>;
  /** Количество кликов */
  clicks?: Maybe<Scalars['Int']>;
  /** Число для отсрочки */
  comon_group_id?: Maybe<Scalars['Int']>;
  /** Конкуренты клиента */
  competitors?: Maybe<Scalars['String']>;
  /** id типа договора */
  contract_type?: Maybe<Scalars['Int']>;
  /** Дата конца рекламной кампании */
  dateEnd?: Maybe<Scalars['Date']>;
  /** Дата начала рекламной кампании */
  dateStart?: Maybe<Scalars['Date']>;
  /** Deadline для отдела контекста */
  deadline?: Maybe<Scalars['DateTime']>;
  /** Дата заполнения брифа */
  entryDate?: Maybe<Scalars['DateTime']>;
  /** комментарий по предыдущему опыту */
  experience_comment?: Maybe<Scalars['String']>;
  /** Федеральные ли площадки */
  federal?: Maybe<Scalars['Int']>;
  /** Файл */
  file?: Maybe<Array<Maybe<File>>>;
  /** Доступ к сервису Google Analytics */
  googleAnalytics?: Maybe<Scalars['Boolean']>;
  /** id - уникальный идентификатор записи */
  id?: Maybe<Scalars['Int']>;
  /** Ключевое сообщение */
  keyMessage?: Maybe<Scalars['String']>;
  /** Комментарий по KPI */
  kpiComment?: Maybe<Scalars['String']>;
  /** Тип KPI для рекламной кампании (traffic, action, sales) */
  kpiType?: Maybe<Scalars['String']>;
  /** Дата создания текущей версии */
  lastUpdate?: Maybe<Scalars['DateTime']>;
  /** id - уникальный идентификатор */
  legal_entity_novat?: Maybe<Scalars['Int']>;
  /** id - уникальный идентификатор */
  legal_entity_vat?: Maybe<Scalars['Int']>;
  /** id - уникальный идентификатор менеджера */
  manager_id?: Maybe<Scalars['Int']>;
  /** название брифа */
  name?: Maybe<Scalars['String']>;
  /** Размещение новости */
  news?: Maybe<Scalars['Int']>;
  /** Клиент без НДС */
  no_vat?: Maybe<Scalars['Int']>;
  /** id - уникальный идентификатор партнёра */
  partner_id?: Maybe<Scalars['Int']>;
  /** Тип острочки */
  payment_delay?: Maybe<Scalars['Int']>;
  /** Число для отсрочки */
  payment_delay_value?: Maybe<Scalars['Int']>;
  /** Продвигаемый продукт или услуги */
  products?: Maybe<Scalars['String']>;
  /** Необходимость разработки рекламных материалов (0/1) */
  promotionalMaterial?: Maybe<Scalars['Boolean']>;
  /** Пожелания и рекомендации */
  recommends?: Maybe<Scalars['String']>;
  /** Причина отказа */
  refusal?: Maybe<Scalars['Int']>;
  /** Описание причины отказа */
  refusal_description?: Maybe<Scalars['String']>;
  /** id - уникальный идентификатор ответственного лица */
  responsible_id?: Maybe<Scalars['Int']>;
  /** Пол целевой аудитории (f,m,fm) */
  sex?: Maybe<Scalars['String']>;
  /** спецпроект */
  special?: Maybe<Scalars['Int']>;
  /** Идентификатор записи */
  status?: Maybe<Scalars['Int']>;
  /** Комментарий по целевой аудитории */
  target_audience?: Maybe<Scalars['String']>;
  /** id задачи в комоне */
  taskId?: Maybe<Scalars['Int']>;
  /** Идентификатор задания */
  task_id?: Maybe<Scalars['Int']>;
  /** Тендер (0/1) */
  tender?: Maybe<Scalars['Int']>;
  /** Дата deadline тендера */
  tender_deadline?: Maybe<Scalars['DateTime']>;
  /** Бюджет в т.ч. НДС на весь период */
  totalBudget?: Maybe<Scalars['Int']>;
  /** Включено ли агентское вознаграждение в бюджет (0/1) */
  totalBudgetAcIn?: Maybe<Scalars['Boolean']>;
  /** Бюджет ОТ в т.ч. НДС на весь период */
  totalBudgetFrom?: Maybe<Scalars['Float']>;
  /** Бюджет ДО в т.ч. НДС на весь период */
  totalBudgetTo?: Maybe<Scalars['Float']>;
  /** Список ID соисполнителей */
  users?: Maybe<Array<Maybe<Scalars['Int']>>>;
  /** Клиент с НДС */
  vat?: Maybe<Scalars['Int']>;
  /** Версия, необходима для хранения изменённых копий. */
  version?: Maybe<Scalars['Boolean']>;
  /** URL сайта клиента */
  website?: Maybe<Scalars['String']>;
  /** Возможность внесения изменений на сайте клиента (0/1) */
  websiteChangeable?: Maybe<Scalars['Boolean']>;
  /** Доступ к сервису Яндекс Метрика */
  yandexMetrika?: Maybe<Scalars['Boolean']>;
};

/** Географические данные площадки */
export type RnBriefingGeoDistrict = {
  __typename?: 'RnBriefingGeoDistrict';
  /** Код */
  Code?: Maybe<Scalars['ID']>;
  /** Имя округа */
  FederalDistrict?: Maybe<Scalars['String']>;
  /** федеральные субъекты */
  FederalSubjects?: Maybe<Array<Maybe<RnBriefingGeoRegion>>>;
};

/** Регион РФ */
export type RnBriefingGeoRegion = {
  __typename?: 'RnBriefingGeoRegion';
  /** aoguid */
  AOGUID?: Maybe<Scalars['String']>;
  /** id целевой аудитории */
  FIAS_ID?: Maybe<Scalars['String']>;
  /** Наменование региона */
  FORMALNAME?: Maybe<Scalars['String']>;
  /** Список вложенных городов */
  Localities?: Maybe<Array<Maybe<MlCity>>>;
  /** OKATO */
  OKATO?: Maybe<Scalars['Int']>;
  /** короткое имя */
  SHORTNAME?: Maybe<Scalars['String']>;
};

/** Бриф региональных площадок */
export type RnBriefingInput = {
  /** ФО */
  FederalDistricts?: Maybe<Array<Maybe<FederalDistrictInput>>>;
  /** Юзеры */
  Users?: Maybe<Array<Maybe<EmployeeInput>>>;
  /** Цель рекламной кампании */
  advTask?: Maybe<Scalars['Int']>;
  /** Другая цель рекламной кампании */
  advTaskOther?: Maybe<Scalars['String']>;
  /** Уникальное торговое предожение клиента */
  advantages?: Maybe<Scalars['String']>;
  /** Id - идентификатор возрастной группы целевой аудитории */
  ageRang_id?: Maybe<Scalars['Int']>;
  /** массив Id - идентификаторов возрастной группы целевой аудитории */
  ageRanges?: Maybe<Scalars['ListOfInt']>;
  /** Агентское вознаграждение */
  agentCommission?: Maybe<Scalars['Int']>;
  /** АВ выносим отдельно или включаем в стоимость клика? (in/over) */
  agentCommissionPosition?: Maybe<Scalars['String']>;
  /** позиция агентского вознаграждения */
  agent_commission_position?: Maybe<Scalars['Int']>;
  /** сумма ав */
  agent_commission_value?: Maybe<Scalars['Float']>;
  /** тип значения ав */
  agent_commission_value_type?: Maybe<Scalars['Int']>;
  /** Комментарий по целевой аудитории */
  audienceComment?: Maybe<Scalars['String']>;
  /** Баннер */
  banner?: Maybe<Scalars['Int']>;
  /** id - уникальный идентификатор бренда */
  brand_id?: Maybe<Scalars['Int']>;
  /** размещение карточек */
  cards?: Maybe<Scalars['Int']>;
  /** Города РК */
  cities?: Maybe<Scalars['String']>;
  /** Количество кликов */
  clicks?: Maybe<Scalars['Int']>;
  /** Число для отсрочки */
  comon_group_id?: Maybe<Scalars['Int']>;
  /** Конкуренты клиента */
  competitors?: Maybe<Scalars['String']>;
  /** id типа договора */
  contract_type?: Maybe<Scalars['Int']>;
  /** Дата конца рекламной кампании */
  dateEnd?: Maybe<Scalars['Date']>;
  /** Дата начала рекламной кампании */
  dateStart?: Maybe<Scalars['Date']>;
  /** Deadline для отдела контекста */
  deadline?: Maybe<Scalars['DateTime']>;
  /** Дата заполнения брифа */
  entryDate?: Maybe<Scalars['DateTime']>;
  /** комментарий по предыдущему опыту */
  experience_comment?: Maybe<Scalars['String']>;
  /** Федеральные ли площадки */
  federal?: Maybe<Scalars['Int']>;
  /** id Файла */
  file?: Maybe<Array<Maybe<Scalars['Int']>>>;
  /** Доступ к сервису Google Analytics */
  googleAnalytics?: Maybe<Scalars['Boolean']>;
  /** id - уникальный идентификатор записи */
  id?: Maybe<Scalars['Int']>;
  /** Ключевое сообщение */
  keyMessage?: Maybe<Scalars['String']>;
  /** Комментарий по KPI */
  kpiComment?: Maybe<Scalars['String']>;
  /** Тип KPI для рекламной кампании (traffic, action, sales) */
  kpiType?: Maybe<Scalars['String']>;
  /** Дата создания текущей версии */
  lastUpdate?: Maybe<Scalars['DateTime']>;
  /** id - уникальный идентификатор */
  legal_entity_novat?: Maybe<Scalars['Int']>;
  /** id - уникальный идентификатор */
  legal_entity_vat?: Maybe<Scalars['Int']>;
  /** id - уникальный идентификатор менеджера */
  manager_id?: Maybe<Scalars['Int']>;
  /** название брифа */
  name?: Maybe<Scalars['String']>;
  /** Размещение новости */
  news?: Maybe<Scalars['Int']>;
  /** Клиент без НДС */
  no_vat?: Maybe<Scalars['Int']>;
  /** id - уникальный идентификатор партнёра */
  partner_id?: Maybe<Scalars['Int']>;
  /** Тип острочки */
  payment_delay?: Maybe<Scalars['Int']>;
  /** Число для отсрочки */
  payment_delay_value?: Maybe<Scalars['Int']>;
  /** Продвигаемый продукт или услуги */
  products?: Maybe<Scalars['String']>;
  /** Необходимость разработки рекламных материалов (0/1) */
  promotionalMaterial?: Maybe<Scalars['Boolean']>;
  /** Пожелания и рекомендации */
  recommends?: Maybe<Scalars['String']>;
  /** Причина отказа */
  refusal?: Maybe<Scalars['Int']>;
  /** Описание причины отказа */
  refusal_description?: Maybe<Scalars['String']>;
  /** id - уникальный идентификатор ответственного лица */
  responsible_id?: Maybe<Scalars['Int']>;
  /** Пол целевой аудитории (f,m,fm) */
  sex?: Maybe<Scalars['String']>;
  /** спецпроект */
  special?: Maybe<Scalars['Int']>;
  /** Идентификатор записи */
  status?: Maybe<Scalars['Int']>;
  /** Комментарий по целевой аудитории */
  target_audience?: Maybe<Scalars['String']>;
  /** id задачи в комоне */
  taskId?: Maybe<Scalars['Int']>;
  /** Идентификатор задания */
  task_id?: Maybe<Scalars['Int']>;
  /** Тендер (0/1) */
  tender?: Maybe<Scalars['Int']>;
  /** Дата deadline тендера */
  tender_deadline?: Maybe<Scalars['DateTime']>;
  /** Бюджет в т.ч. НДС на весь период */
  totalBudget?: Maybe<Scalars['Int']>;
  /** Включено ли агентское вознаграждение в бюджет (0/1) */
  totalBudgetAcIn?: Maybe<Scalars['Boolean']>;
  /** Бюджет ОТ в т.ч. НДС на весь период */
  totalBudgetFrom?: Maybe<Scalars['Float']>;
  /** Бюджет ДО в т.ч. НДС на весь период */
  totalBudgetTo?: Maybe<Scalars['Float']>;
  /** Список ID соисполнителей */
  users?: Maybe<Array<Maybe<Scalars['Int']>>>;
  /** Клиент с НДС */
  vat?: Maybe<Scalars['Int']>;
  /** Версия, необходима для хранения изменённых копий. */
  version?: Maybe<Scalars['Boolean']>;
  /** URL сайта клиента */
  website?: Maybe<Scalars['String']>;
  /** Возможность внесения изменений на сайте клиента (0/1) */
  websiteChangeable?: Maybe<Scalars['Boolean']>;
  /** Доступ к сервису Яндекс Метрика */
  yandexMetrika?: Maybe<Scalars['Boolean']>;
};

/** Элемент коэффициента */
export type RnCoefficient = {
  __typename?: 'RnCoefficient';
  /** id - уникальный идентификатор автора */
  authorId?: Maybe<Scalars['Int']>;
  /** Значение коэффициента */
  coefficient?: Maybe<Scalars['Float']>;
  /** Дата конца */
  endDate?: Maybe<Scalars['DateTime']>;
  /** id - уникальный идентификатор записи */
  id?: Maybe<Scalars['Int']>;
  /** Месяц */
  month?: Maybe<Scalars['Int']>;
  /** День */
  month_day?: Maybe<Scalars['Int']>;
  /** ID-платформы, к которому привязан элемент скидки */
  platformId?: Maybe<Scalars['Int']>;
  /** Дата начала */
  startDate?: Maybe<Scalars['DateTime']>;
  /** Дата обновления */
  updateDate?: Maybe<Scalars['DateTime']>;
};

/** Загрузка данных элемента коэффициента */
export type RnCoefficientInput = {
  /** id - уникальный идентификатор автора */
  authorId?: Maybe<Scalars['Int']>;
  /** Значение коэффициента */
  coefficient?: Maybe<Scalars['Float']>;
  /** Дата конца */
  endDate?: Maybe<Scalars['DateTime']>;
  /** id - уникальный идентификатор записи */
  id?: Maybe<Scalars['Int']>;
  /** Месяц */
  month?: Maybe<Scalars['Int']>;
  /** День */
  month_day?: Maybe<Scalars['Int']>;
  /** ID-платформы, к которому привязан элемент скидки */
  platformId?: Maybe<Scalars['Int']>;
  /** Дата начала */
  startDate?: Maybe<Scalars['DateTime']>;
  /** Дата обновления */
  updateDate?: Maybe<Scalars['DateTime']>;
};

/** Элемент контактов */
export type RnContacts = {
  __typename?: 'RnContacts';
  /** Комментарии */
  Comments?: Maybe<Scalars['String']>;
  /** МЛ-Юридическое лицо */
  LegalEntity?: Maybe<MlUrFaceItem>;
  /** партнёры */
  Partner?: Maybe<MlPartner>;
  /** Привязанные контакты */
  PersonContacts?: Maybe<Array<Maybe<MlContactItem>>>;
  /** email */
  email?: Maybe<Scalars['String']>;
  /** Дата начала */
  endDate?: Maybe<Scalars['DateTime']>;
  /** ФИО */
  fullName?: Maybe<Scalars['String']>;
  /** id - уникальный идентификатор записи */
  id?: Maybe<Scalars['Int']>;
  /** Фамилия */
  last_name?: Maybe<Scalars['String']>;
  /** Юридическое лицо */
  legal_entity_id?: Maybe<Scalars['Int']>;
  /** Отчество */
  middle_name?: Maybe<Scalars['String']>;
  /** ID в таблице ML */
  ml_id?: Maybe<Scalars['Int']>;
  /** Имя */
  name?: Maybe<Scalars['String']>;
  /** Партнер */
  partner_id?: Maybe<Scalars['Int']>;
  /** телефон */
  phone?: Maybe<Scalars['String']>;
  /** ID-платформы, к которому привязан элемент скидки */
  platformId?: Maybe<Scalars['Int']>;
  /** position */
  position?: Maybe<Scalars['String']>;
  /** position */
  processType?: Maybe<Scalars['String']>;
  /** Ответственный за прайсы или нет */
  responsible?: Maybe<Scalars['Int']>;
  /** Дата начала */
  startDate?: Maybe<Scalars['DateTime']>;
  /** Дата обновления */
  updateDate?: Maybe<Scalars['DateTime']>;
};

/** Загрузка данных элемента контактов */
export type RnContactsInput = {
  /** Комментарии */
  Comments?: Maybe<Scalars['String']>;
  /** Список контактов */
  PersonContacts?: Maybe<Array<Maybe<MlContactInput>>>;
  /** email */
  email?: Maybe<Scalars['String']>;
  /** Дата начала */
  endDate?: Maybe<Scalars['DateTime']>;
  /** ФИО */
  fullName?: Maybe<Scalars['String']>;
  /** id - уникальный идентификатор записи */
  id?: Maybe<Scalars['Int']>;
  /** Фамилия */
  last_name?: Maybe<Scalars['String']>;
  /** Юридическое лицо */
  legal_entity_id?: Maybe<Scalars['Int']>;
  /** Отчество */
  middle_name?: Maybe<Scalars['String']>;
  /** ID в таблице ML */
  ml_id?: Maybe<Scalars['Int']>;
  /** Имя */
  name?: Maybe<Scalars['String']>;
  /** Партнер */
  partner_id?: Maybe<Scalars['Int']>;
  /** телефон */
  phone?: Maybe<Scalars['String']>;
  /** ID-платформы, к которому привязан элемент скидки */
  platformId?: Maybe<Scalars['Int']>;
  /** position */
  position?: Maybe<Scalars['String']>;
  /** position */
  processType?: Maybe<Scalars['String']>;
  /** Ответственный за прайсы или нет */
  responsible?: Maybe<Scalars['Int']>;
  /** Дата начала */
  startDate?: Maybe<Scalars['DateTime']>;
  /** Дата обновления */
  updateDate?: Maybe<Scalars['DateTime']>;
};

/** Элемент скидки */
export type RnDiscount = {
  __typename?: 'RnDiscount';
  /** Элемент инвентаря со всеми параметрами */
  Inventory?: Maybe<RnInventoryItem>;
  /** Юзер */
  User?: Maybe<RnUser>;
  /** База начисления */
  accrualBasis?: Maybe<Scalars['Int']>;
  /** Id записи автора */
  authorId?: Maybe<Scalars['Int']>;
  /** Размер */
  discountAmount?: Maybe<Scalars['Float']>;
  /** ОТ */
  discountFrom?: Maybe<Scalars['Float']>;
  /** ДО */
  discountTo?: Maybe<Scalars['Float']>;
  /** Дата начала */
  endDate?: Maybe<Scalars['DateTime']>;
  /** id - уникальный идентификатор записи */
  id?: Maybe<Scalars['Int']>;
  /** для конкретного инвентаря */
  individual?: Maybe<Scalars['Int']>;
  /** id инвентаря */
  inventory_id?: Maybe<Scalars['Int']>;
  /** Тип инвентаря */
  inventory_type?: Maybe<Scalars['String']>;
  /** Название скидки */
  name?: Maybe<Scalars['String']>;
  /** ID-платформы, к которому привязан элемент скидки */
  platformId?: Maybe<Scalars['Int']>;
  /** Дата начала */
  startDate?: Maybe<Scalars['DateTime']>;
  /** Субъект */
  subject?: Maybe<Scalars['Int']>;
  /** Дата обновления */
  updateDate?: Maybe<Scalars['DateTime']>;
  /** Юзер */
  user?: Maybe<RnUser>;
};

/** Загрузка данных элемента прайса */
export type RnDiscountInput = {
  /** База начисления */
  accrualBasis?: Maybe<Scalars['Int']>;
  /** Id записи автора */
  authorId?: Maybe<Scalars['Int']>;
  /** Размер */
  discountAmount?: Maybe<Scalars['Float']>;
  /** ОТ */
  discountFrom?: Maybe<Scalars['Float']>;
  /** ДО */
  discountTo?: Maybe<Scalars['Float']>;
  /** Дата начала */
  endDate?: Maybe<Scalars['DateTime']>;
  /** id - уникальный идентификатор записи */
  id?: Maybe<Scalars['Int']>;
  /** для конкретного инвентаря */
  individual?: Maybe<Scalars['Int']>;
  /** id инвентаря */
  inventory_id?: Maybe<Scalars['Int']>;
  /** Тип инвентаря */
  inventory_type?: Maybe<Scalars['String']>;
  /** Название скидки */
  name?: Maybe<Scalars['String']>;
  /** ID-платформы, к которому привязан элемент скидки */
  platformId?: Maybe<Scalars['Int']>;
  /** Дата начала */
  startDate?: Maybe<Scalars['DateTime']>;
  /** Субъект */
  subject?: Maybe<Scalars['Int']>;
  /** Дата обновления */
  updateDate?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserInput>;
};

/** Элемент инвентаря со всеми параметрами */
export type RnInventoryItem = {
  __typename?: 'rnInventoryItem';
  /** Элемент таргетинга */
  GlobalTargeting?: Maybe<Array<Maybe<RnInventoryItemTargeting>>>;
  /** Элемент цены */
  InventoryPriceItemsList?: Maybe<Array<Maybe<RnInventoryItemPriceItem>>>;
  /** Страница сайта инвентаря */
  Pages?: Maybe<Array<Maybe<RnInventoryPage>>>;
  /** Элемент таргетинга */
  Targeting?: Maybe<Array<Maybe<RnInventoryItemTargeting>>>;
  /** Информация о пользователе */
  User?: Maybe<UserProfile>;
  /** Тело статьи */
  articleBody?: Maybe<Scalars['String']>;
  /** Текст заголовка */
  articleTitle?: Maybe<Scalars['String']>;
  /** id - уникальный идентификатор пользователя */
  authorId?: Maybe<Scalars['Int']>;
  /** id - уникальный идентификатор пользователя */
  author_id?: Maybe<Scalars['Int']>;
  /** Способ расчета цены */
  calculatingMethod?: Maybe<Scalars['Int']>;
  /** Количество кликов */
  clicks?: Maybe<Scalars['Int']>;
  /** отключение применения коэффициента */
  coefficient_off?: Maybe<Scalars['Int']>;
  /** Комментарий */
  comment?: Maybe<Scalars['String']>;
  /** Количество просмотров */
  ctr?: Maybe<Scalars['Float']>;
  /** Валюта */
  currency?: Maybe<Scalars['Int']>;
  /** Описание */
  description?: Maybe<Scalars['String']>;
  /** Отображение десктоп */
  display_desktop?: Maybe<Scalars['Int']>;
  /** Отображение мобайл */
  display_mobile?: Maybe<Scalars['Int']>;
  /** Дата конца */
  endDate?: Maybe<Scalars['DateTime']>;
  /** Дата заполнения */
  entryDate?: Maybe<Scalars['DateTime']>;
  /** Список файлов */
  files?: Maybe<Array<Maybe<File>>>;
  /** Формат инвентаря */
  format?: Maybe<Scalars['String']>;
  /** применение глобально таргетинга */
  global_targeting?: Maybe<Scalars['Int']>;
  /** id - уникальный идентификатор записи */
  id?: Maybe<Scalars['Int']>;
  /** Список файлов */
  image?: Maybe<Array<Maybe<File>>>;
  /** минимальное количество показов */
  min_impressions?: Maybe<Scalars['Int']>;
  /** Название */
  name?: Maybe<Scalars['String']>;
  /** название страницы */
  pageTitle?: Maybe<Scalars['String']>;
  /** ссылка на страницу размещения */
  pageUrl?: Maybe<Scalars['String']>;
  /** страницы сайта */
  pages?: Maybe<Scalars['ListOfInt']>;
  /** pid - уникальный идентификатор записи */
  pid?: Maybe<Scalars['Int']>;
  /** Тип размещения */
  placement_type?: Maybe<Scalars['Int']>;
  /** Время размещения */
  placement_value?: Maybe<Scalars['Int']>;
  /** id - уникальный идентификатор записи площадки */
  platformId?: Maybe<Scalars['Int']>;
  /** Позиция */
  position?: Maybe<Scalars['String']>;
  /** Условие */
  priceCondition?: Maybe<Scalars['Int']>;
  /** Цена от */
  priceFrom?: Maybe<Scalars['Float']>;
  /** Цена до */
  priceTo?: Maybe<Scalars['Float']>;
  /** Тип цены */
  priceType?: Maybe<Scalars['Int']>;
  /** Цена */
  price_value?: Maybe<Scalars['Float']>;
  /** Цена за месяц */
  price_value_month?: Maybe<Scalars['Float']>;
  /** Цена за неделю */
  price_value_week?: Maybe<Scalars['Float']>;
  /** Формат публикации */
  publication_format?: Maybe<Scalars['Int']>;
  /** Размер закупа, дней */
  purchase?: Maybe<Scalars['Int']>;
  /** inventory rating */
  rating?: Maybe<Scalars['Float']>;
  /** Тех требования */
  requirements?: Maybe<Scalars['String']>;
  /** Список файлов ТТ */
  requirementsFiles?: Maybe<Array<Maybe<File>>>;
  /** Ротация, % */
  rotation?: Maybe<Scalars['Int']>;
  /** Список файлов */
  screenshot?: Maybe<Array<Maybe<File>>>;
  /** Количество показов */
  showing?: Maybe<Scalars['Int']>;
  /** Тип размещения */
  showing_percent?: Maybe<Scalars['Float']>;
  /** технические требования */
  specifications?: Maybe<Scalars['String']>;
  /** Дата начала */
  startDate?: Maybe<Scalars['DateTime']>;
  /** Статистика */
  statistics?: Maybe<Scalars['String']>;
  /** тип инвентаря */
  type?: Maybe<Scalars['String']>;
  /** Дата обновления */
  updateDate?: Maybe<Scalars['DateTime']>;
  /** Значение */
  value?: Maybe<Scalars['Int']>;
  /** налогообложение */
  vat?: Maybe<Scalars['Int']>;
  /** Ставка НДС, % */
  vatRate?: Maybe<Scalars['Int']>;
  /** Список файлов */
  video?: Maybe<Array<Maybe<File>>>;
  /** Список файлов */
  video_short?: Maybe<Array<Maybe<File>>>;
  /** Количество просмотров */
  view?: Maybe<Scalars['Int']>;
};

/** Элемент инвентаря */
export type RnInventoryItemInput = {
  /** Тело статьи */
  articleBody?: Maybe<Scalars['String']>;
  /** Текст заголовка */
  articleTitle?: Maybe<Scalars['String']>;
  /** id - уникальный идентификатор пользователя */
  authorId?: Maybe<Scalars['Int']>;
  /** id - уникальный идентификатор пользователя */
  author_id?: Maybe<Scalars['Int']>;
  /** Способ расчета цены */
  calculatingMethod?: Maybe<Scalars['Int']>;
  /** Количество кликов */
  clicks?: Maybe<Scalars['Int']>;
  /** отключение применения коэффициента */
  coefficient_off?: Maybe<Scalars['Int']>;
  /** Комментарий */
  comment?: Maybe<Scalars['String']>;
  /** Количество просмотров */
  ctr?: Maybe<Scalars['Float']>;
  /** Валюта */
  currency?: Maybe<Scalars['Int']>;
  /** Описание */
  description?: Maybe<Scalars['String']>;
  /** Отображение десктоп */
  display_desktop?: Maybe<Scalars['Int']>;
  /** Отображение мобайл */
  display_mobile?: Maybe<Scalars['Int']>;
  /** Дата конца */
  endDate?: Maybe<Scalars['DateTime']>;
  /** Дата заполнения */
  entryDate?: Maybe<Scalars['DateTime']>;
  /** массив id файлов */
  files?: Maybe<Array<Maybe<Scalars['Int']>>>;
  /** Формат инвентаря */
  format?: Maybe<Scalars['String']>;
  /** Загрузка списка таргетинга */
  globalTargetingList?: Maybe<Array<Maybe<RnInventoryItemTargetingInput>>>;
  /** применение глобально таргетинга */
  global_targeting?: Maybe<Scalars['Int']>;
  /** id - уникальный идентификатор записи */
  id?: Maybe<Scalars['Int']>;
  /** массив id файлов */
  image?: Maybe<Array<Maybe<Scalars['Int']>>>;
  /** минимальное количество показов */
  min_impressions?: Maybe<Scalars['Int']>;
  /** Название */
  name?: Maybe<Scalars['String']>;
  /** название страницы */
  pageTitle?: Maybe<Scalars['String']>;
  /** ссылка на страницу размещения */
  pageUrl?: Maybe<Scalars['String']>;
  /** страницы сайта */
  pages?: Maybe<Scalars['ListOfInt']>;
  /** pid - уникальный идентификатор записи */
  pid?: Maybe<Scalars['Int']>;
  /** Тип размещения */
  placement_type?: Maybe<Scalars['Int']>;
  /** Время размещения */
  placement_value?: Maybe<Scalars['Int']>;
  /** id - уникальный идентификатор записи площадки */
  platformId?: Maybe<Scalars['Int']>;
  /** Позиция */
  position?: Maybe<Scalars['String']>;
  /** Условие */
  priceCondition?: Maybe<Scalars['Int']>;
  /** Цена от */
  priceFrom?: Maybe<Scalars['Float']>;
  /** Цена до */
  priceTo?: Maybe<Scalars['Float']>;
  /** Тип цены */
  priceType?: Maybe<Scalars['Int']>;
  /** Цена */
  price_value?: Maybe<Scalars['Float']>;
  /** Цена за месяц */
  price_value_month?: Maybe<Scalars['Float']>;
  /** Цена за неделю */
  price_value_week?: Maybe<Scalars['Float']>;
  /** Формат публикации */
  publication_format?: Maybe<Scalars['Int']>;
  /** Размер закупа, дней */
  purchase?: Maybe<Scalars['Int']>;
  /** rating */
  rating?: Maybe<Scalars['Float']>;
  /** Тех требования */
  requirements?: Maybe<Scalars['String']>;
  /** Ротация, % */
  rotation?: Maybe<Scalars['Int']>;
  /** массив id файлов */
  screenshot?: Maybe<Array<Maybe<Scalars['Int']>>>;
  /** Количество показов */
  showing?: Maybe<Scalars['Int']>;
  /** Тип размещения */
  showing_percent?: Maybe<Scalars['Float']>;
  /** технические требования */
  specifications?: Maybe<Scalars['String']>;
  /** Дата начала */
  startDate?: Maybe<Scalars['DateTime']>;
  /** Статистика */
  statistics?: Maybe<Scalars['String']>;
  /** Загрузка списка таргетинга */
  targetingList?: Maybe<Array<Maybe<RnInventoryItemTargetingInput>>>;
  /** тип инвентаря */
  type?: Maybe<Scalars['String']>;
  /** Дата обновления */
  updateDate?: Maybe<Scalars['DateTime']>;
  /** Значение */
  value?: Maybe<Scalars['Int']>;
  /** налогообложение */
  vat?: Maybe<Scalars['Int']>;
  /** Ставка НДС, % */
  vatRate?: Maybe<Scalars['Int']>;
  /** массив id файлов */
  video?: Maybe<Array<Maybe<Scalars['Int']>>>;
  /** массив id файлов */
  video_short?: Maybe<Array<Maybe<Scalars['Int']>>>;
  /** Количество просмотров */
  view?: Maybe<Scalars['Int']>;
};

/** Элемент цены */
export type RnInventoryItemPriceItem = {
  __typename?: 'RnInventoryItemPriceItem';
  /** id - уникальный идентификатор записи */
  id?: Maybe<Scalars['ID']>;
  /** id - уникальный идентификатор записи */
  inventory_id?: Maybe<Scalars['Int']>;
  /** id - уникальный идентификатор записи */
  platform_id?: Maybe<Scalars['Int']>;
  /** id - уникальный идентификатор записи */
  price_type?: Maybe<Scalars['Int']>;
  /** id - уникальный идентификатор записи */
  price_value?: Maybe<Scalars['Int']>;
};

/** Элемент таргетинга */
export type RnInventoryItemTargeting = {
  __typename?: 'rnInventoryItemTargeting';
  /** коэффициент */
  coefficient?: Maybe<Scalars['Float']>;
  /** глобальный ли это таргетинг */
  global_targeting?: Maybe<Scalars['Int']>;
  /** id - уникальный идентификатор записи */
  id?: Maybe<Scalars['Int']>;
  /** Название */
  name?: Maybe<Scalars['String']>;
};

/** инпут таргетингов */
export type RnInventoryItemTargetingInput = {
  /** коэффициент */
  coefficient?: Maybe<Scalars['Float']>;
  /** глобальный ли это таргетинг */
  global_targeting?: Maybe<Scalars['Int']>;
  /** id - уникальный идентификатор записи */
  id?: Maybe<Scalars['Int']>;
  /** Название */
  name?: Maybe<Scalars['String']>;
};

/** Страница сайта инвентаря */
export type RnInventoryPage = {
  __typename?: 'RnInventoryPage';
  /** id - уникальный идентификатор записи */
  id?: Maybe<Scalars['Int']>;
  /** Наименование страницы */
  name?: Maybe<Scalars['String']>;
};

/** Загрузка страницы сайта */
export type RnInventoryPagesInput = {
  /** id - уникальный идентификатор записи */
  id?: Maybe<Scalars['Int']>;
  /** Наименование страницы */
  name?: Maybe<Scalars['String']>;
};

/** Вид таргетинга */
export type RnInventoryTargeting = {
  __typename?: 'RnInventoryTargeting';
  /** id - уникальный идентификатор записи */
  id?: Maybe<Scalars['Int']>;
  /** Наименование страницы */
  name?: Maybe<Scalars['String']>;
};

/** Загрузка таргетинга */
export type RnInventoryTargetingInput = {
  /** id - уникальный идентификатор записи */
  id?: Maybe<Scalars['Int']>;
  /** Наименование страницы */
  name?: Maybe<Scalars['String']>;
};

/** Элемент лога */
export type RnLog = {
  __typename?: 'RnLog';
  /** Дата созданя */
  created?: Maybe<Scalars['DateTime']>;
  /** Отсрочка клиента */
  customerPaymentDelay?: Maybe<Scalars['String']>;
  /** Отсрочка клиента */
  customerPaymentDelayValue?: Maybe<Scalars['Int']>;
  /** Налогообложение клиента */
  customerVat?: Maybe<Scalars['String']>;
  /** Поля */
  data?: Maybe<Scalars['String']>;
  /** Удалено */
  deleted?: Maybe<Scalars['Int']>;
  /** Отсрочка поставщик факт */
  factPaymentDelay?: Maybe<Scalars['String']>;
  /** Отсрочка поставщик факт */
  factPaymentDelayValue?: Maybe<Scalars['Int']>;
  /** ID */
  id?: Maybe<Scalars['ID']>;
  /** Важно */
  info?: Maybe<Scalars['String']>;
  /** Вид рекламы */
  inventoryName?: Maybe<Scalars['String']>;
  /** mpID */
  mpID?: Maybe<Scalars['Int']>;
  /** Клиент */
  partnerName?: Maybe<Scalars['String']>;
  /** Площадка */
  platformName?: Maybe<Scalars['String']>;
  /** Вид цены */
  priceType?: Maybe<Scalars['String']>;
  /** Требования */
  requirements?: Maybe<Scalars['String']>;
  /** Статус */
  status?: Maybe<Scalars['Int']>;
  /** Статус */
  statusName?: Maybe<Scalars['String']>;
  /** Отсрочка поставщик базовая */
  vendorPaymentDelay?: Maybe<Scalars['String']>;
  /** Отсрочка поставщик базовая */
  vendorPaymentDelayValue?: Maybe<Scalars['Int']>;
  /** Налогообложение площадки */
  vendorVat?: Maybe<Scalars['String']>;
};

/** Элемент наценок */
export type RnMarkup = {
  __typename?: 'RnMarkup';
  /** Применять или нет */
  apply?: Maybe<Scalars['Int']>;
  /** Дата начала */
  endDate?: Maybe<Scalars['DateTime']>;
  /** id - уникальный идентификатор записи */
  id?: Maybe<Scalars['Int']>;
  /** Сумма наценки */
  markup?: Maybe<Scalars['Float']>;
  /** Тип наценки */
  markupType?: Maybe<Scalars['Int']>;
  /** ID-платформы, к которому привязан элемент скидки */
  platformId?: Maybe<Scalars['Int']>;
  /** Дата начала */
  startDate?: Maybe<Scalars['DateTime']>;
  /** Дата обновления */
  updateDate?: Maybe<Scalars['DateTime']>;
};

/** Загрузка данных элемента наценок */
export type RnMarkupInput = {
  /** Применять или нет */
  apply?: Maybe<Scalars['Int']>;
  /** ---- */
  callTrackingFile?: Maybe<Array<Maybe<Scalars['Int']>>>;
  /** id Файл-отчеты из CRM-системы */
  crmCustomersFile?: Maybe<Array<Maybe<Scalars['Int']>>>;
  /** ---- */
  ecommerceAccessFile?: Maybe<Array<Maybe<Scalars['Int']>>>;
  /** Дата начала */
  endDate?: Maybe<Scalars['DateTime']>;
  /** id - уникальный идентификатор записи */
  id?: Maybe<Scalars['Int']>;
  /** Сумма наценки */
  markup?: Maybe<Scalars['Float']>;
  /** Тип наценки */
  markupType?: Maybe<Scalars['Int']>;
  /** ID-платформы, к которому привязан элемент скидки */
  platformId?: Maybe<Scalars['Int']>;
  /** Дата начала */
  startDate?: Maybe<Scalars['DateTime']>;
  /** Дата обновления */
  updateDate?: Maybe<Scalars['DateTime']>;
};

/** Медиаплан */
export type RnMp = {
  __typename?: 'RnMp';
  /** Бриф региональных площадок */
  Briefing?: Maybe<RnBriefing>;
  /** Город РФ */
  City?: Maybe<MlCity>;
  /** Список элементов контактов */
  ContactsItems?: Maybe<Array<Maybe<RnContacts>>>;
  /** Договор */
  Contract?: Maybe<MlDocumentItem>;
  /** Список зон покрытия */
  Coverage?: Maybe<MlCoverage>;
  /** Список элементов скидок */
  DiscountItems?: Maybe<Array<Maybe<RnDiscount>>>;
  /** МЛ-Юридическое лицо */
  LegalEntityClient?: Maybe<MlUrFaceItem>;
  /** Список элементов наценки */
  MarkUpItems?: Maybe<Array<Maybe<RnMarkup>>>;
  /** Список медиагрупп */
  MediaGroup?: Maybe<MlMediaGroups>;
  /** Список медиагрупп */
  MediaType?: Maybe<MlMediaType>;
  /** Элемент медиаплана/ строчка */
  MpItems?: Maybe<Array<Maybe<RnMpItem>>>;
  /** партнёры */
  NetworkPartner?: Maybe<MlPartner>;
  /** партнёры */
  Owner?: Maybe<MlPartner>;
  /** прайс */
  Price?: Maybe<RnPrice>;
  /** Регион РФ */
  Region?: Maybe<MlRegion>;
  /** партнёры */
  Seller?: Maybe<MlPartner>;
  /** Тематика */
  Thematics?: Maybe<MlThematics>;
  /** Сотрудник компании */
  Traffic?: Maybe<RefEmployee>;
  /** Юридические лица Дельтаплана */
  UrFace_nds?: Maybe<MlDpUrFaces>;
  /** Юридические лица Дельтаплана */
  UrFace_nonds?: Maybe<MlDpUrFaces>;
  /** Юзер */
  User?: Maybe<RnUser>;
  /** С НДС или без */
  Vat?: Maybe<Vat>;
  /** Уровень НДС */
  VatRate?: Maybe<VatRate>;
  /** id - уникальный идентификатор автора */
  author_id?: Maybe<Scalars['Int']>;
  /** Комментарий байера */
  bayer_comment?: Maybe<Scalars['String']>;
  /** Дата конца */
  briefing_id?: Maybe<Scalars['Int']>;
  /** Встроенный брифинг */
  briefing_in?: Maybe<Scalars['Int']>;
  /** Клики факт */
  clicks?: Maybe<Scalars['Int']>;
  /** Комментарий */
  comment?: Maybe<Scalars['String']>;
  /** Номер договора */
  contract_id?: Maybe<Scalars['Int']>;
  /** CPC факт */
  cpc?: Maybe<Scalars['Float']>;
  /** Дата актуальности */
  date_relevance?: Maybe<Scalars['DateTime']>;
  /** Медиаплан заблокирован для редактирования */
  disabled?: Maybe<Scalars['Boolean']>;
  /** Дата конца */
  endDate?: Maybe<Scalars['DateTime']>;
  /** Дата создания текущей версии */
  entryDate?: Maybe<Scalars['DateTime']>;
  /** Список файлов */
  files?: Maybe<Array<Maybe<File>>>;
  /** id - уникальный идентификатор записи */
  id?: Maybe<Scalars['Int']>;
  /** Зарубежные площадки да / нет */
  isForeign?: Maybe<Scalars['Boolean']>;
  /** это копия */
  is_copy?: Maybe<Scalars['Int']>;
  /** id - уникальный идентификатор */
  legal_entity_client?: Maybe<Scalars['Int']>;
  /** id - уникальный идентификатор */
  legal_entity_novat?: Maybe<Scalars['Int']>;
  /** id - уникальный идентификатор */
  legal_entity_vat?: Maybe<Scalars['Int']>;
  /** Список логотипов */
  logo?: Maybe<Array<Maybe<File>>>;
  /** Маржа */
  margin?: Maybe<Scalars['Float']>;
  /** Номер заказа */
  order_id?: Maybe<Scalars['Int']>;
  /** Номер заказа */
  order_id_2?: Maybe<Scalars['Int']>;
  /** ID партнера */
  partner_id?: Maybe<Scalars['Int']>;
  /** Список файлов */
  priceFile?: Maybe<Array<Maybe<File>>>;
  /** Рентабельность */
  profitability?: Maybe<Scalars['Float']>;
  /** В МП есть публикация */
  publicationIn?: Maybe<Scalars['Boolean']>;
  /** Причина отмены */
  refusal?: Maybe<Scalars['String']>;
  /** Дата начала */
  startDate?: Maybe<Scalars['DateTime']>;
  /** id - уникальный идентификатор записи */
  status?: Maybe<Scalars['Int']>;
  /** Тендер */
  tender?: Maybe<Scalars['Int']>;
  /** Стоимость за период после скидки без НДС */
  totalCostNoVatDiscount?: Maybe<Scalars['Float']>;
  /** Стоимость за период ЗАКУП без НДС */
  totalCostPurchaseDiscount?: Maybe<Scalars['Float']>;
  /** Итого клиент, включая все налоги */
  totalCostVatDiscount?: Maybe<Scalars['Float']>;
  /** id траффика */
  traffic_id?: Maybe<Scalars['Int']>;
  /** Дата создания текущей версии */
  updateDate?: Maybe<Scalars['DateTime']>;
};

/** Элемент медиаплана/ строчка */
export type RnMpItem = {
  __typename?: 'RnMpItem';
  /** Город РФ */
  City?: Maybe<MlCity>;
  /** Список зон покрытия */
  Coverage?: Maybe<MlCoverage>;
  /** Скидка */
  Discount?: Maybe<Scalars['Float']>;
  /** стоимость единицы */
  Info?: Maybe<RnMpItemInfo>;
  /** Элемент инвентаря со всеми параметрами */
  InventoryItem?: Maybe<RnInventoryItem>;
  /** партнёры */
  NetworkPartner?: Maybe<MlPartner>;
  /** партнёры */
  Owner?: Maybe<MlPartner>;
  /** Информация о площадке */
  Platform?: Maybe<RnPlatform>;
  /** прайс */
  Price?: Maybe<RnPrice>;
  /** Получение типа цены */
  PriceType?: Maybe<Scalars['Int']>;
  /** Регион РФ */
  Region?: Maybe<MlRegion>;
  /** партнёры */
  Seller?: Maybe<MlPartner>;
  /** Тематика */
  Thematics?: Maybe<MlThematics>;
  /** Юзер */
  User?: Maybe<RnUser>;
  /** С НДС или без */
  Vat?: Maybe<Vat>;
  /** Уровень НДС */
  VatRate?: Maybe<VatRate>;
  /** АВ */
  ac?: Maybe<Scalars['Float']>;
  /** НДС АВ */
  acVatValue?: Maybe<Scalars['Float']>;
  /** Сумма налога в АВ */
  ac_vat_rate_piece?: Maybe<Scalars['Float']>;
  /** Получение АВ */
  agent_commission?: Maybe<Scalars['Float']>;
  /** id автора */
  author_id?: Maybe<Scalars['Int']>;
  /** город */
  city?: Maybe<Scalars['String']>;
  /** Клики */
  clicks?: Maybe<Scalars['Int']>;
  /** Отключение коэффициента */
  coefficient_off?: Maybe<Scalars['Int']>;
  /** Цена за клик */
  cpc?: Maybe<Scalars['Float']>;
  /** CTR, % */
  ctr?: Maybe<Scalars['Float']>;
  /** Скидка */
  discount?: Maybe<Scalars['Float']>;
  /** Комментарий по скидке */
  discountComment?: Maybe<Scalars['String']>;
  /** Скидка Закуп */
  discount_purchase?: Maybe<Scalars['Float']>;
  /** Дата конца */
  endDate?: Maybe<Scalars['String']>;
  /** Дата создания */
  entryDate?: Maybe<Scalars['DateTime']>;
  /** Список файлов */
  files?: Maybe<Array<Maybe<File>>>;
  /** Формат внутри строки мп */
  format?: Maybe<Scalars['String']>;
  /** Кастомная география */
  geo?: Maybe<Scalars['String']>;
  /** id - уникальный идентификатор записи */
  id?: Maybe<Scalars['Int']>;
  /** Входит ли строчка медиаплана в заказ */
  inOrder?: Maybe<Scalars['Boolean']>;
  /** Вид рекламы */
  inventoryName?: Maybe<Scalars['String']>;
  /** id инвентаря */
  inventory_id?: Maybe<Scalars['Int']>;
  /** Заменить */
  is_change?: Maybe<Scalars['Int']>;
  /** Комментарий */
  is_change_comment?: Maybe<Scalars['String']>;
  /** Список логотипов */
  logo?: Maybe<Array<Maybe<File>>>;
  /** Маржа */
  margin?: Maybe<Scalars['Float']>;
  /** Минимальное количество закупа */
  minPurchaseCount?: Maybe<Scalars['Int']>;
  /** id медиаплана */
  mp_id?: Maybe<Scalars['Int']>;
  /** Дата создания заказа */
  orderDate?: Maybe<Scalars['DateTime']>;
  /** ID последнего заказа */
  orderID?: Maybe<Scalars['Int']>;
  /** Отсрочка платежа */
  payment_delay?: Maybe<Scalars['Int']>;
  /** Отсрочка платежа, значение */
  payment_delay_value?: Maybe<Scalars['Int']>;
  /** id платформы */
  platform_id?: Maybe<Scalars['Int']>;
  /** Список файлов */
  priceFile?: Maybe<Array<Maybe<File>>>;
  /** id прайса */
  price_id?: Maybe<Scalars['Int']>;
  /** Дата обновления */
  price_query_date?: Maybe<Scalars['DateTime']>;
  /** Тип цены (неделя / месяц) */
  price_type?: Maybe<Scalars['Int']>;
  /** Рентабельность */
  profitability?: Maybe<Scalars['Float']>;
  /** закуп GROSS */
  purchaseGross?: Maybe<Scalars['Float']>;
  /** закуп NET */
  purchaseNet?: Maybe<Scalars['Float']>;
  /** Количество единиц закупа */
  purchase_count?: Maybe<Scalars['Float']>;
  /** Закуп Gross */
  purchase_gross?: Maybe<Scalars['Float']>;
  /** Закуп NET */
  purchase_net?: Maybe<Scalars['Float']>;
  rCPV?: Maybe<Scalars['Float']>;
  rCTR?: Maybe<Scalars['Float']>;
  rClicks?: Maybe<Scalars['Int']>;
  rConversion?: Maybe<Scalars['Float']>;
  rCount?: Maybe<Scalars['Int']>;
  rImpressions?: Maybe<Scalars['Int']>;
  rLeadImpressions?: Maybe<Scalars['Int']>;
  rPlan?: Maybe<Scalars['Float']>;
  rRejects?: Maybe<Scalars['Float']>;
  rSpend?: Maybe<Scalars['Float']>;
  rTime?: Maybe<Scalars['String']>;
  rTotalCost?: Maybe<Scalars['Float']>;
  rViewDepth?: Maybe<Scalars['Float']>;
  rViews?: Maybe<Scalars['Int']>;
  rVisits?: Maybe<Scalars['Int']>;
  /** продажа GROSS */
  sellGross?: Maybe<Scalars['Float']>;
  /** продажа NET */
  sellNet?: Maybe<Scalars['Float']>;
  /** Продажа Gross */
  sell_gross?: Maybe<Scalars['Float']>;
  /** Продажа NET */
  sell_net?: Maybe<Scalars['Float']>;
  /** Показы */
  showing?: Maybe<Scalars['Int']>;
  /** Дата начала */
  startDate?: Maybe<Scalars['String']>;
  /** id - уникальный идентификатор записи */
  status?: Maybe<Scalars['Int']>;
  /** Список таргетингов */
  targetingList?: Maybe<Array<Maybe<RnInventoryItemTargeting>>>;
  /** id таргетинга */
  targeting_id?: Maybe<Scalars['Int']>;
  /** Сумма площадки */
  total?: Maybe<Scalars['Float']>;
  /** Итого без НДС */
  totalCostNoVat?: Maybe<Scalars['Float']>;
  /** Итого со скидкой без НДС */
  totalCostNoVatDiscount?: Maybe<Scalars['Float']>;
  /** Цена закуп со скидкой */
  totalCostPurchaseDiscount?: Maybe<Scalars['Float']>;
  /** Цена закуп со скидкой с НДС */
  totalCostPurchaseDiscountVat?: Maybe<Scalars['Float']>;
  /** Итого с НДС */
  totalCostVatDiscount?: Maybe<Scalars['Float']>;
  /** Общая стоимость без НДС */
  total_cost_no_vat?: Maybe<Scalars['Float']>;
  /** Итоговая стоимость без НДС */
  total_cost_no_vat_discount?: Maybe<Scalars['Float']>;
  /** Сумма закупа */
  total_cost_purchase_discount?: Maybe<Scalars['Float']>;
  /** Сумма закупа с НДС */
  total_cost_purchase_discount_vat?: Maybe<Scalars['Float']>;
  /** Рентабельность */
  total_cost_vat_discount?: Maybe<Scalars['Float']>;
  /** Цена за единицу без НДС */
  unitCostNoVat?: Maybe<Scalars['Float']>;
  /** стоимость единицы */
  unit_cost?: Maybe<Scalars['Float']>;
  /** Стоимость единицы без НДС */
  unit_cost_no_vat?: Maybe<Scalars['Float']>;
  /** Дата обновления */
  updateDate?: Maybe<Scalars['DateTime']>;
  /** налогообложение */
  vat?: Maybe<Scalars['Int']>;
  /** Просмотры */
  view?: Maybe<Scalars['Int']>;
  /** Стоимость просмотра */
  viewCost?: Maybe<Scalars['Float']>;
  /** Скидка */
  view_cost?: Maybe<Scalars['Float']>;
};

/** Рассчетные данные строки медиаплана */
export type RnMpItemInfo = {
  __typename?: 'RnMpItemInfo';
  /** Сумма налога в АВ */
  ac_vat_rate_piece?: Maybe<Scalars['Float']>;
  /** Получение АВ */
  agent_commission?: Maybe<Scalars['Float']>;
  /** ав из брифинга */
  agent_commission_from_briefing?: Maybe<Scalars['Float']>;
  /** сезонный коэффициент */
  coefficient?: Maybe<Scalars['Float']>;
  /** CPC */
  cpc?: Maybe<Scalars['Float']>;
  /** Скидка */
  discount?: Maybe<Scalars['Float']>;
  /** комментарий по скидке */
  discount_comment?: Maybe<Scalars['String']>;
  /** скидка на закуп */
  discount_purchase?: Maybe<Scalars['Float']>;
  /** id - уникальный идентификатор записи */
  id?: Maybe<Scalars['Int']>;
  /** Маржа */
  margin?: Maybe<Scalars['Float']>;
  /** минимальное количество для закупа */
  min_purchase_count?: Maybe<Scalars['Float']>;
  /** Получение типа цены */
  price_type?: Maybe<Scalars['Int']>;
  /** Рентабельность */
  profitability?: Maybe<Scalars['Float']>;
  /** Закуп Gross */
  purchase_gross?: Maybe<Scalars['Float']>;
  /** Закуп NET */
  purchase_net?: Maybe<Scalars['Float']>;
  /** Продажа Gross */
  sell_gross?: Maybe<Scalars['Float']>;
  /** Продажа NET */
  sell_net?: Maybe<Scalars['Float']>;
  /** Общая стоимость без НДС */
  total_cost_no_vat?: Maybe<Scalars['Float']>;
  /** Итоговая стоимость без НДС */
  total_cost_no_vat_discount?: Maybe<Scalars['Float']>;
  /** Стомость закуп со скидкой */
  total_cost_purchase_discount?: Maybe<Scalars['Float']>;
  /** Стомость закуп со скидкой c НДС */
  total_cost_purchase_discount_vat?: Maybe<Scalars['Float']>;
  /** Рентабельность */
  total_cost_vat_discount?: Maybe<Scalars['Float']>;
  /** стоимость единицы */
  unit_cost?: Maybe<Scalars['Float']>;
  /** Стоимость единицы без НДС */
  unit_cost_no_vat?: Maybe<Scalars['Float']>;
  /** стоимость единицы из прайса */
  unit_price_cost?: Maybe<Scalars['Float']>;
  /** Стоимость просмотра */
  view_cost?: Maybe<Scalars['Float']>;
};

/** Отчет */
export type RnMpItemReport = {
  __typename?: 'RnMpItemReport';
  /** Сотрудник компании */
  Author?: Maybe<RefEmployee>;
  /** Бренд / Торговая марка */
  Brand?: Maybe<MlBrand>;
  /** Сотрудник компании */
  Buyer?: Maybe<RefEmployee>;
  /** Элемент инвентаря со всеми параметрами */
  Inventory?: Maybe<RnInventoryItem>;
  /** партнёры */
  Partner?: Maybe<MlPartner>;
  /** Информация о площадке */
  Platform?: Maybe<RnPlatform>;
  /** id - уникальный идентификатор записи */
  author_id?: Maybe<Scalars['Int']>;
  /** лучшая цена */
  best_price?: Maybe<Scalars['Int']>;
  /** клики */
  clicks?: Maybe<Scalars['Int']>;
  /** cpc */
  cpc?: Maybe<Scalars['Float']>;
  /** cpm */
  cpm?: Maybe<Scalars['Float']>;
  /** ctr */
  ctr?: Maybe<Scalars['Float']>;
  /** скидка */
  discount?: Maybe<Scalars['Float']>;
  /** скидка закуп */
  discount_purchase?: Maybe<Scalars['Float']>;
  /** Дата финиш */
  endDate?: Maybe<Scalars['Date']>;
  /** Дата вставки элемента */
  entryDate?: Maybe<Scalars['Date']>;
  /** id - уникальный идентификатор записи */
  id?: Maybe<Scalars['ID']>;
  /** id - уникальный идентификатор записи */
  inventory_id?: Maybe<Scalars['Int']>;
  /** маржа */
  margin?: Maybe<Scalars['Float']>;
  /** Дата занесения мп */
  mp_entry_date?: Maybe<Scalars['Date']>;
  /** Дата окончания подготовки медиаплана */
  mp_finished_date?: Maybe<Scalars['DateTime']>;
  /** id медиаплана */
  mp_id?: Maybe<Scalars['Int']>;
  /** клиент без ндс */
  no_vat?: Maybe<Scalars['Int']>;
  /** отсрочка платежа, тип */
  payment_delay?: Maybe<Scalars['Int']>;
  /** Отсрочка базовая Значение */
  payment_delay_basic?: Maybe<Scalars['String']>;
  /** отсрочка платежа */
  payment_delay_value?: Maybe<Scalars['Int']>;
  /** id - уникальный идентификатор записи */
  platform_id?: Maybe<Scalars['Int']>;
  /** ндс платформы */
  platform_vat?: Maybe<Scalars['Int']>;
  /** тип цены */
  price_type?: Maybe<Scalars['Int']>;
  /** рентабельность */
  profitability?: Maybe<Scalars['Float']>;
  /** количество закупа */
  purchase_count?: Maybe<Scalars['Int']>;
  /** показы */
  showing?: Maybe<Scalars['Int']>;
  /** Дата старт */
  startDate?: Maybe<Scalars['Date']>;
  /** статус */
  status?: Maybe<Scalars['Int']>;
  /** итоговая цена закуп со скидкой */
  total_cost_purchase_discount?: Maybe<Scalars['Float']>;
  /** итоговая цена закуп со скидкой и ндс */
  total_cost_purchase_discount_vat?: Maybe<Scalars['Float']>;
  /** итог с ндс и скидкой */
  total_cost_vat_discount?: Maybe<Scalars['Float']>;
  /** цена без ндс */
  unit_cost_no_vat?: Maybe<Scalars['Float']>;
  /** клиент с ндс */
  vat?: Maybe<Scalars['Int']>;
  /** просмотры */
  view?: Maybe<Scalars['Int']>;
};

/** Стандартный объект */
export type RnMutation = {
  __typename?: 'RnMutation';
  /** копирование строчки */
  copyItem?: Maybe<RnMpItem>;
  /** Удаление файла инветаря */
  deleteInventoryFile?: Maybe<File>;
  /** Удаление через флаг deleted */
  deleteItemSoft?: Maybe<Scalars['Boolean']>;
  /** Возвращение последней удаленной строки */
  undoItem?: Maybe<Scalars['Boolean']>;
  /** Изменение поля инвентаря */
  updateInventoryField?: Maybe<RnInventoryItem>;
  /** добавление настройки */
  updateSetting?: Maybe<Scalars['Boolean']>;
  /** Загрука файла инвентаря */
  uploadInventoryFile?: Maybe<File>;
};


/** Стандартный объект */
export type RnMutationCopyItemArgs = {
  itemID: Scalars['Int'];
};


/** Стандартный объект */
export type RnMutationDeleteInventoryFileArgs = {
  fileID: Scalars['Int'];
  inventoryID: Scalars['Int'];
};


/** Стандартный объект */
export type RnMutationDeleteItemSoftArgs = {
  itemID: Scalars['Int'];
};


/** Стандартный объект */
export type RnMutationUndoItemArgs = {
  mpID: Scalars['Int'];
};


/** Стандартный объект */
export type RnMutationUpdateInventoryFieldArgs = {
  fieldName?: Maybe<Scalars['String']>;
  fieldValue?: Maybe<Scalars['String']>;
  inventoryID: Scalars['Int'];
};


/** Стандартный объект */
export type RnMutationUpdateSettingArgs = {
  field?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};


/** Стандартный объект */
export type RnMutationUploadInventoryFileArgs = {
  comment?: Maybe<Scalars['String']>;
  file: Scalars['Upload'];
  inventoryID: Scalars['Int'];
  srcFileName?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['Int']>;
};

/** Информация о площадке */
export type RnPlatform = {
  __typename?: 'rnPlatform';
  /** Информация о пользователе */
  CheckUser?: Maybe<UserProfile>;
  /** Город РФ */
  City?: Maybe<MlCity>;
  /** Город РФ */
  CityList?: Maybe<Array<Maybe<MlCity>>>;
  /** Список элементов коэффициентов */
  CoefficientItems?: Maybe<Array<Maybe<RnCoefficient>>>;
  /** Список элементов контактов */
  ContactsItems?: Maybe<Array<Maybe<RnContacts>>>;
  /** МЛ-Персона */
  ContactsList?: Maybe<Array<Maybe<MlPersonItem>>>;
  /** Список зон покрытия */
  Coverage?: Maybe<MlCoverage>;
  /** Список элементов скидок */
  DiscountItems?: Maybe<Array<Maybe<RnDiscount>>>;
  /** Элемент инвентаря со всеми параметрами */
  InventoryItems?: Maybe<Array<Maybe<RnInventoryItem>>>;
  /** Список элементов наценки */
  MarkUpItems?: Maybe<Array<Maybe<RnMarkup>>>;
  /** Список медиагрупп */
  MediaGroup?: Maybe<MlMediaGroups>;
  /** Список медиагрупп */
  MediaType?: Maybe<MlMediaType>;
  /** партнёры */
  NetworkPartner?: Maybe<MlPartner>;
  /** партнёры */
  Owner?: Maybe<MlPartner>;
  /** МЛ-Юридическое лицо */
  Partner_legal_entity?: Maybe<MlUrFaceItem>;
  /** прайс */
  Price?: Maybe<RnPrice>;
  /** Регион РФ */
  Region?: Maybe<MlRegion>;
  /** Регион РФ */
  RegionList?: Maybe<Array<Maybe<MlRegion>>>;
  /** партнёры */
  Seller?: Maybe<MlPartner>;
  /** Список элементов суперком */
  SupercomItems?: Maybe<Array<Maybe<RnSupercom>>>;
  /** Тематика */
  Thematics?: Maybe<MlThematics>;
  /** Юзер */
  User?: Maybe<RnUser>;
  /** С НДС или без */
  Vat?: Maybe<Vat>;
  /** Уровень НДС */
  VatRate?: Maybe<VatRate>;
  /** комментарий оффера */
  adequate?: Maybe<Scalars['Int']>;
  /** комментарий по адекватности */
  adequate_comment?: Maybe<Scalars['String']>;
  /** COMON ID of author */
  authorComonID?: Maybe<Scalars['Int']>;
  /** id - уникальный идентификатор автора */
  authorId?: Maybe<Scalars['Int']>;
  /** Список файлов */
  buyer_note_file?: Maybe<Array<Maybe<File>>>;
  /** Сертификат */
  certificate?: Maybe<Scalars['String']>;
  /** проверено байером */
  check_date?: Maybe<Scalars['DateTime']>;
  /** проверено байером */
  check_user?: Maybe<Scalars['Int']>;
  /** проверено байером */
  checked?: Maybe<Scalars['Int']>;
  /** ID городов */
  city?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** комментарий оффера */
  comment?: Maybe<Scalars['String']>;
  /** object_uid */
  content_comment?: Maybe<Scalars['String']>;
  /** id страны */
  country?: Maybe<Scalars['Int']>;
  /** id охвата */
  coverage?: Maybe<Scalars['Int']>;
  /** описание оффера */
  description?: Maybe<Scalars['String']>;
  /** Правило прямого клиента */
  direct_client_rule?: Maybe<Scalars['String']>;
  /** id территории распространения */
  distributionTerritory?: Maybe<Scalars['Int']>;
  /** Черновик (0/1) */
  draft?: Maybe<Scalars['Int']>;
  /** Федеральная площадка */
  federal?: Maybe<Scalars['Int']>;
  /** id федерального округа */
  federalDistrict?: Maybe<Scalars['Int']>;
  /** Список файлов */
  files?: Maybe<Array<Maybe<File>>>;
  /** атрибут скрытой площадки */
  hidden?: Maybe<Scalars['Int']>;
  /** id - уникальный идентификатор записи */
  id?: Maybe<Scalars['Int']>;
  /** иностранная площадка */
  isForeign?: Maybe<Scalars['Int']>;
  /** Площадка суббайера */
  isSubBuyer?: Maybe<Scalars['Boolean']>;
  /** язык */
  language?: Maybe<Scalars['String']>;
  /** Список логотипов */
  logo?: Maybe<Array<Maybe<File>>>;
  /** id медиа-группы */
  mediaGroup?: Maybe<Scalars['Int']>;
  /** id вида медиа */
  mediaType?: Maybe<Scalars['Int']>;
  /** id медиасети */
  media_network?: Maybe<Scalars['Int']>;
  /** id в медиаланшафте */
  ml_id?: Maybe<Scalars['Int']>;
  /** Наименование площадки */
  name?: Maybe<Scalars['String']>;
  /** id сетевого партнера */
  networkPartner?: Maybe<Scalars['Int']>;
  /** object_uid */
  object_uid?: Maybe<Scalars['String']>;
  /** id владельца */
  owner?: Maybe<Scalars['Int']>;
  /** id юр лица владельца */
  partner_legal_entity?: Maybe<Scalars['Int']>;
  /** Тип острочки */
  payment_delay?: Maybe<Scalars['Int']>;
  /** Число для отсрочки */
  payment_delay_value?: Maybe<Scalars['Int']>;
  /** Список файлов */
  priceFile?: Maybe<Array<Maybe<File>>>;
  /** прайс необходим */
  price_is_needed?: Maybe<Scalars['Int']>;
  /** ID Регионов */
  region?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** id селлера */
  seller?: Maybe<Scalars['Int']>;
  /** Адрес страницы статистики */
  statisticsUrl?: Maybe<Scalars['String']>;
  /** ссылка на ца */
  targetAudienceLink?: Maybe<Scalars['String']>;
  /** Список: Элемент таргетинга */
  targeting?: Maybe<Array<Maybe<PlatformTargeting>>>;
  /** COMON task comment */
  taskComment?: Maybe<Scalars['String']>;
  /** Список файлов */
  technical_requirements_banner_file?: Maybe<Array<Maybe<File>>>;
  /** Список файлов */
  technical_requirements_publication_file?: Maybe<Array<Maybe<File>>>;
  /** Список привязанных тематик */
  thematics?: Maybe<Array<Maybe<MlThematics>>>;
  /** Аудитория */
  totalAudience?: Maybe<Scalars['Float']>;
  /** Дата создания текущей версии */
  updateDate?: Maybe<Scalars['DateTime']>;
  /** Адрес сайта */
  url?: Maybe<Scalars['String']>;
  /** Непонятно, вроде, ссылка */
  www?: Maybe<Scalars['String']>;
};

/** Карточка платформы */
export type RnPlatformInput = {
  /** Загрузка списка суперком */
  CoefficientItems?: Maybe<Array<Maybe<RnCoefficientInput>>>;
  /** Загрузка списка элементов контактов */
  ContactsItems?: Maybe<Array<Maybe<RnContactsInput>>>;
  /** Загрузка списка элементов скидок */
  DiscountItems?: Maybe<Array<Maybe<RnDiscountInput>>>;
  /** Загрузка списка инвентарей */
  InventoryItems?: Maybe<Array<Maybe<RnInventoryItemInput>>>;
  /** Загрузка списка элементов наценок */
  MarkUpItems?: Maybe<Array<Maybe<RnMarkupInput>>>;
  /** Информация о прайсе */
  Price?: Maybe<RnPriceInput>;
  /** Загрузка списка суперком */
  SupercomItems?: Maybe<Array<Maybe<RnSupercomInput>>>;
  /** комментарий оффера */
  adequate?: Maybe<Scalars['Int']>;
  /** комментарий по адекватности */
  adequate_comment?: Maybe<Scalars['String']>;
  /** COMON ID of author */
  authorComonID?: Maybe<Scalars['Int']>;
  /** id - уникальный идентификатор автора */
  authorId?: Maybe<Scalars['Int']>;
  /** массив id файлов для оффера */
  buyer_note_file?: Maybe<Array<Maybe<Scalars['Int']>>>;
  /** Сертификат */
  certificate?: Maybe<Scalars['String']>;
  /** проверено байером */
  check_date?: Maybe<Scalars['DateTime']>;
  /** проверено байером */
  check_user?: Maybe<Scalars['Int']>;
  /** проверено байером */
  checked?: Maybe<Scalars['Int']>;
  /** ID городов */
  city?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** комментарий оффера */
  comment?: Maybe<Scalars['String']>;
  /** object_uid */
  content_comment?: Maybe<Scalars['String']>;
  /** id страны */
  country?: Maybe<Scalars['Int']>;
  /** id охвата */
  coverage?: Maybe<Scalars['Int']>;
  /** описание оффера */
  description?: Maybe<Scalars['String']>;
  /** Правило прямого клиента */
  direct_client_rule?: Maybe<Scalars['String']>;
  /** id территории распространения */
  distributionTerritory?: Maybe<Scalars['Int']>;
  /** Черновик (0/1) */
  draft?: Maybe<Scalars['Int']>;
  /** Федеральная площадка */
  federal?: Maybe<Scalars['Int']>;
  /** id федерального округа */
  federalDistrict?: Maybe<Scalars['Int']>;
  /** массив id файлов для оффера */
  files?: Maybe<Array<Maybe<Scalars['Int']>>>;
  /** атрибут скрытой площадки */
  hidden?: Maybe<Scalars['Int']>;
  /** id - уникальный идентификатор записи */
  id?: Maybe<Scalars['Int']>;
  /** иностранная площадка */
  isForeign?: Maybe<Scalars['Int']>;
  /** Площадка суббайера */
  isSubBuyer?: Maybe<Scalars['Boolean']>;
  /** язык */
  language?: Maybe<Scalars['String']>;
  /** Логотип платформы */
  logo?: Maybe<Array<Maybe<Scalars['Int']>>>;
  /** id медиа-группы */
  mediaGroup?: Maybe<Scalars['Int']>;
  /** id вида медиа */
  mediaType?: Maybe<Scalars['Int']>;
  /** id медиасети */
  media_network?: Maybe<Scalars['Int']>;
  /** id в медиаланшафте */
  ml_id?: Maybe<Scalars['Int']>;
  /** Наименование площадки */
  name?: Maybe<Scalars['String']>;
  /** id сетевого партнера */
  networkPartner?: Maybe<Scalars['Int']>;
  /** object_uid */
  object_uid?: Maybe<Scalars['String']>;
  /** id владельца */
  owner?: Maybe<Scalars['Int']>;
  /** id юр лица владельца */
  partner_legal_entity?: Maybe<Scalars['Int']>;
  /** Тип острочки */
  payment_delay?: Maybe<Scalars['Int']>;
  /** Число для отсрочки */
  payment_delay_value?: Maybe<Scalars['Int']>;
  /** массив id файлов для оффера */
  priceFile?: Maybe<Array<Maybe<Scalars['Int']>>>;
  /** прайс необходим */
  price_is_needed?: Maybe<Scalars['Int']>;
  /** ID Регионов */
  region?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** id селлера */
  seller?: Maybe<Scalars['Int']>;
  /** Адрес страницы статистики */
  statisticsUrl?: Maybe<Scalars['String']>;
  /** ссылка на ца */
  targetAudienceLink?: Maybe<Scalars['String']>;
  /** Загрузка списка:Загрузка данных элемента таргетинга */
  targeting?: Maybe<Array<Maybe<PlatformTargetingInput>>>;
  /** COMON task comment */
  taskComment?: Maybe<Scalars['String']>;
  /** массив id файлов для оффера */
  technical_requirements_banner_file?: Maybe<Array<Maybe<Scalars['Int']>>>;
  /** массив id файлов для оффера */
  technical_requirements_publication_file?: Maybe<Array<Maybe<Scalars['Int']>>>;
  /** Загрузка списка тематик */
  thematics?: Maybe<Array<Maybe<MlThematicsInput>>>;
  /** Аудитория */
  totalAudience?: Maybe<Scalars['Float']>;
  /** Дата создания текущей версии */
  updateDate?: Maybe<Scalars['DateTime']>;
  /** Адрес сайта */
  url?: Maybe<Scalars['String']>;
  /** Непонятно, вроде, ссылка */
  www?: Maybe<Scalars['String']>;
};

/** прайс */
export type RnPrice = {
  __typename?: 'rnPrice';
  /** Юзер */
  User?: Maybe<RnUser>;
  /** НДС в цене */
  authorId?: Maybe<Scalars['Int']>;
  /** Замечание байера */
  buyer_note?: Maybe<Scalars['String']>;
  /** Способ расчета цены */
  calculatingMethod?: Maybe<Scalars['Int']>;
  /** Комментарий по прайсу */
  comment?: Maybe<Scalars['String']>;
  /** Комментарий по прайсу */
  comment_2?: Maybe<Scalars['String']>;
  /** Валюта */
  currency?: Maybe<Scalars['Int']>;
  /** Дата конца */
  endDate?: Maybe<Scalars['DateTime']>;
  /** Дата заполнения */
  entryDate?: Maybe<Scalars['DateTime']>;
  /** id - уникальный идентификатор записи */
  id?: Maybe<Scalars['Int']>;
  /** Logo */
  logo?: Maybe<Scalars['Int']>;
  /** Мера */
  measure?: Maybe<Scalars['Int']>;
  /** название страницы */
  pageTitle?: Maybe<Scalars['String']>;
  /** ссылка на страницу размещения */
  pageUrl?: Maybe<Scalars['String']>;
  /** pid - уникальный идентификатор записи */
  pid?: Maybe<Scalars['Int']>;
  /** pid - уникальный идентификатор записи */
  platformId?: Maybe<Scalars['Int']>;
  /** Цена */
  price?: Maybe<Scalars['Int']>;
  /** Условие */
  priceCondition?: Maybe<Scalars['Int']>;
  /** Вид цены */
  priceType?: Maybe<Scalars['Int']>;
  /** НДС в цене */
  priceVat?: Maybe<Scalars['Int']>;
  /** Ссылка на прайс */
  price_url?: Maybe<Scalars['String']>;
  /** Размер закупа, дней */
  purchase?: Maybe<Scalars['Int']>;
  /** Ротация, % */
  rotation?: Maybe<Scalars['Int']>;
  /** Скриншот */
  screenshot?: Maybe<Scalars['Int']>;
  /** Дата начала */
  startDate?: Maybe<Scalars['DateTime']>;
  /** Комментарий по прайсу */
  technical_requirements_publication?: Maybe<Scalars['String']>;
  /** Дата обновления */
  updateDate?: Maybe<Scalars['DateTime']>;
  /** Значение */
  value?: Maybe<Scalars['Int']>;
  /** Цена включает НДС */
  vat?: Maybe<Scalars['Int']>;
  /** Ставка НДС, % */
  vatRate?: Maybe<Scalars['Int']>;
};

/** прайс */
export type RnPriceInput = {
  /** НДС в цене */
  authorId?: Maybe<Scalars['Int']>;
  /** Замечание байера */
  buyer_note?: Maybe<Scalars['String']>;
  /** Способ расчета цены */
  calculatingMethod?: Maybe<Scalars['Int']>;
  /** Комментарий по прайсу */
  comment?: Maybe<Scalars['String']>;
  /** Комментарий по прайсу */
  comment_2?: Maybe<Scalars['String']>;
  /** Валюта */
  currency?: Maybe<Scalars['Int']>;
  /** Дата конца */
  endDate?: Maybe<Scalars['DateTime']>;
  /** Дата заполнения */
  entryDate?: Maybe<Scalars['DateTime']>;
  /** id - уникальный идентификатор записи */
  id?: Maybe<Scalars['Int']>;
  /** Logo */
  logo?: Maybe<Scalars['Int']>;
  /** Мера */
  measure?: Maybe<Scalars['Int']>;
  /** название страницы */
  pageTitle?: Maybe<Scalars['String']>;
  /** ссылка на страницу размещения */
  pageUrl?: Maybe<Scalars['String']>;
  /** pid - уникальный идентификатор записи */
  pid?: Maybe<Scalars['Int']>;
  /** pid - уникальный идентификатор записи */
  platformId?: Maybe<Scalars['Int']>;
  /** Цена */
  price?: Maybe<Scalars['Int']>;
  /** Условие */
  priceCondition?: Maybe<Scalars['Int']>;
  /** Вид цены */
  priceType?: Maybe<Scalars['Int']>;
  /** НДС в цене */
  priceVat?: Maybe<Scalars['Int']>;
  /** Ссылка на прайс */
  price_url?: Maybe<Scalars['String']>;
  /** Размер закупа, дней */
  purchase?: Maybe<Scalars['Int']>;
  /** Ротация, % */
  rotation?: Maybe<Scalars['Int']>;
  /** Скриншот */
  screenshot?: Maybe<Scalars['Int']>;
  /** Дата начала */
  startDate?: Maybe<Scalars['DateTime']>;
  /** Комментарий по прайсу */
  technical_requirements_publication?: Maybe<Scalars['String']>;
  /** Дата обновления */
  updateDate?: Maybe<Scalars['DateTime']>;
  /** Значение */
  value?: Maybe<Scalars['Int']>;
  /** Цена включает НДС */
  vat?: Maybe<Scalars['Int']>;
  /** Ставка НДС, % */
  vatRate?: Maybe<Scalars['Int']>;
};

/** Отчет о запрошенных данных */
export type RnProviderPayloadItem = {
  __typename?: 'RnProviderPayloadItem';
  cityName?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  platformName?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Int']>;
};

/** Стандартный объект */
export type RnQuery = {
  __typename?: 'RnQuery';
  /** Элемент лога инвентарей */
  InventoryItemLogList?: Maybe<ItemLog>;
  /** Элемент цены инвентаря */
  InventoryItemPriceItem?: Maybe<RnInventoryItemPriceItem>;
  /** Элемент списка таргетинга */
  InventoryItemTargeting?: Maybe<RnInventoryItemTargeting>;
  /** Элемент истории */
  MpItemReport?: Maybe<RnMpItemReport>;
  /** Список элементов истории в отчете */
  MpItemReportList?: Maybe<Array<Maybe<RnMpItemReport>>>;
  /** Список элементов истории в отчете */
  MpItemReportListPager?: Maybe<ListWithPager_RnMpItemReport>;
  /** List of страница инвентаря */
  RnInventoryPageList?: Maybe<Array<Maybe<RefRnInventoryPage>>>;
  /** Отчет по брифам */
  briefReport?: Maybe<Scalars['String']>;
  /** email контент-менджера */
  getContentManagerEmail?: Maybe<Scalars['String']>;
  /** Выгрузка данных в эксель, аргумент - id медиаплана, возврат - файл эксель в виде строки base64 */
  getContentReport?: Maybe<Scalars['String']>;
  /** История закупа */
  itemLogList?: Maybe<Array<Maybe<RnLog>>>;
  /** Список отчетов */
  mPlanItemReportList?: Maybe<Array<Maybe<MPlanItemReport>>>;
  /** Отчет xls по медиапланам */
  mpReportXls?: Maybe<Scalars['String']>;
  /** Отчет xls по заказам */
  orderReportXls?: Maybe<Scalars['String']>;
  /** Список отчетов */
  providerPayloadItemList?: Maybe<Array<Maybe<RnProviderPayloadItem>>>;
};


/** Стандартный объект */
export type RnQueryInventoryItemLogListArgs = {
  id?: Maybe<Scalars['ID']>;
};


/** Стандартный объект */
export type RnQueryInventoryItemPriceItemArgs = {
  id?: Maybe<Scalars['ID']>;
};


/** Стандартный объект */
export type RnQueryInventoryItemTargetingArgs = {
  id?: Maybe<Scalars['ID']>;
};


/** Стандартный объект */
export type RnQueryMpItemReportArgs = {
  id?: Maybe<Scalars['ID']>;
};


/** Стандартный объект */
export type RnQueryMpItemReportListArgs = {
  params?: Maybe<ParamsInput>;
};


/** Стандартный объект */
export type RnQueryMpItemReportListPagerArgs = {
  params?: Maybe<ParamsInput>;
};


/** Стандартный объект */
export type RnQueryRnInventoryPageListArgs = {
  params?: Maybe<ParamsInput>;
};


/** Стандартный объект */
export type RnQueryBriefReportArgs = {
  dateFinish?: Maybe<Scalars['DateTime']>;
  dateStart?: Maybe<Scalars['DateTime']>;
};


/** Стандартный объект */
export type RnQueryGetContentReportArgs = {
  mpId?: Maybe<Scalars['Int']>;
};


/** Стандартный объект */
export type RnQueryItemLogListArgs = {
  dateFinish?: Maybe<Scalars['DateTime']>;
  dateStart?: Maybe<Scalars['DateTime']>;
  geo?: Maybe<Scalars['String']>;
  inventoryID?: Maybe<Array<Maybe<Scalars['Int']>>>;
  mpID?: Maybe<Array<Maybe<Scalars['Int']>>>;
  partnerID?: Maybe<Array<Maybe<Scalars['Int']>>>;
  platformID?: Maybe<Array<Maybe<Scalars['Int']>>>;
};


/** Стандартный объект */
export type RnQueryMPlanItemReportListArgs = {
  params?: Maybe<ParamsInput>;
};


/** Стандартный объект */
export type RnQueryMpReportXlsArgs = {
  brandID?: Maybe<Scalars['Int']>;
  buyerID?: Maybe<Scalars['Int']>;
  dateFinish?: Maybe<Scalars['DateTime']>;
  dateStart?: Maybe<Scalars['DateTime']>;
  partnerID?: Maybe<Scalars['Int']>;
};


/** Стандартный объект */
export type RnQueryOrderReportXlsArgs = {
  dateFinish?: Maybe<Scalars['DateTime']>;
  dateStart?: Maybe<Scalars['DateTime']>;
};

/** Элемент суперком */
export type RnSupercom = {
  __typename?: 'RnSupercom';
  /** Юзер */
  User?: Maybe<RnUser>;
  /** База начисления */
  accrualBasis?: Maybe<Scalars['Int']>;
  /** Id автора */
  authorId?: Maybe<Scalars['Int']>;
  /** id - уникальный идентификатор записи */
  id?: Maybe<Scalars['Int']>;
  /** ID-платформы, к которому привязан элемент скидки */
  platformId?: Maybe<Scalars['Int']>;
  /** Дата начала */
  startDate?: Maybe<Scalars['DateTime']>;
  /** Размер */
  supercomAmount?: Maybe<Scalars['Float']>;
  /** ОТ */
  turnoverFrom?: Maybe<Scalars['Float']>;
  /** ДО */
  turnoverTo?: Maybe<Scalars['Float']>;
  /** Дата обновления */
  updateDate?: Maybe<Scalars['DateTime']>;
};

/** Загрузка данных суперком */
export type RnSupercomInput = {
  /** База начисления */
  accrualBasis?: Maybe<Scalars['Int']>;
  /** Id автора */
  authorId?: Maybe<Scalars['Int']>;
  /** ---- */
  callTrackingFile?: Maybe<Array<Maybe<Scalars['Int']>>>;
  /** id Файл-отчеты из CRM-системы */
  crmCustomersFile?: Maybe<Array<Maybe<Scalars['Int']>>>;
  /** ---- */
  ecommerceAccessFile?: Maybe<Array<Maybe<Scalars['Int']>>>;
  /** id - уникальный идентификатор записи */
  id?: Maybe<Scalars['Int']>;
  /** ID-платформы, к которому привязан элемент скидки */
  platformId?: Maybe<Scalars['Int']>;
  /** Дата начала */
  startDate?: Maybe<Scalars['DateTime']>;
  /** Размер */
  supercomAmount?: Maybe<Scalars['Float']>;
  /** ОТ */
  turnoverFrom?: Maybe<Scalars['Float']>;
  /** ДО */
  turnoverTo?: Maybe<Scalars['Float']>;
  /** Дата обновления */
  updateDate?: Maybe<Scalars['DateTime']>;
};

/** Юзер */
export type RnUser = {
  __typename?: 'RnUser';
  /** id пользователя */
  id?: Maybe<Scalars['Int']>;
  /** Логин пользователя */
  login?: Maybe<Scalars['String']>;
};

/** Спот размещения */
export type Spot = {
  __typename?: 'Spot';
  /** id - уникальный идентификатор договора?? */
  agrID?: Maybe<Scalars['Int']>;
  /** id - уникальный идентификатор ?? */
  allocationType?: Maybe<Scalars['Int']>;
  /** id - уникальный идентификатор ?? */
  atpID?: Maybe<Scalars['Int']>;
  /** id - уникальный идентификатор ?? */
  baseRating?: Maybe<Scalars['Int']>;
  /** id - уникальный идентификатор блока */
  blockID?: Maybe<Scalars['Int']>;
  /** ID установленного ролика */
  commInMplID?: Maybe<Scalars['Int']>;
  /** id - уникальный идентификатор ?? */
  currentAuctionBidValue?: Maybe<Scalars['Int']>;
  /** id - уникальный идентификатор ?? */
  dtpID?: Maybe<Scalars['Int']>;
  /** List of included films of blocks (ProgramBreak) */
  film?: Maybe<MPlan>;
  /** id - уникальный идентификатор ?? */
  fixPriority?: Maybe<Scalars['Int']>;
  /** id - уникальный идентификатор ?? */
  floatPriority?: Maybe<Scalars['Int']>;
  /** id - уникальный идентификатор ?? */
  isHumanBeing?: Maybe<Scalars['Int']>;
  /** ID медиаплана */
  mplID?: Maybe<Scalars['Int']>;
  /** id - уникальный идентификатор ?? */
  ordID?: Maybe<Scalars['Int']>;
  /** id - уникальный идентификатор ?? */
  ots?: Maybe<Scalars['Int']>;
  /** id - уникальный идентификатор позиционирования */
  positioning?: Maybe<Scalars['Int']>;
  /** id - уникальный идентификатор ?? */
  rating?: Maybe<Scalars['Int']>;
  /** id - уникальный идентификатор ?? */
  spotBroadcastTime?: Maybe<Scalars['Int']>;
  /** id - уникальный идентификатор ?? */
  spotFactBroadcastTime?: Maybe<Scalars['Int']>;
  /** id - уникальный идентификатор записи */
  spotID?: Maybe<Scalars['Int']>;
  /** id - уникальный идентификатор ?? */
  spotOrderNo?: Maybe<Scalars['Int']>;
  /** id - уникальный идентификатор записи */
  sptChnlPTR?: Maybe<Scalars['Int']>;
  /** id - уникальный идентификатор ?? */
  sptDateL?: Maybe<Scalars['Int']>;
  /** id - уникальный идентификатор ?? */
  tgrID?: Maybe<Scalars['Int']>;
  /** id - уникальный идентификатор ?? */
  tnsBlockID?: Maybe<Scalars['Int']>;
  /** id - уникальный идентификатор ?? */
  tnsSpotsID?: Maybe<Scalars['Int']>;
};

/** Таргетинги договора */
export type Targeting = {
  __typename?: 'Targeting';
  /** время конца */
  endTime?: Maybe<Scalars['String']>;
  /** id - уникальный идентификатор записи */
  id?: Maybe<Scalars['ID']>;
  /** время начала */
  startTime?: Maybe<Scalars['String']>;
  /** Название */
  status?: Maybe<Scalars['Int']>;
};

/** Методы для тестирования типов */
export type Tests = {
  __typename?: 'Tests';
  /**
   * Input (String) one of:
   * 
   * 
   * YYYY-MM-DD
   * 
   * 
   * DD-MM-YYYY
   * 
   * 
   * DD.MM.YYYY
   * 
   * 
   * --------------------------------------
   * 
   * 
   * Output : YYYY-MM-DD
   */
  DateCurrent?: Maybe<Scalars['Date']>;
  /**
   * Input (String) one of:
   * 
   * \t\tISO8601:2020-12-22T13:08:23+05:00
   * 
   * \t\tunix:1608624503
   * 
   * \t\tsource:2020-12-22 13:08:23
   * 
   * 
   * --------------------------------------
   * ISO8601: 2020-12-22T13:08:23+05:00
   */
  DateTimeCurrent?: Maybe<Scalars['DateTime']>;
  /**    Список цифр через запятую. */
  numberListTest?: Maybe<Scalars['ListOfInt']>;
};


/** Методы для тестирования типов */
export type TestsDateCurrentArgs = {
  date?: Maybe<Scalars['Date']>;
};


/** Методы для тестирования типов */
export type TestsDateTimeCurrentArgs = {
  date?: Maybe<Scalars['DateTime']>;
};


/** Методы для тестирования типов */
export type TestsNumberListTestArgs = {
  numbers?: Maybe<Scalars['ListOfInt']>;
};


/** --- */
export enum UploadFilePath {
  /** Файл контекста. */
  DigitalContext = 'DIGITAL_CONTEXT',
  /** Файлы OOH */
  OohFiles = 'OOH_FILES',
  /** Файлы rn */
  RnFiles = 'RN_FILES',
  /** Файлы rn */
  RnInventoryFiles = 'RN_INVENTORY_FILES',
  /** изображения rn */
  RnInventoryFilesImages = 'RN_INVENTORY_FILES_IMAGES',
  /** видео rn */
  RnInventoryFilesVideo = 'RN_INVENTORY_FILES_VIDEO',
  /** Файлы rn */
  RnPlatformFiles = 'RN_PLATFORM_FILES',
  /** изображения rn */
  RnPlatformFilesImages = 'RN_PLATFORM_FILES_IMAGES',
  /** видео rn */
  RnPlatformFilesVideo = 'RN_PLATFORM_FILES_VIDEO'
}

/** Пользовательские настройки АП */
export type UserConfig = {
  __typename?: 'UserConfig';
  /** - */
  ap_id?: Maybe<Scalars['Int']>;
  /** - */
  id?: Maybe<Scalars['String']>;
  /** - */
  name?: Maybe<Scalars['String']>;
  /** - */
  value?: Maybe<Scalars['String']>;
};

/** Загрузка данных */
export type UserInput = {
  /** comon_id если null значит человек вошёл как админ. */
  comon_id?: Maybe<Scalars['Int']>;
  /** Email пользователя */
  email?: Maybe<Scalars['String']>;
  /** id - уникальный идентификатор записи */
  id?: Maybe<Scalars['ID']>;
  /** Логин пользователя */
  login?: Maybe<Scalars['String']>;
  /** главный байер */
  top?: Maybe<Scalars['Int']>;
};

/** Информация о пользователе */
export type UserProfile = {
  __typename?: 'UserProfile';
  /** список точек доступа */
  AccessEndpoints?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** comon_id если null значит человек вошёл как админ. */
  comon_id?: Maybe<Scalars['Int']>;
  /** Email пользователя */
  email?: Maybe<Scalars['String']>;
  /** id - уникальный идентификатор записи */
  id?: Maybe<Scalars['ID']>;
  /** Логин пользователя */
  login?: Maybe<Scalars['String']>;
  /** главный байер */
  top?: Maybe<Scalars['Int']>;
};

/** С НДС или без */
export type Vat = {
  __typename?: 'Vat';
  /** id */
  id?: Maybe<Scalars['Int']>;
  /** С НДС или без */
  vat?: Maybe<Scalars['String']>;
};

/** Уровень НДС */
export type VatRate = {
  __typename?: 'VatRate';
  /** id */
  id?: Maybe<Scalars['Int']>;
  /** Ставка НДС */
  vatRate?: Maybe<Scalars['String']>;
};

/** Загрузка данных */
export type VimbBudgetInput = {
  /** id - уникальный HASH идентификатор записи */
  ID?: Maybe<Scalars['ID']>;
  /** ID рекламодателя ?? */
  adtID?: Maybe<Scalars['Int']>;
  /** название рекламодателя ?? */
  adtName?: Maybe<Scalars['String']>;
  /** id - уникальный идентификатор записи */
  agrID?: Maybe<Scalars['Int']>;
  /** наименование договора */
  agrName?: Maybe<Scalars['String']>;
  /** Бюджет */
  budget?: Maybe<Scalars['Float']>;
  /** Город вещания */
  city?: Maybe<VimbCityInput>;
  /** ?? */
  cmpName?: Maybe<Scalars['String']>;
  /** Месяц */
  cnlID?: Maybe<Scalars['Int']>;
  /** ?? */
  cnlName?: Maybe<Scalars['String']>;
  /** ?? */
  coordCost?: Maybe<Scalars['Float']>;
  /** ?? */
  cost?: Maybe<Scalars['Float']>;
  /** ?? */
  dealChannelStatus?: Maybe<Scalars['Int']>;
  /** Флаг удаления */
  deleted?: Maybe<Scalars['Int']>;
  /** ?? */
  fixPercent?: Maybe<Scalars['Int']>;
  /** ?? */
  fixPercentPrime?: Maybe<Scalars['Int']>;
  /** ?? */
  floatPercent?: Maybe<Scalars['Int']>;
  /** ?? */
  floatPercentPrime?: Maybe<Scalars['Int']>;
  /** GRP */
  grp?: Maybe<Scalars['Float']>;
  /** ?? */
  grpFix?: Maybe<Scalars['Float']>;
  /** ?? */
  grpWithoutKF?: Maybe<Scalars['Float']>;
  /** ?? */
  inventoryUnitDuration?: Maybe<Scalars['Int']>;
  /** Флаг для оптимизации */
  isMax?: Maybe<Scalars['Boolean']>;
  /** Плановый ли это контракт */
  isPlan?: Maybe<Scalars['Boolean']>;
  /** ?? */
  mngName?: Maybe<Scalars['String']>;
  /** Месяц */
  month?: Maybe<Scalars['String']>;
  /** Список роликов */
  mplans?: Maybe<Array<Maybe<VimbMpLanInput>>>;
  /** тип для оптимизации */
  opTypeID?: Maybe<Scalars['Int']>;
  /** значение для оптимизации */
  opValue?: Maybe<Scalars['Float']>;
  parentAgrID?: Maybe<Scalars['Int']>;
  /** целевое TRP */
  planTrp?: Maybe<Scalars['Float']>;
  /** Ценнобразование (хроно, grp) */
  priceType?: Maybe<Scalars['Int']>;
  /** Доля прайма */
  primeRate?: Maybe<Scalars['Float']>;
  /** использование прайма VIMB */
  primeTypeId?: Maybe<Scalars['Int']>;
  /** Направление продаж (база данны) */
  sellingDirection?: Maybe<Scalars['Int']>;
  tempID?: Maybe<Scalars['String']>;
  /** ?? */
  tp?: Maybe<Scalars['String']>;
};

/** Город вещания */
export type VimbCity = {
  __typename?: 'VimbCity';
  /** id - уникальный идентификатор записи */
  bcpCentralID?: Maybe<Scalars['Int']>;
  /** Название */
  bcpName?: Maybe<Scalars['String']>;
  /** id - канала */
  id?: Maybe<Scalars['Int']>;
};

/** Загрузка данных территории вещания */
export type VimbCityInput = {
  /** id - уникальный идентификатор записи */
  bcpCentralID?: Maybe<Scalars['Int']>;
  /** Название */
  bcpName?: Maybe<Scalars['String']>;
  /** id - канала */
  id?: Maybe<Scalars['Int']>;
};

/** Контракт VIMB */
export type VimbContract = {
  __typename?: 'VimbContract';
  /** ID рекламодателя ?? */
  adtID?: Maybe<Scalars['Int']>;
  /** название рекламодателя ?? */
  adtName?: Maybe<Scalars['String']>;
  /** ID договора */
  agrID: Scalars['ID'];
  /** Наименование договора */
  agrName?: Maybe<Scalars['String']>;
  /** Название бренда */
  brandName?: Maybe<Scalars['String']>;
  /** Общий бюджет */
  budget?: Maybe<Scalars['Float']>;
  /** List ofБюджет (контракт) VIMB */
  budgets?: Maybe<Array<Maybe<Budget>>>;
  /** ID телеканала */
  cnlID?: Maybe<Scalars['Int']>;
  /** Имя канала */
  cnlName?: Maybe<Scalars['String']>;
  /** ?? */
  coordCost?: Maybe<Scalars['Float']>;
  /** ?? */
  cost?: Maybe<Scalars['Float']>;
  /** ?? */
  dealChannelStatus?: Maybe<Scalars['Int']>;
  /** --[] */
  documents?: Maybe<Array<Maybe<File>>>;
  /** Список фильтров договора */
  filters?: Maybe<Array<Maybe<VimbFilter>>>;
  /** ?? */
  fixPercent?: Maybe<Scalars['Int']>;
  /** ?? */
  fixPercentPrime?: Maybe<Scalars['Int']>;
  /** ?? */
  floatPercent?: Maybe<Scalars['Int']>;
  /** ?? */
  floatPercentPrime?: Maybe<Scalars['Int']>;
  /** плановый GRP */
  grp?: Maybe<Scalars['Float']>;
  /** ?? */
  grpFix?: Maybe<Scalars['Float']>;
  /** Весь GRP */
  grpTotalFact?: Maybe<Scalars['Float']>;
  /** ?? */
  grpWithoutKF?: Maybe<Scalars['Float']>;
  /** Выходы */
  impressions?: Maybe<Scalars['Int']>;
  /** List of: Таргетинги договора */
  intervals?: Maybe<Array<Maybe<Targeting>>>;
  /** ?? */
  inventoryUnitDuration?: Maybe<Scalars['Int']>;
  /** Это плановый контракт? */
  isPlan?: Maybe<Scalars['Boolean']>;
  /** ?? */
  mngName?: Maybe<Scalars['String']>;
  /** Месяц */
  month?: Maybe<Scalars['String']>;
  /** дата начала */
  ordBegDate?: Maybe<Scalars['Int']>;
  /** дата конца */
  ordEndDate?: Maybe<Scalars['Int']>;
  /** Трафик-менеджер */
  ordManager?: Maybe<Scalars['String']>;
  /** Целевая аудитория[] */
  ta?: Maybe<Array<Maybe<VimbTargetAudience>>>;
  /** Таргетинги договора */
  targeting?: Maybe<Array<Maybe<VimbTargeting>>>;
  /** Весь TRP */
  totalFilmDuration?: Maybe<Scalars['Float']>;
  /** ?? */
  tp?: Maybe<Scalars['String']>;
  /** Весь TRP */
  trpTotalFact?: Maybe<Scalars['Float']>;
  /** дата обновления */
  updateDate?: Maybe<Scalars['String']>;
};

/** Клиент */
export type VimbCustomer = {
  __typename?: 'VimbCustomer';
  /** ?? */
  advID?: Maybe<Scalars['String']>;
  /** ID клиента */
  custID?: Maybe<Scalars['String']>;
  /** id - уникальный идентификатор записи */
  id: Scalars['String'];
  /** Название клиента */
  name?: Maybe<Scalars['String']>;
};

/** Скидки внутри МП */
export type VimbDiscount = {
  __typename?: 'VimbDiscount';
  /** Коэффициент скидки */
  discountFactor?: Maybe<Scalars['Float']>;
  /** Наименование скидки */
  discountTypeName?: Maybe<Scalars['String']>;
  /** id - уникальный идентификатор записи */
  mplID: Scalars['String'];
  /** Тип скидки */
  typeID?: Maybe<Scalars['Float']>;
  /** Value ID */
  valueID?: Maybe<Scalars['Float']>;
};

/** Загрузка данных фильма */
export type VimbFilmInput = {
  /** id - уникальный идентификатор записи ролика */
  commInMplID: Scalars['ID'];
  /** название ролика */
  filmName?: Maybe<Scalars['String']>;
  /** месяц */
  mplMonth?: Maybe<Scalars['Int']>;
};

/** Фильтр VIMB-контракта */
export type VimbFilter = {
  __typename?: 'VimbFilter';
  ID?: Maybe<Scalars['ID']>;
  action?: Maybe<Scalars['Int']>;
  agrID?: Maybe<Scalars['Int']>;
  entityID?: Maybe<Scalars['String']>;
  entityName?: Maybe<Scalars['String']>;
  entityTypeID?: Maybe<Scalars['Int']>;
  /** Список типов в фильтре */
  types?: Maybe<Array<Maybe<VimbFilterType>>>;
};

/** Загрузка данных элемента фильтров */
export type VimbFilterInput = {
  ID?: Maybe<Scalars['ID']>;
  action?: Maybe<Scalars['Int']>;
  agrID?: Maybe<Scalars['Int']>;
  entityID?: Maybe<Scalars['String']>;
  entityName?: Maybe<Scalars['String']>;
  entityTypeID?: Maybe<Scalars['Int']>;
  tempID?: Maybe<Scalars['String']>;
  types?: Maybe<Array<Maybe<VimbFilterTypeInput>>>;
};

/** Типофильтр конкретного фильтра */
export type VimbFilterType = {
  __typename?: 'VimbFilterType';
  filterComment?: Maybe<Scalars['String']>;
  filterID?: Maybe<Scalars['Int']>;
  filterTypeID?: Maybe<Scalars['Int']>;
  filterValue?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** Загрузка данных типофильтров */
export type VimbFilterTypeInput = {
  filterComment?: Maybe<Scalars['String']>;
  filterID?: Maybe<Scalars['Int']>;
  filterTypeID?: Maybe<Scalars['Int']>;
  filterValue?: Maybe<Array<Maybe<Scalars['String']>>>;
  tempID?: Maybe<Scalars['String']>;
};

/** Траффик-менеджер */
export type VimbManager = {
  __typename?: 'VimbManager';
  /** Трафик-менеджер */
  ordManager?: Maybe<Scalars['String']>;
};

/** Загрузка данных */
export type VimbMpLanInput = {
  /** ID ?? */
  adtID?: Maybe<Scalars['Int']>;
  /** ?? */
  adtName?: Maybe<Scalars['String']>;
  /** ?? */
  advID?: Maybe<Scalars['Int']>;
  /** ?? */
  agrName?: Maybe<Scalars['Int']>;
  /** ?? */
  allocationType?: Maybe<Scalars['Int']>;
  /** ?? */
  amountFact?: Maybe<Scalars['Int']>;
  /** ?? */
  amountPlan?: Maybe<Scalars['Int']>;
  /** ID договора ?? */
  argID?: Maybe<Scalars['Int']>;
  /** ?? */
  brandID?: Maybe<Scalars['Int']>;
  /** ?? */
  brandName?: Maybe<Scalars['String']>;
  /** ?? */
  commInMplID?: Maybe<Scalars['Int']>;
  /** ?? */
  contractBeg?: Maybe<Scalars['String']>;
  /** ?? */
  contractEnd?: Maybe<Scalars['String']>;
  /** ?? */
  cpPoffprime?: Maybe<Scalars['Int']>;
  /** ?? */
  cpPprime?: Maybe<Scalars['Int']>;
  /** ?? */
  dateFrom?: Maybe<Scalars['String']>;
  /** ?? */
  dateTo?: Maybe<Scalars['String']>;
  /** ?? */
  dealChannelStatus?: Maybe<Scalars['Int']>;
  deleted?: Maybe<Scalars['Int']>;
  /** ?? */
  doubleAdvertiser?: Maybe<Scalars['String']>;
  /** ?? */
  dtpID?: Maybe<Scalars['Int']>;
  /** ?? */
  dublSpot?: Maybe<Scalars['Int']>;
  /** ?? */
  fbrName?: Maybe<Scalars['String']>;
  /** ?? */
  ffoaAllocated?: Maybe<Scalars['Int']>;
  /** ?? */
  ffoaLawAcc?: Maybe<Scalars['Int']>;
  /** ?? */
  filmDur?: Maybe<Scalars['Int']>;
  /** ?? */
  filmDurKoef?: Maybe<Scalars['Int']>;
  /** ?? */
  filmID?: Maybe<Scalars['Int']>;
  /** ?? */
  filmName?: Maybe<Scalars['String']>;
  /** ?? */
  filmVersion?: Maybe<Scalars['String']>;
  /** ?? */
  fixPriceAsFloat?: Maybe<Scalars['Int']>;
  /** ?? */
  fixPriority?: Maybe<Scalars['Int']>;
  /** ?? */
  grp?: Maybe<Scalars['Int']>;
  /** ?? */
  grpFact?: Maybe<Scalars['Int']>;
  /** ?? */
  grpPlan?: Maybe<Scalars['Int']>;
  /** ?? */
  grpShift?: Maybe<Scalars['Int']>;
  /** ?? */
  grpTotal?: Maybe<Scalars['Int']>;
  /** ?? */
  grpTotalPrime?: Maybe<Scalars['Int']>;
  /** ?? */
  hasReserve?: Maybe<Scalars['Int']>;
  /** Standard duration of film in seconds */
  inventoryUnitDuration?: Maybe<Scalars['Int']>;
  /** ?? */
  mplCbrID?: Maybe<Scalars['Int']>;
  /** ?? */
  mplCbrName?: Maybe<Scalars['String']>;
  /** ?? */
  mplCnlID?: Maybe<Scalars['Int']>;
  /** id - уникальный идентификатор записи */
  mplID?: Maybe<Scalars['Int']>;
  /** ?? */
  mplMonth?: Maybe<Scalars['String']>;
  /** ?? */
  mplName?: Maybe<Scalars['String']>;
  /** ?? */
  mplState?: Maybe<Scalars['String']>;
  /** ?? */
  multiple?: Maybe<Scalars['Int']>;
  /** ?? */
  obdPos?: Maybe<Scalars['Int']>;
  /** ?? */
  ordBegDate?: Maybe<Scalars['String']>;
  /** ?? */
  ordEndDate?: Maybe<Scalars['String']>;
  /** ?? */
  ordFrID?: Maybe<Scalars['Int']>;
  /** ?? */
  ordID?: Maybe<Scalars['Int']>;
  /** ?? */
  ordIsTriggered?: Maybe<Scalars['Int']>;
  /** ?? */
  ordManager?: Maybe<Scalars['String']>;
  /** ?? */
  ordName?: Maybe<Scalars['String']>;
  /** ?? */
  pbaCond?: Maybe<Scalars['String']>;
  /** ?? */
  pbaObjID?: Maybe<Scalars['Int']>;
  /** ?? */
  prodClassID?: Maybe<Scalars['Int']>;
  /** ?? */
  splitMessageGroupID?: Maybe<Scalars['Int']>;
  /** ?? */
  sumShift?: Maybe<Scalars['Int']>;
  tempID?: Maybe<Scalars['String']>;
  /** ?? */
  tgrID?: Maybe<Scalars['Int']>;
  /** ?? */
  tgrName?: Maybe<Scalars['String']>;
  /** ?? */
  tpName?: Maybe<Scalars['String']>;
};

/** Стандартный объект */
export type VimbMutation = {
  __typename?: 'VimbMutation';
  /** Добавление таргетингов в контракт */
  addIntervalList?: Maybe<Scalars['Boolean']>;
  /** Добавление ц аудитории */
  addPrimeItem?: Maybe<Scalars['Int']>;
  /** Добавление ц аудитории */
  addTargetAudience?: Maybe<Scalars['Int']>;
  /** Список Бюджетов */
  clonePlanContract?: Maybe<Scalars['Boolean']>;
  /** Добавление ЦА в контракт */
  createContractTa?: Maybe<Scalars['Boolean']>;
  /** Добавление таргетинга */
  createFilter?: Maybe<VimbFilter>;
  /** Список Бюджетов */
  createPlanContract?: Maybe<Scalars['Boolean']>;
  /** Удалить Бюджет */
  deleteBudget?: Maybe<Scalars['Boolean']>;
  /** Добавление таргетинга */
  deleteFilter?: Maybe<VimbFilter>;
  /** Отправка данных для оптимизации */
  optimizeMp?: Maybe<Array<Maybe<PythonResponse>>>;
  /** Удаление файла */
  removeFile?: Maybe<File>;
  /** удалить прайм */
  removePrimeItem?: Maybe<Prime>;
  /** Удаление ц аудитории */
  removeTargetAudience?: Maybe<Scalars['Int']>;
  /** Добавление таргетинга */
  targetingCreate?: Maybe<VimbTargeting>;
  /** Обновление данных таргетинга */
  targetingDelete?: Maybe<VimbTargeting>;
  /** Обновление данных таргетинга */
  targetingUpdate?: Maybe<VimbTargeting>;
  /** Добавление таргетинга */
  updateFilter?: Maybe<VimbFilter>;
  /** Список Бюджетов */
  updatePlanContract?: Maybe<Scalars['Boolean']>;
  /** Обновить прайм */
  updatePrimeItem?: Maybe<VimbPrimeItem>;
  /** Обновление аудитории */
  updateTargetAudience?: Maybe<VimbTargetAudience>;
  /** Загрука файлов */
  uploadFile?: Maybe<File>;
  /** Отправка данных в ВИМБ */
  vimbSave?: Maybe<Scalars['String']>;
};


/** Стандартный объект */
export type VimbMutationAddIntervalListArgs = {
  agrID?: Maybe<Scalars['Int']>;
  intervalList?: Maybe<Array<Maybe<IntervalInput>>>;
};


/** Стандартный объект */
export type VimbMutationClonePlanContractArgs = {
  agrID?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};


/** Стандартный объект */
export type VimbMutationCreateContractTaArgs = {
  agrID?: Maybe<Scalars['Int']>;
  taList?: Maybe<Array<Maybe<Scalars['Int']>>>;
};


/** Стандартный объект */
export type VimbMutationCreateFilterArgs = {
  filter?: Maybe<VimbFilterInput>;
};


/** Стандартный объект */
export type VimbMutationCreatePlanContractArgs = {
  budgets?: Maybe<Array<Maybe<VimbBudgetInput>>>;
};


/** Стандартный объект */
export type VimbMutationDeleteBudgetArgs = {
  agrID?: Maybe<Scalars['Int']>;
};


/** Стандартный объект */
export type VimbMutationDeleteFilterArgs = {
  filterID?: Maybe<Scalars['Int']>;
};


/** Стандартный объект */
export type VimbMutationOptimizeMpArgs = {
  agrID?: Maybe<Scalars['Int']>;
  blockIDList?: Maybe<Array<Maybe<Scalars['Int']>>>;
  grpOffPrime?: Maybe<Scalars['Float']>;
  grpPrime?: Maybe<Scalars['Float']>;
  mplMonth?: Maybe<Scalars['Int']>;
  noCheck?: Maybe<Scalars['Boolean']>;
};


/** Стандартный объект */
export type VimbMutationRemoveFileArgs = {
  fileID?: Maybe<Scalars['Int']>;
};


/** Стандартный объект */
export type VimbMutationRemovePrimeItemArgs = {
  id?: Maybe<Scalars['Int']>;
};


/** Стандартный объект */
export type VimbMutationRemoveTargetAudienceArgs = {
  taID?: Maybe<Scalars['Int']>;
};


/** Стандартный объект */
export type VimbMutationTargetingCreateArgs = {
  agrID?: Maybe<Scalars['Int']>;
};


/** Стандартный объект */
export type VimbMutationTargetingDeleteArgs = {
  targetingID?: Maybe<Scalars['Int']>;
};


/** Стандартный объект */
export type VimbMutationTargetingUpdateArgs = {
  targeting?: Maybe<VimbTargetingInput>;
};


/** Стандартный объект */
export type VimbMutationUpdateFilterArgs = {
  filter?: Maybe<VimbFilterInput>;
};


/** Стандартный объект */
export type VimbMutationUpdatePlanContractArgs = {
  budgets?: Maybe<Array<Maybe<VimbBudgetInput>>>;
};


/** Стандартный объект */
export type VimbMutationUpdatePrimeItemArgs = {
  prime?: Maybe<VimbPrimeItemInput>;
};


/** Стандартный объект */
export type VimbMutationUpdateTargetAudienceArgs = {
  ta?: Maybe<VimbTargetAudienceInput>;
};


/** Стандартный объект */
export type VimbMutationUploadFileArgs = {
  agrID?: Maybe<Scalars['Int']>;
  file?: Maybe<Scalars['Upload']>;
};


/** Стандартный объект */
export type VimbMutationVimbSaveArgs = {
  agrID?: Maybe<Scalars['Int']>;
  blockList?: Maybe<Array<Maybe<OptimizedBlockInput>>>;
  isPlan?: Maybe<Scalars['Boolean']>;
  month?: Maybe<Scalars['Int']>;
};

/** Загрузка данных */
export type VimbPrimeInput = {
  /** праздники */
  holidays?: Maybe<Scalars['Boolean']>;
  /** Время от */
  id?: Maybe<Scalars['String']>;
  /** Время от */
  timeFrom?: Maybe<Scalars['String']>;
  /** Время до */
  timeTo?: Maybe<Scalars['String']>;
  /** будни */
  weekdays?: Maybe<Scalars['Boolean']>;
  /** выходные */
  weekend?: Maybe<Scalars['Boolean']>;
};

/** Элемент прайма */
export type VimbPrimeItem = {
  __typename?: 'VimbPrimeItem';
  /** Праймы */
  blocks?: Maybe<Array<Maybe<Prime>>>;
  /** ID */
  id?: Maybe<Scalars['ID']>;
  /** Наименование */
  name?: Maybe<Scalars['String']>;
};

/** Загрузка данных */
export type VimbPrimeItemInput = {
  blocks?: Maybe<Array<Maybe<VimbPrimeInput>>>;
  /** ID */
  id?: Maybe<Scalars['ID']>;
  /** Наименование */
  name?: Maybe<Scalars['String']>;
};

/** системное сообщение */
export type VimbPythonMessage = {
  __typename?: 'VimbPythonMessage';
  code?: Maybe<Scalars['Int']>;
  message?: Maybe<Scalars['String']>;
};

/** Стандартный объект */
export type VimbQuery = {
  __typename?: 'VimbQuery';
  /** Бюджет (контракт) VIMB */
  Budget?: Maybe<Budget>;
  /** Список бюджетов (контрактов) */
  BudgetList?: Maybe<ListWithPager_Budget>;
  /** List of Контракт VIMB */
  Contract?: Maybe<VimbContract>;
  /** Список бюджетов (контрактов) */
  ContractList?: Maybe<ListWithPager_VimbContract>;
  /** Клиент */
  Customer?: Maybe<VimbCustomer>;
  /** List ofКлиент */
  CustomerList?: Maybe<Array<Maybe<VimbCustomer>>>;
  /** Ролик для размещения */
  Film?: Maybe<Film>;
  /** List ofРолик для размещения */
  FilmList?: Maybe<Array<Maybe<Film>>>;
  /** Список менеджеров */
  ManagerList?: Maybe<Array<Maybe<VimbManager>>>;
  /** Медиаплан */
  Mplan?: Maybe<MPlan>;
  /** Список [Медиаплан] */
  MplanList?: Maybe<ListWithPager_MPlan>;
  /** Таргетинги договора */
  Targeting?: Maybe<VimbTargeting>;
  /** Список бюджетов (контрактов) */
  TargetingList?: Maybe<ListWithPager_VimbTargeting>;
  /** Список каналов */
  channelList?: Maybe<Array<Maybe<Channel>>>;
  /** Список городов вещания */
  getCityList?: Maybe<Array<Maybe<VimbCity>>>;
  /** Выгрузка данных в эксель, аргумент - id медиаплана, возврат - файл эксель в виде строки base64 */
  getExcel?: Maybe<Scalars['String']>;
  /** Отчет по лимитам */
  getLimitReportXls?: Maybe<Scalars['String']>;
  /** List of Элемент прайма */
  primeItem?: Maybe<VimbPrimeItem>;
  /** Список праймов */
  primeItemList?: Maybe<ListWithPager_VimbPrimeItem>;
  /** List of Целевая аудитория */
  targetAudience?: Maybe<VimbTargetAudience>;
  /** Список ЦА */
  targetAudienceList?: Maybe<ListWithPager_VimbTargetAudience>;
};


/** Стандартный объект */
export type VimbQueryBudgetArgs = {
  agrID?: Maybe<Scalars['Int']>;
  month?: Maybe<Scalars['Int']>;
};


/** Стандартный объект */
export type VimbQueryBudgetListArgs = {
  params?: Maybe<ParamsInput>;
};


/** Стандартный объект */
export type VimbQueryContractArgs = {
  agrID?: Maybe<Scalars['Int']>;
};


/** Стандартный объект */
export type VimbQueryContractListArgs = {
  params?: Maybe<ParamsInput>;
};


/** Стандартный объект */
export type VimbQueryCustomerArgs = {
  id?: Maybe<Scalars['Int']>;
};


/** Стандартный объект */
export type VimbQueryCustomerListArgs = {
  params?: Maybe<ParamsInput>;
};


/** Стандартный объект */
export type VimbQueryFilmArgs = {
  commInMplID?: Maybe<Scalars['Int']>;
};


/** Стандартный объект */
export type VimbQueryFilmListArgs = {
  params?: Maybe<ParamsInput>;
};


/** Стандартный объект */
export type VimbQueryManagerListArgs = {
  params?: Maybe<ParamsInput>;
};


/** Стандартный объект */
export type VimbQueryMplanArgs = {
  mplID?: Maybe<Scalars['Int']>;
  mplMonth?: Maybe<Scalars['Int']>;
};


/** Стандартный объект */
export type VimbQueryMplanListArgs = {
  params?: Maybe<ParamsInput>;
};


/** Стандартный объект */
export type VimbQueryTargetingArgs = {
  id?: Maybe<Scalars['Int']>;
};


/** Стандартный объект */
export type VimbQueryTargetingListArgs = {
  params?: Maybe<ParamsInput>;
};


/** Стандартный объект */
export type VimbQueryChannelListArgs = {
  params?: Maybe<ParamsInput>;
};


/** Стандартный объект */
export type VimbQueryGetCityListArgs = {
  params?: Maybe<ParamsInput>;
};


/** Стандартный объект */
export type VimbQueryGetExcelArgs = {
  agrID?: Maybe<Scalars['Int']>;
};


/** Стандартный объект */
export type VimbQueryPrimeItemArgs = {
  id?: Maybe<Scalars['Int']>;
};


/** Стандартный объект */
export type VimbQueryPrimeItemListArgs = {
  params?: Maybe<ParamsInput>;
};


/** Стандартный объект */
export type VimbQueryTargetAudienceArgs = {
  taID?: Maybe<Scalars['Int']>;
};


/** Стандартный объект */
export type VimbQueryTargetAudienceListArgs = {
  params?: Maybe<ParamsInput>;
};

/** Целевая аудитория */
export type VimbTargetAudience = {
  __typename?: 'VimbTargetAudience';
  /** row's ID */
  ID?: Maybe<Scalars['ID']>;
  /** возраст ОТ целевой аудитории */
  ageFrom?: Maybe<Scalars['Int']>;
  /** возраст ДО целевой аудитории */
  ageTo?: Maybe<Scalars['Int']>;
  /** пол целевой аудитории */
  gender?: Maybe<Scalars['String']>;
  /** доход целевой аудитории */
  income?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** Загрузка данных элемента */
export type VimbTargetAudienceInput = {
  /** row's ID */
  ID?: Maybe<Scalars['ID']>;
  /** возраст ОТ целевой аудитории */
  ageFrom?: Maybe<Scalars['Int']>;
  /** возраст ДО целевой аудитории */
  ageTo?: Maybe<Scalars['Int']>;
  /** пол целевой аудитории */
  gender?: Maybe<Scalars['String']>;
  /** доход целевой аудитории */
  income?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** Таргетинги договора */
export type VimbTargeting = {
  __typename?: 'VimbTargeting';
  /** id контракты */
  contract_id?: Maybe<Scalars['Int']>;
  /** применять двойной ролик */
  double_film?: Maybe<Scalars['Int']>;
  /** Ролик для размещения */
  films?: Maybe<Array<Maybe<Film>>>;
  /** применять общие настройки */
  general_settings?: Maybe<Scalars['Int']>;
  /** id - уникальный идентификатор записи */
  id: Scalars['ID'];
  /** Таргетинги договора */
  intervals?: Maybe<Array<Maybe<Targeting>>>;
  /** Название */
  name?: Maybe<Scalars['String']>;
};

/** Загрузка данных элемента интервала */
export type VimbTargetingInput = {
  /** id контракты */
  contract_id?: Maybe<Scalars['Int']>;
  /** применять двойной ролик */
  double_film?: Maybe<Scalars['Int']>;
  /** Film List downloading */
  films?: Maybe<Array<Maybe<VimbFilmInput>>>;
  /** применять общие настройки */
  general_settings?: Maybe<Scalars['Int']>;
  /** id - уникальный идентификатор записи */
  id: Scalars['ID'];
  /** Загрузка списка интервалов */
  intervals?: Maybe<Array<Maybe<IntervalInput>>>;
  /** Название */
  name?: Maybe<Scalars['String']>;
};

export type BriefReportQueryVariables = Exact<{
  dateStart?: Maybe<Scalars['DateTime']>;
  dateFinish?: Maybe<Scalars['DateTime']>;
}>;


export type BriefReportQuery = (
  { __typename?: 'Query' }
  & { RN?: Maybe<(
    { __typename?: 'RnQuery' }
    & Pick<RnQuery, 'briefReport'>
  )> }
);

export type DeleteItemMutationVariables = Exact<{
  itemID: Scalars['Int'];
}>;


export type DeleteItemMutation = (
  { __typename?: 'Mutation' }
  & { Rn?: Maybe<(
    { __typename?: 'RnMutation' }
    & Pick<RnMutation, 'deleteItemSoft'>
  )> }
);

export type GetMeQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMeQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'UserProfile' }
    & Pick<UserProfile, 'id' | 'email' | 'comon_id'>
  )> }
);

export type InventoryListQueryVariables = Exact<{
  params?: Maybe<ParamsInput>;
}>;


export type InventoryListQuery = (
  { __typename?: 'Query' }
  & { RnInventoryList?: Maybe<(
    { __typename?: 'listWithPager_rnInventoryItem' }
    & Pick<ListWithPager_RnInventoryItem, 'pageInfo'>
    & { data?: Maybe<Array<Maybe<(
      { __typename?: 'rnInventoryItem' }
      & Pick<RnInventoryItem, 'id' | 'pid' | 'name' | 'platformId' | 'format' | 'type' | 'startDate' | 'endDate' | 'pageTitle' | 'pageUrl' | 'purchase' | 'rotation' | 'calculatingMethod' | 'priceCondition' | 'priceType' | 'value' | 'currency' | 'vatRate' | 'vat' | 'description' | 'articleTitle' | 'articleBody' | 'position' | 'display_desktop' | 'display_mobile' | 'placement_value' | 'comment' | 'statistics' | 'price_value' | 'priceFrom' | 'priceTo' | 'price_value_week' | 'price_value_month' | 'showing' | 'view' | 'ctr' | 'placement_type' | 'showing_percent' | 'specifications' | 'publication_format' | 'coefficient_off' | 'min_impressions' | 'global_targeting' | 'rating'>
      & { Targeting?: Maybe<Array<Maybe<(
        { __typename?: 'rnInventoryItemTargeting' }
        & Pick<RnInventoryItemTargeting, 'id' | 'name' | 'coefficient'>
      )>>>, Pages?: Maybe<Array<Maybe<(
        { __typename?: 'RnInventoryPage' }
        & Pick<RnInventoryPage, 'id' | 'name'>
      )>>>, image?: Maybe<Array<Maybe<(
        { __typename?: 'File' }
        & Pick<File, 'id' | 'path' | 'name'>
      )>>>, screenshot?: Maybe<Array<Maybe<(
        { __typename?: 'File' }
        & Pick<File, 'id' | 'path' | 'name'>
      )>>>, video?: Maybe<Array<Maybe<(
        { __typename?: 'File' }
        & Pick<File, 'id' | 'path' | 'name'>
      )>>>, video_short?: Maybe<Array<Maybe<(
        { __typename?: 'File' }
        & Pick<File, 'id' | 'path' | 'name'>
      )>>> }
    )>>> }
  )> }
);

export type ItemLogListQueryVariables = Exact<{
  geo?: Maybe<Scalars['String']>;
  dateStart?: Maybe<Scalars['DateTime']>;
  dateFinish?: Maybe<Scalars['DateTime']>;
  platformID?: Maybe<Array<Maybe<Scalars['Int']>>>;
  partnerID?: Maybe<Array<Maybe<Scalars['Int']>>>;
  inventoryID?: Maybe<Array<Maybe<Scalars['Int']>>>;
  mpID?: Maybe<Array<Maybe<Scalars['Int']>>>;
}>;


export type ItemLogListQuery = (
  { __typename?: 'Query' }
  & { RN?: Maybe<(
    { __typename?: 'RnQuery' }
    & { itemLogList?: Maybe<Array<Maybe<(
      { __typename?: 'RnLog' }
      & Pick<RnLog, 'id' | 'mpID' | 'data' | 'deleted' | 'created' | 'status' | 'statusName' | 'partnerName' | 'platformName' | 'inventoryName' | 'priceType' | 'info' | 'vendorVat' | 'customerVat' | 'requirements' | 'vendorPaymentDelay' | 'vendorPaymentDelayValue' | 'factPaymentDelay' | 'factPaymentDelayValue' | 'customerPaymentDelay' | 'customerPaymentDelayValue'>
    )>>> }
  )> }
);

export type LogoutQueryVariables = Exact<{ [key: string]: never; }>;


export type LogoutQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'logout'>
);

export type RnMpCreateMutationVariables = Exact<{
  userId: Scalars['Int'];
  date: Scalars['String'];
}>;


export type RnMpCreateMutation = (
  { __typename?: 'Mutation' }
  & { RnMpCreate?: Maybe<(
    { __typename?: 'RnMp' }
    & Pick<RnMp, 'id'>
  )> }
);

export type MpItemCreateMutationVariables = Exact<{
  mpId: Scalars['Int'];
}>;


export type MpItemCreateMutation = (
  { __typename?: 'Mutation' }
  & { RnMpItemCreate?: Maybe<(
    { __typename?: 'RnMpItem' }
    & Pick<RnMpItem, 'id'>
  )> }
);

export type MpListQueryVariables = Exact<{
  params?: Maybe<ParamsInput>;
}>;


export type MpListQuery = (
  { __typename?: 'Query' }
  & { RnMpListPager?: Maybe<(
    { __typename?: 'listWithPager_RnMp' }
    & Pick<ListWithPager_RnMp, 'pageInfo'>
    & { data?: Maybe<Array<Maybe<(
      { __typename?: 'RnMp' }
      & Pick<RnMp, 'id' | 'entryDate' | 'status' | 'order_id' | 'order_id_2' | 'is_copy'>
      & { MpItems?: Maybe<Array<Maybe<(
        { __typename?: 'RnMpItem' }
        & Pick<RnMpItem, 'id' | 'margin' | 'total_cost_vat_discount'>
        & { Info?: Maybe<(
          { __typename?: 'RnMpItemInfo' }
          & Pick<RnMpItemInfo, 'id' | 'margin' | 'total_cost_vat_discount'>
        )> }
      )>>>, Briefing?: Maybe<(
        { __typename?: 'RnBriefing' }
        & Pick<RnBriefing, 'id' | 'name' | 'vat' | 'no_vat' | 'status' | 'contract_type' | 'partner_id' | 'products' | 'dateStart' | 'dateEnd'>
        & { Manager?: Maybe<(
          { __typename?: 'RefEmployee' }
          & Pick<RefEmployee, 'id' | 'nameFull'>
        )>, Responsible?: Maybe<(
          { __typename?: 'RefEmployee' }
          & Pick<RefEmployee, 'id' | 'nameFull'>
        )>, Partner?: Maybe<(
          { __typename?: 'MLPartner' }
          & Pick<MlPartner, 'id' | 'name'>
        )>, Brand?: Maybe<(
          { __typename?: 'MLBrand' }
          & Pick<MlBrand, 'id' | 'name'>
        )> }
      )> }
    )>>> }
  )> }
);

export type MpReportXlsQueryVariables = Exact<{
  dateStart?: Maybe<Scalars['DateTime']>;
  dateFinish?: Maybe<Scalars['DateTime']>;
  brandID?: Maybe<Scalars['Int']>;
  buyerID?: Maybe<Scalars['Int']>;
  partnerID?: Maybe<Scalars['Int']>;
}>;


export type MpReportXlsQuery = (
  { __typename?: 'Query' }
  & { RN?: Maybe<(
    { __typename?: 'RnQuery' }
    & Pick<RnQuery, 'mpReportXls'>
  )> }
);

export type OrderReportXlsQueryVariables = Exact<{
  dateStart?: Maybe<Scalars['DateTime']>;
  dateFinish?: Maybe<Scalars['DateTime']>;
}>;


export type OrderReportXlsQuery = (
  { __typename?: 'Query' }
  & { RN?: Maybe<(
    { __typename?: 'RnQuery' }
    & Pick<RnQuery, 'orderReportXls'>
  )> }
);

export type PartnerListQueryVariables = Exact<{
  params?: Maybe<ParamsInput>;
}>;


export type PartnerListQuery = (
  { __typename?: 'Query' }
  & { MLPartnerList?: Maybe<Array<Maybe<(
    { __typename?: 'MLPartner' }
    & Pick<MlPartner, 'id' | 'name'>
  )>>> }
);

export type PlatfromListQueryVariables = Exact<{
  params: ParamsInput;
}>;


export type PlatfromListQuery = (
  { __typename?: 'Query' }
  & { RnPlatformList?: Maybe<(
    { __typename?: 'listWithPager_rnPlatform' }
    & Pick<ListWithPager_RnPlatform, 'pageInfo'>
    & { data?: Maybe<Array<Maybe<(
      { __typename?: 'rnPlatform' }
      & Pick<RnPlatform, 'id' | 'name' | 'url'>
    )>>> }
  )> }
);

export type RnMpQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type RnMpQuery = (
  { __typename?: 'Query' }
  & { RnMp?: Maybe<(
    { __typename?: 'RnMp' }
    & Pick<RnMp, 'id' | 'cpc' | 'clicks' | 'totalCostVatDiscount' | 'totalCostNoVatDiscount' | 'totalCostPurchaseDiscount' | 'margin' | 'profitability' | 'isForeign' | 'publicationIn'>
  )> }
);

export type MlThematicsListQueryVariables = Exact<{ [key: string]: never; }>;


export type MlThematicsListQuery = (
  { __typename?: 'Query' }
  & { MlThematicsList?: Maybe<Array<Maybe<(
    { __typename?: 'MlThematics' }
    & Pick<MlThematics, 'id' | 'name'>
  )>>> }
);

export type UndoItemMutationVariables = Exact<{
  mpID: Scalars['Int'];
}>;


export type UndoItemMutation = (
  { __typename?: 'Mutation' }
  & { Rn?: Maybe<(
    { __typename?: 'RnMutation' }
    & Pick<RnMutation, 'undoItem'>
  )> }
);

export type UpdateInventoryFieldMutationVariables = Exact<{
  inventoryID: Scalars['Int'];
  fieldName: Scalars['String'];
  fieldValue: Scalars['String'];
}>;


export type UpdateInventoryFieldMutation = (
  { __typename?: 'Mutation' }
  & { Rn?: Maybe<(
    { __typename?: 'RnMutation' }
    & { updateInventoryField?: Maybe<(
      { __typename?: 'rnInventoryItem' }
      & Pick<RnInventoryItem, 'id'>
    )> }
  )> }
);

export type UploadInventoryFileMutationVariables = Exact<{
  file: Scalars['Upload'];
  inventoryID: Scalars['Int'];
  srcFileName?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['Int']>;
}>;


export type UploadInventoryFileMutation = (
  { __typename?: 'Mutation' }
  & { Rn?: Maybe<(
    { __typename?: 'RnMutation' }
    & { uploadInventoryFile?: Maybe<(
      { __typename?: 'File' }
      & Pick<File, 'id'>
    )> }
  )> }
);


export const BriefReportDocument = gql`
    query briefReport($dateStart: DateTime, $dateFinish: DateTime) {
  RN {
    briefReport(dateStart: $dateStart, dateFinish: $dateFinish)
  }
}
    `;

/**
 * __useBriefReportQuery__
 *
 * To run a query within a React component, call `useBriefReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useBriefReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBriefReportQuery({
 *   variables: {
 *      dateStart: // value for 'dateStart'
 *      dateFinish: // value for 'dateFinish'
 *   },
 * });
 */
export function useBriefReportQuery(baseOptions?: Apollo.QueryHookOptions<BriefReportQuery, BriefReportQueryVariables>) {
        return Apollo.useQuery<BriefReportQuery, BriefReportQueryVariables>(BriefReportDocument, baseOptions);
      }
export function useBriefReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BriefReportQuery, BriefReportQueryVariables>) {
          return Apollo.useLazyQuery<BriefReportQuery, BriefReportQueryVariables>(BriefReportDocument, baseOptions);
        }
export type BriefReportQueryHookResult = ReturnType<typeof useBriefReportQuery>;
export type BriefReportLazyQueryHookResult = ReturnType<typeof useBriefReportLazyQuery>;
export type BriefReportQueryResult = Apollo.QueryResult<BriefReportQuery, BriefReportQueryVariables>;
export const DeleteItemDocument = gql`
    mutation deleteItem($itemID: Int!) {
  Rn {
    deleteItemSoft(itemID: $itemID)
  }
}
    `;
export type DeleteItemMutationFn = Apollo.MutationFunction<DeleteItemMutation, DeleteItemMutationVariables>;

/**
 * __useDeleteItemMutation__
 *
 * To run a mutation, you first call `useDeleteItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteItemMutation, { data, loading, error }] = useDeleteItemMutation({
 *   variables: {
 *      itemID: // value for 'itemID'
 *   },
 * });
 */
export function useDeleteItemMutation(baseOptions?: Apollo.MutationHookOptions<DeleteItemMutation, DeleteItemMutationVariables>) {
        return Apollo.useMutation<DeleteItemMutation, DeleteItemMutationVariables>(DeleteItemDocument, baseOptions);
      }
export type DeleteItemMutationHookResult = ReturnType<typeof useDeleteItemMutation>;
export type DeleteItemMutationResult = Apollo.MutationResult<DeleteItemMutation>;
export type DeleteItemMutationOptions = Apollo.BaseMutationOptions<DeleteItemMutation, DeleteItemMutationVariables>;
export const GetMeDocument = gql`
    query getMe {
  me {
    id
    email
    comon_id
  }
}
    `;

/**
 * __useGetMeQuery__
 *
 * To run a query within a React component, call `useGetMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMeQuery(baseOptions?: Apollo.QueryHookOptions<GetMeQuery, GetMeQueryVariables>) {
        return Apollo.useQuery<GetMeQuery, GetMeQueryVariables>(GetMeDocument, baseOptions);
      }
export function useGetMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMeQuery, GetMeQueryVariables>) {
          return Apollo.useLazyQuery<GetMeQuery, GetMeQueryVariables>(GetMeDocument, baseOptions);
        }
export type GetMeQueryHookResult = ReturnType<typeof useGetMeQuery>;
export type GetMeLazyQueryHookResult = ReturnType<typeof useGetMeLazyQuery>;
export type GetMeQueryResult = Apollo.QueryResult<GetMeQuery, GetMeQueryVariables>;
export const InventoryListDocument = gql`
    query inventoryList($params: ParamsInput) {
  RnInventoryList(params: $params) {
    pageInfo
    data {
      id
      pid
      name
      platformId
      format
      type
      startDate
      endDate
      pageTitle
      pageUrl
      purchase
      rotation
      calculatingMethod
      priceCondition
      priceType
      value
      currency
      vatRate
      vat
      description
      articleTitle
      articleBody
      position
      display_desktop
      display_mobile
      placement_value
      comment
      statistics
      price_value
      priceType
      priceFrom
      priceTo
      price_value_week
      price_value_month
      showing
      view
      ctr
      placement_type
      showing_percent
      specifications
      publication_format
      priceType
      coefficient_off
      min_impressions
      global_targeting
      rating
      Targeting {
        id
        name
        coefficient
      }
      Pages {
        id
        name
      }
      image {
        id
        path
        name
      }
      screenshot {
        id
        path
        name
      }
      video {
        id
        path
        name
      }
      video_short {
        id
        path
        name
      }
    }
  }
}
    `;

/**
 * __useInventoryListQuery__
 *
 * To run a query within a React component, call `useInventoryListQuery` and pass it any options that fit your needs.
 * When your component renders, `useInventoryListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInventoryListQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useInventoryListQuery(baseOptions?: Apollo.QueryHookOptions<InventoryListQuery, InventoryListQueryVariables>) {
        return Apollo.useQuery<InventoryListQuery, InventoryListQueryVariables>(InventoryListDocument, baseOptions);
      }
export function useInventoryListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InventoryListQuery, InventoryListQueryVariables>) {
          return Apollo.useLazyQuery<InventoryListQuery, InventoryListQueryVariables>(InventoryListDocument, baseOptions);
        }
export type InventoryListQueryHookResult = ReturnType<typeof useInventoryListQuery>;
export type InventoryListLazyQueryHookResult = ReturnType<typeof useInventoryListLazyQuery>;
export type InventoryListQueryResult = Apollo.QueryResult<InventoryListQuery, InventoryListQueryVariables>;
export const ItemLogListDocument = gql`
    query itemLogList($geo: String, $dateStart: DateTime, $dateFinish: DateTime, $platformID: [Int], $partnerID: [Int], $inventoryID: [Int], $mpID: [Int]) {
  RN {
    itemLogList(mpID: $mpID, dateStart: $dateStart, dateFinish: $dateFinish, platformID: $platformID, partnerID: $partnerID, inventoryID: $inventoryID, geo: $geo) {
      id
      mpID
      data
      deleted
      created
      status
      statusName
      partnerName
      platformName
      inventoryName
      priceType
      info
      vendorVat
      customerVat
      requirements
      vendorPaymentDelay
      vendorPaymentDelayValue
      factPaymentDelay
      factPaymentDelayValue
      customerPaymentDelay
      customerPaymentDelayValue
    }
  }
}
    `;

/**
 * __useItemLogListQuery__
 *
 * To run a query within a React component, call `useItemLogListQuery` and pass it any options that fit your needs.
 * When your component renders, `useItemLogListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useItemLogListQuery({
 *   variables: {
 *      geo: // value for 'geo'
 *      dateStart: // value for 'dateStart'
 *      dateFinish: // value for 'dateFinish'
 *      platformID: // value for 'platformID'
 *      partnerID: // value for 'partnerID'
 *      inventoryID: // value for 'inventoryID'
 *      mpID: // value for 'mpID'
 *   },
 * });
 */
export function useItemLogListQuery(baseOptions?: Apollo.QueryHookOptions<ItemLogListQuery, ItemLogListQueryVariables>) {
        return Apollo.useQuery<ItemLogListQuery, ItemLogListQueryVariables>(ItemLogListDocument, baseOptions);
      }
export function useItemLogListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ItemLogListQuery, ItemLogListQueryVariables>) {
          return Apollo.useLazyQuery<ItemLogListQuery, ItemLogListQueryVariables>(ItemLogListDocument, baseOptions);
        }
export type ItemLogListQueryHookResult = ReturnType<typeof useItemLogListQuery>;
export type ItemLogListLazyQueryHookResult = ReturnType<typeof useItemLogListLazyQuery>;
export type ItemLogListQueryResult = Apollo.QueryResult<ItemLogListQuery, ItemLogListQueryVariables>;
export const LogoutDocument = gql`
    query logout {
  logout
}
    `;

/**
 * __useLogoutQuery__
 *
 * To run a query within a React component, call `useLogoutQuery` and pass it any options that fit your needs.
 * When your component renders, `useLogoutQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLogoutQuery({
 *   variables: {
 *   },
 * });
 */
export function useLogoutQuery(baseOptions?: Apollo.QueryHookOptions<LogoutQuery, LogoutQueryVariables>) {
        return Apollo.useQuery<LogoutQuery, LogoutQueryVariables>(LogoutDocument, baseOptions);
      }
export function useLogoutLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LogoutQuery, LogoutQueryVariables>) {
          return Apollo.useLazyQuery<LogoutQuery, LogoutQueryVariables>(LogoutDocument, baseOptions);
        }
export type LogoutQueryHookResult = ReturnType<typeof useLogoutQuery>;
export type LogoutLazyQueryHookResult = ReturnType<typeof useLogoutLazyQuery>;
export type LogoutQueryResult = Apollo.QueryResult<LogoutQuery, LogoutQueryVariables>;
export const RnMpCreateDocument = gql`
    mutation RnMpCreate($userId: Int!, $date: String!) {
  RnMpCreate(userId: $userId, date: $date) {
    id
  }
}
    `;
export type RnMpCreateMutationFn = Apollo.MutationFunction<RnMpCreateMutation, RnMpCreateMutationVariables>;

/**
 * __useRnMpCreateMutation__
 *
 * To run a mutation, you first call `useRnMpCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRnMpCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rnMpCreateMutation, { data, loading, error }] = useRnMpCreateMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      date: // value for 'date'
 *   },
 * });
 */
export function useRnMpCreateMutation(baseOptions?: Apollo.MutationHookOptions<RnMpCreateMutation, RnMpCreateMutationVariables>) {
        return Apollo.useMutation<RnMpCreateMutation, RnMpCreateMutationVariables>(RnMpCreateDocument, baseOptions);
      }
export type RnMpCreateMutationHookResult = ReturnType<typeof useRnMpCreateMutation>;
export type RnMpCreateMutationResult = Apollo.MutationResult<RnMpCreateMutation>;
export type RnMpCreateMutationOptions = Apollo.BaseMutationOptions<RnMpCreateMutation, RnMpCreateMutationVariables>;
export const MpItemCreateDocument = gql`
    mutation mpItemCreate($mpId: Int!) {
  RnMpItemCreate(mpId: $mpId) {
    id
  }
}
    `;
export type MpItemCreateMutationFn = Apollo.MutationFunction<MpItemCreateMutation, MpItemCreateMutationVariables>;

/**
 * __useMpItemCreateMutation__
 *
 * To run a mutation, you first call `useMpItemCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMpItemCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mpItemCreateMutation, { data, loading, error }] = useMpItemCreateMutation({
 *   variables: {
 *      mpId: // value for 'mpId'
 *   },
 * });
 */
export function useMpItemCreateMutation(baseOptions?: Apollo.MutationHookOptions<MpItemCreateMutation, MpItemCreateMutationVariables>) {
        return Apollo.useMutation<MpItemCreateMutation, MpItemCreateMutationVariables>(MpItemCreateDocument, baseOptions);
      }
export type MpItemCreateMutationHookResult = ReturnType<typeof useMpItemCreateMutation>;
export type MpItemCreateMutationResult = Apollo.MutationResult<MpItemCreateMutation>;
export type MpItemCreateMutationOptions = Apollo.BaseMutationOptions<MpItemCreateMutation, MpItemCreateMutationVariables>;
export const MpListDocument = gql`
    query mpList($params: ParamsInput) {
  RnMpListPager(params: $params) {
    pageInfo
    data {
      id
      entryDate
      status
      order_id
      order_id_2
      is_copy
      MpItems {
        id
        margin
        total_cost_vat_discount
        Info {
          id
          margin
          total_cost_vat_discount
        }
      }
      Briefing {
        id
        name
        vat
        no_vat
        status
        contract_type
        partner_id
        products
        dateStart
        dateEnd
        Manager {
          id
          nameFull
        }
        Responsible {
          id
          nameFull
        }
        Partner {
          id
          name
        }
        Brand {
          id
          name
        }
      }
    }
  }
}
    `;

/**
 * __useMpListQuery__
 *
 * To run a query within a React component, call `useMpListQuery` and pass it any options that fit your needs.
 * When your component renders, `useMpListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMpListQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useMpListQuery(baseOptions?: Apollo.QueryHookOptions<MpListQuery, MpListQueryVariables>) {
        return Apollo.useQuery<MpListQuery, MpListQueryVariables>(MpListDocument, baseOptions);
      }
export function useMpListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MpListQuery, MpListQueryVariables>) {
          return Apollo.useLazyQuery<MpListQuery, MpListQueryVariables>(MpListDocument, baseOptions);
        }
export type MpListQueryHookResult = ReturnType<typeof useMpListQuery>;
export type MpListLazyQueryHookResult = ReturnType<typeof useMpListLazyQuery>;
export type MpListQueryResult = Apollo.QueryResult<MpListQuery, MpListQueryVariables>;
export const MpReportXlsDocument = gql`
    query mpReportXls($dateStart: DateTime, $dateFinish: DateTime, $brandID: Int, $buyerID: Int, $partnerID: Int) {
  RN {
    mpReportXls(dateStart: $dateStart, dateFinish: $dateFinish, brandID: $brandID, buyerID: $buyerID, partnerID: $partnerID)
  }
}
    `;

/**
 * __useMpReportXlsQuery__
 *
 * To run a query within a React component, call `useMpReportXlsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMpReportXlsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMpReportXlsQuery({
 *   variables: {
 *      dateStart: // value for 'dateStart'
 *      dateFinish: // value for 'dateFinish'
 *      brandID: // value for 'brandID'
 *      buyerID: // value for 'buyerID'
 *      partnerID: // value for 'partnerID'
 *   },
 * });
 */
export function useMpReportXlsQuery(baseOptions?: Apollo.QueryHookOptions<MpReportXlsQuery, MpReportXlsQueryVariables>) {
        return Apollo.useQuery<MpReportXlsQuery, MpReportXlsQueryVariables>(MpReportXlsDocument, baseOptions);
      }
export function useMpReportXlsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MpReportXlsQuery, MpReportXlsQueryVariables>) {
          return Apollo.useLazyQuery<MpReportXlsQuery, MpReportXlsQueryVariables>(MpReportXlsDocument, baseOptions);
        }
export type MpReportXlsQueryHookResult = ReturnType<typeof useMpReportXlsQuery>;
export type MpReportXlsLazyQueryHookResult = ReturnType<typeof useMpReportXlsLazyQuery>;
export type MpReportXlsQueryResult = Apollo.QueryResult<MpReportXlsQuery, MpReportXlsQueryVariables>;
export const OrderReportXlsDocument = gql`
    query orderReportXls($dateStart: DateTime, $dateFinish: DateTime) {
  RN {
    orderReportXls(dateStart: $dateStart, dateFinish: $dateFinish)
  }
}
    `;

/**
 * __useOrderReportXlsQuery__
 *
 * To run a query within a React component, call `useOrderReportXlsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderReportXlsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderReportXlsQuery({
 *   variables: {
 *      dateStart: // value for 'dateStart'
 *      dateFinish: // value for 'dateFinish'
 *   },
 * });
 */
export function useOrderReportXlsQuery(baseOptions?: Apollo.QueryHookOptions<OrderReportXlsQuery, OrderReportXlsQueryVariables>) {
        return Apollo.useQuery<OrderReportXlsQuery, OrderReportXlsQueryVariables>(OrderReportXlsDocument, baseOptions);
      }
export function useOrderReportXlsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrderReportXlsQuery, OrderReportXlsQueryVariables>) {
          return Apollo.useLazyQuery<OrderReportXlsQuery, OrderReportXlsQueryVariables>(OrderReportXlsDocument, baseOptions);
        }
export type OrderReportXlsQueryHookResult = ReturnType<typeof useOrderReportXlsQuery>;
export type OrderReportXlsLazyQueryHookResult = ReturnType<typeof useOrderReportXlsLazyQuery>;
export type OrderReportXlsQueryResult = Apollo.QueryResult<OrderReportXlsQuery, OrderReportXlsQueryVariables>;
export const PartnerListDocument = gql`
    query partnerList($params: ParamsInput) {
  MLPartnerList(params: $params) {
    id
    name
  }
}
    `;

/**
 * __usePartnerListQuery__
 *
 * To run a query within a React component, call `usePartnerListQuery` and pass it any options that fit your needs.
 * When your component renders, `usePartnerListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePartnerListQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function usePartnerListQuery(baseOptions?: Apollo.QueryHookOptions<PartnerListQuery, PartnerListQueryVariables>) {
        return Apollo.useQuery<PartnerListQuery, PartnerListQueryVariables>(PartnerListDocument, baseOptions);
      }
export function usePartnerListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PartnerListQuery, PartnerListQueryVariables>) {
          return Apollo.useLazyQuery<PartnerListQuery, PartnerListQueryVariables>(PartnerListDocument, baseOptions);
        }
export type PartnerListQueryHookResult = ReturnType<typeof usePartnerListQuery>;
export type PartnerListLazyQueryHookResult = ReturnType<typeof usePartnerListLazyQuery>;
export type PartnerListQueryResult = Apollo.QueryResult<PartnerListQuery, PartnerListQueryVariables>;
export const PlatfromListDocument = gql`
    query platfromList($params: ParamsInput!) {
  RnPlatformList(params: $params) {
    pageInfo
    data {
      id
      name
      url
    }
  }
}
    `;

/**
 * __usePlatfromListQuery__
 *
 * To run a query within a React component, call `usePlatfromListQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlatfromListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlatfromListQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function usePlatfromListQuery(baseOptions?: Apollo.QueryHookOptions<PlatfromListQuery, PlatfromListQueryVariables>) {
        return Apollo.useQuery<PlatfromListQuery, PlatfromListQueryVariables>(PlatfromListDocument, baseOptions);
      }
export function usePlatfromListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PlatfromListQuery, PlatfromListQueryVariables>) {
          return Apollo.useLazyQuery<PlatfromListQuery, PlatfromListQueryVariables>(PlatfromListDocument, baseOptions);
        }
export type PlatfromListQueryHookResult = ReturnType<typeof usePlatfromListQuery>;
export type PlatfromListLazyQueryHookResult = ReturnType<typeof usePlatfromListLazyQuery>;
export type PlatfromListQueryResult = Apollo.QueryResult<PlatfromListQuery, PlatfromListQueryVariables>;
export const RnMpDocument = gql`
    query rnMp($id: Int!) {
  RnMp(id: $id) {
    id
    cpc
    clicks
    totalCostVatDiscount
    totalCostNoVatDiscount
    totalCostPurchaseDiscount
    margin
    profitability
    isForeign
    publicationIn
  }
}
    `;

/**
 * __useRnMpQuery__
 *
 * To run a query within a React component, call `useRnMpQuery` and pass it any options that fit your needs.
 * When your component renders, `useRnMpQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRnMpQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRnMpQuery(baseOptions?: Apollo.QueryHookOptions<RnMpQuery, RnMpQueryVariables>) {
        return Apollo.useQuery<RnMpQuery, RnMpQueryVariables>(RnMpDocument, baseOptions);
      }
export function useRnMpLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RnMpQuery, RnMpQueryVariables>) {
          return Apollo.useLazyQuery<RnMpQuery, RnMpQueryVariables>(RnMpDocument, baseOptions);
        }
export type RnMpQueryHookResult = ReturnType<typeof useRnMpQuery>;
export type RnMpLazyQueryHookResult = ReturnType<typeof useRnMpLazyQuery>;
export type RnMpQueryResult = Apollo.QueryResult<RnMpQuery, RnMpQueryVariables>;
export const MlThematicsListDocument = gql`
    query MlThematicsList {
  MlThematicsList {
    id
    name
  }
}
    `;

/**
 * __useMlThematicsListQuery__
 *
 * To run a query within a React component, call `useMlThematicsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useMlThematicsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMlThematicsListQuery({
 *   variables: {
 *   },
 * });
 */
export function useMlThematicsListQuery(baseOptions?: Apollo.QueryHookOptions<MlThematicsListQuery, MlThematicsListQueryVariables>) {
        return Apollo.useQuery<MlThematicsListQuery, MlThematicsListQueryVariables>(MlThematicsListDocument, baseOptions);
      }
export function useMlThematicsListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MlThematicsListQuery, MlThematicsListQueryVariables>) {
          return Apollo.useLazyQuery<MlThematicsListQuery, MlThematicsListQueryVariables>(MlThematicsListDocument, baseOptions);
        }
export type MlThematicsListQueryHookResult = ReturnType<typeof useMlThematicsListQuery>;
export type MlThematicsListLazyQueryHookResult = ReturnType<typeof useMlThematicsListLazyQuery>;
export type MlThematicsListQueryResult = Apollo.QueryResult<MlThematicsListQuery, MlThematicsListQueryVariables>;
export const UndoItemDocument = gql`
    mutation undoItem($mpID: Int!) {
  Rn {
    undoItem(mpID: $mpID)
  }
}
    `;
export type UndoItemMutationFn = Apollo.MutationFunction<UndoItemMutation, UndoItemMutationVariables>;

/**
 * __useUndoItemMutation__
 *
 * To run a mutation, you first call `useUndoItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUndoItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [undoItemMutation, { data, loading, error }] = useUndoItemMutation({
 *   variables: {
 *      mpID: // value for 'mpID'
 *   },
 * });
 */
export function useUndoItemMutation(baseOptions?: Apollo.MutationHookOptions<UndoItemMutation, UndoItemMutationVariables>) {
        return Apollo.useMutation<UndoItemMutation, UndoItemMutationVariables>(UndoItemDocument, baseOptions);
      }
export type UndoItemMutationHookResult = ReturnType<typeof useUndoItemMutation>;
export type UndoItemMutationResult = Apollo.MutationResult<UndoItemMutation>;
export type UndoItemMutationOptions = Apollo.BaseMutationOptions<UndoItemMutation, UndoItemMutationVariables>;
export const UpdateInventoryFieldDocument = gql`
    mutation updateInventoryField($inventoryID: Int!, $fieldName: String!, $fieldValue: String!) {
  Rn {
    updateInventoryField(inventoryID: $inventoryID, fieldName: $fieldName, fieldValue: $fieldValue) {
      id
    }
  }
}
    `;
export type UpdateInventoryFieldMutationFn = Apollo.MutationFunction<UpdateInventoryFieldMutation, UpdateInventoryFieldMutationVariables>;

/**
 * __useUpdateInventoryFieldMutation__
 *
 * To run a mutation, you first call `useUpdateInventoryFieldMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInventoryFieldMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInventoryFieldMutation, { data, loading, error }] = useUpdateInventoryFieldMutation({
 *   variables: {
 *      inventoryID: // value for 'inventoryID'
 *      fieldName: // value for 'fieldName'
 *      fieldValue: // value for 'fieldValue'
 *   },
 * });
 */
export function useUpdateInventoryFieldMutation(baseOptions?: Apollo.MutationHookOptions<UpdateInventoryFieldMutation, UpdateInventoryFieldMutationVariables>) {
        return Apollo.useMutation<UpdateInventoryFieldMutation, UpdateInventoryFieldMutationVariables>(UpdateInventoryFieldDocument, baseOptions);
      }
export type UpdateInventoryFieldMutationHookResult = ReturnType<typeof useUpdateInventoryFieldMutation>;
export type UpdateInventoryFieldMutationResult = Apollo.MutationResult<UpdateInventoryFieldMutation>;
export type UpdateInventoryFieldMutationOptions = Apollo.BaseMutationOptions<UpdateInventoryFieldMutation, UpdateInventoryFieldMutationVariables>;
export const UploadInventoryFileDocument = gql`
    mutation uploadInventoryFile($file: Upload!, $inventoryID: Int!, $srcFileName: String, $comment: String, $type: Int) {
  Rn {
    uploadInventoryFile(file: $file, inventoryID: $inventoryID, srcFileName: $srcFileName, comment: $comment, type: $type) {
      id
    }
  }
}
    `;
export type UploadInventoryFileMutationFn = Apollo.MutationFunction<UploadInventoryFileMutation, UploadInventoryFileMutationVariables>;

/**
 * __useUploadInventoryFileMutation__
 *
 * To run a mutation, you first call `useUploadInventoryFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadInventoryFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadInventoryFileMutation, { data, loading, error }] = useUploadInventoryFileMutation({
 *   variables: {
 *      file: // value for 'file'
 *      inventoryID: // value for 'inventoryID'
 *      srcFileName: // value for 'srcFileName'
 *      comment: // value for 'comment'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useUploadInventoryFileMutation(baseOptions?: Apollo.MutationHookOptions<UploadInventoryFileMutation, UploadInventoryFileMutationVariables>) {
        return Apollo.useMutation<UploadInventoryFileMutation, UploadInventoryFileMutationVariables>(UploadInventoryFileDocument, baseOptions);
      }
export type UploadInventoryFileMutationHookResult = ReturnType<typeof useUploadInventoryFileMutation>;
export type UploadInventoryFileMutationResult = Apollo.MutationResult<UploadInventoryFileMutation>;
export type UploadInventoryFileMutationOptions = Apollo.BaseMutationOptions<UploadInventoryFileMutation, UploadInventoryFileMutationVariables>;