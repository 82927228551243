//todo надо делать компонент юзеров, это капец
export const content_managers = [
  "admin@admin",
  "ljudmila.legayj@deltaclick.ru",
  "elena.novikova@deltaclick.ru",
  "inna.ilina@deltaclick.ru",
  "margarita.vernaya@deltaclick.ru",
  "galina.vas.merzlyakova@gmail.com",
  "evgenij.denisenko@delta-plan.ru",
  "anastasiya.perova@deltaclick.ru",
  "ekaterina.danilova@delta-plan.ru"
];

export const buyers = [
  "margarita.vernaya@deltaclick.ru",
  "inna.ilina@deltaclick.ru",
  "elena.novikova@deltaclick.ru",
  "ljudmila.legayj@deltaclick.ru",
  "evgenij.denisenko@delta-plan.ru",
  "anastasiya.perova@deltaclick.ru",
  "ekaterina.danilova@delta-plan.ru",
  "admin@admin"
];

export const topManagers = [
  "ljudmila.legayj@deltaclick.ru",
  "ekaterina.danilova@delta-plan.ru",
  "admin@admin"
  // "evgenij.denisenko@delta-plan.ru"
];

export default function verify(access_list, item) {
  return access_list.indexOf(item) > -1;
}
