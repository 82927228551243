import React from "react";
import PopUp from "../modal/modal";
import { Mutation } from "react-apollo";
import { RN_INVENTORY_UPDATE } from "../../RN/GraphQL/RNmutations";
import Td from "./../styled/td";
import Placeholder from "./../styled/imagePlaceholder";
import getRating from "../../functions/getRating";
import { RN_MP_ITEM } from "../../RN/GraphQL/RNqueries";

//todo отрефакторить и использовать в нескольких местах
class InventoryListItemModal extends React.Component<any, any> {
  state: any = {
    toolTip: false,
    hovered: false,
    isLoaded: false,
    data: {},
  };

  static getDerivedStateFromProps(props: any, state: any) {
    if (!state.isLoaded) {
      const newItem = { ...props.inventoryItem };

      newItem.pages = newItem["Pages"].map((item: any) => item.id);
      delete newItem["Pages"];
      newItem.targetingList = newItem["Targeting"].map((item: any) => ({
        id: item.id,
        name: item.name,
        coefficient: item.coefficient,
      }));
      delete newItem["Targeting"];

      if (newItem.image) {
        newItem.image = newItem.image.map((item: any) => item.id);
      }

      newItem.author_id = props.me.id;

      return {
        ...state,
        isLoaded: true,
        data: { ...newItem },
      };
    } else {
      return {
        ...state,
      };
    }
  }

  priceLabelDetector(props: any) {
    const label = props.platform && props.platform.isForeign ? "" : " ₽";

    if (props.inventoryItem.price_value)
      return props.inventoryItem.price_value + label;
    if (props.inventoryItem.priceFrom)
      return "от" + props.inventoryItem.priceFrom + label;
    if (props.inventoryItem.priceTo)
      return "до " + props.inventoryItem.priceTo + label;

    if (
      props.inventoryItem.price_value_week &&
      props.inventoryItem.price_value_month
    )
      return (
        props.inventoryItem.price_value_week +
        +label +
        " / неделя" +
        " " +
        props.inventoryItem.price_value_month +
        +label +
        " / месяц"
      );

    if (props.inventoryItem.price_value_week)
      return props.inventoryItem.price_value_week + label + " / неделя";
    if (props.inventoryItem.price_value_month)
      return props.inventoryItem.price_value_month + label + " / месяц";

    return null;
  }

  render() {
    let domain = document.location.origin;

    if (document.location.origin === "http://doreport.ru") {
      domain = document.location.origin + "/rn/";
    }

    const priceWithLabel = this.priceLabelDetector(this.props);

    return (
      <tr
        className="border-bottom mb-5"
        onMouseLeave={() => {
          this.setState({
            hovered: false,
          });
        }}
        onMouseEnter={() => {
          this.setState({
            hovered: true,
          });
        }}
      >
        <Td
          data-name="name"
          className={
            this.state.hovered ? "bg-warning bordered" : "bg-white bordered"
          }
        >
          {this.props.inventoryItem.name || "Не указано"}
        </Td>
        <Td
          className={
            this.state.hovered ? "bg-warning bordered" : "bg-white bordered"
          }
          style={{ position: "relative" }}
        >
          <div style={{ maxHeight: "90px", overflow: "hidden" }}>
            {this.props.inventoryItem.image.length > 0 ? (
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={`${domain}${
                  this.props.inventoryItem.image[
                    this.props.inventoryItem.image.length - 1
                  ].path
                }`}
              >
                <img
                  alt="Скриншот"
                  style={{
                    width: "90px",
                    height: "auto",
                  }}
                  src={`${domain}${
                    this.props.inventoryItem.image[
                      this.props.inventoryItem.image.length - 1
                    ].path
                  }`}
                />
              </a>
            ) : (
              <Placeholder />
            )}
          </div>
        </Td>
        <Td
          className={
            this.state.hovered ? "bg-warning bordered" : "bg-white bordered"
          }
        >
          {this.props.inventoryItem.format
            ? this.props.inventoryItem.format
            : "Не указано"}
        </Td>
        <Td
          className={
            this.state.hovered ? "bg-warning bordered" : "bg-white bordered"
          }
        >
          {this.props.inventoryItem.type === "publication" && "Публикация"}
          {this.props.inventoryItem.type === "banner" && "Баннер"}
          {this.props.inventoryItem.type === "special" &&
            "Спецпроект / Эксклюзив"}
          {this.props.inventoryItem.type === "additional" && "Доп. услуги"}
          {this.props.inventoryItem.type === "card" && "Карточка"}
        </Td>
        <Td
          className={
            this.state.hovered ? "bg-warning bordered" : "bg-white bordered"
          }
        >
          {this.state.data.priceType === 5 ? "День" : null}
          {this.props.inventoryItem.placement_type === 1 &&
            `Статика ${this.props.inventoryItem.showing_percent}%`}
          {this.props.inventoryItem.placement_type === 2 && `Динамика`}
        </Td>
        <Td
          className={
            this.state.hovered ? "bg-warning bordered" : "bg-white bordered"
          }
          style={{
            userSelect: "none",
            position: "relative",
          }}
          onDoubleClick={() => {
            this.setState({
              ...this.state,
              isChanging: true,
            });
          }}
        >
          {this.state.isChanging ? (
            <PopUp
              style={{
                zIndex: 2,
                top: "25px",
                overflow: "inherit",
                height: "200px",
              }}
            >
              <Mutation mutation={RN_INVENTORY_UPDATE}>
                {(update: any, { data }: any) => (
                  <div className="row mt-3">
                    <div className="row col-12">
                      <div className="col-6 col-form-label">Тип цены</div>
                      <div className="col-6">
                        <select
                          defaultValue={this.props.inventoryItem.priceType}
                          className="form-control"
                          style={{
                            height: "25px",
                          }}
                          onChange={(e) => {
                            this.setState({
                              ...this.state,
                              data: {
                                ...this.state.data,
                                price_value_month: null,
                                price_value_week: null,
                                priceType: +e.target.value,
                              },
                            });
                          }}
                        >
                          <option value="5">День</option>
                          <option value="1">Неделя / месяц</option>
                          <option value="6">2 недели</option>
                          <option value="2">За 1000 показов / CPM</option>
                          <option value="3">За период / CPC</option>
                          <option value="4">Фиксированная</option>
                        </select>
                      </div>
                    </div>

                    {this.state.data.priceType === 1 ? (
                      <>
                        <div className="row mt-3 col-12">
                          <div className="col-6 col-form-label">
                            Цена за неделю
                          </div>
                          <div className="col-6">
                            <input
                              type="text "
                              defaultValue={this.state.data.price_value_week}
                              onChange={(e) => {
                                this.setState({
                                  ...this.state,
                                  data: {
                                    ...this.state.data,
                                    price_value_week: e.target.value,
                                  },
                                });
                              }}
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="row mt-3 col-12">
                          <div className="col-6 col-form-label">
                            Цена за месяц
                          </div>
                          <div className="col-6">
                            <input
                              type="text"
                              defaultValue={this.state.data.price_value_month}
                              onChange={(e) => {
                                this.setState({
                                  ...this.state,
                                  data: {
                                    ...this.state.data,
                                    price_value_month: e.target.value,
                                  },
                                });
                              }}
                              className="form-control"
                            />
                          </div>
                        </div>
                      </>
                    ) : (
                      <div className="row mt-3 col-12">
                        <div className="col-6 col-form-label">Цена</div>
                        <div className="col-6">
                          <input
                            type="text "
                            defaultValue={this.state.data.price_value}
                            className="form-control"
                            onChange={(e) => {
                              this.setState({
                                ...this.state,
                                data: {
                                  ...this.state.data,
                                  price_value: e.target.value,
                                },
                              });
                            }}
                          />
                        </div>
                      </div>
                    )}

                    <div className="row col-12 mt-3">
                      <div className="col-6 form-group">
                        <button
                          className="btn btn-sm btn-success mr-1"
                          disabled={this.state.isMutating}
                          onClick={() => {
                            this.setState({
                              ...this.state,
                              isMutating: true,
                            });

                            update({
                              variables: {
                                inventory: {
                                  ...this.state.data,
                                },
                              },
                              refetchQueries: ["RnInventoryList"],
                            }).then(() => {
                              this.setState({
                                ...this.state,
                                isChanging: false,
                                isMutating: false,
                              });
                            });
                          }}
                        >
                          Сохранить
                        </button>
                        <button
                          className="btn btn-sm btn-danger"
                          onClick={() => {
                            this.setState({
                              ...this.state,
                              isChanging: false,
                            });
                          }}
                        >
                          Отменить
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </Mutation>
            </PopUp>
          ) : null}

          <>{priceWithLabel}</>
        </Td>
        <Td
          className={
            this.state.hovered ? "bg-warning bordered" : "bg-white bordered"
          }
        >
          {this.props.inventoryItem.Targeting
            ? this.props.inventoryItem.Targeting.map(
                (item: any) => item.name
              ).join(", ")
            : null}
        </Td>
        <Td
          className={
            this.state.hovered ? "bg-warning bordered" : "bg-white bordered"
          }
        >
          {getRating(this.props.inventoryItem.rating)}
        </Td>
        <Td
          className={
            this.state.hovered ? "bg-warning bordered" : "bg-white bordered"
          }
        >
          {this.props.inventoryItem.display_desktop ? (
            <>
              десктоп
              <br />
            </>
          ) : null}
          {this.props.inventoryItem.display_mobile ? `мобильное` : null}
        </Td>
        <Td
          className={
            this.state.hovered ? "bg-warning bordered" : "bg-white bordered"
          }
          style={{
            maxWidth: "106px",
          }}
        >
          {this.props.inventoryItem.Pages.map((item: any) => item.name).join(
            ", "
          )}
        </Td>
        <Td
          className={
            this.state.hovered ? "bg-warning bordered" : "bg-white bordered"
          }
        >
          {this.props.inventoryItem.comment
            ? this.props.inventoryItem.comment
            : null}
        </Td>
        <Td
          className={
            this.state.hovered ? "bg-warning bordered" : "bg-white bordered"
          }
        >
          {this.props.mPlanItem.inventory_id === this.props.inventoryItem.id ? (
            <button className="btn btn-primary" disabled={true}>
              Выбрано
            </button>
          ) : (
            <button
              className="btn btn-secondary btn-sm"
              onClick={() => {
                this.props
                  .addInventory({
                    variables: {
                      id: this.props.mPlanItem.id,
                      field: "inventory_id",
                      value: this.props.inventoryItem.id,
                    },
                    refetchQueries: [
                      {
                        query: RN_MP_ITEM,
                        variables: {
                          id: this.props.mPlanItem.id,
                        },
                      },
                      "RnMp",
                      "RnMpItemList",
                      "rnMp",
                    ],
                  })
                  .then(() => {
                    this.props.setClose();
                  });
              }}
            >
              Выбрать
            </button>
          )}
        </Td>
      </tr>
    );
  }
}

export default InventoryListItemModal;
