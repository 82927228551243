import React from "react";
import { ApolloConsumer } from "react-apollo";
import { useQuery } from "@apollo/react-hooks";
import { RN_PLATFORM_LIST } from "../RN/GraphQL/RNqueries";
import Header from "../template/Header";
import ListItem from "./platformList/listItemContainer";
import Pagination from "./platformList/pagination";
import Filter from "./platformList/filter";

const queryString = require("querystring");

interface IPlatformListProps {
  client?: any;
  page: number;
  count?: number;
}

function PlatformList({ client, page }: IPlatformListProps) {
  const variables = {
    params: {
      count: 100,
      offset: (page - 1) * 100,
      filter: {
        draft: {
          field: "draft",
          operator: "EQ",
          values: ["0"],
        },
        hidden: {
          field: "hidden",
          operator: "EQ",
          values: ["0"],
        },
      },
    },
  };

  const { loading, data, fetchMore } = useQuery(RN_PLATFORM_LIST, {
    client,
    variables,
  });

  const [currentPage, setCurrentPage] = React.useState<number>(+page || 1);

  function updateList(newVariables: any) {
    fetchMore({
      variables: newVariables,
      updateQuery: (prev, { fetchMoreResult }) => {
        return fetchMoreResult;
      },
    });

    document.location.hash = "";
    setCurrentPage(1);
  }

  function setPage(page: number) {
    fetchMore({
      variables: {
        params: {
          ...variables.params,
          count: variables.params.count,
          offset: (page - 1) * variables.params.count,
        },
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        return fetchMoreResult;
      },
    });
    setCurrentPage(page);
  }

  return (
    <>
      <Header src_data={data} />
      <section className="content">
        <div className="container-fluid platforms-list">
          <div className="table-container">
            <div className="platforms-list-body mt-4 mb-4">
              <div className="row">
                <Filter
                  variables={variables}
                  updateQuery={(variables: any) => updateList(variables)}
                  me={data && data.me}
                  client={client}
                />

                <div className="col-12 row">
                  {loading ? (
                    "Загрузка"
                  ) : (
                    <>
                      {data &&
                        data.RnPlatformList.data.map((item: any) => (
                          <ListItem key={item.id} item={item} />
                        ))}

                      {data && (
                        <Pagination
                          countOfButtons={3}
                          count={variables.params.count}
                          page={currentPage}
                          pages={Math.ceil(
                            data.RnPlatformList.pageInfo.total /
                              variables.params.count
                          )}
                          total={data.RnPlatformList.pageInfo.total}
                          setPage={setPage}
                        />
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default function PlatformListContainer(props: any) {
  const params = props.location.hash
    ? queryString.parse(props.location.hash.substr(1))
    : [];

  return (
    <ApolloConsumer>
      {(client) => (
        <PlatformList
          client={client}
          count={params.count ? params.count : 100}
          page={params.page ? params.page : 1}
        />
      )}
    </ApolloConsumer>
  );
}
