import React from "react";
import { Query } from "react-apollo";
import { GET_INVENTORY_PAGE_LIST } from "../RN/Components/MediaPlan/InventoryListModal/gql";
import Th from "./styled/th";
import Item from "./inventoryList/item";

export default function List({
  list,
  updateList,
  variables,
  setVariables,
  platformID,
  mPlanItem, //строка медиаплана
  me, // юзер
  addInventory, //добавить инвентарь
  setClose, //закрыть после выбора инвентаря
  platform
}: any) {
  return (
    <table className="w-100">
      <thead>
        <tr className="border-bottom">
          <Th
            style={{
              maxWidth: "106px"
            }}
          >
            Название
          </Th>
          <Th>Превью</Th>
          <Th>Формат</Th>
          <Th
            style={{
              maxWidth: "106px"
            }}
          >
            Тип инвентаря
            <select
              className="form-control"
              onChange={e => {
                const VALUE: string = e.target.value;
                const filter: any = [...variables.params.filter];
                const sortedFilter = filter.filter(
                  (f: any) => f.field !== "inventoryType"
                );

                const variablesInner = {
                  ...variables,
                  params: {
                    ...variables.params,
                    filter: [
                      ...sortedFilter,
                      {
                        field: "inventoryType",
                        operator: "EQ",
                        values: [VALUE]
                      }
                    ]
                  }
                };

                updateList(variablesInner);
                setVariables(variablesInner);
              }}
            >
              //todo наверное, стоит выделить все в отдельный справочник
              <option value="all">Все</option>
              <option value="banner">Баннер</option>
              <option value="card">Карточка</option>
              <option value="publication">
                Размещение публикаций и пресс-релизов
              </option>
              <option value="special">Эксклюзив / спецпроекты</option>
              <option value="additional">Доп. услуги</option>
            </select>
          </Th>
          <Th>Ротация/ Период</Th>
          <Th>Цена</Th>
          <Th>Таргетинги</Th>
          <Th>Рейтинг</Th>
          {/*<th className="bg-light">Мин закуп</th>*/}
          <Th>Отображение</Th>
          <Th
            style={{
              maxWidth: "106px"
            }}
          >
            Разделы сайта
            <Query
              query={GET_INVENTORY_PAGE_LIST}
              variables={{
                params: {
                  count: 999,
                  offset: 0,
                  filter: [
                    {
                      field: "PID",
                      operator: "EQ",
                      values: [platformID]
                    }
                  ]
                }
              }}
            >
              {({ loading, error, data }: any) => {
                if (loading) return "Loading...";
                if (error) return `Error! ${error.message}`;
                return (
                  <select
                    className="form-control"
                    onChange={e => {
                      const VALUE: string = e.target.value;
                      const filter: any = [...variables.params.filter];
                      const sortedFilter = filter.filter(
                        (f: any) => f.field !== "page"
                      );

                      const variablesInner = {
                        ...variables,
                        params: {
                          ...variables.params,
                          filter: [
                            ...sortedFilter,
                            {
                              field: "page",
                              operator: "EQ",
                              values: [VALUE]
                            }
                          ]
                        }
                      };

                      updateList(variablesInner);
                      setVariables(variablesInner);
                    }}
                  >
                    <option value="all">Все</option>
                    {data.RN.RnInventoryPageList.map((page: any) => (
                      <option key={page.id} value={page.id}>
                        {page.name}
                      </option>
                    ))}
                  </select>
                );
              }}
            </Query>
          </Th>
          <Th>Комментарий</Th>
          <Th>&nbsp;</Th>
        </tr>
      </thead>
      <tbody>
        {list.map((item: any) => (
          <Item
            platform={platform}
            mPlanItem={mPlanItem}
            key={item.id}
            inventoryItem={item}
            addInventory={addInventory}
            me={me}
            setClose={setClose}
          />
        ))}
      </tbody>
    </table>
  );
}
