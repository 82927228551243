import React, { Component } from "react";

import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import { ApolloConsumer, ApolloProvider } from "react-apollo";
import { ApolloClient } from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import { ApolloLink } from "apollo-link";
import { onError } from "apollo-link-error";
import { createUploadLink } from "apollo-upload-client";

import Platform from "./RN/Components/Platform/Platform";
import { PlatformEditWithSave } from "./RN/Components/Platform/Platform";
import PlatformList from "./pages/platformList";

import InventoryItemAdd from "./RN/Components/Platform/Tabs/Inventory/InventoryItemAdd";
import { InventoryEdit } from "./RN/Components/Platform/Tabs/Inventory/InventoryItemAdd";
import { InventoryLightContainer } from "./RN/Components/Platform/InventoryLight";

import { FormWithSaveEdit } from "./RN/Components/Briefing/Form/Form";
import View from "./RN/Components/Briefing/View/View";
import { RnBriefingList } from "./RN/Components/Briefing/List";
import Form from "./RN/Components/Briefing/Form/Form";

import MpList from "./RN/Components/MediaPlan/MediaPlanList";
import MediaPlan from "./RN/Components/MediaPlan/MediaPlan";

import Report from "./RN/Components/Reports/ContentReport";
import GroupList from "./RN/Components/Group/GroupList";
import Group from "./RN/Components/Group/Group";
import GroupAccess from "./RN/Components/Group/GroupAccess";
import TopManager from "./RN/Components/TopManager/TopManager";
import Logger from "./RN/Components/Logger";
import Settings from "./pages/settings";
import OperatorRequests from "./pages/operatorRequests";
import { BriefingClone } from "./components/BriefingClone";
import { ItemLog } from "./pages/itemLog";
import DateFnsUtils from "@date-io/date-fns";
import ruLocale from "date-fns/locale/ru";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import HeadReport from "./pages/headReport";
import "../src/css/main.css";

const queryString = require("query-string");

/* global $*/
class App extends Component {
  state = {
    apolloClient: null,
    token: null,
    apolloConfigured: false,
  };

  constructor(props) {
    super(props);
    this.setupApollo();
  }

  getToken = async () => {
    try {
      let response = await fetch(process.env.REACT_APP_API_AUTH_URL);

      let jsonData = await response.json();

      if (jsonData.success) {
        if (jsonData.allow.indexOf("rn") === -1) {
          document.location.href = `/${process.env.REACT_APP_AUTH_URL}`;
        }

        this.setState({
          token: jsonData.token,
        });
      } else {
        let cookies = document.cookie;
        cookies = cookies.split("; ");
        let cookies_array = [];

        cookies.forEach((item) => {
          let mini_array = item.split("=");
          cookies_array[mini_array[0]] = mini_array[1];
        });

        let token = false;

        let hash = queryString.parse(window.location.hash);

        if (hash.token) {
          token = hash.token;
        } else {
          if (cookies_array.token) {
            token = cookies_array.token;
          }
        }

        if (token) {
          this.setState({
            token: token,
          });
        }
      }
    } catch (error) {
      // let url = document.location.href;
      //document.location.href = `${protocol}${auth_path}/?back=${url}`;
      let cookies = document.cookie;
      cookies = cookies.split("; ");
      let cookies_array = [];

      cookies.forEach((item) => {
        let mini_array = item.split("=");
        cookies_array[mini_array[0]] = mini_array[1];
      });

      let token = false;
      if (cookies_array.token) token = cookies_array.token;

      if (token) {
        this.setState({
          token,
        });
      }
    }
  };

  async setupApollo() {
    await this.getToken();

    const errorLink = onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors) {
        graphQLErrors.map(({ message, locations, path }) =>
          console.log(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
          )
        );
      }

      if (networkError) {
        if (networkError.result) {
          if (!networkError.result.error.code) $(".alert").css("opacity", 1);

          if (
            networkError.result.error.code === 100001 ||
            networkError.result.error.code === 100002
          ) {
            let url = document.location.href;
            url = encodeURIComponent(url);
            document.location = `${process.env.REACT_APP_AUTH_URL}?back=${url}`;
          }
        }
      }
    });

    const link = ApolloLink.from([
      errorLink,
      createUploadLink({
        uri: process.env.REACT_APP_API_URL,
        headers: {
          origin: "http://localhost:3000",
          Authorization: `Bearer ${this.state.token}`,
        },
      }),
    ]);

    //создание клиента
    const client = new ApolloClient({
      cache: new InMemoryCache({
        addTypename: false,
      }),
      link,
    });

    this.setState({
      apolloClient: client,
      apolloConfigured: true,
    });
  }

  render() {
    return this.state.apolloConfigured
      ? this.renderApp()
      : "Проверка авторизации";
  }

  componentDidMount() {
    const url = document.location.href.split("#token=");

    if (url[1]) {
      document.cookie = `token=${url[1]}; path=/;`;
      window.history.pushState("", document.title, url[0]);
    }
  }

  renderApp() {
    return (
      <ApolloProvider client={this.state.apolloClient}>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ruLocale}>
          <Router basename={process.env.REACT_APP_BASENAME}>
            <Switch>
              <Route exact path="/" component={PlatformList} />
              <Route exact path="/mediaplan/list" component={MpList} />
              <Route
                exact
                path="/mediaplan/update/:id/:type"
                component={MediaPlan}
              />
              <Route exact path="/mediaplan/update/:id" component={MediaPlan} />

              <Route path="/platform/add" component={Platform} />
              <Route
                path="/platform/update/:id"
                component={(props) => <PlatformEditWithSave {...props} />}
              />
              <Route
                exact
                path="/platform/inventory/add/:type/:platformId/"
                component={InventoryItemAdd}
              />
              <Route
                exact
                path="/platform/inventory/add/:type/:platformId/:inventoryId"
                component={InventoryEdit}
              />
              <Route
                exact
                path="/inventory/:platformId/:inventoryId"
                component={InventoryLightContainer}
              />

              <Route exact path="/briefing/list" component={RnBriefingList} />
              <Route
                path="/briefing/add"
                component={(props) => {
                  document.location.href = 'https://clickach.ru/admin/brief/';
                }}
              />
              <Route
                path="/briefing/view/:id"
                render={(props) => <View {...props} />}
              />
              <Route
                path="/briefing/update/:id"
                render={(props) => <FormWithSaveEdit {...props} />}
              />
              <Route
                path="/briefing/clone/:id"
                render={(props) => (
                  <ApolloConsumer>
                    {(client) => <BriefingClone {...props} client={client} />}
                  </ApolloConsumer>
                )}
              />

              <Route exact path="/content-report" component={Report} />

              <Route exact path="/top-manager" component={TopManager} />

              <Route exact path="/group/list" component={GroupList} />
              <Route exact path="/group/access" component={GroupAccess} />
              <Route exact path="/group/edit/:id" component={Group} />

              <Route exact path="/log/:type" component={Logger} />
              {/*<Route exact path="/report/:type">*/}
              {/*  <ReportPage />*/}
              {/*</Route>*/}
              <Route exact path="/settings">
                <Settings />
              </Route>
              <Route exact path="/operator-request">
                <OperatorRequests />
              </Route>
              <Route exact path="/item-log">
                <ItemLog client={this.state.apolloClient} />
              </Route>
              <Route path="/report">
                <HeadReport />
              </Route>
              <Route path="/">страница не найдена</Route>
            </Switch>
          </Router>
        </MuiPickersUtilsProvider>
      </ApolloProvider>
    );
  }
}

export default App;
