import React from "react";
import { NavLink } from "react-router-dom";
import { ApolloConsumer } from "react-apollo";
import DropDown from "../components/styled/dropdown";
import {
  useGetMeQuery,
  useLogoutLazyQuery,
  useMpItemCreateMutation,
  useRnMpCreateMutation,
} from "../graphql/types";

function Header(props) {
  function renderLink(me) {
    if (me) {
      //todo нужно перенести проверку в бэк
      if (
        me.email === "margarita.vernaya@deltaclick.ru" ||
        me.email === "inna.ilina@deltaclick.ru" ||
        me.email === "elena.novikova@deltaclick.ru" ||
        me.email === "ljudmila.legayj@deltaclick.ru" ||
        me.email === "evgenij.denisenko@delta-plan.ru" ||
        me.email === "aleksandr.shpin@delta-plan.ru" ||
        me.email === "admin@admin"
      ) {
        return true;
      } else {
        return false;
      }
    }
  }

  const { loading, data } = useGetMeQuery({
    client: props.client,
  });

  const [mpCreate] = useRnMpCreateMutation({
    client: props.client,
  });
  const [mpItemCreate] = useMpItemCreateMutation({
    client: props.client,
  });

  const [logout] = useLogoutLazyQuery({
    onCompleted: () => {
      document.location.reload();
    },
  });

  return (
    <section className="section-top">
      <nav className="navbar navbar-expand-lg navbar-inverse navbar-dark bg-dark">
        <div className="container-fluid">
          <span className="navbar-brand mr-5">
            <NavLink className="navbar-brand" to="/">
              RN
            </NavLink>
          </span>
          <div className="collapse navbar-collapse justify-content-end">
            <ul className="nav navbar-nav navbar-right">
              <li className="nav-item">
                <NavLink
                  activeClassName="active"
                  className="nav-link"
                  to="/mediaplan/list"
                >
                  Список медиапланов
                </NavLink>
              </li>

              {loading ? null : (
                <li className="nav-item">
                  <a
                    className="nav-link"
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();

                      mpCreate({
                        variables: {
                          userId: data.me.comon_id,
                          date: new Date().toISOString(),
                        },
                      }).then((res) => {
                        mpItemCreate({
                          variables: {
                            mpId: res.data.RnMpCreate.id,
                          },
                        }).then(
                          () =>
                            (document.location = `${process.env.REACT_APP_URL}${process.env.REACT_APP_BASENAME}mediaplan/update/${res.data.RnMpCreate.id}`)
                        );
                      });
                    }}
                  >
                    Создать медиаплан
                  </a>
                </li>
              )}

              <li className="nav-item">
                <DropDown label={"Брифинги"}>
                  <NavLink
                    activeClassName="active"
                    className="dropdown-item"
                    to="/briefing/add"
                  >
                    Создать
                  </NavLink>
                  <NavLink
                    activeClassName="active"
                    className="dropdown-item"
                    to="/briefing/list"
                  >
                    Список
                  </NavLink>
                </DropDown>
              </li>

              {data && renderLink(data.me) ? (
                <>
                  <li className="nav-item">
                    <NavLink
                      activeClassName="active"
                      className="nav-link"
                      to="/report"
                    >
                      Отчет руководителя
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      activeClassName="active"
                      className="nav-link"
                      to="/top-manager"
                    >
                      Главный байер
                    </NavLink>
                  </li>

                  <li className="nav-item dropdown">
                    <DropDown label={"Логи"}>
                      <NavLink
                        activeClassName="active"
                        className="dropdown-item"
                        to="/log/inventory"
                      >
                        Инвентарь
                      </NavLink>
                      <NavLink
                        activeClassName="active"
                        className="dropdown-item"
                        to="/log/coefficient"
                      >
                        Коэффициенты
                      </NavLink>
                      <NavLink
                        activeClassName="active"
                        className="dropdown-item"
                        to="/log/discount"
                      >
                        Скидки
                      </NavLink>
                      <NavLink
                        activeClassName="active"
                        className="dropdown-item"
                        to="/operator-request"
                      >
                        Запросы поставщикам
                      </NavLink>
                    </DropDown>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      className="nav-link"
                      activeClassName="active"
                      to="/item-log"
                    >
                      История закупа
                    </NavLink>
                  </li>

                  <li className="nav-item">
                    <NavLink
                      className="nav-link"
                      activeClassName="active"
                      to="/settings"
                    >
                      Настройки
                    </NavLink>
                  </li>
                </>
              ) : null}
              <li className="nav-item">
                <a
                  href="#"
                  className="nav-link"
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                >
                  {data ? data.me.email : null}
                </a>
              </li>
              <li className="nav-item">
                <a href="#" className="nav-link" onClick={logout}>
                  Выйти
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </section>
  );
}

const HeaderContainer = (props) => {
  return (
    <ApolloConsumer>
      {(client) => <Header {...props} client={client} />}
    </ApolloConsumer>
  );
};

export default HeaderContainer;
