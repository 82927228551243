import gql from "graphql-tag";

export const GET_CONTENT_MANAGER_EMAIL = gql`
  query {
    RN {
      getContentManagerEmail
    }
  }
`;

export const ADD_SETTING = gql`
  mutation($field: String!, $value: String!) {
    Rn {
      updateSetting(field: $field, value: $value)
    }
  }
`;
