import gql from 'graphql-tag';


export const LOG_LIST = gql`
    query LogList($params: ParamsInput, $type: String) {
        LogList(params:$params, type:$type) {
            pageInfo
            data {
                id
                src_id
                entry_date
                prev_value
                next_value
                field_name
                action_type
                platform_id
                User {
                    id
                    comon_id
                    login
                    email
                }
            }
        }
    }
`;
