import React from "react";
import { useUndoItemMutation } from "../../../graphql/types";

interface IUndoItem {
  readonly mpID: number;
  readonly disabled: boolean;
  client: any;
}

export function UndoItem({ mpID, disabled, client }: IUndoItem) {
  const [undo] = useUndoItemMutation({
    variables: {
      mpID,
    },
    refetchQueries: ["RnMp", "rnMp"],
    client,
  });

  return (
    <button
      className="btn btn-light mt-3"
      disabled={disabled}
      onClick={() => undo()}
    >
      Вернуть строчку
    </button>
  );
}
