import React from "react";
import { FileUpload } from "../../../../../components/fileUpload/fileUpload";
import { ApolloConsumer } from "react-apollo";

interface IRequirementsProps {
  comment?: string;
  platformID: string | number;
  inventoryID: string | number;
  technical_requirements_publication?: string;
  addFile?: boolean;
  onChange?: () => void;
}

export function Requirements({
  comment,
  platformID,
  inventoryID,
  technical_requirements_publication,
  addFile,
  onChange,
}: IRequirementsProps) {
  return (
    <>
      {(comment && comment !== "") ||
      (technical_requirements_publication &&
        technical_requirements_publication !== "") ? (
        <>
          Есть
          <br />
          <a
            href={`${process.env.REACT_APP_URL}/inventory/${platformID}/${inventoryID}`}
          >
            Смотреть
          </a>
        </>
      ) : (
        <>
          <span className="text-danger">Нет</span>
          <br />
          <a
            target="_blank"
            rel="noopener noreferrer"
            style={{ width: "auto" }}
            href={`${process.env.REACT_APP_URL}/platform/update/${platformID}`}
          >
            Добавить
          </a>
        </>
      )}
      {addFile && (
        <>
          <p className="text-danger mt-1 mb-0">
            У баннера нет изображения, вы можете его
          </p>
          <ApolloConsumer>
            {(client) => (
              <FileUpload
                client={client}
                inventoryID={inventoryID}
                onChange={onChange}
              />
            )}
          </ApolloConsumer>
        </>
      )}
    </>
  );
}
