import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Mutation } from "react-apollo";
import gql from "graphql-tag";

class PlatformListItem extends Component {
  state = {
    hovered: false
  };

  render() {
    return (
      <div
        // style={{
        //     backgroundColor: this.props.item.price_is_needed || this.state.hovered ? 'rgba(255, 171, 18, 0.14)' : 'inherit'
        // }}
        onMouseEnter={() => {
          this.setState({
            hovered: true
          });
        }}
        onMouseLeave={() => {
          this.setState({
            hovered: false
          });
        }}
        className="col-12 row border-bottom py-3"
        key={this.props.item.id}
      >
        <div className="col">
          <Link className="ml-1" to={`/platform/update/${this.props.item.id}`}>
            {this.props.item.name}
          </Link>
          <br />
          {this.props.me && +this.props.me.id === 1 ? (
            <Mutation
              mutation={gql`
                mutation($id: Int!, $author_id: Int) {
                  RnPlatformRemove(id: $id, author_id: $author_id) {
                    id
                  }
                }
              `}
            >
              {(deleteItem, { mpItemData }) => (
                <button
                  className="btn btn-danger btn-sm"
                  onClick={() => {
                    let answer = window.confirm(
                      "Вы точно хотите удалить площадку?"
                    );
                    if (answer) {
                      deleteItem({
                        variables: {
                          id: this.props.item.id,
                          author_id: this.props.me.id
                        },
                        refetchQueries: ["RnPlatformList"]
                      });
                    }
                  }}
                >
                  Удалить
                </button>
              )}
            </Mutation>
          ) : null}
        </div>
        <div className="col text-center">
          {this.props.item.CityList
            ? this.props.item.CityList.map(item => (
                <React.Fragment key={item.AOGUID}>
                  <>
                    {item.SHORTNAME} {item.FORMALNAME}
                    <br />
                    <span style={{ color: "#B0B0B0" }}>
                      {item.Parent && item.Parent.Parent ? (
                        <>
                          {item.Parent.Parent.FORMALNAME}{" "}
                          {item.Parent.Parent.SHORTNAME},
                        </>
                      ) : null}{" "}
                      {item.Parent ? (
                        <>
                          {item.Parent.FORMALNAME} {item.Parent.SHORTNAME}
                        </>
                      ) : null}{" "}
                    </span>{" "}
                  </>
                </React.Fragment>
              ))
            : null}{" "}
          <br />
          {this.props.item.RegionList
            ? this.props.item.RegionList.map(item => (
                <React.Fragment key={item.AOGUID}>
                  {" "}
                  {item.FORMALNAME} {item.SHORTNAME}
                  <br />
                </React.Fragment>
              ))
            : null}{" "}
          <br />
        </div>
        <div className="col text-center">
          {this.props.item.Price && this.props.item.Price.vat === 1
            ? "с НДС"
            : null}
          {this.props.item.Price && this.props.item.Price.vat === 2
            ? "без НДС"
            : null}
          {this.props.item.Price && this.props.item.Price.vat === 4
            ? "с НДС / без НДС"
            : null}
        </div>
        <div className="col text-center">
          {this.props.item.InventoryItems &&
          this.props.item.InventoryItems.length > 0
            ? "Да"
            : "Нет"}
        </div>
        <div className="col text-center">
          {this.props.item.ContactsItems &&
          this.props.item.ContactsItems.length > 0
            ? "Да"
            : "Нет"}
        </div>
        <div className="col text-center">
          {this.props.item.Price && this.props.item.Price.comment
            ? "Да"
            : "Нет"}
        </div>
        <div className="col text-center">
          {this.props.item.Price && this.props.item.Price.buyer_note
            ? "Прайс требует доработки"
            : this.props.item.checked
            ? "Проверено байером"
            : null}
          {/*<label>*/}
          {/*<input*/}
          {/*type="checkbox"*/}
          {/*checked={ this.props.item.price_is_needed }*/}
          {/*onChange={ e => {*/}
          {/*const value = e.target.checked ? 1 : 0;*/}

          {/*this.props.updateField({*/}
          {/*variables: {*/}
          {/*id: this.props.item.id,*/}
          {/*field: 'price_is_needed',*/}
          {/*value: value*/}

          {/*},*/}
          {/*refetchQueries: [*/}
          {/*'RnPlatformItem'*/}
          {/*]*/}
          {/*});*/}

          {/*}}*/}
          {/*/> прайс необходим</label>*/}
        </div>
        <div className="col-2 row">
          <div className="col-6 p-0">
            {/*<select*/}
            {/*className="form-control"*/}
            {/*defaultValue={ this.props.item.adequate ? this.props.item.adequate : ''}*/}
            {/*onChange={ e => {*/}
            {/*const value = +e.target.value;*/}
            {/*this.props.updateField({*/}
            {/*variables: {*/}
            {/*id: this.props.item.id,*/}
            {/*field: 'adequate',*/}
            {/*value: value*/}

            {/*},*/}
            {/*refetchQueries: [*/}
            {/*'RnPlatformItem'*/}
            {/*]*/}
            {/*});*/}
            {/*}}*/}
            {/*>*/}
            {/*<option value="0" hidden={true}>Выбрать</option>*/}
            {/*<option value="1">Красный</option>*/}
            {/*<option value="2">Желтый</option>*/}
            {/*<option value="3">Зеленый</option>*/}
            {/*</select>*/}
          </div>
          <div className="col-6 p-0 pl-1">
            {/*<input*/}
            {/*className="form-control"*/}
            {/*defaultValue={ this.props.item.adequate_comment ? this.props.item.adequate_comment : ''}*/}
            {/*type="text"*/}
            {/*onChange={ e => {*/}
            {/*const value = e.target.value;*/}
            {/*if(value.length > 3) {*/}
            {/*this.props.updateFieldString({*/}
            {/*variables: {*/}
            {/*id: this.props.item.id,*/}
            {/*field: 'adequate_comment',*/}
            {/*value: value*/}
            {/*},*/}
            {/*refetchQueries: [*/}
            {/*"RnPlatformList"*/}
            {/*]*/}
            {/*});*/}
            {/*}*/}
            {/*}}*/}
            {/*/>*/}
          </div>
        </div>
        <div className="col">
          <Link
            className="ml-1"
            to={`/platform/update/${this.props.item.id}/#tab=contacts`}
          >
            Контакты
          </Link>
        </div>
      </div>
    );
  }
}

export default PlatformListItem;
