import React from "react";
// @ts-ignore
import AsyncSelect from "react-select/lib/Async";
import { GET_FIAS_LIST } from "./geoSearch/gql";
import { debugLog } from "@graphql-codegen/cli/utils/debugging";

//todo добавить интерфейсы и настройки
//todo обновить селект

type selectOption = {
  label: any;
  value: number | string;
  srcData?: any;
};

export default function GeoSelect({
  onChange,
  value,
  client,
  className,
  isMulti = true,
}: any) {
  return (
    <AsyncSelect
      isMulti={isMulti}
      className={className ? className : "col-12"}
      name="cities"
      placeholder={""}
      defaultValue={value}
      loadOptions={(inputValue: string) => {
        let itemsGeo: any[] = [];
        return new Promise((resolve, reject) => {
          client
            .query({
              query: GET_FIAS_LIST,
              variables: {
                params: {
                  offset: 0,
                  count: 1000,
                  filter: [
                    {
                      values: [inputValue],
                      operator: "LIKE",
                      field: "name",
                    },
                    {
                      field: "aolevel",
                      operator: "EQ",
                      values: [6, 1],
                    },
                  ],
                },
              },
            })
            .then((result: any) => {
              itemsGeo = [];

              for (let i of result.data.Geo.FiasEntityList) {
                let obj: any = {};
                obj.srcData = i;
                obj.value = i.AOGUID;
                obj.label = (
                  <>
                    {i.FORMALNAME} {i.SHORTNAME}
                    <br />
                    <span style={{ color: "#B0B0B0" }}>
                      {i.Parent && i.Parent.Parent ? (
                        <>
                          {i.Parent.Parent.FORMALNAME}{" "}
                          {i.Parent.Parent.SHORTNAME},
                        </>
                      ) : null}{" "}
                      {i.Parent ? (
                        <>
                          {i.Parent.FORMALNAME} {i.Parent.SHORTNAME}
                        </>
                      ) : null}{" "}
                    </span>
                  </>
                );
                itemsGeo.push(obj);
                resolve();
              }
            });
        }).then(() => itemsGeo);
      }}
      onChange={(entities: selectOption[] | selectOption) => {
        if (Array.isArray(entities)) {
          onChange(entities.map((e) => e.srcData));
        } else {
          onChange(entities.srcData);
        }
      }}
    />
  );
}
