import React from "react";
import Region from "./region";
import { GET_FEDERAL_DISTRICT } from "./geoQueries";
import { Query } from "react-apollo";

const District = ({
  district,
  ulStyle,
  handleChange,
  data_geo_tree,
  openHandler,
  tree,
  handleChangeCity,
  indexKeyDistrict,
  src_data,
  geo_data,
  handleChangeGeoData,
  handleChangeGeoDataCity,
  handleChangeGeoDataRegion
}) => {
  const isChecked = () => {
    if (data_geo_tree.some(i => i == district.Code)) {
      return true;
    }

    return false;
  };

  return (
    <Query
      variables={{
        id: district.Code
      }}
      query={GET_FEDERAL_DISTRICT}
    >
      {({ loading, error, data }) => {
        if (loading) return null;
        if (error) return `Error! ${error.message}`;

        return (
          <li>
            <span
              className="mr-1"
              style={{
                fontSize: "1rem"
              }}
              onClick={() => {
                let local_tree = tree.filter(i => i.Code !== district.Code);
                let element = tree.find(i => i.Code === district.Code);

                if (element) {
                  local_tree = [
                    ...local_tree,
                    {
                      Code: district.Code,
                      isOpened: !element.isOpened,
                      regions: []
                    }
                  ];
                } else {
                  local_tree = [
                    ...local_tree,
                    {
                      Code: district.Code,
                      isOpened: true,
                      regions: []
                    }
                  ];
                }

                openHandler(local_tree);
              }}
            >
              {tree.find(i => i.Code === district.Code) &&
              tree.find(i => i.Code === district.Code).isOpened
                ? "-"
                : "+"}
            </span>

            <label>
              <input
                type="checkbox"
                checked={isChecked()}
                onChange={e => {
                  const VALUE = e.target.checked;
                  let inner_data = data_geo_tree.filter(
                    i => i !== district.Code
                  );
                  let src_regions = src_data.FederalSubjects;
                  let src_localities = [];

                  for (let i of src_regions) {
                    if (i) {
                      i.Localities.forEach(i => {
                        src_localities.push(i.AOGUID);
                      });
                    }
                  }

                  src_regions = src_regions.filter(i => i);

                  src_regions = src_regions.map(i => i.AOGUID);

                  if (VALUE) {
                    inner_data = [
                      ...inner_data,
                      district.Code,
                      ...src_regions,
                      ...src_localities
                    ];
                  } else {
                    for (let i of src_regions) {
                      inner_data = inner_data.filter(j => j !== i);
                    }
                    for (let i of src_localities) {
                      inner_data = inner_data.filter(j => j !== i);
                    }
                  }

                  let FederalDistricts = geo_data.FederalDistricts.filter(
                    i => i.Code !== district.Code
                  );

                  let object = {
                    Code: district.Code,
                    isFull: true,
                    FederalSubjects: [
                      ...data.Geo.FederalDistrict.FederalSubjects
                    ]
                  };

                  if (VALUE) {
                    FederalDistricts = [
                      ...FederalDistricts,
                      {
                        ...object
                      }
                    ];
                  }

                  handleChangeGeoData(inner_data, FederalDistricts);
                }}
              />{" "}
              {data.Geo.FederalDistrict.FederalDistrict}
            </label>

            {tree.find(i => i.Code === district.Code) &&
            tree.find(i => i.Code === district.Code).isOpened ? (
              <ul style={ulStyle}>
                {data.Geo.FederalDistrict.FederalSubjects.map((item, index) => {
                  if (item) {
                    return (
                      <Region
                        key={item.AOGUID}
                        district_code={district.Code}
                        item={item}
                        ulStyle={ulStyle}
                        data_geo_tree={data_geo_tree}
                        handleChange={handleChange}
                        openHandler={openHandler}
                        tree={tree}
                        district={data.Geo.FederalDistrict}
                        handleChangeCity={handleChangeCity}
                        indexKeyRegion={index}
                        indexKeyDistrict={indexKeyDistrict}
                        src_district={src_data}
                        src_subject={item}
                        geo_data={geo_data}
                        handleChangeGeoData={handleChangeGeoData}
                        handleChangeGeoDataCity={handleChangeGeoDataCity}
                        handleChangeGeoDataRegion={handleChangeGeoDataRegion}
                      />
                    );
                  } else {
                    return null;
                  }
                })}
              </ul>
            ) : null}
          </li>
        );
      }}
    </Query>
  );
};

export default District;
