import React from "react";
import StyledModal from "./modal/modal";
import ReactDOM from "react-dom";
import CloseIcon from "./styled/closeIcon";
import { ApolloConsumer } from "react-apollo";
import Feed from "./inventoryList";
import { useQuery } from "@apollo/react-hooks";
import { RN_INVENTORY_LIST } from "../RN/GraphQL/RNqueries";

interface IInventoryModalProps {
  readonly platformID: number | string;
  client: any;
  me: any;
  mPlanItem: any;
  setClose: () => void;
  [key: string]: any;
}

function InventoryModal(props: IInventoryModalProps) {
  const [div, setDiv] = React.useState<any>(undefined);
  const [variables, setVariables] = React.useState<any>({
    params: {
      count: 999,
      offset: 0,
      filter: [
        {
          field: "platformId",
          operator: "EQ",
          values: [props.platformID],
        },
        {
          field: "mpStatus",
          operator: "EQ",
          values: [0],
        },
      ],
    },
  });

  React.useEffect(() => {
    const modalDiv = document.getElementById("modal-inventory");
    setDiv(modalDiv);
  }, []);

  const { data, fetchMore } = useQuery(RN_INVENTORY_LIST, {
    client: props.client,
    variables,
    fetchPolicy: "no-cache",
  });

  const updateQuery = (variables: any) => {
    fetchMore({
      variables,
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        return fetchMoreResult;
      },
    });
  };

  if (div) {
    return ReactDOM.createPortal(
      <StyledModal
        style={{
          top: "20%",
          zIndex: 999,
          left: "50%",
          transform: "translateX(-50%)",
          width: "98vw",
          overflow: "inherit",
          padding: "1rem",
          height: "auto",
          fontSize: "0.8rem",
        }}
      >
        <div className="row border-bottom text-left pb-2">
          <div className="col-12 ml-1">
            <h5 className="d-inline-block">Выбор инвентаря</h5>
            <a
              className="btn btn-success btn-sm ml-5"
              target="_blank"
              rel="noopener noreferrer"
              href={`${process.env.REACT_APP_BASENAME}platform/update/${props.platformID}#tab=inventory`}
            >
              Добавить новый инвентарь
            </a>
            <input
              id="name"
              name="name"
              type="text"
              className="ml-5"
              placeholder="Найти инвентарь"
              onChange={(e) => {
                const name: string = e.target.value;
                const filter: any = [...variables.params.filter];
                const sortedFilter = filter.filter(
                  (f: any) => f.field !== "name"
                );

                const variablesInner = {
                  ...variables,
                  params: {
                    ...variables.params,
                    filter: [
                      ...sortedFilter,
                      {
                        field: "name",
                        operator: "LIKE",
                        values: [name],
                      },
                    ],
                  },
                };

                updateQuery(variablesInner);

                setVariables(variablesInner);
              }}
            />
            <CloseIcon
              onClick={() => {
                props.setClose();
              }}
            />
          </div>
        </div>
        <div
          style={{
            maxHeight: "600px",
            overflow: "auto",
          }}
        >
          <Feed
            list={
              data && data.RnInventoryList ? [...data.RnInventoryList.data] : []
            }
            updateList={(variables: any) => updateQuery(variables)}
            variables={variables}
            setVariables={(variables: any) => setVariables(variables)}
            platformID={props.platformID}
            platform={props.platform}
            me={props.me}
            mPlanItem={props.mPlanItem}
            addInventory={props.addInventory}
            setClose={props.setClose}
          />
        </div>
      </StyledModal>,
      div
    );
  }
  return null;
}

const MPItemInventoryModal = (props: any) => {
  return (
    <ApolloConsumer>
      {(client) => <InventoryModal {...props} client={client} />}
    </ApolloConsumer>
  );
};

export default MPItemInventoryModal;
