import geoView from "./geoView.tsx";

//todo если разобраться с именами в выборе, можно будет спилить эту функцию
const compare = (selectedGeo, referenceGeo) => {
  if (!referenceGeo) return null;

  if (!selectedGeo)
    if (selectedGeo.length === 8) {
      return "Россия";
    }

  selectedGeo.map((district) => {
    const refDistrict = referenceGeo.find(
      (refDistrict) => +refDistrict.Code === +district.Code
    );

    district.FederalDistrict = refDistrict.FederalDistrict;

    if (district.FederalSubjects) {
      district.FederalSubjects.forEach((subject) => {
        const refSubject = refDistrict.FederalSubjects.find((fs) => {
          if (fs) {
            return fs.AOGUID === subject.AOGUID;
          } else {
            return false;
          }
        });
        if (subject && subject.Localities && refSubject) {
          subject.Localities = subject.Localities.map((locality) => {
            return {
              ...refSubject.Localities.find(
                (l) => l.AOGUID === locality.AOGUID
              ),
            };
          });
        }
      });
    }

    return district;
  });

  return geoView(selectedGeo);
};

export default compare;
