import gql from "graphql-tag";
import React from "react";

//выгрузка данных через select
let items = [];
export const selectQuery = (
  inputValue,
  queryName,
  client,
  type = "general",
  vat = false,
  partner = false,
  seller = false
) => {
  let nameFull = "";

  if (type === "manager") nameFull = ":nameFull";

  let variables = {
    params: {
      offset: 0,
      count: 1000,
      filter: [
        {
          values: [inputValue],
          operator: "LIKE",
          field: "name",
        },
      ],
    },
  };

  if (partner) {
    variables = {
      params: {
        offset: 0,
        count: 1000,
        filter: [
          {
            values: [inputValue],
            operator: "LIKE",
            field: "name",
          },
          {
            values: ["FK_ref_Partner_ID"],
            operator: "EQ",
            field: "vat",
          },
        ],
      },
    };
  }

  if (seller) {
    variables = {
      params: {
        offset: 0,
        count: 1000,
        filter: [
          {
            values: [inputValue],
            operator: "LIKE",
            field: "name",
          },
          {
            values: ["1"],
            operator: "EQ",
            field: "IsSupplier",
          },
        ],
      },
    };
  }

  if (vat === 0 || vat === 1) {
    variables = {
      params: {
        offset: 0,
        count: 1000,
        filter: [
          {
            values: [inputValue],
            operator: "LIKE",
            field: "name",
          },
          {
            values: [vat],
            operator: "EQ",
            field: "vat",
          },
        ],
      },
    };
  }

  return new Promise((resolve, reject) => {
    client
      .query({
        query: gql`
                    query($params: ParamsInput) {
                        ${queryName}(params: $params) {
                            id
                            name 
                            ${nameFull}
                        }
                    }
                `,
        variables,
      })
      .then((result) => {
        items = [];
        for (let i of result.data[queryName]) {
          let obj = {};
          obj.value = i.id;
          obj.label = i.name;
          items.push(obj);
          resolve();
        }
      });
  }).then(() => items);
};

//справочники мл
items = [];
export const selectMlReference = (
  inputValue,
  queryName,
  client,
  id_field,
  field,
  field_second = "",
  field_third = "",
  field_fourth = "",
  field_fifth = "",
  field_sixth = "",
  partner = ""
) => {
  let variables = {
    params: {
      offset: 0,
      count: 1000,
      filter: [
        {
          values: [inputValue],
          operator: "LIKE",
          field: field,
        },
        {
          values: [partner],
          operator: "EQ",
          field: "FK_ref_Partner_ID",
        },
      ],
    },
  };

  return new Promise((resolve, reject) => {
    client
      .query({
        query: gql`
                query($params: ParamsInput) {
                        ML {
                            ${queryName}(params: $params) {
                            ${id_field}
                            ${field} 
                            ${field_second}
                            ${field_third}
                            ${field_fourth}
                            ${field_fifth}
                            ${field_sixth}
                        }
                    }
                }`,
        variables,
      })
      .then((result) => {
        items = [];

        for (let i of result.data.ML[queryName]) {
          let obj = {};
          obj.value = i[id_field];
          obj.label = i[field];
          obj.item = i;
          items.push(obj);
        }

        //items.items = result.data.Ml[queryName];

        resolve();
      });
  }).then(() => items);
};

//запросы к справочникам
let itemsReference = [];
export const selectReference = (client, queryName, field) => {
  let variables = {
    params: {
      offset: 0,
      count: 1000,
    },
  };

  return new Promise((resolve, reject) => {
    client
      .query({
        query: gql`
                    query ${queryName}($params: ParamsInput) {
                        ${queryName}(params: $params) {
                            id
                            ${field}
                        }
                    }
                `,
        variables,
      })
      .then((result) => {
        itemsReference = [];
        for (let i of result.data[queryName]) {
          let obj = {};
          obj.value = i.id;
          obj.label = i[field];
          itemsReference.push(obj);
          resolve();
        }
      });
  }).then(() => {
    return itemsReference;
  });
};

//запрос гео

let itemsGeo = [];
export const selectReferenceGeo = (inputValue, queryName, client) => {
  let variables = {
    params: {
      offset: 0,
      count: 1000,
      filter: [
        {
          values: [inputValue],
          operator: "LIKE",
          field: "name",
        },
      ],
    },
  };

  return new Promise((resolve, reject) => {
    client
      .query({
        query: gql`
                query geo($params: ParamsInput) {
                    ${queryName}(params: $params) {
                        AOGUID
                        FORMALNAME
                        SHORTNAME
                        Parent {
                            AOGUID
                            FORMALNAME
                            SHORTNAME
                            Parent {
                                AOGUID
                                FORMALNAME
                                SHORTNAME
                            }
                        }
                    }
                }
            `,
        variables,
      })
      .then((result) => {
        itemsGeo = [];
        for (let i of result.data[queryName]) {
          let obj = {};
          obj.value = i.AOGUID;
          obj.label = (
            <>
              {i.FORMALNAME} {i.SHORTNAME}
              <br />
              <span style={{ color: "#B0B0B0" }}>
                {i.Parent && i.Parent.Parent ? (
                  <>
                    {i.Parent.Parent.FORMALNAME} {i.Parent.Parent.SHORTNAME},
                  </>
                ) : null}{" "}
                {i.Parent ? (
                  <>
                    {i.Parent.FORMALNAME} {i.Parent.SHORTNAME}
                  </>
                ) : null}{" "}
              </span>{" "}
            </>
          );
          itemsGeo.push(obj);
          resolve();
        }
      });
  }).then(() => itemsGeo);
};

export const ME = gql`
  query {
    me {
      id
      comon_id
      email
    }
  }
`;

export const RN_INVENTORY = gql`
  query RnInventory($id: Int!) {
    me {
      id
      comon_id
      email
    }
    RnInventory(id: $id) {
      id
      pid
      name
      platformId
      format
      type
      startDate
      endDate
      pageTitle
      pageUrl
      purchase
      rotation
      calculatingMethod
      priceCondition
      priceType
      price_value
      price_value_week
      price_value_month
      value
      currency
      vatRate
      vat
      description
      articleTitle
      articleBody
      position
      display_desktop
      display_mobile
      placement_value
      comment
      statistics
      priceType
      price_value
      priceFrom
      priceTo
      showing
      min_impressions
      clicks
      view
      ctr
      placement_type
      showing_percent
      publication_format
      specifications
      coefficient_off
      files {
        id
        srcFileName
        comment
      }
      Targeting {
        id
        name
        coefficient
      }
      Pages {
        id
        name
      }
      image {
        id
        path
        name
      }
      screenshot {
        id
        path
        name
      }
      video {
        id
        path
        name
      }
      video_short {
        id
        path
        name
      }
    }
  }
`;

export const RN_PLATFORM = gql`
  query RnPlatform($id: Int!) {
    me {
      id
      comon_id
      email
      login
    }
    RnPlatform(id: $id) {
      authorId
      id
      name
      draft
      updateDate
      url
      www
      statisticsUrl
      description
      totalAudience
      language
      certificate
      adequate
      adequate_comment
      payment_delay
      payment_delay_value
      federal
      direct_client_rule
      hidden
      checked
      check_user
      check_date
      object_uid
      seller
      content_comment
      isForeign
      targetAudienceLink
      isSubBuyer
      targeting {
        id
        name
        coefficient
        inventoryType
      }
      CheckUser {
        id
        login
        email
      }
      User {
        id
        login
      }
      Price {
        id
        platformId
        startDate
        endDate
        updateDate
        platformId
        vat
        vatRate
        measure
        priceVat
        comment
        authorId
        calculatingMethod
        price_url
        comment_2
        buyer_note
        technical_requirements_publication
      }
      priceFile {
        id
        name
        path
      }
      buyer_note_file {
        id
        name
        path
      }
      technical_requirements_publication_file {
        id
        name
        path
      }
      technical_requirements_banner_file {
        id
        name
        path
      }
      MediaType {
        id
        name
      }
      MediaGroup {
        id
        MediaGroup
      }
      City {
        AOGUID
        FORMALNAME
      }
      CityList {
        AOGUID
        FORMALNAME
        SHORTNAME
        Parent {
          AOGUID
          SHORTNAME
          FORMALNAME
        }
      }
      RegionList {
        AOGUID
        FORMALNAME
        SHORTNAME
      }
      Region {
        FIAS_ID
        FORMALNAME
        SHORTNAME
      }
      NetworkPartner {
        id
        name
      }
      Seller {
        id
        name
      }
      Thematics {
        id
        name
      }
      Coverage {
        id
        name
      }

      logo {
        id
        name
        path
      }
      files {
        id
        name
        path
      }
      thematics {
        id
        name
      }
      SupercomItems {
        id
        User {
          id
          login
        }
        platformId
        accrualBasis
        turnoverTo
        turnoverFrom
        authorId
        supercomAmount
        startDate
        updateDate
      }
      CoefficientItems {
        id
        platformId
        startDate
        endDate
        updateDate
        coefficient
        month_day
        month
      }
      MarkUpItems {
        id
        platformId
        markupType
        startDate
        endDate
        updateDate
        apply
        markup
      }
      DiscountItems {
        User {
          id
          login
        }
        user {
          id
          login
        }
        id
        name
        subject
        startDate
        inventory_type
        startDate
        endDate
        subject
        accrualBasis
        discountTo
        discountFrom
        discountAmount
        individual
        Inventory {
          id
          name
        }
        updateDate
        authorId
      }
      ContactsItems {
        id
        fullName
        platformId
        phone
        email
        position
        responsible
        processType
        partner_id
        legal_entity_id
        ml_id
        Comments
        PersonContacts {
          Contact_ID
          Contact
          FK_v_ref_ContactType_ID
        }
        LegalEntity {
          UrFace_ID
          UF_Name
        }
        Partner {
          id
          name
        }
        name
        last_name
        middle_name
      }
      InventoryItems {
        id
        pid
        name
        rating
        platformId
        format
        type
        startDate
        endDate
        pageTitle
        pageUrl
        purchase
        rotation
        calculatingMethod
        priceCondition
        priceType
        value
        currency
        vatRate
        vat
        description
        articleTitle
        articleBody
        position
        display_desktop
        display_mobile
        placement_value
        comment
        statistics
        price_value
        priceType
        priceFrom
        priceTo
        price_value_week
        price_value_month
        showing
        view
        ctr
        placement_type
        showing_percent
        specifications
        publication_format
        priceType
        coefficient_off
        min_impressions
        global_targeting
        updateDate
        rating
        User {
          id
          email
        }
        Targeting {
          id
          name
          coefficient
        }
        GlobalTargeting {
          id
          name
          coefficient
        }
        Pages {
          id
          name
        }
        image {
          id
          path
          name
        }
        screenshot {
          id
          path
          name
        }
        video {
          id
          path
          name
        }
        video_short {
          id
          path
          name
        }
      }
    }
  }
`;

export const RN_PLATFORM_ITEM = gql`
  query RnPlatformItem($id: Int!) {
    RnPlatformItem(id: $id) {
      id
      name
      url
      draft
      checked
      check_date
      adequate
      adequate_comment
      price_is_needed
      Price {
        id
        vat
        priceVat
        comment
        buyer_note
      }
      ContactsItems {
        id
      }
      InventoryItems {
        id
      }
      CityList {
        AOGUID
        FORMALNAME
        SHORTNAME
        Parent {
          AOGUID
          FORMALNAME
          SHORTNAME
        }
      }
      RegionList {
        AOGUID
        FORMALNAME
        SHORTNAME
      }
    }
  }
`;

export const RN_PLATFORM_LIST = gql`
  query RnPlatformList($params: ParamsInput) {
    me {
      id
      comon_id
      email
    }
    RnPlatformList(params: $params) {
      pageInfo
      data {
        id
      }
    }
  }
`;

export const RN_PLATFORM_LIST_REPORT = gql`
  query RnPlatformList($params: ParamsInput) {
    me {
      id
      comon_id
      email
    }
    RnPlatformList(params: $params) {
      pageInfo
      data {
        id
        name
        url
        Price {
          id
          comment_2
        }
        ContactsItems {
          id
        }
        InventoryItems {
          id
        }
      }
    }
  }
`;

export const RN_MP = gql`
  query RnMp($id: Int!) {
    me {
      id
      comon_id
      email
    }
    RnMp(id: $id) {
      id
      status
      author_id
      legal_entity_vat
      legal_entity_novat
      comment
      bayer_comment
      date_relevance
      refusal
      legal_entity_client
      briefing_in
      order_id
      order_id_2
      traffic_id
      tender
      Traffic {
        id
        nameFull
      }
      UrFace_nds {
        id
        name
      }
      UrFace_nonds {
        id
        name
      }
      LegalEntityClient {
        UrFace_ID
        UF_Name
      }
      Contract {
        Document_ID
        DocDate
        DocSubject
        DocNumber
      }
      Briefing {
        id
        name
        manager_id
        responsible_id
        partner_id
        brand_id
        taskId
        vat
        no_vat
        tender
        tender_deadline
        target_audience
        banner
        news
        recommends
        experience_comment
        legal_entity_vat
        legal_entity_novat
        contract_type
        agent_commission_position
        agent_commission_value
        agent_commission_value_type
        federal
        Cities {
          AOGUID
          FORMALNAME
        }
        FederalDistricts {
          Code
          FederalSubjects {
            AOGUID
            Localities {
              AOGUID
            }
          }
        }
        Responsible {
          id
          nameFull
        }
        Manager {
          id
          nameFull
        }
        Partner {
          id
          name
          nameFull
        }
        Brand {
          id
          name
        }
        UrFace_nds {
          id
          name
        }
        UrFace_nonds {
          id
          name
        }
        products
        advantages
        website
        competitors
        sex
        ageRanges
        audienceComment
        dateStart
        dateEnd
        advTask
        advTaskOther
        kpiType
        kpiComment
        keyMessage
        totalBudget
        totalBudgetFrom
        totalBudgetTo
        totalBudgetAcIn
        entryDate
        deadline
        dateEnd
        clicks
        kpiComment
        keyMessage
        websiteChangeable
        promotionalMaterial
        totalBudget
        totalBudgetAcIn
        agentCommissionPosition
        agentCommission
        payment_delay
        payment_delay_value
        cities
      }
      MpItems {
        id
        inventory_id
        price_type
        purchase_count
        discount
        clicks
        showing
        status
        startDate
        endDate
        is_change
        is_change_comment
        payment_delay_value
        payment_delay
        view
        ctr
        coefficient_off
        discount_purchase
        Info {
          id
          unit_price_cost
          price_type
          purchase_gross
          sell_gross
          purchase_net
          sell_net
          margin
          profitability
          total_cost_no_vat
          total_cost_vat_discount
          total_cost_no_vat_discount
          total_cost_purchase_discount
          total_cost_purchase_discount_vat
          unit_cost_no_vat
          agent_commission
          ac_vat_rate_piece
          discount
          view_cost
          cpc

          min_purchase_count
        }
        Platform {
          id
          name
          payment_delay
          payment_delay_value
          CoefficientItems {
            id
            month
            coefficient
          }
          ContactsItems {
            id
            fullName
            platformId
            phone
            email
            position
            responsible
            processType
          }
          City {
            AOGUID
            FORMALNAME
          }
          DiscountItems {
            id
            discountTo
            discountFrom
            accrualBasis
            discountAmount
          }
          Price {
            id
            vat
            priceVat
            comment
            comment_2
          }
        }
        InventoryItem {
          id
          name
          id
          pid
          name
          platformId
          format
          type
          startDate
          endDate
          priceCondition
          value
          currency
          vatRate
          vat
          description
          articleTitle
          articleBody
          position
          display_desktop
          display_mobile
          placement_value
          comment
          statistics
          priceType
          price_value
          priceFrom
          priceTo
          price_value_week
          price_value_month
          showing
          min_impressions
          clicks
          view
          placement_type
          showing_percent
          specifications
          publication_format
          coefficient_off
          Targeting {
            id
            name
            coefficient
          }
          Pages {
            id
            name
          }
        }
      }
    }
  }
`;

export const RN_MP_ITEM_LIST = gql`
  query RnMpItemList($params: ParamsInput) {
    RnMpItemList(params: $params) {
      id
      inventory_id
      price_type
      purchase_count
      discount
      clicks
      showing
      status
      startDate
      endDate
      is_change
      is_change_comment
      payment_delay_value
      payment_delay
      view
      ctr
      coefficient_off
      Platform {
        id
        isForeign
      }
      InventoryItem {
        id
        type
      }
      Info {
        id
        unit_price_cost
        price_type
        purchase_gross
        sell_gross
        purchase_net
        sell_net
        margin
        profitability
        total_cost_no_vat
        total_cost_vat_discount
        total_cost_no_vat_discount
        total_cost_purchase_discount
        total_cost_purchase_discount_vat
        unit_cost_no_vat
        agent_commission
        ac_vat_rate_piece
        discount
        view_cost
        cpc
        discount_purchase
        min_purchase_count
      }
    }
  }
`;

export const RN_MP_items_off = gql`
  query RnMp($id: Int!) {
    me {
      id
      comon_id
      email
    }
    RnMp(id: $id) {
      id
      deletedItemCount
      disabled
      status
      tender
      author_id
      legal_entity_vat
      legal_entity_novat
      comment
      bayer_comment
      date_relevance
      refusal
      legal_entity_client
      briefing_in
      order_id
      order_id_2
      traffic_id
      Traffic {
        id
        nameFull
      }
      UrFace_nds {
        id
        name
      }
      UrFace_nonds {
        id
        name
      }
      LegalEntityClient {
        UrFace_ID
        UF_Name
      }
      Contract {
        Document_ID
        DocDate
        DocSubject
        DocNumber
      }
      Briefing {
        id
        # соисполнители
        users
        name
        manager_id
        responsible_id
        partner_id
        brand_id
        taskId
        vat
        no_vat
        tender
        tender_deadline
        target_audience
        banner
        news
        recommends
        experience_comment
        legal_entity_vat
        legal_entity_novat
        contract_type
        agent_commission_position
        agent_commission_value
        agent_commission_value_type
        federal
        FederalDistricts {
          Code
          FederalSubjects {
            AOGUID
            Localities {
              AOGUID
            }
          }
        }
        Cities {
          AOGUID
          FORMALNAME
        }
        Responsible {
          id
          nameFull
        }
        Manager {
          id
          nameFull
        }
        Partner {
          id
          name
          nameFull
        }
        Brand {
          id
          name
        }
        UrFace_nds {
          id
          name
        }
        UrFace_nonds {
          id
          name
        }
        products
        advantages
        website
        competitors
        sex
        ageRanges
        audienceComment
        dateStart
        dateEnd
        advTask
        advTaskOther
        kpiType
        kpiComment
        keyMessage
        totalBudget
        totalBudgetFrom
        totalBudgetTo
        totalBudgetAcIn
        entryDate
        deadline
        dateEnd
        clicks
        kpiComment
        keyMessage
        websiteChangeable
        promotionalMaterial
        totalBudget
        totalBudgetAcIn
        agentCommissionPosition
        agentCommission
        payment_delay
        payment_delay_value
        cities
      }
      MpItems {
        id
        InventoryItem {
          id
          type
        }
      }
    }
  }
`;

export const RN_INVENTORY_LIST = gql`
  query RnInventoryList($params: ParamsInput) {
    me {
      id
      comon_id
      email
    }
    RnInventoryList(params: $params) {
      pageInfo
      data {
        id
        pid
        name
        platformId
        format
        type
        startDate
        endDate
        pageTitle
        pageUrl
        purchase
        rotation
        calculatingMethod
        priceCondition
        priceType
        value
        currency
        vatRate
        vat
        description
        articleTitle
        articleBody
        position
        display_desktop
        display_mobile
        placement_value
        comment
        statistics
        price_value
        priceType
        priceFrom
        priceTo
        price_value_week
        price_value_month
        showing
        view
        ctr
        placement_type
        showing_percent
        specifications
        publication_format
        priceType
        coefficient_off
        min_impressions
        global_targeting
        rating
        Targeting {
          id
          name
          coefficient
        }
        Pages {
          id
          name
        }
        image {
          id
          path
          name
        }
        screenshot {
          id
          path
          name
        }
        video {
          id
          path
          name
        }
        video_short {
          id
          path
          name
        }
      }
    }
  }
`;

export const RN_INVENTORY_LIST_SELECT = gql`
  query($params: ParamsInput) {
    RnInventoryList(params: $params) {
      pageInfo
      data {
        id
        name
      }
    }
  }
`;

export const RN_MP_ITEM = gql`
  fragment report on RnMpItem {
    rCPV
    rCTR
    rClicks
    rConversion
    rCount
    rImpressions
    rLeadImpressions
    rPlan
    rRejects
    rSpend
    rTime
    rTotalCost
    rViewDepth
    rViews
    rVisits
  }

  fragment platformInventory on RnMpItem {
    inventoryName
  }

  fragment orderInfo on RnMpItem {
    orderID
    orderDate
    inOrder
  }

  query RnMpItem($id: Int!) {
    RnMpItem(id: $id) {
      id
      ...orderInfo
      geo
      inventory_id
      price_type
      purchase_count
      discount
      clicks
      showing
      status
      startDate
      endDate
      is_change
      is_change_comment
      payment_delay_value
      payment_delay
      view
      ctr
      coefficient_off
      format
      price_query_date
      vat
      targeting_id
      city
      unit_cost
      platform_id

      margin
      unitCostNoVat
      totalCostNoVat
      totalCostNoVatDiscount
      totalCostVatDiscount
      minPurchaseCount
      totalCostPurchaseDiscount
      profitability
      totalCostPurchaseDiscountVat
      purchaseNet
      purchaseGross
      sellNet
      sellGross
      ac
      acVatValue
      cpc
      viewCost
      discount_purchase
      discountComment
      ...report
      ...platformInventory
      targetingList {
        id
        name
        coefficient
      }
      Platform {
        id
        name
        payment_delay
        payment_delay_value
        federal
        isForeign
        Seller {
          id
          name
        }
        ContactsItems {
          id
          email
        }
        targeting {
          id
          name
          coefficient
        }
        CoefficientItems {
          id
          month
          coefficient
        }
        ContactsItems {
          id
          name
          platformId
          phone
          email
          position
          responsible
          processType
        }
        CityList {
          AOGUID
          FORMALNAME
          SHORTNAME
          Parent {
            AOGUID
            FORMALNAME
            SHORTNAME
          }
        }
        DiscountItems {
          id
          discountTo
          discountFrom
          accrualBasis
          discountAmount
        }
        Price {
          id
          vat
          priceVat
          comment
          comment_2
          technical_requirements_publication
        }
      }
      InventoryItem {
        id
        name
        id
        pid
        name
        platformId
        format
        type
        startDate
        endDate
        priceCondition
        value
        currency
        vatRate
        vat
        description
        articleTitle
        articleBody
        position
        display_desktop
        display_mobile
        placement_value
        comment
        statistics
        priceType
        price_value
        priceFrom
        priceTo
        price_value_week
        price_value_month
        showing
        clicks
        view
        placement_type
        showing_percent
        specifications
        publication_format
        coefficient_off
        vat
        min_impressions
        rating
        files {
          id
        }
        requirements
        requirementsFiles {
          id
        }
        Targeting {
          id
          name
          coefficient
        }
        GlobalTargeting {
          id
          name
          coefficient
        }
        Pages {
          id
          name
        }
        image {
          id
        }
      }
    }
  }
`;

export const RN_MP_lIST_PAGER = gql`
  query RnMpListPager($params: ParamsInput) {
    me {
      id
      comon_id
      email
    }
    RnMpListPager(params: $params) {
      pageInfo
      data {
        id
        entryDate
        status
        order_id
        order_id_2
        is_copy
        tender
        disabled
        MpItems {
          id
          margin
          total_cost_vat_discount
          Info {
            id
            margin
            total_cost_vat_discount
          }
        }
        Briefing {
          id
          name
          vat
          no_vat
          status
          contract_type
          partner_id
          products
          dateStart
          dateEnd
          Manager {
            id
            nameFull
          }
          Responsible {
            id
            nameFull
          }
          Partner {
            id
            name
          }
          Brand {
            id
            name
          }
        }
      }
    }
  }
`;

export const RN_GROUP_lIST_PAGER = gql`
  query RnGroupListPager($params: ParamsInput) {
    GroupListPager(params: $params) {
      pageInfo
      data {
        id
        name
        access
      }
    }
  }
`;

export const RN_GROUP = gql`
  query RnGroup($id: Int!) {
    Group(id: $id) {
      id
      name
      access
      users {
        id
        nameFull
      }
    }
  }
`;

export const COMON_USER_AVAILABLE = gql`
  query ComonUserAvailable($id: Int!) {
    ComonUserAvailable(id: $id) {
      id
      nameFull
    }
  }
`;

export const COMON_GROUP_LIST = gql`
  query ComonGroupList($params: paramsInput) {
    ComonGroupList(params: $params) {
      id
      name
    }
  }
`;

export const UR_FACE_LIST = gql`
  query UrFaceList($params: ParamsInput) {
    ML {
      UrFaceList(params: $params) {
        UrFace_ID
        UF_Name
      }
    }
  }
`;

export const MP_ITEM_REPORT_LIST_PAGER = gql`
  query MpItemReportListPager($params: ParamsInput) {
    RN {
      MpItemReportListPager(params: $params) {
        pageInfo
        data {
          id
          entryDate
          mp_id
          startDate
          endDate
          purchase_count
          discount
          total_cost_vat_discount
          platform_vat
          vat
          no_vat
          ctr
          clicks
          showing
          cpm
          cpc
          discount_purchase
          total_cost_purchase_discount
          total_cost_purchase_discount_vat
          profitability
          margin
          payment_delay
          payment_delay_value
          payment_delay_basic
          best_price
          mp_entry_date
          mp_finished_date
          price_type
          Platform {
            id
            name
            adequate
            adequate_comment
            payment_delay
            payment_delay_value
            City {
              FIAS_ID
              FORMALNAME
            }
          }
          Partner {
            id
            name
          }
          Brand {
            id
            name
          }
          Buyer {
            id
            nameFull
          }
          Author {
            id
            nameFull
          }
          Inventory {
            id
            name
            priceType
            type
          }
        }
      }
    }
  }
`;

export const MP_ITEM_REPORT_LIST = gql`
  query MpItemReportList($params: ParamsInput) {
    RN {
      MpItemReportList(params: $params) {
        id
        entryDate
        mp_id
        startDate
        endDate
        unit_cost_no_vat
        purchase_count
        discount
        total_cost_vat_discount
        platform_vat
        vat
        no_vat
        ctr
        clicks
        showing
        cpm
        cpc
        discount_purchase
        total_cost_purchase_discount
        total_cost_purchase_discount_vat
        profitability
        margin
        payment_delay_value
        Author {
          id
          nameFull
        }
        Inventory {
          id
          name
        }
      }
    }
  }
`;
