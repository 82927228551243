import React from "react";
import MediaPlanItemsList from "./MediaPlanItemsList";
import Header from "./../../../template/Header";
import Select from "react-select";
import AsyncSelect from "react-select/lib/Async";
import { ApolloConsumer } from "react-apollo";
import NumberFormat from "react-number-format";
import Briefing from "./Mediaplan/MediaplanBriefingContainer";
import icon from "./error_icon.png";
import loading from "./loading.gif";
import DateModal from "./../DatePickerModal/DatePickerModal";
import Report from "./../Reports/HistoryReport";
import { Link } from "react-router-dom";
import Modal from "./../Platform/PlatformListModal/Modal";
//apollo
import { graphql } from "react-apollo";
import { Query } from "react-apollo";
import { Mutation } from "react-apollo";
import gql from "graphql-tag";

//Запросы api
import {
  RN_MP_UPDATE_FIELD,
  RN_MP_UPDATE_FIELD_STRING,
} from "../../GraphQL/RNmutations";
import { RN_MP_items_off, selectQuery } from "../../GraphQL/RNqueries";
import { getMpName } from "../../../functions/getMpName";

import "../../../components/mediaPlan/mediaPlan.css";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { UndoItem } from "../../../components/mediaPlan/undo-item";
const download = require("downloadjs");
const moment = require("moment/min/moment-with-locales.js");

class MediaPlan extends React.Component {
  /* global $*/

  state = {
    data: {
      bayer_comment: false,
    },
    show: false,
    isPending: false,
    // уникальное ТТ
    inventoryReqShow: false,
  };

  textInput = React.createRef();

  static getDerivedStateFromProps(props, oldState) {
    let updateForState = {
      ...oldState,
    };

    updateForState = {
      data: {
        ...props.mp,
      },
      MpItems: props.mp.MpItems,
    };

    return updateForState;
  }

  jqueryCreate() {
    let _this = this;
    $("input#daterangselect")
      .daterangepicker({
        startDate:
          _this.state.data.date_relevance && _this.state.data.status === 4
            ? moment(_this.state.data.date_relevance).format("DD.MM.YYYY")
            : moment().add(3, "days"),
        singleDatePicker: true,
        opens: "right",
        locale: {
          applyLabel: "Применить",
          cancelLabel: "Отменить",
        },
      })
      .ready(() => {
        let date = _this.textInput.current.value;

        if (!_this.state.data.date_relevance) {
          _this.props.updateFieldString({
            variables: {
              id: _this.props.match.params.id,
              field: "date_relevance",
              value: moment(date, "DD.MM.YYYY").format("YYYY-MM-DD"),
            },
            refetchQueries: ["RnMp", "RnBriefingList"],
          });
        }
      })
      .on("apply.daterangepicker", function (e, picker) {
        _this.props.updateFieldString({
          variables: {
            id: _this.props.match.params.id,
            field: "date_relevance",
            value: moment(picker.startDate).format("YYYY-MM-DD"),
          },
          refetchQueries: ["RnMp", "RnBriefingList"],
        });
      });
  }

  componentDidMount() {
    this.jqueryCreate();
  }

  render() {
    let renderAccessBtn = false;

    if (this.state.data.UrFace_nds || this.state.data.UrFace_nonds) {
      renderAccessBtn = true;
    } else if (
      this.state.data.Briefing &&
      this.state.data.Briefing.legal_entity_vat
    ) {
      renderAccessBtn = true;
    } else if (
      this.state.data.Briefing &&
      this.state.data.Briefing.legal_entity_novat
    ) {
      renderAccessBtn = true;
    }

    let agent_commission_sum = null;

    if (
      this.props.mp.Briefing &&
      this.props.mp.Briefing.contract_type === 3 &&
      this.props.mp.MpItems.lenght > 0
    ) {
      for (let i of this.props.mp.MpItems) {
        agent_commission_sum += i.agent_commission;
      }
      if (
        this.props.mp.MpItems.every(
          (item) =>
            item.Platform &&
            item.Platform.Price &&
            item.Platform.Price.vat === 1
        )
      )
        agent_commission_sum = null;
    }

    let traffic = {};

    if (this.state.data.Traffic) {
      traffic = {
        label: this.state.data.Traffic.nameFull,
        value: this.state.data.Traffic.id,
      };
    } else if (
      this.state.data.Briefing &&
      this.state.data.Briefing.Responsible
    ) {
      traffic = {
        label: this.state.data.Briefing.Responsible.name,
        value: this.state.data.Briefing.Responsible.id,
      };
    } else {
      traffic = {};
    }

    let legal_entity_vat = true;
    let legal_entity_no_vat = true;

    if (
      this.state.data.Briefing &&
      this.state.data.Briefing.vat &&
      this.state.data.Briefing.no_vat &&
      this.state.data.Briefing.contract_type === 3
    ) {
      legal_entity_no_vat = false;
    }

    if (
      this.state.data.Briefing &&
      !this.state.data.Briefing.vat &&
      this.state.data.Briefing.no_vat &&
      this.state.data.Briefing.contract_type === 3
    ) {
      legal_entity_vat = false;
    }

    if (
      this.state.data.Briefing &&
      !this.state.data.Briefing.vat &&
      this.state.data.Briefing.no_vat &&
      this.state.data.Briefing.contract_type === 1
    ) {
      legal_entity_vat = false;
    }

    if (
      this.state.data.Briefing &&
      this.state.data.Briefing.vat &&
      !this.state.data.Briefing.no_vat &&
      this.state.data.Briefing.contract_type === 1
    ) {
      legal_entity_no_vat = false;
    }

    if (
      this.state.data.Briefing &&
      this.state.data.Briefing.contract_type === 2
    ) {
      legal_entity_no_vat = false;
    }

    //блокировка редактирования
    const disabled = this.state.data.disabled;

    return (
      <>
        <Header {...this.props} {...this.state} />
        <section id="content" className="content">
          <div className="container-fluid mediaplans-list-block mt-2">
            <div
              className="table-container"
              style={{
                overflowY: "hidden",
              }}
            >
              <div className="row">
                <div className="col">
                  <h4>Создание медиаплана</h4>
                </div>
                <div
                  className="col-4"
                  style={{
                    zIndex: 999,
                  }}
                >
                  {this.state.data.briefing_in !== 1 ? (
                    <Query
                      query={gql`
                        query RnBriefingList($params: ParamsInput) {
                          RnBriefingList(params: $params) {
                            id
                            name
                            Manager {
                              id
                              nameFull
                            }
                            Partner {
                              id
                              name
                            }
                            Brand {
                              id
                              name
                            }
                            products
                            entryDate
                          }
                        }
                      `}
                      variables={{
                        params: {
                          offset: 0,
                          count: 50,
                        },
                      }}
                    >
                      {({ loading, error, data }) => {
                        if (loading) return "Загрузка...";
                        if (error) return "Ошибка";

                        const RnBriefingList = data.RnBriefingList.map(
                          (item) => ({
                            label: `${item.Partner ? item.Partner.name : ""} ${
                              item.Brand ? item.Brand.name : ""
                            } ${
                              item.dateStart && item.dateEnd
                                ? `${new Date(
                                    item.dateStart
                                  ).toLocaleDateString()} - ${new Date(
                                    item.dateEnd
                                  ).toLocaleDateString()}`
                                : ""
                            } ${item.products ? item.products : ""}`,
                            value: item.id,
                          })
                        );

                        return (
                          <div className="row">
                            <strong className="col-form-label col-2">
                              Брифинг
                            </strong>
                            <Select
                              isDisabled={this.state.data.Briefing || disabled}
                              name="pages"
                              className="col-6"
                              value={
                                this.state.data.Briefing
                                  ? {
                                      label: `${
                                        this.state.data.Briefing.Partner
                                          ? this.state.data.Briefing.Partner
                                              .name
                                          : ""
                                      } ${
                                        this.state.data.Briefing.Brand
                                          ? this.state.data.Briefing.Brand.name
                                          : ""
                                      } ${
                                        this.state.data.Briefing.dateStart &&
                                        this.state.data.Briefing.dateEnd
                                          ? `${new Date(
                                              this.state.data.Briefing.dateStart
                                            ).toLocaleDateString()} - ${new Date(
                                              this.state.data.Briefing.dateEnd
                                            ).toLocaleDateString()}`
                                          : ""
                                      } ${
                                        this.state.data.Briefing.products
                                          ? this.state.data.Briefing.products
                                          : ""
                                      }`,
                                      value: this.state.data.Briefing.id,
                                    }
                                  : this.state.briefing
                                  ? this.state.briefing
                                  : null
                              }
                              placeholder={""}
                              options={RnBriefingList}
                              onChange={(briefing) => {
                                const briefing_id = briefing.value;
                                this.setState({
                                  ...this.state,
                                  briefing_id,
                                  briefing,
                                });
                              }}
                            />
                            <div className="col-2">
                              <button
                                disabled={this.state.data.Briefing}
                                className="btn btn-primary"
                                onClick={() => {
                                  this.props
                                    .updateField({
                                      variables: {
                                        id: this.props.match.params.id,
                                        field: "briefing_id",
                                        value: this.state.briefing_id,
                                      },
                                    })
                                    .then(() => {
                                      this.props.history.go(0);
                                    });
                                }}
                              >
                                Сохранить
                              </button>
                            </div>

                            {!this.state.data.Briefing ? (
                              <div className="row col-12">
                                <div className="bg-danger text-white col-10 p-2 mt-2 ">
                                  <img src={icon} alt="" />
                                  <span className="ml-3">
                                    Внимание: не выбран бриф
                                  </span>
                                </div>
                              </div>
                            ) : null}

                            {this.state.data.Briefing ? (
                              <div className="row col-12">
                                <div className="col">
                                  <Link
                                    to={`/briefing/update/${this.state.data.Briefing.id}`}
                                  >
                                    Ссылка на брифинг
                                  </Link>
                                </div>
                              </div>
                            ) : null}
                          </div>
                        );
                      }}
                    </Query>
                  ) : null}
                </div>
                <div className="col-6 row">
                  <div className="col-3">
                    <FormControlLabel
                      color="secondary"
                      disabled={disabled}
                      control={
                        <Checkbox
                          size="small"
                          checked={this.state.data.tender}
                          onChange={(e) => {
                            const value = e.target.checked ? 1 : 0;
                            this.props.updateField({
                              variables: {
                                id: this.props.match.params.id,
                                field: "tender",
                                value,
                              },
                              refetchQueries: ["RnMp"],
                            });
                          }}
                        />
                      }
                      label="Тендерный"
                    />
                  </div>
                  <div className="col-3">
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={this.state.show}
                          onChange={() => {
                            this.setState((state) => ({
                              ...state.data,
                              show: !state.show,
                            }));
                          }}
                          size="small"
                        />
                      }
                      label="Показать АВ"
                    />
                  </div>
                  <div className="col-3">
                    <FormControlLabel
                      disabled={disabled}
                      control={
                        <Checkbox
                          checked={this.state.history_show}
                          onChange={() => {
                            this.setState((state) => ({
                              ...state.data,
                              history_show: !this.state.history_show,
                            }));
                          }}
                          size="small"
                        />
                      }
                      label="Показать историю"
                    />
                  </div>
                  <div className="col-3">
                    <FormControlLabel
                      disabled={disabled}
                      control={
                        <Checkbox
                          checked={this.state.scoringShow}
                          onChange={() => {
                            this.setState((state) => ({
                              ...state.data,
                              //TODO убрать эту переменную в глобальный стейт
                              scoringShow: !this.state.scoringShow,
                            }));
                          }}
                          size="small"
                        />
                      }
                      label="Показать скорринг"
                    />
                  </div>
                  <div className="col-3">
                    <FormControlLabel
                      disabled={disabled}
                      control={
                        <Checkbox
                          checked={this.state.historyShow}
                          onChange={() => {
                            this.setState((state) => ({
                              ...state.data,
                              historyShow: !this.state.historyShow,
                            }));
                          }}
                          size="small"
                        />
                      }
                      label="Показать историю закупа"
                    />
                  </div>
                  <div className="col-3">
                    <FormControlLabel
                      disabled={disabled}
                      control={
                        <Checkbox
                          checked={this.state.orderExport}
                          onChange={() => {
                            this.setState((state) => ({
                              ...state.data,
                              orderExport: !this.state.orderExport,
                            }));
                          }}
                          size="small"
                        />
                      }
                      label="Выгрузка в ACC"
                    />
                  </div>
                  <div className="col-3">
                    <FormControlLabel
                      disabled={disabled}
                      control={
                        <Checkbox
                          checked={this.state.inventoryReqShow}
                          onChange={() => {
                            this.setState((state) => ({
                              ...state.data,
                              inventoryReqShow: !this.state.inventoryReqShow,
                            }));
                          }}
                          size="small"
                        />
                      }
                      label="Уникальное ТТ"
                    />
                  </div>
                </div>
              </div>
              <div className="card" id="mplan-body">
                <div
                  className="card-body row "
                  style={{
                    minHeight: "350px",
                    paddingBottom: "6rem",
                  }}
                >
                  {this.state.history_show ? (
                    <div className="col-12">
                      <Report
                        filter={[
                          {
                            field: "mp_id",
                            operator: "EQ",
                            values: [this.props.mp.id],
                          },
                        ]}
                      />
                    </div>
                  ) : (
                    <div id="mplan-items" className="col-12">
                      <MediaPlanItemsList
                        briefingUpdated={this.state.briefingUpdated}
                        parentProps={this.props}
                        parentState={this.state}
                        disabled={disabled}
                        setParentState={(newState) => {
                          console.log(newState);
                          this.setState((state) => ({
                            ...state.data,
                            ...newState,
                          }));
                        }}
                      />
                      <Mutation
                        mutation={gql`
                          mutation RnMpItemCreate($mpId: Int!) {
                            RnMpItemCreate(mpId: $mpId) {
                              id
                            }
                          }
                        `}
                      >
                        {(newMpItem) => (
                          <button
                            className="btn btn-success mt-3 ml-5"
                            disabled={disabled}
                            onClick={() => {
                              newMpItem({
                                variables: {
                                  mpId: this.props.mp.id,
                                },
                                refetchQueries: ["RnMp"],
                              });
                            }}
                          >
                            Добавить строчку
                          </button>
                        )}
                      </Mutation>

                      {
                        <ApolloConsumer>
                          {(client) => (
                            <UndoItem
                              mpID={this.props.mp.id}
                              disabled={
                                disabled || !this.props.mp.deletedItemCount
                              }
                              client={client}
                            />
                          )}
                        </ApolloConsumer>
                      }

                      {/*{this.state.data.Briefing ? null : this.state.data*/}
                      {/*    .briefing_in !== 1 ? (*/}
                      {/*  <Mutation*/}
                      {/*    mutation={RN_BRIEFING_CREATE_FAST}*/}
                      {/*    refetchQueries={["RnMp"]}*/}
                      {/*    variables={{*/}
                      {/*      mp_id: this.props.match.params.id,*/}
                      {/*    }}*/}
                      {/*  >*/}
                      {/*    {(add) => (*/}
                      {/*      <button*/}
                      {/*        className="btn btn-primary ml-2 mt-3"*/}
                      {/*        disabled={disabled}*/}
                      {/*        onClick={() => {*/}
                      {/*          this.props*/}
                      {/*            .updateField({*/}
                      {/*              variables: {*/}
                      {/*                id: this.props.match.params.id,*/}
                      {/*                field: "briefing_in",*/}
                      {/*                value: 1,*/}
                      {/*              },*/}
                      {/*              refetchQueries: ["RnMp"],*/}
                      {/*            })*/}
                      {/*            .then(() => {*/}
                      {/*              add().then((res) => console.log(res));*/}
                      {/*            });*/}
                      {/*        }}*/}
                      {/*      >*/}
                      {/*        Добавить брифинг*/}
                      {/*      </button>*/}
                      {/*    )}*/}
                      {/*  </Mutation>*/}
                      {/*) : null}*/}
                      <span ref={(elem) => (this.anchor = elem)} />
                      <button
                        className="btn btn-secondary ml-2 mt-3"
                        disabled={disabled}
                        onClick={() => {
                          this.setState({
                            ...this.state,
                            modal: true,
                          });
                        }}
                      >
                        Добавить площадку в реестр
                      </button>
                      <button
                        className="btn btn-warning ml-2 mt-3"
                        disabled={disabled}
                        onClick={() => {
                          this.setState({
                            ...this.state,
                            date_picker_modal: true,
                          });
                        }}
                      >
                        Сменить период в МП
                      </button>

                      <div
                        style={{
                          position: "relative",
                          width: "630px",
                        }}
                      >
                        {this.state.date_picker_modal ? (
                          <DateModal
                            {...this.props}
                            {...this.state}
                            setParentState={(newState) => {
                              this.setState({
                                ...this.state,
                                ...newState,
                              });
                            }}
                          />
                        ) : null}

                        {this.state.modal ? (
                          <Modal
                            {...this.props}
                            {...this.state}
                            setParentState={(newState) => {
                              this.setState({
                                ...this.state,
                                ...newState,
                              });
                            }}
                          />
                        ) : null}
                      </div>
                      <div className="row col-12 ml-3 mt-3">
                        {this.state.data.briefing_in === 1 ? (
                          <Briefing
                            {...this.props}
                            {...this.state}
                            deleteBriefing={() => {
                              this.props
                                .updateField({
                                  variables: {
                                    id: this.props.match.params.id,
                                    field: "briefing_in",
                                    value: 0,
                                  },
                                  refetchQueries: ["RnMp"],
                                })
                                .then(() => {
                                  this.props.updateField({
                                    variables: {
                                      id: this.props.match.params.id,
                                      field: "briefing_id",
                                      value: null,
                                    },
                                    refetchQueries: ["RnMp"],
                                  });
                                });
                            }}
                          />
                        ) : null}

                        <div className="col-5">
                          <div
                            className="row col-12 m-3 p-2"
                            style={{
                              background: "#ffe4c47a",
                            }}
                          >
                            <div className="col-12">
                              <h5>Управление медиапланом</h5>
                            </div>
                            <div className="row col-12">
                              <label className="col-3 col-form-label">
                                <strong>Статус</strong>
                              </label>
                              <div className="col-6">
                                <select
                                  name="status"
                                  className="form-control"
                                  disabled={disabled}
                                  defaultValue={
                                    this.state.data.status &&
                                    this.state.data.status
                                  }
                                  onChange={(e) => {
                                    const value = +e.target.value;

                                    this.setState((state) => ({
                                      data: {
                                        ...state.data,
                                      },
                                      status: value,
                                    }));
                                  }}
                                >
                                  <option value="0">В работе</option>
                                  <option value="4">
                                    Отправлен на согласование
                                  </option>
                                  {/*<option value="1">Принят байером</option>*/}
                                  <option value="2">
                                    Возвращен с замечаниями
                                  </option>
                                  {/*<option value="3">Корректируется байером</option>*/}
                                  <option value="6">
                                    Согласован и отправлен в запуск
                                  </option>
                                  <option value="5">Отменено</option>
                                  <option value="7">Отчет</option>
                                </select>
                              </div>
                              <button
                                className="btn btn-primary col-3"
                                disabled={this.state.isPending || disabled}
                                onClick={() => {
                                  if (
                                    this.state.status !== null &&
                                    this.state.status !== 5
                                  ) {
                                    this.setState({
                                      ...this.state,
                                      isPending: true,
                                    });

                                    this.props
                                      .updateField({
                                        variables: {
                                          id: this.props.match.params.id,
                                          field: "status",
                                          value: this.state.status,
                                        },
                                        refetchQueries: [
                                          "RnMp",
                                          "RnBriefingList",
                                        ],
                                      })
                                      .then(() => {
                                        this.setState(
                                          {
                                            ...this.state,
                                            isPending: false,
                                          },
                                          () => {
                                            alert("Статус изменен!");
                                          }
                                        );
                                      })
                                      .catch(() => {
                                        this.setState({
                                          ...this.state,
                                          isPending: false,
                                        });
                                      });
                                  } else {
                                    this.setState({
                                      refusalRender: true,
                                      refusal: this.state.data.refusal
                                        ? this.state.data.refusal
                                        : "",
                                    });
                                  }
                                }}
                              >
                                Сохранить
                              </button>
                            </div>
                            {this.props.match.params.type === "manager" &&
                              this.state.status === 2 && (
                                <div className="row col-12 mt-3">
                                  <div className="col-6">
                                    <p>
                                      Комментарий по всему медиаплану (куратор)
                                    </p>
                                    <Mutation
                                      mutation={gql`
                                        mutation RnMpUpdateFieldString(
                                          $id: Int
                                          $field: String
                                          $value: String
                                        ) {
                                          RnMpUpdateFieldString(
                                            id: $id
                                            field: $field
                                            value: $value
                                          ) {
                                            id
                                          }
                                        }
                                      `}
                                    >
                                      {(updateField, { data }) => (
                                        <textarea
                                          name="comment"
                                          cols="95"
                                          rows="5"
                                          defaultValue={
                                            this.state.data.comment &&
                                            this.state.data.comment
                                          }
                                          disabled={disabled}
                                          onChange={(e) => {
                                            const value = e.target.value;
                                            if (value.length > 4) {
                                              updateField({
                                                variables: {
                                                  id: this.props.match.params
                                                    .id,
                                                  field: "comment",
                                                  value: value,
                                                },
                                                refetchQueries: ["RnMp"],
                                              });
                                            }
                                          }}
                                        >
                                          {" "}
                                        </textarea>
                                      )}
                                    </Mutation>
                                  </div>
                                </div>
                              )}
                            <div className="row col-12 mt-3">
                              <div className="col">
                                <p>
                                  <strong>Комментарий по медиаплану</strong>
                                </p>
                                <Mutation
                                  mutation={gql`
                                    mutation RnMpUpdateFieldString(
                                      $id: Int
                                      $field: String
                                      $value: String
                                    ) {
                                      RnMpUpdateFieldString(
                                        id: $id
                                        field: $field
                                        value: $value
                                      ) {
                                        id
                                      }
                                    }
                                  `}
                                >
                                  {(updateField, { data }) => (
                                    <textarea
                                      name="bayer_comment"
                                      style={{
                                        width: "100%",
                                      }}
                                      rows="5"
                                      disabled={disabled}
                                      defaultValue={
                                        this.state.data.bayer_comment
                                          ? this.state.data.bayer_comment
                                          : ""
                                      }
                                      onChange={(e) => {
                                        const value = e.target.value;
                                        if (value.length > 4) {
                                          updateField({
                                            variables: {
                                              id: this.props.match.params.id,
                                              field: "bayer_comment",
                                              value: value,
                                            },
                                            refetchQueries: ["RnMp"],
                                          });
                                        }
                                      }}
                                    ></textarea>
                                  )}
                                </Mutation>
                              </div>
                            </div>
                            {this.state.refusalRender ? (
                              <div className="col-12 mt-3">
                                <label className="col-12">Причина отмены</label>
                                <textarea
                                  name="refusal"
                                  className="col-12"
                                  cols="40"
                                  rows="4"
                                  disabled={disabled}
                                  defaultValue={
                                    this.state.data.refusal
                                      ? this.state.data.refusal
                                      : ""
                                  }
                                  onChange={(e) => {
                                    const value = e.target.value;
                                    this.setState((state) => ({
                                      data: {
                                        ...state.data,
                                      },
                                      refusal: value,
                                    }));
                                  }}
                                ></textarea>
                                <Mutation
                                  mutation={gql`
                                    mutation RnMpUpdateFieldString(
                                      $id: Int
                                      $field: String
                                      $value: String
                                    ) {
                                      RnMpUpdateFieldString(
                                        id: $id
                                        field: $field
                                        value: $value
                                      ) {
                                        id
                                      }
                                    }
                                  `}
                                >
                                  {(updateField, { data }) => (
                                    <button
                                      className="btn btn-primary"
                                      disabled={disabled}
                                      onClick={() => {
                                        updateField({
                                          variables: {
                                            id: this.props.match.params.id,
                                            field: "refusal",
                                            value: this.state.refusal,
                                          },
                                          refetchQueries: ["RnMp"],
                                        }).then(() => {
                                          this.props
                                            .updateField({
                                              variables: {
                                                id: this.props.match.params.id,
                                                field: "status",
                                                value: 5,
                                              },
                                              refetchQueries: [
                                                "RnMp",
                                                "RnBriefingList",
                                              ],
                                            })
                                            .then(() => {
                                              this.setState({
                                                refusalRender: false,
                                              });
                                            });
                                        });
                                      }}
                                    >
                                      Сохранить
                                    </button>
                                  )}
                                </Mutation>
                              </div>
                            ) : null}
                            <div className="row col-12 mt-3">
                              <p className="col">
                                {this.state.data.comment && (
                                  <>
                                    Комментарий по всему медиаплану:{" "}
                                    {this.state.data.comment}
                                  </>
                                )}
                              </p>
                            </div>
                            <div className="row col-12 mt-3">
                              <label className="col-6">
                                Предложение действительно до
                              </label>
                              <div className="col-6">
                                <input
                                  id="daterangselect"
                                  name="date_relevance"
                                  className="form-control"
                                  type="text"
                                  disabled={disabled}
                                  ref={this.textInput}
                                />
                              </div>
                            </div>
                            <div className="row col-12 my-4">
                              <div className="col">
                                {/*{this.state.data.status >= 4 ? (*/}
                                <ApolloConsumer>
                                  {(client) => (
                                    <button
                                      className="btn btn-success"
                                      disabled={disabled}
                                      onClick={async (e) => {
                                        e.preventDefault();
                                        if (!this.state.data.Briefing) {
                                          alert("Нет брифинга!");
                                        } else if (
                                          !this.state.data.Briefing.Partner
                                        ) {
                                          alert("Не указан партнер!");
                                        } else if (
                                          !this.state.data.Briefing.Brand
                                        ) {
                                          alert("Не указан бренд!");
                                        } else {
                                          const { data } = await client.query({
                                            query: gql`
                                              query RnMpExcelExport(
                                                $mpId: Int!
                                              ) {
                                                RnMpExcelExport(mpId: $mpId)
                                              }
                                            `,
                                            fetchPolicy: "no-cache",

                                            variables: {
                                              mpId: this.props.match.params.id,
                                            },
                                          });

                                          const name = getMpName(
                                            this.state.data.Briefing
                                          );

                                          const file = window.atob(
                                            data.RnMpExcelExport
                                          );
                                          download(
                                            file,
                                            `${name}.xlsx`,
                                            "application/vnd.ms-excel"
                                          );
                                        }
                                      }}
                                    >
                                      Выгрузить в Excel
                                    </button>
                                  )}
                                </ApolloConsumer>
                                {/*// ) : null}*/}
                              </div>
                            </div>
                          </div>
                          <div
                            className="row col-12 m-3 p-2"
                            style={{
                              background: "#add8e66b",
                            }}
                          >
                            <div className="col-12">
                              <h5>Создание заказа в ACC</h5>
                            </div>
                            <div className="row col-12">
                              <label className="col-form-label col-6">
                                <strong>Налогооблажение клиента</strong>
                              </label>
                              <div className="col-6">
                                {this.state.data.Briefing &&
                                  this.state.data.Briefing.vat === 1 && (
                                    <>
                                      с НДС
                                      <br />
                                    </>
                                  )}
                                {this.state.data.Briefing &&
                                  this.state.data.Briefing.no_vat === 1 &&
                                  "без НДС"}
                              </div>
                            </div>
                            <div className="row col-12 mt-1">
                              <label className="col-form-label col-6">
                                <strong>Тип договора</strong>
                              </label>
                              <div className="col-6">
                                {this.state.data.Briefing &&
                                  this.state.data.Briefing.contract_type ===
                                    1 &&
                                  "Услуговый"}
                                {this.state.data.Briefing &&
                                  this.state.data.Briefing.contract_type ===
                                    2 &&
                                  "Агентский"}
                                {this.state.data.Briefing &&
                                  this.state.data.Briefing.contract_type ===
                                    3 &&
                                  "Комплексный"}
                              </div>
                            </div>
                            <div className="row col-12 mt-1">
                              <label className="col-form-label col-6">
                                <strong>АВ: </strong>
                              </label>
                              <div className="col-6">
                                {this.state.data.Briefing &&
                                  this.state.data.Briefing.contract_type !==
                                    1 && (
                                    <>
                                      {this.state.data.Briefing &&
                                        this.state.data.Briefing
                                          .agent_commission_value && (
                                          <>
                                            {
                                              this.state.data.Briefing
                                                .agent_commission_value
                                            }{" "}
                                            %
                                          </>
                                        )}
                                      &nbsp;
                                      {this.state.data.Briefing &&
                                        this.state.data.Briefing
                                          .agent_commission_position === 2 &&
                                        "Внутри"}
                                      {this.state.data.Briefing &&
                                        this.state.data.Briefing
                                          .agent_commission_position === 1 &&
                                        "Сверху"}
                                    </>
                                  )}

                                {agent_commission_sum && (
                                  <>
                                    &nbsp;
                                    <NumberFormat
                                      displayType="text"
                                      thousandSeparator={" "}
                                      decimalSeparator={","}
                                      decimalScale={2}
                                      suffix={" ₽"}
                                      value={agent_commission_sum}
                                    />{" "}
                                    Сверху
                                  </>
                                )}
                              </div>
                            </div>
                            <hr className="col-11" />
                            <div className="row col-12">
                              <label className="col-form-label col-6">
                                <h6>Юридическое лицо Дельтаплан</h6>
                              </label>
                            </div>
                            {legal_entity_vat ? (
                              <div className="row col-12">
                                <label className="col-form-label col-6">
                                  <h6>с НДС</h6>
                                </label>
                                <div className="col-6">
                                  <Query
                                    variables={{
                                      params: {
                                        offset: 0,
                                        count: 50,
                                        filter: [
                                          {
                                            values: [1],
                                            operator: "EQ",
                                            field: "vat",
                                          },
                                        ],
                                      },
                                    }}
                                    query={gql`
                                      query($params: ParamsInput) {
                                        MLDpUrFacesList(params: $params) {
                                          id
                                          name
                                        }
                                      }
                                    `}
                                  >
                                    {({ loading, error, data }) => {
                                      if (loading) return "Loading...";
                                      if (error)
                                        return `Error! ${error.message}`;

                                      const MLDpUrFacesList = data.MLDpUrFacesList.map(
                                        (item) => ({
                                          label: item.name,
                                          value: item.id,
                                        })
                                      );

                                      return (
                                        <Select
                                          isDisabled={disabled}
                                          name="legal_entity_vat"
                                          options={MLDpUrFacesList}
                                          placeholder={"Выбрать"}
                                          defaultValue={
                                            this.state.data.UrFace_nds
                                              ? {
                                                  value: this.state.data
                                                    .UrFace_nds.id,
                                                  label: this.state.data
                                                    .UrFace_nds.name,
                                                }
                                              : this.state.data.Briefing &&
                                                this.state.data.Briefing
                                                  .UrFace_nds
                                              ? {
                                                  value: this.state.data
                                                    .Briefing.UrFace_nds.id,
                                                  label: this.state.data
                                                    .Briefing.UrFace_nds.name,
                                                }
                                              : false
                                          }
                                          onChange={(legal_entity_vat) => {
                                            const value =
                                              legal_entity_vat.value;
                                            this.props.updateField({
                                              variables: {
                                                id: this.props.match.params.id,
                                                field: "legal_entity_vat",
                                                value: value,
                                              },
                                              refetchQueries: ["RnMp"],
                                            });
                                          }}
                                        />
                                      );
                                    }}
                                  </Query>
                                </div>
                              </div>
                            ) : null}
                            {legal_entity_no_vat ? (
                              <div className="row col-12 mt-3">
                                <label className="col-form-label col-6">
                                  <h6>без НДС</h6>
                                </label>
                                <div className="col-6">
                                  <Query
                                    variables={{
                                      params: {
                                        offset: 0,
                                        count: 999,
                                        filter: [
                                          {
                                            values: [0],
                                            operator: "EQ",
                                            field: "vat",
                                          },
                                        ],
                                      },
                                    }}
                                    query={gql`
                                      query($params: ParamsInput) {
                                        MLDpUrFacesList(params: $params) {
                                          id
                                          name
                                        }
                                      }
                                    `}
                                  >
                                    {({ loading, error, data }) => {
                                      if (loading) return "Loading...";
                                      if (error)
                                        return `Error! ${error.message}`;

                                      const MLDpUrFacesList = data.MLDpUrFacesList.map(
                                        (item) => ({
                                          label: item.name,
                                          value: item.id,
                                        })
                                      );

                                      return (
                                        <Select
                                          isDisabled={disabled}
                                          name="legal_entity_novat"
                                          options={MLDpUrFacesList}
                                          placeholder={"Выбрать"}
                                          defaultValue={
                                            this.state.data.UrFace_nonds
                                              ? {
                                                  value: this.state.data
                                                    .UrFace_nonds.id,
                                                  label: this.state.data
                                                    .UrFace_nonds.name,
                                                }
                                              : this.state.data.Briefing &&
                                                this.state.data.Briefing
                                                  .UrFace_nonds
                                              ? {
                                                  value: this.state.data
                                                    .Briefing.UrFace_nonds.id,
                                                  label: this.state.data
                                                    .Briefing.UrFace_nonds.name,
                                                }
                                              : false
                                          }
                                          onChange={(legal_entity_novat) => {
                                            const value =
                                              legal_entity_novat.value;
                                            this.props.updateField({
                                              variables: {
                                                id: this.props.match.params.id,
                                                field: "legal_entity_novat",
                                                value: value,
                                              },
                                              refetchQueries: ["RnMp"],
                                            });
                                          }}
                                        />
                                      );
                                    }}
                                  </Query>
                                </div>
                              </div>
                            ) : null}
                            <hr className="col-11" />
                            <div className="col-12 row">
                              <label className="col-form-label col-12">
                                <strong>Юридическое лицо Клиента</strong>
                              </label>
                              <div className="col-12">
                                {this.state.data.Briefing &&
                                this.state.data.Briefing.partner_id ? (
                                  <ApolloConsumer>
                                    {(client) => (
                                      <AsyncSelect
                                        name="partner_id"
                                        isDisabled={disabled}
                                        defaultValue={
                                          this.state.data.LegalEntityClient
                                            ? {
                                                value: this.state.data
                                                  .LegalEntityClient.UrFace_ID,
                                                label: this.state.data
                                                  .LegalEntityClient.UF_Name,
                                              }
                                            : "не выбрано"
                                        }
                                        loadOptions={(inputValue) =>
                                          client
                                            .query({
                                              query: gql`
                                                query($params: ParamsInput) {
                                                  ML {
                                                    UrFaceList(
                                                      params: $params
                                                    ) {
                                                      UrFace_ID
                                                      UF_Name
                                                    }
                                                  }
                                                }
                                              `,
                                              variables: {
                                                params: {
                                                  offset: 0,
                                                  count: 10,
                                                  filter: [
                                                    {
                                                      values: [inputValue],
                                                      operator: "LIKE",
                                                      field: "name",
                                                    },
                                                  ],
                                                },
                                              },
                                            })
                                            .then((res) => {
                                              let list = res.data.ML.UrFaceList.map(
                                                (item) => ({
                                                  label: item.UF_Name,
                                                  value: item.UrFace_ID,
                                                })
                                              );

                                              return list;
                                            })
                                        }
                                        onChange={(legal_entity_client) => {
                                          const value =
                                            legal_entity_client.value;
                                          this.props.updateField({
                                            variables: {
                                              id: this.props.match.params.id,
                                              field: "legal_entity_client",
                                              value: value,
                                            },
                                            refetchQueries: ["RnMp"],
                                          });
                                        }}
                                      />
                                    )}
                                  </ApolloConsumer>
                                ) : (
                                  <p className="text-warning">
                                    Партнер не выбран
                                  </p>
                                )}
                              </div>
                            </div>
                            <div className="col-12 row mt-3">
                              <label className="col-12 col-form-label">
                                <strong>Номер договора</strong>
                              </label>
                              <div className="col-12">
                                <Query
                                  variables={{
                                    params: {
                                      offset: 0,
                                      count: 50,
                                      filter: [
                                        {
                                          values: [
                                            this.state.data.legal_entity_client,
                                          ],
                                          operator: "EQ",
                                          field: "FK_ref_UrFaces_ID_Client",
                                        },
                                      ],
                                    },
                                  }}
                                  query={gql`
                                    query($params: ParamsInput) {
                                      ML {
                                        DtDocumentList(params: $params) {
                                          DocSubject
                                          Document_ID
                                          DocDate
                                          DocNumber
                                        }
                                      }
                                    }
                                  `}
                                >
                                  {({ loading, error, data }) => {
                                    if (error) return `Error! ${error.message}`;
                                    if (loading) return "Поиск договоров...";

                                    const list = data.ML.DtDocumentList.map(
                                      (item) => ({
                                        label: `${item.DocNumber} ${moment(
                                          item.DocDate
                                        ).format("DD.MM.YYYY")}`,
                                        value: item.Document_ID,
                                      })
                                    );

                                    return (
                                      <Select
                                        isDisabled={disabled}
                                        name="contract_id"
                                        options={list}
                                        placeholder={"Выбрать"}
                                        value={
                                          this.state.data.Contract
                                            ? {
                                                value: this.state.data.Contract
                                                  .Document_ID,
                                                label: `${
                                                  this.state.data.Contract
                                                    .DocNumber
                                                } ${moment(
                                                  this.state.data.Contract
                                                    .DocDate
                                                ).format("DD.MM.YYYY")}`,
                                              }
                                            : false
                                        }
                                        onChange={(legal_entity_vat) => {
                                          const value = legal_entity_vat.value;
                                          this.props.updateField({
                                            variables: {
                                              id: this.props.match.params.id,
                                              field: "contract_id",
                                              value: value,
                                            },
                                            refetchQueries: ["RnMp"],
                                          });
                                        }}
                                      />
                                    );
                                  }}
                                </Query>
                              </div>
                            </div>
                            <div className="col-12 row mt-3">
                              <label className="col-12 col-form-label">
                                <strong>Траффик</strong>
                              </label>
                              <div className="col-12">
                                <ApolloConsumer>
                                  {(client) => (
                                    <AsyncSelect
                                      isDisabled={disabled}
                                      name="responsible_id"
                                      defaultValue={traffic}
                                      loadOptions={(inputValue) =>
                                        selectQuery(
                                          inputValue,
                                          "RefEmployeeList",
                                          client,
                                          "manager"
                                        )
                                      }
                                      onChange={(responsible) => {
                                        const value = responsible.value;

                                        this.props.updateField({
                                          variables: {
                                            id: this.props.match.params.id,
                                            field: "traffic_id",
                                            value: value,
                                          },
                                          refetchQueries: ["RnMp"],
                                        });
                                      }}
                                    />
                                  )}
                                </ApolloConsumer>
                              </div>
                            </div>
                            <div className="row col-12 my-4">
                              {this.state.data.Contract !== null ? (
                                <Mutation
                                  mutation={gql`
                                    mutation {
                                        RnMpAccessExport(id:${this.state.data.id}, user_id:${this.props.me.comon_id})
                                    }
                                `}
                                  refetchQueries={["RnMp"]}
                                >
                                  {(accessExport, { data }) => (
                                    <>
                                      {renderAccessBtn ? (
                                        <div className="col-3">
                                          {this.state.pending && (
                                            <>
                                              <img
                                                style={{ height: "1rem" }}
                                                src={loading}
                                                alt=""
                                              />
                                              &nbsp;
                                            </>
                                          )}
                                          <button
                                            className="btn btn-warning"
                                            disabled={
                                              this.state.pending || disabled
                                            }
                                            onClick={() => {
                                              if (
                                                +this.state.data.status !== 6
                                              ) {
                                                return alert(
                                                  'Чтобы создать сделку в АСС смените статус вашего медиаплана на "Согласован и отправлен в запуск!"'
                                                );
                                              }

                                              this.setState({
                                                pending: true,
                                              });

                                              accessExport().then((res) => {
                                                if (res.data.RnMpAccessExport) {
                                                  this.setState({
                                                    pending: false,
                                                  });
                                                  alert("Заказ отправлен");
                                                } else {
                                                  this.setState({
                                                    pending: false,
                                                  });
                                                  alert("Ошибка добавления");
                                                }
                                              });
                                            }}
                                          >
                                            Выгрузка в Access
                                          </button>

                                          {this.props.mp.order_id ||
                                          this.props.mp.order_id_2 ? (
                                            <div className="">
                                              Номер заказа в Access:&nbsp;
                                              {this.props.mp.order_id ? (
                                                <>
                                                  {this.props.mp.order_id}
                                                  &nbsp;
                                                </>
                                              ) : null}
                                              {this.props.mp.order_id_2 ? (
                                                <>{this.props.mp.order_id_2}</>
                                              ) : null}
                                            </div>
                                          ) : null}
                                        </div>
                                      ) : null}
                                    </>
                                  )}
                                </Mutation>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

const MediaPlanContainer = graphql(RN_MP_items_off, {
  props: ({ data }) => {
    return {
      loading: data.loading,
      error: data.error,
      mp: data.RnMp ? data.RnMp : null,
      me: data.me ? data.me : null,
      src_data: data,
    };
  },
  options: (props) => ({
    variables: {
      id: +props.match.params.id,
    },
  }),
})((props) => {
  if (props.loading) return null;
  if (props.error) return null;
  if (props.mp == null) {
    console.log("loading bad result ", props);
    return <p className="text-danger">Медиаплан удален!</p>;
  }

  return <MediaPlan {...props} />;
});

const MediaPlanContainerEdit = graphql(RN_MP_UPDATE_FIELD, {
  props: ({ mutate }) => {
    return {
      updateField: mutate,
    };
  },
  options: (props) => ({
    refetchQueries: ["RnMp", "RnMpItem", "RnBriefingList"],
  }),
})(MediaPlanContainer);

const MediaPlanContainerEditString = graphql(RN_MP_UPDATE_FIELD_STRING, {
  props: ({ mutate }) => {
    return {
      updateFieldString: mutate,
    };
  },
  options: (props) => ({
    refetchQueries: ["RnMp", "RnMpItem", "RnBriefingList"],
  }),
})(MediaPlanContainerEdit);

export default MediaPlanContainerEditString;
