import { gql } from "apollo-boost";

export const UPLOAD_INVENTORY_FILE = gql`
  mutation uploadInventoryFile(
    $file: Upload!
    $inventoryID: Int!
    $srcFileName: String
    $comment: String
  ) {
    Rn {
      uploadInventoryFile(
        file: $file
        inventoryID: $inventoryID
        srcFileName: $srcFileName
        comment: $comment
      ) {
        id
      }
    }
  }
`;

// deleteInventoryFile(inventoryID: Int!, fileID: Int!): File!

export const DELETE_INVENTORY_FILE = gql`
  mutation deleteInventoryFile($inventoryID: Int!, $fileID: Int!) {
    Rn {
      deleteInventoryFile(inventoryID: $inventoryID, fileID: $fileID) {
        id
      }
    }
  }
`;
