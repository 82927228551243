import React from "react";
import Header from "./../../../template/Header";
import gql from "graphql-tag";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { ApolloConsumer } from "react-apollo";

const query = gql`
  query {
    me {
      id
      comon_id
      email
    }
    TopBuyer {
      id
      email
    }
  }
`;

const mutation = gql`
  mutation TopBuyerUpdateEmail($email: String!) {
    TopBuyerUpdateEmail(email: $email) {
      id
      email
    }
  }
`;

const TopManager = props => {
  const [state, setState] = React.useState({
    email: ""
  });

  const { loading, error, data } = useQuery(query, {
    client: props.client
  });
  let [update] = useMutation(mutation, {
    client: props.client
  });

  if (loading) return "Loading...";
  if (error) return `Error! ${error.message}`;

  return (
    <>
      <Header
        {...props}
        src_data={{
          me: data.me
        }}
      />
      <section className="content">
        <div className="container-fluid mediaplans-list-block">
          <div
            className="table-container"
            style={{
              maxWidth: "1400px",
              margin: "0 auto"
            }}
          >
            <div className="mb-3 mt-3">
              <label>Электронная почта главного байера</label>
              <input
                type="text"
                className="form-control"
                defaultValue={
                  data.TopBuyer ? data.TopBuyer.email : "введите почту"
                }
                onChange={e => {
                  setState({
                    email: e.target.value
                  });
                }}
              />
            </div>
            <button
              className="btn btn-success"
              onClick={() => {
                update({
                  variables: {
                    email: state.email
                  }
                }).then(() => {
                  alert("Байер заменен");
                });
              }}
            >
              Сохранить
            </button>
          </div>
        </div>
      </section>
    </>
  );
};

const TopManagerContainer = props => {
  return (
    <ApolloConsumer>
      {client => {
        return <TopManager client={client} {...props} />;
      }}
    </ApolloConsumer>
  );
};

export default TopManagerContainer;
