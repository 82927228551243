import React, { Component } from "react";
import { ApolloConsumer } from "react-apollo";
import AsyncSelect from "react-select/lib/Async";
import { selectQuery, RN_GROUP } from "./../../GraphQL/RNqueries";
import { graphql } from "react-apollo";
import { RN_GROUP_UPDATE } from "../../GraphQL/RNmutations";

class Group extends Component {
  state = {
    current_person_id: ""
  };

  render() {
    return (
      <>
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
          <div className="collapse navbar-collapse">
            <span className="navbar-brand col-2">
              <input
                type="text"
                placeholder="Введите наименование группы"
                defaultValue={
                  this.props.group.name ? this.props.group.name : ""
                }
                onChange={e => {
                  const value = e.target.value;
                  if (value.length > 3) {
                    this.props.update({
                      variables: {
                        id: this.props.match.params.id,
                        field: "name",
                        value: value
                      },
                      refetchQueries: ["RnGroup"]
                    });
                  }
                }}
              />
            </span>
            <div className="col-2">
              <ApolloConsumer>
                {client => (
                  <AsyncSelect
                    loadOptions={inputValue =>
                      selectQuery(
                        inputValue,
                        "RefEmployeeList",
                        client,
                        "manager"
                      )
                    }
                    onChange={person => {
                      const value = person.value;
                      this.setState({
                        current_person_id: value
                      });
                    }}
                  />
                )}
              </ApolloConsumer>
            </div>
            <div className="col-2">
              <button
                className="btn btn-success"
                onClick={() => {
                  this.props.update({
                    variables: {
                      id: this.props.match.params.id,
                      field: "user_id",
                      value: this.state.current_person_id,
                      status: 1
                    },
                    refetchQueries: ["RnGroup"]
                  });
                }}
              >
                Добавить пользователя
              </button>
            </div>
          </div>
        </nav>
        <section className="content">
          <div className="container-fluid mediaplans-list-block">
            <div className="table-container">
              <div
                className="card"
                style={{
                  height: "calc(100% - 10px)"
                }}
              >
                <div
                  className="card-body mt-0"
                  style={{
                    overflowX: "hidden"
                  }}
                >
                  <div className="row">
                    <table
                      cellSpacing="0"
                      cellPadding="0"
                      className="custom-table"
                    >
                      <thead>
                        <tr>
                          <td
                            className="lighblue-col"
                            style={{
                              background: "#1D85D0"
                            }}
                          >
                            Пользователь
                          </td>
                          <td className="lighblue-col">&nbsp;</td>
                        </tr>
                      </thead>
                      <tbody>
                        {this.props.group.users &&
                          this.props.group.users.map(item => (
                            <tr key={item.id}>
                              <td className="lighblue-light-col">
                                {item.nameFull}
                              </td>
                              <td className="lighblue-light-col">
                                <button
                                  className="btn btn-danger"
                                  onClick={() => {
                                    const remove = window.confirm(
                                      "Вы точно хотите удалить пользователя из группы?"
                                    );
                                    if (remove) {
                                      this.props.update({
                                        variables: {
                                          id: this.props.match.params.id,
                                          field: "user_id",
                                          value: item.id,
                                          status: 0
                                        },
                                        refetchQueries: ["RnGroup"]
                                      });
                                    }
                                  }}
                                >
                                  Удалить
                                </button>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="small-screen">
          <div className="container-fluid mt-5">
            <h1>
              На данном устройстве работа не возможна. Используйте устройство с
              более широким экраном.
            </h1>
          </div>
        </div>
      </>
    );
  }
}

export const GroupContainer = graphql(RN_GROUP, {
  props: ({ data }) => {
    return {
      loading: data.loading,
      error: data.error,
      group: data.RN && data.RN.Group ? data.RN.Group : null
    };
  },
  options: props => ({
    variables: {
      id: props.match.params.id
    }
  })
})(props => {
  if (props.loading) return null;
  if (props.error) return null;
  if (props.group == null) {
    console.log("loading bad result ", props);
    return null;
  }

  return <Group {...props} />;
});

export const GroupUpdateContainer = graphql(RN_GROUP_UPDATE, {
  props: ({ mutate }) => {
    return {
      update: mutate
    };
  },
  options: props => ({})
})(GroupContainer);

export default GroupUpdateContainer;
