import React, { Component } from "react";
import { graphql } from "react-apollo";
import { RN_BRIEFING_UPDATE } from "../gql/mutation";
import Header from "./../../../../template/Header";
import AsyncSelect from "react-select/lib/Async";
import { ApolloConsumer } from "react-apollo";
import { selectQuery } from "../gql/query";

//Запросы
import { RN_BRIEFING } from "../gql/query";
import { COMON_USER_AVAILABLE } from "../../../GraphQL/RNqueries";
import geoView from "../geo/functons/geoView.tsx";

let moment = require("moment/min/moment-with-locales");
moment.locale("ru");

//опции возраста
const ageRange_options = {
  1: "18-24 года",
  2: "25-34 лет",
  3: "35-44 лет",
  4: "44-54 года",
  5: "55 и старше",
};

//возрасты по возрастанию
function ageRange_options_filter(array) {
  let options = [];
  for (let i in ageRange_options) {
    for (let j of array) {
      if (+i === +j) options.push(j);
    }
  }
  return options;
}

class Form extends Component {
  state = {
    data: {},
    refusalRender: 0,
  };

  static getDerivedStateFromProps(props, oldState) {
    let updateForState = {};
    if (oldState.briefing == null && props.briefing) {
      updateForState = {
        data: {
          ...props.briefing,
          ageRanges: ageRange_options_filter(props.briefing.ageRanges),
        },
        briefing: true,
      };

      delete updateForState.data["__typename"];
      delete updateForState.data.Manager;
      delete updateForState.data.Partner;
      delete updateForState.data.MLPartner;
      delete updateForState.data.Brand;
      delete updateForState.data.Cities;
      delete updateForState.data.Responsible;
      delete updateForState.data.UrFace_nonds;
      delete updateForState.data.UrFace_nds;
      delete updateForState.data.ComonGroup;
      updateForState.file = updateForState.data.file;
      updateForState.data.file = updateForState.data.file.map(
        (item) => item.id
      );
    }
    return updateForState;
  }

  //отправка данных
  dataSend = (status) => {
    this.props
      .createBriefing({
        variables: {
          briefing: {
            ...this.state.data,
            status,
          },
        },
      })
      .then(({ data }) => {
        this.setState({
          refresher: true,
          success: true,
        });

        this.props.history.push("/briefing/list");
      })
      .catch((res) => {
        if (res.graphQLErrors[0].extensions.category === "FormDataError") {
          const formErrors = res.graphQLErrors[0].message.split("\n");
          this.setState({ formErrors });
        } else {
          console.warn(res);
        }
      });
  };

  render() {
    return (
      <React.Fragment>
        {this.state.data.status === 1 && (
          <div
            className="alert alert-success alert-dismissible fade show"
            role="alert"
          >
            <strong>Бриф принят в работу!</strong>
            <button
              type="button"
              className="close"
              data-dismiss="alert"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        )}
        <Header {...this.props} {...this.state} />
        <section className="section-top">
          <div className="container-fluid">
            <div className="mb-1">
              <div className="float-left">
                <div className="mr-5 text-dark">
                  <h2>
                    Брифинг {this.state.data.name && this.state.data.name}
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="content">
          <div className="container-fluid mediaplans-list-block">
            <div className="table-container">
              <div
                style={{
                  marginTop: "30px",
                }}
                className="container form-horizontal"
              >
                <div className="form-group row">
                  <label className="col-3">
                    <strong>Куратор проекта</strong>
                  </label>
                  <div className="col-6">
                    {this.state.data ? (
                      <p>
                        {this.props.briefing.Manager &&
                          this.props.briefing.Manager.nameFull}
                      </p>
                    ) : (
                      ""
                    )}
                  </div>
                </div>

                {this.props.src_data.me && this.props.src_data.me.top === 1 ? (
                  <div className="form-group row">
                    <label className="col-3">
                      <strong>Исполнитель</strong>
                    </label>
                    <div className="col-6">
                      <ApolloConsumer>
                        {(client) => (
                          <AsyncSelect
                            name="responsible_id"
                            defaultValue={
                              this.props.briefing.Responsible
                                ? {
                                    value: this.props.briefing.Responsible.id,
                                    label: this.props.briefing.Responsible
                                      .nameFull,
                                  }
                                : {}
                            }
                            loadOptions={(inputValue) =>
                              selectQuery(
                                inputValue,
                                "RefEmployeeList",
                                client,
                                "manager"
                              )
                            }
                            onChange={(responsible) => {
                              const value = responsible.value;

                              client
                                .query({
                                  query: COMON_USER_AVAILABLE,
                                  variables: {
                                    id: value,
                                  },
                                })
                                .then((result) => {
                                  if (
                                    result.data.ComonUserAvailable.id !== value
                                  ) {
                                    alert(
                                      "Исполнитель в отпуске, заменен на заместителя"
                                    );
                                  }
                                  this.setState((state) => ({
                                    data: {
                                      ...state.data,
                                      responsible_id: +result.data
                                        .ComonUserAvailable.id,
                                    },
                                    Responsible: {
                                      ...result.data.ComonUserAvailable,
                                    },
                                  }));
                                });
                            }}
                          />
                        )}
                      </ApolloConsumer>
                    </div>
                    <div className="col-3">
                      <button
                        className="btn btn-success"
                        disabled={this.state.isPending}
                        onClick={() => {
                          this.setState(
                            {
                              isPending: true,
                            },
                            this.dataSend(0)
                          );
                        }}
                      >
                        Сохранить
                      </button>
                    </div>
                  </div>
                ) : (
                  <div className="form-group row">
                    <label className="col-3">
                      <strong>Исполнитель</strong>
                    </label>
                    <div className="col-6">
                      {this.state.data ? (
                        <p>
                          {this.props.briefing.Responsible &&
                            this.props.briefing.Responsible.nameFull}
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                )}

                <div className="form-group row">
                  <label className="col-3">
                    <strong>Соисполнители</strong>
                  </label>
                  <div className="col-6">
                    {this.props.briefing?.Users?.map((user) => (
                      <p key={user.id}>{user.nameFull}</p>
                    ))}
                  </div>
                </div>

                <div className="form-group row">
                  <label className="col-3">
                    <strong>Группа Comon</strong>
                  </label>
                  <div className="col-6">
                    {this.props.briefing && this.props.briefing.ComonGroup ? (
                      <a
                        href={`https://comon.company/workgroups/group/${this.props.briefing.ComonGroup.id}/`}
                      >
                        {this.props.briefing.ComonGroup &&
                          this.props.briefing.ComonGroup.name}
                      </a>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-3">
                    <strong>Название партнера</strong>
                  </label>
                  <div className="col-6">
                    {this.state.data ? (
                      <p>
                        {this.props.briefing.Partner &&
                          this.props.briefing.Partner.name}
                      </p>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-3">
                    <strong>Бренд</strong>
                  </label>
                  <div className="col-6">
                    {this.state.data ? (
                      <p>
                        {this.props.briefing.Brand &&
                          this.props.briefing.Brand.name}
                      </p>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-3">
                    <strong>Налогооблажение клиента</strong>
                  </label>
                  <div className="col-6">
                    {this.state.data.vat ? <p>с НДС</p> : null}
                    {this.state.data.no_vat ? <p>без НДС</p> : null}
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-3">
                    <strong>Тип договора</strong>
                  </label>
                  <div className="col-6">
                    {this.state.data.contract_type === 1 ? (
                      <p>Услуговый</p>
                    ) : null}
                    {this.state.data.contract_type === 2 ? (
                      <p>Агентский</p>
                    ) : null}
                    {this.state.data.contract_type === 3 ? (
                      <p>Комлексный</p>
                    ) : null}
                    {!this.state.data.contract_type ? <p>Не указан</p> : null}
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-3">
                    <strong>АВ</strong>
                  </label>
                  <div className="col-6">
                    {this.state.data.agent_commission_position === 1 ? (
                      <span>Сверху</span>
                    ) : null}
                    {this.state.data.agent_commission_position === 2 ? (
                      <span>Внутри</span>
                    ) : null}
                    {this.state.data.agent_commission_value ? (
                      <span> {this.state.data.agent_commission_value}</span>
                    ) : null}
                    {!this.state.data.agent_commission_value
                      ? "Не указано"
                      : null}
                    {this.state.data.agent_commission_value_type === 1
                      ? " %"
                      : null}
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-3">
                    <strong>Юр лицо Дельта-План без НДС</strong>
                  </label>
                  <div className="col-6">
                    {this.props.briefing.UrFace_nonds ? (
                      <span>{this.props.briefing.UrFace_nonds.name}</span>
                    ) : (
                      "Не указано"
                    )}
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-3">
                    <strong>Юр лицо Дельта-План с НДС</strong>
                  </label>
                  <div className="col-6">
                    {this.props.briefing.UrFace_nds ? (
                      <span>{this.props.briefing.UrFace_nds.name}</span>
                    ) : (
                      "Не указано"
                    )}
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-3">
                    <strong>Адрес сайта</strong>
                  </label>
                  <div className="col-6">
                    {this.state.data.website ? (
                      <p>{this.state.data.website}</p>
                    ) : (
                      "Не указан"
                    )}
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-3">
                    <strong>Тендер</strong>
                  </label>
                  <div className="col-6">
                    {this.state.data.tender === 1 ? (
                      <>
                        Да,{" "}
                        {moment(this.state.data.tender_deadline).format(
                          "D.MM.YYYY, h:mm"
                        )}
                      </>
                    ) : (
                      "Нет "
                    )}
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-3">
                    <strong>Рекламируемый товар / услуга</strong>
                  </label>
                  <div className="col-6">
                    {this.state.data.products ? (
                      <p>{this.state.data.products}</p>
                    ) : (
                      "Не указано"
                    )}
                  </div>
                </div>
                {this.state.data.target_audience && (
                  <div className="form-group row">
                    <label className="col-3">
                      <strong>Целевая аудитория РК</strong>
                    </label>

                    <div className="col-6">
                      {this.state.data.target_audience ? (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: this?.state?.data?.target_audience?.replace(
                              /(?:\r\n|\r|\n)/g,
                              "<br />"
                            ),
                          }}
                        />
                      ) : (
                        "Не указана"
                      )}
                    </div>
                  </div>
                )}
                <div className="form-group row">
                  <label className="col-3">
                    <strong>География РК</strong>
                  </label>
                  <div className="col-6">
                    {this.state.data.FederalDistricts
                      ? geoView(this.state.data.FederalDistricts)
                      : null}
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-3">
                    <strong>Период</strong>
                  </label>
                  <div className="col-6">
                    <p>
                      c {moment(this.state.data.dateStart).format("D MMMM Y")}{" "}
                      по {moment(this.state.data.dateEnd).format("D MMMM Y")}
                    </p>
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-3">
                    <strong>Бюджет в т.ч. НДС на весь период</strong>
                  </label>
                  <div className="col-7">
                    {this.state.data.totalBudgetFrom && (
                      <span>От {this.state.data.totalBudgetFrom} ₽</span>
                    )}
                    &nbsp;
                    {this.state.data.totalBudgetTo && (
                      <span>До {this.state.data.totalBudgetTo} ₽</span>
                    )}
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-3">
                    <strong>Виды инструментов для расчета</strong>
                  </label>
                  <div className="col-7">
                    {this.state.data.banner && <p>Баннерная реклама</p>}
                    {this.state.data.news && <p>Размещение новостей</p>}
                    {this.state.data.special && <p>Спецпроект</p>}
                    {this.state.data.cards && <p>Размещение карточек</p>}
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-3">
                    <strong>Отсрочка платежа</strong>
                  </label>
                  <div className="col-7">
                    <p>
                      {this.state.data.payment_delay &&
                        this.state.data.payment_delay === 1 &&
                        "С Даты Финиш"}
                      {this.state.data.payment_delay &&
                        this.state.data.payment_delay === 3 &&
                        "С конца месяца Даты Финиш"}
                      {this.state.data.payment_delay &&
                        this.state.data.payment_delay === 4 &&
                        "С Даты Старт"}
                      {this.state.data.payment_delay &&
                        this.state.data.payment_delay === 5 &&
                        "С начала месяца Даты Старт"}
                      {!this.state.data.payment_delay && "Не указана"}

                      {this.state.data.payment_delay_value
                        ? this.state.data.payment_delay_value
                        : null}
                    </p>
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-3">
                    <strong>Пожелания по площадкам / разделам форматам</strong>
                  </label>
                  <div className="col-7">
                    <p>
                      {this.state.data.recommends
                        ? this.state.data.recommends
                        : "Не указаны"}
                    </p>
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-3">
                    <strong>Разрабатываем РИМ?</strong>
                  </label>
                  <div className="col-7">
                    {this.state.data.promotionalMaterial ? "Да" : "Нет"}
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-3">
                    <strong>Предыдущая активность в Интернете</strong>
                  </label>
                  <div className="col-7">
                    <p>
                      {this.state.data.experience_comment
                        ? this.state.data.experience_comment
                        : "Не указан"}
                    </p>
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-3">
                    <strong>Файлы</strong>
                  </label>
                  <div className="col-7">
                    <ul>
                      {this.state.file &&
                        this.state.file.map((item) => (
                          <li key={item.id}>
                            <a
                              href={`${document.location.origin}/${item.path}`}
                            >
                              {item.name}
                            </a>
                          </li>
                        ))}
                    </ul>
                  </div>
                </div>
                <div className="footer col-10">
                  <div className="form-group row">
                    <div className="col-12">
                      <p>
                        Дата заполнения брифа –{" "}
                        {moment(this.state.data.entryDate).format(
                          "D MMMM YYYY, H:mm"
                        )}
                      </p>
                    </div>
                    <div className="col-3">
                      <p>deadline, дата и время</p>
                    </div>
                    <div className="col-7">
                      <div className="row">
                        <div className="col-6">
                          {this.state.data.deadline &&
                            moment(this.state.data.deadline).format(
                              "D MMMM Y, H:mm"
                            )}
                        </div>
                        <div className="col-6 date-info">
                          Не менее трех рабочих дней на подготовку медиаплана
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row form-group">
                    <div className="col-3">
                      <button
                        className="form-control btn btn-success"
                        disabled={this.state.isPending}
                        onClick={() => {
                          this.setState(
                            {
                              isPending: true,
                            },
                            this.dataSend(1)
                          );
                        }}
                      >
                        Утверждаю бриф
                      </button>
                    </div>
                    <div className="col-3">
                      <button
                        className="form-control btn btn-warning text-center"
                        disabled={this.state.isPending}
                        onClick={() => {
                          this.setState(
                            {
                              isPending: true,
                            },
                            this.dataSend(3)
                          );
                        }}
                      >
                        У меня высокая нагрузка
                      </button>
                    </div>
                    <div className="col-3">
                      <button
                        className="form-control btn btn-danger"
                        onClick={() => {
                          this.setState({
                            refusalRender: 1,
                          });
                        }}
                      >
                        Вернуть с замечаниями
                      </button>
                    </div>
                    <div
                      className="col-12 mt-3"
                      style={{
                        display:
                          this.state.refusalRender === 1 ? "block" : "none",
                      }}
                    >
                      <div className="form-group row">
                        <div className="col-12">
                          <p>Причина отказа</p>
                          <p>Расскажите чего именно нехватает в брифе</p>
                          <textarea
                            name="refusalDescription"
                            rows="4"
                            className="form-control"
                            onChange={(e) => {
                              const value = e.target.value;
                              this.setState((state) => ({
                                data: {
                                  ...state.data,
                                  refusal_description: value,
                                },
                              }));
                            }}
                          ></textarea>
                        </div>
                      </div>
                      <div className="form-group row">
                        <div className="col-12">
                          <button
                            className="btn btn-secondary mr-1"
                            disabled={this.state.isPending}
                            onClick={() => {
                              this.setState(
                                {
                                  isPending: true,
                                  refusalRender: 0,
                                },
                                this.dataSend(2)
                              );
                            }}
                          >
                            Отправить
                          </button>
                          <button
                            className="btn btn-secondary"
                            onClick={() => {
                              this.setState({
                                refusalRender: 0,
                              });
                            }}
                          >
                            Отмена
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="small-screen">
          <div className="container-fluid mt-5">
            <h1>
              На данном устройстве работа не возможна. Используйте устройство с
              более широким экраном.
            </h1>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export const FormView = graphql(RN_BRIEFING, {
  props: ({ data }) => {
    return {
      loading: data.loading,
      error: data.error,
      briefing: data.RnBriefing ? data.RnBriefing : null,
      src_data: data,
    };
  },
  options: (props) => ({
    variables: {
      id: props.match.params.id,
    },
  }),
})((props) => {
  if (props.loading) return null;
  if (props.error) return null;
  if (props.briefing == null) {
    console.log("loading bad result ", props);
    return null;
  }

  return <Form {...props} />;
});

export const FormWithSaveEdit = graphql(RN_BRIEFING_UPDATE, {
  props: ({ mutate }) => {
    return {
      createBriefing: mutate,
    };
  },
  options: (props) => ({}),
})(FormView);

export default FormWithSaveEdit;
