import {
  MlPartner,
  RnInventoryItem,
  RnMp,
  RnPlatform,
  useItemLogListQuery,
} from "../../graphql/types";
import React from "react";
import {
  SelectPartner,
  SelectPlatform,
  SelectMediaPlan,
  SelectInventory,
} from "../select";
import { Grid, TextField } from "@material-ui/core";
import { DatePicker } from "@material-ui/pickers";
import { Button } from "@material-ui/core";
import { Content } from "./content";
import { GetApp } from "@material-ui/icons";
export { Content } from "./content";

interface IItemLogListProps {
  client: any;
}

export function ItemLogList({ client }: IItemLogListProps) {
  const { data, fetchMore } = useItemLogListQuery({
    client,
  });

  const [filters, setFilters] = React.useState<{
    partnerID: number[] | [];
    inventoryID: number[] | [];
    platformID: number[] | [];
    dateStart: Date | null;
    dateFinish: Date | null;
    mpID: number[] | [];
    geo: string | null;
  }>({
    partnerID: [],
    inventoryID: [],
    dateStart: null,
    dateFinish: null,
    mpID: [],
    platformID: [],
    geo: null,
  });

  return (
    <>
      <div className="col-12 mb-3">
        <Grid container spacing={2}>
          <Grid item xs={2}>
            <SelectPartner
              client={client}
              isMulti={true}
              onChange={(partner) =>
                setFilters({
                  ...filters,
                  partnerID: partner?.map(
                    (partner: MlPartner) => partner.id ?? 0
                  ),
                })
              }
            />
          </Grid>
          <Grid item xs={2}>
            <TextField
              fullWidth
              label="География"
              variant="outlined"
              size="small"
              value={filters.geo}
              onChange={(e) => {
                setFilters({
                  ...filters,
                  geo: e.target.value,
                });
              }}
            />
          </Grid>
          <Grid item xs={1}>
            <DatePicker
              size="small"
              fullWidth
              disableToolbar
              autoOk
              variant="inline"
              inputVariant="outlined"
              label="Дата старт"
              format="dd.MM.yyyy"
              value={filters.dateStart}
              defaultValue={null}
              disableFuture
              onChange={(dateStart) =>
                setFilters({
                  ...filters,
                  dateStart,
                })
              }
            />
          </Grid>
          <Grid item xs={1}>
            <DatePicker
              disableFuture
              size="small"
              disableToolbar
              fullWidth
              autoOk
              variant="inline"
              inputVariant="outlined"
              label="Дата финиш"
              format="dd.MM.yyyy"
              maxDate={new Date()}
              value={filters.dateFinish}
              onChange={(dateFinish) =>
                setFilters({
                  ...filters,
                  dateFinish,
                })
              }
            />
          </Grid>
          <Grid item xs={2}>
            <SelectPlatform
              client={client}
              label={"Площадка"}
              isMulti={true}
              onChange={(platform) =>
                setFilters({
                  ...filters,
                  platformID: platform?.map((p: RnPlatform) => p.id),
                })
              }
            />
          </Grid>
          <Grid item xs={2}>
            <SelectMediaPlan
              label={"Медиаплан"}
              client={client}
              isMulti={true}
              onChange={(mp) =>
                setFilters({
                  ...filters,
                  mpID: mp.map((mp: RnMp) => mp.id),
                })
              }
            />
          </Grid>
          <Grid item xs={2}>
            <SelectInventory
              client={client}
              label="Инвентарь"
              isMulti={true}
              onChange={(inventory) =>
                setFilters({
                  ...filters,
                  inventoryID: inventory.map((i: RnInventoryItem) => i.id),
                })
              }
            />
          </Grid>

          <Grid item xs={3}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                fetchMore({
                  variables: {
                    partnerID: filters.partnerID ?? null,
                    inventoryID: filters.inventoryID ?? null,
                    dateStart: filters.dateStart
                      ? filters.dateStart.toISOString()
                      : null,
                    dateFinish: filters.dateFinish
                      ? filters.dateFinish.toISOString()
                      : null,
                    mpID: filters.mpID ?? null,
                    platformID: filters.platformID ?? null,
                    geo: filters.geo ?? null,
                  },
                  updateQuery: (prev, { fetchMoreResult }) => {
                    if (!fetchMoreResult) return prev;
                    return { ...fetchMoreResult };
                  },
                });
              }}
            >
              Применить
            </Button>
            <Button
              className="ml-1"
              variant="contained"
              color="default"
              startIcon={<GetApp />}
            >
              Сформировать отчет
            </Button>
          </Grid>
        </Grid>
      </div>
      <div className="table-responsive">
        <table className="table table-bordered table-sm">
          <tbody>
            <Content itemLogList={data?.RN?.itemLogList} />
          </tbody>
        </table>
      </div>
    </>
  );
}
