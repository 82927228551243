import React from "react";
import NumberFormat from "react-number-format";
import TargetingList from "./../../Platform/Tabs/Inventory/InventoryTypes/TargetingList/TargetingList";
import { RN_MP_ITEM } from "../../../GraphQL/RNqueries";

class InventoryFastCreation extends React.Component {
  state = {
    uploaded: false,
    data: [],
  };

  static getDerivedStateFromProps(props, state) {
    let newState = {};

    if (!state.uploaded) {
      newState.uploaded = true;

      if (
        props.MediaPlanItem.Platform &&
        props.MediaPlanItem.Platform.Price &&
        props.MediaPlanItem.Platform.Price.vat &&
        +props.MediaPlanItem.Platform.Price.vat !== 4
      ) {
        newState.data = {
          vat: props.MediaPlanItem.Platform.Price.vat,
        };
      }
    }

    return newState;
  }

  inventory_vat_render() {
    let inventory_vat_value = "";

    if (
      this.props.MediaPlanItem.Platform &&
      this.props.MediaPlanItem.Platform.Price &&
      +this.props.MediaPlanItem.Platform.Price.vat === 4
    ) {
      return (
        <select
          className="form-control"
          defaultValue={inventory_vat_value}
          onChange={(e) => {
            const value = e.target.value;
            this.setState({
              ...this.state,
              data: {
                ...this.state.data,
                vat: value,
              },
            });
          }}
        >
          <option value="" hidden>
            Выбрать
          </option>
          <option value="1">с НДС</option>
          <option value="2">без НДС</option>
        </select>
      );
    } else if (
      this.props.MediaPlanItem.Platform &&
      this.props.MediaPlanItem.Platform.Price &&
      this.props.MediaPlanItem.Platform.Price.vat
    ) {
      return (
        <>
          {+this.props.MediaPlanItem.Platform.Price.vat === 1 && <p>с НДС</p>}
          {+this.props.MediaPlanItem.Platform.Price.vat === 2 && <p>без НДС</p>}
        </>
      );
    } else {
      return "Не выбрано налогообложение площадки";
    }
  }

  handleInventoryAdd(id) {}

  render() {
    return (
      <>
        <div
          className="main-wrapper popup-wrapper"
          style={{
            top: "-100px",
            left: "50%",
            minWidth: "700px",
            boxShadow: "0px 0px 6px 0px rgba(50, 50, 50, 0.81)",
            border: "none",
            zIndex: 1000,
            overflow: "auto",
          }}
        >
          <section className="content">
            <div className="container-fluid">
              <div
                className="table-container"
                style={{
                  overflowY: "inherit",
                  overflowX: "inherit",
                }}
              >
                <div className="title row">
                  <div className="col-10">
                    <h3
                      className="mr-5 mb-0 pt-3"
                      style={{
                        border: "none",
                      }}
                    >
                      Создание инвентаря
                    </h3>
                  </div>
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <a
                    href="#"
                    className="float-right text-right mt-3 col-2 dark-color"
                    onClick={(e) => {
                      e.preventDefault();
                      this.props.setParentState({
                        fast_creation: false,
                      });
                    }}
                  >
                    Закрыть окно
                  </a>
                </div>
                <div className="card">
                  <div
                    className="card-body"
                    style={{
                      overflowX: "visible",
                    }}
                  >
                    <div className="row">
                      <div className="col-3">
                        <label>
                          {" "}
                          Тип
                          <select
                            className="form-control"
                            onChange={(e) => {
                              const value = e.target.value;

                              this.setState({
                                ...this.state,
                                data: {
                                  ...this.state.data,
                                  type: value,
                                },
                              });
                            }}
                          >
                            <option hidden={true}>Выбрать</option>
                            <option value="banner">Баннер</option>
                            <option value="card">Карточка</option>
                            <option value="publication">Публикация</option>
                            <option value="special">Спецпроект</option>
                          </select>
                        </label>
                      </div>
                      <div className="col-3">
                        <label>
                          {" "}
                          Название
                          <input
                            type="text"
                            className="form-control"
                            onChange={(e) => {
                              const value = e.target.value;

                              this.setState({
                                ...this.state,
                                data: {
                                  ...this.state.data,
                                  name: value,
                                },
                              });
                            }}
                          />
                        </label>
                      </div>
                      <div className="col-3">
                        <label>
                          {" "}
                          Формат
                          <input
                            type="text"
                            className="form-control"
                            onChange={(e) => {
                              const value = e.target.value;

                              this.setState({
                                ...this.state,
                                data: {
                                  ...this.state.data,
                                  format: value,
                                },
                              });
                            }}
                          />
                        </label>
                      </div>
                      <div className="col-3">
                        <label>
                          {" "}
                          Единицы закупа
                          <select
                            name="priceType"
                            className="form-control"
                            onChange={(e) => {
                              const value = +e.target.value;

                              this.setState({
                                ...this.state,
                                data: {
                                  ...this.state.data,
                                  priceType: value,
                                },
                              });
                            }}
                          >
                            <option>Выбрать</option>
                            <option value={5}>День</option>
                            <option value={1}>Неделя / Месяц</option>
                            <option value={6}>2 недели</option>
                            <option value={2}>За 1000 показов / CPM</option>
                            <option value={3}>За клик / CPC</option>
                            <option value={4}>Фиксированная</option>
                          </select>
                        </label>
                      </div>
                    </div>
                    <div className="row">
                      {this.state.data.priceType !== 1 ? (
                        <div className="col-3">
                          <label>
                            {" "}
                            Стоимость за единицу
                            <NumberFormat
                              name="price_value"
                              className="form-control"
                              thousandSeparator={" "}
                              decimalSeparator={","}
                              decimalScale={2}
                              suffix={" ₽"}
                              onValueChange={(price) => {
                                const value =
                                  price.value === "" ? null : price.floatValue;

                                this.setState({
                                  ...this.state,
                                  data: {
                                    ...this.state.data,
                                    price_value: value,
                                  },
                                });
                              }}
                            />
                          </label>
                        </div>
                      ) : (
                        <>
                          <div className="col-3">
                            <label>
                              {" "}
                              Стоимость за неделю
                              <NumberFormat
                                name="price_value_week"
                                className="form-control"
                                thousandSeparator={" "}
                                decimalSeparator={","}
                                decimalScale={2}
                                suffix={" ₽"}
                                onValueChange={(price) => {
                                  const value =
                                    price.value === ""
                                      ? null
                                      : price.floatValue;
                                  this.setState({
                                    ...this.state,
                                    data: {
                                      ...this.state.data,
                                      price_value_week: value,
                                    },
                                  });
                                }}
                              />
                            </label>
                          </div>
                          <div className="col-3">
                            <label>
                              {" "}
                              Стоимость за месяц
                              <NumberFormat
                                name="price_value_month"
                                className="form-control"
                                thousandSeparator={" "}
                                decimalSeparator={","}
                                decimalScale={2}
                                suffix={" ₽"}
                                onValueChange={(price) => {
                                  const value =
                                    price.value === ""
                                      ? null
                                      : price.floatValue;

                                  this.setState({
                                    ...this.state,
                                    data: {
                                      ...this.state.data,
                                      price_value_month: value,
                                    },
                                  });
                                }}
                              />
                            </label>
                          </div>
                        </>
                      )}
                      <div className="col-3">
                        <label>
                          {" "}
                          Налогообложение
                          {this.inventory_vat_render()}
                        </label>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col">
                        <TargetingListContainer
                          {...this.props}
                          parentState={this.state}
                          setParentState={(newState) => {
                            this.setState((state) => ({
                              data: {
                                ...state.data,
                                ...newState,
                              },
                            }));
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="card-footer">
                    <button
                      className="btn btn-success"
                      onClick={() => {
                        if (!this.state.data.name) {
                          alert("Укажите имя инвентаря");
                        } else if (!this.state.data.type) {
                          alert("Укажите тип инвентаря");
                        } else {
                          let newState = this.state.data;
                          delete newState.uploaded;

                          this.props
                            .createInventory({
                              variables: {
                                inventory: {
                                  platformId: this.props.platformId,
                                  author_id: this.props.parentProps.parentProps
                                    .me.id
                                    ? this.props.parentProps.parentProps.me.id
                                    : null,
                                  ...newState,
                                },
                              },
                            })
                            .then((res) => {
                              this.props
                                .updateField({
                                  variables: {
                                    id: this.props.mpItem.id,
                                    field: "inventory_id",
                                    value: res.data.RnInventoryCreate.id,
                                  },
                                  refetchQueries: [
                                    {
                                      query: RN_MP_ITEM,
                                      variables: {
                                        id: this.props.mpItem.id,
                                      },
                                    },
                                  ],
                                  awaitRefetchQueries: true,
                                })
                                .then(() => {
                                  this.props.setParentState({
                                    fast_creation: false,
                                  });
                                });
                            });
                        }
                      }}
                    >
                      Сохранить
                    </button>
                    <button
                      className="btn btn-secondary ml-1"
                      onClick={() => {
                        this.props.setParentState({
                          fast_creation: false,
                        });
                      }}
                    >
                      Отменить
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </>
    );
  }
}

const TargetingListContainer = (props) => {
  return <TargetingList {...props} />;
};

export default InventoryFastCreation;
