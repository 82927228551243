import React from "react";
import { Query } from "react-apollo";
import { GET_FEDERAL_DISTRICT_LIST } from "../RN/Components/Briefing/geo/geoQueries";
import compare from "../RN/Components/Briefing/geo/functons/geoCompare";
import GeoModal from "../RN/Components/Briefing/geo/geo";

const popUpStyle: any = {
  position: "absolute",
  left: "50%",
  top: "10%",
  transform: "translateX(-50%)",
  background: "#fff",
  minWidth: "600px",
  zIndex: 2,
  boxShadow: "0px 0px 10px 0 grey",
  borderRadius: "5px"
};

const Geo = (props: any) => {
  const [isOpened, changeState] = React.useState(false);

  return (
    <>
      <Query query={GET_FEDERAL_DISTRICT_LIST}>
        {({ loading, error, data }: any) => {
          if (loading) return null;
          if (error) return `Error! ${error.message}`;

          if (props.briefing_data.FederalDistricts) {
            return (
              <>
                {compare(
                  props.briefing_data.FederalDistricts,
                  data.Geo.FederalDistrictList
                )}{" "}
                <br />
              </>
            );
          }

          return null;
        }}
      </Query>

      <button
        className="btn btn-dark"
        onClick={() => {
          changeState(!isOpened);
        }}
      >
        Выбрать ГЕО
      </button>

      {isOpened ? (
        <div style={popUpStyle}>
          <GeoModal
            {...props}
            isOpened={isOpened}
            setClose={() => changeState(false)}
          />
        </div>
      ) : null}
    </>
  );
};

export default Geo;
