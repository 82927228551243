import React, { Component } from 'react';
//moment
const moment = require("moment/min/moment-with-locales.js");

class SupercomItem extends Component {
    /* global $*/

    state = {
        data: {}
    };

    //ссылка
    //создание ссылки ручное
    //впоследствии нужна в jquery
    textInput = React.createRef();

    //создание объектов jquery
    jQueryCreate(){
        const _this = this;
        $('input#startDate').daterangepicker({
            startDate: moment(this.props.parentState.SupercomItems[this.props.indexKey].startDate).format('DD.MM.YYYY'),
            singleDatePicker: true,
            opens: 'right',
            "locale": {
                "applyLabel": "Применить",
                "cancelLabel": "Отменить"
            }
        }).ready(()=>{

            let date = this.textInput.current.value;

            let list = this.props.parentState.SupercomItems;

            for (let i of list) {
                delete i['__typename'];
            }

            list[this.props.indexKey].startDate = moment(date, 'DD-MM-YYYY').format();
            list[this.props.indexKey].updateDate = this.state._updateDate;

            _this.props.parentProps.setParentState({
                "SupercomItems": list
            });

            _this.props.parentProps.GetSupercomItems(list);


        }).on('apply.daterangepicker', function(e, picker) {

            let list = _this.props.parentState.SupercomItems;

            for (let i of list) {
                delete i['__typename'];
            }

            list[_this.props.indexKey].startDate = moment(picker.startDate).format();
            list[_this.props.indexKey].updateDate = _this.state._updateDate;

            _this.props.parentProps.setParentState({
                "SupercomItems": list
            });

            _this.props.parentProps.GetSupercomItems(list);

        });
    }

    componentDidMount(){
        this.jQueryCreate();

        this.setState(state => ({
            _updateDate: moment().format()
        }));

    }

    render() {
        console.log(this.props);
        return (
            <div style={{marginBottom: "1rem"}} className="row">
                <div className="col-2">
                    <div className="">
                        <input
                            id="startDate"
                            name="startDate"
                            type="text"
                            ref={this.textInput}
                            className="form-control calendar-input"
                        />
                    </div>
                </div>
                <div className="col-2">
                    <div className="form-group">
                        <select
                            className="form-control"
                            name="accrualBasis"
                            value={
                                this.props.parentState.SupercomItems[this.props.indexKey].accrualBasis ?
                                    this.props.parentState.SupercomItems[this.props.indexKey].accrualBasis : ''
                            }
                            onChange={(e=>{
                                let list = this.props.parentState.SupercomItems;

                                for (let i of list) {
                                    delete i['__typename'];
                                }

                                list[this.props.indexKey].accrualBasis = +e.target.value;
                                list[this.props.indexKey].updateDate = this.state._updateDate;

                                this.props.parentProps.setParentState({
                                    "SupercomItems": list
                                });

                                this.props.parentProps.GetSupercomItems(list);
                            })}
                        >
                            <option value="">Выбрать...</option>
                            <option value="1">Сумма заказов за год</option>
                        </select>
                    </div>
                </div>
                <div className="col-1">
                    <div className="form-group">
                        <input
                            name="turnoverFrom"
                            type="number"
                            className="form-control"
                            value={
                                this.props.parentState.SupercomItems[this.props.indexKey].turnoverFrom ?
                                    this.props.parentState.SupercomItems[this.props.indexKey].turnoverFrom : ''
                            }
                            onChange={(e=>{
                                let list = this.props.parentState.SupercomItems;

                                for (let i of list) {
                                    delete i['__typename'];
                                }

                                list[this.props.indexKey].turnoverFrom = +e.target.value;
                                list[this.props.indexKey].updateDate = this.state._updateDate;

                                this.props.parentProps.setParentState({
                                    "SupercomItems": list
                                });

                                this.props.parentProps.GetSupercomItems(list);
                            })}
                        />
                    </div>
                </div>
                <div className="col-1">
                    <div className="form-group">
                        <input
                            name="turnoverTo"
                            type="number"
                            className="form-control"
                            value={
                                this.props.parentState.SupercomItems[this.props.indexKey].turnoverTo ?
                                    this.props.parentState.SupercomItems[this.props.indexKey].turnoverTo : ''
                            }
                            onChange={(e=>{
                                let list = this.props.parentState.SupercomItems;

                                for (let i of list) {
                                    delete i['__typename'];
                                }

                                list[this.props.indexKey].turnoverTo = +e.target.value;
                                list[this.props.indexKey].updateDate = this.state._updateDate;

                                this.props.parentProps.setParentState({
                                    "SupercomItems": list
                                });

                                this.props.parentProps.GetSupercomItems(list);
                            })}
                        />
                    </div>
                </div>
                <div className="col-1">
                    <div className="form-group">
                        <input
                            name="supercomAmount"
                            type="number"
                            className="form-control"
                            value={
                                this.props.parentState.SupercomItems[this.props.indexKey].supercomAmount ?
                                    this.props.parentState.SupercomItems[this.props.indexKey].supercomAmount : ''
                            }
                            onChange={(e=>{
                                let list = this.props.parentState.SupercomItems;

                                for (let i of list) {
                                    delete i['__typename'];
                                }

                                list[this.props.indexKey].supercomAmount = +e.target.value;
                                list[this.props.indexKey].updateDate = this.state._updateDate;

                                this.props.parentProps.setParentState({
                                    "SupercomItems": list
                                });

                                this.props.parentProps.GetSupercomItems(list);
                            })}
                        />
                    </div>
                </div>
                <div className="col-2">
                    <div className="form-group">
                        <input
                            type="text"
                            className="form-control"
                            name="authorId"
                            value={ this.props.parentState._supercomItems[this.props.indexKey] ?
                                this.props.parentState._supercomItems[this.props.indexKey].login : 'Не указан' }
                            disabled={true}
                        />
                    </div>
                </div>
                <div className="col-2">
                    <div className="form-group">
                        <input
                            type="text"
                            className="form-control"
                            name="updateDate"
                            value={ this.props.supercomItem.updateDate ? moment(this.props.supercomItem.updateDate).format('DD.MM.YYYY hh:MM') : 'Не указана' }
                            disabled={true}
                        />
                    </div>
                </div>
                <div className="col-1">
                    <button
                        type="button"
                        className="pull-right remove-item btn btn-danger"
                        onClick={()=>{
                            let list = this.props.parentState.SupercomItems;

                            for (let i of list) {
                                delete i['__typename'];
                            }

                            if(list[this.props.indexKey].id) {
                                list = list.filter(item => item.id !== list[this.props.indexKey].id);
                            }
                            else {
                                if(list.length > 1) {
                                    list.splice(this.props.indexKey, 1);
                                }
                                else {
                                    list = [];
                                }
                            }

                            this.props.setParentState({
                                "SupercomItems": list
                            });

                            this.props.parentProps.GetSupercomItems(list);

                        }}
                    >
                        Удалить
                    </button>
                </div>
            </div>
        );
    }
}

export default SupercomItem;