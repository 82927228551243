import React from "react";
import District from "./district";
import { GET_FEDERAL_DISTRICT_LIST } from "./geoQueries";
import { Query } from "react-apollo";
import { ApolloConsumer } from "react-apollo";
import GeoSelect from "../../../../components/geoSelect";

const ulStyle = {
  listStyle: "none",
};

const Geo = ({
  onChange,
  briefing_data,
  client,
  geoIsUpdated,
  update,
  supporting_geo_data,
  isOpened,
  setClose,
}) => {
  const [state, setState] = React.useState({
    countryIsOpened: false,
    data: supporting_geo_data,
    geo_data: {
      FederalDistricts: [...briefing_data.FederalDistricts],
    },
    tree: [],
    selectValue: [],
    manual_city_select: true,
  });

  function selectEntity(fiasEntities) {
    let selectedValues = [];
    let data = [...state.data];
    let districts = [];

    for (let fe of fiasEntities) {
      if (fe.Region) {
        const res = selectLocality(fe);
        selectedValues = [
          ...selectedValues,
          {
            value: fe.AOGUID,
            label: fe.FORMALNAME,
            srcData: {
              ...fe,
            },
          },
        ];
        data = [...data, res.newDistrictWithSubjects.Code, fe.AOGUID];

        districts = [...districts];

        for (let district of res.districtList) {
          if (!districts.find((d) => d.Code === district.Code)) {
            districts = [...districts, { ...district }];
          } else {
            districts = districts.filter((d) => d.Code !== district.Code);
            districts = [...districts, { ...district }];
          }
        }
      } else {
        const { newDistricts, newDistrict } = selectRegion(fe);

        for (let district of newDistricts) {
          if (!districts.find((d) => d.Code === district.Code)) {
            districts = [...districts, { ...district }];
          } else {
            districts = districts.filter((d) => d.Code !== district.Code);
            districts = [...districts, { ...district }];
          }
        }

        data = [...data, newDistrict.Code, fe.AOGUID];
        selectedValues = [
          ...selectedValues,
          { value: fe.AOGUID, label: fe.FORMALNAME, srcData: { ...fe } },
        ];
      }
    }

    setState({
      ...state,
      selectValue: [...selectedValues],
      data: [...data],
      geo_data: {
        FederalDistricts: [...districts],
      },
    });
  }

  function selectLocality(entity) {
    if (entity.Region) {
      const { newDistricts, newDistrict } = selectRegion(entity.Region);

      const fdInState = state.geo_data.FederalDistricts.find(
        (fd) => newDistrict.Code == fd.Code
      );

      const filteredSubjects = newDistrict.FederalSubjects.filter(
        (fs) => fs.AOGUID !== entity.Region.AOGUID
      );

      let newDistrictWithSubjects = {};

      const federalSubjectInState =
        fdInState && fdInState.FederalSubjects
          ? fdInState.FederalSubjects.find(
              (fs) => fs.AOGUID === entity.Region.AOGUID
            )
          : null;

      if (federalSubjectInState) {
        const newSubject = {
          ...federalSubjectInState,
        };

        const filteredLocalities = newSubject.Localities
          ? newSubject.Localities.filter((l) => l.AOGUID !== entity.AOGUID)
          : [];

        newDistrictWithSubjects = {
          ...fdInState,
          FederalSubjects: [
            ...filteredSubjects,
            {
              ...newSubject,
              Localities: [
                ...filteredLocalities,
                {
                  ...entity,
                },
              ],
            },
          ],
        };
      } else {
        newDistrictWithSubjects = {
          ...newDistrict,
          FederalSubjects: [
            ...filteredSubjects,
            {
              ...entity.Region,
              Localities: [
                {
                  ...entity,
                },
              ],
            },
          ],
        };
      }

      return {
        newDistrictWithSubjects,
        districtList: [
          ...newDistricts.filter(
            (fd) => fd.Code !== newDistrictWithSubjects.Code
          ),
          { ...newDistrictWithSubjects },
        ],
      };
    }
  }

  function selectRegion(entity) {
    const result = {};

    if (entity.District) {
      const newDistricts = state.geo_data.FederalDistricts.filter(
        (fd) => fd.Code !== entity.District.Code
      );

      let newDistrict = {};

      let districtInState = state.geo_data.FederalDistricts.find(
        (fd) => fd.Code === entity.District.Code
      );

      if (districtInState) {
        const filteredSubjects = districtInState.FederalSubjects.filter(
          (fs) => fs.AOGUID !== entity.AOGUID
        );

        newDistrict = {
          ...districtInState,
          FederalSubjects: [...filteredSubjects, { ...entity }],
        };
      } else {
        newDistrict = {
          ...entity.District,
          FederalSubjects: [{ ...entity }],
        };
      }

      newDistricts.push(newDistrict);

      result.newDistrict = newDistrict;
      result.newDistricts = newDistricts;
    }

    return result;
  }

  if (!geoIsUpdated) {
    update();
  }

  const handleChangeGeoData = (data = state.data, d_data, cities = []) => {
    setState({
      ...state,
      data: [...data],
      geo_data: {
        FederalDistricts: [...d_data],
      },
      cities: [...cities],
    });
  };

  const handleChangeGeoDataCity = (
    data,
    district,
    region,
    city,
    add,
    cities = []
  ) => {
    let districts = state.geo_data.FederalDistricts
      ? state.geo_data.FederalDistricts.filter((i) => i.Code !== district.Code)
      : [];
    let subjects =
      state.geo_data.FederalDistricts &&
      state.geo_data.FederalDistricts.find((i) => i.Code === district.Code)
        ? state.geo_data.FederalDistricts &&
          state.geo_data.FederalDistricts.find(
            (i) => i.Code === district.Code
          ).FederalSubjects.filter((i) => i.AOGUID !== region.AOGUID)
        : [];
    let localities =
      state.geo_data.FederalDistricts &&
      state.geo_data.FederalDistricts.find((i) => i.Code === district.Code) &&
      state.geo_data.FederalDistricts.find(
        (i) => i.Code === district.Code
      ).FederalSubjects.find((i) => i.AOGUID === region.AOGUID) &&
      state.geo_data.FederalDistricts.find(
        (i) => i.Code === district.Code
      ).FederalSubjects.find((i) => i.AOGUID === region.AOGUID).Localities
        ? state.geo_data.FederalDistricts.find(
            (i) => i.Code === district.Code
          ).FederalSubjects.find((i) => i.AOGUID === region.AOGUID).Localities
        : [];
    localities = localities.filter((i) => i.AOGUID !== city);

    if (add) {
      districts = [
        ...districts,
        {
          ...district,
          FederalSubjects: [
            ...subjects,
            {
              ...region,
              isFull: false,
              Localities: [
                ...localities,
                {
                  AOGUID: city,
                },
              ],
            },
          ],
        },
      ];
    } else {
      districts = [
        ...districts,
        {
          ...district,
          FederalSubjects: [
            ...subjects,
            {
              ...region,
              isFull: false,
              Localities: [...localities],
            },
          ],
        },
      ];
    }

    handleChangeGeoData(data, districts, cities);
  };

  const handleChangeGeoDataRegion = (
    district_code,
    region_code,
    add = true,
    cities = []
  ) => {
    let federalDistricts = state.geo_data.FederalDistricts.filter(
      (i) => i.Code !== district_code
    );
    let district = state.geo_data.FederalDistricts.find(
      (i) => i.Code === district_code
    );
    let federalSubjects = district
      ? district.FederalSubjects.filter((i) => i.AOGUID !== region_code)
      : [];

    if (add) {
      federalDistricts = [
        ...federalDistricts,
        {
          Code: district_code,
          FederalSubjects: [
            ...federalSubjects,
            {
              AOGUID: region_code,
            },
          ],
        },
      ];
    } else {
      federalDistricts = [
        ...federalDistricts,
        {
          Code: district_code,
          FederalSubjects: [...federalSubjects],
        },
      ];
    }

    // setState({
    //     ...state,
    //     data: [
    //         ...state.data
    //     ],
    //     geo_data: {
    //         ...state.geo_data,
    //         FederalDistricts: [
    //             ...federalDistricts
    //         ]
    //     },
    //     cities
    // });

    handleChangeGeoData(state.data, federalDistricts, cities);
  };

  const handleChange = (data, cities) => {
    setState({
      ...state,
      data: [...data],
      cities,
    });
  };

  const handleChangeCity = (city, add, cities = []) => {
    let data = state.data.filter((i) => i !== city);
    if (add) {
      data = [...data, city];
    } else {
      data = [...data];
    }

    handleChange(data, cities);
  };

  const openHandler = (newTree) => {
    setState({
      ...state,
      data: [...state.data],
      tree: [...newTree],
    });
  };

  const Select = ({
    setClose,
    onChange,
    geo_data,
    handleChangeGeoData,
    handleChangeGeoDataCity,
    handleChangeGeoDataRegion,
    tree,
    briefing_data,
    data_geo_tree,
    openHandler,
    handleChangeCity,
  }) => {
    return (
      <div className="p-3">
        <Query query={GET_FEDERAL_DISTRICT_LIST}>
          {({ loading, error, data }) => {
            if (loading) return null;
            if (error) return `Error! ${error.message}`;

            return (
              <>
                <div className="row pb-3">
                  <div className="col">
                    <div className="btn-group btn-group-sm">
                      <button
                        className={`btn btn-sm ${
                          state.manual_city_select
                            ? "btn-primary"
                            : "btn-secondary"
                        }`}
                        onClick={() => {
                          setState({
                            ...state,
                            manual_city_select: true,
                          });
                        }}
                      >
                        Поиск по городам
                      </button>
                      <button
                        className={`btn btn-sm ${
                          state.manual_city_select
                            ? "btn-secondary"
                            : "btn-primary"
                        }`}
                        onClick={() => {
                          setState({
                            ...state,
                            manual_city_select: false,
                            cities: [],
                          });
                        }}
                      >
                        Выбрать город
                      </button>
                    </div>
                  </div>
                  <div className="col text-right">
                    <a href="#" onClick={setClose}>
                      Закрыть
                    </a>
                  </div>
                </div>
                <div className="row">
                  {state.manual_city_select ? (
                    <ApolloConsumer>
                      {(client) => (
                        <GeoSelect
                          client={client}
                          value={state.selectValue}
                          onChange={selectEntity}
                        />
                      )}
                    </ApolloConsumer>
                  ) : (
                    <div className="col">
                      <label>
                        <span
                          className="mr-1"
                          style={{
                            fontSize: "1rem",
                          }}
                          onClick={() => {
                            setState({
                              ...state,
                              countryIsOpened: !state.countryIsOpened,
                            });
                          }}
                        >
                          {state.countryIsOpened ? "-" : "+"}
                        </span>
                        <input
                          checked={state.data && state.data.length}
                          type="checkbox"
                          onChange={(e) => {
                            const VALUE = e.target.checked;

                            if (VALUE) {
                              setState({
                                ...state,
                                data: [
                                  ...data.Geo.FederalDistrictList.map(
                                    (i) => i.Code
                                  ),
                                ],
                                geo_data: {
                                  ...state.data,
                                  FederalDistricts: [
                                    ...data.Geo.FederalDistrictList.map(
                                      (i) => ({
                                        ...i,
                                        isFull: true,
                                      })
                                    ),
                                  ],
                                },
                                tree: [...state.tree],
                              });
                            } else {
                              setState({
                                ...state,
                                data: [],
                                tree: [...state.tree],
                                geo_data: {
                                  ...state.data,
                                  FederalDistricts: [],
                                },
                              });
                            }
                          }}
                        />{" "}
                        Россия
                      </label>

                      <div
                        style={{
                          maxHeight: "500px",
                          overflow: "auto",
                        }}
                      >
                        {state.countryIsOpened ? (
                          <ul style={ulStyle}>
                            {data.Geo.FederalDistrictList.map((item, index) => {
                              return (
                                <District
                                  client={client}
                                  key={item.Code}
                                  indexKeyDistrict={index}
                                  district={item}
                                  ulStyle={ulStyle}
                                  handleChange={handleChange}
                                  briefing_data={briefing_data}
                                  data_geo_tree={data_geo_tree}
                                  openHandler={openHandler}
                                  tree={tree}
                                  handleChangeCity={handleChangeCity}
                                  src_data={item}
                                  geo_data={geo_data}
                                  handleChangeGeoData={handleChangeGeoData}
                                  handleChangeGeoDataCity={
                                    handleChangeGeoDataCity
                                  }
                                  handleChangeGeoDataRegion={
                                    handleChangeGeoDataRegion
                                  }
                                />
                              );
                            })}
                          </ul>
                        ) : null}
                      </div>
                    </div>
                  )}
                </div>
                <div className="row pt-3">
                  <div className="col">
                    <button
                      className="btn btn-primary mr-1"
                      onClick={() => {
                        let data = {};

                        data.FederalDistricts = state.geo_data.FederalDistricts.map(
                          (district) => {
                            if (district.isFull) {
                              return {
                                ...district,
                                FederalSubjects: null,
                              };
                            } else {
                              let inner_district = {
                                ...district,
                              };

                              if (inner_district.FederalSubjects) {
                                inner_district.FederalSubjects = inner_district.FederalSubjects.map(
                                  (subject) => {
                                    if (subject.isFull) {
                                      return {
                                        ...subject,
                                        Localities: null,
                                      };
                                    } else {
                                      return subject;
                                    }
                                  }
                                );
                              }

                              return inner_district;
                            }
                          }
                        );

                        onChange(data);

                        setClose();
                      }}
                    >
                      Сохранить
                    </button>
                    <button className="btn btn-secondary" onClick={setClose}>
                      Отменить
                    </button>
                  </div>
                </div>
              </>
            );
          }}
        </Query>
      </div>
    );
  };

  return (
    <>
      {isOpened ? (
        <Select
          client={client}
          setClose={setClose}
          onChange={onChange}
          briefing_data={briefing_data}
          data_geo_tree={state.data}
          districtIsOpened={state.districtIsOpened}
          openHandler={openHandler}
          tree={state.tree}
          handleChangeCity={handleChangeCity}
          geo_data={state.geo_data}
          handleChangeGeoData={handleChangeGeoData}
          handleChangeGeoDataCity={handleChangeGeoDataCity}
          handleChangeGeoDataRegion={handleChangeGeoDataRegion}
        />
      ) : null}
    </>
  );
};

export default Geo;
