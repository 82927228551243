import React, { Component } from "react";
import NumberFormat from "react-number-format";
import TargetingList from "./TargetingList/TargetingList";

class InventoryAdditional extends Component {
  render() {
    return (
      <div className="col-12">
        <div className="row">
          <div className="col-6">
            <div className="row mb-3">
              <div className="col-4">
                Название<span className="text-danger">*</span>
              </div>
              <div className="col-8">
                <input
                  type="text"
                  name="name"
                  className="form-control"
                  value={
                    this.props.parentState.data.name
                      ? this.props.parentState.data.name
                      : ""
                  }
                  onChange={e => {
                    this.props.setParentState({
                      name: e.target.value
                    });
                  }}
                />
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-4">Размер / формат</div>
              <div className="col-8">
                <input
                  type="text"
                  name="format"
                  className="form-control"
                  placeholder="Размер / формат"
                  value={
                    this.props.parentState.data.format
                      ? this.props.parentState.data.format
                      : ""
                  }
                  onChange={e => {
                    this.props.setParentState({
                      format: e.target.value
                    });
                  }}
                />
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-4">Цена фиксированная</div>
              <div className="col-8">
                <NumberFormat
                  name="price_value"
                  className="form-control"
                  thousandSeparator={" "}
                  decimalSeparator={","}
                  decimalScale={2}
                  suffix={!this.props.isForeign && " ₽"}
                  value={
                    this.props.parentState.data.price_value !== null
                      ? this.props.parentState.data.price_value
                      : ""
                  }
                  onValueChange={price => {
                    const value = price.value === "" ? null : price.floatValue;
                    this.props.setParentState({
                      price_value: value
                    });
                  }}
                />
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-4">Налогообложение</div>
              <div className="col-8">
                <select
                  className="form-control"
                  value={
                    this.props.parentState.data.vat
                      ? this.props.parentState.data.vat
                      : ""
                  }
                  onChange={e => {
                    const value = +e.target.value;
                    this.props.setParentState({
                      vat: value
                    });
                  }}
                >
                  <option value="0" hidden>
                    Выбрать
                  </option>
                  <option value="1">с НДС</option>
                  <option value="2">без НДС</option>
                </select>
              </div>
            </div>
            <TargetingList {...this.props} />
            <div className="row mb-3">
              <div className="col-4">Комментарий</div>
              <div className="col-8">
                <textarea
                  name="comment"
                  rows="4"
                  className="form-control"
                  value={
                    this.props.parentState.data.comment
                      ? this.props.parentState.data.comment
                      : ""
                  }
                  onChange={e => {
                    this.props.setParentState({
                      comment: e.target.value
                    });
                  }}
                ></textarea>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default InventoryAdditional;
