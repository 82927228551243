import React, { Component } from "react";
import { TabBarItem } from "../../TabContext";

import AsyncSelect from "react-select/lib/Async";
import Select from "react-select";
import { ApolloConsumer } from "react-apollo";
import gql from "graphql-tag";
import { Query } from "react-apollo";

//запросы к api
import {
  selectReferenceGeo,
  selectQuery,
} from "./../../../../GraphQL/RNqueries";

class Info extends Component {
  render() {
    //вариант по умолчанию для селекта
    let payment_delay = [];

    if (this.props.parentState.data.payment_delay === 1)
      payment_delay = [{ label: "С Даты Финиш" }];
    if (this.props.parentState.data.payment_delay === 2)
      payment_delay = [{ label: "С начала месяца Даты Финиш" }];
    if (this.props.parentState.data.payment_delay === 3)
      payment_delay = [{ label: "С конца месяца Даты Финиш" }];
    if (this.props.parentState.data.payment_delay === 4)
      payment_delay = [{ label: "С Даты Старт" }];
    if (this.props.parentState.data.payment_delay === 5)
      payment_delay = [{ label: "С начала месяца Даты Старт" }];
    if (this.props.parentState.data.payment_delay === 6)
      payment_delay = [{ label: "С конца месяца Даты Старт" }];

    return (
      <div className="tab-content" id="myTabContent">
        <div
          className="tab-pane fade show active"
          id="contact"
          role="tabpanel"
          aria-labelledby="contact-tab"
        >
          <div className="main-info">
            <div className="item">
              <div className="row">
                <div className="col-3 pt-3">ID</div>
                <div className="col-9">
                  <input
                    name="id"
                    type="text"
                    className="form-control"
                    value={this.props.parentState.data.id}
                    disabled={true}
                  />
                </div>
              </div>
            </div>
            <div className="item">
              <div className="row">
                <div className="col-3 pt-3">ГРУППА МЕДИА</div>
                <div className="col-9">
                  <input
                    type="text"
                    className="form-control"
                    value="Региональные и тематические сайты"
                    disabled={true}
                  />
                </div>
              </div>
            </div>
            <div className="item">
              <div className="row">
                <div className="col-3 pt-3">ОБЛАСТЬ</div>
                <div className="col-9">
                  <ApolloConsumer>
                    {(client) => (
                      <AsyncSelect
                        name="region"
                        placeholder={"Выбрать"}
                        isMulti
                        defaultValue={
                          this.props.parentProps.platform.RegionList
                            ? this.props.parentProps.platform.RegionList.map(
                                (item) => ({
                                  value: item.AOGUID,
                                  label: `${item.FORMALNAME} ${item.SHORTNAME} `,
                                })
                              )
                            : false
                        }
                        loadOptions={(inputValue) =>
                          selectReferenceGeo(inputValue, "MlRegionList", client)
                        }
                        onChange={(region) => {
                          const value = region.map((item) => item.value);
                          this.props.setParentState({
                            region: value,
                          });
                        }}
                      />
                    )}
                  </ApolloConsumer>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="row">
                <div className="col-3 pt-3">ГОРОД</div>
                <div className="col-9">
                  <ApolloConsumer>
                    {(client) => (
                      <AsyncSelect
                        name="city"
                        placeholder={"Выбрать"}
                        isMulti
                        defaultValue={
                          this.props.parentProps.platform.CityList
                            ? this.props.parentProps.platform.CityList.map(
                                (item) => ({
                                  value: item.AOGUID,
                                  label: (
                                    <>
                                      {item.FORMALNAME} {item.SHORTNAME}
                                      <br />
                                      <span style={{ color: "#B0B0B0" }}>
                                        {item.Parent && item.Parent.Parent ? (
                                          <>
                                            {item.Parent.Parent.FORMALNAME}{" "}
                                            {item.Parent.Parent.SHORTNAME},
                                          </>
                                        ) : null}{" "}
                                        {item.Parent ? (
                                          <>
                                            {item.Parent.FORMALNAME}{" "}
                                            {item.Parent.SHORTNAME}
                                          </>
                                        ) : null}{" "}
                                      </span>{" "}
                                    </>
                                  ),
                                })
                              )
                            : false
                        }
                        loadOptions={(inputValue) =>
                          selectReferenceGeo(inputValue, "MlCityList", client)
                        }
                        onChange={(city) => {
                          const value = city.map((item) => item.value);
                          this.props.setParentState({
                            city: value,
                          });
                        }}
                      />
                    )}
                  </ApolloConsumer>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="row">
                <div className="col-3 pt-3">ВИД МЕДИА</div>
                <div className="col-9">
                  <Query
                    variables={{
                      params: {
                        offset: 0,
                        count: 999,
                      },
                    }}
                    query={gql`
                      query MlMediaTypeList($params: ParamsInput) {
                        MlMediaTypeList(params: $params) {
                          id
                          name
                        }
                      }
                    `}
                  >
                    {({ loading, error, data }) => {
                      if (loading) return "Loading...";
                      if (error) return `Error! ${error.message}`;

                      const MlMediaTypeList = data.MlMediaTypeList.map(
                        (item) => ({
                          label: item.name,
                          value: item.id,
                        })
                      );

                      return (
                        <Select
                          name="mediaType"
                          placeholder={"Выбрать"}
                          options={MlMediaTypeList}
                          defaultValue={
                            this.props.parentProps.platform.MediaType
                              ? {
                                  value: this.props.parentProps.platform
                                    .MediaType.id,
                                  label: this.props.parentProps.platform
                                    .MediaType.name,
                                }
                              : false
                          }
                          onChange={(mediaType) => {
                            const value = mediaType.value;
                            this.props.setParentState({ mediaType: value });
                          }}
                        />
                      );
                    }}
                  </Query>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="row">
                <div className="col-3 pt-3">ТЕРРИТОРИАЛЬНЫЙ ОХВАТ</div>
                <div className="col-9">
                  <Query
                    variables={{
                      params: {
                        offset: 0,
                        count: 999,
                      },
                    }}
                    query={gql`
                      query MlCoverageList($params: ParamsInput) {
                        MlCoverageList(params: $params) {
                          id
                          name
                        }
                      }
                    `}
                  >
                    {({ loading, error, data }) => {
                      if (loading) return "Loading...";
                      if (error) return `Error! ${error.message}`;

                      const MlCoverageList = data.MlCoverageList.map(
                        (item) => ({
                          label: item.name,
                          value: item.id,
                        })
                      );

                      return (
                        <Select
                          name="coverage"
                          placeholder={"Выбрать"}
                          options={MlCoverageList}
                          defaultValue={
                            this.props.parentProps.platform.Coverage
                              ? {
                                  value: this.props.parentProps.platform
                                    .Coverage.id,
                                  label: this.props.parentProps.platform
                                    .Coverage.name,
                                }
                              : false
                          }
                          onChange={(thematics) => {
                            const value = thematics.value;
                            this.props.setParentState({
                              coverage: value,
                            });
                          }}
                        />
                      );
                    }}
                  </Query>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="row">
                <div className="col-3 pt-3">РАЗМЕР АУДИТОРИИ</div>
                <div className="col-9">
                  <input
                    name="totalAudience"
                    type="number"
                    className="form-control"
                    value={
                      this.props.parentState.data.totalAudience
                        ? this.props.parentState.data.totalAudience
                        : ""
                    }
                    onChange={(e) => {
                      const value = +e.target.value;
                      this.props.setParentState({
                        totalAudience: value,
                      });
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="item">
              <div className="row">
                <div className="col-3 pt-3">ССЫЛКА НА СТАТИСТИКУ</div>
                <div className="col-9">
                  <input
                    name="statisticsUrl"
                    type="text"
                    className="form-control"
                    value={
                      this.props.parentState.data.statisticsUrl
                        ? this.props.parentState.data.statisticsUrl
                        : ""
                    }
                    onChange={(e) => {
                      const value = e.target.value;
                      this.props.setParentState({
                        statisticsUrl: value,
                      });
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="item">
              <div className="row">
                <div className="col-3 pt-3">СЕТЕВОЙ ПАРТНЕР</div>
                <div className="col-9">
                  <ApolloConsumer>
                    {(client) => (
                      <AsyncSelect
                        name="networkPartner"
                        placeholder={"Выбрать"}
                        defaultValue={
                          this.props.parentProps.platform.NetworkPartner
                            ? {
                                value: this.props.parentProps.platform
                                  .NetworkPartner.id
                                  ? this.props.parentProps.platform
                                      .NetworkPartner.id
                                  : null,
                                label: this.props.parentProps.platform
                                  .NetworkPartner.name
                                  ? this.props.parentProps.platform
                                      .NetworkPartner.name
                                  : null,
                              }
                            : {}
                        }
                        loadOptions={(inputValue) =>
                          selectQuery(inputValue, "MLPartnerList", client)
                        }
                        onChange={(owner) => {
                          const value = +owner.value;
                          this.props.setParentState({ networkPartner: value });
                        }}
                      />
                    )}
                  </ApolloConsumer>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="row">
                <div className="col-3 pt-3">ТЕМАТИКА</div>
                <div className="col-9">
                  <Query
                    variables={{
                      params: {
                        offset: 0,
                        count: 999,
                      },
                    }}
                    query={gql`
                      query MlThematicsList($params: ParamsInput) {
                        MlThematicsList(params: $params) {
                          id
                          name
                        }
                      }
                    `}
                  >
                    {({ loading, error, data }) => {
                      if (loading) return "Loading...";
                      if (error) return `Error! ${error.message}`;

                      const MlThematicsList = data.MlThematicsList.map(
                        (item) => ({
                          label: item.name,
                          value: item.id,
                        })
                      );

                      return (
                        <Select
                          name="mediaType"
                          placeholder={"Выбрать"}
                          options={MlThematicsList}
                          defaultValue={
                            this.props.parentProps.platform.Thematics
                              ? {
                                  value: this.props.parentProps.platform
                                    .Thematics.id
                                    ? this.props.parentProps.platform.Thematics
                                        .id
                                    : null,
                                  label: this.props.parentProps.platform
                                    .Thematics.name
                                    ? this.props.parentProps.platform.Thematics
                                        .name
                                    : null,
                                }
                              : false
                          }
                          onChange={(thematics) => {
                            const value = thematics.value;
                            this.props.setParentState({ thematics: value });
                          }}
                        />
                      );
                    }}
                  </Query>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="row">
                <div className="col-3 pt-3">ВЛАДЕЛЕЦ</div>
                <div className="col-9">
                  <ApolloConsumer>
                    {(client) => (
                      <AsyncSelect
                        name="owner"
                        placeholder={"Выбрать"}
                        defaultValue={
                          this.props.parentProps.platform.Owner
                            ? {
                                value: this.props.parentProps.platform.Owner.id
                                  ? this.props.parentProps.platform.Owner.id
                                  : null,
                                label: this.props.parentProps.platform.Owner
                                  .name
                                  ? this.props.parentProps.platform.Owner.name
                                  : null,
                              }
                            : {}
                        }
                        loadOptions={(inputValue) =>
                          selectQuery(inputValue, "MLPartnerList", client)
                        }
                        onChange={(owner) => {
                          const value = +owner.value;
                          this.props.setParentState({
                            owner: value,
                          });
                        }}
                      />
                    )}
                  </ApolloConsumer>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="row">
                <div className="col-3 pt-3">ЯЗЫК</div>
                <div className="col-9">
                  <input
                    name="language"
                    type="text"
                    className="form-control"
                    value={
                      this.props.parentState.data.language
                        ? this.props.parentState.data.language
                        : ""
                    }
                    onChange={(e) => {
                      const value = e.target.value;
                      this.props.setParentState({
                        language: value,
                      });
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="item">
              <div className="row">
                <div className="col-3 pt-3">СЕЛЛЕР</div>
                <div className="col-9">
                  <ApolloConsumer>
                    {(client) => (
                      <AsyncSelect
                        name="seller"
                        placeholder={"Выбрать"}
                        defaultValue={
                          this.props.parentProps.platform.Seller
                            ? {
                                value: this.props.parentProps.platform.Seller.id
                                  ? this.props.parentProps.platform.Seller.id
                                  : null,
                                label: this.props.parentProps.platform.Seller
                                  .name
                                  ? this.props.parentProps.platform.Seller.name
                                  : null,
                              }
                            : {}
                        }
                        loadOptions={(inputValue) =>
                          selectQuery(
                            inputValue,
                            "MLPartnerList",
                            client,
                            "general",
                            false,
                            false,
                            true
                          )
                        }
                        onChange={(owner) => {
                          const value = +owner.value;
                          this.props.setParentState({ seller: value });
                        }}
                      />
                    )}
                  </ApolloConsumer>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="row">
                <div className="col-3 pt-3">Адекватность</div>
                <div className="col-4">
                  <select
                    name="adequate"
                    className="form-control"
                    value={
                      this.props.parentState.data.adequate
                        ? this.props.parentState.data.adequate
                        : ""
                    }
                    onChange={(e) => {
                      const value = +e.target.value;
                      this.props.setParentState({
                        adequate: value,
                      });
                    }}
                  >
                    <option hidden>Выбрать</option>
                    <option value="1">Красный</option>
                    <option value="2">Желтый</option>
                    <option value="3">Зеленый</option>
                  </select>
                </div>
                <div className="col-5">
                  <input
                    name="adequate_comment"
                    type="text"
                    className="form-control"
                    value={
                      this.props.parentState.data.adequate_comment
                        ? this.props.parentState.data.adequate_comment
                        : ""
                    }
                    onChange={(e) => {
                      const value = e.target.value;
                      this.props.setParentState({ adequate_comment: value });
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="item">
              <div className="row">
                <div className="col-3 pt-3">Отсрочка платежа</div>
                <div className="col-4">
                  <Select
                    name="payment_delay"
                    placeholder={"Выбрать"}
                    options={[
                      {
                        label: "С Даты Финиш",
                        value: 1,
                      },
                      {
                        label: "С начала месяца Даты Финиш",
                        value: 2,
                      },
                      {
                        label: "С конца месяца Даты Финиш",
                        value: 3,
                      },
                      {
                        label: "С Даты Старт",
                        value: 4,
                      },
                      {
                        label: "С начала месяца Даты Старт",
                        value: 5,
                      },
                      {
                        label: "С конца месяца Даты Старт",
                        value: 6,
                      },
                    ]}
                    defaultValue={payment_delay}
                    onChange={(payment_delay) => {
                      const value = payment_delay.value;
                      this.props.setParentState({
                        payment_delay: value,
                      });
                    }}
                  />
                </div>
                <div className="col-5">
                  <input
                    name="payment_delay_value"
                    type="number"
                    step="1"
                    className="form-control"
                    value={
                      this.props.parentState.data.payment_delay_value
                        ? this.props.parentState.data.payment_delay_value
                        : ""
                    }
                    onChange={(e) => {
                      const value = +e.target.value;
                      this.props.setParentState({
                        payment_delay_value: value,
                      });
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="item">
              <div className="row">
                <div className="col-3 pt-3">Правило прямого клиента</div>
                <div className="col-9">
                  <input
                    name="direct_client_rule"
                    type="text"
                    className="form-control"
                    value={
                      this.props.parentState.data.direct_client_rule
                        ? this.props.parentState.data.direct_client_rule
                        : ""
                    }
                    onChange={(e) => {
                      const value = e.target.value;
                      this.props.setParentState({
                        direct_client_rule: value,
                      });
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="item">
              <div className="row">
                <div className="col-3 pt-3">Федеральная площадка</div>
                <div className="col-9">
                  <input
                    name="federal"
                    type="checkbox"
                    checked={this.props.parentState.data.federal === 1}
                    onChange={(e) => {
                      const value = e.target.checked ? 1 : 0;
                      this.props.setParentState({
                        federal: value,
                      });
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="item">
              <div className="row">
                <div className="col-3 pt-3">Не РФ</div>
                <div className="col-9">
                  <input
                    name="federal"
                    type="checkbox"
                    checked={this.props.parentState.data.isForeign === 1}
                    onChange={(e) => {
                      const value = e.target.checked ? 1 : 0;
                      this.props.setParentState({
                        isForeign: value,
                      });
                    }}
                  />
                </div>
              </div>
            </div>
            {this.props.parentState.data.object_uid ? (
              <div className="item">
                <div className="row">
                  <div className="col-3 pt-3">OBJECT UID</div>
                  <div className="col-9">
                    <input
                      name="object_uid"
                      className="form-control"
                      type="text"
                      defaultValue={this.props.parentState.data.object_uid}
                      disabled={true}
                    />
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}

export default TabBarItem(Info);
