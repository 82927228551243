import React, { Component } from "react";
import { Link } from "react-router-dom";
import ListItem from "./MediaPlanListItem";
import { graphql } from "react-apollo";
import Header from "./../../../template/Header";
import { selectQuery } from "../../Components/Briefing/gql/query";
import AsyncSelect from "react-select/lib/Async";
import { ApolloConsumer } from "react-apollo";
import { RN_MP_lIST_PAGER } from "./../../GraphQL/RNqueries";

const queryString = require("query-string");

class List extends Component {
  state = {
    page: 1,
    count: 10,
    offset: 0,
    filter: [],
  };

  update(pageNumber, filter = []) {
    if (filter.length) {
      this.props.update(
        (pageNumber - 1) * this.state.count,
        this.state.count,
        filter
      );
    } else {
      this.props.update((pageNumber - 1) * this.state.count, this.state.count);
    }
  }

  static getDerivedStateFromProps(props, oldState) {
    let updateForState = {};
    if (!oldState.updated || props.pageInfo) {
      updateForState = {
        count:
          props.pageInfo.count === oldState.count
            ? +props.pageInfo.count
            : +oldState.count,
        total: +props.pageInfo.total,
        offset: (oldState.page - 1) * oldState.count,
        page: oldState.offset / oldState.count + 1,
        pages: Math.ceil(props.pageInfo.total / oldState.count),
        updated: true,
      };
      updateForState.page =
        +queryString.parse(document.location.hash).page || 1;
      updateForState.count =
        +queryString.parse(document.location.hash).count || 10;
      updateForState.pages = Math.ceil(
        props.pageInfo.total / updateForState.count
      );
      updateForState.offset = (updateForState.page - 1) * updateForState.count;
      props.update(
        updateForState.offset,
        updateForState.count,
        oldState.filter
      );
    }

    return updateForState;
  }

  renderButtons(count) {
    let array = [];
    let numbers = Math.floor(count / 2);

    let start = this.state.page - numbers;
    let end = this.state.page + numbers;

    for (let i = start - 1; i <= end + 1; i++) {
      if (i <= start - 1 && i > 1) {
        array.push("...");
      } else if (i === end + 1 && i < this.state.pages) {
        array.push("...");
      } else if (i >= this.state.pages) {
        continue;
      } else if (i <= 1) {
        continue;
      } else {
        array.push(i);
      }
    }

    let array_to_show = [1, ...array, +this.state.pages];

    return (
      <>
        {this.state.pages > 1 &&
          array_to_show.map((item, i) => {
            return (
              <React.Fragment key={`${item}${i}`}>
                {item !== "..." ? (
                  <li
                    className={
                      this.state.page === item
                        ? "page-item active"
                        : "page-item"
                    }
                  >
                    <Link
                      className="page-link"
                      onClick={() => {
                        this.update(item);
                        this.setState({
                          page: item,
                        });
                      }}
                      to={`/mediaplan/list/#page=${item}&count=${this.state.count}`}
                    >
                      {item}
                    </Link>
                  </li>
                ) : (
                  <li className="page-item disabled">
                    <span className="page-link">...</span>
                  </li>
                )}
              </React.Fragment>
            );
          })}
      </>
    );
  }

  handleReset() {
    this.setState(
      {
        ...this.state,
        manager: null,
        responsible: null,
        status: null,
        filter: [],
      },
      this.props.update(this.state.page * this.state.count, this.state.count)
    );
  }

  render() {
    return (
      <>
        <Header {...this.props} {...this.state} />
        <section className="content">
          <div className="container-fluid mediaplans-list-block">
            <div className="table-container">
              <div
                className="plans-list"
                style={{
                  maxWidth: "1400px",
                  margin: "0 auto",
                }}
              >
                <h3 className="mt-3 mb-1">Список медиапланов</h3>
                <div className="row mb-3">
                  <div className="col-3">
                    фильтровать по постановщику
                    <br />
                    <ApolloConsumer>
                      {(client) => (
                        <AsyncSelect
                          name="manager_id"
                          value={this.state.manager ? this.state.manager : null}
                          loadOptions={(inputValue) =>
                            selectQuery(
                              inputValue,
                              "RefEmployeeList",
                              client,
                              "manager"
                            )
                          }
                          placeholder={"Выбрать"}
                          onChange={(manager) => {
                            const value = manager.value;
                            let filter = this.state.filter.filter(
                              (item) => item.field !== "manager_id"
                            );
                            filter = [
                              ...filter,
                              {
                                field: "manager_id",
                                operator: "EQ",
                                values: [value],
                              },
                            ];

                            this.setState((state) => ({
                              ...state,
                              manager,
                              filter,
                            }));
                          }}
                        />
                      )}
                    </ApolloConsumer>
                  </div>
                  <div className="col-3">
                    фильтровать по исполнителю
                    <br />
                    <ApolloConsumer>
                      {(client) => (
                        <AsyncSelect
                          name="responsible_id"
                          value={
                            this.state.responsible
                              ? this.state.responsible
                              : null
                          }
                          placeholder={"Выбрать"}
                          loadOptions={(inputValue) =>
                            selectQuery(
                              inputValue,
                              "RefEmployeeList",
                              client,
                              "manager"
                            )
                          }
                          onChange={(responsible) => {
                            const value = responsible.value;
                            let filter = this.state.filter.filter(
                              (item) => item.field !== "responsible_id"
                            );
                            filter = [
                              ...filter,
                              {
                                field: "responsible_id",
                                operator: "EQ",
                                values: [value],
                              },
                            ];

                            this.setState((state) => ({
                              ...state,
                              responsible,
                              filter,
                            }));
                          }}
                        />
                      )}
                    </ApolloConsumer>
                  </div>
                  <div className="col-3">
                    <label>
                      фильтровать по статусу
                      <select
                        name="status"
                        value={this.state.status}
                        className="form-control"
                        onChange={(e) => {
                          const status = e.target.value;

                          let filter = this.state.filter.filter(
                            (item) => item.field !== "status"
                          );
                          filter = [
                            ...filter,
                            {
                              field: "status",
                              operator: "EQ",
                              values: [status],
                            },
                          ];

                          this.setState((state) => ({
                            ...state,
                            status,
                            filter,
                          }));
                        }}
                      >
                        <option value="hidden">Выбрать</option>
                        <option value="0">В работе</option>
                        <option value="4">Отправлен на согласование</option>
                        <option value="2">Возвращен с замечаниями</option>
                        <option value="6">
                          Согласован и отправлен в запуск
                        </option>
                        <option value="7">Отчет</option>
                        <option value="5">Отменен</option>
                      </select>
                    </label>
                  </div>
                  <div className="col-3 mt-4">
                    <div className="btn-groupt">
                      <button
                        className="btn btn-secondary"
                        onClick={() => {
                          this.handleReset();
                        }}
                      >
                        Сбросить
                      </button>
                    </div>
                  </div>
                  <div className="col-3"></div>
                </div>

                {this.props.mpList.map((item) => (
                  <ListItem
                    {...this.props}
                    {...this.state}
                    key={item.id}
                    data={item}
                  />
                ))}
              </div>
              <nav
                className="text-center mt-5 mb-5"
                aria-label="Page navigation example"
              >
                <ul className="pagination justify-content-center">
                  {this.state.total > this.state.count
                    ? this.renderButtons(3)
                    : null}
                </ul>
              </nav>
            </div>
          </div>
        </section>
        <div className="small-screen">
          <div className="container-fluid mt-5">
            <h1>
              На данном устройстве работа не возможна. Используйте устройство с
              более широким экраном.
            </h1>
          </div>
        </div>
      </>
    );
  }
}

const RnMediaplanList = graphql(RN_MP_lIST_PAGER, {
  options: (props) => ({
    variables: {
      params: {
        offset: 0,
        count: 10,
      },
    },
  }),
  props: ({ data }) => {
    return {
      loading: data.loading,
      error: data.error,
      mpList: data.RnMpListPager ? data.RnMpListPager.data : null,
      pageInfo: data.RnMpListPager ? data.RnMpListPager.pageInfo : null,
      src_data: data,

      update: (offset, count, filter = []) => {
        if (filter.length) {
          data.fetchMore({
            variables: {
              params: {
                count: count,
                offset: offset,
                filter: [...filter],
              },
            },
            updateQuery: (prev, { fetchMoreResult }) => {
              return {
                RnMpListPager: fetchMoreResult.RnMpListPager,
              };
            },
          });
        } else {
          data.fetchMore({
            variables: {
              params: {
                count: count,
                offset: offset,
              },
            },
            updateQuery: (prev, { fetchMoreResult }) => {
              return {
                RnMpListPager: fetchMoreResult.RnMpListPager,
              };
            },
          });
        }
      },
    };
  },
})((props) => {
  if (props.loading) return null;
  if (props.error) return null;
  if (props.mpList == null) {
    console.log("loading bad result", props);
    return null;
  }

  return <List {...props} />;
});

export default RnMediaplanList;
