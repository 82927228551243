import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import {
  Box,
  Button,
  IconButton,
  Modal,
  TextField,
  Typography,
} from "@material-ui/core";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import DeleteIcon from "@material-ui/icons/Delete";
import SaveIcon from "@material-ui/icons/Save";
import CloseIcon from "@material-ui/icons/Close";
import { useUploadInventoryFileMutation } from "../../graphql/types";

interface IFileUploadProps {
  client: any;
  inventoryID?: number | string | undefined;
  isInventory?: boolean;
  onChange?: (file: {
    inventoryID: number | undefined | string;
    file: any;
    srcFileName?: string;
    comment?: string;
    buttonLabel?: string;
  }) => void;
  type?: number;
  buttonLabel?: string;
  imagesOnly?: boolean;
}

export function FileUpload({
  client,
  inventoryID,
  onChange,
  isInventory = false,
  type = 1,
  buttonLabel = "Загрузить",
  imagesOnly = true,
}: IFileUploadProps) {
  const [open, setOpen] = React.useState(false);
  const [preview, setPreview] = React.useState(undefined);
  const [file, setFile] = React.useState(undefined);
  const [fileName, setFileName] = React.useState<string | undefined>(undefined);
  const [comment, setComment] = React.useState<string | undefined>(undefined);

  function uploadFile(file: any) {
    //@ts-ignore
    setPreview(URL.createObjectURL(file));
    setFile(file);
  }

  function removeFile() {
    setFile(undefined);
    setPreview(undefined);
  }

  function close() {
    setPreview(undefined);
    setFile(undefined);
    setFileName(undefined);
    setComment(undefined);
    setOpen(false);
  }

  const useStyles = makeStyles((theme) => ({
    modalTitle: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    paper: {
      position: "absolute",
      width: 800,
      top: "15%",
      left: "50%",
      transform: "translateX(-50%)",
      backgroundColor: theme.palette.background.paper,
      borderRadius: 3,
      padding: theme.spacing(2, 4, 3),
    },
    preview: {
      overflowX: "auto",
      maxHeight: 400,
    },
    button: {
      marginRight: theme.spacing(1),
    },
  }));

  const classes = useStyles();

  const [upload] = useUploadInventoryFileMutation({
    client,
    variables: {
      file,
      inventoryID: inventoryID ? +inventoryID : 0,
      srcFileName: fileName,
      comment,
      type,
    },
    refetchQueries: ["RnMpItem"],
  });

  return (
    <>
      <Button
        variant={"contained"}
        component="label"
        size="small"
        onClick={() => setOpen(!open)}
      >
        {buttonLabel ?? "Загрузить"}
      </Button>

      <Modal
        open={open}
        onClose={() => {
          close();
        }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className={classes.paper}>
          <div className={classes.modalTitle}>
            <Typography variant="h6">Загрузка файла</Typography>
            <IconButton onClick={close}>
              <CloseIcon />
            </IconButton>
          </div>

          <Box my={2}>
            <TextField
              size="small"
              fullWidth={true}
              label="Имя файла"
              value={fileName}
              onChange={(e) => {
                setFileName(e.target.value);
              }}
              variant="outlined"
              helperText={"По умолчанию будет подставлено имя из файла"}
            />
          </Box>
          <Box my={2}>
            <TextField
              size={"small"}
              fullWidth={true}
              label="Комментарий к файлу"
              variant="outlined"
              multiline
              value={comment}
              onChange={(e) => {
                setComment(e.target.value);
              }}
            />
          </Box>

          <Button
            size="small"
            variant={"contained"}
            component="label"
            disabled={Boolean(preview)}
            className={classes.button}
            startIcon={<CloudUploadIcon />}
          >
            <input
              type="file"
              id="input-file"
              style={{
                display: "none",
              }}
              disabled={Boolean(preview)}
              accept={
                imagesOnly
                  ? "image/x-png,image/gif,image/jpeg"
                  : "image/x-png,image/gif,image/jpeg, .pdf, .xlsx, .xls"
              }
              onChange={(e) => {
                if (e?.target?.files?.length) {
                  uploadFile(e?.target?.files[0]);
                }

                e.target.value = "";
              }}
            />
            Выбрать файл
          </Button>
          <Button
            size="small"
            disabled={Boolean(!preview)}
            variant={"contained"}
            component="label"
            className={classes.button}
            color="secondary"
            startIcon={<DeleteIcon />}
            onClick={removeFile}
          >
            Удалить
          </Button>

          <Button
            variant="contained"
            color="primary"
            size="small"
            className={classes.button}
            startIcon={<SaveIcon />}
            disabled={Boolean(!preview)}
            onClick={() => {
              if (isInventory && onChange)
                onChange({
                  inventoryID: inventoryID,
                  srcFileName: fileName,
                  comment,
                  file,
                });
              else upload();
              close();
            }}
          >
            {isInventory ? "Прикрепить" : "Загрузить"}
          </Button>

          {preview && (
            <>
              <Box mt={2}>
                <Typography variant="caption" color="textSecondary">
                  Предпросмотр
                </Typography>
              </Box>

              <Box className={classes.preview}>
                <img
                  style={{
                    width: "100%",
                  }}
                  //@ts-ignore
                  src={preview}
                  alt=""
                />
              </Box>
            </>
          )}
        </div>
      </Modal>
    </>
  );
}
