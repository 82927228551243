import React from "react";
import Header from "../template/Header";
import { ApolloConsumer } from "react-apollo";
import { useQuery } from "@apollo/react-hooks";
import { GET_PROVIDER_PAYLOAD_LIST } from "./operatorRequest/gql";

//todo сделать интерфейсы
function OperatorRequests(props: any) {
  const { data } = useQuery(GET_PROVIDER_PAYLOAD_LIST, {
    client: props.client,
  });

  function switchStatus(status: number): string {
    switch (status) {
      case 1:
        return "Отправлено";
      case 2:
        return "Получен ответ";
      default:
        return "Отправлено";
    }
  }

  return (
    <>
      <Header />
      <div className="content">
        <div className="mt-3 row">
          <div className="col-12">
            <div
              style={{
                height: "768px",
                overflow: "auto",
              }}
            >
              <label className="ml-1">
                Количество запросов:{" "}
                {data && data.RN.providerPayloadItemList.length}
              </label>

              <label className="ml-2">
                Количество ответов:{" "}
                {data &&
                  data.RN.providerPayloadItemList.filter(
                    (i: any) => i.status === 2
                  ).length}
              </label>

              <table
                className="w-100 table table-striped"
                style={{
                  tableLayout: "fixed",
                }}
              >
                <thead>
                  <tr>
                    <th>Площадка</th>
                    <th>Город</th>
                    <th>Email</th>
                    <th>Дата</th>
                    <th>Статус</th>
                  </tr>
                </thead>
                <tbody>
                  {data &&
                    data.RN.providerPayloadItemList.map(
                      (item: any, index: number) => (
                        <tr key={index}>
                          <td>{item.platformName}</td>
                          <td>{item.cityName}</td>
                          <td>{item.email}</td>
                          <td>{new Date(item.date).toLocaleDateString()}</td>
                          <td>{switchStatus(+item.status)}</td>
                        </tr>
                      )
                    )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const OperatorRequestContainer = (props: any) => {
  return (
    <ApolloConsumer>
      {(client) => <OperatorRequests {...props} client={client} />}
    </ApolloConsumer>
  );
};

export default OperatorRequestContainer;
