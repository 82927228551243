import React, { Component } from "react";
import { RN_PLATFORM_LIST_REPORT } from "./../../GraphQL/RNqueries";
import { graphql } from "react-apollo";

class Report extends Component {
  render() {
    let no_price_counter = 0;
    let price_counter = 0;

    this.props.platformList.forEach(item => {
      if (!item.InventoryItems.length) {
        no_price_counter++;
      } else {
        price_counter++;
      }
    });

    return (
      <div className="content">
        <table className="table table-striped" style={{ marginBottom: "0rem" }}>
          <thead>
            <tr className="row">
              <th className="col-1">Порядковый номер</th>
              <th className="col">Адрес площадки</th>
              <th className="col">Прайс заполнен</th>
              <th className="col">Тех требования заполнены</th>
              <th className="col">Контакты заполнены</th>
            </tr>
          </thead>
        </table>
        <div className="container-fluid platforms-list">
          <table className="table table-striped">
            <tbody>
              {this.props.platformList.map((item, index) => (
                <tr key={item.id} className="row">
                  <td
                    style={{
                      padding: "5px"
                    }}
                    className="col-1"
                  >
                    {index + 1}
                  </td>
                  <td
                    style={{
                      padding: "5px"
                    }}
                    className="col"
                  >
                    {item.name}
                  </td>
                  <td
                    style={{
                      padding: "5px"
                    }}
                    className="col"
                  >
                    {item.InventoryItems.length ? (
                      <>да / {item.InventoryItems.length}</>
                    ) : (
                      "нет"
                    )}
                  </td>
                  <td
                    style={{
                      padding: "5px"
                    }}
                    className="col"
                  >
                    {item.Price && item.Price.comment_2 ? "да" : "нет"}
                  </td>
                  <td
                    style={{
                      padding: "5px"
                    }}
                    className="col"
                  >
                    {item.ContactsItems.length ? (
                      <>да / {item.ContactsItems.length}</>
                    ) : (
                      "нет"
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
            <p className="text-danger mt-3">
              Кол-во площадок, у которых не заполнен прайс: {no_price_counter}
            </p>
            <p className="text-success">
              Кол-во площадок, у которых заполнен прайс: {price_counter}
            </p>
          </table>
        </div>
      </div>
    );
  }
}

const ReportContainer = graphql(RN_PLATFORM_LIST_REPORT, {
  options: props => ({
    variables: {
      params: {
        offset: 0,
        count: 999,
        filter: [
          {
            field: "hidden",
            operator: "EQ",
            values: [0]
          }
        ]
      }
    }
  }),
  props: ({ data }) => {
    return {
      loading: data.loading,
      error: data.error,
      platformList: data.RnPlatformList ? data.RnPlatformList.data : null,
      pageInfo: data.RnPlatformList ? data.RnPlatformList.pageInfo : null
    };
  }
})(props => {
  if (props.loading) return "Загрузка контент-отчета";
  if (props.error) return null;
  if (props.platformList == null) {
    console.log("loading bad result", props);
    return null;
  }

  return <Report {...props} />;
});

export default ReportContainer;
