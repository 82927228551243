import React, { Component } from "react";

class Coefficient extends Component {
  state = {
    CoefficientItems: [],
    loaded: false,
  };

  static getDerivedStateFromProps(props, oldState) {
    let updateForState = {};
    if (!oldState.loaded) {
      updateForState = {
        CoefficientItems: props.parentProps.platform
          ? props.parentProps.platform.CoefficientItems
          : [],
        loaded: true,
      };
    }
    return updateForState;
  }

  componentDidMount() {
    this.setState((state) => ({
      _updateDate: new Date().toISOString(),
    }));
  }

  handleChange(list) {
    list = list.map((item) => ({
      month: item.month,
      coefficient: item.coefficient,
    }));
    this.props.GetCoefficientItems(list);
  }

  render() {
    return (
      <div className="tab-content" id="myTabContent">
        <div
          className="tab-pane fade show active"
          id="profile2"
          role="tabcard"
          aria-labelledby="profile-tab"
        >
          <div className="top-button mt-2 mb-4">
            <button
              type="button"
              className="pull-right remove-item btn btn-success"
              onClick={() => {
                let list = this.state.CoefficientItems;

                list.push({
                  updateDate: this.state._updateDate,
                  id: new Date().getTime(),
                });
                this.setState(
                  {
                    CoefficientItems: list,
                  },
                  this.handleChange(list)
                );
              }}
            >
              Добавить коэффициент
            </button>
          </div>

          <div className="row mb-3">
            <div className="col-2">Месяц</div>
            <div className="col">Коэффициент</div>
          </div>

          {this.state.CoefficientItems.map((item) => (
            <div key={item.id} className="mb-1">
              <div className="row">
                <div className="col-2">
                  <div className="form-group">
                    <select
                      className="form-control"
                      name="month"
                      value={item.month !== null ? item.month : null}
                      onChange={(e) => {
                        const value = +e.target.value;
                        let list = this.state.CoefficientItems;

                        let index = list.findIndex((i) => i.id === item.id);
                        list[index] = {
                          ...list[index],
                          month: value,
                          updateDate: this.state._updateDate,
                        };

                        this.setState(
                          {
                            CoefficientItems: list,
                          },
                          this.handleChange(list)
                        );
                      }}
                    >
                      <option value="" hidden>
                        Выбрать...
                      </option>
                      <option value="0">Январь</option>
                      <option value="1">Февраль</option>
                      <option value="2">Март</option>
                      <option value="3">Апрель</option>
                      <option value="4">Май</option>
                      <option value="5">Июнь</option>
                      <option value="6">Июль</option>
                      <option value="7">Август</option>
                      <option value="8">Сентябрь</option>
                      <option value="9">Октябрь</option>
                      <option value="10">Ноябрь</option>
                      <option value="11">Декабрь</option>
                      <option value="12">Год</option>
                    </select>
                  </div>
                </div>
                <div className="col-1">
                  <div className="form-group">
                    <input
                      type="number"
                      name="coefficient"
                      className="form-control"
                      value={item.coefficient !== null ? item.coefficient : ""}
                      onChange={({ target: { value } }) => {
                        let list = this.state.CoefficientItems;
                        let index = list.findIndex((i) => i.id === item.id);
                        list[index] = {
                          ...list[index],
                          coefficient: value === "" ? null : value,
                          updateDate: this.state._updateDate,
                        };

                        this.setState(
                          {
                            CoefficientItems: list,
                          },
                          this.handleChange(list)
                        );
                      }}
                    />
                  </div>
                </div>
                <div
                  className="col-1"
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <button
                    type="button"
                    className="pull-right remove-item btn btn-danger btn-block"
                    onClick={() => {
                      let list = this.state.CoefficientItems;
                      list = list.filter((i) => i.id !== item.id);
                      this.setState(
                        {
                          CoefficientItems: list,
                        },
                        this.handleChange(list)
                      );
                    }}
                  >
                    удалить
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default Coefficient;
