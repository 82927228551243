import React from "react";
import { FileUpload } from "../../fileUpload/fileUpload";
import {
  Box,
  Button,
  IconButton,
  Modal,
  TextField,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import {
  useUpdateInventoryFieldMutation,
  useUploadInventoryFileMutation,
} from "../../../graphql/types";

interface IRequirementsProps {
  inventoryID: number;
  client: any;
}

export function Requirements({ inventoryID, client }: IRequirementsProps) {
  const [show, setShow] = React.useState(false);

  const useStyles = makeStyles((theme) => ({
    modalTitle: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    paper: {
      position: "absolute",
      width: 800,
      top: "15%",
      left: "50%",
      transform: "translateX(-50%)",
      backgroundColor: theme.palette.background.paper,
      borderRadius: 3,
      padding: theme.spacing(2, 4, 3),
    },
    preview: {
      overflowX: "auto",
      maxHeight: 400,
    },
    button: {
      marginRight: theme.spacing(1),
    },
    img: {
      minHeight: 200,
      width: "100%",
    },
  }));

  const classes = useStyles();
  const [file, setFile] = React.useState<any>(null);
  const [description, setDescription] = React.useState<string | undefined>(
    undefined
  );

  const [update] = useUpdateInventoryFieldMutation({
    client,
  });
  const [upload] = useUploadInventoryFileMutation({
    client,
  });

  return (
    <>
      <Button
        variant={"contained"}
        component="label"
        size="small"
        onClick={() => {
          setShow(!show);
        }}
      >
        Уникальное ТТ
      </Button>
      <Modal
        open={show}
        onClose={() => {
          setShow(!show);
        }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className={classes.paper}>
          <div className={classes.modalTitle}>
            <Typography variant="h6">
              Уникальное техническое требование
            </Typography>
            <IconButton
              onClick={() => {
                setShow(false);
              }}
            >
              <CloseIcon />
            </IconButton>
          </div>
          <Box my={2}>
            <TextField
              size={"small"}
              fullWidth={true}
              label="Текст ТТ"
              variant="outlined"
              multiline
              value={description}
              onChange={(e) => {
                setDescription(e.target.value);
              }}
            />
          </Box>

          {file ? (
            <p>
              Файл:
              {file.name}{" "}
              <IconButton
                onClick={() => {
                  setFile(null);
                }}
              >
                <CloseIcon />
              </IconButton>
            </p>
          ) : (
            <FileUpload
              client={client}
              isInventory={true}
              imagesOnly={false}
              onChange={({ file }) => {
                if (file) {
                  setFile(file);
                }
              }}
              buttonLabel="Прикрепить файл ТТ"
            />
          )}

          {file || description ? (
            <Box my={2}>
              <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={() => {
                  if (description) {
                    update({
                      variables: {
                        inventoryID,
                        fieldValue: description,
                        fieldName: "requirements",
                      },
                    });
                  }

                  if (file) {
                    upload({
                      variables: {
                        inventoryID,
                        srcFileName: file.srcFileName,
                        comment: file.comment,
                        type: 2,
                        file,
                      },
                    });
                  }

                  setShow(false);
                }}
              >
                Сохранить
              </Button>
            </Box>
          ) : null}
        </div>
      </Modal>
    </>
  );
}
