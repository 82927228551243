import React from "react";
import { GeoPropsType } from "../../../../types/index.types";

export function Geo({
  isForeign,
  cityList,
  citySelected,
  isFederal,
  disabled,
  onChange,
  geo,
}: GeoPropsType) {
  const [isEditable, setEditable] = React.useState(!!geo);

  React.useEffect(() => {
    if ((!citySelected || citySelected === "") && cityList?.length) {
      onChange(cityList[0].AOGUID, "city");
    }
  }, [citySelected]);

  return (
    <>
      {isEditable ? (
        <input
          defaultValue={geo ? geo : ""}
          disabled={disabled}
          className="mb-1 form-control"
          type="text"
          onChange={(e) => {
            const geo = e.target.value;
            onChange(geo, "geo");
          }}
        />
      ) : (
        <>
          {isForeign ? (
            "Не РФ"
          ) : cityList && cityList.length ? (
            <>
              <select
                disabled={disabled}
                value={citySelected ? citySelected : ""}
                className="form-control"
                onChange={(e) => {
                  const geo = e.target.value;
                  onChange(geo, "city");
                }}
              >
                {" "}
                <option value=""></option>
                {cityList.map((city) => (
                  <option key={city.AOGUID} value={city.AOGUID}>
                    {city.FORMALNAME}
                  </option>
                ))}
              </select>
            </>
          ) : isFederal ? (
            "РФ"
          ) : (
            "Не указано"
          )}
        </>
      )}

      <a
        className="d-block"
        href="#"
        onClick={(e) => {
          e.preventDefault();
          setEditable(!isEditable);
        }}
      >
        {isEditable ? "Выбрать" : "Редактировать"}
      </a>
    </>
  );
}
