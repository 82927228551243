import React, { Component } from "react";
import DiscountItem from "./DiscountItem";
const moment = require("moment/min/moment-with-locales.js");

class Discount extends Component {
  state = {};

  static getDerivedStateFromProps(props, oldState) {
    let updateForState = {};
    if (!oldState.loaded) {
      updateForState = {
        DiscountItems: props.parentProps.platform
          ? props.parentProps.platform.DiscountItems
          : [],
        _discountItems: props.parentState._discountItems
          ? props.parentState._discountItems
          : [],
        loaded: true,
      };
    }
    return updateForState;
  }

  componentDidMount() {
    this.setState((state) => ({
      ...state,
      _updateDate: moment().format(),
    }));
  }

  render() {
    return (
      <div className="tab-content">
        <div
          className="tab-pane fade show active"
          aria-labelledby="profile-tab"
        >
          <div className="top-button mt-2 mb-4">
            <button
              type="button"
              className="pull-right remove-item btn btn-success"
              onClick={() => {
                let list = this.state.DiscountItems;
                let obj = {
                  updateDate: this.state._updateDate,
                  authorId: this.props.parentProps?.user?.id,
                  inventory_type: "all",
                  subject: 0,
                  accrualBasis: 3,
                };
                list.push(obj);
                this.setState({
                  DiscountItems: list,
                  _discountItems: list,
                });

                this.props.GetDiscountItems(this.state.DiscountItems);
              }}
            >
              Добавить скидку
            </button>
          </div>
          {this.state.DiscountItems.map((item, index) => (
            <DiscountItem
              key={`discountItem#${index}`}
              indexKey={index}
              discountItem={item}
              parentState={this.state}
              parentProps={this.props}
              currentUser={this.props.parentProps?.user}
              setParentState={(newState) => {
                this.setState(() => ({
                  ...newState,
                }));
              }}
            />
          ))}
        </div>
      </div>
    );
  }
}

export default Discount;
