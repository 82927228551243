import React from "react";
import styled from "styled-components";
import { Query, Mutation } from "react-apollo";
import Select from "react-select";
import gql from "graphql-tag";
import { RN_MP_COPY } from "./mutation";

const Modal = props => {
  const [state, setState] = React.useState({});

  const filter = [
    {
      field: "mp",
      operator: "EQ",
      values: [0]
    }
  ];

  if (
    props.data.tender &&
    props.data.Briefing &&
    props.data.Briefing.partner_id
  ) {
    filter.push({
      field: "partner_id",
      operator: "EQ",
      values: [props.data.Briefing.partner_id]
    });
  }

  const PopUp = styled.div`
    min-height: 120px;
    min-width: 620px;
    position: absolute;
    background: #fff;
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
    left: 50%;
    top: 25%;
    transform: translateX(-50%);
    z-index: 1;
  `;

  return (
    <PopUp className="p-3">
      <div className="row">
        <div className="col-12 text-right">
          <a href="#" onClick={props.onClose}>
            Закрыть
          </a>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-3">Брифинг</div>
        <div className="col-9">
          <Query
            query={gql`
              query RnBriefingList($params: ParamsInput) {
                RnBriefingList(params: $params) {
                  id
                  name
                  partner_id
                  Manager {
                    id
                    nameFull
                  }
                  Partner {
                    id
                    name
                  }
                  Brand {
                    id
                    name
                  }
                  products
                  entryDate
                }
              }
            `}
            variables={{
              params: {
                offset: 0,
                count: 50,
                filter
              }
            }}
          >
            {({ loading, error, data }) => {
              if (loading) return "Загрузка...";
              if (error) return "Ошибка";

              const RnBriefingList = data.RnBriefingList.map(item => ({
                label: `${item.name ? item.name : ""} ${
                  item.Brand ? item.Brand.name : ""
                }`,
                value: item.id
              }));

              return (
                <Select
                  placeholder={""}
                  options={RnBriefingList}
                  value={state.briefing}
                  onChange={briefing => {
                    const briefing_id = briefing.value;
                    setState({
                      briefing_id,
                      briefing
                    });
                  }}
                />
              );
            }}
          </Query>
        </div>
      </div>
      <div className="row mt-5">
        <div className="col">
          <Mutation mutation={RN_MP_COPY}>
            {(copy, { data }) => (
              <button
                className="btn btn-secondary"
                onClick={() => {
                  copy({
                    variables: {
                      mp_id: props.data.id,
                      date: new Date().toISOString(),
                      user_id: props.src_data.me.comon_id,
                      briefing_id: state.briefing_id
                    },
                    refetchQueries: ["RnMpListPager"]
                  }).then(res => {
                    props.onClose();
                  });
                }}
              >
                Копировать
              </button>
            )}
          </Mutation>
        </div>
      </div>
    </PopUp>
  );
};

export default Modal;
