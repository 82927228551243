import React from "react";
import { RnLog } from "../../graphql/types";
import { getPaymentDelayName } from "../../RN/Components/MediaPlan/utils/getPaymentDelayName";

export function Content({ itemLogList }: { itemLogList: any }) {
  return (
    <>
      <tr>
        <td className="bg-primary text-white">Клиент</td>
        <td className="bg-primary text-white">Гео</td>
        <td className="bg-primary text-white">Площадка</td>
        <td className="bg-primary text-white">Вид рекламы</td>
        <td className="bg-primary text-white">Формат</td>
        <td className="bg-primary text-white">Таргетинг</td>
        <td className="bg-primary text-white">Период</td>
        <td className="bg-primary text-white">Единица закупа</td>
        <td className="bg-primary text-white">Количество</td>
        <td className="bg-primary text-white">
          Стоимость за 1-цу по прайс-листу без НДС
        </td>
        <td className="bg-primary text-white">Скидка</td>
        <td className="bg-primary text-white">Важно</td>
        <td className="bg-primary text-white">
          Стоимость за период после скидки без НДС
        </td>
        <td className="bg-primary text-white">Налогообложение площадки</td>
        <td className="bg-primary text-white">Налогообложение клиента</td>
        <td className="bg-primary text-white">
          Итого клиент, включая все налоги
        </td>
        <td className="bg-primary text-white">CTR, %</td>
        <td className="bg-primary text-white">Клики</td>
        <td className="bg-primary text-white">Показы</td>
        <td className="bg-primary text-white">CPM до НДС</td>
        <td className="bg-primary text-white">CPC прогноз до НДС</td>
        <td className="bg-primary text-white">Скидка ЗАКУП,%</td>
        <td className="bg-primary text-white">
          Стоимость за период ЗАКУП без НДС
        </td>
        <td className="bg-primary text-white">Рентабельность</td>
        <td className="bg-primary text-white">Маржа без НДС</td>
        <td className="bg-primary text-white">Поставщик</td>
        <td className="bg-primary text-white">Подтверждение брони</td>
        <td className="bg-primary text-white">Запрос</td>
        <td className="bg-primary text-white">Отсрочка поставщика базовая</td>
        <td className="bg-primary text-white">Отсрочка поставщика факт</td>
        <td className="bg-primary text-white">Отсрочка клиент</td>
        <td className="bg-primary text-white">Тех требования и фото</td>
        <td className="bg-primary text-white">Замечания</td>
      </tr>

      {itemLogList?.map((item: RnLog) => {
        try {
          let json =
            //@ts-ignore
            item?.data
              .replace(/\n/g, " ")
              .replace(/\s\s+/g, " ")
              .replace(/\\"/, "");

          const fields = item?.data && JSON.parse(json);

          return (
            <tr key={String(item?.id)}>
              <td>{item.partnerName ? item.partnerName : null}</td>
              <td>{fields.geo ?? null}</td>
              <td>{item.platformName ?? null}</td>
              <td>{item.inventoryName ?? null}</td>
              <td>{fields.format ?? null}</td>
              <td>{fields.targeting ?? null}</td>
              <td>
                {fields.startDate
                  ? new Date(fields.startDate).toLocaleDateString()
                  : null}
                -
                {fields.endDate
                  ? new Date(fields.endDate).toLocaleDateString()
                  : null}
              </td>
              <td>{item.priceType ?? null}</td>
              <td>{fields.purchase_count ?? null}</td>
              <td>{fields.unitCostNoVat ?? null}</td>
              <td>{fields.discount ?? null}</td>
              <td>{item.info ?? null}</td>
              <td>{fields.totalCostNoVat ?? null}</td>
              <td>{item.vendorVat ?? null}</td>
              <td>{item.customerVat ?? null}</td>
              <td>{fields.totalCostVatDiscount ?? null}</td>
              <td>{fields.rCTR ?? null}</td>
              <td>{fields.rClicks ?? null}</td>
              <td>{fields.rImpressions ?? null}</td>
              <td>{fields.cpm ?? null}</td>
              <td>{fields.cpc ?? null}</td>
              <td>{fields.discount_purchase ?? null}</td>
              <td>{fields.totalCostPurchaseDiscount ?? null}</td>
              <td>{fields.profitability ?? null}</td>
              <td>{fields.margin ?? null}</td>
              <td>{item.partnerName ?? null}</td>
              <td>
                {fields.status && +fields.status == 1
                  ? "Подтверждено"
                  : "Не подтверждено"}
              </td>
              <td>
                {fields.price_query_date
                  ? new Date(fields.dateStart).toLocaleDateString()
                  : null}
              </td>
              <td>
                {item.vendorPaymentDelay &&
                  getPaymentDelayName(+item.vendorPaymentDelay)}{" "}
                {item.vendorPaymentDelayValue}
              </td>
              <td>
                {item.factPaymentDelay &&
                  getPaymentDelayName(+item.factPaymentDelay)}{" "}
                {item.factPaymentDelayValue}
              </td>
              <td>
                {item.customerPaymentDelay &&
                  getPaymentDelayName(+item.customerPaymentDelay)}{" "}
                {item.customerPaymentDelayValue}
              </td>
              <td>{item.requirements ?? null}</td>
              <td>{fields.is_change_comment ?? null}</td>
            </tr>
          );
        } catch (e) {
          return null;
        }
      })}
    </>
  );
}
