import gql from 'graphql-tag';

const RN_BRIEFING_REMOVE =  gql`
    mutation($id: Int) {
        RnBriefingRemove(id: $id) {
            id
        }
    }
`;

export const RN_BRIEFING_CREATE =
    gql`mutation($briefing: RnBriefingInput) {
        RnBriefingCreate(briefing: $briefing) {
            id
        }
    }  
`;

export const RN_BRIEFING_UPDATE =
    gql`mutation($briefing: RnBriefingInput) {
        RnBriefingUpdate(briefing: $briefing) {
            id
            status
            name
            manager_id
            partner_id
            brand_id
            taskId
            task_id
            vat
            no_vat
            tender
            tender_deadline
            target_audience
            banner
            news
            special
            cards
            recommends
            experience_comment
            legal_entity_vat
            legal_entity_vat
            contract_type
            agent_commission_position
            agent_commission_value
            agent_commission_value_type
            federal
            Cities {
                AOGUID
                FORMALNAME
            }
            Responsible {
                id
                nameFull
            }
            Manager {
                id
                nameFull
            }
            Partner {
                id
                name
                nameFull
            }
            Brand {
                id
                name
            }
            UrFace_nds {
                id
                name
            }
            UrFace_nonds {
                id
                name
            }
            products
            advantages
            website
            competitors
            sex
            ageRanges
            audienceComment
            dateStart
            dateEnd
            advTask
            advTaskOther
            kpiType
            kpiComment
            keyMessage
            totalBudget
            totalBudgetFrom
            totalBudgetTo
            totalBudgetAcIn
            entryDate
            deadline
            dateEnd
            clicks
            kpiComment
            keyMessage
            websiteChangeable
            promotionalMaterial
            totalBudget
            totalBudgetAcIn
            agentCommissionPosition
            agentCommission 
            payment_delay
            payment_delay_value
            file {
                id
                name
                path
            }
        }
    }  
`;

export const RN_BRIEFING_UPDATE_FIELD = gql`
    mutation($id: ID, $value: String, $field: String) {
        RnBriefingUpdateField(id: $id, value: $value, field: $field) {
            id
        }
    }
`;

export default RN_BRIEFING_REMOVE;