import { graphql } from "react-apollo";
import { RN_INVENTORY_CREATE } from "./../../../GraphQL/RNmutations";

import InventoryFastCreation from "./InventoryFastCreation";

/*добавление*/
const InventoryFastCreationContainer = graphql(RN_INVENTORY_CREATE, {
  props: ({ mutate }) => {
    return {
      createInventory: mutate
    };
  },
  options: props => ({})
})(InventoryFastCreation);

export default InventoryFastCreationContainer;
