import styled from "styled-components";

const PopUp = styled.div`
  height: 350px;
  min-width: 450px;
  position: absolute;
  background: #fff;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  left: 50%;
  top: 100%;
  transform: translateX(-50%);
  z-index: 9999;
  overflow: auto;
`;

export default PopUp;
