import React from "react";
import { Link } from "react-router-dom";
import { graphql } from "react-apollo";
import { RN_GROUP_REMOVE } from "../../GraphQL/RNmutations";

const GroupListItem = (props) => {
  console.log(props);
  return (
    <tr>
      <td className="align-middle">
        <Link className="text-dark" to={`/group/edit/${props.id}`}>
          {props.name}
        </Link>
      </td>
      <td className="align-middle">{props.access}</td>
      <td className="align-middle">{props.rights}</td>
      <td className="align-middle">
        <Link className="icon-link mr-2" to={`/group/edit/${props.id}`}>
          <i className="icon-edit-action size32"></i>
        </Link>
        <a
          href="#"
          className="icon-link"
          onClick={(e) => {
            e.preventDefault();
            const remove = window.confirm("Вы точно хотите удалить группу?");

            if (remove) {
              props.remove(e, props.id);
            }
          }}
        >
          <i className="icon-remove-action size32"></i>
        </a>
      </td>
    </tr>
  );
};

const GroupListItemContainer = graphql(RN_GROUP_REMOVE, {
  props: ({ mutate }) => {
    return {
      remove: mutate,
    };
  },
  options: (props) => ({
    refetchQueries: ["RnGroupListPager"],
  }),
})(GroupListItem);

export default GroupListItemContainer;
