import React from "react";
import "./targetingList.css";

const TargetingList = (props) => {
  let targetingList = props.parentState.data.targetingList;

  function handleChange(list) {
    list = list.map((item) => ({
      name: item.name,
      coefficient: item.coefficient,
    }));

    props.setParentState({
      targetingList: list,
    });
  }

  return (
    <div className="row mb-3">
      <div className="col-4">Таргетинги</div>
      <div className="col-8">
        <div className="row mb-2">
          <div className="col-6">Наименование</div>
          <div className="col-3">Коэффициент</div>
          <div className="col-3"></div>
        </div>
        {targetingList &&
          targetingList.map((item) => {
            return (
              <div className="row mb-1" key={item.id}>
                <div className="col-6">
                  <input
                    type="text"
                    className="form-control"
                    defaultValue={item.name ? item.name : ""}
                    onChange={(e) => {
                      const value = e.target.value;
                      let list = targetingList;

                      let index = list.findIndex((i) => i.id === item.id);
                      list[index] = {
                        ...list[index],
                        name: value,
                      };

                      handleChange(list);
                    }}
                  />
                </div>
                <div className="col-3">
                  <input
                    id="input_number"
                    type="number"
                    className="form-control"
                    step="0.1"
                    defaultValue={
                      item.coefficient !== null ? item.coefficient : ""
                    }
                    onChange={(e) => {
                      const value = +e.target.value;
                      let list = targetingList;

                      let index = list.findIndex((i) => i.id === item.id);
                      list[index] = {
                        ...list[index],
                        coefficient: value,
                      };

                      handleChange(list);
                    }}
                  />
                </div>
                <div className="col-3">
                  <button
                    className="btn btn-danger btn-sm"
                    onClick={() => {
                      let list = targetingList;
                      list = list.filter((i) => i.id !== item.id);

                      handleChange(list);
                    }}
                  >
                    Удалить
                  </button>
                </div>
              </div>
            );
          })}
      </div>
      <div className="col-4"></div>
      <div className="col-8 mt-3">
        <button
          className="btn btn-success"
          onClick={() => {
            let list = targetingList || [];
            list = [
              ...list,
              {
                id: "#" + new Date().getTime() + 5 + "#",
              },
            ];

            handleChange(list);
          }}
        >
          Добавить
        </button>
      </div>
    </div>
  );
};

export default TargetingList;
