import React from "react";
import { useQuery } from "@apollo/react-hooks";
import { MP_ITEM_REPORT_LIST_PAGER } from "../../GraphQL/RNqueries";
import { ApolloConsumer } from "react-apollo";

const Report = (props) => {
  const [state, setState] = React.useState({
    page: 1,
  });

  const renderButtons = (count, data) => {
    let pages = Math.ceil(data.RN.MpItemReportListPager.pageInfo.total / 10);

    let array = [];
    let numbers = Math.floor(count / 2);

    let start = state.page - numbers;
    let end = state.page + numbers;

    for (let i = start - 1; i <= end + 1; i++) {
      if (i <= start - 1 && i > 1) {
        array.push("...");
      } else if (i === end + 1 && i < pages) {
        array.push("...");
      } else if (i >= pages) {
        continue;
      } else if (i <= 1) {
        continue;
      } else {
        array.push(i);
      }
    }

    let array_to_show = [1, ...array, pages];

    return (
      <>
        {pages > 1 &&
          array_to_show.map((item, i) => {
            return (
              <React.Fragment key={`${item}${i}`}>
                {item !== "..." ? (
                  <li
                    className={
                      state.page === item ? "page-item active" : "page-item"
                    }
                  >
                    <a
                      className="page-link"
                      onClick={(e) => {
                        e.preventDefault();
                        fetchMore({
                          variables: {
                            params: {
                              count: 10,
                              offset: 10 * (item - 1),
                              filter: props.filter ? [...props.filter] : null,
                            },
                          },
                          updateQuery: (prev, { fetchMoreResult }) => {
                            if (!fetchMoreResult) return prev;

                            return {
                              ...fetchMoreResult,
                            };
                          },
                        });
                        setState({
                          page: item,
                        });
                      }}
                      href="#"
                    >
                      {item}
                    </a>
                  </li>
                ) : (
                  <li className="page-item disabled">
                    <span className="page-link">...</span>
                  </li>
                )}
              </React.Fragment>
            );
          })}
      </>
    );
  };

  function timeConversion(millisec) {
    let seconds = (millisec / 1000).toFixed(1);

    let minutes = (millisec / (1000 * 60)).toFixed(1);

    let hours = (millisec / (1000 * 60 * 60)).toFixed(1);

    let days = (millisec / (1000 * 60 * 60 * 24)).toFixed(1);

    if (seconds < 60) {
      return seconds + " с";
    } else if (minutes < 60) {
      return minutes + " м";
    } else if (hours < 24) {
      return hours + " ч";
    } else {
      return days + " д";
    }
  }

  const { loading, error, data, fetchMore } = useQuery(
    MP_ITEM_REPORT_LIST_PAGER,
    {
      client: props.client,
      variables: {
        params: {
          count: 10,
          offset: 0,
          filter: props.filter ? [...props.filter] : null,
        },
      },
    }
  );

  if (loading) return "Загрузка...";
  if (error) return `Error! ${error.message}`;

  return (
    <div>
      <div
        className="mt-3"
        style={{
          overflowX: "auto",
          minHeight: "400px",
        }}
      >
        <table
          className="table table-bordered table-striped"
          style={{
            fontSize: "14px",
            position: "relative",
          }}
        >
          <thead className="thead">
            <tr
              style={{
                fontSize: "12px",
              }}
            >
              <th>Дата создания медиаплана</th>
              <th>Байер</th>
              <th>Бренд-клиент</th>
              <th>Итого клиент, включая все налоги</th>
              <th>Стоимость за период ЗАКУП без НДС</th>
              <th>Площадка со светофором</th>
              <th>Город</th>
              <th>Маржа</th>
              <th>Рентабельность</th>
              <th>Отсрочка базовая</th>
              <th>Отсрочка факт</th>
              <th>Скидка ДП</th>
              <th>Лучшая скидка ДП</th>
              <th>Скидка клиента</th>
              <th>Срок составления медиаплана</th>
              <th>Ссылка на МП</th>
            </tr>
          </thead>
          <tbody>
            {data.RN.MpItemReportListPager.data.map((item) => (
              <tr key={item.id}>
                <td>{item.mp_entry_date && item.mp_entry_date}</td>
                <td>{item.Buyer && item.Buyer.nameFull}</td>
                <td>
                  {item.Partner && item.Partner.name}{" "}
                  {item.Brand && item.Brand.name}
                </td>
                <td>
                  {item.total_cost_vat_discount && (
                    <>{item.total_cost_vat_discount} ₽</>
                  )}
                </td>
                <td>
                  {item.total_cost_purchase_discount && (
                    <>{item.total_cost_purchase_discount} ₽</>
                  )}
                </td>
                <td>
                  {item.Platform && item.Platform.name} &nbsp;
                  {item.Platform && item.Platform.adequate === 1 && "красный"}
                  {item.Platform && item.Platform.adequate === 2 && "желтый"}
                  {item.Platform &&
                    item.Platform.adequate === 3 &&
                    "зеленый"}{" "}
                  &nbsp;
                  {item.Platform &&
                    item.Platform.adequate_comment &&
                    item.Platform.adequate_comment}
                </td>
                <td>
                  {item.Platform &&
                    item.Platform.City &&
                    item.Platform.City.FORMALNAME}
                </td>
                <td>{item.margin && <>{item.margin} ₽</>}</td>
                <td>{item.profitability && <>{item.profitability} %</>}</td>
                <td>
                  {item.Platform &&
                    item.Platform.payment_delay_value &&
                    item.Platform.payment_delay_value}{" "}
                  &nbsp;
                  {item.Platform &&
                    item.Platform.payment_delay === 1 &&
                    "С Даты Финиш"}
                  {item.Platform &&
                    item.Platform.payment_delay === 2 &&
                    "С начала месяца Даты Финиш"}
                  {item.Platform &&
                    item.Platform.payment_delay === 3 &&
                    "С конца месяца Даты Финиш"}
                  {item.Platform &&
                    item.Platform.payment_delay === 4 &&
                    "С Даты Старт"}
                  {item.Platform &&
                    item.Platform.payment_delay === 5 &&
                    "С начала месяца Даты Старт"}
                  {item.Platform &&
                    item.Platform.payment_delay === 6 &&
                    "С конца месяца Даты Старт"}
                </td>
                <td>
                  {item.payment_delay_value && item.payment_delay_value} &nbsp;
                  {item.payment_delay === 1 && "С Даты Финиш"}
                  {item.payment_delay === 2 && "С начала месяца Даты Финиш"}
                  {item.payment_delay === 3 && "С конца месяца Даты Финиш"}
                  {item.payment_delay === 4 && "С Даты Старт"}
                  {item.payment_delay === 5 && "С начала месяца Даты Старт"}
                  {item.payment_delay === 6 && "С конца месяца Даты Старт"}
                </td>
                <td>
                  {item.discount_purchase && <>{item.discount_purchase} %</>}
                </td>
                <td>{item.best_price && "лучшая"}</td>
                <td>{item.discount && <>{item.discount} %</>}</td>
                <td>
                  {item.mp_entry_date &&
                    item.mp_finished_date &&
                    timeConversion(
                      new Date(item.mp_finished_date) -
                        new Date(item.mp_entry_date)
                    )}{" "}
                </td>
                <td>
                  {item.mp_id && (
                    <a
                      href={`${process.env.REACT_APP_URL}/mediaplan/update/${item.mp_id}`}
                    >
                      Медиаплан {item.mp_id}
                    </a>
                  )}{" "}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <nav aria-label="navigation" className="mt-3">
        <ul className="pagination">{renderButtons(3, data)}</ul>
      </nav>
    </div>
  );
};

const ReportContainer = (props) => {
  return (
    <ApolloConsumer>
      {(client) => {
        return <Report client={client} {...props} />;
      }}
    </ApolloConsumer>
  );
};

export default ReportContainer;
