import React, { Component } from "react";
import NumberFormat from "react-number-format";
import Select from "react-select";
import AsyncSelect from "react-select/lib/Async";
import { ApolloConsumer } from "react-apollo";
import { graphql } from "react-apollo";
import Geo from "./../../../../components/geo";
import { ac_disable } from "./form.functions";

//Запросы
import { RN_BRIEFING, ME } from "../gql/query";
import { RN_BRIEFING_CREATE, RN_BRIEFING_UPDATE } from "../gql/mutation";
import { COMON_USER_AVAILABLE } from "../../../GraphQL/RNqueries";
import { RN_UPLOAD_FILE } from "../../../GraphQL/RNmutations";
import { Mutation } from "react-apollo";

//обрабочик для select-react
import { selectQuery } from "../gql/query";

import Header from "./../../../../template/Header";

import { SelectEmployee } from "../../../../components/select";

//moment
const moment = require("moment/min/moment-with-locales.js");

//опции возраста
const ageRange_options = [
  { value: 1, label: "18-24 года" },
  { value: 2, label: "25-34 лет" },
  { value: 3, label: "35-44 лет" },
  { value: 4, label: "44-54 года" },
  { value: 5, label: "55 и старше" },
];

class Form extends Component {
  /* global $*/
  state = {
    success: false,
    tooltip: false,
    data: {
      file: [],
      vat: 0,
      no_vat: 0,
      agent_commission_value_type: 1,
      FederalDistricts: [],
      // пользователи в брифе, соисполнители
      users: [],
      Users: [],
    },
    file: [],
    formErrors: [],
  };

  //определение пола
  detectSexValue() {
    if (this.male.checked && this.female.checked) return "f,m";
    if (!this.male.checked && !this.female.checked) return null;
    if (!this.male.checked && this.female.checked) return "f";
    if (this.male.checked && !this.female.checked) return "m";
  }

  //фильтрация возрастов
  ageRange_options_filter(array) {
    let options = [];
    if (array.length) {
      for (let i of array) {
        options.push(ageRange_options.filter((item) => item.value === +i));
      }
      options = options.reduce((acc, item) => acc.concat(item));
    }
    return options;
  }

  handleChangeData(field, value) {
    return this.setState((state) => ({
      data: {
        ...state.data,
        [field]: value,
      },
    }));
  }

  handleChange(field, value) {
    return this.setState((state) => ({
      ...state,
      [field]: value,
    }));
  }

  //отправка данных
  dataSend() {
    this.setState({ formErrors: [] });

    this.props
      .createBriefing({
        variables: {
          briefing: {
            ...this.state.data,
            status: this.state.data.status === 2 ? 0 : this.state.data.status,
          },
        },
      })
      .then(({ data }) => {
        this.setState({
          button_is_disabled: false,
          refresher: true,
          success: true,
        });
        this.props.history.push("/briefing/list");
      })
      .catch((res) => {
        if (
          res.graphQLErrors[0] &&
          res.graphQLErrors[0].extensions &&
          res.graphQLErrors[0].extensions.category === "FormDataError"
        ) {
          const formErrors = res.graphQLErrors[0].message.split("\n");
          this.setState({
            formErrors,
            button_is_disabled: false,
          });
        } else {
          console.warn(res);
        }
      });
  }

  //создание ссылки ручное
  textInput = React.createRef();
  tenderDeadlineInput = React.createRef();

  //дата заполнения
  addEntryDate() {
    if (!this.props.briefing) {
      this.setState({
        entryDate: moment().format(),
      });
      this.setState((state) => ({
        data: {
          ...state.data,
          entryDate: moment().format(),
        },
      }));
    }
  }

  //создание объектов jquery
  jQueryCreate() {
    let _this = this;
    $("input#daterangselect")
      .daterangepicker({
        startDate: moment(_this.state.data.dateStart).format("DD.MM.YYYY"),
        endDate: moment(_this.state.data.dateEnd).format("DD.MM.YYYY"),
        opens: "right",
        locale: {
          applyLabel: "Применить",
          cancelLabel: "Отменить",
        },
      })
      .ready(() => {
        let date = _this.textInput.current.value;
        date = date.split(" - ");
        _this.setState((state) => ({
          data: {
            ...state.data,
            dateStart: moment(date[0], "DD-MM-YYYY").format("YYYY-MM-DD"),
            dateEnd: moment(date[1], "DD-MM-YYYY").format("YYYY-MM-DD"),
          },
        }));
      })
      .on("apply.daterangepicker", function (e, picker) {
        _this.setState((state) => ({
          data: {
            ...state.data,
            dateStart: moment(picker.startDate).format("YYYY-MM-DD"),
            dateEnd: moment(picker.endDate).format("YYYY-MM-DD"),
          },
        }));
      });

    $("input[name=deadline]")
      .daterangepicker({
        startDate: _this.state.data.deadline
          ? moment(_this.state.data.deadline).format("DD.MM.YYYY HH:mm")
          : moment().add(3, "days").format("DD.MM.YYYY HH:mm"),
        timePicker: true,
        timePicker24Hour: true,
        singleDatePicker: true,
        showDropdowns: true,
        locale: {
          format: "DD.MM.YYYY HH:mm ",
          applyLabel: "Применить",
          cancelLabel: "Отменить",
        },
      })
      .ready(() => {
        if (!_this.state.data.deadline) {
          let date = _this.textInput.current.value;
          date = date.split(" - ");
          _this.setState((state) => ({
            data: {
              ...state.data,
              deadline: moment(date[0], "dd.mm.yyyy")
                .add(3, "days")
                .format("YYYY-MM-DD"),
            },
          }));
        }
      })
      .on("apply.daterangepicker", function (ev, picker) {
        _this.setState((state) => ({
          data: {
            ...state.data,
            deadline: moment(picker.startDate).format(),
          },
        }));
      });

    $("input[name=tender_deadline]")
      .daterangepicker({
        startDate: _this.state.data.tender_deadline
          ? moment(_this.state.data.tender_deadline).format("DD.MM.YYYY HH:mm")
          : moment(),
        timePicker: true,
        timePicker24Hour: true,
        singleDatePicker: true,
        showDropdowns: true,
        locale: {
          format: "DD.MM.YYYY HH:mm ",
          applyLabel: "Применить",
          cancelLabel: "Отменить",
        },
      })
      .ready(() => {
        let date = _this.tenderDeadlineInput.current.value;
        date = date.split(" - ");
        this.setState((state) => ({
          data: {
            ...state.data,
            tender_deadline: moment(date[0], "dd.mm.yyyy").format("YYYY-MM-DD"),
          },
        }));
      })
      .on("apply.daterangepicker", function (ev, picker) {
        _this.setState((state) => ({
          data: {
            ...state.data,
            tender_deadline: moment(picker.startDate).format(),
          },
        }));
      });
  }

  static getDerivedStateFromProps(props, state) {
    let updateForState = {};

    if (
      !state.data.reponsible_id &&
      !state.data.manager_id &&
      !props.briefing
    ) {
      updateForState = {
        ...state,
        Manager: {
          id: props.src_data.me.comon_id,
          nameFull: props.src_data.me.login,
        },
        Responsible: {
          id: props.src_data.TopBuyer.comon_id,
          nameFull: props.src_data.TopBuyer.login,
        },
        data: {
          ...state.data,
          responsible_id: props.src_data.TopBuyer.comon_id,
          manager_id: props.src_data.me.comon_id,
        },
      };
    }

    if (state.briefing == null && props.briefing) {
      updateForState = {
        ...state,
        data: {
          ...props.briefing,
        },
        briefing: true,
      };

      if (updateForState.data.ageRanges.some((item) => item === ""))
        updateForState.data.ageRanges = [];

      delete updateForState.data["__typename"];

      if (updateForState.data.Partner) {
        updateForState.Partner = {
          ...updateForState.data.Partner,
        };
      }

      if (updateForState.data.Brand) {
        updateForState.Brand = {
          ...updateForState.data.Brand,
        };
      }

      delete updateForState.data.Partner;
      delete updateForState.data.MLPartner;
      delete updateForState.data.Brand;
      delete updateForState.data.Cities;
      delete updateForState.data.ComonGroup;

      if (updateForState.data.Responsible) {
        updateForState.Responsible = updateForState.data.Responsible;
      }

      delete updateForState.data.Responsible;

      if (updateForState.data.Manager) {
        updateForState.Manager = updateForState.data.Manager;
      }

      delete updateForState.data.Manager;

      delete updateForState.data.UrFace_nonds;
      delete updateForState.data.UrFace_nds;
      updateForState.file = updateForState.data.file;
      updateForState.data.file = updateForState.data.file.map(
        (item) => item.id
      );
    }

    return updateForState;
  }

  treeIterator(federalDistricts) {
    let temp = [];
    for (let district of federalDistricts) {
      temp = [...temp, district.Code];

      if (district.FederalSubjects) {
        for (let subject of district.FederalSubjects) {
          temp = [...temp, subject.AOGUID];
          if (subject.Localities) {
            for (let city of subject.Localities) {
              temp = [...temp, city.AOGUID];
            }
          }
        }
      }
    }

    return temp;
  }

  componentDidMount() {
    this.addEntryDate();
    this.jQueryCreate();
  }

  renderFileInput() {
    return (
      <>
        <div className="form-group row">
          <div className="col-5">Прикрепить файл</div>
          <div className="col-4">
            <Mutation mutation={RN_UPLOAD_FILE}>
              {(mutate) => (
                <input
                  type="file"
                  required
                  multiple
                  onChange={({ target: { validity, files } }) => {
                    //validity.valid &&
                    //сделал две ветки на отображение и загрузку
                    for (let i = 0; i < files.length; i++) {
                      mutate({
                        variables: { file: files[i] },
                      }).then((res) => {
                        if (this.state.data.file.length > 0) {
                          this.setState({
                            data: {
                              ...this.state.data,
                              file: this.state.data.file.concat([
                                res.data.RnUploadFile.id,
                              ]),
                            },
                            file: this.state.file.concat([
                              res.data.RnUploadFile,
                            ]),
                          });
                        } else {
                          this.setState({
                            data: {
                              ...this.state.data,
                              file: [res.data.RnUploadFile.id],
                            },
                            file: [res.data.RnUploadFile],
                          });
                        }
                      });
                    }
                  }}
                />
              )}
            </Mutation>
          </div>
        </div>
        <div className="row">
          <ul>
            {this.state.file &&
              this.state.file.map((item) => (
                <li key={item.id}>
                  {item.name}
                  <button
                    onClick={() => {
                      const new_state_to_show = this.state.file.filter(
                        (i) => i.id !== item.id
                      );
                      const new_state = this.state.data.file.filter(
                        (i) => i !== item.id
                      );
                      this.setState({
                        data: {
                          ...this.state.data,
                          file: new_state,
                        },
                        file: new_state_to_show,
                      });
                    }}
                  >
                    X
                  </button>
                </li>
              ))}
          </ul>
        </div>
      </>
    );
  }

  render() {
    let payment_delay = [];

    if (this.state.data.payment_delay === 1)
      payment_delay = [{ label: "С Даты Финиш" }];
    if (this.state.data.payment_delay === 2)
      payment_delay = [{ label: "С начала месяца Даты Финиш" }];
    if (this.state.data.payment_delay === 3)
      payment_delay = [{ label: "С конца месяца Даты Финиш" }];
    if (this.state.data.payment_delay === 4)
      payment_delay = [{ label: "С Даты Старт" }];
    if (this.state.data.payment_delay === 5)
      payment_delay = [{ label: "С начала месяца Даты Старт" }];
    if (this.state.data.payment_delay === 6)
      payment_delay = [{ label: "С конца месяца Даты Старт" }];

    let renderLegalEntityVat = false;
    let renderLegalEntityNoVat = false;

    if (this.state.data.contract_type === 1 && this.state.data.vat === 1) {
      renderLegalEntityVat = true;
    }

    if (this.state.data.contract_type === 1 && this.state.data.no_vat === 1) {
      renderLegalEntityNoVat = true;
    }

    if (
      this.state.data.contract_type === 2 &&
      this.state.data.no_vat === 1 &&
      this.state.data.vat === 1 &&
      this.state.data.agent_commission_position
    ) {
      renderLegalEntityVat = true;
    }

    if (
      this.state.data.contract_type === 3 &&
      this.state.data.no_vat &&
      this.state.data.vat &&
      this.state.data.agent_commission_position
    ) {
      renderLegalEntityVat = true;
      renderLegalEntityNoVat = false;
    }

    if (
      this.state.data.contract_type === 3 &&
      this.state.data.no_vat &&
      !this.state.data.vat &&
      this.state.data.agent_commission_position
    ) {
      renderLegalEntityVat = false;
      renderLegalEntityNoVat = true;
    }

    return (
      <>
        <Header {...this.state} {...this.props} />
        <section className="content">
          <div className="container-fluid mediaplans-list-block">
            <div className="table-container">
              <div className="container form-horizontal mt-3">
                <h2 className="text-center mb-3">Техническое задание</h2>
                {this.renderFormErrors()}
                {this.state.success && this.renderSuccess()}
                <div className="form-group row">
                  <label className="col-lg-3 col-form-label">Название</label>
                  <div className="col-lg-7">
                    <input
                      name="name"
                      type="text"
                      disabled={true}
                      value={`${
                        this.state.Partner ? this.state.Partner.name : ""
                      } ${this.state.Brand ? this.state.Brand.name : ""} ${
                        this.state.Partner &&
                        this.state.data.dateStart &&
                        this.state.data.dateEnd
                          ? `${new Date(
                              this.state.data.dateStart
                            ).toLocaleDateString()} - ${new Date(
                              this.state.data.dateEnd
                            ).toLocaleDateString()}`
                          : ""
                      } ${
                        this.state.data.products ? this.state.data.products : ""
                      }`}
                      className="form-control"
                      onChange={(e) => {
                        const value = e.target.value;
                        this.setState((state) => ({
                          data: {
                            ...state.data,
                            name: value,
                          },
                        }));
                      }}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-lg-3 col-form-label">
                    Куратор проекта<span className="text-danger">*</span>
                  </label>
                  <div className="col-lg-7">
                    <ApolloConsumer>
                      {(client) => (
                        <AsyncSelect
                          name="manager_id"
                          defaultValue={
                            this.state.Manager
                              ? {
                                  value: this.state.Manager.id,
                                  label: this.state.Manager.nameFull,
                                }
                              : {}
                          }
                          loadOptions={(inputValue) =>
                            selectQuery(
                              inputValue,
                              "RefEmployeeList",
                              client,
                              "manager"
                            )
                          }
                          onChange={(manager_id) => {
                            const value = manager_id.value;
                            this.setState((state) => ({
                              data: {
                                ...state.data,
                                manager_id: value,
                              },
                            }));
                          }}
                        />
                      )}
                    </ApolloConsumer>
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-lg-3 col-form-label">
                    Исполнитель <span className="text-danger">*</span>
                  </label>
                  <div className="col-lg-7">
                    <ApolloConsumer>
                      {(client) => (
                        <AsyncSelect
                          name="responsible_id"
                          defaultValue={
                            this.state.Responsible
                              ? {
                                  value: this.state.Responsible.id,
                                  label: this.state.Responsible.nameFull,
                                }
                              : {}
                          }
                          loadOptions={(inputValue) =>
                            selectQuery(
                              inputValue,
                              "RefEmployeeList",
                              client,
                              "manager"
                            )
                          }
                          onChange={(responsible) => {
                            const value = responsible.value;
                            client
                              .query({
                                query: COMON_USER_AVAILABLE,
                                variables: {
                                  id: value,
                                },
                              })
                              .then((result) => {
                                if (
                                  result.data.ComonUserAvailable.id !== value
                                ) {
                                  alert(
                                    "Исполнитель в отпуске, заменен на заместителя"
                                  );
                                }

                                this.setState((state) => ({
                                  data: {
                                    ...state.data,
                                    responsible_id: +result.data
                                      .ComonUserAvailable.id,
                                  },
                                  Responsible: {
                                    ...result.data.ComonUserAvailable,
                                  },
                                }));
                              });
                          }}
                        />
                      )}
                    </ApolloConsumer>
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-lg-3 col-form-label">
                    Соисполнители
                  </label>
                  <div className="col-lg-7">
                    <ApolloConsumer>
                      {(client) => (
                        <SelectEmployee
                          client={client}
                          isMulti={true}
                          value={this.state?.data?.Users?.map((user) => ({
                            value: user.id,
                            label: user.nameFull,
                          }))}
                          onChange={(value) => {
                            this.handleChangeData(
                              "Users",
                              value.map((user) => ({
                                nameFull: user.name,
                                id: user.id,
                              }))
                            );
                            this.handleChangeData(
                              "users",
                              value.map((val) => val.id)
                            );
                          }}
                        />
                      )}
                    </ApolloConsumer>
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-lg-3 col-form-label">
                    Группа Comon
                  </label>
                  <div className="col-lg-7">
                    <ApolloConsumer>
                      {(client) => (
                        <AsyncSelect
                          name="coon_group_id"
                          defaultValue={
                            this.props.briefing &&
                            this.props.briefing.ComonGroup
                              ? {
                                  value: this.props.briefing.ComonGroup.id,
                                  label: this.props.briefing.ComonGroup.name,
                                }
                              : {}
                          }
                          loadOptions={(inputValue) =>
                            selectQuery(inputValue, "ComonGroupList", client)
                          }
                          onChange={(comon_group) => {
                            const value = comon_group.value;

                            this.setState((state) => ({
                              data: {
                                ...state.data,
                                comon_group_id: value,
                              },
                            }));
                          }}
                        />
                      )}
                    </ApolloConsumer>
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-lg-3 col-form-label">
                    Клиент <span className="text-danger">*</span>
                  </label>
                  <div className="col-lg-7">
                    <ApolloConsumer>
                      {(client) => (
                        <AsyncSelect
                          name="partner_id"
                          defaultValue={
                            this.props.briefing && this.props.briefing.Partner
                              ? {
                                  value: this.props.briefing.Partner.id,
                                  label: this.props.briefing.Partner.name,
                                }
                              : {}
                          }
                          loadOptions={(inputValue) =>
                            selectQuery(inputValue, "MLPartnerList", client)
                          }
                          onChange={(partner) => {
                            const value = partner.value;
                            this.setState((state) => ({
                              ...state,
                              Partner: {
                                id: partner.value,
                                name: partner.label,
                              },
                              data: {
                                ...state.data,
                                partner_id: value,
                              },
                            }));
                          }}
                        />
                      )}
                    </ApolloConsumer>
                  </div>
                  <div className="col-lg-2 col-form-label">
                    <a
                      href="#"
                      onClick={() => {
                        this.setState({
                          ...this.state,
                          tooltip: true,
                        });
                      }}
                    >
                      Не нашел клиента?
                    </a>
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-lg-3 col-form-label">
                    Бренд <span className="text-danger">*</span>
                  </label>
                  <div className="col-lg-7">
                    <ApolloConsumer>
                      {(client) => (
                        <AsyncSelect
                          name="brand_id"
                          defaultValue={
                            this.props.briefing && this.props.briefing.Brand
                              ? {
                                  value: this.props.briefing.Brand.id,
                                  label: this.props.briefing.Brand.name,
                                }
                              : {}
                          }
                          loadOptions={(inputValue) =>
                            selectQuery(inputValue, "MLBrandList", client)
                          }
                          onChange={(brand) => {
                            const value = brand.value;
                            this.setState((state) => ({
                              ...state,
                              Brand: {
                                id: brand.value,
                                name: brand.label,
                              },
                              data: {
                                ...state.data,
                                brand_id: value,
                              },
                            }));
                          }}
                        />
                      )}
                    </ApolloConsumer>
                  </div>
                  <div className="col-lg-2 col-form-label">
                    <a
                      href="#"
                      onClick={() => {
                        this.setState({
                          ...this.state,
                          tooltip: true,
                        });
                      }}
                    >
                      Не нашел бренд?
                    </a>
                    {this.state.tooltip ? (
                      <div
                        style={{
                          position: "absolute",
                          width: "300px",
                          background: "#fff",
                          top: "-100px",
                          zIndex: 2,
                        }}
                      >
                        Если вы не нашли клиента или бренд который есть в АСС,
                        то значит что сделки по клиенту не было в течение
                        последних двух лет. Что делать: вам нужно внести любое
                        изменение в информацию о контрагенте в АСС, данные
                        появятся в Q-Bit в течении 10 минут. Если клиента нет в
                        АСС, то нужно будет его завести.
                      </div>
                    ) : null}
                  </div>
                </div>

                <div className="form-group row">
                  <label className="col-lg-3 col-form-label">
                    Налогооблажение клиента
                    <span className="text-danger">*</span>
                  </label>
                  <div className="col-lg-7">
                    <label>
                      <input
                        name="vat"
                        type="checkbox"
                        onChange={(e) => {
                          const value = e.target.checked ? 1 : 0;
                          this.setState((state) => ({
                            data: {
                              ...state.data,
                              vat: value,
                              legal_entity_vat: null,
                              legal_entity_novat: null,
                            },
                          }));
                        }}
                        checked={this.state.data.vat === 1}
                      />{" "}
                      с НДС
                    </label>
                    &nbsp;&nbsp;
                    <label>
                      <input
                        name="no_vat"
                        type="checkbox"
                        onChange={(e) => {
                          const value = e.target.checked ? 1 : 0;
                          this.setState((state) => ({
                            data: {
                              ...state.data,
                              no_vat: value,
                              legal_entity_vat: null,
                              legal_entity_novat: null,
                            },
                          }));
                        }}
                        checked={this.state.data.no_vat === 1}
                      />{" "}
                      без НДС
                    </label>
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-lg-3 col-form-label">
                    Тип договора<span className="text-danger">*</span>
                  </label>
                  <div className="col-lg-7">
                    <select
                      name="contract_type"
                      className="form-control"
                      value={
                        this.state.data.contract_type
                          ? this.state.data.contract_type
                          : ""
                      }
                      disabled={!this.state.data.no_vat && !this.state.data.vat}
                      onChange={(e) => {
                        const value = +e.target.value;
                        this.setState((state) => ({
                          data: {
                            ...state.data,
                            contract_type: value,
                            legal_entity_vat: null,
                            legal_entity_novat: null,
                            agent_commission_value_type: 1,
                          },
                        }));
                      }}
                    >
                      <option value={0} hidden>
                        &nbsp;
                      </option>
                      <option value={1}>Услуговый</option>
                      <option
                        value={2}
                        disabled={
                          true
                          // this.state.data.no_vat !== 1 ||
                          // this.state.data.vat !== 1
                        }
                      >
                        Агентский
                      </option>
                      <option
                        value={3}
                        disabled={
                          true
                          // this.state.data.no_vat !== 1
                        }
                      >
                        Комплексный
                      </option>
                    </select>
                  </div>
                </div>
                {this.state.data.contract_type === 2 ||
                this.state.data.contract_type === 3 ? (
                  <div
                    className={
                      this.state.data.vat || this.state.data.no_vat
                        ? ""
                        : "d-none"
                    }
                  >
                    <div className="form-group row">
                      <label className="col-lg-3 col-form-label">
                        Агентское вознаграждение
                      </label>
                      <div className="col-lg-7">
                        <select
                          name="agent_commission_position"
                          value={
                            this.state.data.agent_commission_position
                              ? this.state.data.agent_commission_position
                              : ""
                          }
                          className="form-control"
                          onChange={(e) => {
                            const value = +e.target.value;
                            this.setState((state) => ({
                              data: {
                                ...state.data,
                                agent_commission_position: value,
                              },
                            }));
                          }}
                        >
                          <option value="0" hidden></option>
                          <option value="1">Сверху</option>
                          <option
                            value="2"
                            disabled={ac_disable(this.state.data)}
                          >
                            Внутри
                          </option>
                        </select>
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-3 col-form-label">Сумма АВ</label>
                      <div className="col-2">
                        <input
                          name="agent_commission_value"
                          className="form-control"
                          type="text"
                          value={
                            this.state.data.agent_commission_value
                              ? this.state.data.agent_commission_value
                              : ""
                          }
                          onChange={(e) => {
                            const value = e.target.value;
                            this.setState((state) => ({
                              data: {
                                ...state.data,
                                agent_commission_value: value,
                              },
                            }));
                          }}
                        />
                      </div>
                      <div className="col-1">%</div>
                    </div>
                  </div>
                ) : null}
                {renderLegalEntityVat ? (
                  <div className="form-group row">
                    <label className="col-lg-3 col-form-label">
                      Юр. лицо Дельта Плана с НДС
                    </label>
                    <div className="col-lg-7">
                      <ApolloConsumer>
                        {(client) => (
                          <AsyncSelect
                            name="legal_entity_vat"
                            defaultValue={
                              this.props.briefing
                                ? {
                                    value: this.props.briefing.UrFace_nds
                                      ? this.props.briefing.UrFace_nds.id
                                      : null,
                                    label: this.props.briefing.UrFace_nds
                                      ? this.props.briefing.UrFace_nds.name
                                      : null,
                                  }
                                : {}
                            }
                            defaultOptions
                            loadOptions={(inputValue) =>
                              selectQuery(
                                inputValue,
                                "MLDpUrFacesList",
                                client,
                                "vat",
                                1
                              )
                            }
                            onChange={(legal_entity_vat) => {
                              const value = legal_entity_vat.value;
                              this.setState((state) => ({
                                data: {
                                  ...state.data,
                                  legal_entity_vat: value,
                                },
                              }));
                            }}
                          />
                        )}
                      </ApolloConsumer>
                    </div>
                  </div>
                ) : null}
                {renderLegalEntityNoVat ? (
                  <div className="form-group row">
                    <label className="col-lg-3 col-form-label">
                      Юр. лицо Дельта Плана
                    </label>
                    <div className="col-lg-7">
                      <ApolloConsumer>
                        {(client) => (
                          <AsyncSelect
                            name="legal_entity_novat"
                            defaultValue={
                              this.props.briefing
                                ? {
                                    value: this.props.briefing.UrFace_nonds
                                      ? this.props.briefing.UrFace_nonds.id
                                      : null,
                                    label: this.props.briefing.UrFace_nonds
                                      ? this.props.briefing.UrFace_nonds.name
                                      : null,
                                  }
                                : {}
                            }
                            defaultOptions
                            loadOptions={(inputValue) =>
                              selectQuery(
                                inputValue,
                                "MLDpUrFacesList",
                                client,
                                "vat",
                                0
                              )
                            }
                            onChange={(legal_entity_novat) => {
                              const value = legal_entity_novat.value;
                              this.setState((state) => ({
                                data: {
                                  ...state.data,
                                  legal_entity_novat: value,
                                },
                              }));
                            }}
                          />
                        )}
                      </ApolloConsumer>
                    </div>
                  </div>
                ) : null}
                <div className="form-group row">
                  <label className="col-lg-3 col-form-label">Адрес сайта</label>
                  <div className="col-lg-7">
                    <input
                      name="website"
                      type="text"
                      value={
                        this.state.data.website ? this.state.data.website : ""
                      }
                      className="form-control"
                      onChange={(e) => {
                        const value = e.target.value;
                        this.setState((state) => ({
                          data: {
                            ...state.data,
                            website: value,
                          },
                        }));
                      }}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-lg-3 col-form-label">Тендер</label>
                  <div className="col-lg-4">
                    <label>
                      <input
                        name="tender_yes"
                        type="checkbox"
                        onChange={(e) => {
                          const value = e.target.checked && 1;
                          this.setState((state) => ({
                            data: {
                              ...state.data,
                              tender: value,
                            },
                          }));
                        }}
                        checked={this.state.data.tender === 1}
                      />{" "}
                      да
                    </label>
                    &nbsp;&nbsp;
                    <label>
                      <input
                        name="tender_no"
                        type="checkbox"
                        onChange={(e) => {
                          const value = e.target.checked && 0;
                          this.setState((state) => ({
                            data: {
                              ...state.data,
                              tender: value,
                            },
                          }));
                        }}
                        checked={this.state.data.tender === 0}
                      />{" "}
                      нет
                    </label>
                  </div>
                  <div
                    className="col-lg-3"
                    style={{
                      display:
                        this.state.data.tender === 1 ? "inline-block" : "none",
                    }}
                  >
                    <input
                      name="tender_deadline"
                      id="tender_deadline"
                      type="text"
                      className="form-control calendar-icon"
                      placeholder="Период"
                      title="form-value"
                      ref={this.tenderDeadlineInput}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-lg-3 col-form-label">
                    Рекламируемый товар / услуга{" "}
                    <span className="text-danger">*</span>
                  </label>
                  <div className="col-lg-7">
                    <input
                      name="products"
                      value={
                        this.state.data.products ? this.state.data.products : ""
                      }
                      type="text"
                      className="form-control"
                      onChange={(e) => {
                        const value = e.target.value;
                        this.setState((state) => ({
                          data: {
                            ...state.data,
                            products: value,
                          },
                        }));
                      }}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-lg-3 col-form-label">
                    Целевая аудитория РК - город, возраст, пол, уровень дохода,
                    род занятий <span className="text-danger">*</span>
                  </label>
                  <div className="col-lg-7">
                    <textarea
                      rows="4"
                      name="target_audience"
                      className="form-control"
                      value={
                        this.state.data.target_audience
                          ? this.state.data.target_audience
                          : ""
                      }
                      onChange={(e) => {
                        const value = e.target.value;
                        this.setState((state) => ({
                          data: {
                            ...state.data,
                            target_audience: value,
                          },
                        }));
                      }}
                    ></textarea>
                    <p
                      style={{
                        fontSize: "0.8rem",
                      }}
                    >
                      (если несколько указать важность каждой)
                    </p>
                  </div>
                </div>
                {/*<div className="form-group row">*/}
                {/*  <label className="col-lg-3 col-form-label">Вся РФ</label>*/}
                {/*  <div className="col-lg-7">*/}
                {/*    <input*/}
                {/*      name="federal"*/}
                {/*      type="checkbox"*/}
                {/*      checked={this.state.data.federal === 1}*/}
                {/*      onChange={(e) => {*/}
                {/*        const value = e.target.checked ? 1 : 0;*/}
                {/*        this.setState((state) => ({*/}
                {/*          data: {*/}
                {/*            ...state.data,*/}
                {/*            federal: value,*/}
                {/*          },*/}
                {/*        }));*/}
                {/*      }}*/}
                {/*    />*/}
                {/*  </div>*/}
                {/*</div>*/}
                <div className="form-group row">
                  <label className="col-lg-3 col-form-label">
                    География РК
                  </label>
                  <div className="col-lg-7">
                    <Geo
                      briefing_data={this.state.data}
                      supporting_geo_data={this.treeIterator(
                        this.state.data.FederalDistricts
                      )}
                      geoIsUpdated={this.state.geoIsUpdated}
                      onChange={(geo) => {
                        this.setState((state) => ({
                          data: {
                            ...state.data,
                            ...geo,
                          },
                        }));
                      }}
                      update={() => {
                        this.setState((state) => ({
                          data: {
                            ...state.data,
                          },
                          geoIsUpdated: true,
                        }));
                      }}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-lg-3 col-form-label">Период</label>
                  <div className="col-lg-7">
                    <input
                      id="daterangselect"
                      type="text"
                      className="form-control calendar-icon"
                      ref={this.textInput}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-lg-3 col-form-label">
                    Бюджет РК без НДС
                  </label>
                  <div className="col-lg-3 form-group row">
                    <label className="col-3 col-form-label">До</label>
                    <NumberFormat
                      name="totalBudgetTo"
                      className="col-9 form-control"
                      thousandSeparator={" "}
                      decimalSeparator={","}
                      decimalScale={2}
                      suffix={" ₽"}
                      value={
                        this.state.data.totalBudgetTo
                          ? this.state.data.totalBudgetTo
                          : null
                      }
                      onValueChange={(values) => {
                        const value = values.value;
                        this.setState((state) => ({
                          data: {
                            ...state.data,
                            totalBudgetTo: value,
                          },
                        }));
                      }}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-3 col-form-label">
                    Виды инструментов для расчета
                  </label>
                  <div className="col-7">
                    <label>
                      <input
                        name="banner"
                        type="checkbox"
                        checked={this.state.data.banner === 1}
                        onChange={(e) => {
                          const value = e.target.checked ? 1 : 0;
                          this.setState((state) => ({
                            data: {
                              ...state.data,
                              banner: value,
                            },
                          }));
                        }}
                      />{" "}
                      Баннерная реклама
                    </label>
                    &nbsp;&nbsp;
                    <label>
                      <input
                        name="special"
                        type="checkbox"
                        checked={this.state.data.special === 1}
                        onChange={(e) => {
                          const value = e.target.checked ? 1 : 0;
                          this.setState((state) => ({
                            data: {
                              ...state.data,
                              special: value,
                            },
                          }));
                        }}
                      />{" "}
                      Спецпроект
                    </label>
                    &nbsp;&nbsp;
                    <label>
                      <input
                        name="special"
                        type="checkbox"
                        checked={this.state.data.news === 1}
                        onChange={(e) => {
                          const value = e.target.checked ? 1 : 0;
                          this.setState((state) => ({
                            data: {
                              ...state.data,
                              news: value,
                            },
                          }));
                        }}
                      />{" "}
                      Размещение новостей
                    </label>
                    &nbsp;&nbsp;
                    <label>
                      <input
                        name="cards"
                        type="checkbox"
                        checked={this.state.data.cards === 1}
                        onChange={(e) => {
                          const value = e.target.checked ? 1 : 0;
                          this.setState((state) => ({
                            data: {
                              ...state.data,
                              cards: value,
                            },
                          }));
                        }}
                      />{" "}
                      Размещение карточек (Агрегаторы)
                    </label>
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-3 col-form-label">
                    Отсрочка платежа <span className="text-danger">*</span>
                  </label>
                  <div className="col-5">
                    <Select
                      name="payment_delay"
                      options={[
                        {
                          label: "С Даты Финиш",
                          value: 1,
                        },
                        {
                          label: "С начала месяца Даты Финиш",
                          value: 2,
                        },
                        {
                          label: "С конца месяца Даты Финиш",
                          value: 3,
                        },
                        {
                          label: "С Даты Старт",
                          value: 4,
                        },
                        {
                          label: "С начала месяца Даты Старт",
                          value: 5,
                        },
                        {
                          label: "С конца месяца Даты Старт",
                          value: 6,
                        },
                      ]}
                      defaultValue={payment_delay}
                      onChange={(payment_delay) => {
                        const value = payment_delay.value;
                        this.setState((state) => ({
                          data: {
                            ...state.data,
                            payment_delay: value,
                          },
                        }));
                      }}
                    />
                  </div>
                  <div className="col-2">
                    <input
                      name="payment_delay_value"
                      type="number"
                      step="1"
                      className="form-control"
                      value={
                        this.state.data.payment_delay_value
                          ? this.state.data.payment_delay_value
                          : ""
                      }
                      onChange={(e) => {
                        const value = +e.target.value;
                        this.setState((state) => ({
                          data: {
                            ...state.data,
                            payment_delay_value: value,
                          },
                        }));
                      }}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-lg-3 col-form-label">
                    Пожелания по площадкам / разделам форматам
                  </label>
                  <div className="col-lg-7">
                    <textarea
                      rows="4"
                      name="recommends"
                      className="form-control"
                      value={
                        this.state.data.recommends
                          ? this.state.data.recommends
                          : ""
                      }
                      onChange={(e) => {
                        const value = e.target.value;
                        this.setState((state) => ({
                          data: {
                            ...state.data,
                            recommends: value,
                          },
                        }));
                      }}
                    >
                      Региональные: Тематические: Федеральные:
                    </textarea>
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-3 col-form-label">
                    Разрабатываем РИМ?<span className="text-danger">*</span>
                  </label>
                  <div className="col-7">
                    <select
                      name="promotionalMaterial"
                      className="form-control"
                      value={
                        this.state.data.promotionalMaterial === true
                          ? "1"
                          : this.state.data.promotionalMaterial === false
                          ? "0"
                          : ""
                      }
                      onChange={(e) => {
                        const value = e.target.value === "1" ? true : false;
                        this.setState((state) => ({
                          data: {
                            ...state.data,
                            promotionalMaterial: value,
                          },
                        }));
                      }}
                    >
                      <option value="" hidden></option>
                      <option value="1">Да</option>
                      <option value="0">Нет</option>
                    </select>
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-lg-3 col-form-label">
                    Предыдущая активность в Интернете
                  </label>
                  <div className="col-lg-7">
                    <textarea
                      rows="4"
                      name="experience_comment"
                      className="form-control"
                      value={
                        this.state.data.experience_comment
                          ? this.state.data.experience_comment
                          : ""
                      }
                      onChange={(e) => {
                        const value = e.target.value;
                        this.setState((state) => ({
                          data: {
                            ...state.data,
                            experience_comment: value,
                          },
                        }));
                      }}
                    ></textarea>
                    <p
                      style={{
                        fontSize: "0.8rem",
                      }}
                    >
                      (какие площадки использовали, позитивный / негативный
                      опыт)
                    </p>
                  </div>
                </div>
                {this.renderFileInput()}
                <div className="footer col-10">
                  <div className="form-group row">
                    <div className="col-12">
                      <p>
                        Дата заполнения брифа –{" "}
                        {moment(this.state.data.entryDate).format(
                          "D MMMM YYYY H:mm"
                        )}
                      </p>
                    </div>
                    <div className="col-3">
                      <p>deadline, дата и время</p>
                    </div>
                    <div className="col-7 ml-5">
                      <div className="row">
                        <div className="col-6">
                          <input
                            name="deadline"
                            type="text"
                            className="form-control calendar-icon"
                            placeholder="Период"
                            title="form-value"
                          />
                        </div>
                        <div className="col-6 date-info">
                          Не менее трех рабочих дней на подготовку медиаплана
                        </div>
                      </div>
                    </div>
                    <div className="col-4 ml-3">
                      <button
                        className="form-control btn send-to-click"
                        disabled={this.state.button_is_disabled}
                        onClick={() => {
                          this.setState(
                            (state) => ({
                              data: {
                                ...state.data,
                              },
                              button_is_disabled: true,
                            }),
                            this.dataSend.bind(this)
                          );
                        }}
                      >
                        Сохранить бриф
                      </button>
                      {this.state.button_is_disabled && (
                        <p className="alert alert-success text-center">
                          Данные отправлены
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="small-screen">
          <div className="container-fluid mt-5">
            <h1>
              На данном устройстве работа не возможна. Используйте устройство с
              более широким экраном.
            </h1>
          </div>
        </div>
      </>
    );
  }

  renderFormErrors() {
    if (!this.state.formErrors) return null;
    let Errors = this.state.formErrors;
    const setState = this.setState.bind(this);
    return (
      <div
        style={{
          position: "fixed",
          width: "50%",
          top: "20px",
          zIndex: 40,
          right: "0px",
        }}
      >
        {this.state.formErrors.map(function (text, i) {
          return (
            <div
              key={i}
              className="alert alert-danger alert-dismissible fade show"
              role="alert"
            >
              {text}
              <button
                onClick={(e) => {
                  let formErrors = Errors;
                  delete formErrors[i];
                  setState({ formErrors });
                }}
                type="button"
                className="close"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
          );
        })}
      </div>
    );
  }

  renderSuccess() {
    return (
      <div
        style={{
          position: "fixed",
          width: "50%",
          top: "20px",
          zIndex: 40,
          right: "0px",
        }}
      >
        <div
          className="alert alert-success alert-dismissible fade show"
          role="alert"
        >
          Бриф добавлен
          <button
            onClick={() => {
              this.setState({
                success: false,
              });
            }}
            type="button"
            className="close"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </div>
    );
  }
}

/*добавление*/
const FormWithSave = graphql(RN_BRIEFING_CREATE, {
  props: ({ mutate }) => {
    return {
      createBriefing: mutate,
    };
  },
  options: (props) => ({
    refetchQueries: ["RnBriefingListPager"],
  }),
})(Form);

const FormAdd = graphql(ME, {
  props: ({ data }) => {
    return {
      loading: data.loading,
      error: data.error,
      src_data: data,
    };
  },
})((props) => {
  if (props.loading) return null;
  if (props.error) return null;
  if (props.src_data == null) {
    console.log("loading bad result ", props);
    return null;
  }

  return <FormWithSave {...props} />;
});

/*изменение*/
export const FormEdit = graphql(RN_BRIEFING, {
  props: ({ data }) => {
    return {
      loading: data.loading,
      error: data.error,
      briefing: data.RnBriefing ? data.RnBriefing : null,
      src_data: data,
    };
  },
  options: (props) => ({
    variables: {
      id: props.match.params.id,
    },
  }),
})((props) => {
  if (props.loading) return "loading...";
  if (props.error) return "error";
  if (props.briefing == null) {
    console.log("loading bad result ", props);
    return null;
  }

  return <Form {...props} />;
});

export const FormWithSaveEdit = graphql(RN_BRIEFING_UPDATE, {
  props: ({ mutate }) => {
    return {
      createBriefing: mutate,
    };
  },
  options: (props) => ({}),
})(FormEdit);

export default FormAdd;
