import React from "react";
import LoggerList from "./Logger";
import { ApolloConsumer } from "react-apollo";
import { useQuery } from "@apollo/react-hooks";
import { ME } from "./../../GraphQL/RNqueries";
import Header from "./../../../template/Header";

const Logger = props => {
  const { loading, error, data } = useQuery(ME, {
    client: props.client
  });

  if (loading) return "Loading...";
  if (error) return `Error! ${error.message}`;
  if (data)
    return (
      <>
        <Header {...props} src_data={data} />
        <LoggerList {...props} />
      </>
    );
};

const LoggerContainer = props => {
  return (
    <ApolloConsumer>
      {client => {
        return <Logger client={client} {...props} />;
      }}
    </ApolloConsumer>
  );
};

export default LoggerContainer;
