import React, { Component } from "react";
import ContactsItem from "./ContactsItem";

class Contacts extends Component {
  state = {};

  static getDerivedStateFromProps(props, oldState) {
    let updateForState = {};

    if (oldState.loaded == null && props) {
      updateForState = {
        ContactsItems: props.parentProps.platform
          ? props.parentProps.platform.ContactsItems
          : [],
        loaded: true
      };
    }

    return updateForState;
  }

  render() {
    return (
      <div className="tab-content" id="myTabContent">
        <div
          className="tab-pane fade show active"
          id="profile2"
          role="tabcard"
          aria-labelledby="profile-tab"
        >
          <div className="top-button mt-2 mb-4">
            <button
              type="button"
              className="pull-right remove-item btn btn-success"
              onClick={() => {
                this.setState({
                  ContactsItems: [
                    ...this.state.ContactsItems,
                    {
                      platformId: this.props.parentState.data.id
                    }
                  ]
                });
              }}
            >
              Добавить контакт
            </button>
          </div>
          {this.state.ContactsItems.map((item, index) => (
            <ContactsItem
              key={`contactItem#${index}`}
              indexKey={index}
              contactsItem={item}
              parentState={this.state}
              parentProps={this.props}
              setParentState={newState => {
                this.setState(state => ({
                  ...newState
                }));
              }}
            />
          ))}
        </div>
      </div>
    );
  }
}

export default Contacts;
