const moment = require("moment/min/moment-with-locales.js");

type Coefficient = {
  coefficient: number;
  month: number;
};

export default function coefficientDetector(
  endDate: string,
  coefficients: Coefficient[]
): number | null {
  let coefficient: number | null = null;

  coefficients.forEach(coeff => {
    if (coeff.month === moment(endDate).month() && !coefficient) {
      coefficient = coeff.coefficient;
    }

    if (coeff.month === 12) {
      coefficient = coeff.coefficient;
    }
  });

  return coefficient;
}
