import gql from "graphql-tag";

export const GET_FIAS_LIST = gql`
  query FiasList($params: ParamsInput) {
    Geo {
      FiasEntityList(params: $params) {
        AOGUID
        FORMALNAME
        SHORTNAME
        Parent {
          AOGUID
          FORMALNAME
          SHORTNAME
          Parent {
            AOGUID
            FORMALNAME
            SHORTNAME
          }
        }
        District {
          Code
          FederalDistrict
        }
        Region {
          AOGUID
          FORMALNAME
          SHORTNAME
          District {
            Code
          }
        }
      }
    }
  }
`;
