import React from "react";
// @ts-ignore
import AsyncSelect from "react-select/lib/Async";
import { ApolloConsumer } from "react-apollo";
import Modal from "./../../RN/Components/Platform/PlatformListModal/Modal";

import { selectReferenceGeo } from "../../RN/GraphQL/RNqueries";
import { useLazyQuery } from "@apollo/react-hooks";
import { GET_CONTENT_REPORT } from "./gql";
import { SelectThematics } from "../../components/select";
import { MlThematics } from "../../graphql/types";

const download = require("downloadjs");

//todo сделать интерфейс для пропсов
export default function Filter({ variables, updateQuery, me, client }: any) {
  const [filterValues, setFilterValues] = React.useState<any>({
    draft: {
      field: "draft",
      operator: "EQ",
      values: ["0"],
    },
    hidden: {
      field: "hidden",
      operator: "EQ",
      values: ["0"],
    },
  });

  const [modal, setModal] = React.useState<boolean>(false);

  const [fileIsNeeded, setFileIsNeeded] = React.useState<boolean>(false);

  const [getContentReport, { data }] = useLazyQuery(GET_CONTENT_REPORT, {
    client,
  });

  if (data && fileIsNeeded) {
    const file = window.atob(data.RN.getContentReport);
    download(file, `Отчет.xlsx`, "application/vnd.ms-excel");
    setFileIsNeeded(false);
  }

  const [thematicsFilter, setThematicsFilter] = React.useState([]);

  return (
    <div
      className="row col-12 mb-3"
      onKeyPress={(e) => {
        if (e.key === "Enter") {
          updateQuery({
            ...variables,
            params: {
              ...variables.params,
              filter: {
                ...filterValues,
              },
            },
          });
        }
      }}
    >
      <div className="col">
        <label
          style={{
            fontSize: "12px",
          }}
        >
          <strong>Наименование сайта</strong>
        </label>
        <br />
        <input
          name="name"
          type="text"
          className="form-control"
          style={{
            minHeight: "40px",
          }}
          value={filterValues.name ? filterValues.name.values[0] : ""}
          onChange={(e) => {
            const value = e.target.value;
            setFilterValues({
              ...filterValues,
              name: {
                field: "name",
                operator: "LIKE",
                values: [value],
              },
            });
          }}
        />
      </div>
      <div className="col">
        <label>
          <strong
            style={{
              fontSize: "12px",
            }}
          >
            Город
          </strong>
        </label>
        <br />
        <ApolloConsumer>
          {(client) => (
            <AsyncSelect
              isClearable
              name="city"
              loadOptions={(inputValue: any) =>
                selectReferenceGeo(inputValue, "MlCityList", client)
              }
              placeholder={""}
              onChange={(city: any) => {
                let filter = { ...filterValues };

                if (city) {
                  const value = city.value;
                  setFilterValues({
                    ...filterValues,
                    city: {
                      field: "city",
                      operator: "IN",
                      values: [value],
                    },
                  });
                } else {
                  delete filter.city;
                  setFilterValues({
                    ...filter,
                  });
                }
              }}
            />
          )}
        </ApolloConsumer>
        <label className="mt-1">
          <input
            type="checkbox"
            onChange={(e) => {
              const value = e.target.checked ? 1 : 0;

              if (value === 1) {
                setFilterValues({
                  ...filterValues,
                  federal: {
                    field: "federal",
                    operator: "EQ",
                    values: [`${value}`],
                  },
                });
              } else {
                let filter = { ...filterValues };
                delete filter.federal;

                setFilterValues({
                  ...filter,
                });
              }
            }}
          />{" "}
          <strong>Федеральная</strong>
        </label>
      </div>
      <div className="col">
        <label
          style={{
            fontSize: "12px",
          }}
        >
          <strong>Тематика</strong>
        </label>
        <br />

        <SelectThematics
          isClearable={true}
          isMulti={true}
          client={client}
          value={thematicsFilter.map((tf: MlThematics) => ({
            label: tf.name,
            value: tf.id,
          }))}
          onChange={(thematicsList) => {
            setThematicsFilter(thematicsList);
            let filter = { ...filterValues };

            if (thematicsList.length) {
              const values = thematicsList.map((item: MlThematics) => item.id);
              setFilterValues({
                ...filterValues,
                thematics: {
                  field: "thematics",
                  operator: "IN",
                  values,
                },
              });
            } else {
              delete filter.thematics;
              setFilterValues({
                ...filter,
              });
            }
          }}
        />
      </div>
      <div className="col">
        <label
          style={{
            fontSize: "12px",
          }}
        >
          <strong>Налогообложение</strong>
        </label>
        <br />
        <select
          className="form-control"
          onChange={(e) => {
            const value = e.target.value;

            if (value === "no") {
              let filter = { ...filterValues };
              delete filter.vat;
              setFilterValues({
                ...filter,
              });
            } else {
              setFilterValues({
                ...filterValues,
                vat: {
                  field: "vat",
                  operator: "SUBEQ",
                  values: [value],
                },
              });
            }
          }}
        >
          <option value="no">Все</option>
          <option value="1">с НДС</option>
          <option value="0">без НДС</option>
        </select>
      </div>
      <div className="col">
        <label
          style={{
            fontSize: "12px",
          }}
        >
          <strong>Прайс заполнен?</strong>
        </label>
        <br />
        <select
          className="form-control"
          onChange={(e) => {
            const value = e.target.value;

            if (value === "no") {
              let filter = { ...filterValues };
              delete filter.inventory;
              setFilterValues({
                ...filter,
              });
            } else {
              setFilterValues({
                ...filterValues,
                inventory: {
                  field: "inventory",
                  operator: "SUBEQ",
                  values: [value],
                },
              });
            }
          }}
        >
          <option value="no">Все</option>
          <option value="1">Да</option>
          <option value="0">Нет</option>
        </select>
      </div>
      <div className="col">
        <label
          style={{
            fontSize: "12px",
          }}
        >
          <strong>Контакты есть?</strong>
        </label>
        <br />
        <select
          className="form-control"
          onChange={(e) => {
            const value = e.target.value;

            if (value === "no") {
              let filter = { ...filterValues };
              delete filter.contacts;
              setFilterValues({
                ...filter,
              });
            } else {
              setFilterValues({
                ...filterValues,
                contacts: {
                  field: "contacts",
                  operator: "SUBEQ",
                  values: [value],
                },
              });
            }
          }}
        >
          <option value="no">Все</option>
          <option value="1">Да</option>
          <option value="0">Нет</option>
        </select>
      </div>
      <div className="col">
        <label
          style={{
            fontSize: "12px",
          }}
        >
          <strong>Тех требования есть?</strong>
        </label>
        <br />
        <select
          className="form-control"
          onChange={(e) => {
            const value = e.target.value;

            if (value === "no") {
              let filter = { ...filterValues };
              delete filter.technical;
              setFilterValues({
                ...filter,
              });
            } else {
              setFilterValues({
                ...filterValues,
                technical: {
                  field: "technical",
                  operator: "SUBEQ",
                  values: [value],
                },
              });
            }
          }}
        >
          <option value="no">Все</option>
          <option value="1">Да</option>
          <option value="0">Нет</option>
        </select>
      </div>
      <div className="col">
        <label
          style={{
            fontSize: "12px",
          }}
        >
          <strong>Статус</strong>
        </label>
        <br />
        <select
          className="form-control"
          onChange={(e) => {
            const value = e.target.value;

            if (value === "no") {
              let filter = { ...filterValues };
              delete filter.checked;
              setFilterValues({
                ...filter,
              });
            } else {
              setFilterValues({
                ...filterValues,
                checked: {
                  field: "checked",
                  operator: "SUBEQ",
                  values: [value],
                },
              });
            }
          }}
        >
          <option value="no">Все</option>
          <option value="1">Проверено байером</option>
          <option value="0">Есть замечания</option>
        </select>
      </div>
      <div className="col"></div>
      <div className="col mt-4">
        <div
          className="btn-group"
          style={{
            position: "relative",
          }}
        >
          <button
            className="btn btn-warning btn-sm"
            disabled={fileIsNeeded}
            onClick={() => {
              setFileIsNeeded(true);
              getContentReport();
            }}
          >
            {fileIsNeeded ? (
              <>
                {" "}
                <div className="spinner-border spinner-border-sm" role="status">
                  <span className="sr-only">Loading...</span>
                </div>{" "}
              </>
            ) : null}

            {fileIsNeeded ? "Сканирование ошибок" : "Ошибки контента"}
          </button>
          <button
            className="btn btn-primary btn-sm ml-1"
            onClick={() => {
              updateQuery({
                ...variables,
                params: {
                  ...variables.params,
                  filter: {
                    ...filterValues,
                  },
                },
              });
            }}
          >
            Фильтровать
          </button>
          <button
            className="btn btn-success ml-1 btn-sm"
            onClick={() => {
              setModal(true);
            }}
          >
            Добавить площадку
          </button>
        </div>
      </div>
      {modal ? (
        <Modal
          // @ts-ignore
          setParentState={() => {
            setModal(false);
          }}
          me={me}
        />
      ) : null}
    </div>
  );
}
