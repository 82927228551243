import TextField from "@material-ui/core/TextField";
import React, { useEffect } from "react";

interface IDiscountProps {
  value?: number | undefined;
  onChange: (value: number | undefined) => void;
  disabled?: boolean;
}

export function Discount({
  disabled = false,
  onChange,
  value,
}: IDiscountProps) {
  const [input, setInput] = React.useState(value);

  useEffect(() => setInput(value), [value]);

  return (
    <TextField
      type="number"
      size="small"
      variant="outlined"
      disabled={disabled}
      inputProps={{
        style: { padding: "5px", fontSize: "12px" },
      }}
      onChange={(e) => {
        setInput(+e.target.value);
      }}
      value={input || ""}
      onBlur={() => {
        onChange(input);
      }}
    />
  );
}
