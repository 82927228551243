import React from "react";

export default function getRating(score: number): any {
  if (!score) return null;

  if (score <= 0.5) {
    return (
      <>
        <i className="fas fa-star"></i>
        <i className="far fa-star"></i>
        <i className="far fa-star"></i>
        <i className="far fa-star"></i>
      </>
    );
  } else if (score <= 0.75) {
    return (
      <>
        <i className="fas fa-star"></i>
        <i className="fas fa-star"></i>
        <i className="far fa-star"></i>
        <i className="far fa-star"></i>
      </>
    );
  } else if (score <= 0.9) {
    return (
      <>
        <i className="fas fa-star"></i>
        <i className="fas fa-star"></i>
        <i className="fas fa-star"></i>
        <i className="far fa-star"></i>
      </>
    );
  } else if (score <= 1) {
    return (
      <>
        <i className="fas fa-star"></i>
        <i className="fas fa-star"></i>
        <i className="fas fa-star"></i>
        <i className="fas fa-star"></i>
      </>
    );
  } else {
    return null;
  }
}
