import React from "react";
import { ItemLogList } from "../../components/ItemLogList/itemLogList";
import Header from "../../template/Header";
import { Box, Typography } from "@material-ui/core";

export function ItemLog({ client }: { client: any }) {
  return (
    <>
      <Header />
      <section className="content">
        <div className="container-fluid platforms-list">
          <div className="table-container">
            <div className="platforms-list-body mt-4 mb-4 row m-1">
              <Typography variant="h5">История закупа</Typography>
              <Box my={2}>
                <Typography>
                  Для уточнения информации примените фильтры ниже
                </Typography>
              </Box>
              <ItemLogList client={client} />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
