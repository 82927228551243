import React from "react";
import gql from "graphql-tag";
import { Mutation, Query } from "react-apollo";
import { RN_PLATFORM_ITEM } from "./../../RN/GraphQL/RNqueries";
import PlatformListItem from "../../RN/Components/Platform/PlatformListItem";

const Container = (props: any) => {
  return (
    <Query
      query={RN_PLATFORM_ITEM}
      variables={{
        id: props.item.id
      }}
    >
      {({ loading, error, data }: any) => {
        if (loading) return null;
        if (error) return `Error! ${error.message}`;

        let platform = data.RnPlatformItem;

        return (
          <Mutation
            mutation={gql`
              mutation RnPlatformUpdateField(
                $id: Int
                $field: String
                $value: Float
              ) {
                RnPlatformUpdateField(id: $id, field: $field, value: $value) {
                  id
                  name
                  url
                  draft
                  checked
                  check_date
                  adequate
                  adequate_comment
                  price_is_needed
                  Price {
                    id
                    vat
                    priceVat
                    comment
                  }
                  ContactsItems {
                    id
                  }
                  InventoryItems {
                    id
                  }
                  City {
                    AOGUID
                    FORMALNAME
                  }
                }
              }
            `}
          >
            {(updateField: any, { data }: any) => (
              <Mutation
                mutation={gql`
                  mutation RnPlatformUpdateFieldString(
                    $id: Int
                    $field: String
                    $value: String
                  ) {
                    RnPlatformUpdateFieldString(
                      id: $id
                      field: $field
                      value: $value
                    ) {
                      id
                      name
                      url
                      draft
                      checked
                      check_date
                      adequate
                      adequate_comment
                      price_is_needed
                      Price {
                        id
                        vat
                        priceVat
                        comment
                      }
                      ContactsItems {
                        id
                      }
                      InventoryItems {
                        id
                      }
                      City {
                        AOGUID
                        FORMALNAME
                      }
                    }
                  }
                `}
              >
                {(updateFieldString: any, { data }: any) => (
                  <PlatformListItem
                    {...props}
                    item={platform}
                    updateFieldString={updateFieldString}
                    updateField={updateField}
                  />
                )}
              </Mutation>
            )}
          </Mutation>
        );
      }}
    </Query>
  );
};

export default Container;
