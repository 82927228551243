import * as React from "react";

interface targetingItem {
  id: number;
  name: string;
  coefficient: number;
  platform_id: number;
  inventoryType: string;
  temp_id: string;
}

const Targeting: React.FC = (props: any) => {
  let list: targetingItem[] = props.data.targeting || [];

  list = list.map((item, index) => ({
    ...item,
    temp_id: Date.now() + "#" + index
  }));

  return (
    <div className={"tab-content mb-3"}>
      <div className="row mt-3">
        <div className="col">
          <button
            className="btn btn-success"
            onClick={() => {
              props.setParentState({
                targeting: [
                  ...list,
                  {
                    temp_id: Date.now() + "#" + list.length,
                    inventoryType: "all"
                  }
                ]
              });
            }}
          >
            Добавить
          </button>
        </div>
      </div>

      {list.length ? (
        <div className="row mt-3">
          <div className="col-6 row">
            <div className="col-4">Название таргетинга</div>
            <div className="col-2">Коэффициент</div>
            <div className="col-2">Распространение</div>
            <div className="col-4"></div>
          </div>
        </div>
      ) : null}

      {list.map((targeting, index) => (
        <div key={targeting.id} className={"row mt-3"}>
          <div className="col-6 row">
            <div className="col-4">
              <input
                type="text"
                className={"form-control"}
                value={targeting.name}
                onChange={e => {
                  const VALUE = e.target.value;
                  list[index] = {
                    ...list[index],
                    name: VALUE
                  };

                  props.setParentState({
                    targeting: [...list]
                  });
                }}
              />
            </div>
            <div className="col-2">
              <input
                type="number"
                className={"form-control"}
                value={targeting.coefficient}
                onChange={e => {
                  const VALUE = +e.target.value;
                  list[index] = {
                    ...list[index],
                    coefficient: VALUE
                  };

                  props.setParentState({
                    targeting: [...list]
                  });
                }}
              />
            </div>
            <div className="col-2">
              <select
                name="inventoryType"
                id="inventoryType"
                className="form-control"
                value={targeting.inventoryType}
                onChange={e => {
                  const VALUE = e.target.value;
                  list[index] = {
                    ...list[index],
                    inventoryType: VALUE
                  };

                  props.setParentState({
                    targeting: [...list]
                  });
                }}
              >
                <option value="all">Все</option>
                <option value="banner">Баннер</option>
                <option value="card">Карточка</option>
                <option value="publication">
                  Размещение публикаций и пресс-релизов
                </option>
                <option value="special">Эксклюзив / спец.проекты</option>
                <option value="additional">Доп. услуги</option>
              </select>
            </div>
            <div className="col-4">
              <button
                className="btn btn-danger btn-sm"
                onClick={() => {
                  let newList = list.filter(
                    item => item.temp_id !== targeting.temp_id
                  );
                  props.setParentState({
                    targeting: [...newList]
                  });
                }}
              >
                Удалить
              </button>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Targeting;
