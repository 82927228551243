import React from "react";
import { useRnMpQuery } from "../../../graphql/types";
import NumberFormat from "react-number-format";
interface ITotalsProps {
  client: any; // клиент аполло
  mpID: number;
  scoringShow: boolean; // показывать скорринг
  orderExport: boolean; // показывать экспорт
}

export function Totals({
  mpID,
  scoringShow,
  orderExport,
  client,
}: ITotalsProps) {
  const { data, error, loading } = useRnMpQuery({
    client,
    variables: {
      id: mpID,
    },
    fetchPolicy: "network-only",
  });

  if (error || loading) {
    return null;
  }

  const isForeign = Boolean(data?.RnMp?.isForeign);
  const publicationInTable = Boolean(data?.RnMp?.publicationIn);

  return (
    <>
      <tr>
        {orderExport ? (
          <>
            <td
              style={{
                background: "#fff",
              }}
            />
            <td
              style={{
                background: "#fff",
              }}
            />
            <td
              style={{
                background: "#fff",
              }}
            />
          </>
        ) : null}
        <td
          style={{
            width: "calc(130px / 3)",
            minWidth: "0px",
            background: "#fff",
          }}
        />
        {scoringShow ? (
          <td
            style={{
              background: "#fff",
            }}
          />
        ) : null}
        <td
          style={{
            background: "#fff",
          }}
        />

        <td
          style={{
            background: "#fff",
          }}
        />
        <td
          style={{
            background: "#fff",
          }}
        />
        <td
          style={{
            background: "#fff",
          }}
        />
        <td
          style={{
            background: "#fff",
          }}
        />
        <td
          className="small-width"
          style={{
            background: "#fff",
          }}
        />
        <td
          className="small-width"
          style={{
            background: "#fff",
          }}
        />
        <td
          className="small-width"
          style={{
            background: "#fff",
          }}
        />
        <td
          className="small-width"
          style={{
            background: "#fff",
          }}
        />
        <td
          className="small-width"
          style={{
            background: "#fff",
          }}
        />
        <td
          className="small-width"
          style={{
            background: "#fff",
          }}
        />
        <td
          style={{
            background: "#fff",
          }}
        >
          {data?.RnMp?.totalCostNoVatDiscount ? (
            <>
              Итого:
              <br />
              <NumberFormat
                displayType="text"
                thousandSeparator={" "}
                decimalSeparator={","}
                decimalScale={2}
                suffix={Boolean(!isForeign) ? " ₽" : ""}
                value={data?.RnMp?.totalCostNoVatDiscount}
              />
            </>
          ) : null}
        </td>
        <td
          className="small-width"
          style={{
            background: "#fff",
          }}
        />
        <td
          className="small-width"
          style={{
            background: "#fff",
          }}
        />
        <td
          style={{
            background: "#fff",
          }}
        >
          {data?.RnMp?.totalCostVatDiscount ? (
            <>
              Итого:
              <br />
              <NumberFormat
                displayType="text"
                thousandSeparator={" "}
                decimalSeparator={","}
                decimalScale={2}
                suffix={!isForeign ? " ₽" : ""}
                value={data?.RnMp?.totalCostVatDiscount}
              />
            </>
          ) : null}
        </td>
        <td
          className="small-width"
          style={{
            background: "#fff",
          }}
        />
        <td
          className="small-width"
          style={{
            background: "#fff",
          }}
        >
          {data?.RnMp?.clicks ? (
            <>
              Итого:
              <br /> {data?.RnMp?.clicks} шт.
            </>
          ) : null}
        </td>
        <td
          className="small-width"
          style={{
            background: "#fff",
          }}
        />
        {publicationInTable ? (
          <td
            style={{
              background: "#fff",
            }}
          />
        ) : null}
        <td
          style={{
            background: "#fff",
          }}
        />
        <td
          style={{
            background: "#fff",
          }}
        >
          {data?.RnMp?.cpc ? (
            <>
              Итого:
              <br /> {data?.RnMp?.cpc?.toFixed(2)} ₽
            </>
          ) : null}
        </td>
        {publicationInTable ? (
          <td
            style={{
              background: "#fff",
            }}
          />
        ) : null}
        <td
          className="small-width"
          style={{
            background: "#fff",
          }}
        />
        <td
          style={{
            background: "#fff",
          }}
        >
          {data?.RnMp?.totalCostPurchaseDiscount ? (
            <>
              Итого:
              <br />
              <NumberFormat
                displayType="text"
                thousandSeparator={" "}
                decimalSeparator={","}
                decimalScale={2}
                suffix={!isForeign ? " ₽" : ""}
                value={data?.RnMp?.totalCostPurchaseDiscount}
              />
            </>
          ) : null}
        </td>
        <td
          className="small-width"
          style={{
            background: "#fff",
          }}
        >
          {data?.RnMp?.profitability ? (
            <>
              Итого:
              <br /> {data?.RnMp?.profitability?.toFixed(2)} %
            </>
          ) : null}
        </td>
        <td
          style={{
            background: "#fff",
          }}
        >
          {data?.RnMp?.margin ? (
            <>
              Итого:
              <br />
              <NumberFormat
                displayType="text"
                thousandSeparator={" "}
                decimalSeparator={","}
                decimalScale={2}
                suffix={!isForeign ? " ₽" : ""}
                value={data?.RnMp?.margin}
              />
            </>
          ) : null}
        </td>
      </tr>
    </>
  );
}
