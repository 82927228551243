import React from "react";
import { Link } from "react-router-dom";
import { graphql } from "react-apollo";

import RN_BRIEFING_REMOVE from "./gql/mutation";
import gql from "graphql-tag";
import { Mutation } from "react-apollo";
const moment = require("moment/min/moment-with-locales.js");

class ListItem extends React.Component {
  removeBriefing(e, id) {
    e.preventDefault();
    if (window.confirm("Вы точно хотите удалить бриф?")) {
      this.props
        .removeBriefing({
          variables: { id: id },
        })
        .then(() => {
          this.setState({ refresher: true });
        })
        .catch((res) => {
          console.log("Ошибка удаления брифа", res);
          console.log(res);
        });
    }
  }

  render() {
    return (
      <>
        <td className="align-middle">
          <Link
            className="text-dark"
            to={`/briefing/view/${this.props.data.id}`}
          >
            ID{this.props.data.id}{" "}
            {this.props.data.entryDate &&
              moment(this.props.data.entryDate).format("DD.MM.YYYY")}
          </Link>
        </td>
        <td className="align-middle">
          {this.props.data.Brand && this.props.data.Brand.name}
        </td>
        <td
          className="align-middle"
          style={{
            maxWidth: "400px",
          }}
        >
          {this.props.data.products}
        </td>
        <td className="align-middle">
          {this.props.data.dateStart &&
            moment(this.props.data.dateStart).format("DD.MM.YYYY")}
          {" - "}
          {this.props.data.dateEnd &&
            moment(this.props.data.dateEnd).format("DD.MM.YYYY")}
        </td>
        <td className="align-middle">
          {this.props.data.Responsible && this.props.data.Responsible.nameFull}
        </td>
        <td className="align-middle">
          {this.props.data.Manager && this.props.data.Manager.nameFull}
        </td>
        <td className="align-middle">
          {this.props.data.MP && (
            <Link to={`/mediaplan/update/${this.props.data.MP.id}`}>
              медиаплан ID{this.props.data.MP.id}{" "}
            </Link>
          )}
          {!this.props.data.MP &&
            (+this.props.src_data.me.comon_id ===
              +this.props.data.Responsible?.id ||
              this.props.data.Users?.map((u) => +u.id)?.includes(
                +this.props.src_data.me.comon_id
              )) && (
              <Mutation
                mutation={gql`
                  mutation RnMpCreate(
                    $userId: Int!
                    $date: String!
                    $briefing_id: Int!
                  ) {
                    RnMpCreate(
                      userId: $userId
                      date: $date
                      briefing_id: $briefing_id
                    ) {
                      id
                    }
                  }
                `}
              >
                {(newItem) => (
                  <Mutation
                    mutation={gql`
                      mutation RnMpItemCreate($mpId: Int!) {
                        RnMpItemCreate(mpId: $mpId) {
                          id
                        }
                      }
                    `}
                  >
                    {(newMpItem) => (
                      <button
                        className="btn btn-dark"
                        onClick={(e) => {
                          let rn = "/rn";
                          if (
                            document.location.origin ===
                            "http://rn.lab.delta-plan.ru"
                          ) {
                            rn = "";
                          }

                          e.preventDefault();
                          const date = new Date().toISOString();

                          newItem({
                            variables: {
                              userId: this.props.src_data.me.id,
                              date: date,
                              briefing_id: this.props.data.id,
                            },
                          }).then((res) => {
                            newMpItem({
                              variables: {
                                mpId: res.data.RnMpCreate.id,
                              },
                            }).then(
                              () =>
                                (document.location = `${rn}/mediaplan/update/${res.data.RnMpCreate.id}`)
                            );
                          });
                        }}
                      >
                        Создать медиаплан
                      </button>
                    )}
                  </Mutation>
                )}
              </Mutation>
            )}
        </td>
        <td>
          <Link
            className="btn btn-secondary"
            to={`/briefing/clone/${this.props.data.id}`}
          >
            Копировать
          </Link>
        </td>
        <td className="align-middle">
          <Link
            className="icon-link mr-2"
            to={`/briefing/update/${this.props.data.id}`}
          >
            <i className="icon-edit-action size32"></i>
          </Link>

          {+this.props.src_data.me.id === 1 || this.props.src_data.me.top ? (
            <a
              href="#"
              className="icon-link"
              onClick={(e) => {
                this.removeBriefing(e, this.props.data.id);
              }}
              return="false"
            >
              <i className="icon-remove-action size32"></i>
            </a>
          ) : null}
        </td>
      </>
    );
  }
}

const RemoveItem = graphql(RN_BRIEFING_REMOVE, {
  props: ({ mutate }) => {
    return {
      removeBriefing: mutate,
    };
  },
  options: (props) => ({
    refetchQueries: ["RnBriefingListPager"],
  }),
})(ListItem);

export default RemoveItem;
