import React from "react";
import { PartnerPropsType } from "../../../../types/index.types";
import Mutation from "react-apollo/Mutation";
import { RN_PLATFORM_UPDATE_FIELD } from "../../../GraphQL/RNmutations";
//@ts-ignore
import AsyncSelect from "react-select/lib/Async";
import { selectQuery } from "../../Briefing/gql/query";
import { ApolloConsumer } from "react-apollo";
import { RN_MP_ITEM } from "../../../GraphQL/RNqueries";

export function Partner({ platform, mPlanID }: PartnerPropsType) {
  const [isSearch, setSearch] = React.useState<boolean>(false);

  return (
    <div className="d-flex align-items-center">
      <div
        style={{
          width: "180px",
        }}
      >
        {isSearch ? (
          <Mutation
            mutation={RN_PLATFORM_UPDATE_FIELD}
            refetchQueries={[
              {
                query: RN_MP_ITEM,
                variables: {
                  id: mPlanID,
                },
              },
            ]}
          >
            {(update: any) => (
              <ApolloConsumer>
                {(client) => (
                  <AsyncSelect
                    defaultValue={
                      platform?.Seller
                        ? {
                            value: platform.Seller.id,
                            label: platform.Seller.name,
                          }
                        : {}
                    }
                    name="partner_id"
                    loadOptions={(inputValue: string) =>
                      selectQuery(inputValue, "MLPartnerList", client)
                    }
                    onChange={({ value }: any) => {
                      update({
                        variables: {
                          id: platform.id,
                          field: "seller",
                          value,
                        },
                      }).then(() => {
                        setSearch(false);
                      });
                    }}
                  />
                )}
              </ApolloConsumer>
            )}
          </Mutation>
        ) : (
          <>{platform && <span>{platform?.Seller?.name}</span>}</>
        )}
      </div>
      <div>
        {platform && (
          <i
            onClick={() => {
              setSearch(!isSearch);
            }}
            className="ml-1 fas fa-edit"
          />
        )}
      </div>
    </div>
  );
}
