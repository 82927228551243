import React from "react";
import { Query } from "react-apollo";
import { RN_MP_ITEM } from "../../GraphQL/RNqueries";
import MpItem from "./MediaPlanItemsListItem";

const MpItemContainer = (props) => (
  <Query
    query={RN_MP_ITEM}
    variables={{
      id: props.id,
    }}
  >
    {({ loading, error, data }) => {
      if (loading) return null;
      if (error) return `Error! ${error}`;

      return (
        <MpItem
          {...props}
          disabled={props.disabled}
          indexKey={props.indexKey}
          parentState={props.parentState}
          parentProps={props.parentProps}
          publication_in_table={props.publication_in_table}
          mpItem={{
            id: props.id,
            ...data.RnMpItem,
          }}
        />
      );
    }}
  </Query>
);

export default MpItemContainer;
