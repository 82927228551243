import React from "react";
import "./Modal.css";
import { ApolloConsumer } from "react-apollo";
import {
  RN_PLATFORM_CREATE,
  RN_UPLOAD_FILE,
} from "../../../GraphQL/RNmutations";
import { graphql } from "react-apollo";
import FileInput from "../../../../components/fileInput/fileInput";
import GeoSelect from "../../../../components/geoSelect";
//moment
const moment = require("moment/min/moment-with-locales.js");

const Modal = (props) => {
  const [state, setState] = React.useState({
    disabled: false,
    data: {
      priceFile: [],
    },
    fileList: [],
  });

  const author =
    props.parentProps && props.parentProps.me ? props.parentProps.me : props.me;

  return (
    <div className="rn_modal">
      <div className="">
        <label>Наименование сайта (url)</label>
        <input
          className="form-control"
          type="text"
          onChange={(e) => {
            const value = e.target.value;
            setState({
              ...state,
              data: {
                ...state.data,
                name: value,
                priceFile: [],
              },
            });
          }}
        />
      </div>
      <br />
      <div className="">
        <label>Город</label>

        <ApolloConsumer>
          {(client) => (
            <GeoSelect
              value={null}
              isMulti={true}
              client={client}
              onChange={(city) => {
                let regions = [];
                let cities = [];

                city.forEach((city) => {
                  if (city.District) {
                    regions.push(city.AOGUID);
                    return null;
                  }

                  cities.push(city.AOGUID);
                });

                setState({
                  ...state,
                  data: {
                    ...state.data,
                    city: cities,
                    region: regions,
                  },
                });
              }}
            />
          )}
        </ApolloConsumer>
      </div>
      <br />
      <label>
        <input
          type="checkbox"
          onClick={(e) => {
            const value = e.target.checked ? 1 : 0;

            setState({
              ...state,
              data: {
                ...state.data,
                federal: value,
              },
            });
          }}
        />{" "}
        Федеральная площадка
      </label>
      <br />
      <div className="">
        <label>Налогообложение</label>
        <select
          className="form-control"
          onChange={(e) => {
            setState({
              ...state,
              data: {
                ...state.data,
                Price: {
                  authorId: author.id,
                  vat: +e.target.value,
                  updateDate: new Date().toISOString(),
                  startDate: new Date().toISOString(),
                  endDate: moment().add(1, "years").format(),
                },
              },
            });
          }}
        >
          <option value="" hidden></option>
          <option value="1">с НДС</option>
          <option value="2">без НДС</option>
          <option value="4">с НДС / без НДС</option>
        </select>
      </div>
      <br />
      <textarea
        className="w-100"
        placeholder={
          "Пропишите в поле комментарий: контакт площадки, условия оплаты и скидку ДП. Приложите прайс, если он у вас есть"
        }
        onChange={(e) => {
          setState({
            ...state,
            data: {
              ...state.data,
              taskComment: e.target.value,
            },
          });
        }}
      />
      <br />
      <FileInput
        gql={RN_UPLOAD_FILE}
        uploaded={(file) => {
          setState({
            ...state,
            fileList: [
              ...state.fileList,
              {
                ...file,
              },
            ],
            data: {
              ...state.data,
              priceFile: [...state.data.priceFile, file.id],
            },
          });
        }}
      />
      <br />

      <ul className="mt-1">
        {state.fileList.map((file) => (
          <li key={file.id}>
            {file.name}{" "}
            <button
              className="btn btn-sm btn-danger"
              onClick={() => {
                setState({
                  ...state,
                  data: {
                    ...state.data,
                    priceFile: [
                      ...state.data.priceFile.filter((i) => i !== file.id),
                    ],
                  },
                  fileList: [...state.fileList.filter((i) => i.id !== file.id)],
                });
              }}
            >
              Удалить
            </button>
          </li>
        ))}
      </ul>

      <br />
      <div className="btn-group">
        {state.data.name && state.data.Price && state.data.Price.vat ? (
          <button
            className="btn btn-success"
            disabled={state.disabled}
            onClick={() => {
              setState({
                ...state,
                data: {
                  ...state.data,
                  Price: {
                    ...state.data.Price,
                  },
                },
                disabled: true,
              });

              if (!state.data.name) {
                alert("Не указано имя площадки");
              } else {
                props
                  .createPlatform({
                    variables: {
                      platform: {
                        authorId: author.id,
                        authorComonID: author.comon_id,
                        DiscountItems: [],
                        updateDate: new Date().toISOString(),
                        draft: 0,
                        ...state.data,
                      },
                    },
                    refetchQueries: ["RnPlatformList"],
                  })
                  .then(({ data }) => {
                    alert("Площадка добавлена!");

                    setTimeout(() => {
                      let rn = "/rn";
                      if (
                        document.location.origin ===
                        "http://rn.lab.delta-plan.ru"
                      ) {
                        rn = "";
                      }

                      if (
                        props &&
                        props.match &&
                        props.match.path === "/mediaplan/update/:id"
                      ) {
                        props.setParentState({
                          modal: false,
                        });
                      } else {
                        document.location = `${rn}/platform/update/${data.RnPlatformCreate.id}`;
                      }
                    }, 500);
                  })
                  .catch(() => {
                    alert("Ошибка при создании!");
                  });
              }
            }}
          >
            Создать
          </button>
        ) : null}

        <button
          className="btn btn-danger ml-1"
          onClick={() => {
            props.setParentState({
              modal: !props.modal,
            });
          }}
        >
          Отменить
        </button>
      </div>
    </div>
  );
};

/*добавление*/
const PlatformWithSave = graphql(RN_PLATFORM_CREATE, {
  props: ({ mutate }) => {
    return {
      createPlatform: mutate,
    };
  },
  options: () => ({
    refetchQueries: ["RnPlatformList"],
  }),
})(Modal);

export default PlatformWithSave;
