import React from "react";

const City = ({
  item,
  handleChangeGeoDataCity,
  src_district,
  src_subject,
  handleChange,
  data_geo_tree
}) => {
  const isChecked = () => {
    if (data_geo_tree.some(i => i === item.AOGUID)) {
      return true;
    }
    return false;
  };

  return (
    <li>
      <label>
        <input
          checked={isChecked()}
          type="checkbox"
          onChange={e => {
            const VALUE = e.target.checked;
            let data = data_geo_tree.filter(i => i !== item.AOGUID);
            data = data.filter(i => i !== src_district.Code);
            data = data.filter(i => i !== src_subject.AOGUID);

            if (VALUE) {
              data = [
                ...data,
                item.AOGUID,
                src_district.Code,
                src_subject.AOGUID
              ];
            } else {
              data = [...data, src_district.Code, src_subject.AOGUID];
            }

            handleChange(data, VALUE);

            handleChangeGeoDataCity(
              data,
              src_district,
              src_subject,
              item.AOGUID,
              VALUE
            );
          }}
        />{" "}
        {item.SHORTNAME} {item.FORMALNAME}
      </label>
    </li>
  );
};

export default City;
