import * as React from "react";
import { useMutation } from "@apollo/react-hooks";
import { ApolloConsumer } from "react-apollo";

interface IFileInputProps {
  gql: any;
  uploaded: (id: number) => {};
  client?: any;
  list: boolean;
  className?: string | undefined;
}

function FileInput({ gql, uploaded, client }: IFileInputProps): any {
  const [upload] = useMutation(gql, {
    client: client,
  });

  return (
    <>
      <input
        type="file"
        required
        multiple
        onChange={({ target: { files } }) => {
          if (files) {
            for (let i = 0; i < files.length; i++) {
              try {
                upload({ variables: { file: files[i] } }).then((res) => {
                  uploaded(res.data.RnUploadFile);
                });
              } catch (e) {
                console.error(e);
              }
            }
          }
        }}
      />
    </>
  );
}

//todo в новых версиях можно будет спилить
export default function FileInputWrapper(props: any) {
  return (
    <ApolloConsumer>
      {(client) => <FileInput {...props} client={client} />}
    </ApolloConsumer>
  );
}
