import React from "react";
import ReactDOM from "react-dom";
import StyledModal from "../../../../components/modal/modal";
import { Link } from "react-router-dom";
import styled from "styled-components";
import CloseIcon from "../../../../components/styled/closeIcon";
import DateTime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import { useQuery } from "@apollo/react-hooks";
import { GET_REPORT_LIST } from "./report.modal/gql";
import { ApolloConsumer } from "react-apollo";

const A = styled(Link)`
  border: 1px solid #007bff;
  padding: 0.5rem;

  &:hover {
    text-decoration: none;
  }
`;
//todo сделать тип для элемента списка
interface IReportModalProps {
  readonly setClose: () => void;
  readonly client: any;
  readonly inventoryID: number | string;
}

function ReportModal({ setClose, client, inventoryID }: IReportModalProps) {
  const [div, setDiv] = React.useState<any>(undefined);

  React.useEffect(() => {
    const modalDiv = document.getElementById("modal-report");
    setDiv(modalDiv);
  }, []);

  const [variables, setVariables] = React.useState({
    params: {
      count: 999,
      offset: 0,
      filter: [
        {
          field: "inventory_id",
          operator: "EQ",
          values: [inventoryID],
        },
      ],
    },
  });

  const { data, fetchMore } = useQuery(GET_REPORT_LIST, {
    client,
    variables,
  });

  function fetch(variables: any) {
    fetchMore({
      variables,
      updateQuery: (prev, { fetchMoreResult }) => {
        return fetchMoreResult;
      },
    });
  }

  if (div) {
    return ReactDOM.createPortal(
      <StyledModal
        style={{
          top: "30%",
          zIndex: 99,
          left: "50%",
          transform: "translateX(-50%)",
          minWidth: "1000px",
          overflow: "inherit",
          padding: "1rem",
          height: "auto",
        }}
      >
        <div className="row border-bottom text-left pb-2">
          <div className="col-3">
            <h6>История отчета</h6>
          </div>
          <div className="col-3">
            <DateTime
              defaultValue={"Дата старт"}
              value={undefined}
              dateFormat={"DD.MM.Y"}
              timeFormat={false}
              closeOnSelect
              locale={"ru"}
              onChange={(date) => {
                const sortedFilters = variables.params.filter.filter(
                  (filter: any) => filter.operator !== ">"
                );
                const newVariables = {
                  ...variables,
                  params: {
                    ...variables.params,
                    filter: [
                      ...sortedFilters,
                      {
                        field: "date",
                        operator: ">",
                        values: [
                          //@ts-ignore
                          date.format(),
                        ],
                      },
                    ],
                  },
                };

                setVariables(variables);
                fetch(newVariables);
              }}
            />
          </div>
          <div className="col-3">
            <DateTime
              defaultValue={"Дата финиш"}
              value={undefined}
              dateFormat={"DD.MM.Y"}
              timeFormat={false}
              closeOnSelect
              locale={"ru"}
              onChange={(date) => {
                const sortedFilters = variables.params.filter.filter(
                  (filter: any) => filter.operator !== "<"
                );
                const newVariables = {
                  ...variables,
                  params: {
                    ...variables.params,
                    filter: [
                      ...sortedFilters,
                      {
                        field: "date",
                        operator: "<",
                        values: [
                          //@ts-ignore
                          date.format(),
                        ],
                      },
                    ],
                  },
                };

                fetch(newVariables);
              }}
            />
          </div>
          <div className="col-3">
            <A to="/report/mplan-item">Смотреть все отчеты</A>
            <CloseIcon onClick={setClose} />
          </div>
        </div>
        <div className="row" style={{ height: "300px", overflow: "auto" }}>
          <div className="col">
            <table className="table table-striped table-bordered">
              <thead>
                <tr>
                  <th>Площадка</th>
                  <th>Вид рекламы</th>
                  <th>Месяц / Период</th>
                  <th>CTR</th>
                  <th>Клики</th>
                  <th>Показы</th>
                  {/*<th>Итоговая стоимость с учетом НДС</th>*/}
                  {/*<th>% расходования бюджета</th>*/}
                  {/*<th>Итого количество единиц за период</th>*/}
                  {/*<th>% выполнения плана</th>*/}
                  {/*<th>Количество показов анонса</th>*/}
                  {/*<th>Количество просмотров/ прочтений</th>*/}
                  {/*<th>CPV</th>*/}
                  {/*<th>Визиты</th>*/}
                  {/*<th>Время на сайте, мин:сек</th>*/}
                  {/*<th>Глубина просмотра, стр</th>*/}
                  {/*<th>Показатель отказов</th>*/}
                  {/*<th>Конверсия по любой цели, %</th>*/}
                </tr>
              </thead>
              <tbody>
                {data &&
                  data.RN.mPlanItemReportList.map((item: any) => (
                    <tr key={item.id}>
                      <td>{item.platformName}</td>
                      <td>{item.inventoryName}</td>
                      <td>
                        {new Date(item.begDate).toLocaleDateString()}{" "}
                        {new Date(item.endDate).toLocaleDateString()}
                      </td>
                      <td>{item.rCTR} %</td>
                      <td>{item.rClicks}</td>
                      <td>{item.rImpressions}</td>
                      {/*<td>{item.rTotalCost}</td>*/}
                      {/*<td>{item.rSpend} %</td>*/}
                      {/*<td>{item.rCount}</td>*/}
                      {/*<td>{item.rPlan} %</td>*/}
                      {/*<td>{item.rLeadImpressions}</td>*/}
                      {/*<td>{item.rView}</td>*/}
                      {/*<td>{item.rCPV}</td>*/}
                      {/*<td>{item.rVisits}</td>*/}
                      {/*<td>{item.rTime}</td>*/}
                      {/*<td>{item.rViewDepth}</td>*/}
                      {/*<td>{item.rRejects}</td>*/}
                      {/*<td>{item.rConversion}</td>*/}
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </StyledModal>,
      div
    );
  }
  return null;
}

const ReportModalContainer = (props: any) => {
  return (
    <ApolloConsumer>
      {(client) => <ReportModal {...props} client={client} />}
    </ApolloConsumer>
  );
};

export default ReportModalContainer;
