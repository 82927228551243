import React from 'react';


import {
    Editor,
    EditorState,
    convertFromHTML,
    ContentState
} from 'draft-js';
import { convertToHTML } from 'draft-convert';

function TemplateEditor(props) {

    let markUp = convertFromHTML(props.template);

    const array = ContentState.createFromBlockArray(
        markUp.contentBlocks,
        markUp.entityMap
    );

    const [editorState, setEditorState] = React.useState(
        EditorState.createWithContent(array)
    );

    const handleChange = content => {
        setEditorState(content);
        const message = convertToHTML(content.getCurrentContent());
        props.setParentState({
            'message': message
        });
    };

    return (
        <Editor
            className="form-control"
            editorState={ editorState }
            onChange={ res=> {
                handleChange(res);
            }}
        />

    );
}

export default TemplateEditor;