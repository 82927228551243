import React from "react";
//типы инветаря
import { graphql } from "react-apollo";
//запросы к api

import { RN_PLATFORM, RN_INVENTORY } from "./../../GraphQL/RNqueries";

const moment = require("moment/min/moment-with-locales.js");

class InventoryLight extends React.Component {
  state = {};

  static getDerivedStateFromProps(props, oldState) {
    let updateForState = {};
    if (oldState.loaded == null && props.inventory) {
      updateForState = {
        inventory: { ...props.inventory },
        platform: {
          ...props.platform
        },
        loaded: true
      };
    }

    return updateForState;
  }

  render() {
    const origin = document.location.origin;

    const coefficientArray =
      this.state.platform.CoefficientItems.length > 0 &&
      this.state.platform.CoefficientItems.sort(
        (itemA, itemB) => itemA.month - itemB.month
      );

    return (
      <section className="content">
        <div className="container-fluid mediaplans-list-block">
          <div className="table-container">
            <div
              className="site-logo col-12 row mb-3 mt-3"
              style={{ maxWidth: "1400px", margin: "0 auto" }}
            >
              <div className="col-2 mt-2">
                {this.props.platform.logo.length &&
                this.props.platform.logo[
                  this.props.platform.logo.length - 1
                ] ? (
                  <img
                    style={{
                      width: "145px",
                      height: "auto"
                    }}
                    src={`${origin}/${
                      this.props.platform.logo[
                        this.props.platform.logo.length - 1
                      ].path
                    }`}
                    alt=""
                    title=""
                    className="img-fluid"
                  />
                ) : null}
              </div>
              <div className="title col-3 align-self-center">
                <h1>
                  {this.props.platform
                    ? this.props.platform.name
                    : "Не указано"}
                </h1>
              </div>
            </div>
            <div
              className="container-fluid"
              style={{ maxWidth: "1400px", margin: "0 auto" }}
            >
              <div className="row">
                {this.state.inventory.image.length > 0 && (
                  <div className="col-6">
                    <img
                      style={{ width: "100%" }}
                      alt=""
                      src={`${origin}/${
                        this.state.inventory.image[
                          this.state.inventory.image.length - 1
                        ].path
                      }`}
                    />
                  </div>
                )}
                <div className="col-6">
                  <p>Сезонные коэффициенты</p>
                  <table className="table">
                    <tbody>
                      <tr>
                        <td>Месяц</td>
                        <td>Коэффициент</td>
                      </tr>
                      {this.state.platform.CoefficientItems.length > 0 &&
                        coefficientArray.map((item, index) => (
                          <tr key={`items-${index}`}>
                            <td>
                              {item.month !== null &&
                                moment()
                                  .month(item.month)
                                  .format("MMMM")}
                            </td>
                            <td>{item.coefficient && item.coefficient}</td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
                <div className="col-6">
                  <p>Технические требования</p>
                  {this.state.inventory.specifications}
                  {this.state.inventory.type === "banner"
                    ? this.state.platform.Price.comment
                    : null}
                  {this.state.inventory.type !== "banner"
                    ? this.state.platform.Price
                        .technical_requirements_publication
                    : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

/*получаем данные*/
const GetPlatform = graphql(RN_PLATFORM, {
  props: ({ data }) => {
    return {
      loading: data.loading,
      error: data.error,
      platform: data.RnPlatform ? data.RnPlatform : null,
      user: data.me ? data.me : null
    };
  },
  options: props => ({
    variables: {
      id: props.match.params.platformId
    }
  })
})(props => {
  if (props.loading) return null;
  if (props.error) return null;
  if (props.platform == null) {
    console.log("loading bad result ", props);
    return null;
  }

  return <InventoryLight {...props} />;
});

export const InventoryLightContainer = graphql(RN_INVENTORY, {
  props: ({ data }) => {
    return {
      loading: data.loading,
      error: data.error,
      inventory: data.RnInventory ? data.RnInventory : null
    };
  },
  options: props => ({
    variables: {
      id: props.match.params.inventoryId
    }
  })
})(props => {
  //if(props.loading)return null;
  if (props.error) return null;
  // if(props.inventory == null) {
  //     console.log("loading bad result ", props );
  //     return null;
  // }

  return <GetPlatform {...props} />;
});
