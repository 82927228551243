import React from 'react';
import './Modal.css';
import DatePicker from 'react-date-picker';
const moment = require("moment/min/moment-with-locales.js");


const DatePickerModal = props => {



    let minDate = props.data.Briefing ? new Date(props.data.Briefing.dateStart) : new Date();

    const [state,setState] = React.useState({});

    return(
        <div className="rn_modal_time">

                <div className="row">
                    <label htmlFor="" className="col-6">Дата старт</label>
                    <div className="col-6">
                        <DatePicker
                            onChange={ date => {

                                if(date > minDate) {
                                    setState({
                                        ...state,
                                        start_date: date
                                    });
                                }


                            }}
                            minDate={ minDate }
                            value={ state.start_date }
                            format={'dd.MM.y'}
                            calendarIcon = { null }
                        />
                    </div>
                </div>


                <div className="row">
                    <label htmlFor="" className="col-6">Дата финиш</label>
                    <div className="col-6">
                        <DatePicker
                            onChange={ date => {

                                if(date > minDate) {
                                    setState({
                                        ...state,
                                        end_date: date
                                    });
                                }

                            }}
                            value={ state.end_date }
                            format={'dd.MM.y'}
                            calendarIcon = { null }
                        />
                    </div>
                </div>

                <div className="row mt-3 mr-3 float-right">

                    <button
                        className="btn btn-success"
                        disabled={!(state.start_date > minDate && state.end_date > minDate && state.end_date >= state.start_date)}
                        onClick={()=>{
                            props.updateFieldString({
                                variables: {
                                    id: props.data.id,
                                    field: 'mp_item_date',
                                    value: `${moment(state.start_date).format()}#${moment(state.end_date).format()}`
                                },
                                refetchQueries: [
                                    "RnMp",
                                    'RnMpItem'
                                ]
                            }).then(()=>{
                                props.history.go(0);
                            });
                        }}
                    >Применить</button>
                    <button
                        className="btn btn-danger ml-1"
                        onClick={()=> {
                            props.setParentState({
                                "date_picker_modal": false
                            })
                        }}
                    >Отменить</button>

                </div>


        </div>
    );
};

export default DatePickerModal;