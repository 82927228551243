import React, { Component } from 'react';
import SupercomItem from './SupercomItem';
//moment
const moment = require("moment/min/moment-with-locales.js");

class Supercom extends Component {
    state = {};

    static getDerivedStateFromProps(props, oldState) {
        let updateForState = {};
        if(!oldState.loaded) {
            updateForState = {
                SupercomItems: props.parentProps.platform ? props.parentProps.platform.SupercomItems : [],
                _supercomItems: props.parentState._supercomItems ? props.parentState._supercomItems : [],
                loaded: true
            };
        }
        return updateForState;
    }

    componentDidMount(){
        this.setState(state => ({
            _updateDate: moment().format()
        }));
    }

    render() {
        return (
            <div className="tab-content" id="myTabContent">
                <div className="tab-pane fade show active" id="profile2" role="tabcard" aria-labelledby="profile-tab">
                    <div className="top-button mt-2 mb-4">
                        <button
                            type="button"
                            className="pull-right remove-item btn btn-success"
                            onClick={()=>{
                                let list = this.state.SupercomItems;
                                let obj = {
                                    authorId: this.props.parentState.data.authorId,
                                    updateDate: this.state._updateDate
                                };
                                list.push(obj);
                                this.setState({
                                    SupercomItems: list
                                });

                                this.props.GetSupercomItems(this.state.SupercomItems);
                            }}
                        >
                            Добавить суперком
                        </button>
                    </div>
                    <div className="card-body" style={{margin: '1rem 0'}}>
                        <div className="row mb-2">
                            <div className="col-2">
                                <b>Действует С</b>
                            </div>
                            <div className="col-2">
                                <b>База для начисления</b>
                            </div>
                            <div className="col-1">
                                <b>Оборот<br/> ОТ</b>
                            </div>
                            <div className="col-1 pr-0">
                                <b>Оборот<br/> ДО</b>
                            </div>
                            <div className="col-1">
                                <b>Размер<br/> ДП</b>
                            </div>
                            <div className="col-2">
                                <b>Автор</b>
                            </div>
                            <div className="col-2">
                                <b>Актуализировано</b>
                            </div>
                            <div className="col-1"></div>
                        </div>
                        { this.state.SupercomItems.map((item,index) =>
                            <SupercomItem
                                key={`supercomItem#${index}`}
                                indexKey={index}
                                supercomItem={item}
                                parentState={ this.state }
                                parentProps={ this.props }
                                setParentState = {
                                    newState => {
                                        this.setState(state => ({
                                            ...newState
                                        }));
                                    }
                                }
                            />
                        )}
                    </div>
                </div>
            </div>

        );
    }
}

export default Supercom;