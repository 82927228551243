import React from "react";
import { Box, Button, Grid } from "@material-ui/core";
import { DatePicker } from "@material-ui/pickers";
import { Alert } from "@material-ui/lab";
import { useOrderReportXlsLazyQuery } from "../../../graphql/types";
const download = require("downloadjs");
export function Order({ client }: { client: any }) {
  const [dates, setDates] = React.useState<{
    dateStart: Date | null;
    dateFinish: Date | null;
  }>({
    dateStart: new Date(),
    dateFinish: new Date(),
  });

  const [confirm, setConfirm] = React.useState(false);
  const [fileName, setFileName] = React.useState("");

  const [getReport] = useOrderReportXlsLazyQuery({
    client: client,
    onCompleted: (res) => {
      if (res?.RN?.orderReportXls) {
        const file = window.atob(res?.RN?.orderReportXls);
        download(file, fileName, "application/vnd.ms-excel");
      }
    },
    variables: {
      dateFinish: dates.dateFinish,
      dateStart: dates.dateStart,
    },
  });

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={2}>
          <DatePicker
            fullWidth
            size="small"
            disableToolbar
            autoOk
            variant="inline"
            inputVariant="outlined"
            label="Дата старт"
            format="dd.MM.yyyy"
            value={dates.dateStart}
            maxDate={dates.dateFinish}
            defaultValue={null}
            disableFuture
            onChange={(dateStart) => {
              setConfirm(false);
              setDates({
                ...dates,
                dateStart,
              });
            }}
          />
        </Grid>
        <Grid item xs={2}>
          <DatePicker
            fullWidth
            size="small"
            disableToolbar
            autoOk
            variant="inline"
            inputVariant="outlined"
            label="Дата финиш"
            format="dd.MM.yyyy"
            value={dates.dateFinish}
            defaultValue={null}
            minDate={dates.dateStart}
            disableFuture
            onChange={(dateFinish) => {
              setConfirm(false);
              setDates({
                ...dates,
                dateFinish,
              });
            }}
          />
        </Grid>
        <Grid item xs={2}>
          <Button
            variant="outlined"
            color="primary"
            fullWidth
            disabled={!dates.dateFinish || !dates.dateStart}
            onClick={() => {
              if (dates.dateStart && dates.dateFinish) {
                setConfirm(true);
                setFileName(
                  `${
                    dates.dateStart
                      ? new Date(dates.dateStart).toLocaleDateString()
                      : ""
                  }-${
                    dates.dateFinish
                      ? new Date(dates.dateFinish).toLocaleDateString()
                      : ""
                  }.xlsx`
                );
              }
            }}
          >
            Сформировать отчет
          </Button>
        </Grid>
      </Grid>

      {confirm && (
        <Box my={2}>
          <Alert icon={false} severity="success">
            Файл{" "}
            <Button
              href="#"
              onClick={(e) => {
                e.preventDefault();
                getReport();
              }}
            >
              {fileName}
            </Button>
          </Alert>
        </Box>
      )}
    </>
  );
}
