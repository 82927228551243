import MediaplanBriefing from "./MediaplanBriefing";
import { RN_BRIEFING_UPDATE_FIELD } from "./../../Briefing/gql/mutation";
import { graphql } from "react-apollo";

export const BriefingLiveEdit = graphql(RN_BRIEFING_UPDATE_FIELD, {
  props: ({ mutate }) => {
    return {
      updateField: mutate
    };
  },
  options: props => ({
    refetchQueries: ["RnMp"]
  })
})(MediaplanBriefing);

export default BriefingLiveEdit;
