import * as React from "react";
import { InventoryFieldType } from "../../../../types/index.types";
import { useEffect } from "react";
import { TextareaAutosize } from "@material-ui/core";

export function Inventory({
  inventoryName,
  onChange,
  onDelete,
  onShowModal,
  onFastCreate,
  disabled,
}: InventoryFieldType) {
  const input = React.useRef();

  useEffect(() => {
    if (inventoryName && input) {
      //@ts-ignore
      input.current.value = inventoryName;
    } else {
      //@ts-ignore
      input.current.value = "";
    }
  });

  return (
    <>
      <TextareaAutosize
        //@ts-ignore
        ref={input}
        style={{
          width: "100%",
          display: "block",
        }}
        defaultValue={inventoryName}
        onChange={(e: any) => {
          onChange(e.target.value);
        }}
      />

      {inventoryName && inventoryName !== "" && (
        <a
          href="#"
          className="text-danger"
          style={{
            textDecoration: "underline",
          }}
          onClick={(e) => {
            e.preventDefault();
            //@ts-ignore
            input.current.value = "";
            onDelete();
          }}
        >
          Удалить
        </a>
      )}

      {(!inventoryName || inventoryName === "") && (
        <div className="btn-group">
          <button
            className="btn btn-primary"
            disabled={disabled}
            style={{
              opacity: "1",
              backgroundColor: disabled ? "#62aeff" : "",
            }}
            onClick={() => {
              onShowModal();
            }}
          >
            Выбрать
          </button>
          <br />
          <button
            className="btn"
            style={{
              opacity: "1",
              color: "#1464f6",
              backgroundColor: "#e4f3ff",
              borderColor: "#1464f6",
            }}
            onClick={() => {
              onFastCreate();
            }}
          >
            Создать налету
          </button>
        </div>
      )}
    </>
  );
}
