import React, { Component } from "react";
import { RN_UPLOAD_FILE } from "../../../../GraphQL/RNmutations";
import { Mutation } from "react-apollo";

class Audience extends Component {
  state = {
    files: [],
  };

  static getDerivedStateFromProps(props, oldState) {
    //получение данных от сервера
    let updateForState = {};
    if (oldState.updated == null) {
      updateForState = {
        files: props.parentState.files,
        updated: true,
      };
    }
    return updateForState;
  }

  /*Файл для загрузки*/
  renderFileInput(field_name) {
    return (
      <React.Fragment>
        <div>
          <Mutation mutation={RN_UPLOAD_FILE}>
            {(mutate) => (
              <div className="input-group">
                <div className="custom-file">
                  <input
                    type="file"
                    className="custom-file-input form-control"
                    id="inputGroupFile04"
                    aria-describedby="inputGroupFileAddon04"
                    required
                    multiple
                    onChange={({ target: { validity, files } }) => {
                      //validity.valid &&
                      //сделал две ветки на отображение и загрузку
                      for (let i = 0; i < files.length; i++) {
                        mutate({ variables: { file: files[i] } }).then(
                          (res) => {
                            if (this.state[field_name]) {
                              this.props.setParentState({
                                [field_name]: this.props.parentState.data[
                                  field_name
                                ].concat([res.data.RnUploadFile.id]),
                              });
                              this.setState((state) => ({
                                [field_name]: state[field_name].concat([
                                  res.data.RnUploadFile,
                                ]),
                              }));
                            } else {
                              this.props.setParentState({
                                field_name: [res.data.RnUploadFile.id],
                              });
                              this.setState((state) => ({
                                data: {
                                  ...state.data,
                                  [field_name]: [res.data.RnUploadFile.id],
                                },
                                [field_name]: [res.data.RnUploadFile],
                              }));
                            }
                          }
                        );
                      }
                    }}
                  />
                  <label
                    className="custom-file-label"
                    htmlFor="inputGroupFile04"
                  >
                    Выбрать файл
                  </label>
                </div>
              </div>
            )}
          </Mutation>
          <ul className="list-group" style={{ marginTop: "1rem" }}>
            {this.state[field_name] &&
              this.state[field_name].map((item) => (
                <li className="list-group-item align-middle" key={item.id}>
                  <a href={item.path ? item.path : "#"}>{item.name}</a>
                  <button
                    className="btn btn-danger float-right"
                    onClick={() => {
                      let new_state = this.state[field_name].filter(
                        (i) => i.id !== item.id
                      );
                      this.setState((state) => ({
                        [field_name]: new_state,
                      }));
                      this.props.setParentState({
                        files: new_state.map((item) => item.id),
                      });
                    }}
                  >
                    X
                  </button>
                </li>
              ))}
          </ul>
        </div>
      </React.Fragment>
    );
  }

  render() {
    return (
      <>
        <p> Ссылка на целевую аудиторию</p>
        <div className="input-group">
          <input
            className="form-control mb-3"
            value={this.props.parentProps.platform?.targetAudienceLink}
            type="text"
            onChange={(e) => {
              const value = e.target.value;
              this.props.setParentState({ targetAudienceLink: value });
            }}
          />{" "}
        </div>
        <p>Файл с презентацией о целевой аудитории</p>
        {this.renderFileInput("files")}
      </>
    );
  }
}

export default Audience;
