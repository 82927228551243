import React from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { CircularProgress } from "@material-ui/core";
import { useMpListQuery } from "../../../graphql/types";

interface IMediaPlanProps {
  client: any;
  isMulti?: boolean;
  label: string;
  onChange: (value: any[] | any) => void;
}

export function MediaPlan({
  client,
  onChange,
  label,
  isMulti = false,
}: IMediaPlanProps) {
  const { loading } = useMpListQuery({
    client,
    variables: {
      params: {
        count: 9999,
        offset: 0,
      },
    },
    onCompleted: (data) => {
      setOptions(data?.RnMpListPager?.data || []);
    },
  });

  const [options, setOptions] = React.useState<any[]>([]);
  const [input, setInput] = React.useState<string>("");

  return (
    <Autocomplete
      multiple
      filterSelectedOptions
      disableCloseOnSelect
      loading={loading}
      loadingText="Загрузка медиапланов"
      options={options}
      size="small"
      onChange={(e, value) => onChange(value)}
      getOptionLabel={(option) => String(option.id)}
      inputValue={input}
      onInputChange={(e, value) => {
        setInput(value);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
}
