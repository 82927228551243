import React from "react";
import { useQuery } from "@apollo/react-hooks";
import { RN_BRIEFING } from "../RN/Components/Briefing/gql/query";
import FormAdd from "../RN/Components/Briefing/Form/Form";

export function BriefingClone(props: any) {
  const { data, loading } = useQuery(RN_BRIEFING, {
    client: props.client,
    variables: {
      id: props.match.params.id,
    },
  });

  if (loading) return "Загрузка";

  if (data.RnBriefing) {
    delete data.RnBriefing.deadline;
    delete data.RnBriefing.entryDate;
  }

  return (
    <FormAdd
      //@ts-ignore
      {...props}
      briefing={{
        ...data.RnBriefing,
        id: null,
        status: null,
        taskId: null,
        task_id: null,
      }}
    />
  );
}
