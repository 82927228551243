import React, { Component } from "react";
import AsyncSelect from "react-select/lib/Async";
import { ApolloConsumer } from "react-apollo";
import { UR_FACE_LIST, selectQuery } from "../../../../GraphQL/RNqueries";
import {
  RN_PLATFORM_CONTACT_CREATE,
  RN_PLATFORM_CONTACT_UPDATE,
  RN_CONTACT_REMOVE,
} from "../../../../GraphQL/RNmutations";
import { graphql } from "react-apollo";
import { Mutation } from "react-apollo";
import { Query } from "react-apollo";
import Select from "react-select";

//moment
const moment = require("moment/min/moment-with-locales.js");

class ContactsItem extends Component {
  state = {
    data: {
      PersonContacts: [],
    },
  };

  static getDerivedStateFromProps(props, state) {
    let NewState = {};
    if (!state.updated) {
      NewState = {
        data: {
          ...props.parentState.ContactsItems[props.indexKey],
        },
        updated: true,
      };

      if (NewState.data.PersonContacts) {
        for (let i of NewState.data.PersonContacts) {
          delete i["__typename"];
        }
      } else {
        NewState.data.PersonContacts = [];
      }

      delete NewState.data.LegalEntity;
      delete NewState.data.Partner;
      delete NewState.data["__typename"];
    }
    return NewState;
  }

  componentDidMount() {
    this.setState(() => ({
      _updateDate: moment().format(),
    }));
  }

  render() {
    return (
      <div style={{ marginBottom: "1rem" }} className="card">
        <div className="card-header">
          <div className="row">
            <div className="col-6">Контакт № {this.props.indexKey + 1}</div>
          </div>
        </div>
        <div className="card-body">
          <div className="row mb-1">
            <div className="col-6">
              <div className="row">
                <label className="col-4">
                  <strong>Партнер</strong>
                </label>
                <div className="col-8">
                  <ApolloConsumer>
                    {(client) => (
                      <AsyncSelect
                        defaultValue={
                          this.props.contactsItem.Partner
                            ? {
                                value: this.props.contactsItem.Partner.id,
                                label: this.props.contactsItem.Partner.name,
                              }
                            : false
                        }
                        loadOptions={(inputValue) =>
                          selectQuery(
                            inputValue,
                            "MLPartnerList",
                            client,
                            "UF_FullName"
                          )
                        }
                        onChange={(partner) => {
                          if (partner) {
                            const value = partner.value;
                            this.setState((state) => ({
                              data: {
                                ...state.data,
                                partner_id: value,
                              },
                            }));
                          } else {
                            this.setState((state) => ({
                              data: {
                                ...state.data,
                                partner_id: null,
                              },
                            }));
                          }
                        }}
                      />
                    )}
                  </ApolloConsumer>
                </div>
              </div>
            </div>
            <div className="col-6">
              <div className="row">
                <label className="col-4">
                  <strong>Юридическое лицо</strong>
                </label>
                <div className="col-8">
                  {this.state.data.partner_id ? (
                    <Query
                      query={UR_FACE_LIST}
                      variables={{
                        params: {
                          offset: 0,
                          count: 20,
                          filter: [
                            {
                              values: [`${this.state.data.partner_id}`],
                              operator: "IN",
                              field: "FK_ref_Partner_ID",
                            },
                          ],
                        },
                      }}
                    >
                      {({ loading, error, data }) => {
                        if (loading) return "Загрузка...";
                        if (error) return "Ошибка";

                        const legalEntities =
                          data.ML &&
                          data.ML.UrFaceList?.map((item) => ({
                            label: item.UF_Name,
                            value: item.UrFace_ID,
                          }));

                        return (
                          <Select
                            defaultValue={
                              this.props.contactsItem.LegalEntity
                                ? {
                                    value: this.props.contactsItem.LegalEntity
                                      .UrFace_ID,
                                    label: this.props.contactsItem.LegalEntity
                                      .UF_Name,
                                  }
                                : false
                            }
                            options={legalEntities}
                            onChange={(legal_entity) => {
                              if (legal_entity) {
                                const value = legal_entity.value;
                                this.setState((state) => ({
                                  data: {
                                    ...state.data,
                                    legal_entity_id: value,
                                  },
                                }));
                              } else {
                                this.setState((state) => ({
                                  data: {
                                    ...state.data,
                                    legal_entity_id: null,
                                  },
                                }));
                              }
                            }}
                          />
                        );
                      }}
                    </Query>
                  ) : (
                    <span className="text-secondary">Выберите партнера</span>
                  )}
                </div>
              </div>
            </div>
            <div className="col-12 row mt-4">
              <div className="col-12 mb-3">
                <strong>Контактные данные</strong>
              </div>
              <div className="col-3">
                <div className="form-group">
                  <label className="control-label">Имя</label>
                  <input
                    type="text"
                    name={`FirstName-${this.props.indexKey}`}
                    className="form-control"
                    value={this.state.data.name ? this.state.data.name : ""}
                    onChange={(e) => {
                      const value = e.target.value;
                      this.setState((state) => ({
                        data: {
                          ...state.data,
                          name: value,
                        },
                      }));
                    }}
                  />
                </div>
              </div>
              <div className="col-3">
                <div className="form-group">
                  <label className="control-label">Фамилия</label>
                  <input
                    type="text"
                    name={`SurName-${this.props.indexKey}`}
                    className="form-control"
                    value={
                      this.state.data.last_name ? this.state.data.last_name : ""
                    }
                    onChange={(e) => {
                      const value = e.target.value;
                      this.setState((state) => ({
                        data: {
                          ...state.data,
                          last_name: value,
                        },
                      }));
                    }}
                  />
                </div>
              </div>
              <div className="col-3">
                <div className="form-group">
                  <label className="control-label">Отчество</label>
                  <input
                    type="text"
                    name={`MiddleName-${this.props.indexKey}`}
                    className="form-control"
                    value={
                      this.state.data.middle_name
                        ? this.state.data.middle_name
                        : ""
                    }
                    onChange={(e) => {
                      const value = e.target.value;
                      this.setState((state) => ({
                        data: {
                          ...state.data,
                          middle_name: value,
                        },
                      }));
                    }}
                  />
                </div>
              </div>
              <div className="col-2">
                <div className="form-group">
                  <label className="control-label">Телефон</label>
                  <input
                    type="text"
                    value={this.state.data.phone ? this.state.data.phone : ""}
                    className="form-control"
                    onChange={(e) => {
                      const value = e.target.value;

                      if (
                        this.state.data.PersonContacts &&
                        this.state.data.PersonContacts.length
                      ) {
                        let contact_array = this.state.data.PersonContacts;
                        let index = contact_array.findIndex(
                          (item) => item.FK_v_ref_ContactType_ID === 826
                        );

                        if (index >= 0) {
                          if (
                            this.state.data.PersonContacts[index].Contact_ID
                          ) {
                            contact_array[index] = {
                              ...contact_array[index],
                              Contact: value,
                            };

                            this.setState((state) => ({
                              data: {
                                ...state.data,
                                phone: value,
                                PersonContacts: [...contact_array],
                              },
                            }));
                          } else {
                            contact_array[index] = {
                              Contact: value,
                              FK_v_ref_ContactType_ID: 826,
                            };

                            this.setState((state) => ({
                              data: {
                                ...state.data,
                                phone: value,
                                PersonContacts: [...contact_array],
                              },
                            }));
                          }
                        } else {
                          this.setState((state) => ({
                            data: {
                              ...state.data,
                              phone: value,
                              PersonContacts: [
                                ...contact_array,
                                {
                                  Contact: value,
                                  FK_v_ref_ContactType_ID: 826,
                                },
                              ],
                            },
                          }));
                        }
                      } else {
                        this.setState((state) => ({
                          data: {
                            ...state.data,
                            phone: value,
                            PersonContacts: [
                              {
                                Contact: value,
                                FK_v_ref_ContactType_ID: 826,
                              },
                            ],
                          },
                        }));
                      }
                    }}
                  />
                </div>
              </div>
              <div className="col-2">
                <div className="form-group">
                  <label className="control-label">Электронная почта</label>
                  <input
                    type="text"
                    className="form-control"
                    value={this.state.data.email ? this.state.data.email : ""}
                    onChange={(e) => {
                      const value = e.target.value;

                      if (
                        this.state.data.PersonContacts &&
                        this.state.data.PersonContacts.length
                      ) {
                        let contact_array = this.state.data.PersonContacts;

                        let index = contact_array.findIndex(
                          (item) => item.FK_v_ref_ContactType_ID === 828
                        );

                        if (index >= 0) {
                          if (
                            this.state.data.PersonContacts[index].Contact_ID
                          ) {
                            contact_array[index] = {
                              ...contact_array[index],
                              Contact: value,
                            };

                            this.setState((state) => ({
                              data: {
                                ...state.data,
                                email: value,
                                PersonContacts: [...contact_array],
                              },
                            }));
                          } else {
                            contact_array[index] = {
                              Contact: value,
                              FK_v_ref_ContactType_ID: 828,
                            };

                            this.setState((state) => ({
                              data: {
                                ...state.data,
                                email: value,
                                PersonContacts: [...contact_array],
                              },
                            }));
                          }
                        } else {
                          this.setState((state) => ({
                            data: {
                              ...state.data,
                              email: value,
                              PersonContacts: [
                                ...contact_array,
                                {
                                  Contact: value,
                                  FK_v_ref_ContactType_ID: 828,
                                },
                              ],
                            },
                          }));
                        }
                      } else {
                        this.setState((state) => ({
                          data: {
                            ...state.data,
                            email: value,
                            PersonContacts: [
                              {
                                Contact: value,
                                FK_v_ref_ContactType_ID: 828,
                              },
                            ],
                          },
                        }));
                      }
                    }}
                  />
                </div>
              </div>
              <div className="col-2">
                <div className="form-group">
                  <label className="control-label">Должность</label>
                  <input
                    type="text"
                    name="position"
                    className="form-control"
                    value={
                      this.state.data.position ? this.state.data.position : ""
                    }
                    onChange={(e) => {
                      const value = e.target.value;
                      this.setState((state) => ({
                        data: {
                          ...state.data,
                          position: value,
                        },
                      }));
                    }}
                  />
                </div>
              </div>
              <div className="col-3">
                <div className="form-group">
                  <label className="control-label">Тип процесса ДП</label>
                  <input
                    type="text"
                    name="position"
                    className="form-control"
                    value={
                      this.state.data.processType
                        ? this.state.data.processType
                        : null
                    }
                    onChange={(e) => {
                      const value = e.target.value;
                      this.setState((state) => ({
                        data: {
                          ...state.data,
                          processType: value,
                        },
                      }));
                    }}
                  />
                </div>
              </div>
              <div className="col-3">
                <div className="">
                  <label className="control-label">
                    Ответственный за прайсы
                  </label>
                  <select
                    name="responsible"
                    className="form-control"
                    defaultValue={
                      this.state.data.responsible
                        ? this.state.data.responsible
                        : ""
                    }
                    onChange={(e) => {
                      const value = +e.target.value;
                      this.setState((state) => ({
                        data: {
                          ...state.data,
                          responsible: value,
                        },
                      }));
                    }}
                  >
                    <option value="">Выбрать...</option>
                    <option value="1">Да</option>
                    <option value="0">Нет</option>
                  </select>
                </div>
              </div>
              <div className="col-2">
                <div className="form-group">
                  <label className="control-label">Комментарий</label>
                  <textarea
                    value={this.state.data.Comments && this.state.data.Comments}
                    onChange={(e) => {
                      const value = e.target.value;
                      this.setState((state) => ({
                        data: {
                          ...state.data,
                          Comments: value,
                        },
                      }));
                    }}
                  >
                    {" "}
                  </textarea>
                </div>
              </div>
            </div>
            <div className="col-12 row">
              <div className="col float-right">
                <button
                  className="btn btn-success"
                  onClick={() => {
                    if (!this.state.data.name) {
                      alert("Не указано имя контакта");
                    } else if (!this.state.data.last_name) {
                      alert("Не указана фамилия контакта");
                    } else {
                      if (!this.state.data.id) {
                        this.props
                          .createPerson({
                            variables: {
                              contact: this.state.data,
                            },
                          })
                          .then(() => {
                            alert("Контакт сохранен!");
                          });
                      } else {
                        this.props
                          .updatePerson({
                            variables: {
                              contact: this.state.data,
                            },
                          })
                          .then(() => {
                            alert("Контакт сохранен!");
                          });
                      }
                    }
                  }}
                >
                  Сохранить
                </button>
                <Mutation
                  mutation={RN_CONTACT_REMOVE}
                  refetchQueries={["RnPlatform"]}
                >
                  {(remove, { data }) => (
                    <button
                      className="btn btn-danger ml-1"
                      onClick={() => {
                        if (this.props.contactsItem.id) {
                          remove({
                            variables: {
                              id: this.props.contactsItem.id,
                            },
                            refetchQueries: ["RnPlatform"],
                          }).then(() => {
                            this.props.setParentState({
                              ContactsItems: this.props.parentState.ContactsItems.filter(
                                (item) => item.id !== this.props.contactsItem.id
                              ),
                            });
                          });
                        } else {
                          if (this.props.indexKey === 0) {
                            this.props.setParentState({
                              ContactsItems: [],
                            });
                          } else {
                            this.props.setParentState({
                              ContactsItems: this.props.parentState.ContactsItems.splice(
                                [this.props.indexKey],
                                1
                              ),
                            });
                          }
                        }
                      }}
                    >
                      Удалить
                    </button>
                  )}
                </Mutation>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

/*добавление*/
const ContactsItemAdd = graphql(RN_PLATFORM_CONTACT_CREATE, {
  props: ({ mutate }) => {
    return {
      createPerson: mutate,
    };
  },
  options: () => ({
    refetchQueries: ["RnPlatform"],
  }),
})(ContactsItem);

export const ContactsItemUpdate = graphql(RN_PLATFORM_CONTACT_UPDATE, {
  props: ({ mutate }) => {
    return {
      updatePerson: mutate,
    };
  },
  options: () => ({
    refetchQueries: ["RnPlatform"],
  }),
})(ContactsItemAdd);

export default ContactsItemUpdate;
