import React, { Component } from "react";
import { RN_UPLOAD_FILE } from "../../../../GraphQL/RNmutations";
import { Mutation } from "react-apollo";

//moment
const moment = require("moment/min/moment-with-locales.js");

class Price extends Component {
  /* global $*/
  state = {
    priceFile: [],
    buyer_note_file: [],
    technical_requirements_publication_file: [],
    technical_requirements_banner_file: []
  };

  //ссылка
  //создание ссылки ручное
  //впоследствии нужна в jquery
  textInput = React.createRef();
  textInputStartDate = React.createRef();

  //создание объектов jquery
  jQueryCreate() {
    const _this = this;
    $("input#startDate")
      .daterangepicker({
        startDate: this.props.parentState.data.Price
          ? moment(this.props.parentState.data.Price.startDate).format(
              "DD.MM.YYYY"
            )
          : moment(),
        singleDatePicker: true,
        opens: "right",
        locale: {
          applyLabel: "Применить",
          cancelLabel: "Отменить"
        }
      })
      .ready(() => {
        let date = this.textInputStartDate.current.value;

        _this.props.setParentState({
          startDate: moment(date, "DD-MM-YYYY").format()
        });
      })
      .on("apply.daterangepicker", function(e, picker) {
        _this.props.setParentState({
          startDate: moment(picker.startDate).format(),
          updateDate: _this.state._updateDate
        });
      });

    $("input#endDate")
      .daterangepicker({
        startDate: this.props.parentState.data.Price
          ? moment(this.props.parentState.data.Price.endDate).format(
              "DD.MM.YYYY"
            )
          : moment(),
        singleDatePicker: true,
        opens: "right",
        locale: {
          applyLabel: "Применить",
          cancelLabel: "Отменить"
        }
      })
      .ready(() => {
        let date = this.textInput.current.value;

        _this.props.setParentState({
          endDate: moment(date, "DD-MM-YYYY").format()
        });
      })
      .on("apply.daterangepicker", function(e, picker) {
        _this.props.setParentState({
          endDate: moment(picker.startDate).format(),
          updateDate: _this.state._updateDate
        });
      });
  }

  static getDerivedStateFromProps(props, state) {
    let updateForState = {};

    if (!state.updated) {
      updateForState = {
        priceFile: props.parentState.priceFile,
        buyer_note_file: props.parentState.buyer_note_file,
        technical_requirements_publication_file:
          props.parentState.technical_requirements_publication_file,
        technical_requirements_banner_file:
          props.parentState.technical_requirements_banner_file,
        authorId: props.parentState.data.authorId,
        userLogin: props.parentState.priceAuthor
          ? props.parentState.priceAuthor
          : "Не указано",
        updated: true
      };
    }

    return updateForState;
  }

  renderFileInput(field_name) {
    return (
      <React.Fragment>
        <div>
          <Mutation mutation={RN_UPLOAD_FILE}>
            {mutate => (
              <div className="input-group">
                <div className="custom-file">
                  <input
                    type="file"
                    className="custom-file-input form-control"
                    id="inputGroupFile04"
                    aria-describedby="inputGroupFileAddon04"
                    required
                    multiple
                    onChange={({ target: { validity, files } }) => {
                      //validity.valid &&
                      //сделал две ветки на отображение и загрузку
                      for (let i = 0; i < files.length; i++) {
                        mutate({ variables: { file: files[i] } }).then(res => {
                          this.props.setParentState({
                            authorId: this.props.parentProps.user.id
                          });

                          if (this.state[field_name]) {
                            this.props.setParentStateDir({
                              [field_name]: this.props.parentState.data[
                                field_name
                              ].concat([res.data.RnUploadFile.id])
                            });

                            this.setState(state => ({
                              [field_name]: state[field_name].concat([
                                res.data.RnUploadFile
                              ])
                            }));

                            this.props.setParentState({
                              updateDate: this.state._updateDate
                            });
                          } else {
                            this.props.setParentStateDir({
                              [field_name]: [res.data.RnUploadFile.id]
                            });

                            this.setState(state => ({
                              data: {
                                ...state.data,
                                [field_name]: [res.data.RnUploadFile.id]
                              },
                              [field_name]: [res.data.RnUploadFile]
                            }));
                            this.props.setParentState({
                              updateDate: this.state._updateDate
                            });
                          }
                        });
                      }
                    }}
                  />
                  <label
                    className="custom-file-label"
                    htmlFor="inputGroupFile04"
                  >
                    Выбрать файл
                  </label>
                </div>
              </div>
            )}
          </Mutation>
          <ul
            className="list-group"
            style={{
              marginTop: "1rem"
            }}
          >
            {this.state[field_name] &&
              this.state[field_name].map(item => (
                <li className="list-group-item align-middle" key={item.id}>
                  <a href={item.path ? `${origin}/${item.path}` : "#"}>
                    {item.name}
                  </a>
                  <button
                    className="btn btn-danger float-right"
                    onClick={() => {
                      let new_state = this.state[field_name].filter(
                        i => i.id !== item.id
                      );

                      this.setState(state => ({
                        [field_name]: new_state
                      }));

                      new_state = this.props.parentState.data[
                        field_name
                      ].filter(i => i !== item.id);

                      this.props.setParentStateDir({
                        [field_name]: new_state
                      });

                      this.props.setParentState({
                        updateDate: this.state._updateDate
                      });
                    }}
                  >
                    X
                  </button>
                </li>
              ))}
          </ul>
        </div>
      </React.Fragment>
    );
  }

  componentDidMount() {
    this.jQueryCreate();
    this.setState(state => ({
      _updateDate: moment().format()
    }));
    this.props.setParentState({
      updateDate: moment().format()
    });
  }

  render() {
    console.log(this.props.parentState.data.Price.startDate);

    return (
      <div className="card">
        <div className="card-header">
          <div className="row">
            <div className="col">
              <label className="control-label" htmlFor="startDate">
                <b>Действует с</b>
              </label>
              <input
                id="startDate"
                name="startDate"
                type="text"
                ref={this.textInputStartDate}
                className="form-control calendar-input"
                onChange={e => {
                  if (e.target.value.length === 10) {
                    this.props.setParentState({
                      startDate: moment(e.target.value).format()
                    });
                  }
                }}
              />
            </div>
            <div className="col">
              <label className="control-label" htmlFor="endDate">
                <b>Действует до</b>
              </label>
              <input
                id="endDate"
                name="endDate"
                type="text"
                ref={this.textInput}
                className="datepicker form-control"
                onChange={e => {
                  if (e.target.value.length === 10) {
                    this.props.setParentState({
                      endDate: moment(e.target.value).format()
                    });
                  }
                }}
              />
            </div>
            <div className="col">
              <label className="control-label" htmlFor="vat">
                <b>НДС площадки</b>
              </label>
              <select
                id="vat"
                name="vat"
                className="form-control"
                value={
                  this.props.parentState.data.Price
                    ? this.props.parentState.data.Price.vat
                      ? this.props.parentState.data.Price.vat
                      : ""
                    : ""
                }
                onChange={e => {
                  const value = +e.target.value;
                  this.props.setParentState({
                    vat: value,
                    updateDate: this.state._updateDate
                  });
                }}
              >
                <option>Выбрать</option>
                <option value="1">С НДС</option>
                <option value="2">Без НДС</option>
                <option value="4">с НДС / без НДС</option>
                <option value="3">Неизвестно</option>
              </select>
            </div>
            {/*<div className="col">*/}
            {/*<label className="control-label" htmlFor="priceVat"><b>Прайс с НДС</b></label>*/}
            {/*<select*/}
            {/*id="priceVat"*/}
            {/*name="priceVat"*/}
            {/*className="form-control"*/}
            {/*value={ this.props.parentState.data.Price ? this.props.parentState.data.Price.priceVat ? this.props.parentState.data.Price.priceVat : '' : '' }*/}
            {/*onChange={ e =>{*/}
            {/*const value = +e.target.value;*/}
            {/*this.props.setParentState({*/}
            {/*"priceVat": value,*/}
            {/*"updateDate": this.state._updateDate*/}
            {/*});*/}
            {/*}}*/}
            {/*>*/}
            {/*<option>Выбрать</option>*/}
            {/*<option value="1">Да</option>*/}
            {/*<option value="2">Нет</option>*/}
            {/*</select>*/}
            {/*</div>*/}
          </div>
          <div className="row">
            <div className="col-4">
              <div className="">
                <label className="control-label" htmlFor="comment">
                  <b>Технические требования к баннерам</b>
                </label>
                <textarea
                  name="comment"
                  id="comment"
                  rows="3"
                  value={
                    this.props.parentState.data.Price
                      ? this.props.parentState.data.Price.comment
                        ? this.props.parentState.data.Price.comment
                        : ""
                      : ""
                  }
                  onChange={e => {
                    const value = e.target.value;
                    this.props.setParentState({
                      comment: value,
                      updateDate: this.state._updateDate
                    });
                  }}
                  className="form-control"
                ></textarea>
              </div>
              <div className="form-group">
                <label className="control-label" htmlFor="price-9">
                  <b>Файл</b>
                </label>
                <div className="file-input-container">
                  {/*<input type="text" id="price-9" className="form-control" placeholder="Выбрать файл..." />*/}
                  {this.renderFileInput("technical_requirements_banner_file")}
                </div>
              </div>
              <div className="">
                <label className="control-label" htmlFor="comment">
                  <b>Технические требования к новостям</b>
                </label>
                <textarea
                  name="comment"
                  id="comment"
                  rows="3"
                  value={
                    this.props.parentState.data.Price &&
                    this.props.parentState.data.Price
                      .technical_requirements_publication
                      ? this.props.parentState.data.Price
                          .technical_requirements_publication
                      : ""
                  }
                  onChange={e => {
                    const value = e.target.value;
                    this.props.setParentState({
                      technical_requirements_publication: value,
                      updateDate: this.state._updateDate
                    });
                  }}
                  className="form-control"
                ></textarea>
              </div>
              <div className="form-group">
                <label className="control-label" htmlFor="price-9">
                  <b>Файл</b>
                </label>
                <div className="file-input-container">
                  {/*<input type="text" id="price-9" className="form-control" placeholder="Выбрать файл..." />*/}
                  {this.renderFileInput(
                    "technical_requirements_publication_file"
                  )}
                </div>
              </div>
              <div className="">
                <label className="control-label" htmlFor="comment">
                  <b>Комментарий</b>
                </label>
                <textarea
                  name="comment"
                  id="comment"
                  rows="3"
                  value={
                    this.props.parentState.data.Price
                      ? this.props.parentState.data.Price.comment_2
                        ? this.props.parentState.data.Price.comment_2
                        : ""
                      : ""
                  }
                  onChange={e => {
                    const value = e.target.value;
                    this.props.setParentState({
                      comment_2: value,
                      updateDate: this.state._updateDate
                    });
                  }}
                  className="form-control"
                ></textarea>
              </div>
              <div className="">
                <label className="control-label" htmlFor="comment">
                  <b>Замечания байера</b>
                </label>
                <textarea
                  name="comment"
                  id="comment"
                  rows="3"
                  value={
                    this.props.parentState.data.Price
                      ? this.props.parentState.data.Price.buyer_note
                        ? this.props.parentState.data.Price.buyer_note
                        : ""
                      : ""
                  }
                  onChange={e => {
                    const value = e.target.value;
                    this.props.setParentState({
                      buyer_note: value,
                      updateDate: this.state._updateDate
                    });
                  }}
                  className="form-control"
                ></textarea>
              </div>
              <div className="form-group">
                <label className="control-label" htmlFor="price-9">
                  <b>Файл</b>
                </label>
                <div className="file-input-container">
                  {/*<input type="text" id="price-9" className="form-control" placeholder="Выбрать файл..." />*/}
                  {this.renderFileInput("buyer_note_file")}
                </div>
              </div>
            </div>
            <div className="col-5">
              <div className="form-group">
                <label className="control-label" htmlFor="price-9">
                  <b>Файл прайса</b>
                </label>
                <div className="file-input-container">
                  {/*<input type="text" id="price-9" className="form-control" placeholder="Выбрать файл..." />*/}
                  {this.renderFileInput("priceFile")}
                </div>
              </div>
              <div className="form-group">
                <label>
                  <b>Ссылка на прайс</b>
                </label>
                <input
                  type="text"
                  name="price_url"
                  className="form-control"
                  value={
                    this.props.parentState.data.Price &&
                    this.props.parentState.data.Price.price_url
                      ? this.props.parentState.data.Price.price_url
                      : ""
                  }
                  onChange={e => {
                    const value = e.target.value;
                    this.props.setParentState({
                      price_url: value,
                      updateDate: this.state._updateDate
                    });
                  }}
                />
              </div>
            </div>
            <div className="col-3">
              <div className="form-group">
                <label className="control-label" htmlFor="price-10">
                  <b>Автор</b>
                </label>
                <input
                  type="text"
                  id="price-10"
                  className="form-control mb-3"
                  disabled="disabled"
                  value={
                    this.props.parentProps.platform.User
                      ? this.props.parentProps.platform.User.login
                      : ""
                  }
                />
                <b>Актуализированно:</b>{" "}
                {this.props.parentState.data.Price &&
                  this.props.parentState.data.Price.updateDate &&
                  moment(this.props.parentState.data.Price.updateDate).format(
                    "DD.MM.YYYY HH:mm"
                  )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Price;
