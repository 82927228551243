import React from "react";
import { Link } from "react-router-dom";
import ListItem from "./ListItem";
import { graphql } from "react-apollo";
import { RN_BRIEFING_lIST_PAGER } from "./gql/query";
import { selectQuery } from "./gql/query";
import AsyncSelect from "react-select/lib/Async";
import { ApolloConsumer } from "react-apollo";

import Header from "./../../../template/Header";

const queryString = require("query-string");

class List extends React.Component {
  state = {
    page: 1,
    count: 10,
    offset: 0,
    filter: [],
  };

  update(pageNumber, filter = []) {
    this.props.update(
      (pageNumber - 1) * this.state.count,
      this.state.count,
      filter
    );
  }

  static getDerivedStateFromProps(props, oldState) {
    let updateForState = {};
    if (oldState.updated == null || props.pageInfo) {
      updateForState = {
        ...oldState,
        count:
          props.pageInfo.count === oldState.count
            ? +props.pageInfo.count
            : +oldState.count,
        total: +props.pageInfo.total,
        offset: (oldState.page - 1) * oldState.count,
        page: oldState.offset / oldState.count + 1,
        pages: Math.ceil(props.pageInfo.total / oldState.count),
        updated: true,
      };
      updateForState.page =
        +queryString.parse(document.location.hash).page || 1;
      updateForState.count =
        +queryString.parse(document.location.hash).count || 10;
      updateForState.pages = Math.ceil(
        props.pageInfo.total / updateForState.count
      );
      updateForState.offset = (updateForState.page - 1) * updateForState.count;
      props.update(
        updateForState.offset,
        updateForState.count,
        oldState.filter
      );
    }

    return updateForState;
  }

  renderButtons(count) {
    let array = [];
    let numbers = Math.floor(count / 2);

    let start = this.state.page - numbers;
    let end = this.state.page + numbers;

    for (let i = start - 1; i <= end + 1; i++) {
      if (i <= start - 1 && i > 1) {
        array.push("...");
      } else if (i === end + 1 && i < this.state.pages) {
        array.push("...");
      } else if (i >= this.state.pages) {
        continue;
      } else if (i <= 1) {
        continue;
      } else {
        array.push(i);
      }
    }

    let array_to_show = [1, ...array, +this.state.pages];

    return (
      <>
        {this.state.pages > 1 &&
          array_to_show.map((item, i) => {
            return (
              <React.Fragment key={`${item}${i}`}>
                {item !== "..." ? (
                  <li
                    className={
                      this.state.page === item
                        ? "page-item active"
                        : "page-item"
                    }
                  >
                    <Link
                      className="page-link"
                      onClick={() => {
                        this.update(item);
                        this.setState({
                          page: item,
                        });
                      }}
                      to={`/briefing/list/#page=${item}&count=${this.state.count}`}
                    >
                      {item}
                    </Link>
                  </li>
                ) : (
                  <li className="page-item disabled">
                    <span className="page-link">...</span>
                  </li>
                )}
              </React.Fragment>
            );
          })}
      </>
    );
  }

  updateList(field) {
    const value = field.value;
    let filter_list = this.state.filter.filter(
      (item) => item.field !== field.name
    );

    document.location.hash = null;

    this.setState({
      ...this.state,
      filter: [
        ...filter_list,
        {
          operator: "IN",
          field: field.name,
          values: [value],
        },
      ],
    });
  }

  render() {
    const startString = (this.state.page - 1) * this.state.count + 1;
    const endString =
      (this.state.page - 1) * this.state.count + this.state.count;

    return (
      <>
        <Header {...this.props} {...this.state} />
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
          <div className="collapse navbar-collapse">
            <span className="navbar-brand mr-5">Брифинги Digital</span>
          </div>
        </nav>
        <section className="section-top">
          <div className="container-fluid">
            <div className="row mt-2">
              <div className="col-1">
                <Link className="btn btn-sm btn-dark" to="/briefing/add">
                  Добавить бриф
                </Link>
              </div>
              <div className="col-8 row">
                <div className="col row">
                  <label htmlFor="brand_id" className="col-3 col-form-label">
                    Бренд
                  </label>
                  <div className="col-9">
                    <ApolloConsumer>
                      {(client) => (
                        <AsyncSelect
                          name="brand_id"
                          defaultValue={
                            this.props.briefing && this.props.briefing.Brand
                              ? {
                                  value: this.props.briefing.Brand.id,
                                  label: this.props.briefing.Brand.name,
                                }
                              : {}
                          }
                          loadOptions={(inputValue) =>
                            selectQuery(inputValue, "MLBrandList", client)
                          }
                          onChange={(res) => {
                            this.updateList({
                              ...res,
                              name: "brand_id",
                            });
                          }}
                        />
                      )}
                    </ApolloConsumer>
                  </div>
                </div>
                <div className="col row">
                  <label
                    htmlFor="responsible_id"
                    className="col-3 col-form-label"
                  >
                    Исполнитель
                  </label>
                  <div className="col-9">
                    <ApolloConsumer>
                      {(client) => (
                        <AsyncSelect
                          name="responsible_id"
                          defaultValue={
                            this.state.Responsible
                              ? {
                                  value: this.state.Responsible.id,
                                  label: this.state.Responsible.nameFull,
                                }
                              : {}
                          }
                          loadOptions={(inputValue) =>
                            selectQuery(
                              inputValue,
                              "RefEmployeeList",
                              client,
                              "manager"
                            )
                          }
                          onChange={(res) => {
                            this.updateList({
                              ...res,
                              name: "responsible_id",
                            });
                          }}
                        />
                      )}
                    </ApolloConsumer>
                  </div>
                </div>
                <div className="col row">
                  <label htmlFor="manager_id" className="col-3 col-form-label">
                    Куратор
                  </label>
                  <div className="col-9">
                    <ApolloConsumer>
                      {(client) => (
                        <AsyncSelect
                          name="manager_id"
                          defaultValue={
                            this.state.Responsible
                              ? {
                                  value: this.state.Responsible.id,
                                  label: this.state.Responsible.nameFull,
                                }
                              : {}
                          }
                          loadOptions={(inputValue) =>
                            selectQuery(
                              inputValue,
                              "RefEmployeeList",
                              client,
                              "manager"
                            )
                          }
                          onChange={(res) => {
                            this.updateList({
                              ...res,
                              name: "manager_id",
                            });
                          }}
                        />
                      )}
                    </ApolloConsumer>
                  </div>
                </div>
              </div>
              <div className="col-2">
                <div className="text-right right-nav-text">
                  <a>
                    {startString} -{" "}
                    {endString < this.state.total
                      ? endString
                      : this.state.total}{" "}
                    из {this.state.total}
                  </a>
                  <span className="nav-controls">
                    {this.state.page > 1 ? (
                      <Link
                        className="prev-page"
                        onClick={() => {
                          this.update(this.state.page - 1);
                          this.setState((state) => ({
                            page: state.page - 1,
                          }));
                        }}
                        to={`/briefing/list/#page=${
                          this.state.page - 1
                        }&count=${this.state.count}`}
                      />
                    ) : (
                      <a className="prev-page disabled"></a>
                    )}
                    {this.state.page < this.state.pages &&
                    this.state.count < this.state.total ? (
                      <Link
                        className="next-page"
                        onClick={() => {
                          this.update(this.state.page + 1);
                          this.setState((state) => ({
                            page: state.page + 1,
                          }));
                        }}
                        to={`/briefing/list/#page=${
                          this.state.page + 1
                        }&count=${this.state.count}`}
                      />
                    ) : (
                      <a className="next-page disabled"></a>
                    )}
                  </span>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <label>Показывать строк: </label>
                <select
                  value={this.state.count}
                  onChange={(e) => {
                    const value = e.target.value;
                    this.setState({
                      count: value,
                      pages: Math.ceil(this.state.total / value),
                      page: Math.ceil(this.state.offset / value) + 1,
                    });

                    const params = queryString.parse(document.location.hash);
                    params.page = Math.ceil((this.state.offset + 1) / value);
                    params.count = value;
                    document.location.hash = queryString.stringify(params);

                    this.props.update(
                      Math.ceil(this.state.offset / value) * value,
                      value
                    );
                  }}
                >
                  <option value={10}>10</option>
                  <option value={5}>5</option>
                  <option value={20}>20</option>
                </select>
              </div>
            </div>
          </div>
        </section>
        <section className="content">
          <div className="container-fluid mediaplans-list-block">
            <div className="table-container">
              <table className="table table-borderless table-striped table-hover mpListTable">
                <thead className="thead-light">
                  <tr>
                    <th className="font-weight-bold">Дата создания</th>
                    <th className="font-weight-bold">Бренд</th>
                    <th className="font-weight-bold">Продвигаемая услуга</th>
                    <th className="font-weight-bold">Период</th>
                    <th className="font-weight-bold">Исполнитель</th>
                    <th className="font-weight-bold">Куратор</th>
                    <th className="font-weight-bold">Ссылка на медиаплан</th>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {!this.state.searching
                    ? this.props.briefingList.map((item) => (
                        <tr key={item.id}>
                          {<ListItem {...this.props} data={item} />}
                        </tr>
                      ))
                    : this.state.briefingListSearch.map((item) => (
                        <tr key={item.id}>
                          {<ListItem {...this.props} data={item} />}
                        </tr>
                      ))}
                </tbody>
              </table>
              <nav
                className="text-center mt-5 mb-5"
                aria-label="Page navigation example"
              >
                <ul className="pagination justify-content-center">
                  {this.state.total > this.state.count
                    ? this.renderButtons(3)
                    : null}
                </ul>
              </nav>
            </div>
          </div>
        </section>
        <div className="small-screen">
          <div className="container-fluid mt-5">
            <h1>
              На данном устройстве работа не возможна. Используйте устройство с
              более широким экраном.
            </h1>
          </div>
        </div>
      </>
    );
  }
}

export const RnBriefingList = graphql(RN_BRIEFING_lIST_PAGER, {
  options: () => ({
    variables: {
      params: {
        offset: 0,
        count: 10,
      },
    },
  }),
  props: ({ data }) => {
    return {
      loading: data.loading,
      error: data.error,
      briefingList: data.RnBriefingListPager
        ? data.RnBriefingListPager.data
        : null,
      pageInfo: data.RnBriefingListPager
        ? data.RnBriefingListPager.pageInfo
        : null,
      src_data: data,
      update: (offset, count, filter = []) => {
        data.fetchMore({
          variables: {
            params: {
              count: count,
              offset: offset,
              filter: filter.length ? filter : null,
            },
          },
          updateQuery: (prev, { fetchMoreResult }) => {
            return {
              RnBriefingListPager: fetchMoreResult.RnBriefingListPager,
            };
          },
        });
      },
    };
  },
})((props) => {
  if (props.loading) return null;
  if (props.error) return null;
  if (props.briefingList == null) {
    console.log("loading bad result", props);
    return null;
  }

  return <List {...props} />;
});
