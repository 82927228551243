import React, { Component } from "react";
import Select from "react-select";
import { Query } from "react-apollo";
import { RN_INVENTORY_LIST_SELECT } from "../../../../GraphQL/RNqueries";

//moment
const moment = require("moment/min/moment-with-locales.js");

class DiscountItem extends Component {
  /* global $*/

  state = {
    data: {},
  };

  //ссылка
  textInput = React.createRef();
  textInputEndDate = React.createRef();

  //создание объектов jquery
  jQueryCreate() {
    const _this = this;
    $(`input#startDate-${this.props.indexKey}`)
      .daterangepicker({
        startDate: moment(
          this.props.parentState.DiscountItems[this.props.indexKey].startDate
        ).format("DD.MM.YYYY"),
        singleDatePicker: true,
        opens: "right",
        locale: {
          applyLabel: "Применить",
          cancelLabel: "Отменить",
        },
      })
      .ready(() => {
        let date = this.textInput.current.value;

        let list = this.props.parentState.DiscountItems;

        for (let i of list) {
          delete i["__typename"];
        }

        list[this.props.indexKey].startDate = moment(
          date,
          "DD-MM-YYYY"
        ).format();
        list[this.props.indexKey].updateDate = this.state._updateDate;

        _this.props.parentProps.setParentState({
          DiscountItems: list,
        });

        _this.props.parentProps.GetDiscountItems(list);
      })
      .on("apply.daterangepicker", function (e, picker) {
        let list = _this.props.parentState.DiscountItems;

        for (let i of list) {
          delete i["__typename"];
        }

        list[_this.props.indexKey].startDate = moment(
          picker.startDate
        ).format();
        list[_this.props.indexKey].updateDate = _this.state._updateDate;

        _this.props.parentProps.setParentState({
          DiscountItems: list,
        });

        _this.props.parentProps.GetDiscountItems(list);
      });

    let condition = {};
    this.props.parentState.DiscountItems[this.props.indexKey].endDate
      ? (condition = {
          startDate: moment(
            this.props.parentState.DiscountItems[this.props.indexKey].endDate
          ).format("DD.MM.YYYY"),
        })
      : (condition = {
          autoUpdateInput: false,
        });

    $(`input#endDate-${this.props.indexKey}`)
      .daterangepicker({
        ...condition,
        singleDatePicker: true,
        opens: "right",
        locale: {
          applyLabel: "Применить",
          cancelLabel: "Отменить",
        },
      })
      .on("apply.daterangepicker", function (e, picker) {
        $(this).val(picker.endDate.format("DD.MM.YYYY"));
        let list = _this.props.parentState.DiscountItems;

        for (let i of list) {
          delete i["__typename"];
        }

        list[_this.props.indexKey].endDate = moment(picker.endDate).format();
        list[_this.props.indexKey].updateDate = _this.state._updateDate;

        _this.props.parentProps.setParentState({
          DiscountItems: list,
        });

        _this.props.parentProps.GetDiscountItems(list);
      });
  }

  componentDidMount() {
    // this.jQueryCreate();
    this.setState((state) => ({
      _updateDate: moment().format(),
    }));
  }

  render() {
    return (
      <div
        style={{
          marginBottom: "1rem",
          position: "relative",
          display: "flex",
          flexDirection: "column",
          minWidth: 0,
          backgroundColor: "#fff",
          backgroundClip: "border-box",
          border: "1px solid rgba(0,0,0,.125)",
          borderRadius: ".25rem",
        }}
      >
        <div
          className="card-header"
          style={{
            padding: "0.75rem 1rem 0",
            overflowX: "none",
          }}
        >
          <div className="row">
            <div className="col-6">
              <div className="form-group">
                <input
                  type="text"
                  name="discountItemName"
                  className="form-control"
                  placeholder="комментарий к скидке"
                  value={
                    this.props.parentState.DiscountItems[this.props.indexKey]
                      .name
                      ? this.props.parentState.DiscountItems[
                          this.props.indexKey
                        ].name
                      : ""
                  }
                  onChange={(e) => {
                    let list = this.props.parentState.DiscountItems;

                    for (let i of list) {
                      delete i["__typename"];
                    }

                    list[this.props.indexKey].name = e.target.value;
                    list[
                      this.props.indexKey
                    ].updateDate = this.state._updateDate;

                    this.props.parentProps.setParentState({
                      DiscountItems: list,
                    });

                    this.props.parentProps.GetDiscountItems(list);
                  }}
                />
              </div>
            </div>
            <div className="col-3 pt-1">
              <b>
                Автор:{" "}
                {this.props.discountItem?.user?.login ||
                  this.props.currentUser?.login ||
                  "Не указан"}
              </b>
            </div>
            <div className="col-2">
              <input
                type="text"
                className="form-control"
                value={moment(
                  this.props.parentState.DiscountItems[this.props.indexKey]
                    .updateDate
                ).format("DD.MM.Y")}
                disabled={true}
              />
            </div>
            <div className="col-1">
              <button
                type="button"
                className="pull-right remove-item btn btn-danger"
                onClick={() => {
                  let list = this.props.parentState.DiscountItems;

                  if (list[this.props.indexKey].id) {
                    list = list.filter(
                      (item) => item.id !== list[this.props.indexKey].id
                    );
                  } else {
                    if (list.length > 1) {
                      list.splice(this.props.indexKey, 1);
                    } else {
                      list = [];
                    }
                  }

                  this.props.setParentState({
                    DiscountItems: list,
                    _discountItems: list,
                  });

                  this.props.parentProps.GetDiscountItems(list);
                }}
              >
                <i className="glyphicon glyphicon-trash"> </i>удалить
              </button>
            </div>
          </div>
        </div>
        <div
          className="p-2"
          style={{
            marginTop: "1rem",
          }}
        >
          <div className="row">
            <div className="col d-none">
              <div className="">
                <label
                  className="control-label"
                  htmlFor="adress-11"
                  style={{
                    color: "#9e9e9e",
                  }}
                >
                  Действует с
                </label>
                <input
                  id={`startDate-${this.props.indexKey}`}
                  name="startDate"
                  type="text"
                  ref={this.textInput}
                  className="form-control calendar-input"
                />
              </div>
            </div>
            <div className="col d-none">
              <div className="form-group">
                <label
                  className="control-label"
                  htmlFor="adress-12"
                  style={{
                    color: "#9e9e9e",
                  }}
                >
                  Действует до
                </label>
                <input
                  id={`endDate-${this.props.indexKey}`}
                  name="endDate"
                  type="text"
                  ref={this.textInputEndDate}
                  className="datepicker form-control"
                  autoComplete="off"
                />
              </div>
            </div>
            <div className="col-2">
              <div className="form-group">
                <label
                  className="control-label"
                  htmlFor="adress-13"
                  style={{
                    color: "#9e9e9e",
                  }}
                >
                  Субъект
                </label>
                <select
                  id="adress-13"
                  className="form-control"
                  name="subject"
                  defaultValue={
                    this.props.parentState.DiscountItems[this.props.indexKey]
                      .subject !== null
                      ? this.props.parentState.DiscountItems[
                          this.props.indexKey
                        ].subject
                      : ""
                  }
                  onChange={(e) => {
                    let list = this.props.parentState.DiscountItems;

                    for (let i of list) {
                      delete i["__typename"];
                    }

                    list[this.props.indexKey].subject = +e.target.value;
                    list[
                      this.props.indexKey
                    ].updateDate = this.state._updateDate;

                    this.props.parentProps.setParentState({
                      DiscountItems: list,
                    });

                    this.props.parentProps.GetDiscountItems(list);
                  }}
                >
                  <option value="">Выбрать...</option>
                  <option value="0">Клиент</option>
                  <option value="1">DeltaPlan</option>
                  <option value="2">Клиент + DeltaPlan</option>
                </select>
              </div>
            </div>
            <div className="col-2">
              {this.props.parentState.DiscountItems[this.props.indexKey]
                .individual ? (
                <div className="">
                  <label
                    className="control-label"
                    style={{
                      color: "#9e9e9e",
                    }}
                  >
                    Инвентарь
                  </label>
                  <Query
                    query={RN_INVENTORY_LIST_SELECT}
                    variables={{
                      params: {
                        count: 150,
                        offset: 0,
                        filter: [
                          {
                            field: "platformId",
                            operator: "EQ",
                            values: [
                              this.props.parentProps.parentState.data.id,
                            ],
                          },
                        ],
                      },
                    }}
                  >
                    {({ loading, error, data }) => {
                      if (loading) return null;
                      if (error) return `Error! ${error.message}`;

                      let list = data.RnInventoryList.data.map((item) => ({
                        value: item.id,
                        label: item.name,
                      }));

                      return (
                        <Select
                          name="legal_entity_vat"
                          options={list}
                          placeholder={"Выбрать"}
                          defaultValue={
                            this.props.parentState._discountItems[
                              this.props.indexKey
                            ] &&
                            this.props.parentState._discountItems[
                              this.props.indexKey
                            ].inventory
                          }
                          onChange={(legal_entity_vat) => {
                            const value = legal_entity_vat.value;

                            let list = this.props.parentState.DiscountItems;

                            for (let i of list) {
                              delete i["__typename"];
                            }

                            list[this.props.indexKey].inventory_id = value;
                            list[this.props.indexKey].inventory_type = null;
                            list[
                              this.props.indexKey
                            ].updateDate = this.state._updateDate;

                            this.props.parentProps.setParentState({
                              DiscountItems: list,
                            });

                            this.props.parentProps.GetDiscountItems(list);
                          }}
                        />
                      );
                    }}
                  </Query>
                </div>
              ) : (
                <div className="">
                  <label
                    className="control-label"
                    style={{
                      color: "#9e9e9e",
                    }}
                  >
                    Тип инвентаря
                  </label>
                  <select
                    name="inventory_type"
                    className="form-control"
                    defaultValue={
                      this.props.parentState.DiscountItems[this.props.indexKey]
                        .inventory_type
                        ? this.props.parentState.DiscountItems[
                            this.props.indexKey
                          ].inventory_type
                        : ""
                    }
                    onChange={(e) => {
                      const value = e.target.value;
                      let list = this.props.parentState.DiscountItems;

                      for (let i of list) {
                        delete i["__typename"];
                      }

                      list[this.props.indexKey].inventory_type = value;
                      list[
                        this.props.indexKey
                      ].updateDate = this.state._updateDate;

                      this.props.parentProps.setParentState({
                        DiscountItems: list,
                      });

                      this.props.parentProps.GetDiscountItems(list);
                    }}
                  >
                    <option value="all">Все</option>
                    <option value="banner">Баннер</option>
                    <option value="publication">Новости и публикации</option>
                  </select>
                </div>
              )}

              <div className="form-group">
                <label>
                  <input
                    type="checkbox"
                    checked={
                      this.props.parentState.DiscountItems[this.props.indexKey]
                        .individual
                    }
                    onChange={(e) => {
                      const value = e.target.checked ? 1 : 0;
                      let list = this.props.parentState.DiscountItems;

                      for (let i of list) {
                        delete i["__typename"];
                      }

                      list[this.props.indexKey].individual = value;
                      list[this.props.indexKey].inventory_type = null;
                      list[
                        this.props.indexKey
                      ].updateDate = this.state._updateDate;

                      if (value === 0) {
                        list[this.props.indexKey].inventory_id = null;
                      }

                      this.props.parentProps.setParentState({
                        DiscountItems: list,
                      });

                      this.props.parentProps.GetDiscountItems(list);
                    }}
                  />{" "}
                  Привязать к инвентарю
                </label>
              </div>
            </div>
            <div className="col-2">
              <div className="form-group">
                <label
                  className="control-label"
                  htmlFor="adress-15"
                  style={{
                    color: "#9e9e9e",
                  }}
                >
                  База начисления
                </label>
                <select
                  id="adress-15"
                  name="accrualBasis"
                  className="form-control"
                  defaultValue={
                    this.props.parentState.DiscountItems[this.props.indexKey]
                      .accrualBasis
                      ? this.props.parentState.DiscountItems[
                          this.props.indexKey
                        ].accrualBasis
                      : ""
                  }
                  onChange={(e) => {
                    let list = this.props.parentState.DiscountItems;

                    for (let i of list) {
                      delete i["__typename"];
                    }

                    list[this.props.indexKey].accrualBasis = +e.target.value;
                    list[
                      this.props.indexKey
                    ].updateDate = this.state._updateDate;

                    this.props.parentProps.setParentState({
                      DiscountItems: list,
                    });

                    this.props.parentProps.GetDiscountItems(list);
                  }}
                >
                  <option value="">Выбрать...</option>
                  <option value="1">Время размещения</option>
                  <option value="2">От объема закупа</option>
                  <option value="3">Базовая</option>
                </select>
              </div>
            </div>
            <div className="col-1">
              <div className="form-group">
                <label
                  className="control-label"
                  htmlFor="adress-16"
                  style={{
                    color: "#9e9e9e",
                  }}
                >
                  от
                </label>
                <input
                  type="number"
                  name="discountFrom"
                  id="adress-16"
                  className="form-control"
                  step="0.01"
                  value={
                    this.props.parentState.DiscountItems[this.props.indexKey]
                      .discountFrom
                      ? this.props.parentState.DiscountItems[
                          this.props.indexKey
                        ].discountFrom
                      : ""
                  }
                  onChange={(e) => {
                    let list = this.props.parentState.DiscountItems;

                    for (let i of list) {
                      delete i["__typename"];
                    }

                    list[this.props.indexKey].discountFrom = +e.target.value;
                    list[
                      this.props.indexKey
                    ].updateDate = this.state._updateDate;

                    this.props.parentProps.setParentState({
                      DiscountItems: list,
                    });

                    this.props.parentProps.GetDiscountItems(list);
                  }}
                />
              </div>
            </div>
            <div className="col-1">
              <div className="form-group">
                <label
                  className="control-label"
                  htmlFor="adress-17"
                  style={{
                    color: "#9e9e9e",
                  }}
                >
                  до
                </label>
                <input
                  name="discountTo"
                  type="number"
                  id="adress-17"
                  className="form-control"
                  step="0.1"
                  value={
                    this.props.parentState.DiscountItems[this.props.indexKey]
                      .discountTo
                      ? this.props.parentState.DiscountItems[
                          this.props.indexKey
                        ].discountTo
                      : ""
                  }
                  onChange={(e) => {
                    let list = this.props.parentState.DiscountItems;

                    for (let i of list) {
                      delete i["__typename"];
                    }

                    list[this.props.indexKey].discountTo = +e.target.value;
                    list[
                      this.props.indexKey
                    ].updateDate = this.state._updateDate;

                    this.props.parentProps.setParentState({
                      DiscountItems: list,
                    });

                    this.props.parentProps.GetDiscountItems(list);
                  }}
                />
              </div>
            </div>
            <div className="col-1">
              <div className="form-group">
                <label
                  className="control-label"
                  htmlFor="adress-18"
                  style={{
                    color: "#9e9e9e",
                  }}
                >
                  Размер, %
                </label>
                <input
                  type="number"
                  id="adress-18"
                  className="form-control"
                  name="discountAmount"
                  step="0.1"
                  value={
                    this.props.parentState.DiscountItems[this.props.indexKey]
                      .discountAmount
                      ? this.props.parentState.DiscountItems[
                          this.props.indexKey
                        ].discountAmount
                      : ""
                  }
                  onChange={(e) => {
                    let list = this.props.parentState.DiscountItems;

                    for (let i of list) {
                      delete i["__typename"];
                    }

                    list[this.props.indexKey].discountAmount = e.target.value;
                    list[
                      this.props.indexKey
                    ].updateDate = this.state._updateDate;

                    this.props.parentProps.setParentState({
                      DiscountItems: list,
                    });

                    this.props.parentProps.GetDiscountItems(list);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default DiscountItem;
