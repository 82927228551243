import React, { Component } from "react";

class Modal extends Component {
  state = {};

  render() {
    return (
      <>
        <div
          className="popup-wrapper p-1"
          style={{
            top: "-80px",
            left: "500px",
            boxShadow: "0px 0px 6px 0px rgba(50, 50, 50, 0.3)",
            border: "none",
            zIndex: 1,
            minWidth: "250px",
            minHeight: "50px",
          }}
        >
          <div className="text-right mb-2">
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                this.props.setParentState({
                  inventory_format_modal: !this.props.inventory_format_modal,
                });
              }}
            >
              Закрыть
            </a>
          </div>
          <div>
            <textarea
              cols="30"
              rows="5"
              className="form-control"
              onChange={(e) => {
                const value = e.target.value;
                this.props.updateFieldText({
                  variables: {
                    id: this.props.mp_item_id,
                    field: "format",
                    value: value,
                  },
                  refetchQueries: ["RnMpItem"],
                });
              }}
            >
              {" "}
            </textarea>
          </div>
        </div>
      </>
    );
  }
}

export default Modal;
