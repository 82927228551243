import React from "react";
import Header from "../template/Header";

import { Tabs, Tab, Typography } from "@material-ui/core";
import { NavLink } from "react-router-dom";

import { useHistory } from "react-router-dom";
import { Route, Router, Switch } from "react-router";

import MpHistoryReport from "../components/historyReport/historyReport";
import Box from "@material-ui/core/Box";
import { ApolloConsumer } from "react-apollo";
import { ItemLogList } from "../components/ItemLogList/itemLogList";
import { BriefReport, OrderReport } from "../components/report";

export default function HeadReport() {
  const history = useHistory();

  const [page, setPage] = React.useState(history.location.pathname);

  return (
    <>
      <Header />
      <section className="content">
        <div className="container-fluid mediaplans-list-block">
          <div className="table-container">
            <Typography variant="h4">Отчет руководителя</Typography>
            <Box my={3}>
              <Tabs value={page} onChange={(e, value) => setPage(value)}>
                <Tab
                  label="Брифы"
                  value={"/report/brief"}
                  to="/report/brief"
                  component={NavLink}
                />
                <Tab
                  label="Медиапланы и рентабельность"
                  value={"/report/mp"}
                  to="/report/mp"
                  component={NavLink}
                />
                <Tab
                  label="Заказы"
                  value={"/report/order"}
                  to="/report/order"
                  component={NavLink}
                />
                <Tab
                  label="Медиа-факт"
                  value={"/report/media-fact"}
                  to="/report/media-fact"
                  component={NavLink}
                />
              </Tabs>
            </Box>
            <ApolloConsumer>
              {(client) => (
                <Router history={history}>
                  <Switch>
                    <Route exact path="/report/brief">
                      <BriefReport client={client} />
                    </Route>
                    <Route exact path="/report/mp">
                      <MpHistoryReport />
                    </Route>
                    <Route exact path="/report/order">
                      <OrderReport client={client} />
                    </Route>
                    <Route exact path="/report/media-fact">
                      <ItemLogList client={client} />
                    </Route>
                  </Switch>
                </Router>
              )}
            </ApolloConsumer>
          </div>
        </div>
      </section>
    </>
  );
}
